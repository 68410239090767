import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sidebarModel } from 'models';
import products from '_config/products';
import stateToProps from '@containers/storeState';
import dispatchToProps from '@containers/dispatches';
import { objLib } from '@xc-core/lib';
import { Loader } from '@xc-core/components/core';
import BaseQuotationDetails from '../base-quotation-detail';
import fields from './fields';
import models from './model';
import util from './util';

interface IProps { history: any, quotationManagement: IObject, setQuotationOccupationList: any }
const IMedQuotationDetails = (props: IProps) => {
  const {
    history,
    quotationManagement: { quotation, collections },
    setQuotationOccupationList,
  } = props;

  const [updatedModels, setModels] = useState(models as IObject);

  // console.log('collections', collections);
  // console.log('quotation', quotation);

  useEffect(() => {
    if (objLib.isEmpty(quotation) === false) {
      setModels(util.applyQuestionNumber(util.tryAddFemaleDisorderAndNumberOfCigarette(quotation, models, fields)));
    }
  }, [quotation]);

  if (objLib.isEmpty(quotation)) return <Loader />;
  return (
    <BaseQuotationDetails
      setOccupationList={setQuotationOccupationList}
      fields={fields}
      models={{ ...updatedModels, sidebar: sidebarModel.quotation.iMedGbsn }}
      history={history}
      quotation={quotation}
      collections={collections}
      defaultCountry={products.gbsnIMed.defaultValues.country}
    />
  );
};

export default connect(stateToProps, dispatchToProps)(IMedQuotationDetails);
