import React from "react";
const notification = props => {
  const { toggleNotification, notificationMessage } = props;
  // const statusClass = notificationStatus === "error" ? "danger" : "success" ? "success" : "secondary";
  return (
    <div className="shadow-sm d-flex m-5 p-3 password-notification" >
      {/* <i
        className={`
                p-1
                ${
                  statusClass === "danger"
                    ? "icon-errorOutline"
                    : "icon-checkedOutline"
                }
            `}
      /> */}
      <span>{notificationMessage} </span>
      <i
        className="icon-error cursor-pointer"
        onClick={toggleNotification}
      />
    </div>
  );
};
export default notification;
