
import env from 'env';
import Request from './request';
import Response from './response';

class GetQuoteService implements IGetQuoteService {
  protected api = 'quotations';

  protected env = env.quotation;

  protected withApi = 'with-collections';

  // quotations/init/with-collections?productCode=2036273
  public async init(productCode: number): Promise<any> {
    const url = `${this.env}/${this.api}/init/${this.withApi}?productCode=${productCode}`;
    const request = new Request(null);
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  // quotations/290160/next
  public async next(id: number, body: object): Promise<any> {
    const url = `${this.env}/${this.api}/${id}/next`;
    const request = new Request(body, 'PUT');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  // quotations/290161/prev
  public async previous(id: number, body: object): Promise<any> {
    const url = `${this.env}/${this.api}/${id}/prev`;
    const request = new Request(body, 'PUT');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  // quotations/291188/with-collections
  public async get(id: number): Promise<any> {
    const url = `${this.env}/${this.api}/${id}/${this.withApi}`;
    const request = new Request(null);
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
}

export default GetQuoteService;
