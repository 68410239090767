import React from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";

const FilterTypesCol = (props) => {
  const {
    filterOptions, activeIndex,
    setActiveIndex, getFilterIndexById,
  } = props;
  return (
    <Col sm="3" className="border-top-0 border-bottom-0 border-left-0 border d-flex flex-column py-3">
      {filterOptions && filterOptions.map((filter) => {
        const index = getFilterIndexById(filter.id);
        return (
          <button
            type="button"
            className={`filterTypes ${activeIndex === index ? "activeNav px-3 mb-4" : "px-3 mb-4"}`}
            key={filter.id}
            onClick={() => setActiveIndex(index)}
          >
            <i className={`icon-${filter.icon}`} />
            {filter.displayName}
          </button>
        );
      })}
    </Col>
  );
};
FilterTypesCol.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  getFilterIndexById: PropTypes.func.isRequired,
};

export default FilterTypesCol;
