import React from 'react';
import { connect } from 'react-redux';
import { objLib, numberLib } from '@xc-core/lib';
import config from '@xc-core/config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';


interface IPlanButtonProps {
  type: string
  isSelected: boolean
  value: string
  onClick: () => void
}
const PlanButton = (props: IPlanButtonProps) => {
  
  const {
    type,
    isSelected,
    value,
    onClick,
  } = props;

  return (
    <Grid item xs={12} md={4} key={type}>
      <Card className={`${isSelected ? 'cardActive' : ''}`}>
        <CardActionArea
          onClick={onClick}
          data-set={1}
          type='button'
          autoFocus={isSelected}
        >
          <div
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              color: '#064e8c',
            }}
          >
            <i className={isSelected ? 'icon-checkedOutline font-size-normal' : ''} />
          </div>
          <CardContent className='d-flex flex-column p-4'>
            <h5>{type}</h5>
            <h3 className='text-primary font-weight-bold'>{value}</h3>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
interface ILoadableComponentPropsCBPlanSelection extends ILoadableComponentProps{
  storeItem: IObject,
}
const LoadablePlanSelectionComponent = (props: ILoadableComponentPropsCBPlanSelection) => {
  const {
    storeItem,
    model: {
      model: { forms },
      currentStep,
      selectedPlan,
    },
  } = props;
  const { fields: [planModel] } = forms;
  // const { properties: { Destination } } = storeItem;
  let priceList : IObject[] = [];
  if (storeItem && storeItem.properties && storeItem.properties.priceList) {
    priceList = storeItem.properties.priceList;
  }
  // const filterPrices = (): IObject[] => {
  //   const cambodiaPlans = ['Basic', 'Ultimate', 'Ultimate +'];
  //   if (Destination === 'Cambodia') {
  //     return priceList.filter((priceItem) => cambodiaPlans.includes(priceItem.Plan));
  //   }
  //   return priceList.filter((priceItem) => !cambodiaPlans.includes(priceItem.Plan));
  // };
  // if (priceList.length) priceList = filterPrices();

  const getType = (plan: IObject) => objLib.getValueWithLodash(plan, 'Plan');
  const getValue = (plan: IObject) => objLib.getValueWithLodash(plan, 'Main Policy');
  const formatPlanValue = (plan: IObject) => (
    `${config.currencySymbol} ${numberLib.applyFormat(getValue(plan), config.currencyFormat)}`);
  return (
    <Grid container spacing={24}>
      {priceList.map((priceItem: IObject) => (
        <PlanButton
          key={getType(priceItem)}
          isSelected={selectedPlan ? getType(priceItem) === selectedPlan : false}
          type={getType(priceItem)}
          value={formatPlanValue(priceItem)}
          onClick={() => planModel.handler(currentStep, priceItem)}
        />
      ))}
    </Grid>
  );
};
const mapStateToProps = ({ newGetQuote } : IObject) => ({
  storeItem: newGetQuote.item,
});

export default connect(mapStateToProps, () => ({}))(LoadablePlanSelectionComponent);
