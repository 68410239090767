
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { dateLib, objLib } from '@xc-core/lib';
import { defaultValues } from '../config';

const CustomerDetail = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
      initValues,
      defaults,
    },
  } = props;
  useEffect(() => {
    const country = objLib.getValueWithLodash(item, model.fields.country.id)
      || defaults.country;
    const startDate =   objLib.getValueWithLodash(item, model.fields.startDate.id)
      || dateLib.subtractFromNow(0, 0, 0);
    const endDate = objLib.getValueWithLodash(item, model.fields.endDate.id)
      || dateLib.subtractFromNow(0, 0, 0);
    const insuredType = objLib.getValueWithLodash(item, model.fields.insuredType.id)
      || defaultValues.insuredType;
    const adults = objLib.getValueWithLodash(item, model.fields.adults.id)  
      || 0;
    const children = objLib.getValueWithLodash(item, model.fields.children.id)  
      || 0;
    const seniorCitizen = objLib.getValueWithLodash(item, model.fields.seniorCitizen.id)  
      || 0;
    const noOfDays = objLib.getValueWithLodash(item, ['properties','No. of Days'])  
    || 1;

    const pairs: IPair[] = [
      { id: model.fields.country.id, value: country },
      { id: model.fields.dob.id, value: '' },
      { id: model.fields.startDate.id, value: startDate },
      { id: model.fields.endDate.id, value: endDate },
      { id: model.fields.insuredType.id, value: insuredType },
      { id: model.fields.adults.id, value: adults },
      { id: model.fields.children.id, value: children },
      { id: model.fields.seniorCitizen.id, value: seniorCitizen },
      { id: ['properties', 'No. of Days'], value: noOfDays },
      { id: ['properties', 'Other Insured'], value: []}
    ];
    initValues(pairs);
  }, []);

  const filterDestinations = (): IObject[] => {
    const destinations: string[] = ['Asia Pacific', 'Worldwide Excluding U.S. & Canada', 'Worldwide Including U.S. & Canada'];
    const destinationList: IObject[] = objLib.getValueWithLodash(collections, 'destinations');
    return destinationList.filter(dest=> destinations.includes(dest.code));
  };
  return (
    <Row>
      <Col md='12'>
        <h5 className='sub-title'>Please enter Trip information</h5>
        <RenderFields
          onChange={(pairs: IPair[]) => {onChange('onChange', { pairs });}}
          collections={collections}
          item={item}
          validationErrors={validationErrors}
          fields={model.tripDetail.fields}
        />
      </Col>
    </Row>
  );
};

export default CustomerDetail;
