import React from 'react';
import Title from './title';

interface IProps { policy: any, children?: any}

const PolicyDetailTitle = (props: IProps) => {
  const { policy, children } = props;
  return (
    <>
      <Title item={{ ...policy, displayName: 'Policy Number', displayId: policy ? policy.refCode : '' }}>
        {children}
        <p className='m-0'>{`Policy Type: ${policy? policy.policyState : ''}`}</p>
        <p className='m-0'>{`Status: ${policy ? policy.status : ''}`}</p>
      </Title>
    </>
  );
};

export default PolicyDetailTitle;
