const model = {
  body: {
    items: {
      main: {
        id: 'properties.Car Owner Details',
        items: [
          { id: 'properties.Car Owner Details.Full Name', name: 'Full Name', config: {} },
          { id: 'properties.Car Owner Details.Occupation', name: 'Occupation', config: {} },
          { id: 'properties.Car Owner Details.ID Type', name: 'ID Type', config: {} },
          { id: 'properties.Car Owner Details.ID Number', name: 'ID Number', config: {} },
          { id: 'properties.Car Owner Details.DOB', name: 'DOB', config: {} },
          { id: 'properties.Car Owner Details.Gender', name: 'Gender', config: {} },
          { id: 'properties.Car Owner Details.Marital Status', name: 'Marital Status', config: {} },
          { id: 'properties.Car Owner Details.Address', name: 'Address', config: {} },
          { id: 'properties.Car Owner Details.Postcode', name: 'Postcode', config: {} },
          { id: 'properties.Car Owner Details.City', name: 'City / Town', config: {} },
          { id: 'properties.Car Owner Details.State', name: 'State / Province / Region', config: {} },
          { id: 'properties.Car Owner Details.Email Address', name: 'Email Address', config: {} },
        ],
        config: {},
      },
      carDetails: {
        id: 'properties.Car Details',
        items: [
          { id: 'properties.Car Details.Vehicle Registration Number', name: 'Vehicle Registration Number', config: {} },
          { id: 'properties.Car Details.Make', name: 'Make', config: {} },
          { id: 'properties.Car Details.Model', name: 'Model', config: {} },
          { id: 'properties.Car Details.Manufacture Year', name: 'Manufacture Year', config: {} },
          { id: 'properties.Car Details.Engine CC', name: 'Engine CC', config: {} },
          { id: 'properties.Car Details.Transmission', name: 'Transmission', config: {} },
          { id: 'properties.Car Details.Variant', name: 'Variant', config: {} },
          { id: 'properties.Car Details.Vehicle details verified', name: 'Vehicle details verified', config: {} },
        ],
        config: {},
      },
    },
    config: {},
  },
};

export default model;
