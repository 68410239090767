import React from "react";
import { Col } from "reactstrap";
import Input from "react-toolbox/lib/input";
import PropTypes from "prop-types";

const Searchbar = (props) => {
  const { children, searchKeyword, handleSearchKeyword } = props;

  const inputChange = (obj) => {
    const { id, value } = obj;
    handleSearchKeyword({ id, value });
  };

  return (
    <div className="additionalTableHeader overflow-unset align-items-center py-1 d-flex px-1">
      <Col xs="8">
        <Input
          className="py-2 customSearchinput"
          type="text"
          label="Search"
          id="search"
          icon={<i className="icon-search" />}
          value={searchKeyword}
          onChange={(value) => inputChange({ id: "search", value })}
        />
      </Col>
      <Col xs="4" className="text-right">
        {children}
      </Col>
    </div>
  );
};

Searchbar.propTypes = {
  children: PropTypes.node,
  searchKeyword: PropTypes.string,
  handleSearchKeyword: PropTypes.func,
};
Searchbar.defaultProps = {
  children: <></>,
  searchKeyword: "",
  handleSearchKeyword: () => {},
};

export default Searchbar;

// searchBar = () => {
//   return (
//     <div className="additionalTableHeader overflow-unset align-items-center py-1 d-flex px-1">
//       <Col xs="8">
//         <Input
//           className="py-2 customSearchinput"
//           type="text"
//           label="Search"
//           icon={<i className="icon-search" />}
//           value={this.state.hint}
//           onChange={this.handleChange.bind(this, "hint")}
//         />
//       </Col>
//       <Col xs="4" className="text-right">
//         <ExportButton exportToCsv={this.handleExport.bind(this)} />
//       </Col>
//     </div>
//   )
// }

// <Col xs="2" className="text-right">
// <button
//   className="btn btn-primary pl-1 btn-sm btn-border-radius"
//   onClick={this.toggle}
// >
//   <i className="icon-add mx-2 font-size-small"/>
//   Add Organisation
// </button>
// </Col>
