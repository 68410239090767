import React from 'react';
import { Row, Col } from 'reactstrap';
import { objLib, fieldLib } from '@xc-core/lib';
import lib from 'utils/lib';

const Individual = (props: { model: IObject[], item: IObject, index: number, ext?: boolean }) => {
  const {
    model, item, index, ext,
  } = props;
  return (
    <ul className='list-group'>
      {model.map((m: any) => {
        const id = fieldLib.applyIndexToRawId(index, m.id);
        return (
          <li key={id} className='list-group-item d-flex justify-content-between border-0 px-0 py-1 grey-bg summary-label'>
            {m.label}
            {(lib.checkIsDemoSite()) && ((ext && m.label === 'Relationship') ? ' / ទំនាក់ទំនង' : m.ext)}
            <span className='col-5 p-0 wordBreak summary-label-2'>{objLib.getValueWithLodash(item, id)}</span>
          </li>
        );
      })}
    </ul>
  );
};

interface IDetailProp {
    model: {
        spouseModel: IObject[],
        insured: IObject[],
    },
    item: IObject,
    index: number,
}

const Pair = (props: IDetailProp) => {
  const { model, item, index } = props;
  const { spouse } = item.properties;
  return (
    <>
      <Row>
        <Col md='6'>
          <p className='custom-text-primary'>
            Insured Person Details
            {lib.checkIsDemoSite() && '/ ព័ត៌មានលម្អិតរបស់អ្នកត្រូវបានធានារ៉ាប់រង'}
          </p>
          <Individual model={model.insured} item={item} index={index} />
        </Col>
        <Col md='6'>
          {spouse && (
            <>
              <p className='custom-text-primary'>Spouse Details</p>
              <Individual model={model.spouseModel} item={item} index={index} />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const Detail = (props: {
    item: IObject,
    mainModel: IObject[], spouseModel: IObject[]
}) => {
  const {
    item,
    mainModel,
    spouseModel,
  } = props;
  return (
    <Pair
      index={0}
      item={item}
      model={{
        insured: mainModel,
        spouseModel,
      }}
    />
  );
};

export default Detail;
