/* eslint-disable object-curly-newline */

import lib from "utils/lib";


const fieldsModel: IFieldModel = {
  occupationClass: { id: 'properties.Occupation Class', label: 'Occupation Class', ext: ' / ចំណាត់ថ្នាក់ ឬមុខរបរ', type: 'Select', collectionId: 'occupationClass', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ សូមរើសជម្រើស' : ''}` },
  
  plan: { id: 'properties.Plan', label: 'Plan', ext: ' / គម្រោង', type: 'PlanButton', collectionId: 'priceList' },

  fullName: { id: 'properties.main applicant.Full Name', label: 'Full Name', ext: ' / ឈ្មោះពេញ', type: 'Input', placeholder: `Enter full name ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះពេញ' : ''}` },
  nationality: { id: 'properties.main applicant.Nationality', label: 'Nationality', ext: ' / សញ្ជាតិ', type: 'Select', collectionId: 'nationalities', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសសញ្ជាតិ' : ''}` },
  idType: { id: 'properties.main applicant.ID Type', label: 'ID Type', ext: ' / ប្រភេទអត្តសញ្ញាណបណ្ណ', type: 'Select', collectionId: 'idTypes', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសប្រភេទអត្តសញ្ញាណបណ្ណ' : ''}` },
  idNumber: { id: 'properties.main applicant.ID Number', label: 'ID Number', ext: ' / លេខអត្តសញ្ញាណបណ្ណ', type: 'Input', placeholder: `Enter ID number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខអត្តសញ្ញាណបណ្ណ' : ''}` },
  dob: { id: 'properties.main applicant.DOB', label: 'Date of Birth', ext: ' / ថ្ងៃខែឆ្នាំកំណើត', type: 'DatePicker', placeholder: `Select Date of Birth ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសថ្ងៃខែឆ្នាំកំណើត' : ''}` },
  gender: { id: 'properties.main applicant.Gender', label: 'Gender', ext: ' / ភេទ', type: 'Select', collectionId: 'genders', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសភេទ' : ''}` },

  address: { id: 'properties.main applicant.Address', label: 'Address', ext: ' / អាសយដ្ឋាន', type: 'Input', placeholder: `Enter address ${lib.checkIsDemoSite() ? '/ សូមបំពេញអាសយដ្ឋាន' : ''}` },
  country: { id: 'properties.main applicant.Country', label: 'Country', ext: ' / ប្រទេស', type: 'Select', collectionId: 'countries' },
  state: { id: 'properties.main applicant.State', label: 'City/Province', ext: ' / ខេត្តក្រុង', type: 'Select',collectionId:'cb_auto_states', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ សូមជ្រើសរើសខេត្តក្រុង' : ''}` },
  postCode: { id: 'properties.main applicant.Postcode', label: 'Postcode', ext: ' / លេខកូដតំបន់', type: 'Input', placeholder: `Enter postcode ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខកូដតំបន់' : ''}` },
  city: { id: 'properties.main applicant.City', label: 'State/Region', ext: ' / តំបន់', type: 'Input', placeholder: `Enter State/Region ${lib.checkIsDemoSite() ? '/ សូមបំពេញតំបន់' : ''}` },
  email: { id: 'properties.main applicant.Email Address', label: 'Email Address', ext: ' / អាសយដ្ឋានអ៊ីមែល', type: 'Input', placeholder: `Enter email address ${lib.checkIsDemoSite() ? '/ សូមបំពេញអាសយដ្ឋានអ៊ីមែល' : ''}` },
  contactNo: { id: 'properties.main applicant.Contact Number', label: 'Contact Number', ext: ' / លេខទូរសព្ទ័ទំនាក់ទំនង', type: 'Input', placeholder: `Enter contact number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខទូរសព្ទ័ទំនាក់ទំនង' : ''}` },

  beneficiary: { id: 'properties.beneficiaries', label: '', type: 'array' },
  beneficiaryName: { id: 'properties.beneficiaries.Full Name', label: 'Full Name', ext: ' / ឈ្មោះពេញ', type: 'Input', placeholder: `Enter full name ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះពេញ' : ''}` },
  beneficiaryRelation: { id: 'properties.beneficiaries.Relationship', label: 'Relationship', ext: ' / ទំនាក់ទំនងជាមួយអ្នកត្រូវបានធានារ៉ាប់រង', type: 'Select', collectionId: 'relations', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសប្រភេទទំនាក់ទំនង' : ''}` },
  beneficiaryIdType: { id: 'properties.beneficiaries.ID Type', label: 'ID Type', ext: ' / ប្រភេទអត្តសញ្ញាណបណ្ណ', type: 'Select', collectionId: 'idTypes', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសប្រភេទអត្តសញ្ញាណបណ្ណ' : ''}` },
  beneficiaryIdNumber: { id: 'properties.beneficiaries.ID Number', label: 'ID Number', ext: ' / លេខអត្តសញ្ញាណបណ្ណ', type: 'Input', placeholder: `Enter ID number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខអត្តសញ្ញាណបណ្ណ' : ''}` },
  beneficiaryEmail: { id: 'properties.beneficiaries.Email', label: 'Email Address', ext: ' / អាសយដ្ឋានអ៊ីមែល', type: 'Input', placeholder: `Enter email address ${lib.checkIsDemoSite() ? '/ សូមបំពេញអាសយដ្ឋានអ៊ីមែល' : ''}` },
  beneficiaryPhone: { id: 'properties.beneficiaries.Contact Number', label: 'Contact Number', ext: ' / លេខទូរសព្ទ័ទំនាក់ទំនង', type: 'Input', placeholder: `Enter contact number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខទូរសព្ទ័ទំនាក់ទំនង' : ''}` },

  paymentMode: { id: 'properties.Payment Mode', label: 'Mode of Payment', ext: ' / មធ្យោបាយនៃការបង់ប្រាក់', type: 'Select', collectionId: 'paymentMethod', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសមធ្យោបាយនៃការបង់ប្រាក់' : ''}` },
  receiptNo: { id: 'properties.Receipt No', label: 'Receipt No', ext: ' / លេខវិក្កយប័ត្រ', type: 'Input', placeholder: `Enter receipt no ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខវិក្កយប័ត្រ' : ''}` },
  paymentReceivedBy: { id: 'properties.Payment Received By', label: 'Payment Received By', ext: ' / ធ្វើការទូទាត់ដោយ', type: 'Select', collectionId: 'currentOuUsers', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសជម្រើសនៃការទូទាត់' : ''}` },
};

const mainDetailModel = [
  fieldsModel.fullName,
  fieldsModel.nationality,
  fieldsModel.idType,
  fieldsModel.idNumber,
  fieldsModel.dob,
  fieldsModel.gender,
  fieldsModel.address,
  fieldsModel.city,
  fieldsModel.state,
  fieldsModel.country,
  fieldsModel.postCode,
  fieldsModel.email,
  fieldsModel.contactNo,
];

const beneficiaryDetailModel = [
  fieldsModel.beneficiaryName,
  fieldsModel.beneficiaryRelation,
  fieldsModel.beneficiaryIdType,
  fieldsModel.beneficiaryIdNumber,
  fieldsModel.beneficiaryEmail,
  fieldsModel.beneficiaryPhone,
];

const basicDetail = [
  fieldsModel.occupationClass,
];

const model = { fieldsModel, mainDetailModel, beneficiaryDetailModel, basicDetail };

export default model;
