import React from 'react';
import { Row, Col } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import Detail from './detail';
import lib from 'utils/lib';

const Summary = (props: ILoadableComponentProps) => {
  const {
    model: {
      item,
      model,
    },
  } = props;
  if(item.properties && item.properties.beneficiaries && item.properties.beneficiaries.length>0 ) {
      item.properties.beneficiaries = item.properties.beneficiaries[0];
  }

  return (
    <Row>
      <Col md='8'>
        <Detail
          item={item}
          mainModel={model.mainModel}
          beneficiaryModel={model.beneficiaryModel}
        />
      </Col>
      <Col md='4'>
        <SidebarCard.Quotation
          customIconProduct={"icon-hospital"}
          item={item}
          model={sidebarModel.quotation.medicalInsurance}
          ext={lib.checkIsDemoSite()}
        />
      </Col>
    </Row>
  );
};

export default Summary;
