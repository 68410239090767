import Notification from 'react-notification-system-redux';
import {userGroupsConstants} from "../constants";
import {userGroupServices} from "../services";
import {getNotificationMessage} from "../../../_services";

export const userGroupActions = {
  getList,
  createGroup,
  getUserGrpupDetails,
  updateNotification,
  getCreateGroupDetail,
  updateGroupDetails,
  deleteUserGroup
};

function updateList(list, type, notification) {
  return {
    type: type,
    list,
    notification
  };
}

function updateNotification() {
  return {
    type: userGroupsConstants.GRP_REMOVE_NOTIFICATION,
    notification: ""
  };
}

function getList() {
  return async dispatch => {
    const list = await userGroupServices.getUserGroupsList();
    if (list.message === "Forbidden") return dispatch({type: userGroupsConstants.SET_FORBIDDEN_USER_GROUPS});
    if (list.error) return dispatch(Notification.error(getNotificationMessage('An error occurred in getting user groups.')));
    dispatch(updateList(list, userGroupsConstants.GET_GROUP_LIST, ""));
  };
}

function createGroup(data) {
  return async (dispatch) => {
    const response = await userGroupServices.createUserGroups(data);
    if (response.error) {
      return false;
    }
    dispatch(Notification.success(getNotificationMessage("User group created successfully.")));
    dispatch({ type: userGroupsConstants.CREATE_GROUP });
    return true;
  };
}

function getCreateGroupDetail() {
  return dispatch => {
    userGroupServices.getCreateGroupDetails().then(list => {
      !list.error && dispatch(updateState(list));
    });
  };

  function updateState(data) {
    return {
      type: userGroupsConstants.GET_CREATE_GROUP_DETAILS,
      data
    };
  }
}

function getUserGrpupDetails(name) {
  return async dispatch => {
    const data = await userGroupServices.getGroupDetails(name);
    if (!data.error) dispatch({
      type: userGroupsConstants.GET_GROUP_DETAILS,
      data
    });
  };
}

function updateGroupDetails(name, data) {
  return async dispatch => {
    const response = await userGroupServices.updateUserGroupDetails(name, data);
    if(response.error){
      return response
    }
    if (response.ok) {
      dispatch(Notification.success(getNotificationMessage('User group updated successfully.')));
      return dispatch({type: userGroupsConstants.UPDATE_GROUPS_DETAILS});
    }
  };
}

async function deleteUserGroup(name) {
  return await userGroupServices.deletedUserGroup(name);
}