import BaseReducer from "./base";
import actionTypes from '@redux/actionTypes';
import {objLib, dateLib, listLib} from '@xc-core/lib';
import config from '@xc-core/config';

class DistributionControlReducer extends BaseReducer<any> {
    
    public initialState: IObject;
    public actions: IDictionary<(state: IListState, action: IAction) => object>;
    protected actionTypes: IObject;

    constructor() {
        super();
        this.actionTypes = actionTypes.distributionControl;
        this.initialState = {
          pagination : {},
          isLoading: true,
          isEditMode: false,
          searchKeyword: '',
          limitEditIndex: null,
          filters: [],
          prevFilters: [],
          orgList:[],
          orgDetails: {},
          collections: {},
          filterOptions: [],
          limitEditItem:{},
          limitEditIsNew: false
        };
        this.actions = this.createActions();
    
    }  
    createActions = (): IDictionary<(state: IListState, action: IAction) => IListState> => ({

        [this.actionTypes.GET_LIST]: this.getList,
        [this.actionTypes.GET_DETAILS]: this.getDetails,
        [this.actionTypes.TOGGLE_EDIT_LIMIT]: this.toggleEdit,
        [this.actionTypes.DELETE_LIMIT]: this.deleteLimit,
        [this.actionTypes.ADD_LIMIT]: this.addLimit,
        [this.actionTypes.SAVE_LIMIT_SETTINGS]: this.saveLimitsSettings,
        [this.actionTypes.HANDLE_CHANGE_LIMIT]: this.handleChange,
        [this.actionTypes.SET_DISTRIBUTION_LOADING]: this.setLoading,
        [this.actionTypes.LIMIT_RESET_ORG_DETAILS]: this.resetOrgDetails,
        


      })
      
      private getList = (state: IListState, action: IAction) =>{
        let pagination = {
          empty: action.payload.list.empty, 
          first: action.payload.list.first,
          last: action.payload.list.last,
          totalPages: action.payload.list.totalPages,
          numberOfElements: action.payload.list.numberOfElements,
          totalElements: action.payload.list.totalElements,
          pageSize : action.payload.list.pageable.pageSize,
          pageNumber : action.payload.list.pageable.pageNumber,
        };
        let orgList = action.payload.list.content.map((item:IObject)=>{
          return {
            updatedOn: item.common.updatedOn ? dateLib.applyFormat(item.common.updatedOn, config.dateTimeMomentFormat) : '',
            orgUnitName: item.orgUnitName,
            orgUnitType: item.orgUnitType,
            orgUnitCode: item.orgUnitCode,
            status: (item.inheritLimitsFromParent && item.parentStatus) ? 'Yes' : (item.status ? 'Yes' : 'No')
          }
        })
        return {
          ...state,
          pagination,
          isLoading: false,
          orgList: orgList,
          filters: action.payload.filters ? action.payload.filters : state.filters,
          prevFilters: action.payload.filters ? action.payload.filters : state.prevFilters,
        };
      };

      private getDetails = (state: IListState, action: IAction) => {
        return{
          ...state,
          orgDetails: {
            ...action.payload['policyLimitConfig'],
            limits: this.setLimitsDates(this.sortLimits(action.payload['policyLimitConfig'].limits))
          },
          collections: this.prepareCollections(action.payload['collections']),
          isLoading: false,
        }
      }

      private toggleEdit = (state: IListState, action: IAction) => {
        // const lastRecord = state.records[state.records.length - 1];
        // let records = [...state.records];
        // if (lastRecord && !lastRecord.id) {
        //   records = [...records.slice(0, records.length - 1)];
        // }
        let limits = [...state.orgDetails.limits];
        if(action.payload.limitEditIsNew){
          limits.pop()
        }
        return ({
          ...state,
          orgDetails:{
            ...state.orgDetails,
            limits: limits
          },
          limitEditItem: {},
          limitEditIndex: action.payload.index,
          isEditMode: !state.isEditMode,
          limitEditIsNew: false
        });
      };

      private deleteLimit = (state: IListState, action: IAction) => {
        let limits = state.orgDetails.limits.filter((item:IObject,index:number)=>{
          return index === action.payload.index ? false : true
        })

        if(action.payload.limitEditIsNew){
          limits.pop();
        }

        return ({
          ...state,
          orgDetails:{
            ...state.orgDetails,
            limits: limits
          },
          limitEditIndex: null,
          isEditMode: false,
          limitEditIsNew: false,
          limitEditItem: {}
        });
      }

      private addLimit = (state: IListState, action: IAction) => {
        let limits = [...state.orgDetails.limits];
        limits = [...limits,{}]
        return ({
          ...state,
          isEditMode: true,
          orgDetails:{
            ...state.orgDetails,
            limits: limits
          },
          limitEditIndex: state.orgDetails.limits.length,
          limitEditIsNew : true
        });
      };

      private saveLimitsSettings = (state: IListState, action: IAction) => {
        return ({
          ...state,
            limitEditItem:{},
            isEditMode: false,
            limitEditIndex: null,
            orgDetails: {
              ...action.payload,
              limits: this.setLimitsDates(this.sortLimits(action.payload.limits))
            },
            limitEditIsNew: false

        })
        
      };

      private handleChange = (state: IListState, action: IAction) => {
        const { id, value } = action.payload.pair;
        let newState = objLib.setValueWithLodash(state,id,value)
        return ({
          ...newState,
        });
      };

      private resetOrgDetails = (state:IListState, action: IAction) =>{
        return ({
          ...state,
            limitEditIndex: null,
            isEditMode: false,
            limitEditItem: {},
            limitEditIsNew: false,
            orgDetails: {}

        })
      }

      private prepareCollections = (obj: IObject): IObject => {
        const collections: IObject = {};
        Object.keys(obj).forEach((key) => {
            if(key === "products"){
                collections[key] = listLib.extendWithIDValueForKeys(obj[key], ['productName', 'productName']);
            } else {
              collections[key] = obj[key]
            }
        });
        return collections;
      }

      protected setLoading = (state: IListState) =>{
        return ({
          ...state,
          isLoading: true,
       })
      }
      
      private sortLimits = (limits: IObject[]) => {

        return limits.sort((a:IObject,b:IObject)=>{
          // @ts-ignore
          return (a.startDate && dateLib.toDate(a.startDate,dateLib.config.dateMomentFormat)) > (b.startDate && dateLib.toDate(b.startDate,dateLib.config.dateMomentFormat)) ? 1 : -1;
        })
      }

      private setLimitsDates = (limits: IObject[]) => {
        return limits.map((item:IObject)=>{
          return{
            ...item,
            common:{
              ...item.common,
              createdOn: dateLib.applyFormat(item.common.createdOn, config.dateTimeMomentFormat),
              updatedOn: dateLib.applyFormat(item.common.updatedOn, config.dateTimeMomentFormat)  
            }
          }
        })
      }

}

export default new DistributionControlReducer();