import React from 'react';
import { connect } from 'react-redux';
import {
  objLib, fieldLib,
  dateLib, stringLib,
} from '@xc-core/lib';
import { userMessages } from '@xc-core/constants';
import { Message, Loader } from '@xc-core/components/core';
import BaseWizard from '@containers/getQuote/wizard/baseWizard';
import RenderWizard from '@containers/getQuote/wizard/renderWizard';
import { ComponentLoader } from '@xc-core/components';
import { GetQuoteActions, PolicyActions } from '@redux/actions';
import config from '@xc-core/config';
import * as repo from './components';
import { model, defaultValues } from './config';

class AlfalahWalletWizard extends BaseWizard<IBaseWizardProps, IBaseWizardState> {
  protected defaultCountry = defaultValues.country;

  protected stateListParam = defaultValues.stateListParam;

  protected model = model;

  private CNICLength = defaultValues.cnicLength;

  private PostCodeLength = defaultValues.postcodeLength;

  private DebitCreditNoLength = defaultValues.debitCreditNoLength;

  private ContactNumberLength = defaultValues.contactNumberLength;

  constructor(props: IBaseWizardProps) {
    super(props);
    this.state = {
      sendingRequest: false,
      invalidForm: false,
      item: {},
      validationErrors: [],
    };
  }

  protected prepareSteps = (steps: IStep[]): IStep[] => (steps.map((step: IStep) => {
    const metadata = this.getStepMetadata(step.order);
    return {
      ...step,
      componentType: metadata.componentType,
      formContainer: metadata.formContainer,
    };
  }))

  protected getStepMetadata = (order: number): { componentType: string,
    formContainer: IFormContainer } => {
    switch (order) {
      case 1:
        return {
          componentType: 'PlanSelection',
          formContainer: {
            title: '',
            forms: [this.createPlanSelectionForm()],
          },
        };
      case 2:
        return {
          componentType: 'CustomerDetail',
          formContainer: {
            title: 'Please enter customer details',
            mainDetail: this.createMainInsuredFormModel(),
            contactDetail: this.createContactDetailFormModel(),
            fields: this.model.fieldsModel,
          },
        };
      case 3:
        return {
          componentType: 'BeneficiaryDetail',
          formContainer: {
            title: 'Please enter beneficiary details',
            beneficiaryDetail: this.createBeneficiaryFormModel(),
            fields: this.model.fieldsModel,
          },
        };
      case 4:
        return {
          componentType: 'Summary',
          formContainer: {
            title: '',
            mainModel: model.mainDetailModel,
            beneficiaryModel: model.beneficiaryDetailModel,
          },
        };
      case 5:
        return {
          componentType: 'PaymentDetail',
          formContainer: {
            title: '',
            paymentDetail: this.createPaymentFormModel(),
          },
        };
      case 6:
        return {
          componentType: 'ThankyouPage',
          formContainer: {
            title: '',
          },
        };
      default:
        return {
          componentType: '',
          formContainer: {
            title: '',
          },
        };
    }
  }

  protected handleMainIdType = (pair: IPair, item: IObject): IPair[] => {
    let idNumber = objLib.getValueWithLodash(item, model.fieldsModel.idNumber.id);
    const idType = objLib.getValueWithLodash(item, pair.id);
    if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed

    return [pair, { id: model.fieldsModel.idNumber.id, value: idNumber }];
  }

  private getMaxDob = (): string => {
    const { minAge } = defaultValues;
    return dateLib.subtractFromNow(minAge.years, minAge.months, minAge.days);
  }

  private handlePaymentMode = (pair: IPair, item: IObject): IPair[] => {
    let receivedByValue = objLib.getValueWithLodash(item, model.fieldsModel.paymentReceivedBy.id);
    if (!receivedByValue) {
      const { user } = config;
      receivedByValue = user.name;
    }
    return [pair, { id: model.fieldsModel.paymentReceivedBy.id, value: receivedByValue }];
  }

  private handleMainIdNumber = (pair: IPair, item: IObject): IPair[] => {
    let { value } = pair;
    let gender = objLib.getValueWithLodash(item, model.fieldsModel.gender.id) || '';
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const idType = objLib.getValueWithLodash(item, model.fieldsModel.idType.id);
    if (idType === 'CNIC') {
      value = value.length > this.CNICLength ? value.slice(0, this.CNICLength) : value;
      value = this.restrictToNumber(value, preValue);
      gender = value && value.length === this.CNICLength
        ? this.getGenderFromValue(value[this.CNICLength - 1]) : gender;
    } else {
      value = this.restrictToAlphanumeric(value, preValue);
    }
    return [{ id: pair.id, value }, { id: model.fieldsModel.gender.id, value: gender }];
  }

  private handleBeneficiaryIdNumber = (pair: IPair, item: IObject): IPair[] => {
    let { value } = pair;
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const idTypeId = fieldLib.applyIndexToRawId(fieldLib.getIndex(pair.id),
      model.fieldsModel.beneficiaryIdType.id);
    const idType = objLib.getValueWithLodash(item, idTypeId);
    if (idType === 'CNIC') {
      value = value.length > this.CNICLength ? value.slice(0, this.CNICLength) : value;
      value = this.restrictToNumber(value, preValue);
    } else {
      value = this.restrictToAlphanumeric(value, preValue);
    }
    return [{ id: pair.id, value }];
  }

  protected handleBeneficiaryIdType = (pair: IPair, item: IObject): IPair[] => {
    const idNumberId = fieldLib.applyIndexToRawId(fieldLib.getIndex(pair.id),
      model.fieldsModel.beneficiaryIdNumber.id);
    let idNumber = objLib.getValueWithLodash(item, idNumberId);
    const idType = objLib.getValueWithLodash(item, pair.id);
    if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed

    return [pair, { id: idNumberId, value: idNumber }];
  }

  protected handlePostcode = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    value = value.length > this.PostCodeLength ? value.slice(0, this.PostCodeLength) : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  }

  protected handleDebitCreditNo = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    value = value.length > this.DebitCreditNoLength
      ? value.slice(0, this.DebitCreditNoLength) : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  }

  protected handleContactNumber = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    value = value.length > this.ContactNumberLength
      ? value.slice(0, this.ContactNumberLength) : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  }

  private createPlanSelectionForm = (): IForm => {
    const title = '';
    const plan: IField = {
      id: model.fieldsModel.plan.id,
      label: model.fieldsModel.plan.label,
      type: model.fieldsModel.plan.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.plan.collectionId,
    };
    return { getTitle: () => title, fields: [plan] };
  }

  private createMainInsuredFormModel = (): IForm => {
    const title = 'Main Insured Details';
    const fullName: IField = {
      id: model.fieldsModel.fullName.id,
      label: model.fieldsModel.fullName.label,
      type: model.fieldsModel.fullName.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.fullName.placeholder,
    };
    const nationality: IField = {
      id: model.fieldsModel.nationality.id,
      label: model.fieldsModel.nationality.label,
      type: model.fieldsModel.nationality.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      collectionId: model.fieldsModel.nationality.collectionId,
      disabled: this.defaultFieldDisabled,
    };
    const idType: IField = {
      id: model.fieldsModel.idType.id,
      label: model.fieldsModel.idType.label,
      type: model.fieldsModel.idType.type,
      style: this.defaultFieldStyle,
      handler: this.handleMainIdType,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.idType.collectionId,
    };
    const idNumber: IField = {
      id: model.fieldsModel.idNumber.id,
      label: model.fieldsModel.idNumber.label,
      type: model.fieldsModel.idNumber.type,
      style: this.defaultFieldStyle,
      handler: this.handleMainIdNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.idNumber.placeholder,
    };
    const dob: IField = {
      id: model.fieldsModel.dob.id,
      label: model.fieldsModel.dob.label,
      type: model.fieldsModel.dob.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      getMax: () => this.getMaxDob(),
      // current: this.getMaxDob(),
      placeholder: model.fieldsModel.dob.placeholder,
    };
    const gender: IField = {
      id: model.fieldsModel.gender.id,
      label: model.fieldsModel.gender.label,
      type: model.fieldsModel.gender.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.gender.collectionId,
    };
    const debitCreditNo: IField = {
      id: model.fieldsModel.debitCreditNo.id,
      label: model.fieldsModel.debitCreditNo.label,
      type: model.fieldsModel.debitCreditNo.type,
      style: this.defaultFieldStyle,
      handler: this.handleDebitCreditNo,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.debitCreditNo.placeholder,
    };
    return {
      getTitle: () => title,
      fields: [fullName, nationality, idType, idNumber, dob, gender, debitCreditNo],
    };
  }

  private createContactDetailFormModel = (): IForm => {
    const title = 'Contact Details';
    const address: IField = {
      id: model.fieldsModel.address.id,
      label: model.fieldsModel.address.label,
      type: model.fieldsModel.address.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.address.placeholder,
    };
    const country: IField = {
      id: model.fieldsModel.country.id,
      label: model.fieldsModel.country.label,
      type: model.fieldsModel.country.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: () => true,
      collectionId: model.fieldsModel.country.collectionId,
      // defaultValue: this.defaultCountry,
    };
    const state: IField = {
      id: model.fieldsModel.state.id,
      label: model.fieldsModel.state.label,
      type: model.fieldsModel.state.type,
      collectionId: model.fieldsModel.state.collectionId,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
    };
    const postCode: IField = {
      id: model.fieldsModel.postCode.id,
      label: model.fieldsModel.postCode.label,
      type: model.fieldsModel.postCode.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.postCode.placeholder,
    };
    const city: IField = {
      id: model.fieldsModel.city.id,
      label: model.fieldsModel.city.label,
      type: model.fieldsModel.city.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.city.placeholder,
    };
    const email: IField = {
      id: model.fieldsModel.email.id,
      label: model.fieldsModel.email.label,
      type: model.fieldsModel.email.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.email.placeholder,
    };
    const contactNo: IField = {
      id: model.fieldsModel.contactNo.id,
      label: model.fieldsModel.contactNo.label,
      type: model.fieldsModel.contactNo.type,
      style: this.defaultFieldStyle,
      handler: this.handleContactNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.contactNo.placeholder,
    };
    return {
      getTitle: () => title,
      fields: [address, country, state, postCode, city, email, contactNo],
    };
  }

  private createBeneficiaryFormModel = (): IForm => {
    const beneficiaryName: IField = {
      id: model.fieldsModel.beneficiaryName.id,
      label: model.fieldsModel.beneficiaryName.label,
      type: model.fieldsModel.beneficiaryName.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.beneficiaryName.placeholder,
    };
    const beneficiaryRelation: IField = {
      id: model.fieldsModel.beneficiaryRelation.id,
      label: model.fieldsModel.beneficiaryRelation.label,
      type: model.fieldsModel.beneficiaryRelation.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.beneficiaryRelation.collectionId,
    };
    const beneficiaryIdType: IField = {
      id: model.fieldsModel.beneficiaryIdType.id,
      label: model.fieldsModel.beneficiaryIdType.label,
      type: model.fieldsModel.beneficiaryIdType.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.beneficiaryIdType.collectionId,
    };
    const beneficiaryIdNumber: IField = {
      id: model.fieldsModel.beneficiaryIdNumber.id,
      label: model.fieldsModel.beneficiaryIdNumber.label,
      type: model.fieldsModel.beneficiaryIdNumber.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.beneficiaryIdNumber.placeholder,

    };
    const beneficiaryEmail: IField = {
      id: model.fieldsModel.beneficiaryEmail.id,
      label: model.fieldsModel.beneficiaryEmail.label,
      type: model.fieldsModel.beneficiaryEmail.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.beneficiaryEmail.placeholder,
    };
    const beneficiaryPhone: IField = {
      id: model.fieldsModel.beneficiaryPhone.id,
      label: model.fieldsModel.beneficiaryPhone.label,
      type: model.fieldsModel.beneficiaryPhone.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.beneficiaryPhone.placeholder,
    };
    return {
      getTitle: () => 'beneficiary form',
      fields: [beneficiaryName, beneficiaryRelation, beneficiaryIdType,
        beneficiaryIdNumber, beneficiaryEmail, beneficiaryPhone],
    };
  }

  private createPaymentFormModel = (): IForm => {
    const paymentMode: IField = {
      id: model.fieldsModel.paymentMode.id,
      label: model.fieldsModel.paymentMode.label,
      type: model.fieldsModel.paymentMode.type,
      style: { col: '12' },
      handler: this.handlePaymentMode,
      disabled: this.defaultFieldDisabled,
      collectionId: this.model.fieldsModel.paymentMode.collectionId,
    };
    const receiptNo: IField = {
      id: model.fieldsModel.receiptNo.id,
      label: model.fieldsModel.receiptNo.label,
      type: model.fieldsModel.receiptNo.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.receiptNo.placeholder,
    };
    const paymentReceivedBy: IField = {
      id: model.fieldsModel.paymentReceivedBy.id,
      label: model.fieldsModel.paymentReceivedBy.label,
      type: model.fieldsModel.paymentReceivedBy.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.paymentReceivedBy.collectionId,
    };
    const cc: IField = {
      id: model.fieldsModel.cc.id,
      label: model.fieldsModel.cc.label,
      type: model.fieldsModel.cc.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
    };
    const bcc: IField = {
      id: model.fieldsModel.bcc.id,
      label: model.fieldsModel.bcc.label,
      type: model.fieldsModel.bcc.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
    };
    return {
      getTitle: () => 'payment details',
      fields: [paymentMode, receiptNo, paymentReceivedBy, cc, bcc],
    };
  }

  private handlePlan = async (step: IStep, plan: IObject) => {
    const { getQuote, getItemOnNext } = this.props;
    if (getQuote.product && getQuote.collections) {
      const sourceObj = {
        priceList: getQuote.collections.priceList,
        plan,
      };
      let { properties } = getQuote.item;
      getQuote.product.steps[step.order - 1].properties.forEach((property) => {
        properties = {
          ...properties,
          [property.name]: objLib.getValueWithLodash(sourceObj, property.valueId),
        };
      });
      this.setState((prevState: IBaseWizardState) => ({
        ...prevState,
        item: {
          ...prevState.item,
          properties: {
            ...prevState.item.properties,
            ...properties,
          },
        },
      }));
      const updatedItem = await getItemOnNext(getQuote.product.id, getQuote.item.id, properties);
      await this.updateStateItem(updatedItem);
      this.setValues([{ id: model.fieldsModel.country.id, value: this.defaultCountry }]);
    }
  }

  // setDefaultValues = (pairs: IPair[]): void => {
  //   console.log('hello from setDefaultValues');
  // }
  isPaymentOrEmailsSelected = () => {
    const { item } = this.state;
    const { fieldsModel } = this.model;
    return !stringLib.isEmpty(objLib.getValueWithLodash(item, fieldsModel.paymentReceivedBy.id))
      || !stringLib.isEmpty(objLib.getValueWithLodash(item, fieldsModel.cc.id))
      || !stringLib.isEmpty(objLib.getValueWithLodash(item, fieldsModel.bcc.id));
  }

  render() {
    const { getQuote, getQuote: { collections, product, steps } } = this.props;
    const {
      item, validationErrors, sendingRequest, invalidForm,
    } = this.state;
    const currentStep = this.getCurrentStep();
    const paymentSelected = this.isPaymentOrEmailsSelected();
    // if (getQuote.error) return <Message message={userMessages.ERROR} />;
    if (product === null) return <Message message={userMessages.ERROR} />;
    if (collections === null || steps.length === 0) return <Loader />;

    const isBeneficirayEnterd = true;
    return (
      <RenderWizard
        getQuote={getQuote}
        getCurrentStep={this.getCurrentStep}
        handleNext={this.next}
        handlePrev={this.previous}
        handleSkip={this.skip}
        paymentSelected={paymentSelected}
        isBeneficirayEnterd={isBeneficirayEnterd}
        sendingRequest={sendingRequest}
        invalidForm={invalidForm}
        // properties={properties}
        // handlePlan={this.planHandler}
        // handleOnChange={this.onChange}
      >
        {
          currentStep.order === 1 ? (
            <ComponentLoader
              name='PlanSelection'
              repo={repo}
              propsModel={{
                collections: collections ? collections.priceList : [],
                selectedPlan: objLib.getValueWithLodash(item, this.model.fieldsModel.plan.id),
                model: { typeId: 'Plan Type', priceId: 'Total' },
                handler: (plan: IObject) => this.handlePlan(currentStep, plan),
              }}
            />
          ) : (
            <ComponentLoader
              name={currentStep.componentType}
              repo={repo}
              propsModel={{
                onChange: (handler: string, obj: IHandledValue) => this[handler](obj),
                initValues: (pairs: IPair[]) => this.setValues(pairs),
                collections,
                item,
                model: currentStep.formContainer,
                // title: currentStep.formContainer.title,
                // formContainer: currentStep.formContainer, // .forms,
                validationErrors,
                defaults: { country: this.defaultCountry },
                product
              }}
            />
          )
        }
      </RenderWizard>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  getQuote: state.newGetQuote,
  // products: state.productList.list,
});

const getQuoteActions = new GetQuoteActions();
const policyActions = new PolicyActions();

const mapDispatchToProps = (dispatch: any) => ({
  init: (productCode: number, country: string,
    stateListParam: string) => dispatch(getQuoteActions.init(productCode, country, stateListParam)),
  setStateInCollections: (country: string,
    param: string) => dispatch(getQuoteActions.setStateInCollections(country, param)),
  setSteps: (steps: IStep[]) => dispatch(getQuoteActions.setSteps(steps)),
  getItemOnNext: (productCode: number, id: number,
    properties: IObject) => dispatch(getQuoteActions.getItemOnNext(productCode, id, properties)),
  getItemOnPrevious: (productCode: number,
    id: number) => dispatch(getQuoteActions.getItemOnPrevious(productCode, id)),
  downloadCertificate: (id: string) => dispatch(policyActions.download(id)),
  reset: () => dispatch(getQuoteActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlfalahWalletWizard);
