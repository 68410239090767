import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { objLib, listLib } from '@xc-core/lib';
import { Loader } from '@xc-core/components/core';
import MarketingInfoCard from './marketing-card';

import {
  BaseQuotationDetails,
  BaseQuotationTitleDetails,
  BaseQuotationSidebarDetails,
  BaseQuotationSectionDetails,
} from '@containers';

const Sections = (props: { models: any, quotation: IObject, children: JSX.Element }) => {
  const { quotation, children, models } = props;
  const leftModel = models.detailsMainModel;
  const rightModel = models.detailsHealthQuestionnaireModel;
  return (
    <>
      <BaseQuotationSectionDetails
        title='Adult 1 (Main Insured)'
        left={{ title: 'Insured Person Details', item: quotation, model: leftModel }}
        right={{ title: 'Health Questionnaire', item: quotation, model: rightModel }}
      />
      {children}
    </>
  );
};

interface IProps {
  setOccupationList: () => {}
  collections: IObject
  quotation: IObject
  history: any
  children?: JSX.Element
  models: IObject
  fields: IObject
  defaultCountry: string
}

const BaseQuotationDetail = (props: IProps) => {
  const {
    quotation,
    collections,
    history,
    models,
    children,
    fields,
    defaultCountry,
    setOccupationList,
  } = props;
  const [preparedQuotation, setPreparedQuotation] = useState({});
  // console.log('**BaseQuotationDetail**', quotation);

  const getCollectionValue = (id: string, list: IObject[]): string => {
    const code = objLib.getValueWithLodash(quotation, id);
    const obj = listLib.findByDynamicKey(list, 'code', code);
    return obj ? obj.name : '';
  };

  const prepareQuotationObj = (): any => {
    const obj = {
      ...quotation,
      [fields.quotation.country.id]: defaultCountry,
      [fields.quotation.religionName.id]:
        getCollectionValue(fields.quotation.religion.id,
          collections[fields.quotation.religion.collectionId]),
      [fields.quotation.address.id]:
        `${objLib.getValueFromPath(quotation, fields.quotation.address1.id)},
        ${objLib.getValueFromPath(quotation, fields.quotation.address2.id) || ''}`,
      [fields.quotation.stateName.id]:
        getCollectionValue(fields.quotation.state.id,
          collections[fields.quotation.state.collectionId]),
      [fields.quotation.businessName.id]:
        getCollectionValue(fields.quotation.business.id,
          collections[fields.quotation.business.collectionId]),
      [fields.quotation.occupationName.id]:
        getCollectionValue(fields.quotation.occupation.id,
          collections[fields.quotation.occupation.collectionId]),
      [fields.quotation.mobile.id]:
        `${objLib.getValueFromPath(quotation, fields.quotation.mobileCountryCode.id)}
        ${objLib.getValueFromPath(quotation, fields.quotation.mobileBody.id) || ''}`,
    };
    return obj;
  };

  useEffect(() => {
    if (objLib.getValueFromPath(collections, fields.quotation.occupation.collectionId)) {
      setPreparedQuotation(prepareQuotationObj());
    } else {
      setOccupationList();
    }
  }, [quotation, collections]);

  if (objLib.isEmpty(preparedQuotation)) return <Loader />;
  return (
    <>
      <BaseQuotationDetails
        quotation={preparedQuotation}
        title={<BaseQuotationTitleDetails quotation={preparedQuotation} />}
        sidebar={(
          <BaseQuotationSidebarDetails
            item={preparedQuotation}
            model={models.sidebar}
          />
          )}
        actionButton={quotation.state === 'Completed' ? <></> : (
          <div className='mb-3 text-right'>
            <Button
              className='customPrimarybtn'
              onClick={() => alert('Convert to Certificate')}
            >
              Convert to Certificate
            </Button>
          </div>
        )}
        marketingInfoCard={<MarketingInfoCard item={preparedQuotation} model={models.marketingInfoModel} />}
        history={history}
      >
        <Sections quotation={preparedQuotation} models={models}>
          {children ? <>{children}</> : <></>}
        </Sections>
      </BaseQuotationDetails>
    </>
  );
};

export default BaseQuotationDetail;
