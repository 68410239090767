import React, {ReactNode, useState} from 'react';
import {Button} from "reactstrap";
import { ModalUi } from '../../../../_components';
import Editor from 'react-simple-code-editor';
import './index.scss'

interface IProps{
    isOpen:boolean;
    toggleModal:()=>void;
    template?:ITemplateProps;
    onSave?:(jsonTemplate:string)=>void;
    errorMessages?:string[];
    onCodeChange?:(code:string)=>void;
    code:string;
    customButton?:ReactNode;
}

const CodeEditor=(props:IProps)=>{
    const {
        isOpen,
        toggleModal,
        template,
        onSave,
        errorMessages,
        onCodeChange,
        code,
        customButton
    } = props;

    return <ModalUi id={'editor-modal'} isOpen={isOpen} title={template?.title || ''} toggle={toggleModal}>
        <div className={'flex-container'}>
            <div className={'flex-container'}>
                <div className={'editor-container'}>
                    <Editor
                        value={code}
                        onValueChange={(value)=>{
                            onCodeChange && onCodeChange(value);
                        }}
                        highlight={json => json}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 14,
                            minHeight:400
                        }}
                    />
                </div>
                {errorMessages ?errorMessages.map((msg:string)=><span key={msg} className={'text-danger mt-1 ml-1'}>{msg || ''}</span>)  : null}
            </div>
            <div
                style={{
                    display: code ? 'flex' :'none',
                    flexDirection:'row',
                    columnGap:10,
                    alignSelf:'flex-end',
                }}
            >
                {customButton ? customButton : null}
                <Button
                    className={'btn-primary'}
                    onClick={()=>onSave && onSave(code)}
                    style={{
                        borderRadius:5,
                        width:'10em',
                        padding:'0.25rem 0rem',
                        alignSelf:'flex-end',
                        marginTop:10
                    }}
                    size={'sm'}
                >
                    Save
                </Button>
            </div>
        </div>
    </ModalUi>
}

export default CodeEditor;