export const usersConstants ={
  GET_USERS_LIST: "GET_USERS_LIST",
  GET_USERS_DETAILS: "GET_USERS_DETAILS",
  CREATE_USERS: "CREATE_USERS",
  UPDATE_USERS_LIST: "UPDATE_USERS_LIST",
  UPDATE_DETAILS: "UPDATE_DETAILS",
  USER_APPLY_FILTER: "USER_APPLY_FILTER",
  USER_REMOVE_NOTIFICATION: "USER_REMOVE_NOTIFICATION",
  SET_FORBIDDEN_USERS: "SET_FORBIDDEN_USERS",
  RESET_KEEP_FILTERS: "RESET_KEEP_FILTERS",
};