import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { objLib, listLib } from '@xc-core/lib';
import { Loader } from '@xc-core/components/core';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import dispatchToProps from '@containers/dispatches';
import CancelForm from 'Products/shared/policyListing/components/CancelForm';
import { store } from '_helpers';
import { SHOW_FORBIDDEN_MESSAGE } from '_constants';
import {
  BasePolicyDetails,
  BasePolicyTitleDetails,
  BasePolicySidebarDetails,
  BasePolicySectionDetails,
} from '@containers';
import {RemarksCard} from "../../@xc-core/components";
import PaymentCard from './payment-card';
import MarketingInfoCard from './marketing-card';

const Sections = (props: { models: any, policy: IObject, children: JSX.Element }) => {
  const { policy, children, models } = props;
  const leftModel = models.detailsMainModel;
  const rightModel = models.detailsHealthQuestionnaireModel;
  return (
    <>
      <BasePolicySectionDetails
        title='Adult 1 (Main Insured)'
        left={{ title: 'Insured Person Details', item: policy, model: leftModel }}
        right={{ title: 'Health Questionnaire', item: policy, model: rightModel }}
      />
      {children}
    </>
  );
};

interface IProps {
  collections: IObject
  policy: IObject
  history: any
  children?: JSX.Element
  models: IObject
  fields: IObject
  defaultCountry: string
  setOccupationList: () => {}
  reissuePolicy: (id: number) => void
  cancelPolicy: (id: number, properties: IObject) => void
  getReissuePermission: () => boolean
  showForbiddenModal: () => void
  cancelNotInEffectPermission: () => boolean
  cancelInEffectPermission: () => boolean
}


const BasePolicyDetail = (props: IProps) => {
  const {
    policy,
    collections,
    history,
    models,
    children,
    fields,
    defaultCountry,
    setOccupationList,
    reissuePolicy,
    getReissuePermission,
    showForbiddenModal,
  } = props;
  const [preparedPolicy, setPreparedPolicy] = useState({});
  const [isActionButtonsOpen, toggleActionButtons] = useState(false);
  const [cancelRemarkModalIsOpen, toggleCancelRemarkModalIsOpen] = useState(false);
  // console.log('**BaseQuotationDetail**', quotation);
  useEffect(() => {
    toggleCancelRemarkModalIsOpen(() => false);
  }, [policy.status]);

  const getCollectionValue = (id: string, list: IObject[]): string => {
    const code = objLib.getValueWithLodash(policy, id);
    const obj = listLib.findByDynamicKey(list, 'code', code);
    return obj ? obj.name : '';
  };

  const getPaymentMethodName = (value: string): string => {
    const methodCollections = [
      { id: 'CC', name: 'Credit Card (Online3D/Non3D)' },
      { id: 'MO', name: 'Credit Card (MOTO – Mail Order Telephone Order)' },
      { id: 'DD', name: 'Direct Debit (not applicable to TransactionType AUTH)' },
      { id: 'WA', name: 'e-Wallet (not applicable to Transaction Type AUTH)' },
      { id: 'OTC', name: 'Over The Counter (not applicable to TransactionType AUTH)' },
      { id: 'ANY', name: 'All payment method(s) registered with eGHL' },
    ];
    const obj = listLib.findByDynamicKey(methodCollections, 'id', value);
    return obj ? obj.name : '';
  };

  const preparePolicyObj = (): any => {
    const obj = {
      ...policy,
      [fields.policy.country.id]: defaultCountry,
      [fields.policy.religionName.id]:
        getCollectionValue(fields.policy.religion.id,
          collections[fields.policy.religion.collectionId]),
      [fields.policy.address.id]:
        `${objLib.getValueFromPath(policy, fields.policy.address1.id)},
          ${objLib.getValueFromPath(policy, fields.policy.address2.id) || ''}`,
      [fields.policy.stateName.id]:
        getCollectionValue(fields.policy.state.id,
          collections[fields.policy.state.collectionId]),
      [fields.policy.businessName.id]:
        getCollectionValue(fields.policy.business.id,
          collections[fields.policy.business.collectionId]),
      [fields.policy.occupationName.id]:
          getCollectionValue(fields.policy.occupation.id,
            collections[fields.policy.occupation.collectionId]),
      [fields.policy.paymentModeName.id]: getPaymentMethodName(objLib.getValueFromPath(policy, fields.policy.paymentMode.id)),
      [fields.policy.mobile.id]:
      `${objLib.getValueFromPath(policy, fields.policy.mobileCountryCode.id)}
      ${objLib.getValueFromPath(policy, fields.policy.mobileBody.id) || ''}`,
    };
    // console.log('preparePolicyObj', obj);
    return obj;
  };

  useEffect(() => {
    if (objLib.getValueFromPath(collections, fields.policy.occupation.collectionId)) {
      setPreparedPolicy(preparePolicyObj());
    } else {
      setOccupationList();
    }
  }, [policy, collections]);

  const tryReissue = async () => {
    const isPermitted = await getReissuePermission();
    if (isPermitted) {
      reissuePolicy(policy.id);
    } else {
      showForbiddenModal();
    }
  };

  const toggleCancelRemarkModal = async () => {
    const {
      cancelNotInEffectPermission, cancelInEffectPermission,
    } = props;
    let isPermitted;
    if (policy.status === 'In Effect') {
      isPermitted = await cancelInEffectPermission();
      if (!isPermitted) {
        return store.dispatch({
          type: SHOW_FORBIDDEN_MESSAGE,
          payload: 'You are not allowed to cancel In Effect certificates.',
        });
      }
    } else {
      isPermitted = await cancelNotInEffectPermission();
      if (!isPermitted) {
        return store.dispatch({
          type: SHOW_FORBIDDEN_MESSAGE,
          payload: 'You are not allowed to cancel Not In Effect certificates.',
        });
      }
    }
    return toggleCancelRemarkModalIsOpen((prevState) => !prevState);
  };

  const cancelable = (additionalStatuses: string[] = [], additionalStates: string[] = []) => {
    if (!policy) return false;
    const statuses = ['not in effect', 'in effect'].concat(additionalStatuses.map((s) => s.toLowerCase()));
    const states = ['new', 'endorsement'].concat(additionalStates.map((s) => s.toLowerCase()));
    return statuses.includes(String(policy.status).toLowerCase())
      && states.includes(String(policy.policyState).toLowerCase());
  };

  if (objLib.isEmpty(preparedPolicy)) return <Loader />;
  return (
    <>
      <BasePolicyDetails
        policy={preparedPolicy}
        title={<BasePolicyTitleDetails policy={preparedPolicy} />}
        sidebar={(
              <BasePolicySidebarDetails
                  item={preparedPolicy}
                  status={policy.status}
                  model={models.sidebar}
              />
        )}
        actionButtons={(
          <Dropdown className='mb-3 text-right' isOpen={isActionButtonsOpen} toggle={() => toggleActionButtons(!isActionButtonsOpen)}>
            <DropdownToggle caret className='btn-action'>
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={tryReissue}>Reissue Certificate</DropdownItem>
              { cancelable() ? <DropdownItem onClick={toggleCancelRemarkModal}>Cancel Certificate</DropdownItem> : ''}
            </DropdownMenu>
          </Dropdown>
        )}
        paymentCard={(
            <>
              <PaymentCard item={preparedPolicy} model={models.paymentModel} />
              <RemarksCard
                  properties={policy ? policy.properties : {}}
                  status={policy ? policy.status : ''}
              />
            </>
        )}
        marketingInfoCard={<MarketingInfoCard item={preparedPolicy} model={models.marketingInfoModel} />}
        history={history}
      >
        <Sections policy={preparedPolicy} models={models}>
          {children ? <>{children}</> : <></>}
        </Sections>
      </BasePolicyDetails>
      <CancelForm
        isOpen={cancelRemarkModalIsOpen}
        toggleModal={toggleCancelRemarkModal}
        policy={policy}
      />
    </>
  );
};

export default connect(() => ({}), dispatchToProps)(BasePolicyDetail);
