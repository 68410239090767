import React from "react";
import Moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import SimpleReactValidator from 'simple-react-validator';
import productConfig from '_config';
import {
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col
} from "reactstrap";
import { CONFIG_DATE_MOMENT_FORMAT, getOneYearInclusiveEndMoment, utils,
  maximumReturnDaysValidationRule, getMaximumReturnTripEndDate } from "../../../../utils";


import {CommonInputTypes} from "../../../../_components/CommonInputTypes"

const countArray = [
  {
    name: "0"
  },
  {
    name: "1"
  },
  {
    name: "2"
  },
  {
    name: "3"
  },
  {
    name: "4"
  },
  {
    name: "5"
  },
  {
    name: "6"
  },
  {
    name: "7"
  },
  {
    name: "8"
  },
  {
    name: "9"
  },
  {
    name: "10"
  }
];

const maximumIndividualsNumberError = {
  anyError: true,
  errorMessage: "The total number of Adults, Children and Senior Citizens cannot be more than 125."
}
// const maximumGroupNumberError = {
//   anyError: true,
//   errorMessage: "The total number of Adults, Children and Senior Citizens cannot be more than 125."
// };
const minimumGroupNumberError = {
  anyError: true,
  errorMessage: `The total number of Adults, Children and Senior Citizens must be at least 2, 
                and there must be a minimum of 1 Adult or Senior Citizen within the group.`
};

class TripDetails extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      className: 'text-danger-step',
      validators: {
        validate_maximum_return_days: {
          message: "Number of days should not go beyond 185 for Return type.",
          rule: maximumReturnDaysValidationRule,
        }
      }
    });
    this.initialState = {};

    this.state = {...this.props.curentThis.state, 

      /**this is for individuals insuredType radio buttons */
      individualIsAdult: this.props.curentThis.state.properties['Senior Citizens'] === 0,
    };
  }

  async componentDidMount() {
    this.props.onRef(this);
    this.initialState = this.state;
    this.props.curentThis.setState({disabled: true});

    const { curentThis: { props: {item} } } = this.props;
    const country = (item && item.properties.Country) || productConfig.products.hreTravelInsurance.defaultValues.country;
    await this.props.getStates(country);
  }

  getAlert() {
    const {validator, props, forceUpdate} = this;
    const { curentThis } = props;
    if (validator.allValid()) {
      curentThis.setState({disabled: false});
      curentThis.createOtherInsuredObj();
      curentThis.populateBeneficiaryObj();
      return true
    } else {
      validator.showMessages();
      forceUpdate();
      curentThis.setState({disabled: true});
      return false;
    }
  }

  componentDidUpdate(prevProps) {
    const {curentThis} = this.props;
    const citizensNumber = Number(curentThis.state.properties["Senior Citizens"]);
    const adultsNumber = Number(curentThis.state.properties["Adults"]);
    const childrenNumber = Number(curentThis.state.properties["Children"]);

    if (
      (curentThis.state.disabled !== true && !this.validator.allValid()) ||
      (curentThis.state.disabled !== true && curentThis.state.seniorCitizenErrors.anyError === true) ||
      (curentThis.state.disabled !== true && curentThis.state.adultsErrors.anyError === true) ||
      (curentThis.state.disabled !== true && curentThis.state.childrenErrors.anyError === true) ||
      (curentThis.state.disabled !== true && curentThis.state.insuredTypeError.anyError === true)
    ) {
      curentThis.setState({disabled: true});
    } else if (
      curentThis.state.disabled === true &&
      this.validator.allValid() &&
      curentThis.state.seniorCitizenErrors.anyError === false &&
      curentThis.state.adultsErrors.anyError === false &&
      curentThis.state.childrenErrors.anyError === false &&
      curentThis.state.insuredTypeError.anyError === false &&
      citizensNumber + adultsNumber + childrenNumber !== 0) {
      curentThis.setState({disabled: false});
    }
  }

  handleTripTypeInputChange = (e) => {
    const { curentThis } = this.props;
    const { target } = e;
    const startM = Moment(curentThis.state.properties["Start Date"], CONFIG_DATE_MOMENT_FORMAT);

    if (target.name === "Trip Type" && target.value === "One-Way") {
      curentThis.setState({ disableDomestic: false, disableArrival: false }, () => {
        const endM = startM.clone().add(1, "days");
        curentThis.handleDateChange({
          startDate: startM.format(CONFIG_DATE_MOMENT_FORMAT),
          endDate: endM.format(CONFIG_DATE_MOMENT_FORMAT),
        });
      });
    } else if (target.name === "Trip Type" && target.value === "Annual") {
      curentThis.setState({ disableDomestic: true, disableArrival: true }, () => {
        const newEndDate = getOneYearInclusiveEndMoment(curentThis.state.properties["Start Date"]).format(CONFIG_DATE_MOMENT_FORMAT);
        curentThis.handleDateChange({startDate: curentThis.state.properties["Start Date"], endDate: newEndDate});
      });
    } else if (target.name === "Trip Type" && target.value === "Return") {
      const endM = startM.clone().add(utils.constants.defaultTravelNoOfDays - 1, "days");
      curentThis.setState({ disableDomestic: false, disableArrival: false }, () => {
        curentThis.handleDateChange({ startDate: startM.format(CONFIG_DATE_MOMENT_FORMAT), endDate: endM.format(CONFIG_DATE_MOMENT_FORMAT) });
      });
    }
    curentThis.handleInputChange(e);
  };

  handleDestinationInputChange = (e) => {
    const {curentThis} = this.props;
    curentThis.handleInputChange(e);
  };

  handleInsuredTypeInputChange = (e) => {
    const {curentThis} = this.props;
    const {target} = e;
    curentThis.setState({
      insuredTypeError: {
        anyError: false,
        errorMessage: ""
      }
    });
    const noError = {
      anyError: false,
      errorMessage: ''
    };

    //Error handling for Adult/Senior Citizen/Children number when Insured Type is Individual
    if (target.value === "Group") { // "Individual or Group"
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: false,
          hideSeniorField: false,
          disableChildrenField: false,
          disableSeniorField: false,
          disableAdultsField: false,
          seniorCitizenErrors: noError,
          adultsErrors: noError,
          childrenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            "Senior Citizens": 0,
            "Children": 0,
            "Adults": 2
          },
        });
      });
      curentThis.handleInputChange(e);
    }

    if(target.value === 'Individual') {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: false,
          hideSeniorField: false,
          disableChildrenField: false,
          disableSeniorField: false,
          disableAdultsField: false,
          seniorCitizenErrors: noError,
          adultsErrors: noError,
          childrenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            "Senior Citizens": 0,
            "Children": 0,
            "Adults": 1
          },
        });
      });
      curentThis.handleInputChange(e);
    }
    //Error handling for Adult/Senior Citizen/Children number when Insured Type is Family
    if (target.value === "Family") {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: false,
          disableChildrenField: false,
          hideSeniorField: true,
          disableSeniorField: true,
          disableAdultsField: true,
          childrenErrors: noError,
          adultsErrors: noError,
          seniorCitizenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            "Senior Citizens": 0,
            "Children": 1, 
            "Adults": 2
          }
        });
      });
      curentThis.handleInputChange(e);
    }

    //Error handling for Adult/Senior Citizen/Children number when Insured Type is Individual and Children
    if (target.value === "Individual and Children") {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: false,
          disableChildrenField: false,
          hideSeniorField: true,
          disableSeniorField: true,
          disableAdultsField: true,
          childrenErrors: noError,
          adultsErrors: noError,
          seniorCitizenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            "Senior Citizens": 0,
            "Children": 1,
            "Adults": 1
          }
        });
      });
      curentThis.handleInputChange(e);
    }

    //Error handling for Adult/Senior Citizen/Children number when Insured Type is Individual and Spouse
    if (target.value === "Individual and Spouse") {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: true,
          disableChildrenField: true,
          hideSeniorField: false,
          disableSeniorField: false,
          disableAdultsField: false,
          seniorCitizenErrors: noError,
          adultsErrors: noError,
          childrenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            "Senior Citizens": 0,
            "Adults": 2,
            "Children": 0
          }
        });
      });
      curentThis.handleInputChange(e);
    }
  };

  handleSeniorCitizenInputChange = (e) => {
    const {target} = e;
    const {curentThis} = this.props;
    const value = Number(target.value) || 0;
    const adults_count = Number(curentThis.state.properties['Adults']);
    const children_count = Number(curentThis.state.properties['Children']);


    //Check if Insured Type is empty
    if (!curentThis.state.properties["Insured Type"].length) {
      curentThis.setState({
        insuredTypeError: {
          anyError: true,
          errorMessage: "Please select insured type."
        }
      });
      return;
    } else {
      curentThis.setState({
        insuredTypeError: {
          anyError: false,
          errorMessage: ""
        }
      });
    }

    //Check with other Insured counts for different Insured Types
    if (curentThis.state.properties['Insured Type'] === "Group") { // "Individual or Group"
      const noError = {
        anyError: false,
        errorMessage: ""
      };
      if (value + adults_count + children_count > 125) {
        curentThis.setState({
          seniorCitizenErrors: maximumIndividualsNumberError,
          childrenErrors: maximumIndividualsNumberError,
          adultsErrors: maximumIndividualsNumberError,
        });
      } else if ((value + adults_count + children_count < 2
        || (value + adults_count === 0))) {
        curentThis.setState({
          seniorCitizenErrors: minimumGroupNumberError,
          adultsErrors: minimumGroupNumberError
        });
      } else {
        curentThis.setState({
          seniorCitizenErrors: noError,
          adultsErrors: noError,
          childrenErrors: noError
        });
      }
    } else if (curentThis.state.properties['Insured Type'] === "Individual and Spouse") {
      const error = {
        anyError: true,
        errorMessage: "The total number of Senior Citizen and Adults must be 2 for “Individual & Spouse” plan."
      };
      const noError = {
        anyError: false,
        errorMessage: ""
      };

      if (value && adults_count) {
        const error = {
          anyError: true,
          errorMessage: "For “Individual & Spouse” plan, you should select either 2 Adults or 2 Senior Citizens."
        };
        curentThis.setState({
          adultsErrors: error,
          seniorCitizenErrors: error,
        });
      }
      else if (value + adults_count !== 2) {
        curentThis.setState({
          seniorCitizenErrors: error,
          adultsErrors: error,
        });
      } else if (value + adults_count === 2) {
        curentThis.setState({
          seniorCitizenErrors: noError,
          adultsErrors: noError,
        });
      }
    } else if (curentThis.state.properties['Insured Type'] === "Family") {

    }
    // curentThis.handleInputChange(e);
    const event = {...e};
    event.target.value = value; // avoiding value to be ""
    curentThis.handleInputChange(event);
  };

  handleAdultInputChange = (e) => {
    const {curentThis} = this.props;
    const {target} = e;
    const value = Number(target.value) || 0;
    const senior_count = Number(curentThis.state.properties['Senior Citizens']);
    const children_count = Number(curentThis.state.properties['Children']);


    //Check if Insured Type is empty
    if (!curentThis.state.properties["Insured Type"].length) {
      curentThis.setState({
        insuredTypeError: {
          anyError: true,
          errorMessage: "Please select insured type."
        }
      });
      return;
    } else {
      // this.setState({insuredTypeError: this.initialState.insuredTypeError});
      curentThis.setState({
        insuredTypeError: {
          anyError: false,
          errorMessage: ""
        }
      });
    }

    //Check with other Insured counts for different Insured Types
    if (curentThis.state.properties['Insured Type'] === "Group") { // "Individual or Group"
      const noError = {
        anyError: false,
        errorMessage: ""
      };
      if (value + senior_count + children_count > 125) {
        curentThis.setState({
          adultsErrors: maximumIndividualsNumberError,
          seniorCitizenErrors: maximumIndividualsNumberError,
          childrenErrors: maximumIndividualsNumberError
        });
      } else if ((value + senior_count + children_count < 2
        || (value + senior_count === 0))) {
        curentThis.setState({
          adultsErrors: minimumGroupNumberError,
          seniorCitizenErrors: minimumGroupNumberError,
        });
      } else {
        curentThis.setState({
          adultsErrors: noError,
          seniorCitizenErrors: noError,
          childrenErrors: noError
        });
      }
    } else if (curentThis.state.properties['Insured Type'] === "Individual and Spouse") {


      if (value && senior_count) {
        const error = {
          anyError: true,
          errorMessage: "For “Individual & Spouse” plan, you should select either 2 Adults or 2 Senior Citizens."
        };
        curentThis.setState({
          adultsErrors: error,
          seniorCitizenErrors: error,
        });
      }
      else if (value + senior_count > 2) {
        curentThis.setState({
          adultsErrors: {
            anyError: true,
            errorMessage: "The total number of Senior Citizen and Adults must be 2 for “Individual & Spouse” plan."
          },
        });
      } else if (value + senior_count < 2) {
        curentThis.setState({
          adultsErrors: {
            anyError: true,
            errorMessage: "The total number of Senior Citizen and Adults must be 2 for “Individual & Spouse” plan."
          },
        });
      } else if (value + senior_count === 2) {
        curentThis.setState({
          seniorCitizenErrors: {
            anyError: false,
            errorMessage: ""
          },
          adultsErrors: {
            anyError: false,
            errorMessage: ""
          },
        });
      }
    } else if (value + senior_count + children_count > 125) {
      curentThis.setState({
        adultsErrors: maximumIndividualsNumberError
      });
    } else {
      curentThis.setState({
        adultsErrors: {
          anyError: false,
          errorMessage: ""
        },
      });
    }

    // curentThis.handleInputChange(e);
    const event = {...e};
    event.target.value = value;
    curentThis.handleInputChange(event);
  };

  handleChildrenInputChange = (e) => {
    const {curentThis} = this.props;
    const {target} = e;
    const value = Number(target.value) || 0;
    const adults_count = Number(curentThis.state.properties['Adults']);
    const senior_count = Number(curentThis.state.properties['Senior Citizens']);
    const noError = {
      anyError: false,
      errorMessage: ""
    };

    //Check if Insured Type is empty
    if (!curentThis.state.properties["Insured Type"].length) {
      curentThis.setState({
        insuredTypeError: {
          anyError: true,
          errorMessage: "Please select insured type."
        }
      });
      return;
    } else {
      // this.setState({insuredTypeError: this.initialState.insuredTypeError});
      curentThis.setState({
        insuredTypeError: {
          anyError: false,
          errorMessage: ""
        }
      });
    }

    //Check with other Insured counts for different Insured Types
    if (curentThis.state.properties['Insured Type'] === "Family") {
      if (value > 3) {
        curentThis.setState({
          childrenErrors: {
            anyError: true,
            errorMessage: 'The total number of Children cannot be more than 3 for “Family” plan.'
          },
        });
      } else if (value === 0) {
        curentThis.setState({
          childrenErrors: {
            anyError: true,
            errorMessage: 'Please enter the number of Children here.'
          },
        });
      } else if (value > 0 && value <= 3) {
        curentThis.setState({
          childrenErrors: {
            anyError: false,
            errorMessage: ""
          },
        });
      }
    } else if (curentThis.state.properties['Insured Type'] === "Individual and Children") {
      if (value > 3) {
        curentThis.setState({
          childrenErrors: {
            anyError: true,
            errorMessage: 'The total number of Children cannot be more than 3 for Individual and Children" plan.'
          },
        });
      } else if (value === 0) {
        curentThis.setState({
          childrenErrors: {
            anyError: true,
            errorMessage: 'Please enter the number of Children here.'
          },
        });
      } else if (value > 0 && value <= 3) {
        curentThis.setState({
          childrenErrors: {
            anyError: false,
            errorMessage: ""
          },
        });
      }
    } else if (curentThis.state.properties['Insured Type'] === "Group") { // "Individual or Group"

      if (value + senior_count + adults_count > 125) {
        curentThis.setState({
          seniorCitizenErrors: maximumIndividualsNumberError,
          adultsErrors: maximumIndividualsNumberError,
          childrenErrors: maximumIndividualsNumberError
        });
      } else if ((value + adults_count + senior_count < 2)
      || (value > 0 && adults_count + senior_count === 0)) {
        curentThis.setState({
          adultsErrors: minimumGroupNumberError,
          seniorCitizenErrors: minimumGroupNumberError,
        });
      } else {
        curentThis.setState({
          childrenErrors: noError,
          adultsErrors: noError,
          seniorCitizenErrors: noError
        });
      }
    } else {
      curentThis.setState({
        childrenErrors: {
          anyError: false,
          errorMessage: ""
        },
      });
    }
    const event = {...e};
    event.target.value = value;
    curentThis.handleInputChange(event);
  };

  handleStartDateChange = (date) => {
    const { curentThis, curentThis: { handleChangeStart, handleDateChange } } = this.props;
    const dateM = Moment(date, CONFIG_DATE_MOMENT_FORMAT);
    if (curentThis.state.properties["Trip Type"] === "One-Way") {
      const endM = dateM.clone().add(1, "days");
      handleDateChange({
        startDate: dateM.format(CONFIG_DATE_MOMENT_FORMAT),
        endDate: endM.format(CONFIG_DATE_MOMENT_FORMAT),
      });
    } else handleChangeStart(dateM.format(CONFIG_DATE_MOMENT_FORMAT));
  };

  handleEndDateChange = async (date) => {
    const { curentThis } = this.props;
    const dateM = Moment(date, CONFIG_DATE_MOMENT_FORMAT);
    await curentThis.handleChangeEnd(dateM.format(CONFIG_DATE_MOMENT_FORMAT));
    this.validator.showMessageFor("end date");
  }


  computeOpenToDate = (type, currentThis) => {
    let date;
    if(currentThis && currentThis.state && currentThis.state.properties) {
      if(type === 'start')
        date = currentThis.state.properties["Start Date"];
      
      else if(type === 'end')
        date = currentThis.state.properties["End Date"];
    }
    return date ? new Date(date) : new Date();
  }

  radioButtonHandleChange = (currentThis, id) => {
    if(currentThis && currentThis.state && currentThis.state.properties) {
      currentThis.forceUpdate(() => {
        currentThis.setState({ 
          ...currentThis.state,
          properties: {
            ...currentThis.state.properties,
            "Senior Citizens": id === 'Senior Citizens' ? 1 : 0,
            "Adults": id === 'Adults' ? 1 : 0,
          }
        });
      });
    }
    this.setState({
      ...this.state,
      individualIsAdult: id === 'Adults'
    })
    // curentThis.handleInputChange(e);

  }

  render() {
    const {curentThis, curentThis: {state: {properties}}} = this.props;
    const {collections} = this.props.curentThis.props;
    const regionsToDisplay = collections && collections.regions.map(region => {
      let disabled = false;
      // this conversion in not currently needed but we are keeping it for potential future use.
      // if (curentThis.state.disableDomestic && region.name.includes('Domestic')) disabled = true;
      return {...region, disabled}
    });
    const {seniorCitizenErrors, adultsErrors, insuredTypeError, childrenErrors} = curentThis.state;
    const computeOpenToDate = this.computeOpenToDate;
    let maxEndDate = getMaximumReturnTripEndDate(properties["Start Date"] 
      ? Moment(properties["Start Date"], CONFIG_DATE_MOMENT_FORMAT)
      : Moment(new Date(), CONFIG_DATE_MOMENT_FORMAT)
      , utils.constants.defaultTuguTravelMaximumNoOfReturnDays);
    return (
      <div className="row my_50 justify-content-center">
        <div className="col-12 col-md-10">
          <h5 className="sub-title">Fill in trip details</h5>
          <Form>
            <FormGroup>
              <CommonInputTypes
                idName="Trip Type"
                iconName="aeroplane"
                group={true}
                type="select"
                option={collections !== undefined ? collections.tripTypes : []}
                value={curentThis.state.properties["Trip Type"]}
                onChange={this.handleTripTypeInputChange}
                title="Trip Type"
                id="1"
                validator={this.validator}
                autoFocus = {true}
              />
              <CommonInputTypes
                idName="Destination"
                iconName="placeholder"
                group={true}
                type="select"
                option={regionsToDisplay ? regionsToDisplay : []}
                value={curentThis.state.properties["Destination"]}
                onChange={this.handleDestinationInputChange}
                title="Destination"
                id="2"
                validator={this.validator}
              />
              <FormGroup>
                <Row>
                  <Col md={curentThis.state.properties["Trip Type"] === "One-Way" ? 12 : 6} xs="auto">
                    <Label for="Start Date">Start Date</Label>
                    <InputGroup className="fill-children-width">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="terminal-icon">
                          <i className={"icon-depart"}/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <div className="terminal-input">
                        <CommonInputTypes
                          dateStyle={{
                            marginBottom: 0
                          }}
                          validator={this.validator}
                          idName="Start Date"
                          placeholder="Select Start Date"
                          group={false}
                          type="date"
                          value={properties["Start Date"]}
                          startDate={properties["Start Date"]}
                          endDate={properties["End Date"]}
                          onChange={this.handleStartDateChange} // datepicker onchange
                          minDate={new Date()}
                          openToDate={computeOpenToDate('start', curentThis)}
                          id="2"
                          // handleInputDateChange = {(date) => this.handleStartInputDateChange(date)} //text input on change
                        />
                      </div>
                    </InputGroup>
                  </Col>
                  {curentThis.state.properties["Trip Type"] !== "One-Way" &&
                  <Col md="6" xs="auto">
                    <Label for="End Date">End Date</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="terminal-icon">
                          <i className={"icon-arrival"}/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <div className="terminal-input">
                        <CommonInputTypes
                          dateStyle={{
                            marginBottom: 0
                          }}
                          className={`${curentThis.state.disableArrival ? "custom-disabled" : ""}`}
                          disabled={curentThis.state.disableArrival}
                          validator={this.validator}
                          placeholder="Select End Date"
                          idName="End Date"
                          group={false}
                          type="date"
                          value={properties["End Date"]}
                          startDate={properties["Start Date"]}
                          endDate={properties["End Date"]}
                          onChange={this.handleEndDateChange} // datepicker onchange
                          id="2"
                          maxDate={maxEndDate}
                          minDate={new Date()}
                          openToDate={computeOpenToDate('end', curentThis)}
                          // handleInputDateChange = {(date) => this.handleEndInputDateChange(date)} //text input on change
                        />
                      </div>
                    </InputGroup>
                    {properties["Trip Type"] === "Return" && this.validator.message(
                      'end date',
                      {
                        startDate: properties["Start Date"],
                        endDate: properties["End Date"],
                        maxNoOfDay: utils.constants.defaultTuguTravelMaximumNoOfReturnDays,
                      },
                      'validate_maximum_return_days'
                    )}
                  </Col>
                  }
                </Row>
              </FormGroup>
              <CommonInputTypes
                idName="Insured Type"
                iconName="user"
                group={true}
                type="select"
                option={collections ? collections.insuredType : []}
                value={curentThis.state.properties["Insured Type"]}
                onChange={this.handleInsuredTypeInputChange}
                title="Insured Type"
                id="1"
                validator={this.validator}
              >
                {insuredTypeError.anyError &&
                <span className="error">{insuredTypeError.errorMessage}</span>
                }
              </CommonInputTypes>
              {
                curentThis.state.properties["Insured Type"] !== 'Individual' ? // check the insuredType for showing options 
                <FormGroup>
                <Row>
                  <Col xs="12" md="6" lg="4">
                    <CommonInputTypes
                      idName="Adults"
                      iconName="user"
                      group={true}
                      type="number"
                      placeholder="0"
                      option={countArray}
                      value={curentThis.state.properties["Adults"]}
                      onChange={this.handleAdultInputChange}
                      title="Adult (18-65)"
                      id="1"
                      validator={this.validator}
                      disabled={curentThis.state.disableAdultsField}
                      min={0}
                    >
                      {adultsErrors.anyError &&
                      <span className="error">{adultsErrors.errorMessage}</span>
                      }
                    </CommonInputTypes>
                  </Col>
                  {!curentThis.state.hideChildrenField &&
                  <Col xs="12" md="6" lg="4">
                    <CommonInputTypes
                      idName="Children"
                      iconName="user"
                      group={true}
                      type="number"
                      placeholder="0"
                      option={countArray}
                      value={curentThis.state.properties["Children"]}
                      onChange={this.handleChildrenInputChange}
                      title="Child (0-17)"
                      id="1"
                      validator={this.validator}
                      disabled={curentThis.state.disableChildrenField}
                      min={0}
                    >
                      {childrenErrors.anyError &&
                      <span className="error">{childrenErrors.errorMessage}</span>
                      }
                    </CommonInputTypes>
                  </Col>
                  }
                  {!curentThis.state.hideSeniorField &&
                  <Col xs="12" md="6" lg="4">
                    <CommonInputTypes
                      idName="Senior Citizens"
                      title="Senior Citizen (66-80)"
                      iconName="user"
                      group={true}
                      type="number"
                      placeholder="0"
                      min={0}
                      option={countArray}
                      value={curentThis.state.properties["Senior Citizens"]}
                      onChange={this.handleSeniorCitizenInputChange}
                      disabled={curentThis.state.disableSeniorField}
                      id="1"
                      validator={this.validator}
                    >
                      {seniorCitizenErrors.anyError &&
                      <span className="error">{seniorCitizenErrors.errorMessage}</span>
                      }
                    </CommonInputTypes>
                  </Col>
                  }
                </Row>
              </FormGroup> : 
              <FormGroup>
                <Row>
                  <Col md="6" xs="12">
                    <CommonInputTypes type='radio' 
                      idName='Adults' 
                      title='Adult (18-65)'
                      checked={this.state.individualIsAdult === true} 
                      onChange = {(e) => this.radioButtonHandleChange(curentThis, e)}
                      iconName = {curentThis.state.properties['Adults'] === 1 ? 'radio-checked' : 'radio-unchecked'}
                    />        
                  </Col>
                  <Col md="6" xs="12">
                  <CommonInputTypes type='radio' 
                    idName='Senior Citizens' 
                    title='Senior Citizen (66-80)'
                    checked={this.state.individualIsAdult === false}
                    onChange = {(e) => this.radioButtonHandleChange(curentThis, e)}
                    iconName = {curentThis.state.properties['Senior Citizens'] === 1 ? 'radio-checked' : 'radio-unchecked'}
                  />
                  </Col>
                </Row>
              </FormGroup>
              }
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default TripDetails;
