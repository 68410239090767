import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { objLib } from '@xc-core/lib';

const InsuredDetails = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
      initValues,
      defaults,
    },
  } = props;

  useEffect(() => {
    const country = objLib.getValueWithLodash(item, model.fields.country.id)
      || defaults.country;
    const pairs: IPair[] = [{ id: model.fields.country.id, value: country }];
    initValues(pairs);
  }, []);

  return (
    <Row>
      <Col md='8'>
        <h5 className='sub-title'>Please enter customer details / សូមបំពេញញព័ត៌មានលម្អិតរបស់អ្នកត្រូវបានធានារ៉ាប់រង</h5>

        <p className='custom-text-primary'>Main Insured Details / ព័ត៌មានលម្អិតរបស់អ្នកត្រូវបានធានារ៉ាប់រង</p>
        <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={collections}
          item={item}
          ext={true}
          validationErrors={validationErrors}
          fields={model.mainDetail.fields}
        />

        <p className='custom-text-primary'>Contact Details / ព័ត៌មានទំនាក់ទំនង</p>
        <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={collections}
          item={item}
          ext={true}
          validationErrors={validationErrors}
          fields={model.contactDetail.fields}
        />

      </Col>
      <Col md='4'>
        <SidebarCard.Quotation
          item={item}
          model={sidebarModel.quotation.flexiPaCB}
          ext={true}
        />
      </Col>
    </Row>
  );
};

export default InsuredDetails;
