import moment from 'moment';
import { CONFIG_DATE_MOMENT_FORMAT } from '../../../utils';

const minAge = {
    years: 0,
    months: 0,
    days: 29,
};
const maxAge = {
    years: 80,
    months: 0,
    days: 364,
};


const properties : IObject = {

};

const country = '';

const insuredType = '';

const stateListParam = '';

const cnicLength = 13;

const postcodeLength = 5;

const debitCreditNoLength = 16;

const carDetailDefaultValue = {
    'properties.Car Details.Make': 'Honda',
    'properties.Car Details.Model':'City',
    'properties.Car Details.Manufacture Year': '2021',
    'properties.Car Details.Engine CC': '1498',
    'properties.Car Details.Transmission': '1 SP AUTOMATIC CONSTANTLY VARIABLE',
    'properties.Car Details.Variant': 'E MY20 GN',
}

const defaults = {
    minAge,
    maxAge,
    country,
    stateListParam,
    cnicLength,
    postcodeLength,
    debitCreditNoLength,
    properties,
    insuredType,
    carDetailDefaultValue
};

export default defaults;
