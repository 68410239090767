import React from 'react';

import {Form,FormGroup,Label} from "reactstrap";
import {CommonInputTypes} from '_components';
import Field from '@containers/getQuote/wizard/field';

const PaymentDetails = props => {

	const {fields, collections} = props;
  return(
		<div className="row my_50 justify-content-center">
		<div className="col-12 col-md-10">
			<label className="sub-title">Fill in payment details (optional)</label>
			<Form>
				<FormGroup>
					<CommonInputTypes
						id="1"
						idName="paymentMode"
						group={false}
						type="select"
						title="Mode of Payment"
						option={collections.paymentMethod}
						value={fields.paymentMode}
						onChange={(e) => props.handlePaymentModeChange("paymentDetails",e)}
						autoFocus={true}
					/>
					<CommonInputTypes
						id="2"
						idName="receiptNo"
						title="Receipt No"
						group={false}
						type="input"
						value={fields.receiptNo}
						onChange={(e) => props.handleChange("paymentDetails",e)}		
						autoFocus={true}							
					/>
					<CommonInputTypes
						id="3"
						idName="receivedBy"
						title="Payment Received By"
						group={false}
						type="select"
						option={collections.currentOuUsers}
						value={fields.receivedBy}
						onChange={(e) => props.handleChange("paymentDetails",e)}
						autoFocus={true}
					/>
					<div className='additional-tag-styles'>
					  <p>Other Emails to Receive Policy</p>
					<FormGroup>
						<Label for='cc'>CC:</Label>
						<Field
							model={{
								id: 'ccEmail',
								type: 'MultiValueInput',
								handler: (pair) => ({ target: pair }),
								style: { col: '4' },
								label: 'cc',
							}}
							item={fields}
							onChange={(e) => props.handleChange("paymentDetails",e)}
						/>
					</FormGroup>
					<FormGroup>
						<Label for='bcc'>BCC:</Label>
						<Field
							model={{
								id: 'bccEmail',
								type: 'MultiValueInput',
								handler: (pair) => ({ target: pair }),
								style: { col: '4' },
								label: 'bcc',
							}}
							item={fields}
							onChange={(e) => props.handleChange("paymentDetails",e)}
						/>
					</FormGroup>
					</div>
				</FormGroup>
			</Form>
		</div>
	</div>
  );
}

export default PaymentDetails;