import React from 'react';

declare interface IComponentLoaderProps {
  repo: IObject,
  name: string;
  propsModel: IObject;
  history?: IObject,
}

const componentNotFound = () => (<p>Component Not Found</p>);

const ComponentLoader = (props: IComponentLoaderProps) => {
  const {
    propsModel, name, repo, history,
  } = props;
  const Component = (repo as IObject)[name] || componentNotFound;
  return <Component model={propsModel} history={history} />;
};

export default ComponentLoader;
