import env from 'env';
import { currentUser } from 'utils';
import Request from './request';
import Response from './response';

class FiscalYearService {
    protected env = env.policy;

    protected api = 'fiscal/year';

    protected orgUnitCode = currentUser ? currentUser.domain : '';

    public getList = async (): Promise<any> => {
      const url = `${this.env}/${this.api}`;
      const request = new Request();
      const response = new Response(url, request);
      const result = await response.handle();
      return result.data;
    }

    public createFiscalYear = async (item: IObject) : Promise<any> => {
      const url = `${this.env}/${this.api}`;
      const request = new Request(item, 'POST');
      const response = new Response(url, request);
      const result = await response.handle();
      return result.data;
    }

    public updateFiscalYear = async (item: IObject, id: String | number) : Promise<any> => {
      const url = `${this.env}/${this.api}/${id}`;
      const request = new Request(item, 'PUT');
      const response = new Response(url, request);
      const result = await response.handle();
      return result.data;
    }
}

export default FiscalYearService;
