import React from 'react';

const DomesticBenefitTable = (props) => {
		
    return(
    <div className="benefit-table">
			<p>TABEL MANFAAT PRODUK STANDAR / STANDARD PRODUCT BENEFIT TABLE ASURANSI PERJALANAN / <i>TRAVEL INSURANCE</i></p>
        <table>
						<thead>
							<tr>
								<th rowSpan={1} colSpan={5} className="invisible"></th>
								<th rowSpan={1} colSpan={1}>IDR</th>
								<th rowSpan={1} colSpan={1}>IDR</th>
							</tr>
							<tr>
								<th rowSpan={2} colSpan={3}>LUAS JAMINAN / COVERAGE</th>
								<th rowSpan={2} colSpan={2}>MANFAAT / BENEFIT</th>
								<th rowSpan={1} colSpan={1}>STANDARD</th>
								<th rowSpan={1} colSpan={1}>EXECUTIVE</th>
							</tr>
							<tr>
								<th rowSpan={1} colSpan={1}>BATASAN GANTI RUGI / LIMIT OF LIABILITY</th>			
								<th rowSpan={1} colSpan={1}>BATASAN GANTI RUGI / LIMIT OF LIABILITY</th>
							</tr>
						</thead>
            <tbody>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">A</td>
								<td colSpan={2} rowSpan={4} className="category">Biaya Pengobatan &amp; Layanan Darurat / Emergency Service Expenses</td>
								<td colSpan={2} rowSpan={1}>Biaya pengobatan dan rawat inap / Medical expenses and hospitalization</td>
								<td colSpan={1} rowSpan={1} className="price">10,000,000</td>
								<td colSpan={1} rowSpan={1} className="price">20,000,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Biaya pengobatan akibat kecelakaan / Medical expenses due to accident</td>
								<td colSpan={1} rowSpan={1} className="price">25,000,000</td>
								<td colSpan={1} rowSpan={1} className="price">50,000,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Biaya Transportasi atau Repatriasi ketika sakit atau kecelakaan / Transportation or repatriation in the event of illness or accident expenses</td>
								<td colSpan={1} rowSpan={1} className="price">25,000,000</td>
								<td colSpan={1} rowSpan={1} className="price">50,000,000</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Biaya Repatriasi Jenazah / Repatriation of Mortal Remain expenses</td>
								<td colSpan={1} rowSpan={1} className="price">15,000,000</td>
								<td colSpan={1} rowSpan={1} className="price">25,000,000</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={1} className="category">B</td>
								<td colSpan={2} rowSpan={1} className="category">Biaya Pembatalan Perjalanan / Travel Cancellation Expenses</td>
								<td colSpan={2} rowSpan={1}>Biaya pembatalan atau pengurangan perjalanan / Travel cancellation or curtailment expenses</td>
								<td colSpan={1} rowSpan={1} className="price">2,500,000</td>
								<td colSpan={1} rowSpan={1} className="price">5,000,000</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">C</td>
								<td colSpan={2} rowSpan={4} className="category">Manfaat Atas Kehilangan dan Penundaan / Benefits for Losses dan Delay</td>
								<td colSpan={2} rowSpan={1}>Gangguan perjalanan / Travel disruption</td>
								<td colSpan={1} rowSpan={1} className="price">1,000,000</td>
								<td colSpan={1} rowSpan={1} className="price">2,500,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Penundaan perjalanan / Travel postponement</td>
								<td colSpan={1} rowSpan={1} className="price">1,000,000</td>
								<td colSpan={1} rowSpan={1} className="price">2,500,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Tertinggal perjalanan lanjutan / Travel misconnection</td>
								<td colSpan={1} rowSpan={1} className="price">1,000,000</td>
								<td colSpan={1} rowSpan={1} className="price">2,500,000</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Keterlambatan keberangkatan / Delayed Departure</td>
								<td colSpan={1} rowSpan={1} className="price">750,000</td>
								<td colSpan={1} rowSpan={1} className="price">1,500,000</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">D</td>
								<td colSpan={2} rowSpan={4} className="category">
									Manfaat Untuk Barang Bawaan /Benefits For Luggage
									<p className="regular mt-2">Limit per Family / Individual &amp; Spouse / Individual &amp; Children: 200% of Individual Limit</p>
								</td>
								<td colSpan={2} rowSpan={1}>Kompensasi kehilangan di dalam pesawat, perampokan atau perusakan bagasi / Compensation for in-flight loss, robbery or destruction of baggage checked-in</td>
								<td colSpan={1} rowSpan={1} className="price">2,500,000</td>
								<td colSpan={1} rowSpan={1} className="price">5,000,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Keterlambatan kedatangan bagasi / Delay in the arrival of luggage</td>
								<td colSpan={1} rowSpan={1} className="price">750,000</td>
								<td colSpan={1} rowSpan={1} className="price">1,500,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Kehilangan paspor, SIM atau KTP/ Loss of passport, driving license or ID Card</td>
								<td colSpan={1} rowSpan={1} className="price">750,000</td>
								<td colSpan={1} rowSpan={1} className="price">1,500,000</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Penemuan dan pengiriman bagasi dan barang pribadi / Discovery &amp; delivery of luggage and personal effects</td>
								<td colSpan={1} rowSpan={1} className="price">750,000</td>
								<td colSpan={1} rowSpan={1} className="price">1,500,000</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">E</td>
								<td colSpan={2} rowSpan={4} className="category">Manfaat Tambahan / Additional Benefit</td>
								<td colSpan={2} rowSpan={1}>Pengiriman obat-obatan / Drugs delivery</td>
								<td colSpan={1} rowSpan={1} className="price">500,000</td>
								<td colSpan={1} rowSpan={1} className="price">1,000,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Pembajakan pesawat terbang / Aircraft Hijacking</td>
								<td colSpan={1} rowSpan={1} className="price">50,000 per hari(max 500,000)</td>
								<td colSpan={1} rowSpan={1} className="price">50,000 per hari(max 1,000,000)</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Biaya telepon darurat / Emergency phone call</td>
								<td colSpan={1} rowSpan={1} className="price">500,000</td>
								<td colSpan={1} rowSpan={1} className="price">1,000,000</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Perpanjangan periode pertanggungan otomatis / Automatic of extension perio</td>
								<td colSpan={1} rowSpan={1} className="price">Ya / Yes</td>
								<td colSpan={1} rowSpan={1} className="price">Ya / Yes</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={2} className="category">F</td>
								<td colSpan={2} rowSpan={2} className="category">Manfaat Kecelakaan Diri dan Cacat Tetap / Benefits For Personal Accident and Disability</td>
								<td colSpan={2} rowSpan={1}>Santunan Kematian dan Cacat Tetap Akibat Kecelakaan / Death &amp; Permanent Disablement due to accident</td>
								<td colSpan={1} rowSpan={1} className="price">50,000,000</td>
								<td colSpan={1} rowSpan={1} className="price">100,000,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Cacat Tetap Sebagian / Partial Permanent Disablement</td>
								<td colSpan={1} rowSpan={1}>Limit tetap sebagian/partial permanat disablement</td>
								<td colSpan={1} rowSpan={1}>Limit tetap sebagian/partial permanat disablement</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={2} className="category">G</td>
								<td colSpan={2} rowSpan={2} className="category">Manfaat Tanggung Gugat Pribadi / Benefits For Personal Liability</td>
								<td colSpan={2} rowSpan={2}>Tanggung gugat pribadi / Personal Liability</td>
								<td colSpan={1} rowSpan={2} className="price">25,000,000</td>
								<td colSpan={1} rowSpan={2} className="price">50,000,000</td>
							</tr>
            </tbody>
        </table>
    </div>);
}

export default DomesticBenefitTable;