import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { objLib } from '@xc-core/lib';
import lib from 'utils/lib';

/**
 * This component should be removed after the backend has removed the spouse state

 */
interface ILoadableComponentPropsSouseDetails extends ILoadableComponentProps {
    storeItem: IObject,
}
const SpouseDetails = (props: ILoadableComponentPropsSouseDetails) => {

  const {
    storeItem,
    model: {
      item,
      model,
      onChange,
      initValues,
      next,
      skipAutoNextOnAdditionalDivers,
      previous,
      defaults,
    },
  } = props;

  useEffect(() => {
    const country = objLib.getValueWithLodash(item, model.fields.country.id)
          || defaults.country;
    const pairs: IPair[] = [{ id: model.fields.country.id, value: country }];
    initValues(pairs);
  }, []);

  useEffect(() => {
    if (!skipAutoNextOnAdditionalDivers) {
      const numberOfDriver = storeItem?.properties['Number of Additional Drivers'] || 0;
      if (numberOfDriver == 0) {
        next();
        return;
      }
      const additionalDriver = objLib.getValueWithLodash(item, model.fields.additionalDrivers.id) || {};
      const pairs: IPair[] = [{ id: model.fields.additionalDrivers.id, value: additionalDriver }];
      initValues(pairs);
      setTimeout(() => {
        new Array(numberOfDriver).fill('Driver').map((item, index: number) => {
          onChange('onChange', {
            pairs: [
              {
                id: `properties.additional drivers[${index}].id`,
                value: `${index}`,
              },
            ],
          });
        });
      }, 400);
    } else {
      previous('skipAutoNextOnAdditionalDivers');
    }
  }, []);
  return (<></>);

  // return (
  //   <Row>
  //     <Col md='8'>
  //       <h5 className='sub-title'>
  //         Please enter customer details
  //         {lib.checkIsDemoSite() && '/ សូមបំពេញញព័ត៌មានលម្អិតរបស់អ្នកត្រូវបានធានារ៉ាប់រង'}
  //       </h5>
  //
  //       <p className='custom-text-primary'>
  //         Main Insured Details
  //         {lib.checkIsDemoSite() && '/ ព័ត៌មានលម្អិតរបស់អ្នកត្រូវបានធានារ៉ាប់រង'}
  //       </p>
  //       <RenderFields
  //         onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
  //         collections={collections}
  //         item={item}
  //         ext={lib.checkIsDemoSite()}
  //         validationErrors={validationErrors}
  //         fields={model.mainDetail.fields}
  //       />
  //
  //       <p className='custom-text-primary'>
  //         Contact Details
  //         {lib.checkIsDemoSite() && '/ ព័ត៌មានទំនាក់ទំនង'}
  //       </p>
  //       <RenderFields
  //         onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
  //         collections={collections}
  //         item={item}
  //         ext={lib.checkIsDemoSite()}
  //         validationErrors={validationErrors}
  //         fields={model.contactDetail.fields}
  //       />
  //
  //       <p className='custom-text-primary'>
  //         Spouse details
  //       </p>
  //       <RenderFields
  //         onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
  //         collections={collections}
  //         item={item}
  //         ext={lib.checkIsDemoSite()}
  //         validationErrors={validationErrors}
  //         fields={model.contactDetail.fields}
  //       />
  //
  //     </Col>
  //     <Col md='4'>
  //       <SidebarCard.Quotation
  //         item={item}
  //         model={sidebar}
  //         ext={lib.checkIsDemoSite()}
  //       />
  //     </Col>
  //   </Row>
  // );
};

export default SpouseDetails;
