
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    total: { id: 'properties.Price.Total', name: 'Total Price', ext: ' / តម្លៃសរុប', config: {} },
    config: {},
  },
  body: {
    items: [
      {
        id: 0,
        title: { id: 'Price Breakdown', name: 'Price Breakdown', ext: ' / តម្លៃលំអិត' },
        items: [
          { id: 'properties.Price.Main Policy', name: 'Gross Premium', ext: ' / បុព្វលាភដុល', config: { currency: true } },
          { id: 'properties.Price.Admin Fee', name: 'Admin Fee', ext: ' / សេវារដ្ឋបាល', config: { currency: true } },
        ],
        config: {},
      },
      {
        id: 1,
        title: { id: 'Other Details', name: 'Policy Details', ext: ' / ពត៌មានលំអិតពីបណ្ណសន្យារ៉ាប់រង' },
        items: [
          { id: 'properties.Plan', name: 'Plan', ext: ' / គម្រោង', config: {} },
          { id: 'properties.Trip Type', name: 'Trip Type', ext: ' / ប្រភេទនៃការធ្វើដំណើរ', config: {} },
          { id: 'properties.Country of Origin', name: 'Country of Origin', ext: ' / ប្រទេសដើម', config: {} },
          { id: 'properties.Destination', name: 'Destination', ext: ' / ប្រទេសគោលដៅ', config: {} },
          { id: 'properties.Start Date', name: 'Start Date', ext: ' / ថ្ងៃចាប់ផ្ដើម', config: {} },
          { id: 'properties.End Date', name: 'End Date', ext: ' / ថ្ងៃបញ្ចប់', config: {} },
          { id: 'properties.Insured Type', name: 'Insured Type', ext: ' / ប្រភេទនៃការធានារ៉ាប់រង', config: {} },
        ],
        config: {},
      },
    ],
    config: {},
  },
  config: {},
};

export default model;
