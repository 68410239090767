import BaseReducer from "./base";
import actionTypes from '@redux/actionTypes';

class ScopeManagementReducer extends BaseReducer<any> {
    
    public initialState: IObject;
    public actions: IDictionary<(state: IListState, action: IAction) => object>;
    protected actionTypes: IObject;

    constructor() {
        super();
        this.actionTypes = actionTypes.scopeManagement;
        this.initialState = {
            list: [],
            collections: {},
        };
        this.actions = this.createActions();
    
    }  
    createActions = (): IDictionary<(state: IListState, action: IAction) => IListState> => ({
        [this.actionTypes.GET_LIST]: this.getList,
        [this.actionTypes.SEARCH_LIST]: this.search,
        [this.actionTypes.ADD]: this.add,
        [this.actionTypes.DELETE]: this.delete,
        [this.actionTypes.EDIT]: this.edit,
        [this.actionTypes.RESET_DETAIL]: this.resetDetail

    })
      
    private getList = (state: IListState, action: IAction) =>{
        return {
            ...state,
            list : action.payload.Items.children,
            collections: this.prepareCollections(action.payload.collections)
        };
    };

    private search = (state:IListState, action:IAction) => {
        const data = action.payload.map((item:IObject)=>{
            return{
                ...item,
                parents: item.parents.join(' > ')
            }
        })
        return {
            ...state,
            list : data
        }
    }


    private add = (state: IListState, action: IAction) =>{
        return {
            ...state,

        };
    };

    private delete = (state: IListState, action: IAction) =>{
        return {
            ...state,

        };
    };

    private edit = (state: IListState, action: IAction) =>{
        return {
            ...state,

        };
    };



    private resetDetail = (state: IListState, action: IAction) =>{
        // when user leaves the detail page
        return {
            ...state,
        };
    };



      protected setLoading = (state: IListState) =>{
        return ({
          ...state,
          isLoading: true,
       })
      }

    private prepareCollections = (collections:IObject)=>{
        const keys = Object.keys(collections);
        let preparedCollections:IObject = {}
        keys.forEach((key, index) => {
            preparedCollections[key] = collections[key].map((item:IObject) => {
                return {...item,
                    id:item.name,
                    value:item.name,
                    label: item.name}
            } )
        });

        return preparedCollections;
    }  
      


}

export default new ScopeManagementReducer();