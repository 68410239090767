import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import {
  ExportButton, Filter, Header, Table,
} from '@xc-core/components/core';
import * as routes from '@xc-core/routes';
import BaseListingContainer from '../baseListingContainer';
import { actions } from '@redux';
import { dateLib, filterLib, stringLib } from '../../@xc-core/lib';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';


const leadManagementActions = new actions.LeadManagementActions();

class LeadList extends BaseListingContainer<any> {
  async componentDidMount(): Promise<void> {
    const { setList, getSearchParams } = this.props;
    setList(getSearchParams());
  }

  protected getParams = (): IRequestParam => {
    const { searchKeyword, pageInfo, filters } = this.props;
    let params: IRequestParam = {};

    if (pageInfo) {
      params.page = [stringLib.toString(pageInfo.pageNumber)];
      params.pageSize = [stringLib.toString(pageInfo.pageSize)];
    }
    if (searchKeyword) params.name = [searchKeyword];
    if (filters) {
      const filtersParams = filterLib.getRequestParamsChecked(filters);
      params = {
        ...params,
        ...filtersParams,
      };
    }
    return params;
  }

  onLeadExport = (pii: boolean) => {
    const date = dateLib.getCurrentDate('YYYYMMDD');
    const fileName = `Lead_Export_${date}.csv`;
    this.onExport(pii, fileName);
  }

  private rowClicked = (row: IObject) => {
    const { setCurrentLeadInfo } = this.props;
    setCurrentLeadInfo(row);
    this.onRowClicked(routes.lead.base, row.createdOn);
  }

  setList = async (): Promise<void> => {
    const { setList, getSearchParams } = this.props;
    const params = getSearchParams();
    setList(params);
  }

  render() {
    const {
      list, hasRecordsInitially = [], pageInfo, isLoading, filters = [],
      prevFilters = [], fields, searchKeyword,
    } = this.props;
    return (
      <div>
        <Header
          title='Lead Management'
          bannerGraphic={bannerImg}
          withBorderBottom
        />
        <div className='container-fluid px-0'>
          <div className='row d-flex justify-content-center my_30'>
            <Col sm='12' className='my-2 filter-viewType-container'>
              <Filter
                isLoading={isLoading}
                filterOptions={filters}
                onCheckChange={
                  (newFilters: IListingFilter[]) => this.onFilterCheckChange(newFilters)
                }
                applyFilter={(newFilters: IListingFilter[]) => this.onApplyFilter(newFilters)}
                cancelFilter={() => this.onCancelFilter(prevFilters)}
              />
            </Col>
            <div className='col-sm-12'>
              <Table
                titles={fields}
                records={list}
                hasRecords={hasRecordsInitially}
                image={bannerImg}
                onRowClicked={this.rowClicked}
                onRowButtonClicked={this.rowClicked}
                handleSearchKeyword={(pair: IPair) => this.onSearchChange(pair)}
                searchKeyword={searchKeyword}
                handlePageClick={(pair: IPair) => this.onPageClick(pair)}
                handlePageSizeChange={(pair: IPair) => this.onPageSizeChange(pair)}
                pagination={pageInfo}
                buttonIcon='pencil'
              >
                <ExportButton
                  exportToCsv={(pii: boolean) => this.onLeadExport(pii)}
                  hasDropDown={false}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ leadList }: IStore) => ({
  products: leadList.productList || [],
  item: leadList.item || {},
  list: leadList.list,
  searchKeyword: leadList.searchKeyword,
  fields: leadList.fields,
  filters: leadList.filters,
  isLoading: leadList.isLoading,
  pageInfo: leadList.pageInfo,
  prevFilters: leadList.prevFilters,
});
const mapDispatchToProps = (dispatch: any) => ({
  setList: (params: IRequestParam) => dispatch(leadManagementActions.setList(params)),
  getSearchParams: () => dispatch(leadManagementActions.getSearchParams()),
  setCurrentLeadInfo: (row: object) => dispatch(leadManagementActions.setCurrentLeadInfo(row)),
  changePage: (value: number) => dispatch(leadManagementActions.changePage(value)),
  changePageSize: (value: number) => dispatch(leadManagementActions.changePageSize(value)),
  exportCsv: (params: IRequestParam, fileName: string) => dispatch(
    leadManagementActions.getCsvFile(params, fileName),
  ),
  applyFilters: (filters: IListingFilter[]) => dispatch(
    leadManagementActions.applyFilters(filters),
  ),
  changeSearchKey: (keyword: string) => dispatch(leadManagementActions.changeSearchKey(keyword)),
  changeFilters: (filters: IListingFilter[]) => dispatch(
    leadManagementActions.changeFilters(filters),
  ),
  cancelFilters: (prefFilters: IListingFilter[]) => dispatch(
    leadManagementActions.cancelFilters(prefFilters),
  ),
  resetFilters: () => dispatch(leadManagementActions.resetFilters()),
  setHasRecordsInitially: () => dispatch(leadManagementActions.setHasRecordsInitially()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeadList);
