import React, {useState, useEffect, useRef} from 'react';
import { TablesUi } from '../../_components';
import {
    Input
  } from 'reactstrap';
import ButtonCircular from '@xc-core/components/core/buttonCircular';
import CollectionManagementService from '@xc-core/services/collectionManagement.service';
import { objLib, handleError, dateLib } from '@xc-core/lib';

const service = new CollectionManagementService();

const heading = [
    {
      id: 1,
      headerName: 'Collection Name',
      key: 'name',
    },
    {
      id: 2,
      headerName: 'Collection Code',
      key: 'code',
    },
    {
      id: 3,
      headerName: 'Collection File',
      key: 'fileName',
    },
    {
      id: 4,
      headerName: 'Created at',
      key: 'createdOn',
    },
    {
      id: 5,
      headerName: 'Updated At',
      key: 'updatedOn',
    },
    {
      id: 6,
      headerName: 'Updated by',
      key: 'updatedBy',
    },
    {
      id: 11,
      headerName: 'Action',
      key: 'action',
    },
    
  ];


const Body = (props:IObject) =>{
    const [generatedCollections,setGeneratedCollections] = useState<IObject[]>([]);
    const [fileName,setFileName] = useState<string>("");
    const {
        collections,
        onEdit,
        setEditItem,
        editItem,
        editItemIndex,
        editItemIsNew,
        fileInput,
        onDelete,
        onDownload,
    } = props;

    useEffect(()=>{
        setGeneratedCollections(generateCollections())
    },[collections,editItemIndex,editItem,fileName])

    useEffect(()=>{
      setFileName("");
    },[editItemIndex])

    useEffect(()=>{
      fileInput.current = document.getElementById('upload-collection-input')
    })

    const handleInputChange = (pairs:IPair[]) =>{
        pairs.forEach((pair:IPair)=>{
          setEditItem(objLib.setValueWithLodash(editItem,pair.id,pair.value))
        })
    }  
  
    const createNameCell = (name:string,index:number)=>{
        return (editItemIndex === -1 || editItemIndex !== index ) ? name : (
            <div
              style={{ width: '120px' }}
            >
              <Input
                value={editItem.name}
                type='text'
                style={{ width: '120px' }}
                className='mr-3'
                onChange={({ target: { value } }) => {handleInputChange([{id:'name',value:value}])}}
              />
            </div>)
    }  
    const createCodeCell = (code:string,index:number) => {
        return (editItemIsNew && editItemIndex === index) ? 
        (<div
              style={{ width: '120px' }}
            >
              <Input
                value={editItem.code}
                type='text'
                style={{ width: '120px' }}
                className='mr-3'
                onChange={({ target: { value } }) => {handleInputChange([{id:'code',value:value}])}}
              />
            </div>)
            :code;
    }  

    const createDateCells = (date:string,index:number) => {
        return (editItemIsNew && editItemIndex === index) ? "" : dateLib.applyFormat(date, dateLib.config.dateTimeMomentFormat);
    }

    const createActionCell = (index:number) =>{
      return (
      <div className="d-flex justify-content-center">
        {(editItemIndex === -1  || editItemIndex !== index) && <ButtonCircular
                  className='table-row-button mx-1'
                  handler={()=> {
                    setFileName("")
                    onEdit(index)
                  }}
                  key='row-action-edit'
                  icon='pencil'
                  id=''
                  name='details'
                />}
        {(editItemIndex !== -1 && editItemIndex === index)  && 
          <ButtonCircular
            className='table-row-button mx-1 text-success'
            handler={() => fileInput.current.click() }
            key='row-action-upload'
            icon='file'
            id=''
            name='details'
          />
        }
        {(editItemIsNew && editItemIndex === index) ? null :  <ButtonCircular
                  className='table-row-button mx-1 text-danger'
                  handler={() => onDelete(index)}
                  key='row-action-delete'
                  icon='garbage'
                  id=''
                  name='details'
                />}
        <input type="file" className="d-none" id="upload-collection-input" onChange={(e)=> {
          if(e.target.files && e.target.files[0]){
            setFileName(e.target.files[0].name)
          }
        }}/>        
      </div>
      )
    }
  
    const createFileCell = (code:string,index:number) =>{
      if(editItemIndex === -1 || editItemIndex !== index){
        return <span className="text-primary" style={{cursor:'pointer'}} onClick={()=> onDownload(code)} >download</span>
      } else if(editItemIndex !== -1 && editItemIsNew){
        if(fileName){
          return <span>{fileName}</span>
        } else {
          return ""
        }
      } else if (editItemIndex !== -1 && !editItemIsNew){
        if(fileName){
          return <span>{fileName}</span>
        } else {
          return <span className="text-primary" style={{cursor:'pointer'}} onClick={()=> onDownload(code)}>download</span>
        }
      }
    }

    const generateCollections = ()=>{
        return collections.map((collection:IObject,index:number)=>{
            return {
                name: createNameCell(collection.name,index),
                code: createCodeCell(collection.code,index),
                fileName: createFileCell(collection.code,index),
                createdOn: createDateCells(collection.common.createdOn,index),
                updatedOn: createDateCells(collection.common.updatedOn,index),
                updatedBy: collection.common.updatedBy,
                action: createActionCell(index)
            }
        })
    }

    return (
        <TablesUi
        openTop={true}
        horizontalScroll={true}
        tableClasses='center'
        heading={heading}
        tableData={generatedCollections}
    /> 
    )

}


export default Body;