const defaultFieldHandler = (pair: IPair): IPair[] => ([pair]);
const defaultFieldDisabled = (): boolean => false;


const nameModel:IField ={
    id:'name',
    type: 'Input',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'name'
}

const resourceModel:IField ={
    id:'resource',
    type: 'Input',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'resource'
}

const methodModel:IField ={
    id:'method',
    type: 'Select',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'method',
    collectionId: 'method'
}

const typeModel:IField ={
    id:'type',
    type: 'Select',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'type',
    collectionId: 'type'
}

// const parentResourceModel:IField ={
//     id:'type',
//     type: 'Select',
//     handler:defaultFieldHandler,
//     disabled: defaultFieldDisabled,
//     style: { col: '4' },
//     label:'type',
//     collectionId: 'type'
// }


const displayNameModel:IField = {
    id:'extendedProperties.displayName',
    type: 'Input',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'Display Name'
}

const linkModel:IField ={
    id:'extendedProperties.link',
    type: 'Input',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'Link'
}

const parentResourceModel:IField={
    id:'parentResource',
    type: 'AdvancedSelect',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'Parent Resource',
    collectionId: 'parentResource',
    isMulti: false
}

const dependenciesModel:IField={
    id:'dependencies',
    type: 'AdvancedSelect',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'Dependencies',
    collectionId: 'dependencies',
    isMulti: true
}

const auditedModel:IField={
    id:'audited',
    type: 'Switch',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'Audited'
}

const isPermissionVisibaleModel:IField= {
    id:'extendedProperties.isPermissionVisibale',
    type: 'Switch',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'isPermissionVisibale'
}

const isNavVisibleModel:IField= {
    id:'extendedProperties.isNavVisible',
    type: 'Switch',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'isNavVisible'
}

const iconModel:IField={
    id:'extendedProperties.icon',
    type: 'AdvancedSelect',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'Icon',
    collectionId: 'icons',
}

const visibleToModel: IField={
    id:'extendedProperties.isPermissionVisibleType',
    type: 'AdvancedSelect',
    handler:defaultFieldHandler,
    disabled: defaultFieldDisabled,
    style: { col: '4' },
    label:'PermissionVisibleType',
    collectionId: 'orgUnitType',
    isMulti: true
}


export {nameModel,resourceModel,
     methodModel, typeModel,
     displayNameModel, linkModel,
     parentResourceModel, dependenciesModel,
     auditedModel, isPermissionVisibaleModel,
     isNavVisibleModel, iconModel, visibleToModel,
     }