
export default abstract class BaseReducer<T extends IObject> {
  protected abstract createActions(): IDictionary<(state: T, action: IAction) => T>;

  public abstract initialState:T;

  public abstract actions: IDictionary<(state: T, action: IAction) => T>;

  protected abstract actionTypes: IBaseActionTypes;

  public changeState = (state:T = this.initialState, action:IAction):T => {
    if (action.type && this.actions[action.type]) {
      return this.actions[action.type](state, action);
    }
    return state;
  }

  protected setLoading = (state: T):T =>{
    return ({
    ...state,
    isLoading: true,
    error: null,
  })
}

  protected setError = (state:T, action: IAction):T => ({
    ...state,
    isLoading: false,
    error: { ...action.payload },
  })

  protected setNotFound = (state:T):T => ({
    ...state,
    notFound: true,
  })

  protected setForbidden = (state:T):T => ({
    ...state,
    accessIsForbidden: true,
  })

  protected setCreateForbidden = (state:T, action: IAction):T => ({
    ...state,
    isCreateForbidden: action.payload,
  })

  protected setUpdateForbidden = (state:T, action: IAction):T => ({
    ...state,
    isUpdateForbidden: action.payload,
  })

  protected setProduct = (state:T, action: IAction):T => ({
    ...state,
    product: action.payload,
  })

  protected applyFilter = (state:T, action: IAction):T => ({
    ...state,
    filters: [...action.payload],
    prevFilters: [...action.payload],
  })

  protected changeFilter = (state:T, action: IAction):T => ({
    ...state,
    filters: [...action.payload],
  })

  protected resetFilter = (state:T):T => ({
    ...state,
    initialFilters: [],
    filters: [],
    prevFilters: [],
  })

  protected setCreatePermission = (state:T, action: IAction):T => ({
    ...state,
    isCreatePermitted: action.payload,
  })

  protected setUpdatePermission = (state:T, action: IAction):T => ({
    ...state,
    isUpdatePermitted: action.payload,
  })

  protected reset = ():T => ({
    ...this.initialState,
  })

  protected hasRecordsInitially = (state:T, action: IAction):T => ({
    ...state,
    hasRecordsInitially: action.payload,
  })
}
