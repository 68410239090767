function defaultDisabled() {
    return false;
}

function defaultHandler(pair: any) {
    return pair;
}

const model = {
    body: {
        items: {
            mainApplicant: {
                id: 'properties.main applicant',
                items: [
                    {
                        id: 'properties.main applicant.Full Name',
                        name: 'Full Name',
                        type: "Input",
                        placeholder: "Enter Full Name",
                        disabled: defaultDisabled,
                        handler: defaultHandler,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.DOB',
                        name: 'DOB',
                        type: 'DatePicker',
                        placeholder: `Select Date`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.ID Type',
                        name: 'ID Type',
                        type: 'Select',
                        placeholder: `Select Options`,
                        collectionId: 'idTypes',
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.ID Number',
                        name: 'ID Number',
                        type: 'Input',
                        placeholder: `Enter ID Number`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.Gender',
                        name: 'Gender',
                        type: 'Input',
                        placeholder: `Enter Gender`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.Email Address',
                        name: 'Email Address',
                        type: 'Input',
                        placeholder: `Enter Email Address`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.Mobile Number',
                        name: 'Mobile Number',
                        type: 'Input',
                        placeholder: `Enter Mobile Number`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.Address',
                        name: 'Address',
                        type: 'Input',
                        placeholder: `Enter Address`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.City',
                        name: 'City',
                        type: 'Input',
                        placeholder: `Enter City`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.State',
                        name: 'State / Province / Region',
                        type: 'Input',
                        placeholder: `Enter State`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.Postcode',
                        name: 'Postcode',
                        type: 'Input',
                        placeholder: `Enter Postcode`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.main applicant.Country',
                        name: 'Country',
                        type: 'Input',
                        placeholder: `Enter Country`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                ],
                config: { editable: true },
            },
            insuredProperty: {
                id: 'properties.Insured Property',
                items: [
                    {
                        id: 'properties.Insured Property.Address',
                        name: 'Address',
                        type: 'Input',
                        placeholder: `Enter Address`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.Insured Property.City',
                        name: 'City',
                        type: 'Input',
                        placeholder: `Enter City`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },

                    {
                        id: 'properties.Insured Property.State',
                        name: 'State/Province/Region',
                        type: 'Input',
                        placeholder: `Enter State`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.Insured Property.Postcode',
                        name: 'Postcode',
                        type: 'Input',
                        placeholder: `Enter Postcode`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    },
                    {
                        id: 'properties.Insured Property.Country',
                        name: 'Country',
                        type: 'Input',
                        placeholder: `Enter Country`,
                        handler: defaultHandler,
                        disabled: defaultDisabled,
                        config: {}
                    }
                ],
                config: { editable: true },
            },
        },
        config: {},
    },
};

export default model;
