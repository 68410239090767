//other server keys will be updated
let token = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')).token : ''

const url = '/services/tds';
//const url = 'http://1d734b42.ngrok.io';
const quotationUrl = '/services/quote';
const policyUrl = '/services/policy';
const productsUrl = '/services/rate';
const auditUrl = '/services/audit';
const siteKey = '6LczYw4pAAAAAMJZcT786HaIIKyRdgINdJMO3alg';
const isEnabledReCaptcha= false;
export const env = {
	apiURL: url,
	apiVersion: `${url}`,
	quotationUrl: quotationUrl,
	policyUrl,
	productsUrl,
	auditUrl,
	token,
	siteKey,
	isEnabledReCaptcha,
};
