import env from 'env';
import Request from './request';
import Response from './response';
import BaseListingService from './baseListing';

class PolicyService extends BaseListingService {
  protected env = env.policy;

  protected api = 'policies';

  protected withApi = 'with-collections';

  public getDashboard = async (): Promise<any> => {
    const url = `${this.env}/${this.api}/dashboard`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public download = async (id: string): Promise<any> => {
    const url = `${this.env}/${this.api}/download?idOrCode=${id}`;
    const request = new Request();
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }

  public async getCollectionList(list: string[]): Promise<IObject> {
    const url = `${this.env}/collections/list`;
    const obj = { groupList: list };
    const request = new Request(obj, 'POST');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public async getWitnessSignature(refId: string): Promise<any> {
    const url = `${this.env}/${this.api}/witness-image?refCode=${refId}`;
    const request = new Request(null, 'GET');
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }

  public async requestBulkCancellationPolicies(policiesRefCodesList:string[]): Promise<any> {
    const url = `${this.env}/${this.api}/bulk-cancellation-request`;
    const request = new Request({
      policiesRefCodesList
    }, 'POST');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public async confirmBulkCancellationPolicies(obj:{requestId:string;  remarks:string;}): Promise<any> {
    const url = `${this.env}/${this.api}/bulk-cancellation-confirm`;
    const request = new Request(obj, 'POST');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
}

export default PolicyService;
