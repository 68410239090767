const main = [
  { id: 'Full Name', name: 'Full Name' },
  { id: 'Nationality', name: 'Nationality' },
  { id: 'ID Type', name: 'ID Type' },
  { id: 'ID Number', name: 'ID Number' },
  { id: 'Gender', name: 'Gender' },
  { id: 'Debit Card or Account No', name: 'Debit Card / Account No' },
  { id: 'Address', name: 'Address' },
  { id: 'City', name: 'City / Town' },
  { id: 'State', name: 'State / Province / Region' },
  { id: 'Country', name: 'Country' },
  { id: 'Postcode', name: 'Postcode' },
  { id: 'Email Address', name: 'Email Address' },
  { id: 'Contact Number', name: 'Phone Number' },
];

const beneficiary = [
  { id: 'Full Name', name: 'Full Name' },
  { id: 'Relationship', name: 'Relationship' },
  { id: 'ID Type', name: 'ID Type' },
  { id: 'ID Number', name: 'ID Number' },
  { id: 'Email Address', name: 'Email Address' },
  { id: 'Contact Number', name: 'Phone Number' },
];

export { main, beneficiary };
