import React from 'react';
import { objLib } from '@xc-core/lib';

const Checkbox = (props: ICheckBoxFormControl)=>{
    const {
        handler, id,  name, className,
        disabled, autoFocus, validator,
        validationMessage,validationName,
        validationData, validationRules, 
        checked
      } = props;

      const onChange = (e: any) => {
        const pair = objLib.getPair(id, e.target.checked);
        handler(pair)
      }

    return <div
        className={`xc-custom-checkbox mr-2 ${className ? className : ''} ${disabled ? 'disabled' : ''}`}
    >
        <label htmlFor={id}>
        <input id={id} type="checkbox" name={name} checked={checked} onChange={onChange} autoFocus={autoFocus}/>
        <span className='box'></span>
        <span className='lever'></span>
        </label>
        {validator 
            ? <div>{validator.message(validationName ? validationName : name,
            validationData ? validationData : String(checked),
            validationRules ? validationRules : "") }</div>
            : ''}
        {
            validationMessage ? <div className='text-danger-step'>{validationMessage}</div> : <></>
        }
    </div>
}

export default Checkbox;