function defaultDisabled() {
    return false;
}

function defaultHandler(pair: IPair) {
    return pair;
}
const model = {
    header: {
        productName: {id: 'productName', name: '', config: {}},
        plan: {id: 'properties.Plan', name: '', config: {}},
        items: [
            {
                current: {
                    id: 'properties.Price.Total',
                    name: 'Total Price',
                    config: {}
                },
                prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
                config: {},
            },
        ]
    },
    body: {
        items: [
            {
                id: 0,
                name: 'Price Breakdown',
                items: [
                    {
                        current:{
                            id: 'properties.Price.Main Policy',
                            name: 'Gross Premium',
                            ext: '',
                            config: {currency: true}
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current:{
                            id: 'properties.Price.Admin Fee',
                            name: 'Admin Fee',
                            ext: '',
                            config: {currency: true}
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.Price.Tax',
                            name: 'Tax',
                            ext: '',
                            config: {currency: true}
                        },
                        prev: {config: {}},
                        config: {}
                    }
                    ,
                ],
                config: {},
            },
            {
                id: 2,
                name: 'Other Details',
                items: [
                    {
                        current: {
                            id: 'properties.Building Type',
                            name: 'Building Type',
                            type: 'Select',
                            collectionId: "buildingTypes",
                            placeholder: "Select Options",
                            config: {},
                            disabled: defaultDisabled,
                            handler: defaultHandler,
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.Construction Type',
                            name: 'Construction Type',
                            type: 'Select',
                            collectionId: "constructionTypes",
                            placeholder: "Select Options",
                            config: {},
                            disabled: defaultDisabled,
                            handler: defaultHandler,
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.Building Sum Assured',
                            name: 'Building Sum Assured',
                            type: 'Input',
                            placeholder: "Enter Building Sum Assured",
                            config: {currency: true},
                            disabled: defaultDisabled,
                            handler: defaultHandler,
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.Contents Sum Assured',
                            name: 'Contents Sum Assured',
                            type: 'Input',
                            placeholder: "Enter Contents Sum Assured",
                            config: {currency: true},
                            disabled: defaultDisabled,
                            handler: defaultHandler,
                        },
                        prev: {config: {}},
                        config: {}
                    },
                ],
                config: { isEditable: true },
            }
        ],
    },
};

export default model;
