import React from "react";
import { Col } from "reactstrap";
import { CommonInputTypes } from "_components";
import { defaultValues } from '@products/AlfalahWallet/config';

const ContactDetails = (props) => {
	const { fields, collections, validator, handleChange } = props;
	return (
	  <>
			<Col sm="12">
				<CommonInputTypes
					id={1}
					idName="address"
					type="input"
					title="Address"
					group={false}
					placeholder="Enter address"
					onChange={(e) => props.handleChange(e)}
					validator={validator}
					validationRules="customRequired"
					value={fields.address}
					autoFocus={true}
				/>
			</Col>
			<Col sm="6">
			  <CommonInputTypes
			    id={2}
					idName="country"
			    type="select"
			    onChange={(e) => props.handleChange(e)}
			    title="Country"
			    group={false}
					option={collections.countries}
			    value= {fields.country}
				autoFocus={true}
				disabled
			  />
			</Col>
			<Col sm="6">
				<CommonInputTypes
					id={3}
					idName="state"
					type="select"
					title="State/Province/Region"
					group={false}
					option={collections.states}
					value={fields.state}
					onChange={(e) => props.handleChange(e)}
					// validator={validator}
					validationRules="customRequired"
					autoFocus={true}
				/>
			</Col>
		<Col sm="6">
		  <CommonInputTypes
		    id={4}
				idName="postcode"
				title="Postcode"
				type="text"
		    placeholder="Enter postcode"
		    group={false}
				onChange={handleChange}
		    // validator={fields.postcode ? validator: ''}
		    validationRules={fields.postcode ? `min:${defaultValues.postcodeLength}|max:${defaultValues.postcodeLength}` : ''}
		    value={fields.postcode}
		    autoFocus={true}
		  />
		</Col>
		<Col sm="6">
		  <CommonInputTypes
		    id={5}
				idName="city"
				type="input"
				title="City/Town"
				group={false}
		    placeholder="Enter City/Town"
				onChange={(e) => props.handleChange(e)}
		    validator={validator}
		    validationRules="customRequired"
		    value={fields.city}
		    autoFocus={true}
		  />
		</Col>
		<Col sm="6">
		  <CommonInputTypes
				id={6}
				idName="email"
		    type="email"
				title="Email Address"
		    group={false}
				placeholder="Enter email address"
				onChange={(e) => props.handleChange(e)}
		    validator={props.validator}
		    validationRules="customRequired|email"
		    value={fields.email}
		    autoFocus={true}
		  />
		</Col>
		<Col sm="6">
		  <CommonInputTypes
		    id={7}
				idName="contactNumber"
		    type="text"
				title="Contact Number"
		    group={false}
				placeholder="Enter contact number"
				onChange={(e) => props.handleContactNumberChange(e)}
		    validator={props.validator}
		    validationRules={`customRequired|numbersOnly|min:${defaultValues.contactNumberLength}|max:${defaultValues.contactNumberLength}`}
		    value={fields.contactNumber}
		    autoFocus={true}
		  />
		</Col>
		</>
	);
};

export default ContactDetails;
