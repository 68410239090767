const scopeManagementActionTypes = {
    GET_LIST: 'GET_LIST_SCOPES',
    SEARCH_LIST: 'SEARCH_LIST_SCOPES',
    ADD: 'ADD_SCOPE',
    DELETE: 'DELETE_SCOPE',
    EDIT: 'EDIT_SCOPE',
    RESET_DETAIL: 'RESET_DETAIL_SCOPE',

  };
  
  export default scopeManagementActionTypes;
  