import {
	UPDATE_SEARCH_VALUE,
	INCREMENT_CHECKED_FIELDS_COUNT,
	DECREMENT_CHECKED_FIELDS_COUNT,
	RESET_CHECKED_FIELDS_COUNT,
} from "../_constants";

export function searchValue(state = "", { type, payload }) {
	switch (type) {
	case UPDATE_SEARCH_VALUE:
		return payload;
	default:
		return state;
	}
}

export function checkedFilterFieldCount(state = 0, { type }) {
	switch (type) {
	case INCREMENT_CHECKED_FIELDS_COUNT:
		return state + 1;
	case DECREMENT_CHECKED_FIELDS_COUNT:
		return state - 1;
	case RESET_CHECKED_FIELDS_COUNT:
		return 0;
	default:
		return state;
	}
}
