import React from 'react';
import { Col } from 'reactstrap';
import { filterLib } from '@xc-core/lib';

const RenderSelectedValues = (props: { values: any, type: string, id: string }) => {
  const { values, type, id } = props;
  let selection = '';

  const concatNames = () => {
    const temp = [];
    temp.push(values.map((value: any) => value.name));
    return temp.join(',');
  };

  if (type === filterLib.dateRangeType) {
    const startDate = values.find((value: any) => value.name === 'Start Date').value;
    const endDate = values.find((value: any) => value.name === 'End Date').value;
    // selection = `${dateLib.applyFormat(startDate, config.dateMomentFormat)}
    // to ${dateLib.applyFormat(endDate, config.dateMomentFormat)}`;
    selection = `${startDate} to ${endDate}`;
  } else {
    selection = concatNames();
  }
  return (
    <span key={id}>
      {`${selection}`}
    </span>
  );
};

const SelectedFiltersCol = (props: { filters: any }) => {
  const { filters } = props;
  return (
    <Col sm='4' className='p-0'>
      <div>
        <div className='card-header xc-selectedfilter-header'>
          <span>Filters Selected</span>
        </div>
        <div className='card-body xc-selectedfilters'>
          {filters != null
          && filters.map((filter: any) => (
            <div className='xc-selectedfilters-section' key={filter.id}>
              {filter.checkedCount >= 1 && <p>{filter.displayName}</p>}
              {
                (filter.checkedCount > 0)
                  ? (
                    <RenderSelectedValues
                      id={filter.id}
                      values={filter.list.filter((item: any) => item.isChecked)}
                      type={filter.type}
                    />
                  )
                  : <></>
              }
            </div>
          ))}
        </div>
      </div>
    </Col>
  );
};

export default SelectedFiltersCol;
