const userMessages = {
	QUOTATIONS: "quotations",
	CERTIFICATES: "certificates",
	UNIMPLEMENTED_PRODUCT: "Sales Journey for this product is not available yet.",
	NO_TEMPLATE: "Listing for this product is not available yet.",
	NOT_ALLOWED: "You are not allowed to perform this action.",
	WAIT: "Please wait...",
	// getAccessDenied: (name="this") => `Yor are not allowed to view ${name}.`
};

export default userMessages;
