import { handleResponse, logout, handleRequest} from "../../../_services";
import {env} from "_config/env";
import {getRequestOptions} from "../../../utils";

const {
  apiVersion: apiUrl,
  policyUrl,
  quotationUrl,
  productsUrl,
} = env;

export const userService = {
  login,
  logout,
};

async function login(username, password) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      username,
      password
    })
  };
  let serverResponse;
  let user;
  try {
    serverResponse = await handleRequest(`${apiUrl}/identity/users/login`, requestOptions);
    user = await serverResponse.json();
    if (user.name && user.username) {
      const authObject = {
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token' : `${user.token}`,
        },
        method: 'get',
      };
      const quotationAuthentication = await handleRequest(`${quotationUrl}/auth`, authObject);
      await handleResponse(quotationAuthentication);
      const policyAuthentication = await handleRequest(`${policyUrl}/auth`, authObject);
      await handleResponse(policyAuthentication);
      await handleRequest(`${productsUrl}/auth?auth_token=${user.token}`, getRequestOptions());

      // delete user.token;
      localStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('user', JSON.stringify(user));
    }
    return user;
  } catch (e) {
    console.log("e", e);
    if (e.message === "Failed to fetch") {
      return {
        error: { message: "server error" },
      }
    }
    return {
      error: { message: 'unable to login' },
    };
  }
}