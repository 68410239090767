import React, {useEffect} from 'react';
import {Col, Row} from 'reactstrap';
import {SidebarCard} from '@xc-core/components';
import {sidebarModel} from 'models';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import {fieldLib, objLib} from '@xc-core/lib';
import lib from 'utils/lib';
import {connect} from "react-redux";
import {additionalDrivers} from "../../../models/detail/motorInsurance";

interface ILoadableComponentPropsAdditionalDrivers extends ILoadableComponentProps {
    storeItem: IObject,
}

const AdditionalDrivers = (props: ILoadableComponentPropsAdditionalDrivers) => {
    const {
        storeItem,
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            initValues,
            sidebar,
            next,
            skipAutoNextOnAdditionalDivers,
            previous
        },
    } = props;
    const numberOfDriver = storeItem?.properties["Number of Additional Drivers"] || 0
    useEffect(() => {
        if (!skipAutoNextOnAdditionalDivers) {
            const numberOfDriver = storeItem?.properties["Number of Additional Drivers"] || 0
            if (numberOfDriver == 0) {
                next();
                return;
            }
            const additionalDriver = objLib.getValueWithLodash(item, model.fields.additionalDrivers.id) || {};
            const pairs: IPair[] = [{id: model.fields.additionalDrivers.id, value: additionalDriver}];
            initValues(pairs);
            setTimeout(() => {
                new Array(numberOfDriver).fill("Driver").map((item, index: number) => {
                    onChange('onChange', {
                        pairs: [
                            {
                                "id": `properties.additional drivers[${index}].id`,
                                "value": `${index}`
                            }
                        ]
                    })
                });
            }, 400)
        }else{
            previous("skipAutoNextOnAdditionalDivers")
        }
    }, []);


    //TODO remove condition and use iterator and dynamic index to avoid duplicate code
    return (
        <Row>
            <Col md='8'>
                <h5 className='sub-title'>Please enter Additional Drivers details </h5>
                {numberOfDriver >= 1 ? (
                    <>
                        <h6 className='custom-text-primary'>Additional Driver [1]</h6>
                        <RenderFields
                            onChange={(pairs: IPair[]) => {
                                onChange('onChange', {pairs})
                            }}
                            collections={collections}
                            item={item}
                            ext={lib.checkIsDemoSite()}
                            validationErrors={validationErrors}
                            fields={fieldLib.applyIndexToFieldsRawId(0, model.additionalDriverDetail.fields)}
                        />
                    </>
                ) : null}
                {numberOfDriver >= 2 ? (
                    <>
                        <h6 className='custom-text-primary'>Additional Driver [2]</h6>
                        <RenderFields
                            onChange={(pairs: IPair[]) => {
                                onChange('onChange', {pairs})
                            }}
                            collections={collections}
                            item={item}
                            ext={lib.checkIsDemoSite()}
                            validationErrors={validationErrors}
                            fields={fieldLib.applyIndexToFieldsRawId(1, model.additionalDriverDetail.fields)}
                        />
                    </>
                ) : null}
                {numberOfDriver >= 3 ? (
                    <>
                        <h6 className='custom-text-primary'>Additional Driver [3]</h6>
                        <RenderFields
                            onChange={(pairs: IPair[]) => {
                                onChange('onChange', {pairs})
                            }}
                            collections={collections}
                            item={item}
                            ext={lib.checkIsDemoSite()}
                            validationErrors={validationErrors}
                            fields={fieldLib.applyIndexToFieldsRawId(2, model.additionalDriverDetail.fields)}
                        />
                    </>
                ) : null}
                {numberOfDriver >= 4 ? (
                    <>
                        <h6 className='custom-text-primary'>Additional Driver [4]</h6>
                        <RenderFields
                            onChange={(pairs: IPair[]) => {
                                onChange('onChange', {pairs})
                            }}
                            collections={collections}
                            item={item}
                            ext={lib.checkIsDemoSite()}
                            validationErrors={validationErrors}
                            fields={fieldLib.applyIndexToFieldsRawId(3, model.additionalDriverDetail.fields)}
                        />
                    </>
                ) : null}

            </Col>
            <Col md='4'>
                <SidebarCard.Quotation
                    customIconProduct={"icon-motor-insurance"}
                    item={item}
                    model={sidebar}
                    ext={lib.checkIsDemoSite()}
                />
            </Col>
        </Row>
    );
};
const mapStateToProps = ({newGetQuote}: IObject) => ({
    storeItem: newGetQuote.item,
});
export default connect(mapStateToProps, () => ({}))(AdditionalDrivers);

