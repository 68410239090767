import env from 'env';
import Request from './request';
import Response from './response';
import BaseListingService from './baseListing';

class QuotationService extends BaseListingService {
  protected env = env.quotation;

  protected api = 'quotations';

  protected withApi = 'with-collections';

  // https://xc-quote-test.finology.com.my/quotations/Pakistan/states?collection=state_alf
  public async getStates(country: string, param: string): Promise<any> {
    const url = `${this.env}/${this.api}/${country}/states?collection=${param}`;
    const request = new Request(null);
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public async getCollectionList(list: string[]): Promise<IObject> {
    const url = `${this.env}/collections/list`;
    const obj = { groupList: list };
    const request = new Request(obj, 'POST');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public async getWitnessSignature(refId: string): Promise<any> {
    const url = `${this.env}/${this.api}/witness-image?refId=${refId}`;
    const request = new Request(null, 'GET');
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }
}

export default QuotationService;
