import React from 'react';
import { Col } from 'reactstrap';
import InputAnimatedLabel from '../inputAnimatedLabel';

const Search = (props:{ children: Node, keyword: string, onKeywordChange: Function, classes?:string }) => {
  const { children, keyword, onKeywordChange, classes } = props;
  const inputChange = (pair: IPair) => {
    onKeywordChange(pair);
  };
  return (
    <div className={`additionalTableHeader overflow-unset align-items-center py-1 d-flex px-1 ${classes ? classes : ''}`}>
      <Col xs='8'>
        <InputAnimatedLabel
          placeholder='Search'
          id='search'
          name='search'
          icon='search'
          className='customSearchinput'
          value={keyword}
          handler={(pair) => inputChange(pair)}
        />
      </Col>
      <Col xs='4' className='text-right'>
        {children}
      </Col>
    </div>
  );
};

export default Search;
