import React from 'react';

const OthersBenefitTable = (props) => {
    return(
			<div className="benefit-table">
				<p>STANDARD PRODUCT BENEFIT TABLE - TRAVEL INSURANCE</p>
        <table>
						<thead>
							<tr>
								<th rowSpan={1} colSpan={5} className="invisible"></th>
								<th rowSpan={1} colSpan={2}>USD</th>
							</tr>
							<tr>
								<th rowSpan={2} colSpan={3}>COVERAGE</th>
								<th rowSpan={2} colSpan={2}>BENEFIT</th>
								<th rowSpan={1} colSpan={2}>LIMIT OF LIABILITY</th>
							</tr>
							<tr>
								<th rowSpan={1} colSpan={1}>STANDARD</th>
								<th rowSpan={1} colSpan={1}>EXECUTIVE</th>
							</tr>
						</thead>
            <tbody>
							<tr>
								<td colSpan={1} rowSpan={5} className="category">A</td>
								<td colSpan={2} rowSpan={5} className="category">Emergency Service Expenses</td>
								<td colSpan={2} rowSpan={1}>Medical and hospitalization expenses</td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
								<td colSpan={1} rowSpan={1} className="price">70,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Transport or repatriation in the event of illness or accident expenses &amp; repatriation expenses</td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Repatriation of Mortal Remain expenses</td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
								<td colSpan={1} rowSpan={1} className="price">70,000</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Travel of one immediate family member expenses</td>
								<td colSpan={1} rowSpan={1} className="price">1,800</td>
								<td colSpan={1} rowSpan={1} className="price">3,500</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Child Guard expenses</td>
								<td colSpan={1} rowSpan={1} className="price">100</td>
								<td colSpan={1} rowSpan={1} className="price">200</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>

							<tr>
								<td colSpan={1} rowSpan={1} className="category">B</td>
								<td colSpan={2} rowSpan={1} className="category">Travel Cancellation Expenses</td>
								<td colSpan={2} rowSpan={1}>Travel cancellation or curtailment expenses</td>
								<td colSpan={1} rowSpan={1} className="price">700</td>
								<td colSpan={1} rowSpan={1} className="price">1,500</td>
							</tr>

							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">C</td>
								<td colSpan={2} rowSpan={4} className="category">Benefits for Losses dan Delay</td>
								<td colSpan={2} rowSpan={1}>Travel disruption</td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
								<td colSpan={1} rowSpan={1} className="price">700</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Travel postponement</td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
								<td colSpan={1} rowSpan={1} className="price">700</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Travel misconnection</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Delayed Departure</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">D</td>
								<td colSpan={2} rowSpan={4} className="category">Benefits For Luggage</td>
								<td colSpan={2} rowSpan={1}>Compensation for in-flight loss, robbery or destruction of baggage checked-in</td>
								<td colSpan={1} rowSpan={1} className="price">1,600 (max 400 per item)</td>
								<td colSpan={1} rowSpan={1} className="price">800 (max 200 per item)</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Delay in the arrival of luggage</td>
								<td colSpan={1} rowSpan={1} className="price">180 (45 every 6 hours)</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Loss of passport, driving license or ID Card</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Discovery &amp; delivery of luggage and personal effects</td>
								<td colSpan={1} rowSpan={1} className="price">100</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">E</td>
								<td colSpan={2} rowSpan={4} className="category">Additional Benefit</td>
								<td colSpan={2} rowSpan={1}>Drugs delivery</td>
								<td colSpan={1} rowSpan={1} className="price">100</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Aircraft Hijacking</td>
								<td colSpan={1} rowSpan={1} className="price">35 per hari (max 350)</td>
								<td colSpan={1} rowSpan={1} className="price">70 per hari (max 700)</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Emergency phone call</td>
								<td colSpan={1} rowSpan={1} className="price">100</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Automatic of extension perio</td>
								<td colSpan={1} rowSpan={1} className="price">Yes</td>
								<td colSpan={1} rowSpan={1} className="price">Yes</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={2} className="category">F</td>
								<td colSpan={2} rowSpan={2} className="category">Benefits For Personal Accident and</td>
								<td colSpan={2} rowSpan={1}>Death &amp; Permanent Disablement due to accident</td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
								<td colSpan={1} rowSpan={1} className="price">70,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Partial Permanent Disablement</td>
								<td colSpan={1} rowSpan={1} className="price">Sesuai Persentase Tabel Santunan</td>
								<td colSpan={1} rowSpan={1} className="price">Sesuai Persentase Tabel Santunan</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={2} className="category">G</td>
								<td colSpan={2} rowSpan={2} className="category">Benefits For Personal Liability</td>
								<td colSpan={2} rowSpan={2}>Personal Liability</td>
								<td colSpan={1} rowSpan={2} className="price">35,000</td>
								<td colSpan={1} rowSpan={2} className="price">70,000</td>
							</tr>
            </tbody>
        </table>
    </div>);
}

export default OthersBenefitTable;