export const policyConstants ={
  GET_POLICIES_LIST: "GET_POLICIES_LIST",
  POLICIES_APPLY_FILTER: "POLICIES_APPLY_FILTER",
  GET_POLICY_DETAIL: "GET_POLICY_DETAIL",
  UPDATE_POLICY_DETAIL: "UPDATE_POLICY_DETAIL",
  SET_CSV_BLOB: 'SET_POLICY_CSV_BLOB',
  RESET_CSV_BLOB: 'RESET_POLICY_CSV_BLOB',
  DOWNLOAD_POLICY_DETAIL: "DOWNLOAD_POLICY_DETAIL",
  CLEAR_BLOB: "CLEAR_POLICY_BLOB",
  TODAY_POLICY_APPLY_FILTER: "TODAY_POLICY_APPLY_FILTER",
  SET_FORBIDDEN_POLICIES: "SET_FORBIDDEN_POLICIES",
  CLEAR_POLICY_DETAIL: 'CLEAR_POLICY_DETAIL',
  POLICY_RESET_FILTER: 'POLICY_RESET_FILTER',
  CHANGE_CURRENT_POLICY_PRODUCT: 'CHANGE_CURRENT_POLICY_PRODUCT',
  GET_POLICY_PRODUCTS: 'GET_POLICY_PRODUCTS',
  CLEAR_POLICY_LIST: 'CLEAR_POLICY_LIST',
  GET_STATES: "GET_STATES",
  RESET_KEEP_FILTERS: "RESET_KEEP_FILTERS",
  SET_APPLY_TODAY_POLICY_FILTER: "SET_APPLY_TODAY_POLICY_FILTER",
  RESET_TODAY_POLICY_FILTER: "RESET_TODAY_POLICY_FILTER",
  POLICY_APPLY_TODAY_FILTER: "POLICY_APPLY_TODAY_FILTER",
  SET_CURRENT_POLICY_PRODUCT_TO_ALL: "SET_CURRENT_POLICY_PRODUCT_TO_ALL",
  SET_POLICY_ENDORSEMENT_CHECK_DATA: 'SET_POLICY_ENDORSEMENT_CHECK_DATA',
  TOGGLE_PRICE_UPDATE_MODAL: 'TOGGLE_PRICE_UPDATE_MODAL',
  POLICY_UPDATE_COLLECTIONS: 'POLICY_UPDATE_COLLECTIONS',
  SET_ENDORSEMENT_PERMISSIONS: 'SET_ENDORSEMENT_PERMISSIONS',
};
