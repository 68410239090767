import React, { useEffect, useRef } from 'react';

export default (props: IObject) => {
  const formRef = useRef(null);
  const { item } = props;
  const {
    id: RefNo,
    productName,
    properties: {
      paymentId,
      currencyCode,
      signature,
      responseUrl,
      backendCallBackUrl,
      paymentRefCode,
      // paymentResponse,
      merchantCode,
      'main applicant': {
        'Contact Number': UserContact,
        'Email Address': UserEmail,
        'Full Name': UserName,
      },
      Price: { Total: Amount },
    },
  } = item;
  useEffect(() => {
    if (merchantCode && merchantCode !== '') {
      // @ts-ignore
      formRef.current.click();
    }
  }, [merchantCode]);
  return (
    <form
      className='d-none'
      method='post'
      name='ePayment'
      action='https://sandbox.ipay88.com.kh/epayment/entry.asp'
    >
      <input type='input' name='Currency' value={currencyCode} />
      <p>currencyCode</p>
      <input type='input' name='Signature' value={signature} />
      <p>signature</p>
      <input type='input' name='ResponseURL' value={responseUrl} />
      <p>responseUrl</p>
      <input type='input' name='BackendURL' value={backendCallBackUrl} />
      <p>backendCallBackUrl</p>
      <input type='input' name='MerchantCode' value={merchantCode} />
      <p>merchantCode</p>

      <input type='input' name='UserContact' value={UserContact} />
      <p>UserContact</p>
      <input type='input' name='UserEmail' value={UserEmail} />
      <p>UserEmail</p>
      <input type='input' name='UserName' value={UserName} />
      <p>UserName</p>
       {/* <input type='input' name='Amount' value='1.00' /> */}
      <input type='input' name='Amount' value={Amount} />
      <p>Amount</p>
      <input type='input' name='RefNo' value={paymentRefCode} />
      <p>RefNo</p>

      <input type='hidden' name='ProdDesc' value={`CB Insurance - ${productName}`} />
      <input type='hidden' name='Remark' value='' />
      <input type='hidden' name='Lang' value='UTF-8' />
      <input type='submit' value='Proceed with Payment' name='Submit' ref={formRef} />
    </form>
  );
};
