import React from 'react';

const benefitScheduleFlexiPaTable = () => {
    return(
		<div className="benefit-table">
			<p>BENEFITS SCHEDULE FLEXI PA / តារាងអត្ថប្រយោជន៍ផលិតផលធានារ៉ាប់រងគ្រោះថ្នាក់បុគ្គល</p>
        	<table>
				<thead className="blue-header">
					<tr>
						<th colSpan={2}>COVERAGES / វិសាលភាពធានារ៉ាប់រង</th>
						<th>PLAN 1</th>
						<th>PLAN 2</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="center">1</td>
						<td>Death/Permanent/Partial Disablement / មរណភាព ឬ ពិការភាពអចិន្ត្រៃយ៍ទាំងស្រុង ឬដោយផ្នែក</td>
						<td className="fixPrice">$5,000</td>
						<td className="fixPrice">$10,000</td>
					</tr>
					<tr>
						<td className="center">2</td>
						<td>Medical Expenses / ថ្លៃចំណាយវេជ្ជសាស្រ្ដ </td>
						<td className="fixPrice">$500</td>
						<td className="fixPrice">$1,000</td>
					</tr>
					<tr>
						<td className="center">3</td>
						<td>Funeral Expenses / ថ្លៃចំណាយពិធីបុណ្យសព </td>
						<td className="fixPrice">$200</td>
						<td className="fixPrice">$200</td>
					</tr>

				</tbody>
        	</table>
				
    	</div>);
}

export default benefitScheduleFlexiPaTable;