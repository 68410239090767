/* eslint-disable react/require-default-props */
import React from 'react';
import { connect } from 'react-redux';
import { quotationActions } from 'Products/shared/quotationListing/actions';
import Notifications from 'react-notification-system-redux';
import { showForbiddenMessage } from '_actions';
import { GetQuoteActions } from '@redux/actions';
import { checkPermission } from '_services';
import PaymentForm from '@products/CbTravel/components/paymentForm';

import { Row, Col, Button } from 'reactstrap';
import { isDatePassed } from 'utils';

const PrevButton = (props) => {
  const { quotation, editQuotation } = props;
  return (
    <button
      className={`btn mx-2 px-4 btn-light`}
      onClick={() => editQuotation(quotation)}
    >
      Previous
    </button>
  );
};

const RetryButton = (props) => {
  const { retryQuotation } = props;

  return (
    <Button
    className={`btn mx-2 px-5 btn-primary`}
      onClick={() => retryQuotation()}
    >
      Retry
    </Button>
  );
};

export class QuotationDetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      redirectToGateway: false
    }
  }
  componentWillMount() {
    const backPath = this.props.history.location.pathname.split('/');
    this.setState({ backPath: [...backPath.slice(0, 2)].join('/') });
  }

  componentWillReceiveProps(newProps) {
    const { notification, notificationType } = newProps;
    if (notification) {
      this.props.notify(notification, notificationType);
    }
  }

  componentWillUnmount() {
    this.props.clearQuotationDetail();
  }

  editQuotation = async (quotation) => {
    const startDate = quotation.properties['Start Date'];
    if (isDatePassed(startDate)) {
      this.props.showForbiddenMessage('This quotation is outdated and cannot be edited.');
    } else {
      const { status } = await checkPermission('Edit Quotation');
      if (status === 'permitted') {
        const urlData = {
          pathname: `/editQuotation/${quotation.productCode}/${quotation.id}`,
          state: 'reset',
        };
        this.props.history.push(urlData);
      } else this.props.showForbiddenMessage();
    }
  }

  retryQuotation = async () => {
    this.setState({redirectToGateway: true});
  }

  render() {
    const { quotation } = this.props;
    if (this.state.redirectToGateway) return <PaymentForm item={quotation}/>
    return (
      <div className='container-fluid px-0'>
        <div className='row d-flex justify-content-center my_50' id='quotation-detail'>
          <Col sm={11} className='quotation-detail-wrapper'>
            <Row>
              <Col sm={12}>
                <div className="my-5 pb-3 text-dark border-bottom bg-white text-center">
                  <h3 className="payment-failed-title">Payment Failed</h3>
                </div>
              </Col>
              <Col sm={12}>
                <div className="mb-3 text-center">The payment could not be processed. Please try again.</div>
                <div className="text-center">
                  <PrevButton quotation={quotation} editQuotation={this.editQuotation} />
                  <RetryButton quotation={quotation} retryQuotation={this.retryQuotation} />
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    quotation,
    notification,
    notificationType,
  } = state.quotationManagement;

  return {
    quotation,
    notification,
    notificationType,
  };
}

const getQuoteActions = new GetQuoteActions();

function mapDispatchToProps(dispatch) {
  return {
    getItemOnNext: (productCode, id, properties) => dispatch(getQuoteActions.getItemOnNext(productCode, id, properties)),
    getDetail: (quotationId) => dispatch(quotationActions.getDetail(quotationId)),
    notify: (message, type) => dispatch(Notifications.show({ message }, type)),
    showForbiddenMessage: (message) => dispatch(showForbiddenMessage(message)),
    clearQuotationDetail: () => dispatch(quotationActions.clearQuotationDetail()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetail);
