import React from 'react';
import {connect} from 'react-redux';
import {
    objLib,
    dateLib, stringLib,
} from '@xc-core/lib';
import {userMessages} from '@xc-core/constants';
import {Message, Loader} from '@xc-core/components/core';
import BaseWizard from '@containers/getQuote/wizard/baseWizard';
import RenderWizard from '@containers/getQuote/wizard/renderWizard';
import {ComponentLoader} from '@xc-core/components';
import {GetQuoteActions, PolicyActions} from '@redux/actions';
import config from '@xc-core/config';
import * as repo from './components';
import {model, defaultValues} from './config';
// import PaymentForm from './components/PaymentForm';
import lib from 'utils/lib'
import {sidebarModel} from 'models';

class AutoInsurance extends BaseWizard<IBaseWizardProps, IBaseWizardState> {
    protected defaultCountry = defaultValues.country;

    protected stateListParam = defaultValues.stateListParam;

    protected model = model;

    private CNICLength = defaultValues.cnicLength;

    private PostCodeLength = defaultValues.postcodeLength;

    private DebitCreditNoLength = defaultValues.debitCreditNoLength;

    private hiddenStepIndexes = [2];

    constructor(props: IBaseWizardProps) {
        super(props);
        this.state = {
            sendingRequest: false,
            invalidForm: false,
            item: {},
            validationErrors: [],
            redirectToGateway: false,
        };
    }

    protected prepareSteps = (steps: IStep[]): IStep[] =>
        steps.map((step: IStep) => {
            const metadata = this.getStepMetadata(step.order);
            return {
                ...step,
                componentType: metadata.componentType,
                formContainer: metadata.formContainer,
            };
        });

    protected checkBoxHandler = (pair: IPair) => {
        return [
            {
                ...pair,
                value: pair.value ? "Yes" : "No",
            },
        ];
    };

    private generateForm = (objs: IFieldModel2, title: string) => {
        return {
            getTitle: () => title,
            fields: Object.entries(objs).map((value => {
                const {
                    id,
                    type,
                    label,
                    handler,
                    style,
                    maxVal,
                    minVal,
                    currencyType,
                    placeholder,
                    collectionId,
                    collectionItem,
                    group
                } = value[1]
                return {
                    id,
                    type,
                    label,
                    placeholder,
                    collectionId,
                    handler: handler ? (pair) => this[handler](pair, this.state.item, {
                        minVal,
                        maxVal
                    }) : this.defaultFieldHandler,
                    disabled: this.defaultFieldDisabled,
                    style: style ? style : {col: "6"},
                    getMax: maxVal ? () => maxVal : undefined,
                    getMin: minVal ? () => minVal : undefined,
                    currencyType,
                    collectionItem,
                    group
                } as IField
            }))
        };
    };


    protected getStepMetadata = (
        order: number
    ): { componentType: string; formContainer: IFormContainer } => {
        const {
            basicDetailsModel,
            insuredPropertyObjectModel,
            mainApplicant,
            fieldsModel,
            paymentModel,
            autoFilledCheckbox
        } = model
        switch (order) {
            case 1:
                return {
                    componentType: "BasicDetails",
                    formContainer: {
                        title: "Please enter the following details",
                        basicDetailFields: this.generateForm(basicDetailsModel, ""),
                        fields: fieldsModel,
                    },
                };
            case 2:
                return {
                    componentType: "Submit price",
                    formContainer: {
                        title: "",
                    },
                };
            case 3:
                return {
                    componentType: "InsuredDetails",
                    formContainer: {
                        title: "",
                        insuredDetailFields: this.generateForm(mainApplicant, ""),
                        fields: fieldsModel,
                    },
                };
            case 4:
                return {
                    componentType: "InsuredPropertyDetails",
                    formContainer: {
                        title: "",
                        insuredPropertyFields: this.generateForm(insuredPropertyObjectModel, ""),
                        autoFilledCheckbox: this.generateForm(autoFilledCheckbox, ''),
                        fields: fieldsModel,
                    },
                };
            case 5:
                return {
                    componentType: "Summary",
                    formContainer: {
                        title: "",
                        basicDetailFields: this.generateForm(basicDetailsModel, ""),
                        insuredDetailFields: this.generateForm(mainApplicant, ""),
                        insuredPropertyFields: this.generateForm(insuredPropertyObjectModel, ""),
                        fields: fieldsModel,
                    },
                };
            case 6:
                return {
                    componentType: "PaymentPage",
                    formContainer: {
                        title: `Fill in payment details (optional)`,
                        paymentDetails: this.generateForm(paymentModel, ""),
                        fields: fieldsModel,
                    },
                };
            case 7:
                return {
                    componentType: 'ThankYouPage',
                    formContainer: {
                        title: '',
                    },
                }
            default:
                return {
                    componentType: "",
                    formContainer: {
                        title: "",
                    },
                };
        }
    };

    protected handleStartEndDate = (pair: IPair, item: IObject): IPair[] => {
        const pairsToUpdate: IPair[] = [];
        const prevStartDate = objLib.getValueWithLodash(
            item,
            model.fieldsModel.startDate.id
        );
        const prevEndDate = objLib.getValueWithLodash(
            item,
            model.fieldsModel.endDate.id
        );
        const tripType = objLib.getValueWithLodash(
            item,
            model.fieldsModel.tripType.id
        );
        let noOfDays = 0;
        if (pair.id.includes("Start Date") && prevEndDate) {
            if (tripType === "Annual") {
                const endDate = this.getEndDateForAnnualType(pair.value);
                pairsToUpdate.push({
                    id: model.fieldsModel.endDate.id,
                    value: endDate,
                });
                noOfDays = this.getNoOfDays(endDate, pair.value);
            } else {
                noOfDays = this.getNoOfDays(prevEndDate, pair.value);
                const isStartDateGreater =
                    dateLib.compare(pair.value, prevEndDate, config.dateMomentFormat) > 0;
                if (isStartDateGreater) {
                    const newEndDate = dateLib.addToDate(pair.value, 0, 0, 4);
                    noOfDays = this.getNoOfDays(newEndDate, pair.value);
                    pairsToUpdate.push({
                        id: model.fieldsModel.endDate.id,
                        value: newEndDate,
                    });
                }
            }
        } else if (pair.id.includes("End Date") && prevStartDate) {
            noOfDays = this.getNoOfDays(pair.value, prevStartDate);
        }
        pairsToUpdate.push({id: pair.id, value: pair.value});
        pairsToUpdate.push({id: ["properties", "No. of Days"], value: noOfDays});
        return pairsToUpdate;
    };

    private getNoOfDays = (date1: string, date2: string) =>
        dateLib.getDiff(date1, date2, "days") + 1;

    private getEndDateForAnnualType = (startDate: string) => {
        let endDate = dateLib.addToDate(startDate, 1, 0, 0);
        endDate = dateLib.subtractFromDate(endDate, 0, 0, 1);
        return endDate;
    };

    private getMaxDob = (): string => {
        const {minAge} = defaultValues;
        return dateLib.subtractFromNow(minAge.years, minAge.months, minAge.days);
    };

    private getMinDob = (): string => {
        const {maxAge} = defaultValues;
        return dateLib.subtractFromNow(maxAge.years, maxAge.months, maxAge.days);
    };

    private isEndDateDisabled = (item: IObject): boolean => {
        const tripTypeId = model.fieldsModel.tripType.id;
        return objLib.getValueWithLodash(item, tripTypeId) === "Annual";
    };

    private handlePaymentMode = (pair: IPair, item: IObject): IPair[] => {
        let receivedByValue = objLib.getValueWithLodash(
            item,
            model.fieldsModel.paymentReceivedBy.id
        );
        if (!receivedByValue) {
            const {user} = config;
            receivedByValue = user.name;
        }
        return [
            pair,
            {id: model.fieldsModel.paymentReceivedBy.id, value: receivedByValue},
        ];
    };

    private getMinEndDate = (item: IObject): string => {
        const startDate =
            objLib.getValueWithLodash(item, model.fieldsModel.startDate.id) || "";
        const currentDate = dateLib.getCurrentDate(config.dateMomentFormat);
        return startDate || currentDate;
    };

    protected handlePostcode = (pair: IPair, item: IObject): IPair[] => {
        const pre = objLib.getValueWithLodash(item, pair.id);
        let {value} = pair;
        // value = value.length > this.PostCodeLength ? value.slice(0, this.PostCodeLength) : value;
        value = this.restrictToNumber(value, pre);
        return [{id: pair.id, value}];
    };

    protected handleDebitCreditNo = (pair: IPair, item: IObject): IPair[] => {
        const pre = objLib.getValueWithLodash(item, pair.id);
        let {value} = pair;
        value =
            value.length > this.DebitCreditNoLength
                ? value.slice(0, this.DebitCreditNoLength)
                : value;
        value = this.restrictToNumber(value, pre);
        return [{id: pair.id, value}];
    };

    protected rangeNumberHandler = (pair: IPair, item: IObject, config?: any) => {
        const pre = objLib.getValueWithLodash(item, pair.id);
        const {value} = pair;

        if (config) {
            const {maxVal} = config
            const num = parseInt(value)
            if (num > parseInt(maxVal)) {
                return [{id: pair.id, value: pre}];
            }
        }
        let newVal = parseInt(this.restrictToNumber(value, pre));
        return [{id: pair.id, value: newVal}];
    }

    render() {
        const {
            getQuote,
            getQuote: {collections, product, steps},
            history,
        } = this.props;
        const {item, validationErrors, sendingRequest, invalidForm} = this.state;

        const currentStep = this.getCurrentStep(this.hiddenStepIndexes);
        const paymentSelected = !stringLib.isEmpty(
            objLib.getValueWithLodash(
                item,
                this.model.fieldsModel.paymentReceivedBy.id
            )
        );

        if (product === null) return <Message message={userMessages.ERROR}/>;
        if (collections === null || steps.length === 0) return <Loader/>;

        return (
            <RenderWizard
                getQuote={{
                    ...getQuote,
                    steps: steps.filter(
                        (el, index) => this.hiddenStepIndexes.indexOf(index) === -1
                    ),
                }}
                getCurrentStep={() => this.getCurrentStep(this.hiddenStepIndexes)}
                handleNext={() => {
                    this.next().then(() => {
                        if (this.hiddenStepIndexes.indexOf(currentStep.order + 1) !== -1 && !this.state.invalidForm) {
                            this.setState({
                                item: {
                                    ...this.state.item,
                                    properties: {
                                        ...this.state.item.properties,
                                        'main applicant': {},
                                        Price: this.state.item.properties?.priceList[item?.properties?.['Building Type'] === "Landed" ? 0 : 1]
                                    }
                                }
                            }, () => {
                                this.next();
                            })
                        }
                    })
                }}
                handlePrev={() => this.previous().then((res) => {
                    if (res?.state === 'Submit price') {
                        this.previous()
                    }
                })}
                handleSkip={this.skip}
                paymentSelected={paymentSelected}
                isBeneficirayEnterd={false}
                sendingRequest={sendingRequest}
                invalidForm={invalidForm}
                isKhmer={false}
            >
                <ComponentLoader
                    name={currentStep.componentType}
                    repo={repo}
                    history={history}
                    propsModel={{
                        onChange: (handler: string, obj: IHandledValue) => {
                            this[handler](obj)
                        },
                        updateStateItem: this.updateStateItem,
                        initValues: (pairs: IPair[]) => this.setValues(pairs),
                        collections,
                        item,
                        currentStep,
                        model: currentStep.formContainer,
                        validationErrors,
                        defaults: {country: this.defaultCountry},
                        sidebar: sidebarModel.quotation.homeInsurance,
                        history,
                        productIcon: "icon-home-insurance",
                        isKhmer: lib.checkIsDemoSite()
                    }}
                />
            </RenderWizard>
        );
    }
}

const mapStateToProps = (state: IStore) => ({
    getQuote: state.newGetQuote,
});

const getQuoteActions = new GetQuoteActions();
const policyActions = new PolicyActions();

const mapDispatchToProps = (dispatch: any) => ({
    init: (productCode: number, country: string,
           stateListParam: string) => dispatch(getQuoteActions.init(productCode, country, stateListParam)),
    setStateInCollections: (country: string,
                            param: string) => dispatch(getQuoteActions.setStateInCollections(country, param)),
    setSteps: (steps: IStep[]) => dispatch(getQuoteActions.setSteps(steps)),
    getItemOnNext: (productCode: number, id: number,
                    properties: IObject) => dispatch(getQuoteActions.getItemOnNext(productCode, id, properties)),
    getItemOnSkip: (productCode: number, id: number,
                    properties: IObject) => dispatch(getQuoteActions.getItemOnSkip(productCode, id, properties)),
    getItemOnPrevious: (productCode: number,
                        id: number) => dispatch(getQuoteActions.getItemOnPrevious(productCode, id)),
    downloadCertificate: (id: string) => dispatch(policyActions.download(id)),
    reset: () => dispatch(getQuoteActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoInsurance);
