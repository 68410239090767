import Moment from 'moment';
import { CONFIG_DATE_MOMENT_FORMAT } from "utils";
import { dobRanges } from 'utils/';

const styles = theme => ({
  card: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  root: {
    width: "90%"
  },
  removePadding:{
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "#fcfcfc"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  connectorActive: {
    "& $connectorLine": {
      borderColor: theme.palette.primary.main
    }
  },
  connectorCompleted: {
    "& $connectorLine": {
      borderColor: theme.palette.primary.main
    }
  },
  connectorDisabled: {
    "& $connectorLine": {
      borderColor: theme.palette.grey[100]
    }
  },
  connectorLine: {
    transition: theme.transitions.create("border-color"),
    borderTopWidth: 3,
    borderRadius: 7
  }
});

const getSteps = () => ([
  // "Product Selection",
  "Trip Details",
  "Select Plan", //"Plan Selection", // "Select Plan" on the backend
  "Insured Details", //"Customer Details", // "Insured Details" on the backend
  "Beneficiaries", //"Beneficiary Details",
  "Summary",
  "Payment Details",
  "Completed",
]);

const BeneficiaryBaseObj = {
  "Full Name": "",
  "ID Type": "",
  "ID Number": "",
  Email: "",
  "Contact Number": "",
  Relationship: "",
  "Same as Main": false,
};

// /**
//  * Days to subscribe should be either 0 or 364
//  * @param yearsFromNow (this is the number of years to subtract from today)
//  * @param monthsToSubtract
//  * @param daysToSubtract (this is the number of days before getting into another category ex: from adult to senior)
//  * @return Moment string date
//  */
// const getDate = (yearsFromNow = 0, monthsToSubtract = 0, daysToSubtract = 0) => (Moment(new Date())
//   .subtract(`${yearsFromNow}`, 'years')
//   .subtract(`${monthsToSubtract}`, 'months')
//   .subtract(`${daysToSubtract}`, 'days')._d);

const datesOfBirth = {
  children: dobRanges.childrenRange,
  adult: dobRanges.adultRange,
  seniorCitizen: dobRanges.senior6680Range,
};

const initialParentState = {
  activeStep: null,
  productSelected: "",
  planSelected: "",
  properties: {
    "Adults": 0,
    "Children": 0,
    "Senior Citizens": 0,
    "Insured Type": "",
    "Trip Type": "",
    "Source": "B2B Portal",
    "Start Date": Moment().format(CONFIG_DATE_MOMENT_FORMAT),
    "End Date": Moment().format(CONFIG_DATE_MOMENT_FORMAT),
  },
  OtherInsured: [],
  BeneficiaryObj: [],
  disabled: false,
  disableDomestic: false,
  disableArrival: false,
  disableSeniorField: false,
  disableAdultsField: false,
  disableChildrenField: false,
  hideSeniorField: false,
  hideChildrenField: false,
  seniorCitizenErrors: {
    anyError: false,
    errorMessage: ''
  },
  adultsErrors: {
    anyError: false,
    errorMessage: ''
  },
  childrenErrors: {
    anyError: false,
    errorMessage: ''
  },
  insuredTypeError: {
    anyError: false,
    errorMessage: ''
  },
  IDNumberError: {
    anyError: false,
    errorMessage: ''
  },
  resetIsApplied: false,
  sendingRequest: false,
  productsFetched: false,
};
export {
  styles,
  getSteps,
  BeneficiaryBaseObj,
  datesOfBirth,
  initialParentState,
}