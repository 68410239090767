import actionTypes from '@redux/actionTypes';
import BaseReducer from './base';

class GetQuoteReducer extends BaseReducer<IGetQuoteState> {
  public actions: IDictionary<(state: IGetQuoteState, action: IAction) => IGetQuoteState>;

  public initialState: IGetQuoteState;

  protected actionTypes: IGetQuoteActionTypes;

  constructor() {
    super();

    this.actionTypes = actionTypes.getQuote;

    this.initialState = {
      isLoading: true,
      product: null,
      error: null,
      accessIsForbidden: false,
      notFound: false,
      isCreateForbidden: false,
      isUpdateForbidden: false,
      item: {
        id: 0,
        status: '',
        state: '',
        common: {},
        properties: {
          Source: 'B2B Portal',
        },
      },
      collections: null,
      steps: [],
      // currentStep: null,
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IGetQuoteState,
    action: IAction) => IGetQuoteState> => ({
    [this.actionTypes.GET_QUOTE_LOADING]: this.setLoading,
    [this.actionTypes.GET_QUOTE_FAILED]: this.setError,
    [this.actionTypes.CREATE_SET_FORBIDDEN]: this.setCreateForbidden,
    [this.actionTypes.UPDATE_SET_FORBIDDEN]: this.setUpdateForbidden,
    [this.actionTypes.SET_PRODUCT]: this.setProduct,
    [this.actionTypes.GET_QUOTE_SET_STEPS]: this.setSteps,
    [this.actionTypes.GET_QUOTE_NEXT]: this.updateItem,
    [this.actionTypes.GET_QUOTE_PREV]: this.updateItem,
    [this.actionTypes.GET_QUOTE_RESET]: this.reset,
    [this.actionTypes.GET_QUOTE_SET_COLLECTIONS]: this.setCollections,
    [this.actionTypes.GET_QUOTE_SET]: this.setGetQuote,
    // [this.actionTypes.]: this.setNotFound,
    // [this.actionTypes.GET_QUOTE_SET_FORBIDDEN]: this.setForbidden,
    // [this.actionTypes.GET_QUOTE_CREATE]: this.create,
  })

  protected setCollections = (state:IGetQuoteState, action: IAction):IGetQuoteState => ({
    ...state,
    collections: {
      ...state.collections, // states may be there before getting collections
      ...action.payload,
    },
    isLoading: false,
  })

  protected setSteps = (state:IGetQuoteState, action: IAction):IGetQuoteState => ({
    ...state,
    steps: action.payload,
    isLoading: false,
  })

  protected updateItem = (state:IGetQuoteState, action: IAction):IGetQuoteState => ({
    ...state,
    item: action.payload,
    isLoading: false,
  })

  protected setGetQuote = (state:IGetQuoteState, action: IAction):IGetQuoteState => ({
    ...state,
    item: action.payload.item,
    collections: {
      ...state.collections, // states may be there before getting collections
      ...action.payload.collections,
    },
    product: action.payload.product,
    isLoading: false,
  })
}
export default GetQuoteReducer;
