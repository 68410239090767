import {
  requestLib,
  responseLib,
  mediaLib,
  dateLib,
  objLib,
  numberLib,
  stringLib,
  filterLib,
} from '@xc-core/lib';
import config from '@xc-core/config';
import { userMessages } from '@xc-core/constants';
import ProductActions from './product';
import BaseAction from './base';
import ModalMessageAction from './modalMessage';

export default abstract class BaseListingAction extends BaseAction {
  public abstract setList(params: IRequestParam): Function

  public abstract setHasRecordsInitially(): Function

  public abstract getSearchParams(): Function

  protected abstract service: IListingService;

  protected abstract csvExportScope: string;

  private applyFormatToItem = (item: any, model: IObject[]): any => {
    let formattedItem = { ...item };
    model.forEach((m:IObject) => {
      const value = objLib.getValueFromPath(formattedItem, m.id);
      switch (m.type) {
        case 'date':
          formattedItem = objLib.setValueWithLodash(formattedItem, m.id,
            dateLib.applyFormat(value, config.dateMomentFormat));
          break;
        case 'datetime':
          formattedItem = objLib.setValueWithLodash(formattedItem, m.id,
            dateLib.applyFormat(value, config.dateTimeMomentFormat));
          break;
        case 'time':
          formattedItem = objLib.setValueWithLodash(formattedItem, m.id,
            dateLib.applyFormat(value, 'HH:mm'));
          break;
        case 'currency':
          formattedItem = objLib.setValueWithLodash(formattedItem, m.id,
            numberLib.applyFormat(Number.parseFloat(value), config.currencyFormat));
          break;
        case 'string':
        default:
          formattedItem = objLib.setValueWithLodash(formattedItem, m.id, value);
          break;
      }
    });
    return formattedItem;
  };

  protected createPageInfo = (obj: any): IPageInfo => ({
    empty: obj.empty,
    first: obj.first,
    last: obj.last,
    pageNumber: obj.number,
    pageSize: obj.size,
    numberOfElements: obj.numberOfElements,
    totalElements: obj.totalElements,
    totalPages: obj.totalPages,
  });

  protected createTabPair = (params: IRequestParam): IPair => {
    const value = params.status ? params.status[0] : 'All';
    const id = params.status ? params.status[0] : '';
    return {
      id,
      value,
    };
  };

  protected addAllToList = (list: IPair[]) : IPair[] => {
    const all = { id: '', value: 'All' } as IPair;
    return list.length > 1 ? [all, ...list] : list;
  };

  protected applyListFormat = (list: any[],
    model: IObject[]): any[] => (list.map((listItem:any) => {
    let item = listItem;
    if (item.children) {
      item = {
        ...item,
        children: this.applyListFormat(item.children, model),
      };
    }
    item = this.applyFormatToItem(item, model);
    return item;
  }));

  protected setActiveValueFor = (list: IObject[], key: string): IObject[] => (list.map((item) => ({
    ...item,
    [key]: item[key] ? 'Active' : 'Inactive',
  })));

  protected getSearchParamsByItem = (item: IObject):IRequestParam => {
    let params: IRequestParam = {};

    if (item.product && item.product.id) {
      params.productCode = [item.product.id];
    }
    if (item.pageInfo) {
      params.page = [stringLib.toString(item.pageInfo.pageNumber)];
      params.pageSize = [stringLib.toString(item.pageInfo.pageSize)];
    }
    if (item.filters) {
      const filtersParams = filterLib.getRequestParamsChecked(item.filters);
      params = {
        ...params,
        ...filtersParams,
      };
    }
    if (item.searchKeyword) params.name = [item.searchKeyword];
    if (item.tab && item.tab.id) params.status = [item.tab.value];
    if (item.viewTypeIsFlat) params.isFlatView = [item.viewTypeIsFlat];

    if (item.filterToday) {
      const today = dateLib.getCurrentDate(config.dateRequestParamFormat);
      params.createdOn = [`${today},${today}`];
    }
    return params;
  }

  public create = (obj: IObject) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.ENTITY_LOADING));
    try {
      await this.service.create(obj);
      dispatch(this.createAction(this.actions.ENTITY_CREATE));
      return null;
    } catch (error) {
      dispatch(this.createAction(this.actions.ENTITY_FAILED, error));
      return error;
    }
  };

  public edit = (id: string, obj: IObject) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.ENTITY_LOADING));
    try {
      await this.service.update(id, obj);
      dispatch(this.createAction(this.actions.ENTITY_UPDATE));
    } catch (error) {
      dispatch(this.createAction(this.actions.ENTITY_FAILED, error));
    }
  };

  public get = (id: string) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.ENTITY_LOADING));
    try {
      const result = await this.service.getDetail(id);
      dispatch(this.createAction<any>(this.actions.ENTITY_GET, result));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.ENTITY_SET_FORBIDDEN));
      }
      dispatch(this.createAction<IError>(this.actions.ENTITY_FAILED, error));
    }
  }

  public changePage = (value: number) => (dispatch: any) => {
    const action = this.createAction<number>(this.actions.LIST_CHANGE_PAGE, value);
    dispatch(action);
  }

  public changePageSize = (value: number) => (dispatch: any) => {
    const action = this.createAction<number>(this.actions.LIST_CHANGE_PAGE_SIZE, value);
    dispatch(action);
  }

  public changeFilters = (filters: IListingFilter[]) => (dispatch: any) => {
    const action = this.createAction<IListingFilter[]>(this.actions.LIST_CHANGE_FILTERS, filters);
    dispatch(action);
  }

  public cancelFilters = (prevFilters: IListingFilter[]) => (dispatch: any) => {
    const action = this.createAction<IListingFilter[]>(this.actions.LIST_CHANGE_FILTERS,
      prevFilters);
    dispatch(action);
  }

  public applyFilters = (filters: IListingFilter[]) => (dispatch: any) => {
    const action = this.createAction<IListingFilter[]>(this.actions.LIST_APPLY_FILTERS, filters);
    dispatch(action);
  }

  public resetFilters = () => (dispatch: any) => {
    const action = this.createAction(this.actions.LIST_RESET_FILTERS);
    dispatch(action);
  }

  public resetList = () => (dispatch: any) => {
    const action = this.createAction(this.actions.LIST_RESET);
    dispatch(action);
  }

  public changeTab = (pair: IPair) => (dispatch: any) => {
    const action = this.createAction<IPair>(this.actions.LIST_CHANGE_TAB, pair);
    dispatch(action);
  }

  public changeSearchKey = (value: string) => (dispatch: any) => {
    const action = this.createAction<string>(this.actions.LIST_CHANGE_SEARCH_KEY, value);
    dispatch(action);
  }

  public getCsvFile = (params: IRequestParam, fileNameBackup: string) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.CSV_EXPORT_LOADING));
    try {
      const isForbidden = await this.isResourceForbidden(this.csvExportScope);
      if (isForbidden) {
        const modalMessageAction = new ModalMessageAction();
        dispatch(this.createAction(this.actions.CSV_EXPORT_SET_FORBIDDEN));
        dispatch(modalMessageAction.show(userMessages.PERMISSION_TITLE,
          userMessages.PERMISSION_MESSAGE));
      } else {
        const stringParams = requestLib.convertToRequestParams(params);
        const result = await this.service.getCsvFile(stringParams);
        let fileName = responseLib.extractFileName(result.contentDisposition);
        if (!fileName) fileName = fileNameBackup;
        mediaLib.downloadFile(fileName, result.file);

        // const payload:IObject = { file: result.file, fileName };
        // dispatch(this.createAction<IObject>(this.actions.CSV_EXPORT_GET, payload));
      }
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.CSV_EXPORT_SET_FORBIDDEN));
      }
      dispatch(this.createAction<IError>(this.actions.CSV_EXPORT_FAILED, error));
    }
  }

  public setProduct = (product: IProduct) => (dispatch: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    dispatch(this.createAction<IProduct>(this.actions.SET_PRODUCT, product));
  }

  public initProduct = () => async (dispatch: any, getState: any) => {
    const { product } = getState().quotationList;
    let selectedProduct = null;
    if (product) selectedProduct = product;
    else {
      const productActions = new ProductActions();
      await dispatch(productActions.setList());
      const { list } = getState().productList;
      if (list && list.length > 0) selectedProduct = { ...list[0] };
    }
    const action = this.createAction<IPair>(this.actions.SET_PRODUCT, selectedProduct);
    dispatch(action);
  }
}
