import React from 'react';
import { connect } from 'react-redux';
import { sidebarModel } from 'models';
import products from '_config/products';
import stateToProps from '@containers/storeState';
import dispatchToProps from '@containers/dispatches';
import { objLib } from '@xc-core/lib';
import { Loader } from '@xc-core/components/core';
import NomineeWitnessDetails from './nominees-witness-details';
import BasePolicyDetails from '../base-policy-detail';
import fields from './fields';
import models from './model';

interface IProps {
  history: any
  policyManagement: IObject
  setPolicyOccupationList: any
  getPolicyWitnessSignature: (refId: string) => Promise<string>
}

const ICarePolicyDetails = (props: IProps) => {
  const {
    history, policyManagement: { policy, detailCollections },
    setPolicyOccupationList, getPolicyWitnessSignature,
  } = props;

  if (objLib.isEmpty(policy)) return <Loader />;
  return (
    <BasePolicyDetails
      fields={fields}
      models={{ ...models, sidebar: sidebarModel.policy.iCareGbsn }}
      history={history}
      policy={policy}
      collections={detailCollections}
      defaultCountry={products.gbsnICare.defaultValues.country}
      setOccupationList={setPolicyOccupationList}
    >
      <NomineeWitnessDetails
        fields={fields.policy}
        item={policy}
        collections={detailCollections}
        models={models}
        getWitnessSignature={getPolicyWitnessSignature}
        signatureId={policy.refCode}
      />
    </BasePolicyDetails>
  );
};

export default connect(stateToProps, dispatchToProps)(ICarePolicyDetails);
