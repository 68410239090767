import React from 'react';
import {
  Form, FormGroup, Label, Input, Button, Col, Row,
} from 'reactstrap';
import { ModalUi } from '../../../../_components';
// eslint-disable-next-line import/extensions
import Field from '../../../../@containers/getQuote/wizard/field';

const EndorseForm = (props) => {
  const {
    properties,
    isOpen,
    toggleModal,
    handleInputChange,
    handleSubmit,
    disableSubmit,
    endorseRemarkIsValid,
    hasAdditionalEmails = false,
  } = props;

  return (
    <ModalUi isOpen={isOpen} title='Remarks' toggle={!disableSubmit && toggleModal}>
      <Form>
        <FormGroup>
          <Label for='endorse-remark'>Please state your reasons for the certificate endorsement.</Label>
          <Input
            id='Endorse Remarks'
            name='Endorse Remark'
            type='textarea'
            value={properties ? properties['Endorse Remarks'] : ''}
            onChange={handleInputChange}
            title='Endorse Remark'
            style={{ height: 130 }}
          />
          {endorseRemarkIsValid === false ? <p className='text-danger'>this field is required.</p> : <></>}
        </FormGroup>
        {
          hasAdditionalEmails && (
            <div className='additional-tag-styles'>
              <p>Other Emails to Receive Policy</p>
              <FormGroup>
                <Label for='cc'>CC:</Label>
                <Field
                  model={{
                    id: 'CC Email',
                    type: 'MultiValueInput',
                    handler: (pair) => ({ target: pair }),
                    style: { col: '4' },
                    label: 'cc',
                  }}
                  item={properties}
                  onChange={(event) => handleInputChange(event)}
                />
              </FormGroup>
              <FormGroup>
                <Label for='bcc'>BCC:</Label>
                <Field
                  model={{
                    id: 'BCC Email',
                    type: 'MultiValueInput',
                    handler: (pair) => ({ target: pair }),
                    style: { col: '4' },
                    label: 'bcc',
                  }}
                  item={properties}
                  onChange={(event) => handleInputChange(event)}
                />
              </FormGroup>
            </div>
          )
        }
        <FormGroup className='text-right mb-0'>
          <Button className='customLeastbtn mr-3' color='muted' onClick={toggleModal} disabled={disableSubmit}>Cancel</Button>
          <Button className='customPrimarybtn' onClick={handleSubmit} color='primary' disabled={disableSubmit}>Submit</Button>
        </FormGroup>
      </Form>
    </ModalUi>
  );
};

EndorseForm.defaultProps = {
  handleSubmit: () => alert('unimplemented'),
  toggleModal: () => alert('unimplemented'),
  handleInputChange: () => alert('unimplemented'),
};

export default EndorseForm;
