import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { sidebarModel } from "models";
import { SidebarCard } from '@xc-core/components';
import { withStyles } from "@material-ui/core/styles";
import BasicInfoForm from "../containers/BasicInfoForm";
import SimpleReactValidator from "simple-react-validator";
import Checkbox from "@material-ui/core/Checkbox";
import { utils } from 'utils';
import config from "_config";
import {adjustAlfalahSideBar} from "../../alfalahUtils";

const styles = {
  root: {
    "&$checked": {
      color: "#2B61B4"
    }
  },
  checked: {
    color: "#2B61B4"
  }
};

class BeneficiaryDetails extends React.Component {
  constructor(props) {
    super(props);
    // this.validator = new SimpleReactValidator(utils.validations.alfalahValidationObject);
    this.mainBeneficiaryValidator = new SimpleReactValidator(utils.validations.alfalahValidationObject);
    this.otherBeneficiariesValidator = new SimpleReactValidator(utils.validations.alfalahValidationObject);
    this.state = this.props.curentThis.state;
  }

  async componentDidMount() {
    const { curentThis } = this.props;
    const { 'Other Insured': otherInsured = [] } = curentThis.state.properties;

    const country = curentThis.props.item.properties.Country
      || config.products.alfalahTravelInsurance.defaultValues.country;
    await this.props.getStates(country, '?collection=state_alf');
    if (
      curentThis.state.BeneficiaryObj &&
      (!curentThis.state.BeneficiaryObj.length || curentThis.state.BeneficiaryObj.length - 1 !== otherInsured.length)
    ) {
      await curentThis.populateBeneficiaryObj();
    }
    this.props.onRef(this);
  }

  getAlert() {
    const { curentThis } = this.props;
    // if (this.validator.allValid()) {
    if (this.mainBeneficiaryValidator.allValid() && this.otherBeneficiariesValidator.allValid()) {
      curentThis.setInvalidForm(false);
      return true;
    } else {
      this.mainBeneficiaryValidator.showMessages();
      this.otherBeneficiariesValidator.showMessages();
      curentThis.setInvalidForm(true);
      this.forceUpdate();
      return false;
    }
  }
  // state = {
  //   checkedA: true,
  //   checkedB: true,
  //   checkedF: true
  // };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleSameAsMainCheck = (id, e) => {
    const { curentThis } = this.props;
    if (this.mainBeneficiaryValidator.allValid()) {
      curentThis.updateBeneficiaryDetails(id, e);
    } else {
      this.mainBeneficiaryValidator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const { properties, productName } = this.props.curentThis.props.item;
    const { item } = this.props.curentThis.props;
    const { curentThis, classes } = this.props;
    let { BeneficiaryObj, OtherInsured } = this.props.curentThis.state;
    OtherInsured = properties["Other Insured"];
    const curentStep = this;
    // this.validator.purgeFields();
    this.mainBeneficiaryValidator.purgeFields();
    this.otherBeneficiariesValidator.purgeFields();
    return (
      <Row>
        <Col md="8">
          {BeneficiaryObj.length >= 1 &&
          BeneficiaryObj.map((data, index) => {
            if (index === 0) {
              return (
                <Fragment key={index}>
                  <h5 className="sub-title">Please enter the beneficiary(s) details</h5>
                  <p className="custom-text-primary">Main Insured’s Beneficiary </p>
                  <Row>
                    <BasicInfoForm
                      updatedVal={curentThis.state.BeneficiaryObj[index]}
                      handleInputChange={curentThis.updateBeneficiaryDetails.bind(
                        this,
                        data.id
                      )}
                      index={index}
                      onChange={curentThis.handleDOB} // this onChange prop should be called handleDOB to be clear
                      collections={curentThis.props.collections}
                      beneficiary={true}
                      curentThis={curentThis}
                      curentStep={curentStep}
                      curentID={data.id}
                      validator={this.mainBeneficiaryValidator}
                      autoFocus
                      noValidation
                      disableDefaultCountry
                    />
                  </Row>
                </Fragment>
              );
            }
            if (OtherInsured[index - 1]) {
              return (
                <Fragment key={index}>
                  <hr />
                  <p className="custom-text-primary">
                    {OtherInsured[index - 1]["Full Name"] + "'s Beneficiary"}
                  </p>
                  <span className="d-flex align-items-center font-size-small mb-3">
                      <Checkbox
                        checked={
                          curentThis.state.BeneficiaryObj[index]["Same as Main"]
                        }
                        // disabled={
                        //   curentThis.state.BeneficiaryObj[0]["Full Name"] === ""
                        // }
                        onChange={(e) => this.handleSameAsMainCheck(data.id, e)}
                        value={
                          !curentThis.state.BeneficiaryObj[index][
                            "Same as Main"
                            ]
                        }
                        id="Same as Main"
                        className="p-0"
                        classes={{
                          root: classes.root,
                          checked: classes.checked
                        }}
                      />
                      Same as main insured’s beneficiary
                    </span>
                  <Row>
                    <BasicInfoForm
                      key={index}
                      disableAll={
                        curentThis.state.BeneficiaryObj[index]["Same as Main"]
                      }
                      updatedVal={curentThis.state.BeneficiaryObj[index]}
                      handleInputChange={curentThis.updateBeneficiaryDetails.bind(
                        this,
                        data.id
                      )}
                      index={index}
                      collections={curentThis.props.collections}
                      beneficiary={true}
                      curentThis={curentThis}
                      curentID={data.id}
                      validator={this.otherBeneficiariesValidator}
                      autoFocus
                      noValidation
                      disableDefaultCountry
                    />
                  </Row>
                </Fragment>
              );
            }
          })}
        </Col>
        <Col md="4">
          <SidebarCard.Quotation
            properties={properties}
            data={properties}
            productName={productName}
            model={adjustAlfalahSideBar(sidebarModel.quotation.travelAlfalah,properties)}
            item={item}
          />
          {/* <SidbarCard values={properties} /> */}
        </Col>
      </Row>
    );
  }
}

BeneficiaryDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BeneficiaryDetails);
