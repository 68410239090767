
import { LeadManagementService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import {
  requestLib,
  filterLib,
  listLib,
} from '@xc-core/lib';
import BaseListAction from './baseListing';

class LeadManagementAction extends BaseListAction {
  constructor() {
    super();
    this.service = new LeadManagementService();
  }

  protected actions = actionTypes.leadManagement;

  protected service: LeadManagementService;

  protected csvExportScope = 'Export Leads';

  protected createScope = '';

  protected editScope = '';

  private defaultFields = [
    { id: 'common.createdOn', displayName: 'Created At Date', type: 'date' },
    { id: 'common.updatedOn', displayName: 'Created At Time', type: 'time' },
    { id: 'customerName', displayName: 'Lead Name', type: 'string' },
    { id: 'age', displayName: 'Age', type: 'number' },
    { id: 'mobileNo', displayName: 'Mobile No', type: 'number' },
    { id: 'emailAddress', displayName: 'Email Address', type: 'string' },
    { id: 'state', displayName: 'State', type: 'string' },
    { id: 'preferredCallTime', displayName: 'Preferred Call Time', type: 'string' },
    { id: 'enquiryReason', displayName: 'Enquiry Reason', type: 'string' },
  ];

  public setList = (params: IRequestParam) => async (dispatch: any, getState: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      const { filters } = getState().leadList;
      const stringParams = requestLib.convertToRequestParams(params);
      let result = await this.service.getList(stringParams);
      const serverFilters = await this.service.getFilters();
      const fields = listLib.convertToIdValueTypeList(this.defaultFields, ['id', 'displayName', 'type']);
      const initialFilters = filterLib.parse(serverFilters, params);

      result = {
        ...result,
        // collections: this.prepareCollections(result.collections),
        list: this.applyListFormat(result.content, fields),
        fields,
        initialFilters,
        filters: filters.length > 0 ? filters : initialFilters,
        prevFilters: filters.length > 0 ? filters : initialFilters,
        pageInfo: this.createPageInfo(result),
        searchKeyword: params.name ? params.name[0] : '',
      };
      dispatch(this.createAction<IObject>(this.actions.LIST_GET, result));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      if (error.status === 404) {
        dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }


  public setCurrentLeadInfo = (leadItem: object) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.SET_CURRENT_LEAD_INFO, leadItem))
  }

  public getSearchParams = () => (dispatch: any, getState: any): IRequestParam => {
    const { leadList } = getState();
    return this.getSearchParamsByItem(leadList);
  }

  public setHasRecordsInitially= () => async (dispatch: any, getState: any) => {
    const { list } = getState().policyList;
    const hasRecords = list && list.length > 0;
    dispatch(this.createAction<boolean>(this.actions.LIST_SET_HAS_RECORDS_INITIALLY, hasRecords));
  }
}



export default LeadManagementAction;
