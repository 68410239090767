import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import {formatCurrency, config} from "utils";

const styles = {
  card: {
    // minWidth: 275,
    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: 10
  },
  cardActive: {
    border: "2px solid #064e8c"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  margin: {
    margin: 1 * 2
  },
  dot: {
    position: "absolute",
    bottom: 0,
    left: "2.75pc",
    height: 15,
    width: 15,
    border: "2px solid white",
    borderRadius: 15
  },
  badgeActive: {
    backgroundColor: "#5AFF31"
  },
  badgeInActive: {
    backgroundColor: "#FF3131"
  }
};

class PLanSelection extends React.Component {
  componentDidMount() {
    this.props.onRef(this);
  }
  getAlert() {
    return true
  }
  render() {
    const { classes, values, curentThis } = this.props;
    // const selectedDestination = curentThis.props.item.properties != null && curentThis.props.item.properties.Destination;
    return (
      <>
      <Grid container spacing={24}>
          {curentThis.props.item.properties != null && curentThis.props.item.properties.priceList.map((data, key) => {
           //To hide the Basic Plan 
          if (data["Plan"] !== "Basic")
          return (
            <Grid item xs={12} md={4} key={key}>
              <Card
                className={`
                  ${classes.card}
                  ${values.planSelected["Plan"] === data["Plan"] ? classes.cardActive : ""}
                `}
              >
                <CardActionArea
                  onClick={() => curentThis.updatedPlanSelected(data)}
                  data-set={1}
                  type="button"
                  autoFocus = { data["Plan"] === "Basic" ? true : false}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      color: "#064e8c"
                    }}
                  >
                    <i
                      className={
                        values.planSelected["Plan"] === data["Plan"]
                          ? "icon-checkedOutline font-size-normal"
                          : ""
                      }
                    />
                  </div>
                  <CardContent
                    className={"d-flex flex-column p-4"}
                  >
                    <h5>
                      {data["Plan"]}
                    </h5>
                    <h3 className="text-primary font-weight-bold">
                      {config.currencySymbol} {formatCurrency(data["Total"])}
                    </h3>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      </>
    );
  }
}

PLanSelection.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PLanSelection);
