import { usersConstants } from "../constants";
import { setHasRecords } from "../../../utils";
import { HANDLE_FILTER_CLICKED } from "../../../_constants";


const initialState = {
  canViewList: true,
  hasRecords: true,
  keepFilters: false, /** when backing to list from detail page, list should not get refreshed */
};

function userManagement(state = initialState, action) {
  let pagination;
  switch (action.type) {
    case usersConstants.GET_USERS_LIST:
      pagination = {
        first: action.list.list.first,
        last: action.list.list.last,
        currentPage: action.list.list.number,
        pageable: action.list.list.pageable,
        totalElement: action.list.list.totalElements,
        totalPages: action.list.list.totalPages,
      };
      const hasRecords = setHasRecords(action.list.list.content);
      return {
        ...state,
        hasRecords,
        pagination,
        canViewList: true,
        usersList: action.list.list ? action.list.list.content : [],
        collections: action.list.collections,
        filterOptions: action.list.filters, // processFilters(action.list.filters)
      };
    case usersConstants.SET_FORBIDDEN_USERS:
      return {
        ...state,
        canViewList: false,
      };
    case usersConstants.UPDATE_USERS_LIST:
      return {
        ...state,
        usersList: [...state.usersList, action.list],
        collections: state.collections,
        modalClose: false,
        notification: action.notification,
      };
    case usersConstants.GET_USERS_DETAILS:
      return {
        ...state,
        keepFilters: true,
        userDetails: action.data,
      };
    case usersConstants.USER_APPLY_FILTER:
      pagination = {
        first: action.list.list.first,
        last: action.list.list.last,
        currentPage: action.list.list.number,
        pageable: action.list.list.pageable,
        totalElement: action.list.list.totalElements,
        totalPages: action.list.list.totalPages,
      };
      return {
        ...state,
        pagination,
        usersList: action.list.list.content === "" ? state.orgList : action.list.list.content,
        collections: state.collections,
        modalClose: false,
        filterOptions: action.list.filter === "" ? state.filterOptions : action.list.filter,
      };
    case usersConstants.USER_REMOVE_NOTIFICATION:
      return {
        ...state,
        usersList: state.usersList,
        collections: state.collections,
        notification: "",
      };
    case HANDLE_FILTER_CLICKED:
      return {
        ...state,
        filterOptions: action.payload.filterOptions,
      };
    case usersConstants.RESET_KEEP_FILTERS:
      return {
        ...state,
        keepFilters: false,
      };
    default:
      return state;
  }
}
export default userManagement;
