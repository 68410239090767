import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { actions } from '@redux';
import { connect } from "react-redux";
import { Header } from '../../@xc-core/components/core/header';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import ButtonCircular from '@xc-core/components/core/buttonCircular';
import Body from './body';
import {
    Input
  } from 'reactstrap';
import { Select, DatePicker, Loader } from '@xc-core/components/core';
import { dateLib, numberLib, handleError } from '@xc-core/lib';
import Switch from 'react-toolbox/lib/switch/Switch.js';
import { Col, Row } from 'reactstrap';
import { WithContext as ReactTags } from 'react-tag-input';
import config from '@xc-core/config';


const distributionControlActions = actions.DistributionControlActions;

const DistributionControlDetail = (props: IObject) =>{
    const [limitsList,setLimitsList] = useState<IObject[]>([]);
    const [alertAddressesList, setAlertAddressesList] = useState([])
    const [saving, setSaving] = useState<boolean>(false);

    let { id } = useParams<IObject>();
    const {
        getDetails,
        orgDetails,
        toggleEditAction,
        limitEditIndex,
        isEditMode,
        limitEditItem,
        saveLimitsSettings,
        addLimit,
        handleChange,
        collections,
        isLoading,
        limitEditIsNew,
        deleteLimit,
        resetOrgDetails
    } = props

    useEffect(()=>{
      return ()=>{
        resetOrgDetails()
      }
    },[])
    useEffect(()=>{
        if(id){
          callGetDetails(id)
        }
    },[id])

    useEffect(() => {
        if(orgDetails && orgDetails.limits){
            setLimitsList(() => createLimitsList(orgDetails.limits));
        }
      }, [isEditMode, limitEditItem, orgDetails.limits]);

    useEffect(()=>{
      if(orgDetails.alertAddressesList){
        let list = orgDetails.alertAddressesList.map((item:string)=> {
          return {text:item, id:item};
        })
        setAlertAddressesList(list)
      }
    },[orgDetails])  

    const callGetDetails = async (id:string|number) =>{
      try{
        getDetails(id)
      } catch(e){
        handleError(e)
      }
    }
    
    const createLimitTypeField = (limitType:any) =>{
      if(limitEditIsNew){
        return <div style={{ width: '150px' }}>
          <Select
              name={""}
              id={"limitEditItem.limitType"}
              value={limitEditItem.limitType ? limitEditItem.limitType : (limitType ? limitType : '')}
              handler={(pair: IPair) => handleLimitTypeChange(pair)}
              collections={collections.limitTypes}
          />
        </div>
      } else {
        return <div style={{width:'70px'}}>
                        {limitType ? limitType : ''}

                      </div>
      }
    }

    const createProductSlugField = (productSlug:any,editRow:boolean) =>{
      collections.products.forEach((product:IObject) => {
        if(productSlug === product.productSlug){
          productSlug = product.productName;
        }
      });  
      if(limitEditIsNew && editRow){
        return <div
          style={{ width: '150px' }}
          >
          <Select
              name={""}
              id={"limitEditItem.productSlug"}
              value={limitEditItem.productSlug ? limitEditItem.productSlug : (productSlug ? productSlug : '')}
              handler={(pair: IPair) => handleChange(pair)}
              collections={collections.products}
          />
          </div>
      } else{
            return <div style={{width:'70px'}}>
              {productSlug ? productSlug : ''}
            </div>
      }

    }

    const createRefCodeField = (refCode:string,limitEditItem:IObject,status: string) => {
      if(status === 'Active'){
        return <div style={{width:'70px'}}>
              {refCode ? refCode : ''}
            </div>
      } else {
        return (
          <div
            style={{ width: '120px' }}
          >
            <Input
              value={limitEditItem.refCode != null ? limitEditItem.refCode : refCode}
              type='text'
              style={{ width: '120px' }}
              className='mr-3'
              onChange={({ target: { value } }) => handleChangeWithLimit({ id: 'limitEditItem.refCode', value },30)}
            />
          </div>)
      }
    }

    const createStartDateField = (startDate:string,limitEditItem:IObject,status: string) =>{
      if(status === 'Active'){
        return <div style={{width:'70px'}}>
              {startDate ? startDate : ''}
            </div>
      } else {
        return (<div style={{ width: '120px' }}>
              <DatePicker
                id='limitEditItem.startDate'
                name='Start Date'
                value={limitEditItem.startDate ? limitEditItem.startDate : startDate}
                handler={(pair: IPair) => handleChange(pair)}
                min={dateLib.getCurrentDate(config.dateMomentFormat)}
                max=''
                start=''
                end=''
                openTo={
                  limitEditItem.startDate === ''
                    ? dateLib.getCurrentDate(dateLib.config.dateMomentFormat)
                    : dateLib.applyFormat(limitEditItem.startDate, dateLib.config.dateMomentFormat)
                }
                container={true}
              />
            </div>)
      }
    }

    const createEndDateField = (endDate:string,limitEditItem:IObject,status: string) =>{
      if(status === 'Active'){
        return <div style={{width:'70px'}}>
              {endDate ? endDate : ''}
            </div>
      } else {
        return (<div style={{ width: '120px' }}>
              <DatePicker
                id='limitEditItem.endDate'
                name='End Date'
                value={limitEditItem.endDate ? limitEditItem.endDate : endDate}
                handler={(pair: IPair) => handleChange(pair)}
                min={dateLib.getCurrentDate(config.dateMomentFormat)}
                max=''
                start=''
                end=''
                openTo={
                  limitEditItem.endDate === ''
                    ? dateLib.getCurrentDate(dateLib.config.dateMomentFormat)
                    : dateLib.applyFormat(limitEditItem.endDate, dateLib.config.dateMomentFormat)
                }
                className=''
                container={true}
              />
            </div>)
      }
    }


    const handlePercentChange = (data:IObject) =>{
      if((!isNaN(data.value) || data.value.length === 0) && data.value > -1 && data.value <= 100){
        handleChange({id:data.id,value: Math.floor(data.value)})
      }
    }

    const handleLimitCapChange = (data:IObject)=>{
      if((!isNaN(data.value) || data.value.length === 0) && data.value > -1){
        if(limitEditItem.limitType === 'No. Of Certificates' ||
         (!limitEditItem.limitType && orgDetails.limits[limitEditIndex].limitType === 'No. Of Certificates')) {
          handleChange({id:data.id,value: Math.floor(data.value)})
        } else {
          handleChange({id:data.id,value: data.value.slice(0,data.value.indexOf(".")) + data.value.substr(data.value.indexOf("."),3)})
        }
      }
    }

    const handleChangeWithLimit = (data:IObject,limit:number) =>{
      if(data.value.length <= limit){
        handleChange(data)
      }

    }

    const handleEmailListAddition = (tag:IObject)=>{
      let emailsList = orgDetails.alertAddressesList || [];
      emailsList.push(tag.text)
      handleChange({id: 'orgDetails.alertAddressesList', value:emailsList})
    }

    const handleEmailListDelete = (i:number)=>{
      let emailsList = orgDetails.alertAddressesList || [];
      emailsList = emailsList.filter((email:string, index:number) => index !== i)
      handleChange({id: 'orgDetails.alertAddressesList', value:emailsList})
    }

    const handleLimitTypeChange = (pair:IPair) => {
      handleChange({id:'limitEditItem.limitCap', value: ""})
      handleChange(pair)
    }

    const onSave = async () => {
      if(saving) return;
      try{
        setSaving(true)
        await saveLimitsSettings(orgDetails,limitEditItem)
      } catch(e){

      } finally{
        setSaving(false);
      }
    }


    const createLimitsList = (items: any[]) => items.map((limit: IObject, index: number) => {
        const { status = '',limitType = '',productSlug= '',limitCap= '',limitUsed= '', startDate= '', endDate= '', refCode= '', common : {createdOn ='',updatedOn=''} = {} } = limit;

        if(isEditMode && index === limitEditIndex){
            return {
                isEditing: true,
                refCode :  createRefCodeField(refCode,limitEditItem,status),
                productSlug: createProductSlugField(productSlug,true),
                limitType: createLimitTypeField(limitType),
                startDate: createStartDateField(startDate,limitEditItem,status),
                endDate: createEndDateField(endDate,limitEditItem,status),
                  limitUsed:(<div style={{width:'60px'}}>
                    {limitType !== 'No. Of Certificates' ? numberLib.applyFormat(limitUsed,config.numberFormat) : limitUsed +""}

                  </div>),
                  limitCap: (<div style={{width:'90px'}}>
                    <Input
                      value={limitEditItem.limitCap != null ? limitEditItem.limitCap : limitCap}
                      type='text'
                      style={{ width: '90px' }}
                      className='mr-3'
                      onChange={({ target: { value } }) => handleLimitCapChange({ id: 'limitEditItem.limitCap', value })}
                  />
                  </div>),
                  status: (<div style={{width:'50px'}}>
                    {status ? status : ''}
                  </div>),
                  updatedOn: (<div style={{width:'50'}}>
                    {updatedOn ? updatedOn : ''}
                  </div>),
                  createdOn: (<div style={{width:'50'}}>
                    {createdOn ? createdOn : ''}
                </div>),
                action: ' '      
            }
        } else {
            return {
                isEditing: false,
                refCode,
                productSlug : createProductSlugField(productSlug,false),
                limitType,
                startDate,
                endDate,
                limitUsed : limitType !== 'No. Of Certificates' ? numberLib.applyFormat(limitUsed,config.numberFormat) : limitUsed +"",
                limitCap : limitType !== 'No. Of Certificates' ? numberLib.applyFormat(limitCap,config.numberFormat) : limitCap +"",
                status: status ? status : '',
                updatedOn,
                createdOn,
                action: <div style={{display:'flex'}}>
                  { status !== 'Expired' &&  
                  <ButtonCircular
                  className='table-row-button mr-1 ml-1'
                  handler={() => toggleEditAction(index,limitEditIsNew)}
                  key='row-action-edit'
                  icon='pencil'
                  id='record.edit'
                  name='details'
                />
                 }
                {!limitUsed && <ButtonCircular
                  className='table-row-button mr-1 ml-1 border-danger text-danger bg-transparent'
                  handler={() => deleteLimit(index,limitEditIsNew,orgDetails.limits[index])}
                  key='row-action-delete'
                  icon='garbage'
                  id='record.delete'
                  name='details'
                />}
                </div>,
              };
        }
    })
    if (isLoading) return <Loader />;
    if(orgDetails.inheritLimitsFromParent && orgDetails.parentStatus) return <div>
      <Header
                title={orgDetails.orgUnitName ? orgDetails.orgUnitName : ""}
                bannerGraphic={bannerImg}
              />
              <Row className='d-flex justify-content-center my_30'>
                <Col sm={12} className='col-sm-12 d-flex' style={{ padding: '0 4%', pointerEvents:'none', filter:'grayScale(100%)' }}>
                  <span className='mr-3'>Distribution Control</span>
                  <Switch
                  theme={{
                    on: 'switchOn switch',
                    thumb: 'thumb',
                    off: 'switch'
                  }}
                  checked={orgDetails.parentStatus ? true : false}
                  label={
                    orgDetails.parentStatus
                      ? 'On'
                      : 'Off'
                  }
                 />
                </Col>
                <Col sm={12} style={{ padding: '0 4%'}}>
                <div className="alert alert-info d-inline-block" role="alert">
                  This organisation shares distribution limits with {orgDetails.orgUnitParent}.
                </div>
                </Col>
              </Row>
    </div>
    return <div data-testid="containter">
            <Header
                title={orgDetails.orgUnitName ? orgDetails.orgUnitName : ""}
                bannerGraphic={bannerImg}
              />
              <Row className='d-flex justify-content-center my_30'>
                <Col sm={12} className='col-sm-12 d-flex' style={{ padding: '0 4%' }}>
                  <span className='mr-3'>Distribution Control</span>
                  <Switch
                  theme={{
                    on: 'switchOn switch',
                    thumb: 'thumb',
                    off: 'switch'
                  }}
                  checked={orgDetails.status ? true : false}
                  label={
                    orgDetails.status
                      ? 'On'
                      : 'Off'
                  }
                  onChange={(e:any) => handleChange({ id: 'orgDetails.status', value: e })}
                 />
                </Col>
                { (orgDetails.orgUnitType === 'Agency' || orgDetails.orgUnitType === 'Regional Office') && orgDetails.status &&
                  <Col sm={12} className='col-sm-12 d-flex' style={{ padding: '0 4%' }}>
                  <span className='mr-3'>Shared Limit with Branches</span>
                  <Switch
                    theme={{
                      on: 'switchOn switch',
                      thumb: 'thumb',
                      off: 'switch'
                    }}
                    checked={orgDetails.sharedLimitWithBranches ? true : false}
                    label={
                      orgDetails.sharedLimitWithBranches
                        ? 'On'
                        : 'Off'
                    }
                    onChange={(e:any) => handleChange({ id: 'orgDetails.sharedLimitWithBranches', value: e })}
                   />
                  </Col>
                }

                { orgDetails.status && <Col sm={12} className='col-sm-12 d-flex' style={{ padding: '0 4%' }}>
                <span className='mr-3'>Email Alerts</span>
                <Switch
                  theme={{
                    on: 'switchOn switch',
                    thumb: 'thumb',
                    off: 'switch'
                  }}
                  checked={orgDetails.emailAlertStatus ? true : false}
                  label={
                    orgDetails.emailAlertStatus
                      ? 'On'
                      : 'Off'
                  }
                  onChange={(e:any) => handleChange({ id: 'orgDetails.emailAlertStatus', value: e })}
                 />
                </Col>
                }
                {
                  orgDetails.status && orgDetails.emailAlertStatus && 
                  <Col sm={12} className='col-sm-12 d-flex align-items-center' style={{ padding: '0 4%' }}>
                    <span className='mr-3'>Alert Threshold</span>
                    <Input
                        value={orgDetails.alertThreshold ? orgDetails.alertThreshold: ''}
                        type='text'
                        style={{ width: '120px' }}
                        className='mr-3'
                        onChange={({ target: { value } }) => handlePercentChange({ id: 'orgDetails.alertThreshold', value })}
                      />
                      <span>% of limit used (Applies to all limits)</span>
                  </Col>
                }
                {
                  orgDetails.status && orgDetails.emailAlertStatus && 
                  <Col sm={12} className='col-sm-12 d-flex my-3' style={{ padding: '0 4%' }}>
                    <span className='mr-3'>Send Alerts To</span>
                    {/* <div> */}
                      <ReactTags
                          tags={alertAddressesList ? alertAddressesList : []}
                          allowDragDrop={false}
                          placeholder = "Type an email and hit enter'"
                          handleDelete={handleEmailListDelete}
                          handleAddition={handleEmailListAddition}
                          inputFieldPosition="inline"
                          inline={true}
                        />
                    {/* </div> */}
                  </Col>
                }
              </Row>
              <Body
                limits={limitsList}
                addLimit={()=>{
                  if(!limitEditIsNew){
                    addLimit()
                  }
                }}
                saveSettings={()=> onSave()}
                status={orgDetails.status}
                />
                
        </div>

}

const mapStateToProps = (state: IStore) => ({
    orgDetails: state.distributionControl.orgDetails,
    isEditMode: state.distributionControl.isEditMode,
    limitEditIndex: state.distributionControl.limitEditIndex,
    limitEditItem: state.distributionControl.limitEditItem,
    collections: state.distributionControl.collections,
    isLoading: state.distributionControl.isLoading,
    limitEditIsNew: state.distributionControl.limitEditIsNew
 });
 const mapDispatchToProps = (dispatch: any) => ({
   getDetails: (id:any) => dispatch(distributionControlActions.getDetails(id)),
   toggleEditAction : (index:number,limitEditIsNew:boolean) => dispatch(distributionControlActions.toggleEdit(index,limitEditIsNew)),
   addLimit: () => dispatch(distributionControlActions.addLimit()),
   handleChange: (pair: IPair) => dispatch(distributionControlActions.handleChange(pair)),
   saveLimitsSettings: (data: IObject,item:IObject) => dispatch(distributionControlActions.saveLimitsSettings(data,item)),
   deleteLimit : (index:number,limitEditIsNew:boolean, limit:IObject) => dispatch(distributionControlActions.deleteLimit(index,limitEditIsNew,limit)),
   resetOrgDetails: () => dispatch(distributionControlActions.resetOrgDetails())
 });

export default connect(mapStateToProps, mapDispatchToProps)(DistributionControlDetail);

