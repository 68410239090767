import React from "react";
import {Col, Input, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row} from "reactstrap";
import {OrganisationInput} from "../TeamManagementModule/OrganizationModule/containers";
import CommisionSetting from "./CommisionSetting";

const Products = (props) => {
    const {commissionValidationError,collections, productsOnState, handleProductChange,orgDetails, canEditCommission, handleCommission, handlePriceField ,validator,isAgency} = props;
    const permissionStatuses = collections ? collections.permissionStatuses : [];
    return (
        collections && collections.products.length ?
            <Row className="justify-content-center my_50">
                <Col sm={10}>
                    <ListGroup className="list shadow-sm rounded-xl p-2">
                        <ListGroupItemHeading className="text-primary p-3">
                            <ListGroupItemText>
                                Products
                            </ListGroupItemText>
                        </ListGroupItemHeading>
                        {collections.products.map((product, index) => {
                            /**
                             * check if this product has been changed (on the state)
                             * and then update the dropdown value accordingly
                             **/
                            const changedProduct = productsOnState.find(p => p.productName === product.productName);
                            return (
                                <ListGroupItem key={index} className="border-0 border py-0 pb-3">

                                    <Row className="justify-content-between align-items-center">
                                        <Col sm={4} className="py-2">
                                            {product.productName}
                                        </Col>
                                        <Col sm={4} className="justify-end" style={{paddingLeft:'1.2em'}}>
                                            <OrganisationInput
                                                option={permissionStatuses}
                                                idName="products"
                                                type="select"
                                                onChange={(e) => handleProductChange(index, e)}
                                                value={changedProduct ? changedProduct.status : product.status}
                                                validator={""}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="light-grey-box ml-0 mr-0">
                                        {isAgency ?
                                            (<CommisionSetting
                                                product={product}
                                                orgDetails={orgDetails}
                                                collections={collections}
                                                canEditCommission={canEditCommission}
                                                handleCommission={handleCommission}
                                                handlePriceField={handlePriceField}
                                                validator={validator}
                                                commissionValidationError={commissionValidationError}
                                            />)
                                            : null}
                                    </Row>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </Col>
            </Row> : ""
    );
};

export default Products;
