import env from 'env';
import Request from './request';
import Response from './response';
import BaseListingService from './baseListing';

class DataDumpService extends BaseListingService {
  protected env = env.policy;

  protected api = 'policies';

  protected withApi = '';

  public generateFiles = async (dataDumpDate: string): Promise<any> => {
    const url = `${this.env}/${this.api}/data-dump`;
    const request = new Request({ dataDumpDate }, 'POST');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
}

export default DataDumpService;
