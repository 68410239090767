import React from "react";
import { Button, Form } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';

import { CommonInputTypes } from "../../../_components/CommonInputTypes";
import MultiSelect from "../../../_components/MultiSelect";

class CreateUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userGrps: null,
      name: "",
      userName: "",
      email: "",
      orgName: "",
      inputValue: "",
      filteredUserGrps: [],
      usernameError: "",
      emailError: "",
      disableSubmit: false,
    };
    this.validator = new SimpleReactValidator({
      // element: (message, className) => <div className="invalid-feedback d-block">{message}</div>,
      element:(message, className) => <small className={`${className} text-danger`}>{message}</small>,
      className: 'text-danger',
      messages: {
        email: 'That is not an email.'
        // default: "Womp! That's not right!"
      },
      validators: {
        ip: { // name the rule
          message: 'The :attribute must be a valid IP address.', // give a message that will display when there is an error. :attribute will be replaced by the name you supply in calling it.
          rule: function(val, params, validator) { // return true if it is succeeds and false it if fails validation. the testRegex method is available to give back a true/false for the regex and given value
            // check that it is a valid IP address and is not blacklisted
            return validator.helpers.testRegex(val,/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i) && params.indexOf(val) === -1
          }
        },
        unique_username: {
          message: ":attribute already exists.",
          rule: () => (this.state.usernameError !== "Username already exists")
        },
        unique_email: {
          message: ":attribute already exists.",
          rule: () => (this.state.emailError !== "Email already exists")
        }
      }
    });
    this.resetBuilder = this.resetBuilder.bind(this);
  }

  componentDidMount() {
    this.baseState = this.state;
  }

  handleMultipleChange = (newValue, actionMeta) => {
    this.setState({
      userGrps: newValue
    });
  };

  resetBuilder = e => {
    this.setState(this.baseState);
    this.props.toggle();
    e.preventDefault();
  };

  onSubmit = async e => {
    e.preventDefault();
    const { email, name, orgName, userGrps, userName } = this.state;
    if( this.validator.allValid() ){
      this.setState({ disableSubmit: true });
      let updatedGrp = userGrps.map(data => {
        let copyOfObj = data.name;
        return copyOfObj;
      });
      let userValues = {
        name,
        email: email,
        orgUnit: orgName,
        isActive: true,
        groups: updatedGrp,
        username: userName,
        password:"12345678",
      };
      const result = await this.props.handleSubmit(userValues);
      this.setState({disableSubmit: false});
      if (result.error) {
        result.message === "Username already exists" && this.setState({
            usernameError: result.message
        });
        result.message === "Email already exists" && this.setState({
          emailError: result.message
        });
        this.setState({ disableSubmit: false });
        this.validator.showMessages();
        this.forceUpdate();
      } else {
        this.setState({ disableSubmit: false });
        await this.props.getList(0, 10);
      }
    } else {
      this.setState({ disableSubmit:false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
    // check if we are changing username and reset the error messages
    if (name === 'userName') this.setState({ usernameError: '' });
    if (name === 'email') this.setState({ emailError: '' });

    if (name === 'orgName' && value !== '') {
      const { groups, organisation } = this.props;
      const selectedOrgType = organisation.find(org => org.code === value).extendedProperties.type;
      const filteredUserGrps = groups.filter(group => (group.extendedProperties.orgUnitType === selectedOrgType));
      this.setState({ filteredUserGrps })
    } else if (name === 'orgName' && value === '') this.setState({ filteredUserGrps: [] })
  }

  onInputChange = (inputValue, { action }) => {
    switch (action) {
      case "input-change":
        this.setState({ inputValue });
        return;
      case "menu-close":
        let menuIsOpen = undefined;
        if (this.state.inputValue) {
          menuIsOpen = true;
        }
        this.setState({
          menuIsOpen
        });
        return;
      default:
        return;
    }
  };

  render() {
    const { userGrps, name, email, orgName, inputValue, filteredUserGrps, userName } = this.state;
    const { organisation } = this.props;
    return (
      <Form>
        <CommonInputTypes
          idName="name"
          group={false}
          placeholder="Enter Name"
          type="input"
          value={name}
          onChange={this.handleInputChange.bind(this)}
          title="Name"
          id="1"
          validator={this.validator}
        />
        <CommonInputTypes
          idName="userName"
          placeholder="Enter Username"
          type="input"
          value={userName}
          onChange={this.handleInputChange.bind(this)}
          title="Username"
          id="4"
          validator={this.validator}
          validationRules="unique_username"
        />
        <CommonInputTypes
          idName="email"
          group={false}
          placeholder="Enter Email"
          type="email"
          value={email}
          onChange={this.handleInputChange.bind(this)}
          title="Email"
          id="2"
          validator={this.validator}
          validationRules="email|unique_email"
        />
        <CommonInputTypes
          idName="orgName"
          group={false}
          disabled={false}
          option={organisation}
          type="select"
          title="Organisation"
          value={orgName}
          onChange={this.handleInputChange.bind(this)}
          id="3"
          validator={this.validator}

        />
        <MultiSelect
          title="User Group(s)"
          idName="userGrp"
          value={userGrps}
          onInputChange={this.onInputChange.bind(this)}
          inputValue={inputValue}
          options={filteredUserGrps}
          handleChange={this.handleMultipleChange.bind(this)}
          validator={this.validator}
        />
        <div className="row justify-content-end" id="modalButton">
          <div className="col-2">
            <Button
              onClick={this.resetBuilder}
              className="text-info"
              color="link"
            >
              Cancel
            </Button>
          </div>
          <div className="col-3">
            <Button
              onClick={this.onSubmit.bind(this)}
              color="primary"
              className="fullWidth rounded-xl"
              disabled={this.state.disableSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export default CreateUserForm;
