
import fields from './fields';
import baseModel from '../base-models';

const { quotation } = fields;

const detailsMainModel = [
  quotation.name,
  quotation.gender,
  quotation.nric,
  quotation.religionName,
  quotation.dob,
  quotation.address,
  quotation.city,
  quotation.stateName,
  quotation.postcode,
  quotation.country,
  quotation.occupationName,
  quotation.businessName,
  quotation.height,
  quotation.weight,
  quotation.email,
  quotation.mobile,
];

const detailsHealthQuestionnaireModel = [
  quotation.treatmentFor,
  quotation.advisedTo,
  quotation.declinedInsuranceCoverage,
  quotation.insuranceBenefitClaim,
];

const detailsNomineeModel = [
  quotation.nomineeName,
  quotation.nomineeNationalityName,
  quotation.nomineeReligion,
  quotation.nomineeIdType,
  quotation.nomineeIdNumber,
  quotation.nomineeDob,
  quotation.nomineeAddress,
  quotation.nomineeCity,
  quotation.nomineeStateName,
  quotation.nomineePostcode,
  quotation.nomineeCountry,
  quotation.nomineeEmail,
  quotation.nomineeMobile,
];

const detailsWitnessModel = [
  quotation.witnessName,
  quotation.witnessNric,
  quotation.witnessMobile,
];

const models = {
  detailsMainModel,
  detailsHealthQuestionnaireModel,
  detailsNomineeModel,
  detailsWitnessModel,
  ...baseModel,
};

export default models;