
import React from 'react';
import { Row, Col } from 'reactstrap';
import Individual from './individual';


interface IPair { beneficiary: any, insured: any }
interface IProps { model: IPair, item: IPair, children: any }
const Pair = (props: IProps) => {
  const { model, item, children } = props;
  return (
    <>
      <Row>
        <Col sm='12'>
          {children}
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          <p className='custom-text-primary'>Insured Person Details</p>
          <Individual model={model.insured} item={item.insured} />
        </Col>
        <Col md='6'>
          <p className='custom-text-primary'>Beneficiary Details</p>
          <Individual model={model.beneficiary} item={item.beneficiary} />
        </Col>
      </Row>
    </>
  );
};

export default Pair;
