import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TitleComponent, Products } from '_components';
import {
  Card, CardFooter, CardBody,
  Row, Col, Button
} from 'reactstrap';
import { orgActions } from 'TeamManagementModule/OrganizationModule/actions';
import Switch from 'react-toolbox/lib/switch/Switch.js';
import SimpleReactValidator from 'simple-react-validator';
import { OrganisationInput } from 'TeamManagementModule/OrganizationModule/containers';
import { withStyles } from '@material-ui/core/styles';
import Notifications from 'react-notification-system-redux';
import { utils } from 'utils';
import config from '@xc-core/config';
import organisationGraphic from '../../../images/bannerGraphicImages/organisations.png';
import { MailingSetting } from '@containers/index';
import { objLib } from '@xc-core/lib';

const AgencyTypes = ['Agency', 'Bank Agency', 'Agency Branch'];

const styles = {
  root: {
    '&$checked': {
      color: '#2B61B4',
    },
  },
  checked: {
    color: '#2B61B4',
  },
};

class OrganisationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSwitch: '',
      commissionTier: [],
      products: [],
      possibleParentsOrgs: [],
      backPath: '',
      canEditCommission: true,
      validationError: false,
      commissionValidationError: []
    };

    this.validator = new SimpleReactValidator({
      className: 'text-danger',
      messages: {
        email: 'That is not an email.',
      },
      validators: {
        isRequired: {
          message: 'This field is required.',
          rule: (val, params, validator) => {
            return !validator.helpers.isBlank(val) && !!val // making sure val is truthy value
          },
          required: true,
        },
      },
    });
  }

  async componentDidMount() {
    const { match: { params }, history, getOrgDetails, hasPermissionTo } = this.props;
    const orgDetails = await getOrgDetails(params.name);

    if (orgDetails.error) return history.push('/organizationsManagement');
    const backPath = history.location.pathname.split('/');
    this.setState({ backPath: [...backPath.slice(0, 2)].join('/') });

    const canEditCommission = await hasPermissionTo('Edit Commission');
    this.setState({
      canEditCommission,
      orgDetail: {
        ...orgDetails.item,
      },
    });
  }

  componentWillReceiveProps(props) {
    const { orgDetails } = props;
    if (!orgDetails) return;
    const orgType = orgDetails.item.extendedProperties.type;
    const parentType = orgDetails.collections.orgUnitType.find(orgUnitType => (orgUnitType.code === orgType))
      .extendedProperties.parentTypes;
    const possibleParentsOrgs =
      orgDetails.collections.parents.filter(parent => (parent.extendedProperties.type === parentType
        || parentType.includes(parent.extendedProperties.type)));

        this.setState({
      possibleParentsOrgs,
      orgDetails: {
        ...orgDetails.item,
        orgUnitUserCode: orgDetails.item.extendedProperties.orgUnitUserCode,
        companyName: orgDetails.item.extendedProperties.companyName,
        ...(orgDetails.item.ownerRef.includes('cb-insurance') && {usePaymentGateway: orgDetails.item.extendedProperties.usePaymentGateway}),
      },
      collections: orgDetails.collections,
      activeSwitch: orgDetails.item.status,
    });
    if (orgDetails.item.extendedProperties.commissionTier) {
      this.setState({
        commissionTier: orgDetails.item.extendedProperties.commissionTier
      });
      if (props.orgDetails.item.extendedProperties.commissionTier) {
        this.setState({
          commissionTier:
            props.orgDetails.item.extendedProperties.commissionTier
        });
      }
      if (props.orgDetails.products) {
        let addedCheckedKey = props.orgDetails.products.map(data => {
          let tempData = data;
          let checkedKey = { isChecked: false };
          tempData = { ...tempData, ...checkedKey };
          return tempData;
        });
        this.setState({
          products: addedCheckedKey
        });
      }

      if (props.orgDetails.item.extendedProperties.products && props.orgDetails.products) {
        let arr1 = props.orgDetails.item.extendedProperties.products;
        let arr2 = props.orgDetails.products;
        var result = arr2.reduce(function(r, e) {
          var f = arr1.find(el => e.id === el.id);
          r.push(f ? f : e);
          return r;
        }, []);
        let addedCheckedKey = result.map(data => {
          let tempData = data;
          let checkedKey = {
            isChecked: data.isChecked ? data.isChecked : false
          };
          tempData = { ...tempData, ...checkedKey };
          return tempData;
        });
        this.setState({
          products: addedCheckedKey
        });
      }
    }
  }

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value
    });
  };

  handleInputChange = (event) => {
    const { orgDetails } = this.state;
    const { target } = event;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    if (name === 'commissionValue') {
      value = value === '' || utils.lib.isNumeric(value) ? value : orgDetails[name];
    }
    orgDetails[name] = value;
    this.setState({
      orgDetails,
    });
  }

  handleCommission = (commission) => {
    const { orgDetails } = this.state;
    const commissionItems= orgDetails.extendedProperties?.commissions;

    this.setState({
      orgDetails: {
        ...orgDetails,
        extendedProperties: {
          ...orgDetails.extendedProperties,
          commissions: !commissionItems ||
          (commissionItems && !commissionItems.find((el)=>el.product_code === commission.product_code))
              ? [...(commissionItems || []),commission] :
              commissionItems.map((el)=>{
                if(el.product_code === commission.product_code ){
                  return {...commission}
                }
                return el
              })
        },
      },
    });
  }

  handlePriceField = (event) => {
    const { orgDetails } = this.state;
    let { value, name } = event.target;

    this.setState({
      orgDetails: {
        ...orgDetails,
        extendedProperties: {
          ...orgDetails.extendedProperties,
          commission: {
            ...orgDetails.extendedProperties.commission,
            [name]: value,
          },
        },
      },
    });
  }

  validateCommissionSetting=()=>{
    const {collections,orgDetails,} = this.state;

    const errorMessages = collections.products.reduce((prev,currentProduct)=>{
      const item = orgDetails?.extendedProperties?.commissions?.find((currentCommission)=>{
        return currentCommission.product_code === currentProduct.productCode &&
            (currentCommission?.value === "" || !currentCommission?.code || !currentCommission?.priceField || !currentCommission?.type)
      })

      if(orgDetails?.extendedProperties?.commissions){
        return (item || (!item && !orgDetails?.extendedProperties?.commissions?.some((el)=>el.product_code === currentProduct.productCode))) && currentProduct.status === "permitted" ? [
            ...prev,
          {
            productName:item?.product_name || currentProduct.productName,
            errorMessage:'Required'
          }
        ] : prev
      }else{
        return prev
      }
    },[])
    this.setState({
      commissionValidationError: errorMessages
    })
    return errorMessages;
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      match: { params },
      // dispatch
      updateOrg,
      history,
      successNotification,
      errorNotification,
    } = this.props;
    const orgUpdatedNotificationOpts = {
      title: 'Organisation updated successfully.',
      position: 'tr',
      autoDismiss: 2,
    };
    const { orgDetails, commissionTier, activeSwitch, products } = this.state;
    let allowedProducts = products.filter(e => e.isChecked === true);
    let orgValues = {
      products,
      name: orgDetails.name,
      description: '',
      parent: orgDetails.parent,
      status: activeSwitch,
      dataScope: orgDetails.dataScope && orgDetails.dataScope.length ? orgDetails.dataScope : null,
      extendedProperties: {
        ...orgDetails.extendedProperties,
        type: orgDetails.type ? orgDetails.type : orgDetails.extendedProperties.type,
        commissionTier,
        products: allowedProducts,
        orgUnitUserCode: orgDetails.orgUnitUserCode,
        companyName: orgDetails.companyName,
        ...(orgDetails.ownerRef.includes('cb-insurance') && {usePaymentGateway: orgDetails.usePaymentGateway}),
      },
    };
    /**
     * check if we an agency type organisation and only then add the commission setting
     * this is handling the case when the organisation type is edited
     * however currently we are not allowing the user to edit the organization type
     */
    if (AgencyTypes.includes(orgDetails.extendedProperties.type)) {
      orgValues = {
        ...orgValues,
        extendedProperties: {
          ...orgValues.extendedProperties
        },
      };
    }

    // check active states email
    const proLenght = orgValues.extendedProperties.config?.mailingList?.products.length;
    var failed = false;
    for (let i = 0; i < proLenght; i++){
      const product = orgValues.extendedProperties.config.mailingList.products[i];
      failed = product?.states.reduce(function(failed, item) {
        const updatedStatus = failed || item?.enabled && (item?.cc.length === 0);
        return updatedStatus;
      }, failed);
    }

    const commissionSettingErrors=  this.validateCommissionSetting();

    if(commissionSettingErrors.length > 0){
      return;
    }

    if (this.validator.allValid() && !failed) {
      const updatedOrganization = await updateOrg(params.name, orgValues);
      if (!updatedOrganization.error) {
        successNotification(orgUpdatedNotificationOpts);
        // dispatch(Notifications.success(orgUpdatedNotificationOpts));
        history.push('/organizationsManagement');
      } else {
        errorNotification({...orgUpdatedNotificationOpts, title: updatedOrganization.message});
        // dispatch(Notifications.error({...orgUpdatedNotificationOpts, title: updatedOrganization.message}));
      }
    } else {
      if (failed) {
        this.setState((state) => ({
          ...state,
          validationError: true
        }));
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  /**
   * @function handleProductChange should be refactored in a separate file
   * because it is very similar to the one on userDetails
   */
  handleProductChange = (productIndex, event) => {
    const {collections} = this.state;
    const productToChange = collections.products.find((product, index) => (index === productIndex));
    const changedProduct = {...productToChange, status: event.target.value };
    const currentChangedProducts = this.state.products;
    let newProducts;

    /**check if this product has already been changed**/
    if (currentChangedProducts.find((product) => product.productName === changedProduct.productName)) {
      const indexOnState = currentChangedProducts
        .findIndex((product) => (product.productName === changedProduct.productName));

      newProducts = [
        ...currentChangedProducts.slice(0, indexOnState),
        changedProduct,
        ...currentChangedProducts.slice(indexOnState + 1)
      ];
    } else newProducts = [...currentChangedProducts, changedProduct];

    this.setState({
      products: newProducts,
      collections:{
        ...collections,
        products: collections.products.map((el)=>{
          const currentUpdated = newProducts?.find((val)=>val.productCode === el.productCode);
          if(currentUpdated){
            return {...currentUpdated}
          }
          return el;
        })
      }
    });
  };

  orgCodeChange = (e) => {
    const value = e.target.value;
    if (value === '' || utils.lib.isOnlyNumberOrAlphabet(value)) {
      return this.handleInputChange(e);
    }
  }

  handleConfigChange = (event) => {
    const { orgDetails } = this.state;
    let { value, id } = event;
    var lis = [];
    if (value === 'true') {
      lis = [];
    } else {
      lis = [];
      this.state.collections.products.map((product, index) => {
        let obj = {
          code: product.productSlug,
          name: product.productName,
          states: [
            {
              enabled: false,
              name: 'New',
              cc: [],
              bcc: [],
            },
            {
              enabled: false,
              name: 'Endorsement',
              cc: [],
              bcc: [],
            },
            {
              enabled: false,
              name: 'Cancellation',
              cc: [],
              bcc: [],
            }
          ]
        }
        lis[index] = obj;
      });
    }

    this.setState({
      orgDetails: {
        ...orgDetails,
        extendedProperties: {
          ...orgDetails.extendedProperties,
          config: {
            ...orgDetails.extendedProperties?.config,
            mailingList: {
              ...orgDetails.extendedProperties.config?.mailingList,
              [id]: value === 'true' ? false : true,
              products: lis,
            }
          },
        },
      },
    });
  }

  handleProductConfigChange = (index , stateIdx , event) => {
    const { orgDetails } = this.state;    
    const productData = orgDetails.extendedProperties.config?.mailingList?.products[index];

    if (event.id === 'enabled') {
      const pro = {
        name: this.state.collections.products[index].productName,
        code: this.state.collections.products[index].productSlug,
        states: [
          ...productData?.states.slice(0, stateIdx) ,
          {
            enabled: productData?.states[stateIdx]?.enabled
              ? !productData?.states[stateIdx]?.enabled
              : true,
            name: productData?.states[stateIdx]?.name ? productData?.states[stateIdx].name : '',
            cc: productData?.states[stateIdx]?.cc ? productData?.states[stateIdx].cc : [],
            bcc: productData?.states[stateIdx]?.bcc ? productData?.states[stateIdx].bcc : [],
          },
          ...productData?.states.slice(stateIdx + 1)
        ]
      }
      var newOrgDetails = objLib.setValueWithLodash(orgDetails, `extendedProperties.config.mailingList.products[${index}]`, pro);
      this.setState({ orgDetails: newOrgDetails ,validationError: false });
    } else {
      let newVal = [];
      if (event?.value?.split(';')) {
        newVal = event?.value?.split(';')
      }
      var newOrgDetails = objLib.setValueWithLodash(orgDetails, `extendedProperties.config.mailingList.products[${index}].states[${stateIdx}][${event.id}]`, newVal);
      this.setState({ orgDetails: newOrgDetails , validationError: false});
    }

  }

  isAgency = (orgDetails) => {
    return orgDetails && AgencyTypes.includes(orgDetails.extendedProperties.type);
  };

  render() {
    let { orgDetails, collections, canEditCommission,commissionValidationError } = this.state;

    return (
      <div className='container-fluid px-0'>
        <TitleComponent 
          title='Edit - Organisation Details'
          bannerGraphic={organisationGraphic}
          backTitle='Back to listing'
          backPath={this.state.backPath}
          history={this.props.history}
        />
        <Row className='justify-content-center my_50'>
          <Col sm='10'>
            <Card className='rounded-xl shadow-md border-0'>
              {orgDetails === undefined ? (
                <div
                  className={
                    orgDetails === undefined ? 'loader d-block' : 'd-none'
                  }
                />
              ) : (
                <>
                  <CardBody className='p-3 org-details-all'>
                    <h6 className='text-primary card-title sub-title-container'>
                      Organisation Details
                    </h6>
                    <Row>
                      <Col md={4}>
                        <OrganisationInput
                          idName='name'
                          type='input'
                          value={
                            orgDetails === undefined ? '' : orgDetails.name
                          }
                          onChange={this.handleInputChange}
                          title='Organisation Name'
                          validator={this.validator}
                        />
                      </Col>
                      <Col md={4}>
                        <OrganisationInput
                          option={ collections ? collections.orgUnitType : [] }
                          idName='type'
                          type='select'
                          onChange={this.handleInputChange}
                          value={ orgDetails.type ? orgDetails.type : orgDetails.extendedProperties.type }
                          title='Organisation Type'
                          validator={this.validator}
                          disabled={true}
                        />
                      </Col>
                      <Col md={4}>
                        <OrganisationInput
                          option={this.state.possibleParentsOrgs}
                          idName='parent'
                          type='select'
                          onChange={this.handleInputChange}
                          title='Parent Organisation'
                          value={
                            orgDetails !== undefined ? orgDetails.parent : ''
                          }
                          validator={
                            orgDetails.type !== 'Insurer' || orgDetails.extendedProperties.type !== 'Insurer' ?
                              '':
                              this.validator
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <OrganisationInput
                          type='text'
                          idName='orgUnitUserCode'
                          title='Organisation Code'
                          onChange={(e) => this.orgCodeChange(e)}
                          value={orgDetails === undefined ? '' : orgDetails.orgUnitUserCode}
                          validator={this.validator}
                          placeholder='Enter organisation code'
                          // validationRules='min:6|max:6'
                        />
                        </Col>
                         <Col md={4}>
                         <OrganisationInput
                          type='text'
                          idName='companyName'
                          title='Company Name'
                          onChange={(e) => this.handleInputChange(e)}
                          value={orgDetails === undefined ? '' : orgDetails.companyName}
                          validator={''}
                          placeholder='Enter company name'
                        />
                        </Col>
                        {orgDetails.ownerRef.includes('cb-insurance') && 
                        <Col md={4} className="d-flex align-items-center">
                        <Switch
                          theme={{
                            on: 'switchOn switch',
                            thumb: 'thumb',
                            off: 'switch'
                          }}
                          checked={orgDetails === undefined ? false : orgDetails.usePaymentGateway}
                          label={
                            orgDetails.usePaymentGateway
                              ? 'Use Payment Gateway? Yes'
                              : 'Use Payment Gateway? No'
                          }
                          on={'#ffff'}
                          onChange={(e) => this.handleInputChange({target:{name:'usePaymentGateway', type:'radio', value: e}})}
                        />
                        </Col>
                        } 
                      
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Switch
                      theme={{
                        on: 'switchOn switch',
                        thumb: 'thumb',
                        off: 'switch'
                      }}
                      checked={this.state.activeSwitch}
                      label={
                        this.state.activeSwitch
                          ? 'Status: Active'
                          : 'Status: Inactive'
                      }
                      on={'#ffff'}
                      onChange={this.handleChange.bind(this, 'activeSwitch')}
                    />
                  </CardFooter>
                </>
              )}
            </Card>
          </Col>
        </Row>

        <Products
          handleProductChange={this.handleProductChange}
          collections={collections}
          productsOnState={this.state.products}
          orgDetails={orgDetails}
          canEditCommission={canEditCommission}
          handleCommission={this.handleCommission}
          handlePriceField={this.handlePriceField}
          validator={this.validator}
          isAgency={this.isAgency(orgDetails)}
          commissionValidationError={commissionValidationError}
        />

        <MailingSetting
          orgDetails={orgDetails}
          collections={collections}
          handleConfigChange={this.handleConfigChange}
          handleProductConfigChange={this.handleProductConfigChange}
          validationError={this.state.validationError}
        />
        
        <Col sm='11'>
              <div className='row justify-content-end mb-5' id='modalButton'>
                <div className='col-2 text-center'>
                  <Button
                    onClick={() =>
                      this.props.history.push('/organizationsManagement')
                    }
                    className='text-info'
                    color='link'
                  >
                    Cancel
                  </Button>
                </div>
                <div className='col-2'>
                  <Button
                    color='primary'
                    onClick={this.onSubmit}
                    className='fullWidth rounded-xl'
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Col>
      </div>

    );
  }
}

OrganisationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { orgDetails } = state.organisations;
  return {
    orgDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hasPermissionTo: (scope) => dispatch(orgActions.hasPermissionTo(scope)),
    getOrgDetails: (orgName) => dispatch(orgActions.getOrgDetails(orgName)),
    updateOrg: (name, values) => dispatch(orgActions.updateOrg(name, values)),
    successNotification: (opts) => dispatch(Notifications.success(opts)),
    errorNotification: (obj) => dispatch(Notifications.error(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganisationDetails));
