import Notifications from 'react-notification-system-redux';
import {handleRequest, handleResponse, getNotificationMessage, checkPermission} from "../../../_services";
import { store } from "../../../_helpers";
import {getRequestOptions} from "../../../utils";

const Config = require('_config/env');

const apiUrl = Config.env.apiVersion;
const quotationUrl = Config.env.quotationUrl;

export const orgServices = {
  getOrganisationList,
  getOrganisationDetails,
  createOrganisation,
  updateOrganisation,
  applyFilter,
  getProducts,
  getOrganisationsChart,
  hasPermission,
};

async function getOrganisationList(page, perPage) {
  const url = `${apiUrl}/identity/orgunits/withcollections?page=${page}&pageSize=${perPage}`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  let res;
  if (serverResponse.error) {
    store.dispatch(Notifications.error(getNotificationMessage('An error occurred in getting organisations.')));
  } else res =  await handleResponse(serverResponse);
  return res || serverResponse;
}

async function createOrganisation(body){
  const url = `${apiUrl}/identity/orgunits`;
  const serverResponse = await handleRequest(url, getRequestOptions({ body, method:"Post" }));
  const res = await handleResponse(serverResponse);
  return res;
}

async function updateOrganisation(name, body){
  const url = `${apiUrl}/identity/orgunits/${name}/with-associations`;
  const serverResponse = await handleRequest(url, getRequestOptions({ body, method:"PUT" }));
  const response =  await handleResponse(serverResponse);
  return response;
}

async function getOrganisationDetails(name){
  const url = `${apiUrl}/identity/orgunits/${name}/withcollections`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  const response =  await handleResponse(serverResponse);
  return response;
}


async function getProducts(name){
  const url = `${quotationUrl}/products/insurer?orgUnit=${name}`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  const res =  await handleResponse(serverResponse);
  return res
}

async function applyFilter({stringParams}){
  const url = `${apiUrl}/identity/orgunits/search?${stringParams}`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  const res = await handleResponse(serverResponse);
  return res;
}

async function getOrganisationsChart(){
  const url = `${apiUrl}/identity/orgunits/current-node`;
  const response = await handleRequest(url, getRequestOptions());
  const res = await handleResponse(response);
  return res;
}

async function hasPermission (scope) {
  const permission = await checkPermission(scope);
  let isPermitted = true;
  if(!permission.error) {
    if(permission.status !== "permitted") {
      isPermitted = false;
    }
    return isPermitted;
  }
}