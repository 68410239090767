import React from "react";
import { connect } from "react-redux";
import {
  TitleComponent,
  FlashMessage,
  Filterable,
} from "_components";
import { Loader } from '@xc-core/components/core';
import { hasPermissionTo } from "_actions";
import { Button } from 'reactstrap';
import { resActions } from "./actions";
import { resServices } from './services';
import "react-orgchart/index.css";
import getResourceGraphic from "../../images/bannerGraphicImages/get-quote.png";
import { mediaLib } from '@xc-core/lib';

class ResourceModule extends Filterable {
  constructor(props) {
    super(props);
    this.state = {
      isGettingList: false,
      canManageResource: false,
      resources: []
    };
  }

  async componentWillMount() {
    this.setState({isGettingList: true});
    this.setState({resources: await this.props.getList()});
    this.setState({isGettingList: false});

    const canManageResource = await this.props.hasPermissionTo("Manage Resource Center");
    this.setState({canManageResource});
  }

  createGroup = async () => {
    const { history } = this.props;
    history.push(`/resourceCenter/manage`);
  };

  handleDownloadResource = async (resourceId, index) => {
    const { resources } = this.state;
    const blob = await resServices.downloadResource(resourceId);

    const fileName = resources[index].fileName.split('/');
    mediaLib.downloadFile(fileName[fileName.length - 1], blob, false);
  };

  render() {
    const { isGettingList, canManageResource, resources } = this.state;
    const { canViewList } = this.props;
    if (!canViewList) return (<FlashMessage flashMessage="you are not allowed to view Resources."/>);

    return (
      <div className="container-fluid px-0">
        <TitleComponent
          title="Resource Center"
          bannerGraphic={getResourceGraphic}
        />
        {isGettingList && <Loader /> }
        <div className="d-flex flex-column m-5">
          { (!isGettingList && resources.length === 0) && <div className="download-card">
              <span>No Documents Available</span>
            </div> }
          {resources.length > 0 && resources.map((item, index) => {
            return <div className="download-card mb-3" onClick={() => this.handleDownloadResource(item.resourceId, index)}>
              <div>
                <i className="icon-file file-type-icon" />
                <span>{item.title}</span>
              </div>
              <i className="icon-down-arrow-line" />
            </div>
          })}
        </div>
        { canManageResource &&
        <div className="d-flex justify-content-end m-5">
          <Button
            className={`btn`}
            color='primary'
            onClick={() => this.createGroup()}
          >
            Manage Documents
          </Button>
        </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    orgList,
    canViewList,
    collections,
    notification,
  } = state.organisations;
  const { loading } = state;
  return {
    orgList,
    canViewList,
    collections,
    notification,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getList: () => dispatch(resActions.getList()),
    hasPermissionTo: (scope) => dispatch(hasPermissionTo(scope, false)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceModule);
