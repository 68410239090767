import React from "react";
import "react-datepicker/dist/react-datepicker.css";

import {
  Form,
  FormGroup,
  Label,
} from "reactstrap";

import Field from '@containers/getQuote/wizard/field';
import {CommonInputTypes} from "../../../../_components/CommonInputTypes"

class PaymentsPage extends React.Component {
  componentDidMount() {
    this.props.onRef(this);
  }
  getAlert() {
    return true
  }
  handleInput = e => {
    const { name: currentUser} = JSON.parse(sessionStorage.getItem('user'));
    const { curentThis } = this.props;
    // check if the "received by" field is empty to default it to current user
    if (!curentThis.state.properties["Payment Received By"]) {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          properties: {
            ...curentThis.state.properties,
            "Payment Received By": currentUser,
          }
        });
      });
    }
    curentThis.handleInputChange(e);
  };
  render() {
    const { collections } = this.props.curentThis.props;
    const { curentThis } = this.props;
    return (
      <div className="row my_50 justify-content-center">
        <div className="col-12 col-md-10">
          <label className="sub-title">Fill in payment details (optional)</label>
          <Form>
            <FormGroup>
              <CommonInputTypes
                idName="Payment Mode"
                group={false}
                type="select"
                option={
                  collections !== undefined ? collections.paymentMethod : []
                }
                value={curentThis.state.properties["Payment Mode"]}
                onChange={(e) => this.handleInput(e)}
                title="Mode of Payment"
                id="1"
                validator=""
                autoFocus = {true}
              />
              {/*<CommonInputTypes*/}
              {/*  idName="Amount Paid"*/}
              {/*  group={false}*/}
              {/*  type="input"*/}
              {/*  value={curentThis.state.properties["Amount Paid"]}*/}
              {/*  onChange={curentThis.handleInputChange}*/}
              {/*  title="Amount Paid (IDR)"*/}
              {/*  id="2"*/}
              {/*  validator=""*/}
              {/*/>*/}
              <CommonInputTypes
                idName="Receipt No"
                group={false}
                type="input"
                value={curentThis.state.properties["Receipt No"]}
                onChange={curentThis.handleInputChange}
                title="Receipt No"
                id="3"
                validator=""
              />
              <CommonInputTypes
                idName="Payment Received By"
                group={false}
                type="select"
                option={
                  collections !== undefined ? collections.currentOuUsers : []
                }
                value={curentThis.state.properties["Payment Received By"] || ""}
                onChange={curentThis.handleInputChange}
                title="Payment Received By"
                id="4"
                validator=""
              />
              <div className='additional-tag-styles'>
                <p>Other Emails to Receive Policy</p>
                <FormGroup>
                  <Label for='cc'>CC:</Label>
                  <Field
                    model={{
                      id: 'CC Email',
                      type: 'MultiValueInput',
                      handler: (pair) => ({ target: pair }),
                      style: { col: '6' },
                      label: 'cc',
                    }}
                    item={curentThis.state.properties}
                    onChange={curentThis.handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='bcc'>BCC:</Label>
                  <Field
                    model={{
                      id: 'BCC Email',
                      type: 'MultiValueInput',
                      handler: (pair) => ({ target: pair }),
                      style: { col: '6' },
                      label: 'bcc',
                    }}
                    item={curentThis.state.properties}
                    onChange={curentThis.handleInputChange}
                  />
                </FormGroup>
              </div>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default PaymentsPage;
