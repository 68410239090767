import numeral from 'numeral';

/**
 * apply format using numeral module
 * @param {string, number} value - number to format
 * @return {string} - formatted number
 */
const applyFormat = (value: string | number, format: string): string => {
  if (value === '') return '';
  const num = numeral(value);
  return num.format(format);
};

/**
 * compare to string values as number
 * @param {string} first
 * @param {string} second
 * @returns {number} returns 0 when both are equal,
 * returns 1 when first > second, returns -1 when first < second
 */
const compare = (first: string, second: string): number => {
  const firstFloat = parseFloat(String(first).split(",").join(""));
  const secondFloat = parseFloat(String(second).split(",").join(""));
  
  if (Number.isNaN(firstFloat) || Number.isNaN(secondFloat)) {
    // throw Error('number.compare, values are not valid numbers');
  }
  if (firstFloat === secondFloat) return 0;
  if (firstFloat > secondFloat) return 1;
  return -1;
};

const toInt = (value: string): number => {
  const int = parseInt(String(value).split(",").join(""), 10);
  return int;
};

const isEven = (value: string | number) => (Number(value) % 2) === 0;

const number = {
  applyFormat,
  compare,
  toInt,
  isEven,
};

export default number;
