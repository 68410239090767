import React from "react";
import PropTypes from "prop-types";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class ExportButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    return (
      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle.bind(this)}>
        <DropdownToggle caret color={'primary'}>
          Export To CSV
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => this.props.exportToCsv(true)}>All Fields</DropdownItem>
          <DropdownItem onClick={() => this.props.exportToCsv(false)}>Non-PII</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

ExportButton.propTypes = {
  exportToCsv: PropTypes.func,
};

export default ExportButton;
