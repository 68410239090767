import React from "react";
import styled from "styled-components";

const CustomDiv = styled.div`
  color: #333333;
`;
export default ({version}) => (
  <CustomDiv>
    Version {version}
  </CustomDiv>
);