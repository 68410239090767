import React from 'react';
import { store } from '_helpers';
import { SHOW_FORBIDDEN_MESSAGE } from '_constants';
import PropType from 'prop-types';


class BasePolicyDetail extends React.PureComponent {
  reissueable = (policy) => {
    if (!policy) return false;
    return ['not in effect', 'in effect'].includes(String(policy.status).toLowerCase())
      && ['new', 'endorsement'].includes(String(policy.policyState).toLowerCase());
  }

  cancelable = (policy, additionalStatuses = [], additionalStates = []) => {
    if (!policy) return false;

    const statuses = ['not in effect', 'in effect'].concat(additionalStatuses.map((s) => s.toLowerCase()));
    const states = ['new', 'endorsement'].concat(additionalStates.map((s) => s.toLowerCase()));

    return statuses.includes(String(policy.status).toLowerCase())
      && states.includes(String(policy.policyState).toLowerCase());
  }

  downloadable = (policy) => {
    if (!policy) return false;
    return (['cancelled'].includes(String(policy.status).toLowerCase())
      && ['cancellation'].includes(String(policy.policyState).toLowerCase())) === false;
  }

  endorseable = (policy, additionalStatuses = [], additionalStates = []) => {
    if (!policy) return false;

    const statuses = ['not in effect', 'in effect'].concat(additionalStatuses.map((s) => s.toLowerCase()));
    const states = ['new', 'endorsement'].concat(additionalStates.map((s) => s.toLowerCase()));

    return statuses.includes(String(policy.status).toLowerCase())
      && states.includes(String(policy.policyState).toLowerCase());
  }

  handleBackToListing = () => {
    const { history } = this.props;
    history.push(`/policies`);
  }

  toggleEndorseModal = () => {
    const { endorseModalIsOpen } = this.state;
    if (endorseModalIsOpen === false) {
      if (this.validator.allValid()) {
        this.setState((prevState) => ({
          ...prevState,
          endorseModalIsOpen: !endorseModalIsOpen,
          endorseRemarkIsValid: true,
        }));
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        endorseModalIsOpen: !endorseModalIsOpen,
        endorseRemarkIsValid: true,
      }));
    }
  }

  toggleCancelModal = async () => {
    const {
      cancelNotInEffectPermission, cancelInEffectPermission, policy,
    } = this.props;
    let isPermitted;
    if (policy.status === 'In Effect') {
      isPermitted = await cancelInEffectPermission();
      if (!isPermitted) {
        return store.dispatch({
          type: SHOW_FORBIDDEN_MESSAGE,
          payload: 'You are not allowed to cancel In Effect certificates.',
        });
      }
    } else {
      isPermitted = await cancelNotInEffectPermission();
      if (!isPermitted) {
        return store.dispatch({
          type: SHOW_FORBIDDEN_MESSAGE,
          payload: 'You are not allowed to cancel Not In Effect certificates.',
        });
      }
    }
    const { cancelModalIsOpen } = this.state;
    return this.setState((prevState) => ({ ...prevState, cancelModalIsOpen: !cancelModalIsOpen }));
  }
}

BasePolicyDetail.propTypes = {
  policy: PropType.object.isRequired,
  cancelNotInEffectPermission: PropType.func.isRequired,
  cancelInEffectPermission: PropType.func.isRequired,
  history: PropType.object.isRequired,
};

export default BasePolicyDetail;
