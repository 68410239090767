import React from 'react';
import { connect } from 'react-redux';
import { dateLib } from '@xc-core/lib';
import {
  Col, Button, FormGroup, Label, Row,
} from 'reactstrap';
import config from '@xc-core/config';
import { DatePicker, Header } from '@xc-core/components/core';
import { ModalUi } from '_components';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import { actions } from '@redux';

const dataDumpActions = actions.DataDumpActions;


class DataDump extends React.Component<any, any> {
  constructor(props: Readonly<IObject>) {
    super(props);
    this.state = {
      dateValue: dateLib.getCurrentDate(config.dateMomentFormat),
      modal: false,
    };
  }

  handleSubmit = () => this.toggle();

  toggle = () => this.setState((prevState: IObject) => {
    const { modal } = prevState;
    return { modal: !modal };
  });

  handleGenerateFiles = () => {
    const { generateFiles } = this.props;
    const { dateValue } = this.state;
    generateFiles(dateValue);
    this.toggle();
  }

  render() {
    const { dateValue, modal } = this.state;
    return (
      <div data-testid="containter">
        <Header
          title='Ad Hoc Sales File Generation'
          bannerGraphic={bannerImg}
          withBorderBottom
        />
        <div data-testid="containter-inner" className='container-fluid px-0'>
          <div className='row d-flex justify-content-center my_30'>
            <Col sm={12}>
              <div className='' style={{ padding: '3%' }}>
                <p className='font-weight-bold'>
                  This function will trigger the Sales file generation and
                  send it to GBSN's SFTP folder. it will not download the File to your machine.
                </p>
                <p className='font-weight-bold'>
                  Pleas select a date.
                </p>
                <Row className='mt-4 mb-4'>
                  <Col sm={12} md={3}>
                    <FormGroup data-testid="datepicker-area">
                      <Label>Created at Date</Label>
                      <DatePicker
                        start=''
                        end=''
                        handler={({ value }) => this.setState({ dateValue: value })}
                        id='date'
                        max=''
                        min=''
                        name='date'
                        openTo={dateValue}
                        value={dateValue}
                        lefIcon
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button data-testid="generate-btn" onClick={this.handleSubmit}>Generate Sales File</Button>
              </div>
            </Col>
          </div>
        </div>
        <ModalUi title='Confirmation' isOpen={modal} toggle={this.toggle}>
          <p className='mt-4 mb-4'>Are you sure you want to generate the Sales File?</p>
          <div className='row justify-content-end' id='modalButton'>
            <div className='col-2'>
              <Button
                onClick={this.toggle}
                className='text-info'
                color='link'
                data-testid="modal-cancel"
              >
                Cancel
              </Button>
            </div>
            <div className='col-3'>
              <Button
                onClick={this.handleGenerateFiles}
                color='primary'
                className='fullWidth rounded-xl'
                data-testid="modal-confirm"
                // disabled={this.state.disableSubmit}
              >
                Confirm
              </Button>
            </div>
          </div>
        </ModalUi>
      </div>
    );
  }
}

// const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  generateFiles: (date: string) => dispatch(dataDumpActions.generateFiles(date)),
});

export default connect(null, mapDispatchToProps)(DataDump);
