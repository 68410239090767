import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Styled from 'styled-components';
import * as routes from '@xc-core/routes';
import { Col, Row } from 'reactstrap';
import getQuoteGraphic from '../../images/bannerGraphicImages/get-quote.png';
import { Loader, TitleComponent } from '../../_components';
import { actions } from '../../@redux';
import { dateLib } from '../../@xc-core/lib';

const auditLogActions = actions.AuditLogActions;

interface Interface extends IObject{
  list: IObject[],
  currentLeadItem: IObject,
}

const PreElement = Styled.pre`
  background-color: #f4f4f4;
  padding: 10px;
  max-height: 200px;
`;
const AuditLogDetails = (props: IObject) => {
  const {
    currentItem, history, match: { params: { id } },
  } = props;
  useEffect(() => {
    if (!currentItem) {
      props.getDetails(id);
    }
  }, []);
  const RenderJson = ({ data = '' } : IObject) => {
    try {
      return (
        <PreElement>
          {JSON.stringify(JSON.parse(data), null, 4)}
        </PreElement>
      );
    } catch (e) {
      return (
        <PreElement>
          {data}
        </PreElement>
      );
    }
  };
  if (!currentItem) return <Loader />;
  return (
    <div>
      <TitleComponent
        title='Audit Log Details'
        bannerGraphic={getQuoteGraphic}
        backTitle='Back to listing'
        backPath={`/${routes.audit.base}`}
        history={history}
        noBorderBottom={false}
      />
      <div className='container-fluid px-0 normal-gray-text'>
        <Row className='d-flex justify-content-center my_30'>
          <Col sm={12} className='col-sm-12' style={{ padding: '0 4%' }}>
            <Row className='wordBreak'>
              <Col>
                <div className='custom-q-text-primary bold my-2'>User Information</div>
                <Row>
                  <Col sm={3}><p>Name: </p></Col>
                  <Col><p>{ currentItem.userFullName }</p></Col>
                </Row>
                <Row>
                  <Col sm={3}><p>Username: </p></Col>
                  <Col><p>{ currentItem.userName }</p></Col>
                </Row>
                <Row>
                  <Col sm={3}><p>IP Address: </p></Col>
                  <Col><p>{ currentItem.sourceIP }</p></Col>
                </Row>
              </Col>
            </Row>
            <Row className='wordBreak'>
              <Col>
                <div className='custom-q-text-primary bold my-2'>Activity Information</div>
                <Row>
                  <Col sm={3}><p>Service: </p></Col>
                  <Col><p>{ currentItem.serviceNameFull }</p></Col>
                </Row>
                <Row>
                  <Col sm={3}><p>Action: </p></Col>
                  <Col><p>{ currentItem.userAction }</p></Col>
                </Row>
                <Row>
                  <Col sm={3}><p>Created At: </p></Col>
                  <Col><p>{ dateLib.asDateTime(currentItem.logTime) !== 'Invalid date' ? dateLib.asDateTime(currentItem.logTime) : currentItem.logTime }</p></Col>
                </Row>
                <Row>
                  <Col sm={3}><p>Start Time: </p></Col>
                  <Col><p>{ dateLib.asDateTime(currentItem.activityStartTime) }</p></Col>
                </Row>
                <Row>
                  <Col sm={3}><p>End Time: </p></Col>
                  <Col><p>{ dateLib.asDateTime(currentItem.activityEndTime) }</p></Col>
                </Row>
                <Row>
                  <Col sm={3}><p>Duration: </p></Col>
                  <Col>
                    <p>
                      { dateLib.getDurationAsMilliseconds(currentItem.activityDuration) || 1 } ms
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}><p>Sent Data: </p></Col>
                  <Col sm={9}><RenderJson data={currentItem.inputJsonObject} /></Col>
                </Row>
                <Row>
                  <Col sm={3}><p>Response Data: </p></Col>
                  <Col sm={9}><RenderJson data={currentItem.outputJsonObject} /></Col>
                </Row>
              </Col>
            </Row>
            <Row className='wordBreak'>
              <Col>
                <div className='custom-q-text-primary bold my-2'>Error Information</div>
                <Row>
                  <Col className='d-flex mb-2'>
                    <span dangerouslySetInnerHTML={{ __html: currentItem.icon }} />
                    <span className='ml-1'>{ currentItem.actionResult }</span>
                  </Col>
                </Row>
                {currentItem.actionResult === 'FAIL' && (
                  <>
                    <Row>
                      <Col sm={3}><p>Error: </p></Col>
                      <Col><p>{ currentItem.logLevel }</p></Col>
                    </Row>
                    <Row>
                      <Col sm={3}><p>Message: </p></Col>
                      <Col><p>{ currentItem.message }</p></Col>
                    </Row>
                    <Row>
                      <Col sm={3}><p>Stack Trace: </p></Col>
                      <Col sm={9}><RenderJson data={currentItem.errorSummary} /></Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auditLog }: IStore) => ({
  currentItem: auditLog.currentItem,
  list: auditLog.list,
});
const mapDispatchToProps = (dispatch: any) => ({
  setList: (params: IRequestParam) => dispatch(auditLogActions.setList(params)),
  getDetails: (id: string) => dispatch(auditLogActions.get(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AuditLogDetails);
