import actionTypes from '@redux/actionTypes';
import BaseReducer from './base';

class ModalMessageReducer extends BaseReducer<IModalMessageState> {
  public actions: IDictionary<(state: IModalMessageState,
    action: IAction) => IModalMessageState>;

  public initialState: IModalMessageState;

  protected actionTypes: IModalMessageActionTypes;

  constructor() {
    super();

    this.actionTypes = actionTypes.modalMessage;

    this.initialState = {
      title: '',
      message: '',
      isVisible: false,
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IModalMessageState,
    action: IAction) => IModalMessageState> => ({
    [this.actionTypes.SHOW]: this.show,
    [this.actionTypes.HIDE]: this.hide,
  })

  public show = (state:IModalMessageState, action: IAction):IModalMessageState => ({
    ...state,
    title: action.payload.title,
    message: action.payload.message,
    isVisible: true,
  })

  public hide = (state:IModalMessageState):IModalMessageState => ({
    ...state,
    title: '',
    message: '',
    isVisible: false,
  })
}

export default ModalMessageReducer;
