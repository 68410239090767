import React from 'react';
import Chip from 'react-toolbox/lib/chip';
import { filterLib } from '@xc-core/lib';

const RenderChipValue = (props: { filter: any}) => {
  const { filter, filter: { checkedCount } } = props;

  if (filter.type === filterLib.dateRangeType) {
    const startDate = filter.list.find((value: any) => value.code === 'startDate').value;
    const endDate = filter.list.find((value: any) => value.code === 'endDate').value;
    // return <span>{`${dateLib.applyFormat(startDate, 'DD/MMM/YYYY')}-${dateLib.applyFormat(endDate, 'DD/MMM/YYYY')}`}</span>;
    return <span>{`${startDate}-${endDate}`}</span>;
  }

  const firstCheckedName = filter.list.find((listItem: any) => (listItem.isChecked)).name;
  return checkedCount <= 1
    ? <span>{firstCheckedName}</span> : <span>{ `${firstCheckedName} .... (${checkedCount - 1} more)`}</span>;
};

const SelectedFiltersChip = (props: {filters: any, removeChip: Function}) => {
  const { filters, removeChip } = props;
  return (
    filters && filters.map((filter: any) => (
      <div key={filter.id} style={{ marginBottom: '-5px' }}>
        {filter.checkedCount >= 1 && (
          <Chip
            key={filter.id + 1}
            theme={{
              deleteIcon: 'deleteBg',
              deleteX: 'deleteIcon',
              chip: 'chipBg',
            }}
            onDeleteClick={() => removeChip(filter.id)}
            deletable
            className='m-1'
          >
            <span className='text-primary font-weight-normal'>
              {`${filter.displayName} =`}
              <RenderChipValue filter={filter} />
            </span>
          </Chip>
        )}
      </div>
    ))
  );
};

export default SelectedFiltersChip;
