import Notification from 'react-notification-system-redux';
import BaseAction from './base';

class NotificationAction extends BaseAction {
  protected actions = {};

  protected service = {};

  protected createScope = '';

  protected editScope = '';

  public info = (id: string, title: string, message?: string) => (
    Notification.info(this.getOptions(id, title, message))
  );

  public success = (id: string, title: string, message?: string) => (
    Notification.success(this.getOptions(id, title, message))
  );

  public error = (id: string, title: string, message?: string) => (
    Notification.error(this.getOptions(id, title, message))
  );

  public hide = (id: string) => (Notification.hide(id));

  public removeAll = () => (Notification.removeAll());

  private getOptions = (id: string, title: string, message?: string): any => ({
    uid: id,
    title,
    message,
    position: 'tr',
    autoDismiss: 2,
  });
}

export default NotificationAction;
