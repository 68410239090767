import actionTypes from '@redux/actionTypes';
import BaseReducer from './base';
import { dateLib } from '../../@xc-core/lib';

class FiscalYearReducer extends BaseReducer<any> {
    public initialState: IObject;

    public actions: IDictionary<(state: IListState, action: IAction) => object>;

    protected actionTypes: IObject;

    constructor() {
      super();
      this.actionTypes = actionTypes.fiscalYear;
      this.initialState = {
        isLoading: true,
        isEditMode: false,
        editIndex: null,
        editItem: {
          first: '',
          second: '',
          startDate: '',
          endDate: '',
        },
        records: [
          {},
          // {
          //   editing: true,
          //   id: 1,
          //   description: null,
          //   fiscalYear: '19/20',
          //   startDate: '01/08/2019',
          //   endDate: '31/07/2020',
          // },
          // {
          //   editing: true,
          //   id: 2,
          //   description: null,
          //   fiscalYear: '19/20',
          //   startDate: '01/08/2019',
          //   endDate: '31/07/2020',
          // },
        ],
      };
      this.actions = this.createActions();
    }

    createActions = (): IDictionary<(state: IListState, action: IAction) => IListState> => ({
      [this.actionTypes.TOGGLE_EDIT]: this.toggleEdit,
      [this.actionTypes.HANDLE_CHANGE]: this.handleChange,
      [this.actionTypes.ADD_FISCAL_YEAR]: this.addFiscalYear,
      [this.actionTypes.GET_LIST]: this.getList,
      [this.actionTypes.SAVE_RECORD]: this.saveRecord,
      [this.actionTypes.RESET_FISCAL_YEAR_DETAILS]: this.resetFiscalYearDetails
    })

      private handleChange = (state: IListState, action: IAction) => {
        const { id, value } = action.payload.pair;
        return ({
          ...state,
          editItem: { ...state.editItem, [id]: value },
        });
      };

      private saveRecord = (state: IListState, action: IAction) => {
        let records = [
          ...state.records.slice(0, state.editIndex),
          action.payload.data,
          ...state.records.slice(state.editIndex + 1),
        ];
        
        records.sort((a:IObject,b:IObject)=>{
          return a.fiscalYear > b.fiscalYear ? 1 : -1;
        })
        return ({
          ...state,
          isEditMode: false,
          records: records,
          editItem: {
            first: '',
            second: '',
            startDate: '',
            endDate: '',
          },
        });
      };

      private getList = (state: IListState, action: IAction) => {
        let records = action.payload;
        records.sort((a:IObject,b:IObject)=>{
          return a.fiscalYear > b.fiscalYear ? 1 : -1;
        })
        return ({
          ...state,
          records: action.payload,
        });
      };

      private addFiscalYear = (state: IListState) => {
        const currentYear = dateLib.getCurrentDate(dateLib.config.dateMomentFormat).split('/')[2];
        const lastRecord = state.records[state.records.length - 1];
        let records = [...state.records];
        let newItem = {};
        newItem = {
          editing: true,
          id: undefined,
          description: null,
          fiscalYear: `${currentYear.substring(2)}/${currentYear.substring(2)}`,
          startDate: dateLib.getCurrentDate(dateLib.config.dateMomentFormat),
          endDate: dateLib.getCurrentDate(dateLib.config.dateMomentFormat),
        };
        if (lastRecord && lastRecord.id) {
          records = [...records, newItem];
          return ({
            ...state,
            records,
            isEditMode: true,
            editIndex: state.records.length,
          });
        } else if (records.length === 0){
          records = [newItem];
          return ({
            ...state,
            records,
            isEditMode: true,
            editIndex: state.records.length,
          });
        }
        return { ...state };
      };

      private toggleEdit = (state: IListState, action: IAction) => {
        const lastRecord = state.records[state.records.length - 1];
        let records = [...state.records];
        if (lastRecord && !lastRecord.id) {
          records = [...records.slice(0, records.length - 1)];
        }
        return ({
          ...state,
          records,
          editIndex: action.payload.index,
          isEditMode: !state.isEditMode,
        });
      };

      private resetFiscalYearDetails = (state:IListState, action: IAction) => {
        return ({
          ...state,
          editIndex: null,
          isEditMode: false,
        });
      }
}

export default new FiscalYearReducer();
