
const products = {
	cashForHospitalization: {
		steps: [
			"Basic Details",
			"Plan Selection",
			"Customer Details",
			"Beneficiary Details",
			"Summary",
			"Payment Details",
			"Completed",
		],
	},
	walletInsurance: {
		steps: [
			"Plan Selection",
			"Customer Details",
			"Beneficiary Details",
			"Summary",
			"Payment Details",
			"Completed",
		],
	},
	travelInsurance: {
		steps: [
			"Trip Details",
			"Select Plan",
			"Insured Details",
			"Beneficiaries",
			"Summary",
			"Payment Details",
			"Completed",
		]
		// getSteps: (productsLength) => (productsLength > 1
		// 	? [
		// 		"Plan Selection",
		// 		"Trip Details",
		// 		"Select Plan",
		// 		"Insured Details",
		// 		"Beneficiaries",
		// 		"Summary",
		// 		"Payment Details",
		// 		"Completed",
		// 	]
		// 	: [
		// 		"Trip Details",
		// 		"Select Plan",
		// 		"Insured Details",
		// 		"Beneficiaries",
		// 		"Summary",
		// 		"Payment Details",
		// 		"Completed",
		// 	]
		// ),
	}
};

export default products;
