import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import {SidebarCard} from "../../../@xc-core/components";

const InsuredPropertyDetails = (props: ILoadableComponentProps) => {
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            productIcon,
            sidebar,
            isKhmer
        },
    } = props;
    const [autoFilled, setAutoFilled] = useState<'Yes' | 'No'>('No')

    const onChangeHandler = (pair: IPair) => {
        let updatedPairs: IPair[] = [];
        if (pair.id === 'properties.autoFilledValue') {
            setAutoFilled(pair.value)
            updatedPairs = [...updatedPairs, ...['Address', 'Country', 'State', 'Postcode', 'City'].reduce((prevVal: IPair[], currentVal: string) => {
                return [
                    ...prevVal,
                    {
                        id: `properties.Insured Property.${currentVal}`,
                        value: pair.value ? item.properties['main applicant'][currentVal] : ""
                    }
                ]
            }, [])
            ]
        }
        onChange('onChange', {
            pairs: [
                ...updatedPairs
            ]
        })
    }

    return (
        <Row>
            <Col md='8'>
                <h5 className='sub-title'>{model.title}</h5>
                <RenderFields
                    onChange={(pairs: IPair[]) => onChangeHandler(pairs[0])}
                    collections={collections}
                    item={{
                        properties: {
                            autoFilledValue: autoFilled
                        }
                    }}
                    validationErrors={validationErrors}
                    fields={model.autoFilledCheckbox.fields}
                />
                <RenderFields
                    onChange={(pairs: IPair[]) => {
                        onChange('onChange', {pairs})
                    }}
                    collections={collections}
                    item={item}
                    validationErrors={validationErrors}
                    fields={model.insuredPropertyFields.fields}
                />
            </Col>
            <Col md='4'>
                <SidebarCard.Quotation
                    customIconProduct={productIcon}
                    item={item}
                    model={sidebar}
                    ext={isKhmer}
                />
            </Col>
        </Row>
    )
}

export default InsuredPropertyDetails;