import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
} from 'reactstrap';
import CircularButton from '../CircularButton';
import { formatCurrency, config, utils } from '../../utils';


const RenderBlock = (props) => {
  const {
    properties,
    status,
    model,
  } = props;

  return (
    <div key={model.id} className='item'>
      <span className='plan-title d-block mb-3 mt-2'>{model.name}</span>
      {
        model.items.map((item) => {
          return (
            <RenderBlockItem
              key={item.current.id}
              current={item.current}
              prev={item.prev}
              item={properties}
              isCanceled={String(status).toLowerCase() === 'cancelled'}
            />
          );
        })
      }
    </div>
  )
}

const RenderBlockItem = (props) => {
  // const { current, prev, item, isCanceled } = props;
  // let value = utils.lib.resolveObjKey(item, current.id);
  // let prevValue = utils.lib.resolveObjKey(item, prev.id);

  const { current, prev, properties } = props;
  let value = utils.lib.resolveObjKey(properties, current.id);
  let prevValue = utils.lib.resolveObjKey(properties, prev.id);
  let formatedValue = value;
  let formatedPrev = prevValue;

  if (current.config.currency) {
    value = parseFloat(value || 0, 10);
    formatedValue = value === 0 ? 0 : formatCurrency(value);
  }


  if (prev.config.currency && prevValue !== null && prevValue !== undefined) {
    prevValue = parseFloat(prevValue || 0, 10);
    formatedPrev = prevValue === 0 ? 0 : formatCurrency(prevValue);
  }

  return (
    <Row className='pt-1 pb-3' key={current.id}>
      <Col
        sm={6}
        className={current.config.className}
      >
        {current.name}
      </Col>
      <Col
        sm={6}
        className={`text-right summary-label-3 ${current.config.className}`}
      >
        {
          prevValue !== null && prevValue !== undefined && prevValue !== value ? (
            <span className={`mr-1 ${prev.config.className}`}>
              {prev.config.currency ? `${config.currencySymbol} ` : ''}
              {formatedPrev}
            </span>
          ) : <></>
        }
        <span>
          {current.config.currency ? `${config.currencySymbol} ` : ''}
          {formatedValue}
        </span>
        { (prev.config.currency && utils.lib.isGreaterThan(prevValue, value)) ? <i className='icon-down-arrow-line text-danger small' /> : <></>}
        { (prev.config.currency && utils.lib.isGreaterThan(value, prevValue)) ? <i className='icon-up-arrow-line text-success small' /> : <></>}
      </Col>
    </Row>
  );
}
export default class PolicySidebarCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      submiting: false,
    }
  }

  componentWillReceiveProps(newProps) {
    const { editMode, submiting } = this.state;
    if (editMode && newProps.notification && submiting) {
      this.setState({editMode: false, submiting: false});
    }
  }

  toggleEditMode = () => {
    this.props.validator.hideMessages();
    this.props.resetPaymentDetailsForm();
    this.setState({editMode: !this.state.editMode, submiting: false});
  }

  handleSubmit = (e) => {
    const {validator} = this.props;
    this.setState({submiting: true});
    if (validator.allValid()) {
      this.props.handleSubmit(e);
    } else validator.showMessages();
  };

  showItem = (isPolicy, onlyPolicy) => ((isPolicy === true)
    || (isPolicy !== true && onlyPolicy !== true));

  render() {
    const { data, collections, validator, model, status, isPolicy, item} = this.props;
    const { editMode } = this.state;

    let prevTotal = utils.lib.resolveObjKey(item, model.header.items[0].prev.id);
    let total = utils.lib.resolveObjKey(item, model.header.items[0].current.id);
    let formatedPrev = prevTotal;
    let formatedTotal = total;

    total = parseFloat(total || 0, 10);
    formatedTotal = total === 0 ? 0 : formatCurrency(total);

    if (prevTotal !== null && prevTotal !== undefined) {
      prevTotal = parseFloat(prevTotal || 0, 10);
      formatedPrev = prevTotal === 0 ? 0 : formatCurrency(prevTotal);
    }

    return (
      <div className='sidebarCard'>
        <Card style={{ borderRadius: '10px' }} className='shadow-md border-0'>
          <CardBody className='px-3'>
            <div className='pt-2 text-primary pr-3'>
              <Row>
                <Col sm={10} className='text-secondary mt-2'>
                  {`${utils.lib.resolveObjKey(item, model.header.productName.id)} - ${utils.lib.resolveObjKey(item, model.header.plan.id)}`}
                </Col>
                <Col sm={2} className='text-right pr-0 mt-2 customCircularbutton'>
                  <CircularButton
                    press={() => {}}
                    iconName='planeProduct'
                  />
                </Col>
              </Row>
            </div>
            <div className='pt-3'>
              <span className='plan-title'>
                Total Price
              </span>
              <h2 className='py-2'>
                {
                 prevTotal !== null && total !== prevTotal
                   ? (
                     <span className='cross-line gray bold total-pre mr-1'>
                       {`${config.currencySymbol} ${formatedPrev}`}
                     </span>
                   )
                   : <></>
                }
                <span className='total-current'>{`${config.currencySymbol} ${formatedTotal}`}</span>
                { utils.lib.isGreaterThan(prevTotal, total) ? <i className='icon-down-arrow-line text-danger' /> : <></>}
                { utils.lib.isGreaterThan(total, prevTotal) ? <i className='icon-up-arrow-line text-success' /> : <></>}
              </h2>
            </div>
            {
              model.body.items.map((model) => {
                return (
                  <RenderBlock
                    key={model.id}
                    model={model}
                    properties={item}
                    status={status}
                  />
                );
              })
            }

          </CardBody>
        </Card>

        {/** payment detail- for policy */}
        <div className={isPolicy ? '' : 'd-none'}>
          <Card className='mt-4 shadow-md border-0' style={{ borderRadius: '10px' }}>
            <CardBody className='px-3'>
              <div className='pt-2 text-primary'>
                <Row>
                  <Col sm={9} className='payment-title-qc font-size-normal mt-2 mb-2'>
                    Payment Details
                  </Col>
                  <Col sm={3} className={editMode ? 'd-none' : 'text-right mt-2 mb-2' }>
                    <CircularButton
                      press={this.toggleEditMode.bind(this)}
                      iconName={'pencil'}
                    />
                  </Col>
                </Row>
              </div>
              <hr className='my-2' />
              <div className='pt-2'>
                <Row>
                  <Col sm={6}>Mode of Payment</Col>
                  <Col className='text-right summary-label-3' sm={6}>
                    { editMode ? (

                      <Input
                        type='select'
                        id='Payment Mode'
                        name='Payment Mode'
                        value={data['Payment Mode']}
                        onChange={this.props.handleInputChange}
                      >
                        <option disabled value=''>Select Option</option>
                        {
                          collections.paymentMethod.map(pm => {
                            return (<option key={pm.id}>{pm.code}</option>);
                          })
                        }
                      </Input>
                    ) : null }
                    { editMode ? null : (data ? data['Payment Mode'] : '-') }
                    {editMode && validator.message('Mode of Payment', data['Payment Mode'], 'required')}
                  </Col>
                  <Col className='pt-3' sm={6}>Receipt No.</Col>
                  <Col className='text-right pt-3 summary-label-3' sm={6}>
                    { editMode ? (
                      <Input
                        id='Receipt No'
                        name='Receipt No'
                        value={data['Receipt No']}
                        onChange={this.props.handleInputChange}
                      />
                    ) : null }
                    { editMode ? null : (data ? data['Receipt No'] : '-') }
                    {editMode && validator.message('Receipt No', data['Receipt No'], 'required')}
                  </Col>

                  <Col className='py-3' sm={6}>Payment Received By</Col>
                  <Col className='text-right py-3 summary-label-3' sm={6}>
                    { editMode ? (
                      <Input
                        type='select'
                        id='Payment Received By'
                        name='Payment Received By'
                        value={data['Payment Received By']}
                        onChange={this.props.handleInputChange}
                      >
                        <option disabled value=''>Select Option</option>
                        {
                          collections.currentOuUsers.map(pm => {
                            return ( <option key={pm.id}>{pm.name}</option>);
                          })
                        }
                      </Input>
                    ) : null }
                    { editMode ? null : (data ? data['Payment Received By'] : '-') }
                    {editMode && validator.message('Payment Received By', data['Payment Received By'], 'required')}
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <div className={editMode ? 'float-right' : 'd-none'}>
            <Button
              color='info'
              className='my-4 customInfobtn mr-2'
              onClick={this.toggleEditMode.bind(this)}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              className='my-4'
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

PolicySidebarCard.defaultProps = {
  handleInputChange: () => alert('undefined'),
  handleSubmit: () => alert('undefined'),
  collections: {},
  notification: null,
  isPolicy: false,
};
