/* eslint-disable object-curly-newline */

import lib from "utils/lib";


const fieldsModel: IFieldModel = {
    //Car Details//
    vehicleRegistrationNumber: {
        id: 'properties.Car Details.Vehicle Registration Number',
        label: 'Vehicle Registration Number',
        type: 'Input',
        placeholder: `Enter Vehicle Registration Number`
    },
    make:{
        id: 'properties.Car Details.Make',
        label: 'Make',
        type: 'Select',
        collectionId: 'make',
        placeholder: `Select Options`
    },
    carModel:{
        id: 'properties.Car Details.Model',
        label: 'Model',
        type: 'Select',
        collectionId: 'model',
        placeholder: `Select Options`
    },
    manufactureYear:{
        id: 'properties.Car Details.Manufacture Year',
        label: 'Manufacture Year',
        type: 'Select',
        collectionId: 'manufactureYear',
        placeholder: `Select Options`
    },
    engineCC:{
        id: 'properties.Car Details.Engine CC',
        label: 'Engine CC',
        type: 'Select',
        collectionId: 'engineCC',
        placeholder: `Select Options`
    },
    transmission:{
        id: 'properties.Car Details.Transmission',
        label: 'Transmission',
        type: 'Select',
        collectionId: 'transmission',
        placeholder: `Select Options`
    },
    variant:{
        id: 'properties.Car Details.Variant',
        label: 'Variant',
        type: 'Select',
        collectionId: 'variant',
        placeholder: `Select Options`
    },
    vehicleDetailsVerified:{
        id: 'properties.Car Details.Vehicle details verified',
        label: 'I confirm that the vehicle details above are true and accurate.',
        type: 'Checkbox',
    },

    //Car Owner Details//
    idType:{
        id: 'properties.Car Owner Details.ID Type',
        label: 'ID Type',
        type: 'Select',
        collectionId: 'idTypes',
        placeholder: `Select Option`
    },
    idNumber:{
        id: 'properties.Car Owner Details.ID Number',
        label: 'ID Number',
        type: 'Input',
        placeholder: `Enter ID number`
    },
    dob:{
        id: 'properties.Car Owner Details.DOB',
        label: 'DOB',
        type: 'DatePicker',
        placeholder: `Select Date`
    },
    gender:{
        id: 'properties.Car Owner Details.Gender',
        label: 'Gender',
        type: 'Select',
        collectionId: 'genders',
    },
    maritalStatus:{
        id: 'properties.Car Owner Details.Marital Status',
        label: 'Marital Status',
        type: 'Select',
        collectionId: 'maritalStatus',
        placeholder: `Select Option`
    },
    postcode:{
        id: 'properties.Car Owner Details.Postcode',
        label: 'Postcode',
        type: 'Input',
        collectionId: 'variant_myMotorInsurance',
        placeholder: `Enter postcode`
    },
    fullName:{
        id: 'properties.Car Owner Details.Full Name',
        label: 'Full Name',
        type: 'Input',
        collectionId: 'variant_myMotorInsurance',
        placeholder: `Enter full name`
    },
    occupation:{
        id: 'properties.Car Owner Details.Occupation',
        label: 'Occupation',
        type: 'Input',
        collectionId: 'variant_myMotorInsurance',
        placeholder: `Enter Occupation`
    },
    mobileNumber:{
        id: 'properties.Car Owner Details.Mobile Number',
        label: 'Mobile Number',
        type: 'Input',
        collectionId: 'variant_myMotorInsurance',
        placeholder: `Enter mobile number`
    },
    address:{
        id: 'properties.Car Owner Details.Address',
        label: 'Address',
        type: 'Input',
        collectionId: 'variant_myMotorInsurance',
        placeholder: `Enter Address`
    },
    city:{
        id: 'properties.Car Owner Details.City',
        label: 'City',
        type: 'Input',
        collectionId: 'variant_myMotorInsurance',
        placeholder: `Enter City`
    },
    state:{
        id: 'properties.Car Owner Details.State',
        label: 'State',
        type: 'Select',
        collectionId: 'provinces',
        placeholder: `Select Option`
    },
    emailAddress:{
        id: 'properties.Car Owner Details.Email Address',
        label: 'Email Address',
        type: 'Input',
        collectionId: 'variant_myMotorInsurance',
        placeholder: `Enter email address`
    },
    sumAssured: { id: 'properties.Sum Assured', label: '', ext: ' / ', type: 'PriceRange', placeholder: `Sum Assured ${lib.checkIsDemoSite() ? '/ ' : ''}` },

    paymentMode: { id: 'properties.Payment Mode', label: 'Mode of Payment', ext: ' / មធ្យោបាយនៃការបង់ប្រាក់', type: 'Select', collectionId: 'paymentMethod', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសមធ្យោបាយនៃការបង់ប្រាក់' : ''}` },
    receiptNo: { id: 'properties.Receipt No', label: 'Receipt No', ext: ' / លេខវិក្កយប័ត្រ', type: 'Input', placeholder: `Enter receipt no ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខវិក្កយប័ត្រ' : ''}` },
    paymentReceivedBy: { id: 'properties.Payment Received By', label: 'Payment Received By', ext: ' / ធ្វើការទូទាត់ដោយ', type: 'Select', collectionId: 'currentOuUsers', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសជម្រើសនៃការទូទាត់' : ''}` },
};

const basicDetailsFields:tObjModel=['vehicleRegistrationNumber']
const carDetailsFields:tObjModel=['make' ,'carModel' ,'manufactureYear','engineCC','transmission'
    ,'variant', {keyName:'vehicleDetailsVerified',handler:'vehicleVerifiedHandler'}];
const carOwnerDetailsFields:tObjModel=[

        {
            keyName:'idType',handler:'handleMainIdType'
        },
        {
            keyName:'idNumber',handler:'handleMainIdNumber'
        },
        'dob',
        'gender',
        'maritalStatus',
        {
            keyName:'postcode', handler:'handlePostcode'
        }
    ];

const carOwnerCompleteDetailsFields:tObjModel =[
    'fullName',
    {
        keyName:'occupation',handler:'handleStringOnly'
    },
    {
        keyName:'idType',handler:'handleMainIdType'
    },
    {
        keyName:'idNumber',handler:'handleMainIdNumber'
    },
    'dob',
    'gender',
    'maritalStatus',
    {
        keyName:  'address',
        style:{col:12}
    },
    {
        keyName:'postcode', handler:'handlePostcode'
    },
    'city',
    'state',
    'emailAddress',
    {
        keyName:"mobileNumber" , handler:'handleNumber'
    }
]

const paymentDetails:tObjModel=[
    {
        keyName:'paymentMode',
        handler:'handlePaymentMode',
        style:{col:12}
    } ,
    {
        keyName:'receiptNo',
        style:{col:12}
    },
    {
        keyName:'paymentReceivedBy',
        style:{col:12}
    }
  ]

const model = { fieldsModel, basicDetailsFields, carDetailsFields,carOwnerDetailsFields,carOwnerCompleteDetailsFields,paymentDetails};

export default model;
