import actionTypes from '@redux/actionTypes';
import BaseListingReducer from './baseListing';

class LeadListReducer extends BaseListingReducer<any> {
  public actions: IDictionary<(state: IListState, action: IAction) => object>;

  public initialState: IListState;

  protected actionTypes: ILeadActionTypes;

  constructor() {

    super();

    this.actionTypes = actionTypes.leadManagement;

    this.initialState = {
      isLoading: true,
      error: null,
      searchKeyword: '',
      list: [],
      initialFilters: [],
      filters: [],
      prevFilters: [],
      fields: [],
      collections: {},
      accessIsForbidden: false,
      notFound: false,
      hasRecordsInitially: false,
      currentLeadItem: null,
      pageInfo: {
        empty: false,
        first: true,
        last: false,
        pageNumber: 0,
        pageSize: 10,
        totalElements: 10,
        numberOfElements: 0,
        totalPages: 1,
      },
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IListState,action: IAction) => IListState> => ({
    [this.actionTypes.LIST_FAILED]: this.setError,
    [this.actionTypes.LIST_LOADING]: this.setLoading,
    [this.actionTypes.LIST_SET_FORBIDDEN]: this.setForbidden,

    [this.actionTypes.LIST_GET]: this.getList,
    [this.actionTypes.SET_CURRENT_LEAD_INFO]: this.setCurrentLeadInfo,
    [this.actionTypes.LIST_SET_NOT_FOUND]: this.setNotFound,
    [this.actionTypes.LIST_RESET]: this.reset,

    [this.actionTypes.LIST_CHANGE_PAGE]: this.setPageNumber,
    [this.actionTypes.LIST_CHANGE_PAGE_SIZE]: this.setPageSize,
    [this.actionTypes.LIST_CHANGE_FILTERS]: this.changeFilter,
    [this.actionTypes.LIST_RESET_FILTERS]: this.resetFilter,
    [this.actionTypes.LIST_APPLY_FILTERS]: this.applyFilter,
    [this.actionTypes.LIST_CHANGE_SEARCH_KEY]: this.setSearchKeyword,

    [this.actionTypes.LIST_SET_HAS_RECORDS_INITIALLY]: this.hasRecordsInitially,
  })

  protected getList = (state:IListState, action: IAction):IListState => ({
    ...state,
    isLoading: false,
    error: null,
    collections: action.payload.collections,
    list: action.payload.list,
    pageInfo: action.payload.pageInfo,
    fields: action.payload.fields,
    initialFilters: action.payload.initialFilters,
    filters: action.payload.filters,
    prevFilters: action.payload.filters,
    tab: action.payload.tab,
    viewTypeIsFlat: action.payload.viewTypeIsFlat,
  })

  private setCurrentLeadInfo = (state: IListState, action: IAction) => ({
    ...state,
    currentLeadItem: action.payload,
  });
}
export default LeadListReducer;
