import React, {useState,useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import {actions} from "../../@redux";
import {Header, Loader, Table} from "../../@xc-core/components/core";
import {objLib} from "../../@xc-core/lib";
import bannerImg from "../../images/bannerGraphicImages/get-quote.png";
import CodeEditor from "../../@xc-core/components/core/codeEditor";
import { AssetsConfigList } from './assetsConfigList';
import './index.scss'

const productAction = new actions.ProductActions();

const ProductConfig=()=>{
    const [loading , setIsLoading] = useState<boolean>(false);
    const [errorMessages, setErrorMessages]= useState<string[]>([]);
    const [code, setCode] = React.useState('');
    const [isOpenTemplateEditor , setIsOpenTemplateEditor] = useState<boolean>(false);
    const [template , setTemplate] = useState<ITemplateProps>();
    const dispatch= useDispatch();
    const productList:any = useSelector<IStore>(state => state.productList?.list);

    useEffect(()=>{
        if(!productList){
            setIsLoading(true)
            dispatch(productAction.setList())
        }
        return ()=> setIsLoading(false)
    },[productList])

    const templateActionHandler=async (type:'policy' | 'quotation', productCode:string)=>{
        const productTemplate = await dispatch(productAction.getTemplateByProductCode(type,productCode))
        setTemplate({
            title:`Edit ${type}.json` || '',
            type,
            productCode
        })
        setErrorMessages([])
        setCode(productTemplate ? objLib.beautifyJSON(JSON.stringify(productTemplate)) : '')
        setIsOpenTemplateEditor(!isOpenTemplateEditor)
    }

    const validateJSON=(jsonTemplate:string)=>{
        const beautifyJson = objLib.beautifyJSON(jsonTemplate);
        if(beautifyJson === 'INVALID_JSON_FORMAT'){
            setErrorMessages([...errorMessages,'Invalid Format']);
            return '';
        }
        return 'valid'
    }

    const saveJSONTemplate=(jsonTemplate:string)=>{
        const isValid = validateJSON(jsonTemplate);
        if(isValid){
            if(template && template.type){
                const templateJSON= JSON.parse(jsonTemplate.replace(/\n/g, " "));
                if(templateJSON?.productCode !== template.productCode){
                    setErrorMessages([...errorMessages,'Wrong Template'])
                    return;
                }
                dispatch(productAction.updateProductTemplateJSON(
                    template.type,
                    templateJSON
                ))
            }
        }
    }

    const codeChangeHandler=(value:string)=>{
        if(errorMessages.length !== 0){
            setErrorMessages([])
        }
        setCode(value)
    }

    const beautifyJsonHandler=()=>{
        const isValid = validateJSON(code);
        if(isValid){
            setCode(objLib.beautifyJSON(code))
        }
    }

    return <>
        <Header
            title='Products - Config'
            bannerGraphic={bannerImg}
            withBorderBottom
        />
        {loading && <Loader/>}
        <div
            className={'col-9'}
            style={{
                margin: '4.5rem 0 2rem 4rem',
            }}
        >
            <Table
                titles={[
                    {id:'no' , value:'No.'},
                    {id:'name' ,value:'Name'},
                    {id:'templates' ,value:'Templates'},
                ]}
                records={productList?.filter((el:IProduct)=>el.name !== 'All')?.map((el:IProduct,index:number)=>{
                    const productCode= el.id.toString();
                    return {
                        no: index + 1,
                        name: el.name,
                        templates: (
                            <div className={'flex-container'}>
                                <span
                                    title={'policy'}
                                    className="linkButton text-primary"
                                    onClick={()=>templateActionHandler('policy',productCode)}
                                >
                                    Policy
                                </span>
                                <span
                                    title={'quotation'}
                                    className="linkButton text-primary"
                                    onClick={()=>templateActionHandler('quotation',productCode)}
                                >
                                    Quotation
                                </span>
                                <AssetsConfigList product={el}/>
                            </div>
                        )
                    }
                }) || []}
                hasRecords={true}
                image={bannerImg}
                onRowClicked={() =>{}}
                onRowButtonClicked={() =>{}}
                handleSearchKeyword={() =>{}}
                searchKeyword={''}
                handlePageClick={() => {}}
                handlePageSizeChange={()=>{}}
                pagination={''}
                activeSearch={false}
                style={{
                    padding:0
                }}
            />
        </div>
        <CodeEditor
            template={template}
            toggleModal={()=>setIsOpenTemplateEditor(false)}
            isOpen={isOpenTemplateEditor}
            onSave={saveJSONTemplate}
            errorMessages={errorMessages}
            onCodeChange={codeChangeHandler}
            code={code}
            customButton={(
                <Button
                    color={'success'}
                    onClick={beautifyJsonHandler}
                    style={{
                        borderRadius:5,
                        width:'10em',
                        padding:'0.25rem 0rem',
                        alignSelf:'flex-end',
                        marginTop:10
                    }}
                    size={'sm'}
                >
                    Beautify
                </Button>
            )}
        />
    </>
}

export default ProductConfig;