import actionTypes from '@redux/actionTypes';
import BaseAction from './base';

class ModalMessageAction extends BaseAction {
  protected service = {};

  protected createScope = '';

  protected editScope = '';

  protected actions = actionTypes.modalMessage;

  public show = (title: string, message: string) => (dispatch: any) => {
    const payload = { title, message };
    dispatch(this.createAction(this.actions.SHOW, payload));
  };

  public hide = () => (dispatch: any) => {
    dispatch(this.createAction(this.actions.HIDE));
  };
}

export default ModalMessageAction;
