/* eslint-disable object-curly-newline */

import lib from 'utils/lib';

const fieldsModel: IFieldModel = {
  insuredType: { id: 'properties.Insured Type', label: 'I want a plan for', ext: ' / ', type: 'Select', collectionId: 'insuredType', placeholder: 'Select Insured Type' },
  individualOccupation: { id: 'properties.Individual Occupation', label: 'Individual Occupation', ext: ' / ', type: 'Select', collectionId: 'occupation', placeholder: 'Select Individual Occupation' },
  spouseOccupation: { id: 'properties.Spouse Occupation', label: 'Spouse Occupation', ext: ' / ', type: 'Select', collectionId: 'spouseOccupation', placeholder: 'Select Spouse Occupation' },
  // individualOccupation: { id: 'properties.occupationName', label: 'Individual Occupation', ext: ' / ', type: 'Select', collectionId: 'occupation', placeholder: 'Select Individual Occupation' },
  // spouseOccupation: { id: 'properties.spouseOccupationName', label: 'Spouse Occupation', ext: ' / ', type: 'Select', collectionId: 'spouseOccupation', placeholder: 'Select Spouse Occupation' },

  plan: { id: 'properties.Plan', label: 'Plan', type: 'PlanButton', collectionId: 'priceList' },
  // plan: { id: 'properties.Plan', label: 'I want a plan for', ext: ' / ', type: 'Select', collectionId: 'plans', placeholder: `Select Plan ${lib.checkIsDemoSite() ? '/ ' : ''}` },

  fullName: { id: 'properties.main applicant.Full Name', label: 'Full Name', ext: ' / ឈ្មោះពេញ', type: 'Input', placeholder: `Enter full name ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះពេញ' : ''}` },
  idType: { id: 'properties.main applicant.ID Type', label: 'ID Type', ext: ' / ប្រភេទអត្តសញ្ញាណបណ្ណ', type: 'Select', collectionId: 'idTypes', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសប្រភេទអត្តសញ្ញាណបណ្ណ' : ''}` },
  idNumber: { id: 'properties.main applicant.ID Number', label: 'ID Number', ext: ' / លេខអត្តសញ្ញាណបណ្ណ', type: 'Input', placeholder: `Enter ID number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខអត្តសញ្ញាណបណ្ណ' : ''}` },
  dob: { id: 'properties.main applicant.DOB', label: 'Date of Birth', ext: ' / ថ្ងៃខែឆ្នាំកំណើត', type: 'DatePicker', placeholder: `Select Date of Birth ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសថ្ងៃខែឆ្នាំកំណើត' : ''}` },
  gender: { id: 'properties.main applicant.Gender', label: 'Gender', ext: ' / ភេទ', type: 'Select', collectionId: 'genders', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសភេទ' : ''}` },

  spouseFullName: { id: 'properties.spouse.Full Name', label: 'Full Name', ext: ' / ឈ្មោះពេញ', type: 'Input', placeholder: `Enter full name ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះពេញ' : ''}` },
  spouseIdType: { id: 'properties.spouse.ID Type', label: 'ID Type', ext: ' / ប្រភេទអត្តសញ្ញាណបណ្ណ', type: 'Select', collectionId: 'idTypes', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសប្រភេទអត្តសញ្ញាណបណ្ណ' : ''}` },
  spouseIdNumber: { id: 'properties.spouse.ID Number', label: 'ID Number', ext: ' / លេខអត្តសញ្ញាណបណ្ណ', type: 'Input', placeholder: `Enter ID number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខអត្តសញ្ញាណបណ្ណ' : ''}` },
  spouseDob: { id: 'properties.spouse.DOB', label: 'Date of Birth', ext: ' / ថ្ងៃខែឆ្នាំកំណើត', type: 'DatePicker', placeholder: `Select Date of Birth ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសថ្ងៃខែឆ្នាំកំណើត' : ''}` },
  spouseGender: { id: 'properties.spouse.Gender', label: 'Gender', ext: ' / ភេទ', type: 'Select', collectionId: 'genders', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសភេទ' : ''}` },

  address: { id: 'properties.main applicant.Address', label: 'Address', ext: ' / អាសយដ្ឋាន', type: 'Input', placeholder: `Enter address ${lib.checkIsDemoSite() ? '/ សូមបំពេញអាសយដ្ឋាន' : ''}` },
  country: { id: 'properties.main applicant.Country', label: 'Country', ext: ' / ប្រទេស', type: 'Select', collectionId: 'countries' },
  state: { id: 'properties.main applicant.State', label: 'State/Province/Region', ext: ' / សូមបំពេញ ក្រុង/ខេត្ត/តំបន់', type: 'Input', placeholder: 'Enter State/Province/Region' },
  postCode: { id: 'properties.main applicant.Postcode', label: 'Postcode', ext: ' / លេខកូដតំបន់', type: 'Input', placeholder: `Enter postcode ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខកូដតំបន់' : ''}` },
  city: { id: 'properties.main applicant.City', label: 'City/Town', ext: ' / ទីក្រុង', type: 'Input', placeholder: `Enter city/town ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះទីក្រុង' : ''}` },
  email: { id: 'properties.main applicant.Email Address', label: 'Email Address', ext: ' / អាសយដ្ឋានអ៊ីមែល', type: 'Input', placeholder: `Enter email address ${lib.checkIsDemoSite() ? '/ សូមបំពេញអាសយដ្ឋានអ៊ីមែល' : ''}` },
  mobileNo: { id: 'properties.main applicant.Mobile Number', label: 'Mobile Number', ext: ' / លេខទូរសព្ទ័ទំនាក់ទំនង', type: 'Input', placeholder: `Enter contact number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខទូរសព្ទ័ទំនាក់ទំនង' : ''}` },

  additionalDrivers: { id: 'properties.additional drivers', label: '', type: 'array' },
  additionalDriverName: { id: 'properties.additional drivers[_#_].Full Name', label: 'Full Name', ext: ' / ឈ្មោះពេញ', type: 'Input', placeholder: `Enter full name ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះពេញ' : ''}` },
  additionalDriverIdType: { id: 'properties.additional drivers[_#_].ID Type', label: 'ID Type', ext: ' / ប្រភេទអត្តសញ្ញាណបណ្ណ', type: 'Select', collectionId: 'idTypes', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសប្រភេទអត្តសញ្ញាណបណ្ណ' : ''}` },
  additionalDriverIdNumber: { id: 'properties.additional drivers[_#_].ID Number', label: 'ID Number', ext: ' / លេខអត្តសញ្ញាណបណ្ណ', type: 'Input', placeholder: `Enter ID number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខអត្តសញ្ញាណបណ្ណ' : ''}` },
  additionalDriverDob: { id: 'properties.additional drivers[_#_].DOB', label: 'Date of Birth', ext: ' / ថ្ងៃខែឆ្នាំកំណើត', type: 'DatePicker', placeholder: `Select Date of Birth ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសថ្ងៃខែឆ្នាំកំណើត' : ''}` },

  paymentMode: { id: 'properties.Payment Mode', label: 'Mode of Payment', ext: ' / មធ្យោបាយនៃការបង់ប្រាក់', type: 'Select', collectionId: 'paymentMethod', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសមធ្យោបាយនៃការបង់ប្រាក់' : ''}` },
  receiptNo: { id: 'properties.Receipt No', label: 'Receipt No', ext: ' / លេខវិក្កយប័ត្រ', type: 'Input', placeholder: `Enter receipt no ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខវិក្កយប័ត្រ' : ''}` },
  paymentReceivedBy: { id: 'properties.Payment Received By', label: 'Payment Received By', ext: ' / ធ្វើការទូទាត់ដោយ', type: 'Select', collectionId: 'currentOuUsers', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសជម្រើសនៃការទូទាត់' : ''}` },
};

const mainDetailModel = [
  fieldsModel.fullName,
  fieldsModel.idType,
  fieldsModel.idNumber,
  fieldsModel.dob,
  fieldsModel.gender,
  fieldsModel.address,
  fieldsModel.city,
  fieldsModel.state,
  fieldsModel.country,
  fieldsModel.postCode,
  fieldsModel.email,
  fieldsModel.mobileNo,
];
const spouseDetailModel = [
  fieldsModel.fullName,
  fieldsModel.gender,
  fieldsModel.dob,
  fieldsModel.idType,
  fieldsModel.idNumber,
];

const model = { fieldsModel, mainDetailModel, spouseDetailModel };

export default model;
