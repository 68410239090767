import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sidebarModel } from 'models';
import products from '_config/products';
import stateToProps from '@containers/storeState';
import dispatchToProps from '@containers/dispatches';
import { objLib } from '@xc-core/lib';
import { Loader } from '@xc-core/components/core';
import BasePolicyDetails from '../base-policy-detail';
import fields from './fields';
import models from './model';
import util from '../i-med/util';

interface IProps {
  history: any
  policyManagement: IObject
  setPolicyOccupationList: () => {}
}
const IProtectPolicyDetails = (props: IProps) => {
  const {
    history,
    policyManagement: { policy, detailCollections },
    setPolicyOccupationList,
  } = props;

  const [updatedModels, setModels] = useState(models as IObject);

  // console.log('collections', collections);
  // console.log('quotation', quotation);

  useEffect(() => {
    /** only add femaleDisorder question to model when applicant is a female
     */
    if (objLib.isEmpty(policy) === false) {
      setModels(util.applyQuestionNumber(util.tryAddFemaleDisorderAndNumberOfCigarette(policy,
        models, fields)));
    }
  }, [policy]);

  if (objLib.isEmpty(policy)) return <Loader />;

  return (
    <BasePolicyDetails
      fields={fields}
      models={{ ...updatedModels, sidebar: sidebarModel.policy.iMedGbsn }}
      history={history}
      policy={policy}
      collections={detailCollections}
      defaultCountry={products.gbsnIMed.defaultValues.country}
      setOccupationList={setPolicyOccupationList}
    />
  );
};

export default connect(stateToProps, dispatchToProps)(IProtectPolicyDetails);
