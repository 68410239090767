import { UserGroupService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import { requestLib } from '@xc-core/lib';
import BaseListAction from './baseListing';

class UserGroupAction extends BaseListAction {
  constructor() {
    super();
    this.service = new UserGroupService();
  }

  protected actions = actionTypes.userGroup;

  protected service: UserGroupService;

  protected csvExportScope = '';

  protected editScope = 'Edit User Group';

  protected createScope = 'Create User Group';

  public setList = (params: IRequestParam) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      const stringParams = requestLib.convertToRequestParams(params);
      let result = await this.service.getList(stringParams);
      result = {
        ...result,
      };
      dispatch(this.createAction<IObject>(this.actions.LIST_GET, result));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      if (error.status === 404) {
        dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }

  public getSearchParams = () => (dispatch: any, getState: any): IRequestParam => {
    const { userGroupList } = getState();
    return this.getSearchParamsByItem(userGroupList);
  }

  public setHasRecordsInitially= () => async (dispatch: any, getState: any) => {
    const { list } = getState().userGroupList;
    const hasRecords = list && list.length > 0;
    dispatch(this.createAction<boolean>(this.actions.LIST_SET_HAS_RECORDS_INITIALLY, hasRecords));
  }
}

export default UserGroupAction;
