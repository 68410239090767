import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { defaultValues } from '@products/AlfalahWallet/config';
const ValidationMessages = ({validator, disabled, value='', inputName, validationRules, validationParams}) => {
  return (
    <div>
      {
        validator && !disabled
          && validator.message(inputName, validationParams
            ? { value, validationParams } : value, validationRules)
      }
    </div>
  );
};

const Edit = (props) => {
  const { properties, collections, handleChange, beneficiaryValidator, 
    validator, handleIdTypeChange, handleIdNumberChange, handleNumberFormatFields } = props;
  const { Beneficiaries } = properties;
  const isBeneficiary = true;

  const handleLength = (e, length, forBeneficiary = false) => {
    const { id } = e.target;
    let { value } = e.target;
    if (value && value.length > length) {
      value = value.slice(0, length);
    }
    handleNumberFormatFields({ target: { id, value } }, forBeneficiary);
  };

  const getMainInsuredEnteredIdNumber = () => [properties['ID Number']];
  const getBeneficiaryEnteredIdNumber = () => [Beneficiaries[0]["ID Number"]];
  return (
  <Row>
    <Col sm={12}>
      <Row>
      <Col sm={12}>
        <h4 className='beneficiary-header my-3 p-3'>Main Applicant</h4>
        <Row className='wordBreak'>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Customer Details</div>
            <Row>
              <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Full Name'
                    name='Full Name'
                    placeholder='Enter full name'
                    value={properties['Full Name']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='customRequired|alfalah_full_name_valid_characters'
                    value={properties['Full Name']}
                    inputName='Full Name'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Nationality</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='Nationality'
                    name='Nationality'
                    value={properties['Nationality']}
                    onChange={(e) => handleChange(e)}
                >
                  {
                    collections.nationalities.map(nationality => {
                      return (<option key={nationality.id}>{nationality.name}</option>);
                    })
                  }
                </Input>
              </Col>
              <Col className='pt-2 summary-label' sm={6}>ID Type</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='ID Type'
                    name='ID Type'
                    value={properties['ID Type']}
                    onChange={(e) => handleIdTypeChange(e)}
                    autoFocus
                >
                  {
                    collections.idTypes.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='ID Number'
                    name='ID Number'
                    placeholder='Enter ID number'
                    value={properties['ID Number']}
                    onChange={(e) => handleIdNumberChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={properties['ID Number']}
                    inputName='ID Number'
                    validationRules={`customRequired${Beneficiaries[0]['ID Type'] === properties['ID Type'] ? '|beneficiary_no_same_Id' : '' }${properties['ID Type'] === 'CNIC' ? `|cnic_length` : ''}`}
                    validationParams={{ idCollections: getBeneficiaryEnteredIdNumber() }}
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Gender</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='Gender'
                    name='Gender'
                    value={properties.Gender}
                    onChange={(e) => handleChange(e)}
                >
                  {
                    collections.genders.map(gender => {
                      return (<option key={gender.id}>{gender.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Debit Card / Account No</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Debit Card or Account No'
                    name='Debit Card or Account No'
                    placeholder='Enter debit card or account No'
                    value={properties['Debit Card or Account No']}
                    onChange={(e) => handleLength(e, defaultValues.debitCreditNoLength)}
                    autoFocus
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Address</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Address'
                    name='Address'
                    placeholder='Enter address'
                    value={properties['Address']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='customRequired'
                    value={properties['Address']}
                    inputName='Address'
                  />
                </>
              </Col>
              <Col className='pt-2 summary-label' sm={6}>Country</Col>
                    <Col className='pt-2 summary-label-2' sm={6}>
                      <Input
                        type='select'
                        id='Country'
                        name='Country'
                        value={properties.Country}
                        onChange={(e) => handleChange(e)}
                        autoFocus
                        disabled
                      >
                        {
                          collections.countries.map(country => {
                            return (<option key={country.id}>{country.name}</option>);
                          })
                        }
                      </Input>
                    </Col>

                    <Col className='pt-2 summary-label' sm={6}>State / Province / Region</Col>
                    <Col className='pt-2 summary-label-2' sm={6}>
                      <Input
                        // type='select'
                        id='State'
                        name='State'
                        placeholder='Enter State'
                        value={properties.State}
                        onChange={(e) => handleChange(e)}
                        autoFocus
                      >
                        {/*{*/}
                        {/*  collections.states.map(state => {*/}
                        {/*    return (<option key={state.id}>{state.name}</option>);*/}
                        {/*  })*/}
                        {/*}*/}
                      </Input>
                    </Col>

                    <Col className='pt-2 summary-label' sm={6}>Postcode</Col>
                    <Col className='pt-2 summary-label-2' sm={6}>
                      <>
                        <Input
                          id='Postcode'
                          name='Postcode'
                          placeholder='Enter postcode'
                          value={properties.Postcode}
                          // onChange={(e) => handleLength(e, defaultValues.postcodeLength)}
                          onChange={(e) => handleChange(e)}
                          autoFocus
                        />
                        {/*<ValidationMessages*/}
                        {/*  validator={validator}*/}
                        {/*  validationRules={`${`numbersOnly|min:${defaultValues.postcodeLength}|max:${defaultValues.postcodeLength}`}`}*/}
                        {/*  value={properties.Postcode}*/}
                        {/*  inputName='Postcode'*/}
                        {/*/>*/}
                      </>
                    </Col>

                    <Col className='pt-2 summary-label' sm={6}>City / Town</Col>
                    <Col className='pt-2 summary-label-2' sm={6}>
                      <>
                        <Input
                          id='City'
                          name='City'
                          placeholder='Enter city'
                          value={properties.City}
                          onChange={(e) => handleChange(e)}
                          autoFocus
                        />
                        <ValidationMessages
                          validator={validator}
                          validationRules='customRequired'
                          value={properties.City}
                          inputName='City'
                        />
                      </>
                    </Col>

                    <Col className='pt-2 summary-label' sm={6}>Email Address</Col>
                    <Col className='pt-2 summary-label-2' sm={6}>
                      <>
                        <Input
                          id='Email Address'
                          name='Email Address'
                          placeholder='Enter email address'
                          value={properties['Email Address']}
                          onChange={(e) => handleChange(e)}
                          autoFocus
                        />
                        <ValidationMessages
                          validator={validator}
                          validationRules='customRequired|email'
                          value={properties['Email Address']}
                          inputName='Email Address'
                        />
                      </>
                    </Col>

                    <Col className='pt-2 summary-label' sm={6}>Phone Number</Col>
                    <Col className='pt-2 summary-label-2' sm={6}>
                      <>
                        <Input
                          id='Contact Number'
                          name='Contact Number'
                          placeholder='Enter phone number'
                          value={properties['Contact Number']}
                          onChange={(e) => handleLength(e, defaultValues.contactNumberLength)}
                          autoFocus
                        />
                        <ValidationMessages
                          validator={validator}
                          validationRules={`numbersOnly|customRequired|min:${defaultValues.contactNumberLength}|max:${defaultValues.contactNumberLength}`}
                          value={properties['Contact Number']}
                          inputName='Contact Number'
                        />
                      </>
                    </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Beneficiary Details</div>
            <Row>
              <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
                <Col className='pt-2 summary-label-2' sm={6}>
                  <>
                    <Input
                      id='Full Name'
                      name='Full Name'
                      placeholder='Enter full name'
                      onChange={(e) => handleChange(e, isBeneficiary)}
                      value={Beneficiaries && Beneficiaries.length > 0
                        ? Beneficiaries[0]['Full Name'] : ''}
                      autoFocus
                    />
                    {/*<ValidationMessages*/}
                    {/*  validator={beneficiaryValidator}*/}
                    {/*  validationRules='full_name_valid_characters|customRequired'*/}
                    {/*  value={Beneficiaries && Beneficiaries.length > 0*/}
                    {/*    ? Beneficiaries[0]['Full Name'] : ''}*/}
                    {/*  inputName='Full Name'*/}
                    {/*/>*/}
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Relationship</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  // type='select'
                    id='Relationship'
                    name='Relationship'
                    placeholder='Enter Relationship'
                    onChange={(e) => handleChange(e, isBeneficiary)}
                    value={Beneficiaries && Beneficiaries.length > 0
                      ? Beneficiaries[0]['Relationship'] : ''}
                    autoFocus
                >
                  {/*{*/}
                  {/*  collections.relations.map(relation => {*/}
                  {/*    return (<option key={relation.id}>{relation.name}</option>);*/}
                  {/*  })*/}
                  {/*}*/}
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Type</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  // type='select'
                  id='ID Type'
                  name='ID Type'
                  placeholder='Enter ID Type'
                  onChange={(e) => handleChange(e, isBeneficiary)}
                  value={Beneficiaries && Beneficiaries.length > 0
                    ? Beneficiaries[0]['ID Type'] : ''}
                  autoFocus
                >
                  {/*{*/}
                  {/*  collections.idTypes.map(idType => {*/}
                  {/*    return (<option key={idType.id}>{idType.name}</option>);*/}
                  {/*  })*/}
                  {/*}*/}
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='ID Number'
                    name='ID Number'
                    placeholder='Enter ID number'
                    onChange={(e) => handleChange(e, isBeneficiary)}
                    value={Beneficiaries && Beneficiaries.length > 0
                      ? Beneficiaries[0]['ID Number'] : ''}
                    autoFocus
                  />
                  {/*<ValidationMessages*/}
                  {/*  validator={beneficiaryValidator}*/}
                  {/*  value={Beneficiaries && Beneficiaries.length > 0*/}
                  {/*    ? Beneficiaries[0]['ID Number'] : ''}*/}
                  {/*  inputName='ID Number'*/}
                  {/*  validationRules={`customRequired${Beneficiaries[0]['ID Type'] === properties['ID Type'] ? '|beneficiary_no_same_Id' : '' }${Beneficiaries[0]['ID Type'] === 'CNIC' ? `|cnic_length` : ''}`}*/}
                  {/*  validationParams={{ idCollections: getMainInsuredEnteredIdNumber() }}*/}
                  {/*/>*/}
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Email Address</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Email Address'
                    name='Email Address'
                    placeholder='Enter email address'
                    onChange={(e) => handleChange(e, isBeneficiary)}
                    value={Beneficiaries && Beneficiaries.length > 0
                      ? Beneficiaries[0]['Email Address'] : ''}
                    autoFocus
                  />
                  {/*<ValidationMessages*/}
                  {/*  validator={beneficiaryValidator}*/}
                  {/*  validationRules='email|customRequired'*/}
                  {/*  value={Beneficiaries && Beneficiaries.length > 0*/}
                  {/*    ? Beneficiaries[0]['Email Address'] : ''}*/}
                  {/*  inputName='Email Address'*/}
                  {/*/>*/}
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Phone Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Contact Number'
                    name='Contact Number'
                    placeholder='Enter phone number'
                    onChange={(e) => handleChange(e,isBeneficiary)}
                    value={Beneficiaries && Beneficiaries.length > 0
                      ? Beneficiaries[0]['Contact Number'] : ''}
                    autoFocus
                  />
                  {/*<ValidationMessages*/}
                  {/*  validator={beneficiaryValidator}*/}
                  {/*  validationRules={`numbersOnly|customRequired|min:${defaultValues.contactNumberLength}|max:${defaultValues.contactNumberLength}`}*/}
                  {/*  value={Beneficiaries && Beneficiaries.length > 0*/}
                  {/*    ? Beneficiaries[0]['Contact Number'] : ''}*/}
                  {/*  inputName='Contact Number'*/}
                  {/*/>*/}
                </>
              </Col>
            </Row>
          </Col>
        </Row>
    </Col>
    </Row>
    </Col>
  </Row>
    );
}

export default Edit;
