/* eslint-disable object-curly-newline */
import baseFields from '../base-fields';

const quotation = {
  ...baseFields.quotation,
  gender: { ...baseFields.quotation.gender, id: 'properties.Main Applicant.Gender' },
  treatmentFor: { id: 'properties.Questionnaire.Treatment for', label: 'Health Risks', type: '' },
  declinedInsuranceCoverage: { id: 'properties.Questionnaire.Declined Insurance Coverage', label: 'Abnormal Health Results', type: '' },
  consultSpecialist: { id: 'properties.Questionnaire.Consult a Specialist', label: 'Insurance Declined/Postponed/Loading', type: '' },
  femaleDisorder: { id: 'properties.Questionnaire.Female Disorder', label: 'Female health complications', type: '' },
  smokingCigarette: { id: 'properties.Questionnaire.Smoking Cigarette', label: 'Smoker', type: '' },
  numberOfCigarette: { id: 'properties.Questionnaire.Number of Cigarette', label: 'Cigarettes per day', type: '' },
};

const policy = { ...baseFields.policy, ...quotation };

export default { quotation, policy };
