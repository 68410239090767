import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import { config, formatCurrency } from 'utils';

const PlanSelection = props => {
  const {classes, fields, collections} = props; 
  return(
      <Grid container spacing={24}>
      {collections.priceList && collections.priceList.map((price, key ) => {
        return (
          <Grid item xs={12} md={4} key={key}>
            <Card
              className={`
              ${classes.card}
              ${fields.plan === price["Plan Type"] ? classes.cardActive : ""}
            `}
            >
              <CardActionArea
                onClick={() => props.handlePlanChange("planSelection",{id:'plan', name: price["Plan Type"], priceObj: price})}
                data-set={1}
                type="button"
                autoFocus = { price["Plan Type"] === fields.plan ? true : false}
              >
                <div
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                      color: "#064e8c"
                    }}
                  >
                    <i
                    className={
                      fields.plan ===  price["Plan Type"]
                          ? "icon-checkedOutline font-size-normal"
                          : ""
                      }
                    />
                  </div>
                  <CardContent
                    className={"d-flex flex-column p-4"}
                  >
                    <h5>
                      {price["Plan Type"]}
                    </h5>
                    <h3 className="text-primary font-weight-bold">
                    {`${config.currencySymbol} ${formatCurrency(price["Main Policy"])}`}
                    </h3>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
}

export default PlanSelection;
