
const model = {
    header: {
      productName: { id: 'productName', name: '', config: {} },
      plan: { id: 'properties.Plan', name: '', config: {} },
      total: { id: 'properties.Price.Total', name: 'Total Price', config: {} },
      config: {},
    },
    body: {
      items: [
        {
          id: 0,
          title: { id: 'Price Breakdown', name: 'Price Breakdown' },
          items: [
            { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
            { id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: { currency: true } },
            { id: 'properties.Price.VAT', name: 'VAT', config: { currency: true } },
            { id: 'properties.Price.Exchange Rate from USD to NPR', name: 'Exchange Rate', config: { currency: false } },
            { id: 'properties.Price.Premium in USD', name: 'Premium in USD', config: { currency: false } },

            
          ],
          config: {},
        },
        {
          id: 1,
          title: { id: 'Other Details', name: 'Policy Details' },
          items: [
            { id: 'properties.Plan', name: 'Plan', config: {} },
            { id: 'properties.Trip Type', name: 'Trip Type', config: {} },
            // { id: 'properties.Country of Origin', name: 'Country of Origin', config: {} },
            { id: 'properties.Destination', name: 'Destination', config: {} },
            { id: 'properties.Start Date', name: 'Start Date', config: {} },
            { id: 'properties.End Date', name: 'End Date', config: {} },
            { id: 'properties.Insured Type', name: 'Insured Type', config: {} },
            { id: 'properties.Adults', name: 'Adults', config: {} },
            { id: 'properties.Senior Citizen', name: 'Senior Citizen', config: {} },
            { id: 'properties.Children', name: 'Children', config: {} },
          ],
          config: {},
        },
      ],
      config: {},
    },
    config: {},
  };
  
  export default model;
  