import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const Button = styled.button`
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: ${props => props.bgColor || "palevioletred"};
  padding-top: 3px;
  border: 0px;
`;

class CircularButton extends React.Component {
  buttonClick = event => {
    const { press } = this.props;
    if (press) {
      this.props.press(event);
    }
  };

  render() {
    let { iconName, color, bgColor, id } = this.props;
    color = color ? color : "primary";
    bgColor = bgColor ? bgColor : "#D0EAFF";
    return (
      <Button
        style={{ ...this.props.buttonStyle }}
        bgColor={bgColor}
        className="mx-1"
        data-testid="circle-button"
        onClick={this.buttonClick}
        data-name={id}
      >
        <i
          style={{ ...this.props.iconStyle }}
          data-index={id}
          className={" text-" + color + " icon-" + iconName}
        />
      </Button>
    );
  }
}

CircularButton.propTypes = {
  press: PropTypes.func,
  iconName: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  id: PropTypes.string
};

export default CircularButton;
