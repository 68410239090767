import React from "react";

import {Row, Col} from "reactstrap";
import {connect} from "react-redux";

import {withStyles} from "@material-ui/core/styles";
import moment from "moment";
import TripDetails from "./component/Step2";
import PLanSelection from "./component/Step3";
import CustomerDetails from "./component/Step4";
import BeneficiaryDetails from "./component/Step5";
import Summary from "./component/Step6";
import Payment from "./component/Step7";
import {isDatePassed, utils, CONFIG_DATE_MOMENT_FORMAT, getOneYearInclusiveEndMoment, isAfter} from "utils";
import { defaultValues } from '@products/AlfalahWallet/config';
import {getQuoteActions} from "../../shared/GetQuote/actions";
import ThankYouPage from "./containers/ThankYouPage";
import {TitleComponent, FlashMessage, Stepper} from "_components";
import {getSteps, styles} from "../../shared/utils/utils";
import getGuoteGraphic from "../../../images/bannerGraphicImages/get-quote.png";
import config from '_config';
import { getMainInsuredType } from '../alfalahUtils';
const BeneficiaryBaseObj = {
  "Full Name": "",
  "ID Type": "",
  "ID Number": "",
  Email: "",
  "Contact Number": "",
  Relationship: "",
  "Same as Main": false,
  Address: "",
  Country: "",
  State: "",
  City: "",
  Postcode: "",
};

const STATE_PARAM = '?collection=state_alf';

const initialState = {
  activeStep: 0,
  productSelected: "",
  planSelected: "",
  properties: {
    "Adults": 0,
    "Children": 0,
    "Senior Citizen (66-75)": 0,
    "Senior Citizen (76-80)": 0,
    "Senior Citizen (81-85)": 0,
    "Insured Type": "",
    "Trip Type": "",
    "Source": "B2B Portal",
    "Start Date": moment().format(CONFIG_DATE_MOMENT_FORMAT),
    "End Date": moment().format(CONFIG_DATE_MOMENT_FORMAT),
    'Covid-19 Add On': 'No Add On',
  },

  OtherInsured: [],
  BeneficiaryObj: [],
  disabled: false,
  disableDomestic: false,
  disableArrival: false,
  disableSeniorField: false,
  disableAdultsField: false,
  disableChildrenField: false,
  hideSeniorField: false,
  hideChildrenField: false,
  seniorCitizenErrors: {
    anyError: false,
    errorMessage: ''
  },
  adultsErrors: {
    anyError: false,
    errorMessage: ''
  },
  childrenErrors: {
    anyError: false,
    errorMessage: ''
  },
  insuredTypeError: {
    anyError: false,
    errorMessage: ''
  },
  IDNumberError: {
    anyError: false,
    errorMessage: ''
  },
  resetIsApplied: false,
  sendingRequest: false,
  invalidForm: false,
  productsFetched: false,
};
class GetQuoteWizard extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = initialState;
    this.baseState = this.state;
  }

  componentDidMount() {
    const { item, collections, getCollections } = this.props;
    if (!collections) {
      getCollections(config.products.alfalahTravelInsurance.id, STATE_PARAM);
    } else if (item) {
      this.setState({
        activeStep: getSteps().indexOf(item.state),
        properties: item.properties,
        OtherInsured: item.properties['Other Insured'] ? item.properties['Other Insured'] : [],
      });
    } else this.setState({activeStep: 0});
  }

  getStepContent = (step, values, curentThis) => {
    switch (step) {
      case 0:
        return (
          <TripDetails
            onRef={ref => (this.child = ref)}
            curentThis={curentThis}
            isIndividualAdult={this.isIndividualAdult}
            getSeniorCitizenCount={this.getSeniorCitizenCount}
            getStates={(country) => this.props.getStates(country, '?collection=state_alf')}
          />
        );
      case 1:
        return (
          <PLanSelection
            onRef={ref => (this.child = ref)}
            curentThis={curentThis}
            values={values}
          />
        );
      case 2:
        return (
          <CustomerDetails
            onRef={ref => (this.child = ref)}
            curentThis={curentThis}
            getStates={(country) => this.props.getStates(country, '?collection=state_alf')}
          />
        );
      case 3:
        return (
          <BeneficiaryDetails
            onRef={ref => (this.child = ref)}
            curentThis={curentThis}
            getStates={(country) => this.props.getStates(country, '?collection=state_alf')}
          />
        );
      case 4:
        return (
          <Summary onRef={ref => (this.child = ref)} curentThis={curentThis}/>
        );
      case 5:
        return (
          <Payment onRef={ref => (this.child = ref)} curentThis={curentThis}/>
        );
      default:
        return <div>undefined step.</div>;
    }
  };

  getSeniorCitizenCount = () => {
    const { properties } = this.state;
    return Number(properties["Senior Citizen (66-75)"])
    + Number(properties["Senior Citizen (76-80)"])
    + Number(properties["Senior Citizen (81-85)"]);
  }

  isIndividualAdult = () => {
    const { properties } = this.state;
    return Number(properties["Senior Citizen (66-75)"]) === 0
    && Number(properties["Senior Citizen (76-80)"]) === 0
    && Number(properties["Senior Citizen (81-85)"]) === 0;
  }

  setInvalidForm = value => this.setState({ invalidForm: value });

  handleNext = async () => {
    const {item, selectedProductCode} = this.props;
    const {OtherInsured, BeneficiaryObj, activeStep, invalidForm} = this.state;
    let quotationId = item ? item.id : null;
    if ( await this.child.getAlert() && !invalidForm) {
      const priceList = item && item.properties ? item.properties.priceList : [];
      const productCode = item && item.productCode ? item.productCode : selectedProductCode;
      let otherInsured = {};
      if (OtherInsured.length || (getSteps()[activeStep] === "Insured Details")) {
        otherInsured = {"Other Insured":  OtherInsured };
      }
      let beneficiaries = BeneficiaryObj.length ? {"Beneficiaries": BeneficiaryObj} : {};
      const data = {
        productCode,
        properties: {
          ...this.state.properties,  
          Country: this.state.properties.Country || config.products.cashForHospitalization.defaultValues.country,
          ...otherInsured,
          ...beneficiaries,
          priceList: priceList,
        }
      };
      this.setState({sendingRequest: true});
      quotationId = quotationId || 0;
      const response = await this.props.updateQuotation(data, quotationId, "next");
      this.setState({sendingRequest: false});
      response.list && response.list.productCode && this.setState(state => ({
        activeStep: state.activeStep + 1,
        properties: response.list.properties,
      }));
    }
  };

  handleTripDuration = (newState) => this.setState((prevState) => ({
    ...prevState,
    properties: { ...prevState.properties, ...newState },
  }));

  handleDateChange = (params) => {
    const { properties, disableArrival } = this.state;
    let { startDate, endDate } = params;

    startDate = startDate || properties["Start Date"];
    endDate = endDate || properties["End Date"];



    let startM = moment(startDate, CONFIG_DATE_MOMENT_FORMAT);
    let endM = moment(endDate, CONFIG_DATE_MOMENT_FORMAT);

    // check if plan is annual
    if (disableArrival) {
      endM = getOneYearInclusiveEndMoment(startDate);
    }

    // check if start is before today
    const todayM = moment(new Date());
    if (isAfter(todayM._d, startM._d)) {
      startM = todayM;
    }
    // check if start is after end
    if (isAfter(startM._d, endM._d)) {
      endM = startM.clone().add(utils.constants.defaultTravelNoOfDays - 1, "days");
    }
    // use format method to remove the time form date to get the appropriate days difference
    const diffDays = moment(endM.format("L")).diff(moment(startM.format("L")), "days") + 1;

    this.forceUpdate(() => {
      this.setState({
        properties: {
          ...properties,
          "Start Date": startM.format(CONFIG_DATE_MOMENT_FORMAT),
          "End Date": endM.format(CONFIG_DATE_MOMENT_FORMAT),
          "No. of Days": diffDays,
        },
      });
    });
  };

  handleChangeStart = (startDate) => this.handleDateChange({ startDate });

  handleChangeEnd = (endDate) => this.handleDateChange({ endDate });

  handleDOB = (DOB) => {
    return this.setState({
      properties: {
        ...this.state.properties,
        DOB: DOB
      }
    });
  };

  handleIssuanceDate = (date) => {
    return this.setState({
      properties: {
        ...this.state.properties,
        "CNIC Issuance Date": date,
      }
    });
  }

  isQuotationEditable = (quote, activeStep) => {
    const steps = getSteps();
    const quoteState = quote && quote.state;
    return quoteState === "Completed" ? activeStep === steps.length : true;
  };

  isQuotationExpired = (quote) => {
    return quote && isDatePassed(quote.properties["Start Date"]);
  };

  handleInputChange = async (e) => {
    const { properties, invalidForm } = this.state;
    const { getStates } = this.props;
    const { id, name } = e.target;
    let { value } = e.target;
    const newPropertiesDict = {};
    Object.keys(properties).forEach((key) => {
      if (properties.hasOwnProperty(key)) {
        newPropertiesDict[key] = properties[key];
      }
    });
    if (id === "ID Type") {
      newPropertiesDict["ID Number"] = "";
    }


    // if (value && id === 'Postcode') {
    //   value = value.length > defaultValues.postcodeLength
    //     ? value.slice(0, defaultValues.postcodeLength) : value;
    // }

    newPropertiesDict[id] = value;
    await this.setState({
      properties: newPropertiesDict,
    }, () => {
      if (['Insured Type', 'Children', 'Adults', 'Senior Citizen (66-75)',
        'Senior Citizen (76-80)', 'Senior Citizen (81-85)'].includes(id)) {
        this.handleMainInsuredTypeChange();
      }
    });

    /**
     * check if the input is country and then, send a request for getting states
     */
    if (name === "Country") {
      await getStates(value);
    }
  };

  handleMainInsuredTypeChange = () => {
    this.setState((prevState) => ({
      ...prevState,
      properties: {
        ...prevState.properties,
        'Main Policyholder Type': getMainInsuredType(prevState.properties),
        DOB: '',
      },
    }));
  }

  updateOtherInsuredDate = (id, e, dateKey) => {
    const { OtherInsured } = this.state;
    let updatedObj = {};
    const objIndex = OtherInsured.findIndex((obj) => obj.id === id);
    const key = dateKey === "dob" ? "DOB" : "CNIC Issuance Date";
    const value = utils.lib.applyConfigFormat(e);
    updatedObj = { ...OtherInsured[objIndex], [key]: value };

    const updatedData = [
      ...OtherInsured.slice(0, objIndex),
      updatedObj,
      ...OtherInsured.slice(objIndex + 1),
    ];
    return this.setState({
      OtherInsured: updatedData,
    });
  }

  updateOtherInsured = async (id, e, genderOrDateKey) => {
    const { OtherInsured } = this.state;
    let updatedObj = {};
    const objIndex = OtherInsured.findIndex((obj) => obj.id === id);
    if (e.target.id === "CNIC Number") {
      updatedObj = {
        ...OtherInsured[objIndex],
        [e.target.id]: e.target.value,
        Gender: genderOrDateKey,
      };
    } else {
      updatedObj = {
        ...OtherInsured[objIndex],
        [e.target.id]: e.target.value,
      };
    }
    // make new object of updated object.
    // make final new array of objects by combining updated object.
    const updatedData = [
      ...OtherInsured.slice(0, objIndex),
      updatedObj,
      ...OtherInsured.slice(objIndex + 1),
    ];
    return this.setState({
      OtherInsured: updatedData,
    });
  };

  // updateDOBForAllOtherInsured = () => {
  //   const { OtherInsured } = this.state;
  //   if (OtherInsured && OtherInsured.length > 0) {
  //     const updatedList = [];
  //     OtherInsured.forEach((other) => {
  //       const updated = { ...other, DOB: '' };
  //       updatedList.push(updated);
  //     });
  //     return updatedList;
  //   }
  // }

  updateBeneficiaryDetails = async (id, e) => {
    const {BeneficiaryObj} = this.state;
    const { getStates } = this.props;

    let updatedObj = {};

    const objIndex = BeneficiaryObj.findIndex(obj => obj.id === id);
    if (e.target.id === "Same as Main" && e.target.value === "false") {
      updatedObj = {
        ...BeneficiaryObj[objIndex],
        ...BeneficiaryBaseObj,
        id: BeneficiaryObj[objIndex].id
      };
    }
    // else if (e.target.id === "ID Type") {
    //   updatedObj = {
    //     ...BeneficiaryObj[objIndex],
    //     [e.target.id]: e.target.value,
    //     "ID Number": "",
    //   };
    // }
    else if (e.target.id === "Same as Main") {
      updatedObj = {
        ...BeneficiaryObj[objIndex],
        ...BeneficiaryObj[0],
        [e.target.id]: true,
        id: BeneficiaryObj[objIndex].id
      };
    } else {
      updatedObj = {
        ...BeneficiaryObj[objIndex],
        [e.target.id]: e.target.value,
      };
    }

    // make new object of updated object.

    // // make final new array of objects by combining updated object.
    const updatedData = [
      ...BeneficiaryObj.slice(0, objIndex),
      updatedObj,
      ...BeneficiaryObj.slice(objIndex + 1)
    ];
    this.setState({
      BeneficiaryObj: updatedData
    });

    if (e.target.id === "Country") {
      await getStates(e.target.value, '?collection=state_alf');
    }
  };

  updatedPlanSelected = (key) => {
    const {sendingRequest} = this.state;
    !sendingRequest && this.setState({
      planSelected: key,
      disabled: false,
      properties: {...this.state.properties, Price: key}
    }, () => this.handleNext());
  };

  handleBack = async () => {
    const {item: {productCode, id: quotationId}} = this.props;
    const data = {productCode};
    this.setState({sendingRequest: true});
    await this.props.updateQuotation(data, quotationId, "prev");
    this.setState({sendingRequest: false});
    if (getSteps()[this.state.activeStep - 1] === "Select Plan") this.setState({planSelected: ""});
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
    if (this.state.activeStep !== 1) {
      this.setState({
        disabled: false
      });
    }
  };

  handleReset = async () => {
    const {history, resetQuotation} = this.props;
    await resetQuotation();
    history.push('/getQuote');
  };

  addAdult = (array, count, baseObj, beneficiary = false) => {
    for (let i = 0; i < count; i += 1) {
      const item = {
        ...baseObj,
        id: `Adult ${beneficiary ? (i + 1) : (i + 2)}`,
      };
      if (!beneficiary) item.Type = "Adult";

      array.push({
        ...item,
      });
    }
  }

  addSeniorCitizen = (array, count, baseObj, subId, beneficiary = false) => {
    for (let i = 0; i < count; i += 1) {
      const item = {
        ...baseObj,
        id: `${subId} ${(i + 1)}`,
      };
      if (!beneficiary) item.Type = String(subId).replace(/\(/g, '').replace(/\)/g, '');

      array.push({
        ...item,
      });
    }
  }

  addChildren = (array, count, baseObj, beneficiary = false) => {
    for (let i = 0; i < count; i += 1) {
      const item = {
        ...baseObj,
        id: `Child ${(i + 1)}`,
      };
      if (!beneficiary) item.Type = "Child";
      array.push({
        ...item,
      });
    }
  }

  createOtherInsuredObj() {
    const array = [];
    const baseObj = {
      "Full Name": "",
      Nationality: "",
      "CNIC Number": "",
      "CNIC Issuance Date": "",
      "Passport Number": "",
      DOB: "",
      Gender: "",
    };
    const { properties } = this.state;
    const adultCount = parseInt(properties.Adults, 10);
    const childrenCount = parseInt(properties.Children, 10);
    const srCitizen66Count = parseInt(properties["Senior Citizen (66-75)"], 10);
    const srCitizen76Count = parseInt(properties["Senior Citizen (76-80)"], 10);
    const srCitizen81Count = parseInt(properties["Senior Citizen (81-85)"], 10);
    /**
     * Check if there is any adult,
     * If none, make other insured from srCitizen removing one who is considered Main insurer
     */
    if (!adultCount) {
      this.addSeniorCitizen(array, srCitizen66Count - 1, baseObj, "Senior Citizen (66-75)");
      this.addSeniorCitizen(array,
        srCitizen66Count > 0
          ? srCitizen76Count : srCitizen76Count - 1,
        baseObj, "Senior Citizen (76-80)");
      this.addSeniorCitizen(array,
        srCitizen66Count + srCitizen76Count > 0
          ? srCitizen81Count : srCitizen81Count - 1,
        baseObj, "Senior Citizen (81-85)");
    } else {
      this.addAdult(array, adultCount - 1, baseObj);
      this.addSeniorCitizen(array, srCitizen66Count, baseObj, "Senior Citizen (66-75)");
      this.addSeniorCitizen(array, srCitizen76Count, baseObj, "Senior Citizen (76-80)");
      this.addSeniorCitizen(array, srCitizen81Count, baseObj, "Senior Citizen (81-85)");
    }
    this.addChildren(array, childrenCount, baseObj);
    this.setState({ OtherInsured: array });
  }

  async populateBeneficiaryObj() {
    const Beneficiaries = this.state.properties && this.state.properties.Beneficiaries;
    const { 'Other Insured': otherInsured = [] } = this.state.properties;
    if (Beneficiaries && Beneficiaries.length && Beneficiaries.length - 1 === otherInsured.length) {
      await this.setState({ BeneficiaryObj: [...Beneficiaries] });
    } else {
      const array = [];
      const { properties } = this.state;
      const adultCount = parseInt(properties.Adults, 10);
      const childrenCount = parseInt(properties.Children, 10);
      const srCitizen66Count = parseInt(properties["Senior Citizen (66-75)"], 10);
      const srCitizen76Count = parseInt(properties["Senior Citizen (76-80)"], 10);
      const srCitizen81Count = parseInt(properties["Senior Citizen (81-85)"], 10);
  
      const isBeneficiary = true;
      this.addAdult(array, adultCount, BeneficiaryBaseObj, isBeneficiary);
      this.addSeniorCitizen(array, srCitizen66Count, BeneficiaryBaseObj, "Senior Citizen (66-75)", isBeneficiary);
      this.addSeniorCitizen(array, srCitizen76Count, BeneficiaryBaseObj, "Senior Citizen (76-80)", isBeneficiary);
      this.addSeniorCitizen(array, srCitizen81Count, BeneficiaryBaseObj, "Senior Citizen (81-85)", isBeneficiary);
      this.addChildren(array, childrenCount, BeneficiaryBaseObj, isBeneficiary);
  
      await this.setState({ BeneficiaryObj: array });
    }
  }

  disableNext = () => {
    const { invalidForm, insuredTypeError, adultsErrors, childrenErrors, seniorCitizenErrors } = this.state;
    return invalidForm ||
      insuredTypeError.anyError ||
      adultsErrors.anyError ||
      childrenErrors.anyError ||
      seniorCitizenErrors.anyError;
  };

  render() {
    const {classes, item, products, productsFetched, flashMessage, collections} = this.props;
    const {
      activeStep, productSelected,
      planSelected, properties, sendingRequest, invalidForm,
    } = this.state;
    const values = {productSelected, planSelected};
    const steps = getSteps();
    let shouldSkipOnLastStep;
    if ((activeStep + 1 === steps.length - 1)
      && !(properties && (properties["Payment Mode"] || properties["Receipt No"] || properties["CC Email"] || properties["BCC Email"]))) {
      shouldSkipOnLastStep = true;
    }
    let showNext = true;
    if (steps[activeStep] === "Select Plan" || steps[activeStep] === "Product Selection") {
      showNext = false;
    }

    /**
     * display proper message if fetching products returned an empty collection
     * @const productsFetched
     * */

    if (productsFetched && !products.length) return (<FlashMessage flashMessage="There are no products."/>);
    if (flashMessage !== "") return (<FlashMessage flashMessage={flashMessage} />);
    if (activeStep === null || collections === null) return (<FlashMessage flashMessage="Please wait..." />);

    // if(this.isQuotationEditable(item, activeStep) === false)
    // return(<FlashMessage flashMessage="This Quotation is completed and cannot be edited."/>);
    if (this.isQuotationExpired(item)) return (<FlashMessage flashMessage="This quotation is outdated and cannot be edited." />);
    return (
      <div className="container-fluid px-0">
        <TitleComponent
          title="Get Quote"
          bannerGraphic={getGuoteGraphic}
        />
        <div className="row d-flex justify-content-center my_50">
          <div className="col-10">
            <div className={classes.root}>
              <Stepper
                  steps = {utils.products.travelInsurance.steps}
                  activeStep={activeStep} 
                  classes={classes} 
              />
              <>
                {steps[activeStep] === "Completed" ? (
                  <>
                    <ThankYouPage certificateId={item ? item.policyRefCode : null}
                                  handleReset={this.handleReset.bind(this)}/>
                  </>
                ) : (
                  <>
                    <div className={classes.instructions}>
                      {this.getStepContent(activeStep, values, this)}
                    </div>
                    <Row className="text-right justify-content-end">
                      <Col sm="2">
                        {activeStep > 0 && getSteps()[activeStep] !== "Payment Details" && (
                          <button
                            className="btn btn-light col-12 my-2"
                            onClick={this.handleBack}
                            disabled={sendingRequest}
                          >
                            Previous
                          </button>
                        )}
                      </Col>
                      {
                        showNext && <Col sm="2">
                          <button
                            className="btn btn-primary col-12 my-2 rounded-xl"
                            onClick={this.handleNext}
                            // disabled={disabled || sendingRequest}
                            disabled={sendingRequest || this.disableNext()}
                          >
                            {shouldSkipOnLastStep ? "Skip" : "Next"}
                          </button>
                        </Col>
                      }
                    </Row>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GetQuoteWizard.propTypes = {
  // classes: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
  updateQuotation: (data, id, direction) => dispatch(getQuoteActions.updateQuotation(data, id, direction)),
  getStates: (country, param) => dispatch(getQuoteActions.getStates(country, param)),
  resetQuotation: () => dispatch(getQuoteActions.resetQuotation()),
  
  checkEditPermission: () => dispatch(getQuoteActions.checkEditPermission()),
  checkGetQuotePermission: () => dispatch(getQuoteActions.checkGetQuotePermission()),
  // createQuotation: (data) => dispatch(getQuoteActions.createQuotation(data)),
  getQuotation: (quotationId) => dispatch(getQuoteActions.getQuotation(quotationId)),
  getProducts: () => dispatch(getQuoteActions.getProducts()),

  selectProduct: (product) => dispatch(getQuoteActions.selectProduct(product)),
  getCollections: (productCode, stateParam) => dispatch(getQuoteActions.getCollections(productCode, stateParam)),
});

function mapStateToProps(state) {
  const {item, collections, products, productsFetched, flashMessage, selectedProductCode} = state.getQuote;
  const { product } = state.newGetQuote;
  return {
    item,
    collections,
    productsFetched,
    products,
    selectedProductCode: selectedProductCode || product.id,
    flashMessage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GetQuoteWizard));
