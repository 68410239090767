const noAddOnCountries = ['Andorra', 'Argentina', 'Aruba', 'Bahrain', 'Brazil', 'Costa Rica', 'Czechia', 'Czech Republic', 'France', 'French Polynesia', 'Guam', 'Israel', 'Kuwait', 'Maldives', 'Montenegro', 'Occupied Palestinian territory', 'Panama', 'Peru', 'Puerto Rico', 'Saint Martin', 'Spain', 'Serbia and Montenegro'];

const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    total: { id: 'properties.Price.Total', name: 'Total Price', config: {} },
    config: {},
  },
  body: {
    items: [
      {
        id: 0,
        title: { id: 'Price Breakdown', name: 'Price Breakdown' },
        items: [
          { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
          { id: 'properties.Price.Admin Surcharge', name: 'Admin Surcharge', config: { currency: true } },
          { id: 'properties.Price.Federal Excise Duty', name: 'Federal Excise Duty', config: { currency: true } },
          { id: 'properties.Price.Fixed Insurance Fee', name: 'Fixed Insurance Fee', config: { currency: true } },
          { id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: { currency: true } },
          { id: 'properties.Price.Withholding Tax', name: 'Withholding Tax', config: { currency: true } },
          // { id: 'properties.Price.COVID-19 Add On', name: 'Covid-19 Add-On', config: { currency: true } },
        ],
        config: {},
      },
      {
        id: 1,
        title: { id: 'Other Details', name: 'Other Details' },
        items: [
          { id: 'properties.Plan', name: 'Plan Type', config: {} },
          { id: 'properties.Destination', name: 'Destination', config: {} },
          { id: 'properties.Trip Type', name: 'Trip Type', config: {} },
          { id: 'properties.destinationCountry', name: 'Country', config: {} },
          { id: 'properties.Start Date', name: 'Start Date', config: {} },
          { id: 'properties.End Date', name: 'End Date', config: {} },
          { id: 'properties.Insured Type', name: 'Insured Type', config: {} },
          { id: 'properties.Adults', name: 'Adults', config: {} },
          { id: 'properties.Children', name: 'Children', config: {} },
          { id: 'properties.Senior Citizen (66-75)', name: 'Senior Citizen (66-75)', config: {} },
          { id: 'properties.Senior Citizen (76-80)', name: 'Senior Citizen (76-80)', config: {} },
          { id: 'properties.Senior Citizen (81-85)', name: 'Senior Citizen (81-85)', config: {} },
        ],
        config: {},
      },
      {
        id: 0,
        title: { id: 'COVID-19 Add On Details', name: 'COVID-19 Add On Details' },
        items: [
          { id: 'properties.Covid-19 Add On', name: 'Type', config: {} },
          { id: 'properties.Add On Start Date', name: 'Start Date', config: {} },
          { id: 'properties.Add On End Date', name: 'End Date', config: {} },
        ],
        config: {},
      },
    ],
  }
};

export default model;
