import React, { Component } from "react";
import { createFilterParams} from "utils"
import { resetCheckedFieldsCount } from "_actions";
import { Col } from "reactstrap";
import Input from "react-toolbox/lib/input";
import ExportButton from "_components/quotation/ExportButton";

class Filterable extends Component {

  handleChange = (name, value) => {
    const {filterOptions} = this.props;
    let pagination = {};
    if (name === "hint") pagination = {pagination: {page: 0, perPage: 10}};
    this.setState({
      ...this.state,
      ...pagination,
      [name]: value,
    }, () => {
      this.handleFilter(filterOptions);
    });
  };

  createArray = (param, arrayValue) => {
    if (!arrayValue) return "";
    return arrayValue.map((value) => (param + "=" + value)).join("&");
  };

  makeStringParams = (filterOptions) => {
    let stringParameter="";
    const {hint, status, pagination: {page, perPage} } = this.state;
    const { isFlatView } = this.props;
    const filterStringParams = createFilterParams(filterOptions);
    const nameStringParams = this.createArray('name', [hint]);
    let statusStringParams = "";
    if (status) statusStringParams = this.createArray('status', status);
    if (hint && hint !== "") stringParameter += nameStringParams;
    if (status && status[0] !== "") stringParameter += `&${statusStringParams}`;

    if (filterStringParams !== "") stringParameter += `&${filterStringParams}`;
    stringParameter += `&page=${page}&pageSize=${perPage}`;

    /** for all the status other than the empty one the flatView is true */
    stringParameter += `&isFlatView=${status.join() ? true : isFlatView}`;
    /**
     * make sure there is no "&" at the beginning of the stringParameters
     */
    while (stringParameter[0] === "&") stringParameter = stringParameter.slice(1);
    return stringParameter;
  };

  handleFilter = (filterOptions, resetPage = false) => {
    const { applyFilters } = this.props;
    /** when it comes from the filter component itself, the page should be reset to 0 */
    this.setState((prevState) => (
      {
        pagination: {
          ...prevState.pagination,
          page: resetPage ? 0 : prevState.pagination.page,
        },
      }
    ), () => {
      const stringParams = this.makeStringParams(filterOptions);
      applyFilters({
        stringParams,
        filterObject: filterOptions,
      });
    });

  };

  handlePagination = (page, perPage) => {
    const pageChanged = this.state.pagination.page !== page;
    this.setState({pagination: {page, perPage}, pageChanged}, () => {
      const {filterOptions} = this.props;
      this.handleFilter(filterOptions);
    });
  };

  clearFilter = () => {
    resetCheckedFieldsCount(); // it seems there is need to have a new action here!!!
    const { filterOptions } = this.props;
    if(filterOptions && filterOptions.length > 0) {
      let copyOfArray = [...filterOptions];
      copyOfArray.forEach(option => {
        option.checkedCount = 0;
        option.list.forEach(type => {
          type.isChecked = false;
        })
      })
      this.handleFilter(copyOfArray);
    }
  };

  setDateRange = ({startDate, endDate}) => {
    const {history: {location: {state}, replace}} = this.props;
    state && replace({state: undefined});
    this.setState({dateRange: {startDate, endDate}}, () => {
      const {filterOptions} = this.props;
      this.handleFilter(filterOptions);
    });
  };

  searchBar = () => {
    return (
      <div className="additionalTableHeader overflow-unset align-items-center py-1 d-flex px-1">
        <Col xs="8">
          <Input
            className="py-2 customSearchinput"
            type="text"
            label="Search"
            icon={<i className="icon-search" />}
            value={this.state.hint}
            onChange={this.handleChange.bind(this, "hint")}
          />
        </Col>
        <Col xs="4" className="text-right">
          <ExportButton exportToCsv={this.handleExport.bind(this)} />
        </Col>
      </div>
    )
  }
}

export {Filterable};