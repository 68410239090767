import moment from 'moment';
import { CONFIG_DATE_MOMENT_FORMAT } from '../../../utils';

const minAge = {
  years: 0,
  months: 0,
  days: 29,
};
const maxAge = {
  years: 80,
  months: 0,
  days: 364,
};


const properties : IObject = {

};

const country = '';

const insuredType = '';

const stateListParam = '';

const cnicLength = 13;

const postcodeLength = 5;

const debitCreditNoLength = 16;

const defaults = {
  minAge,
  maxAge,
  country,
  stateListParam,
  cnicLength,
  postcodeLength,
  debitCreditNoLength,
  properties,
  insuredType,
};

export default defaults;
