import {userConstants} from '../constants';
import {userService} from '../services';
import Notification from 'react-notification-system-redux';
import {getNotificationMessage} from "../../../_services";

export const userActions = {
  login,
  logout,
};

function login(username, password) {
  return async dispatch => {
    dispatch(request({
      username
    }));

    const user = await userService.login(username, password);
    if (user.error && user.error.message === "server error") {
      dispatch(Notification.error(getNotificationMessage("An error occurred, please try again later")));
      return dispatch(failure(user.message));
    } else if (user.error) {
      return dispatch(failure(user.message));
    }
    return dispatch(success(user));
  };

  function request(user) {
    return {
      type: userConstants.LOGIN_REQUEST,
      user
    }
  }

  function success(user) {
    return {
      type: userConstants.LOGIN_SUCCESS,
      user
    }
  }

  function failure(error) {
    return {
      type: userConstants.LOGIN_FAILURE,
      error
    }
  }
}

function logout() {
  userService.logout();
  return {
    type: userConstants.LOGOUT
  };
}