import React from "react";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";

const colourStyles = {
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: "none",
      border: "1px solid #2B61B4",
      borderRadius: "4px"
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: "#2B61B4"
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: "#2B61B4",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "none",
      color: "red"
    }
  })
};

export default class MultiSelect extends React.Component {

  render() {
    const { options, inputValue,value} = this.props;
    return (
      <FormGroup>
        <Label for={"userGrp"}>User Group(s)</Label>
        <Select
          value={value}
          isMulti
          name="userGrp"
          onChange={this.props.handleChange}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={colourStyles}
          inputValue={inputValue}
          onInputChange={this.props.onInputChange}
          // value={options.find(o => o.value === this.state.value)}
          components={makeAnimated()}
          theme={theme => ({
            ...theme,
            borderRadius: 4,
            colors: {
              ...theme.colors,
              // primary25: '#2B61B4',
              primary: "#2B61B4"
            }
          })}
        />
        {this.props.validator.message("select", value === '' ? null : value, 'required')}
      </FormGroup>
    );
  }
}
