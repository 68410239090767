import React from 'react';
import lib from 'utils/lib';

const SelectPlanDetailTable = () => {
    return (
        <div className="benefit-table-2">
            <p>BENEFITS SCHEDULE {lib.checkIsDemoSite() && '/ អត្ថប្រយោជន៍ CB SMART TRAVELER'}</p>
            <table>
                <thead className="blue-header">
                <tr>
                    <th colSpan={2}>COVERAGES{lib.checkIsDemoSite() && ' / វិសាលភាពធានារ៉ាប់រង'}</th>
                    <th>Bronze</th>
                    <th>Silver</th>
                    <th>Gold</th>
                </tr>
                </thead>
                <tbody>
                <tr className="mainField">
                    <td colSpan={2}>ANNUAL LIMIT MAXIMUM{lib.checkIsDemoSite() && ' / កំរិតអតិបរិមាប្រចាំឆ្នាំ'}</td>
                    <td className="fixPrice">$50,000</td>
                    <td className="fixPrice">$100,000</td>
                    <td className="fixPrice">$150,000</td>
                </tr>
                <tr className="seprateField">
                    <td colSpan={5}>Personal Legal Liability Benefit{lib.checkIsDemoSite() && ' / ភារះទទួលខុសត្រួវតាមផ្លូវច្បាប់'}</td>
                </tr>
                <tr>
                    <td className="center">1</td>
                    <td>Personal Legal Liability {lib.checkIsDemoSite() && '/ ភារះទទួលខុសត្រួវតាមផ្លូវច្បាប់'}</td>
                    <td className="fixPrice">$250,000</td>
                    <td className="fixPrice">$500,000</td>
                    <td className="fixPrice">$750,000</td>
                </tr>
                <tr className="seprateField">
                    <td colSpan={5}>Emergency Medical and Evacuation Benefits {lib.checkIsDemoSite() && '/ ការព្យាបាលជម្ងឺ និងការបញ្ជូនទៅកាន់ទីតាំងសុវត្ថិភាពជាបន្ទាន់'}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={5}>Deductibles: 25 USD each claim under 2, 7, 9, 12 {lib.checkIsDemoSite() && '/ កម្រិតមិនធ្វើសំណង:២៥​ដុល្លានៃការទាមទារសំណងក្នុងមួយករណី នៅក្រោមចំណុចលេខ ២ ៧​ ៩ ១២'}</td>
                </tr>
                </tfoot>
            </table>
        </div>);
}

export default SelectPlanDetailTable;