import React, {Fragment} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Endpoint from "./singleEndpointDocumentation";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

function TabContainer(props) {
  return (
    <Typography component="div" style={{padding: 8 * 3}}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
/**
 * @let endpointId
 * this is just for being able to to maintain Accordion's state(open/close)
 * even when we navigate to other tabs
 * it is made up by {endpoint.host}-{endpoint.path}-{index: {the index of the endpoint on the resource(ex:priceEndpoints)}}
 * @const tabsState: this is an Obj that holds the value for all the tabs whether open or close
 *
 */
class TabSection extends React.Component{
  state = {
    currentTab: 0,
    policyEndpoints: [],
    quotationEndpoints: [],
    priceEndpoints: [],
    tabsState: {},
  };
  pricesTableHeading = [
    {id: 1, headerName: "Name", key: "Name"},
    {id: 2, headerName: "Type", key: "DataType"},
    {id: 3, headerName: "Possible values", key: "PossibleValues"},
  ];
  quoteTableHeading = [
    {id: 1, headerName: "Name", key: "name"},
    {id: 2, headerName: "Type", key: "dataType"},
    {id: 3, headerName: "Description", key: "description"},
    {id: 4, headerName: "Required", key: "required"},
  ];
  policyTableHeading = [
    {id: 1, headerName: "Name", key: "name"},
    {id: 2, headerName: "Type", key: "type"},
    {id: 3, headerName: "Description", key: "objectDescription"},
  ];
  componentDidMount() {
    const {products} = this.props;
    let policyHost=null, priceHost=null, quoteHost=null;
    products.forEach(product => {
      /**
       *  Set the host variables as long as they are not set
       *  and current product has the expected endpoint (policyEndpoint, priceEndpoint, quoteEndpoint)
       **/
      if (!policyHost && product.policyEndpoint) policyHost = product.policyEndpoint.host;
      if (!priceHost && product.priceEndpoint) priceHost = product.priceEndpoint.host;
      if (!quoteHost && product.quoteEndpoint) quoteHost = product.quoteEndpoint.host;
      this.setState(prevState => ({
        policyEndpoints: [...prevState.policyEndpoints, product.policyEndpoint],
        quotationEndpoints: [...prevState.quotationEndpoints, product.quoteEndpoint],
        priceEndpoints: [...prevState.priceEndpoints, product.priceEndpoint],
      }));
    });
    this.setState({policyHost, priceHost,quoteHost,});
  }
  changeTabsState = (changeTabPayload) => {
    this.setState(prevSate => ({tabsState: {...prevSate.tabsState, ...changeTabPayload}}));
  };
  render() {
    const {currentTab, priceEndpoints,policyHost,
      priceHost, quoteHost, tabsState, quotationEndpoints, policyEndpoints
    } = this.state;
    return (
      <Fragment>
        <div className="border-bottom mt-5">
          <Tabs
            value={currentTab}
            onChange={(e, newTab) => {
              this.setState({currentTab: newTab})
            }}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Get Price"/>
            <Tab label="Get Quotation"/>
            <Tab label="Create Policy"/>
          </Tabs>
        </div>
        <div>
          {currentTab === 0 && (
            <TabContainer>
              <div className="text-dark">
                <p className="alert-dark p-3 rounded-xl text-info">Get Price:
                  <a href={`${priceHost}`}>{priceHost}</a>
                </p>
              </div>
              {/*==========================TABLES_UI=====================================*/}
              {priceEndpoints.map((endpoint, index) => {
                if (!endpoint) return null;
                let endpointId = `${endpoint.host}-${endpoint.path}-${index}`;
                return (<Fragment key={index}>
                  <Endpoint
                    host={endpoint.host}
                    path={endpoint.path}
                    method={endpoint.method}
                    properties={endpoint.productProperties}
                    propertiesHeading={this.pricesTableHeading}
                    requestObject={endpoint.requestObject}
                    responses={endpoint.responses}
                    endpointId={endpointId}
                    open={tabsState[endpointId]}
                    openState={{tabsState, changeTabsState: this.changeTabsState}}
                  />
                </Fragment>);
              })}
            </TabContainer>
          )}
          {currentTab === 1 && (
            <TabContainer>
              <div className="text-dark">
                <p className="alert-dark p-3 rounded-xl text-info">Get Quotation:
                  <a href={`${quoteHost}`}>{quoteHost}</a>
                </p>
              </div>
              {/*==========================TABLES_UI=====================================*/}
              {quotationEndpoints.map((endpoint, index) => {
                if (!endpoint) return null;
                let endpointId = `${endpoint.host}-${endpoint.path}-${index}`;
                return (<Fragment key={index}>
                  <Endpoint
                    host={endpoint.host}
                    path={endpoint.path}
                    method={endpoint.method}
                    properties={endpoint.quoteProperties}
                    propertiesHeading={this.quoteTableHeading}
                    requestObject={endpoint.requestObject}
                    responses={endpoint.responses}
                    endpointId={endpointId}
                    open={tabsState[endpointId]}
                    openState={{tabsState, changeTabsState: this.changeTabsState}}
                  />
                </Fragment>);
              })}
            </TabContainer>
          )}
          {currentTab === 2 && (
            <TabContainer>
              <div className="text-dark">
                <p className="alert-dark p-3 rounded-xl text-info">Create Policy:
                  <a href={`${policyHost}`}>{policyHost}</a>
                </p>
              </div>
              {policyEndpoints.map((endpoint, index) => {
                if (!endpoint) return null;
                let endpointId = `${endpoint.host}-${endpoint.path}-${index}`;
                return (<Fragment key={index}>
                  <Endpoint
                    host={endpoint.host}
                    path={endpoint.path}
                    method={endpoint.method}
                    properties={endpoint.policyProperties}
                    propertiesHeading={this.policyTableHeading}
                    requestObject={endpoint.requestObject}
                    responses={endpoint.responses}
                    endpointId={endpointId}
                    open={tabsState[endpointId]}
                    openState={{tabsState, changeTabsState: this.changeTabsState}}
                  />
                </Fragment>);
              })}
            </TabContainer>
          )}
        </div>
      </Fragment>
    )
  }
}
export default TabSection;