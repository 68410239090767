import React from 'react';
import {connect} from 'react-redux';
import dashboardAction from './actions';
import {Container, Col, Row} from 'reactstrap';
import { actions } from '@redux';
import Card from "@material-ui/core/Card";
import {LineChart, Line, XAxis, YAxis, CartesianGrid} from 'recharts';
import {TitleComponent} from '_components';
import {quotationActions} from "Products/shared/quotationListing/actions";
import {policyActions} from "Products/shared/policyListing/actions";
import * as moment from "moment";
import {formatCurrency, config, utils} from 'utils';
// import _config from "_config";

const newQuotationAction = new actions.QuotationActions();
const newPolicyAction = new actions.PolicyActions();
const DATEFORMAT = "DD/MM/YYYY";

class DashboardComponent extends React.Component {

  handleTodayQuotations = async () => {
    const {
      setApplyTodayQuotationFilter,
      setCurrentQuotationProductToAll,
      history,
      getQuotationProducts,
      quotationProducts,
      newSetQuotationTodayFilter,
    } = this.props;

    newSetQuotationTodayFilter();

    const targetUrl = this.prepareTargetUrl("quotations");

    setApplyTodayQuotationFilter();
    if (quotationProducts && quotationProducts.length > 0) {
      setCurrentQuotationProductToAll();
      history.push(targetUrl);
    } else {
      await getQuotationProducts();
      setCurrentQuotationProductToAll();
      history.push(targetUrl);
    }
  };

  handleTodayCertificates = async () => {
    const {
      setApplyTodayPolicyFilter,
      setCurrentPolicyProductToAll,
      history,
      getPolicyProducts,
      policyProducts,
      newSetPolicyTodayFilter,
    } = this.props;

    newSetPolicyTodayFilter();

    const targetUrl = this.prepareTargetUrl("policies");

    setApplyTodayPolicyFilter();
    if (policyProducts && policyProducts.length > 0){
      setCurrentPolicyProductToAll();
      history.push(targetUrl);
    } else {
      await getPolicyProducts();
      setCurrentPolicyProductToAll();
      history.push(targetUrl);
    }

  };

  prepareTargetUrl = (targetModule) => {

    const today = moment(utils.lib.getCurrentDateTimeZone()).format(DATEFORMAT);
    return {
      pathname: `/${targetModule}`,
      state: {
        dates: { startDate: today, endDate: today }
      },
    };
  };

  prepareChartData = (data) => {
    const prepared = data
    .map(d => ({ y: d.y, x: new Date(d.x) }))
    .sort((a, b) => a.x - b.x)
    .map(d => ({ y: d.y, x: moment(d.x).format('MMM') }));
  
    return prepared;
  }

  render() {
    const { data } = this.props;
    const chartData = this.prepareChartData(data && data.chartData ? data.chartData : []);
    return (
      <Container id="dashboard">
        <Row>
          <Col><TitleComponent title="Dashboard" noBorderBottom={true}/></Col>
        </Row>
        <div className="cards">
          <Row>
            <Col sm={12} md={4}>
              <Card className="card">
                <div className="header">Today's Quotations</div>
                <div className="content">
                  {data ? data.quotations : ''}
                  <div className="icon-box" onClick={this.handleTodayQuotations}><i className="icon-rightArrow font-size-small"></i></div>
                </div>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="card">
                <div className="header">Today's Certificates</div>
                <div className="content">
                  {data ? data.policies : ''}
                  <div className="icon-box" onClick={this.handleTodayCertificates}><i className="icon-rightArrow font-size-small"></i></div>
                </div>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="card">
                <div className="header">Today's Sales</div>
                <div className="content">
                  <span>{config.currencySymbol}</span>
                  {data && data.sales ? formatCurrency(data.sales) : 0}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="chart-container">
          <Row>
            <Col>
              <Card className="card">
                <div className="header">Total Certificates</div>
                {chartData ? <div className="chart">
                  <LineChart style={{width: "100%", height: "auto"}}
                             width={800} height={200} data={chartData}
                             margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Line type="monotone" dataKey="y" stroke="#2FA1FF" fillOpacity={1} fill="#2FA1FF"/>
                    <XAxis dataKey="x"/>
                    <YAxis/>
                  </LineChart>
                </div> : <></>}
              </Card>
            </Col>
          </Row>
        </div>
        {/* ****************************Release version******************************* */}
        <aside className="release_number d-flex justify-content-end">
          <div>Version {config.version}</div>
        </aside>
      </Container>
    );
  } //end of render

  componentDidMount() {
    this.props.getDashboard();
  } //end of componentDidMount

} // end of DashboardComponent

const mapStateToProps = (state) => {
  const { data } = state.dashboard;
  const { products: quotationProducts } = state.quotationManagement;
  const { products: policyProducts } = state.policyManagement;
  return { quotationProducts, policyProducts, data };
}; // end of map state to props

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboard: () => dispatch(dashboardAction.getDashboard()),
    setApplyTodayQuotationFilter: () => dispatch(quotationActions.setApplyTodayQuotationFilter()),
    changeQuotationListProduct: (productCode) => (
      dispatch(quotationActions.changeProduct(productCode))
    ),
    getQuotationProducts: () => dispatch(quotationActions.getProducts()),
    applyTodayPoliciesFilter: (filterObj) => (
      dispatch(policyActions.applyTodayPoliciesFilter(filterObj))
    ),
    changePolicyListProduct: (productCode) => dispatch(policyActions.changeProduct(productCode)),
    setCurrentQuotationProductToAll: () => dispatch(quotationActions.setCurrentProductToAll()),
    setApplyTodayPolicyFilter: () => dispatch(policyActions.setApplyTodayPolicyFilter()),
    setCurrentPolicyProductToAll: () => dispatch(policyActions.setCurrentProductToAll()),
    getPolicyProducts: () => dispatch(policyActions.getProducts()),
    newSetQuotationTodayFilter: () => dispatch(newQuotationAction.setTodayFilter()),
    newSetPolicyTodayFilter: () => dispatch(newPolicyAction.setTodayFilter()),
  };
}; // end of map dispatch to props

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);