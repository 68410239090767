import React, { Component } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import { ModalUi, TitleComponent } from "../../_components";
import ChangePasswordForm from "./component/ChangePasswordFrom";
import Notification from "./component/Notification";
import userprofileGraphic from "../../images/bannerGraphicImages/myprofile.png";
import { utils, config } from "utils"

class UserProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      notificationIsOpen: false,
      notificationMessage: '',
      notificationStatus: '',
      user: null
    }
  }

  componentDidMount() {
    const user = localStorage.getItem('user');
    this.setState({user: JSON.parse(user)});
  }

  toggleModal = () => {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
    })
  };

  toggleNotification = (notificationMessage = '', notificationStatus = '') => {
    this.setState({
      notificationMessage,
      notificationStatus,
      notificationIsOpen: !this.state.notificationIsOpen,
    });
    if (this.state.notificationIsOpen) {
      setTimeout(() => this.setState({notificationIsOpen: false}), 2000);
    }
  };

  getTimeZone = () => {
    const timeZone = String(config.timeZoneServer);
    const offset = utils.lib.getOffsetByTimeZoneName();
    return `${timeZone} (UTC${offset})`;
  }

  render() {
    const {user} = this.state;
    return (
      <div className="container-fluid px-0">
        <TitleComponent
          title="User Profile"
          bannerGraphic={userprofileGraphic}
        >
        </TitleComponent>
        <Container>
          <Row className="justify-content-end">
            {this.state.notificationIsOpen ?
              <Notification
                toggleNotification={this.toggleNotification}
                notificationMessage={this.state.notificationMessage}
                notificationStatus={this.state.notificationStatus}
              />
              : ''
            }
          </Row>
          <Row className="mt-5">
            <Col sm="8" className="m-auto">
              <h3 className="profile-sub-title font-size-large">User Profile Details</h3>
              <Table className="shadow-sm rounded-xl user-details-table customProfile" borderless>
                <tbody>
                <tr>
                  <td>Name</td>
                  <td className="profile-section-2">{user ? user.name : ''}</td>
                </tr>
                <tr>
                  <td>Username</td>
                  <td className="profile-section-2">{user ? user.username : ''}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td className="profile-section-2">{user ? user.email : ''}</td>
                </tr>
                <tr>
                  <td>User Group(s)</td>
                  <td>{user ? user.groups.join(',') : ''}</td>
                </tr>
                <tr>
                  <td>Organisation Name</td>
                  <td>{user ? user.orgUnitName : ''}</td>
                </tr>
                <tr>
                  <td>Organization Type</td>
                  <td>{user ? user.orgUnitType : ''}</td>
                </tr>
                <tr>
                  <td>Password</td>
                  <td className="text-secondary">
                    <span className="change-pwd-btn" onClick={this.toggleModal}>Change Password</span>
                  </td>
                </tr>
                <tr>
                  <td>Timezone</td>
                  <td>{user ? this.getTimeZone() : ""}</td>
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
        <ModalUi title="Change Password" isOpen={this.state.modalIsOpen} toggle={this.toggleModal}>
          <ChangePasswordForm
            toggleNotification={this.toggleNotification}
            toggleModal={this.toggleModal}
            history={this.props.history}
          />
        </ModalUi>
      </div>
    )
  }
}

export default UserProfileDetails;
