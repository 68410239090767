import React from 'react';
import { Row, Col } from 'reactstrap';
import { utils } from 'utils';

const RenderDetails = (props) => {
  const { model, item } = props;
  return (
    <>
      {
        model ? model.items.map((modelItem) => {
          return <RenderDetail key={modelItem.id} model={modelItem} item={item} />;
        }) : <></>
      }
    </>
  );
};
const RenderDetail = (props) => {
  const { item, model } = props;
  const { lib: { resolveObjKey } } = utils;
  return (
    <>
      <Col className='pt-2 summary-label' sm={6}>{model.name}</Col>
      <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
        { item ? resolveObjKey(item, model.id) : '' }
      </Col>
    </>
  );
};
const RenderInsuredDetails = (props) => {
  const { model, item } = props;
  if(item.properties.beneficiaries && item.properties.beneficiaries.length && item.properties.beneficiaries.length >0) {
    item.properties.beneficiaries = item.properties.beneficiaries[0]
  }
  return (
    <Row>
      <Col sm={12}>
        <h4 className='beneficiary-header my-3 p-3'>
          Adult 1 (Main Applicant)
        </h4>
        <Row className='wordBreak'>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Insured Person Details</div>
            <Row>
              <RenderDetails model={model.insured} item={item} />
            </Row>
          </Col>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Beneficiary Details</div>
            <Row>
              <RenderDetails model={model.beneficiary} item={item} />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default RenderInsuredDetails;
