import React from "react";
import { Col } from "reactstrap";
import Checkbox from "react-toolbox/lib/checkbox";
import { DateRange } from "_components";
import PropTypes from "prop-types";

const RenderValues = (props) => {
  const {
    values, searchKeyword, isCalendar,
    onDateRangeChange, onValueCheckChange,
  } = props;
  if (values.length === 0) return (<>No results found</>);
  const filteredValues = values.filter((value) => (
    value.name.toLowerCase().includes(searchKeyword.toLowerCase())
  ));
  if (isCalendar) {
    const start = values.find((val) => val.code === "startDate").value;
    const end = values.find((val) => val.code === "endDate").value;
    return (
      <DateRange
        dateRange={[start, end]}
        onSelected={(dateRange) => {
          onDateRangeChange(dateRange);
        }}
      />
    );
  }

  return filteredValues.map((value) => (
    <Checkbox
      key={value.id}
      theme={{ check: "bg-light xc-checkbox" }}
      className="p-2 mb-4"
      checked={value.isChecked}
      label={value.name}
      onChange={() => onValueCheckChange({ ...value, isChecked: !value.isChecked })}
    />
  ));
};

const FilterValuesCol = (props) => {
  const {
    searchKeyword, onSearchKeywordChange,
    onDateRangeChange, values, onValueCheckChange, valuesType,
  } = props;
  const isCalendar = valuesType === "dateRange";
  return (
    <Col sm="5" className="border-top-0 border-bottom-0 border-left-0 border p-0">
      <div id="searchOption">
        <div className={`card-header p-2 ${isCalendar ? "d-none" : ""}`}>
          <div className="input-group col-md-12 p-0">
            <input
              className="form-control py-2 border-0 rounded-0"
              type="search"
              value={searchKeyword}
              onChange={(range) => onSearchKeywordChange(range)}
              placeholder="Search"
              id="example-search-input"
            />
            <span className="input-group-append">
              <button className="btn rounded-0" type="button">
                <i className="icon-search" />
              </button>
            </span>
          </div>
        </div>
        <div className="card-body">
          <RenderValues
            values={values}
            isCalendar={isCalendar}
            searchKeyword={searchKeyword}
            onValueCheckChange={(value) => onValueCheckChange(value)}
            onDateRangeChange={(rangeObj) => onDateRangeChange(rangeObj)}
          />
        </div>
      </div>
    </Col>
  );
};
FilterValuesCol.propTypes = {
  searchKeyword: PropTypes.string,
  values: PropTypes.array.isRequired,
  valuesType: PropTypes.string.isRequired,
  onSearchKeywordChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  onValueCheckChange: PropTypes.func.isRequired,
};
FilterValuesCol.defaultProps = {
  searchKeyword: "",
};

export default FilterValuesCol;
