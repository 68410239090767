import React, { useEffect, useState } from 'react';
import {
  Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
  DatePicker,
} from '@xc-core/components/core';
import { dateLib, objLib } from '@xc-core/lib';
import ButtonCircular from '@xc-core/components/core/buttonCircular';
import Body from './body';
import { actions } from '@redux';


const fiscalYearActions = actions.FiscalYearActions;

const FiscalYearComponent = (props: IObject) => {
  const {
    records, toggleEditAction, editIndex, isEditMode,
    editItem, handleChange, addFiscalYear, saveRecord, getList,
    resetFiscalYearDetails
  } = props;

  const [fiscalYearList, setFiscalYearList] = useState([{}]);
  useEffect(() => {
    if(!props.isTestCase)
      getList();

    return ()=>{
      if(!props.isTestCase)
        resetFiscalYearDetails()
    }
  }, []);

  const createFiscalYearList = (items: IObject[]) => items.map((record: IObject, index: number) => {
    const { fiscalYear, startDate, endDate } = record;
    if (isEditMode && index === editIndex) {
      const [first, second] = fiscalYear.split('/');
      return {
        isEditing: true,
        fiscalYear: (
          <div
            style={{ width: '150px' }}
            className='d-inline-flex'
          >
            <Input
              value={editItem.first !== '' ? editItem.first : first}
              type='number'
              style={{ width: '70px' }}
              className='mr-3'
              data-testid="year-start"
              onChange={({ target: { value } }) => handleChange({ id: 'first', value })}
            />
            <p className='font-size-normal m-0 d-flex justify-content-center align-items-center'>/</p>
            <Input
              value={editItem.second !== '' ? editItem.second : second}
              type='number'
              style={{ width: '70px' }}
              className='ml-3'
              data-testid="year-end"
              onChange={({ target: { value } }) => handleChange({ id: 'second', value })}
            />
          </div>),
        startDate: <div style={{ width: '150px' }}>
          <DatePicker
            id='startDate'
            name='Start Date'
            value={objLib.getValueWithLodash((editItem.startDate !== '' ? { startDate: editItem.startDate } : record), 'startDate') || ''}
            handler={(pair: IPair) => handleChange(pair)}
            min=''
            max=''
            start=''
            end=''
            openTo={
              editItem.startDate === ''
                ? dateLib.getCurrentDate(dateLib.config.dateMomentFormat)
                : dateLib.applyFormat(editItem.startDate, dateLib.config.dateMomentFormat)
            }
          />
        </div>,
        endDate: <div style={{ width: '150px' }}>
          <DatePicker
            id='endDate'
            name='End Date'
            value={objLib.getValueWithLodash((editItem.endDate !== '' ? { endDate: editItem.endDate } : record), 'endDate') || ''}
            handler={(pair: IPair) => handleChange(pair)}
            min=''
            max=''
            start=''
            end=''
            openTo={
              editItem.endDate === ''
                ? dateLib.getCurrentDate(dateLib.config.dateMomentFormat)
                : dateLib.applyFormat(editItem.endDate, dateLib.config.dateMomentFormat)
            }
            className=''
          />
        </div>,
        action: ' ',
      };
    }
    return {
      isEditing: false,
      fiscalYear: fiscalYear ? fiscalYear.split('/').join(' / ') : '',
      startDate,
      endDate,
      action: <ButtonCircular
        className='table-row-button mr-auto ml-auto'
        handler={() => toggleEditAction(index)}
        key='row-action'
        icon='pencil'
        id='record.id'
        name='details'
      />,
    };
  });

  useEffect(() => {
    setFiscalYearList(() => createFiscalYearList(records));
  }, [isEditMode, editItem, records]);
  // if (props.isLoading) return <Loader />;
  return (
    <Body
      fiscalYearList={fiscalYearList}
      addFiscalYear={addFiscalYear}
      saveRecord={saveRecord}
    />
  );
};
const mapStateToProps = (state: IStore) => ({
  records: state.fiscalYear.records,
  isEditMode: state.fiscalYear.isEditMode,
  isLoading: state.fiscalYear.isLoading,
  editIndex: state.fiscalYear.editIndex,
  editItem: state.fiscalYear.editItem,
  isTestCase: state.isTestCase
});
const mapDispatchToProps = (dispatch: any) => ({
  toggleEditAction: (index: number) => dispatch(fiscalYearActions.toggleEdit(index)),
  handleChange: (pair: IPair) => dispatch(fiscalYearActions.handleChange(pair)),
  addFiscalYear: () => dispatch(fiscalYearActions.addFiscalYear()),
  saveRecord: () => dispatch(fiscalYearActions.saveRecord()),
  getList: () => dispatch(fiscalYearActions.getList()),
  resetFiscalYearDetails: () => dispatch(fiscalYearActions.resetFiscalYearDetails())
});
export default connect(mapStateToProps, mapDispatchToProps)(FiscalYearComponent);
