import React, { FC, useEffect, useState, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {actions} from "@redux";
import { ModalUi, AddButton } from '_components';
import { connect } from "react-redux";
import CircularButton from "_components/CircularButton";
import Notifications from 'react-notification-system-redux';
import { getNotificationMessage } from '_services';
import {Loader} from "@xc-core/components/core";
const productAction = new actions.ProductActions();
const AssetsConfigListLogic = ({ product } : IObject )  => {
    const [loading , setIsLoading] = useState<boolean>(false);
    const [errorMessages, setErrorMessages]= useState<string[]>([]);
    const [isOpenModal , setIsOpenModal] = useState<boolean>(false);
    const [assets , setAssets] = useState<string[]>([]);
    const dispatch= useDispatch();
    const productList:any = useSelector<IStore>(state => state);
    const uploadButtonRef: any = useRef(null);

    const loadListOfAssets = async (productSlug: string) => {
        setIsLoading(true);
        const assets: any = await dispatch(productAction.getAssetsByProductSlug(productSlug))
        setAssets(assets)
        setIsOpenModal(true)
        setIsLoading(false)
        return assets
    }

    const closeModal = () => {
        setIsOpenModal(false)
    }
    const uploadNewAssets = async (event: IObject) => {        
        try{
            const fileObj = event.target.files && event.target.files[0];
            var formdata = new FormData();                
            formdata.append("file", fileObj, fileObj.name);
            formdata.append("productSlug", product?.slug);
            setIsLoading(true)
            const uploadProductAssets : IObject = await dispatch(productAction.uploadAssetsOfProduct(formdata))
            const message:any = getNotificationMessage('The assets have been successfully uploaded.')
            dispatch(Notifications.success(message))            
            loadListOfAssets(product.slug)        
            setIsLoading(false);
        }catch(e){
            handleError(e)
        }
    }
    const decorateFileName = (fileName: string) =>{
        if(fileName){ 
            return fileName.split('/')[2]
        }
        return '';
    } 
    const previewImage = (image: string, imageType: string) => {
        const w = window.open('about:blank');
        if(w && image && image){
            setTimeout(function () {
            w.document.body.appendChild(w.document.createElement('iframe')).src = image.replace('data:application/json;', imageType ) ;
            w.document.getElementsByTagName('iframe')[0].style.width = '100%';
            w.document.getElementsByTagName('iframe')[0].style.height = '100%';
            }, 0);
        }
    }
    const showFile = async (fileName: string) => {
        try{
            const uploadProductAssets : any = await dispatch(productAction.getAssetsOfProduct(product?.slug,decorateFileName(fileName)))
            var reader = new FileReader();
            reader.readAsDataURL(uploadProductAssets); 
            reader.onloadend = function() {
            const base64data : any = reader.result;     
            const fileExt= fileName.split('.')[1]
            switch(fileExt){
                case 'png':
                case 'jpg':
                case 'jpeg':
                    return previewImage(base64data, `data:image/${fileExt};`)
                default:
                    const link = document.createElement('a');
                    link.href = base64data;
                    link.download = fileName;
                    link.target = 'blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    break;
            }
            }
        }catch(e){
            handleError(e)
        }
    }
    const deleteFile = async (fileName: string) => {
        try{
            if(window.confirm('are you sure')){
                const uploadProductAssets : any = await dispatch(productAction.deleteAssetsOfProduct(product?.slug,decorateFileName(fileName)))
                loadListOfAssets(product.slug);   
            }
        }catch(e){
            handleError(e)
        }
    }
    const handleError = (e: Error | Object | any) => {
        if (e instanceof Error) {
            const message:any = getNotificationMessage(e?.message)
            dispatch(Notifications.error(message))            
            setIsLoading(false);
        }
    }

    return {
      loadListOfAssets,
      isOpenModal,
      closeModal,
      uploadNewAssets,
      uploadButtonRef,
      assets,
      showFile,
      deleteFile,
      loading
    }
}

export const AssetsConfigList : FC<IObject> = ({ product }) => {
    const { 
        loadListOfAssets, 
        isOpenModal, 
        closeModal, 
        uploadNewAssets, 
        uploadButtonRef, 
        assets,      
        showFile,
        deleteFile,
        loading
    } = AssetsConfigListLogic({product});
    return (
        <>
            <span
                title={'Assets'}
                className="linkButton text-primary"
                onClick={()=> {
                    loadListOfAssets(product.slug)
                }}
            >
                Assets
            </span>
            <ModalUi id={'editor-modal'} isOpen={isOpenModal} title={'Edit Assets'} toggle={closeModal}>
                {
                    loading? (<Loader/>): null
                }                
                <div style={{
                    padding: 20
                }}>
                    {
                        assets && assets.map((asset)=>{
                            return (
                                <div key={asset} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    marginTop: 10
                                }}> 
                                    <span style={{ flex: 0.90}}>
                                        {asset.replace('assets/','')}
                                    </span>
                                    <div>
                                        <CircularButton
                                            press={()=> {
                                                showFile(asset)
                                            }}
                                            iconName={'file'}
                                            />
                                        <CircularButton
                                            color='danger'
                                            press={()=> {
                                                deleteFile(asset)
                                            }}
                                            iconName={'garbage'}
                                            />
                                    </div>
                                </div>
                            )
                        })
                    }
                    {!loading ? (<input
                        style={{display: 'none'}}
                        ref={uploadButtonRef}
                        type="file"                    
                        onChange={(e)=>{
                            uploadNewAssets(e)
                        }}
                    />): null}                    
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 20,
                    }}>
                        <AddButton text='Upload New'onClick={(e)=>{
                            e.preventDefault();
                            uploadButtonRef?.current?.click();
                        }}/>
                    </div>
                </div>
            </ModalUi>
        </>
    )
}