import {env as config} from '_config/env';
import { handleResponse } from "../../../_services";

const { token, apiURL } = config;

async function changePassword(body) {
  const requestOptions = {
    method: "put",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token" : `${token}`,
    }),
    body: JSON.stringify(body),
  };
  try {
    const response = await fetch(`${apiURL}/identity/users/change-password`, requestOptions);
    return await handleResponse(response);
  } catch (e) {
    return e;
  }
}

export { changePassword };
