import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import moment from "moment";
import { CONFIG_DATE_MOMENT_FORMAT } from "utils";

const RenderSelectedValues = (props) => {
  const { values, type, id } = props;
  let selection = "";

  const concatNames = () => {
    const temp = [];
    temp.push(values.map((value) => value.name));
    return temp.join(",");
  };

  if (type === "dateRange") {
    const startDate = values.find((value) => value.name === "Start Date").value;
    const endDate = values.find((value) => value.name === "End Date").value;
    selection = `${moment(startDate).format(CONFIG_DATE_MOMENT_FORMAT)} to ${moment(endDate).format(CONFIG_DATE_MOMENT_FORMAT)}`;
  } else {
    selection = concatNames(values);
  }
  return (
    <span key={id}>
      {`${selection}`}
    </span>
  );
};
RenderSelectedValues.propTypes = {
  values: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const SelectedFiltersCol = (props) => {
  const { filters } = props;
  return (
    <Col sm="4" className="p-0">
      <div>
        <div className="card-header xc-selectedfilter-header">
          <span>Filters Selected</span>
        </div>
        <div className="card-body xc-selectedfilters">
          {filters != null
          && filters.map((filter) => {
            return (
              <div className="xc-selectedfilters-section" key={filter.id}>
                {filter.checkedCount >= 1 && <p>{filter.displayName}</p>}
                {
                  (filter.checkedCount > 0)
                    ? <RenderSelectedValues id={filter.id} values={filter.list.filter((item) => item.isChecked)} type={filter.type} />
                    : <></>
                }
              </div>
            );
          })}
        </div>
      </div>
    </Col>
  );
};
SelectedFiltersCol.propTypes = {
  filters: PropTypes.array.isRequired,
};

export default SelectedFiltersCol;
