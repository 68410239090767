import {checkPermission, handleRequest, handleResponse} from "../../../../_services";
import {getRequestOptions} from "../../../../utils";
import {utils} from "utils";

const Config = require('_config/env');
const apiUrl = Config.env.policyUrl;


async function hasPermission(scope) {
  return await checkPermission(scope);
}

async function exportToCsv({ pii, stringParameters, currentProduct }) {
  let url = `${apiUrl}/policies/export?export_pii=${pii}&${stringParameters}`;
    if (currentProduct.id) {
      url += `&productCode=${currentProduct.id}`;
    }
  let fileName = "";
  const response = await handleRequest(url, getRequestOptions());
  const blobResponse = await handleResponse(response, true);
  const contentDisposition = response.headers.get("Content-Disposition");

  fileName = contentDisposition && contentDisposition.indexOf("filename=")
    ? contentDisposition.split("filename=")[1] : fileName;
  fileName = fileName.replace(/"/g, "");
  if (utils.lib.isEdge()) {
    const isQuotation = false;
    fileName = utils.lib.generateCsvFileName(isQuotation, pii, currentProduct.orgUnit);
  }
  return { fileName, blobResponse };
}

async function getPoliciesList(page, perPage, productCode) {
  /** for listing the flat view if always false */
  const response = await fetch(
    `${apiUrl}/policies/with-collections?page=${page}&pageSize=${perPage}&productCode=${productCode}&isFlatView=false`,
    getRequestOptions(),
  );
  return await handleResponse(response);
}

function downloadPolicy(policyId) {
  function handleResponse(response) {
    return response.blob();
  }

  return fetch(`${apiUrl}/policies/download?idOrCode=${policyId}`, getRequestOptions())
    .then(handleResponse)
    .then(blob => {
      return blob;
    })
    .catch(function(err) {
      return err;
    });
}

function getPolicyDetail(quotationId) {
  return fetch(`${apiUrl}/policies/${quotationId}/with-collections`, getRequestOptions())
    .then(handleResponse)
    .then(list => {
      return list;
    })
    .catch(function(err) {
      return err;
    });
}

async function getEndorsementPermissions(quotationId) {
  const url = `${apiUrl}/policies/endorsement-permissions?id=${quotationId}`;
  const permissions = await fetch(url, getRequestOptions());
  return handleResponse(permissions);
}

function cancelPolicy(id, properties) {
  let url = `${apiUrl}/policies/${id}/cancel`;
  return fetch(url, getRequestOptions({method: "put", body: properties}))
    .then(handleResponse)
    .then(data => {
      return data;
    })
    .catch(function(err) {
      return err;
    });
}

function updatePolicy(id, properties, updatePayment) {
  let url = `${apiUrl}/policies/${id}`;

  if (updatePayment){
    url = `${apiUrl}/policies/${id}?reissue=false&archive=false`;
    for (var key in properties.properties) {
      if (properties.properties.hasOwnProperty(key)) {
        properties.properties[key] = properties.properties[key] == "" ? null : properties.properties[key];
      }
    }
  }

  return fetch(url, getRequestOptions({method: "put", body: properties}))
    .then(handleResponse)
    .then(data => {
      return data;
    })
    .catch(function(err) {
      return err;
    });
}

async function financialEndorsementCheck(newPolicy) {
  const url = `${apiUrl}/policies/endorsement-request`;
  const options = { method: 'Post', body: { ...newPolicy } };
  const response = await handleRequest(url, getRequestOptions(options));
  return handleResponse(response);
}

async function endorseConfirm(endorsementRequestId, remarks) {
  const url = `${apiUrl}/policies/endorsement-confirm`;
  const options = { method: 'Post', body: { endorsementRequestId, remarks } };
  const response = await handleRequest(url, getRequestOptions(options));
  return handleResponse(response);
}

function reissuePolicy(id) {
  return fetch(`${apiUrl}/policies/${id}/reissue`, getRequestOptions({ method: 'put' }))
    .then(handleResponse)
    .then(data => {
      return data;
    })
    .catch(function(err) {
      return err;
    });
}

async function applyFilter(params, productCode) {
  const url = `${apiUrl}/policies/search?${params}&productCode=${productCode}`;
  try {
    const data = await handleRequest(url, getRequestOptions());
    return await handleResponse(data);
  } catch (e) {
    return e;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const policyServices = {
  getPolicyDetail,
  getPoliciesList,
  applyFilter,
  reissuePolicy,
  updatePolicy,
  cancelPolicy,
  exportToCsv,
  downloadPolicy,
  hasPermission,
  financialEndorsementCheck,
  endorseConfirm,
  getEndorsementPermissions,
};
