import React, {Component} from 'react';
import {Form, Button} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import {CommonInputTypes} from "../../../_components/CommonInputTypes";
import {changePassword} from "../services/userProfile.services";

class ChangePasswordForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordError: false,
      confirmPasswordError: false,
      passwordPolicyError: false,
      errorMsg: ''
    };

    this.validator = new SimpleReactValidator({
      className: 'text-danger',
      messages: {
        email: 'That is not an email.'
      },
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const {currentPassword, newPassword, confirmPassword} = this.state;
    if (this.validator.allValid()) {
      const body = {
        currentPassword,
        newPassword,
        confirmation: confirmPassword,
      };
      const response = await changePassword(body);
      if (!response.error) {
        this.props.toggleNotification('Password is reset successfully. Please login using your new password.', response.status);
        this.props.toggleModal();
        setTimeout(() => {
          this.logout();
        },2000);
      } else {
        // this.props.toggleNotification(response.message, 'error');
        if (response.message === "Current password is invalid.") {
            this.setState({
              currentPasswordError: true, errorMsg: response.message
            });
        } else if(response.message === "Password does not match confirmation."){
          this.setState({
            confirmPasswordError: true, errorMsg: response.message
          });
        }else {
          this.setState({
            passwordPolicyError: true, errorMsg: response.message
          });
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInputChange = (e) => {
    const target = e.target;
    this.setState({
      [target.name.replace(/\s/g, '')]: target.value,
      currentPasswordError: false,
      confirmPasswordError: false,
      passwordPolicyError: false,
      errorMsg: ''
    });
  };

  logout = () => {
    localStorage.clear();
    window.location.href = "#/";
    // this.props.history.push('/');
    window.location.reload();
  };

  render() {
    const {currentPassword, newPassword, confirmPassword} = this.state;
    return (
      <Form>
        <CommonInputTypes
          idName="current Password"
          placeholder="Enter current password"
          type="password"
          value={currentPassword}
          onChange={this.handleInputChange.bind(this)}
          title="Current Password"
          id="1"
          error={this.state.currentPasswordError}
          validator={this.validator}
        >
          {this.state.currentPasswordError ?
            <span className="error" style={{position: "absolute"}}>
              {this.state.errorMsg}
              </span>
            : ''
          }
        </CommonInputTypes>
        <CommonInputTypes
          idName="new Password"
          placeholder="Enter new password"
          type="password"
          value={newPassword}
          onChange={this.handleInputChange.bind(this)}
          title="New Password"
          id="2"
          error={this.state.passwordPolicyError}
          validator={this.validator}
        >
        {this.state.passwordPolicyError ? 
          <span className="error" style={{position: "absolute"}}>
            {this.state.errorMsg}
          </span>
          : ''
        }
        </CommonInputTypes>
        <CommonInputTypes
          idName="confirm Password"
          placeholder="Confirm password"
          type="password"
          value={confirmPassword}
          onChange={this.handleInputChange.bind(this)}
          title="Confirm Password"
          id="3"
          error={this.state.confirmPasswordError}
          validator={this.validator}
        >
          {this.state.confirmPasswordError ?
            <span className="error" style={{position: "absolute"}}>
              {this.state.errorMsg}
            </span>
            : ''
          }
        </CommonInputTypes>
        <div className="row justify-content-end" id="modalButton">
          <div className="col-2">
            <Button
              onClick={this.props.toggleModal}
              className="text-info"
              color="link"
            >
              Cancel
            </Button>
          </div>
          <div className="col-3">
            <Button
              onClick={this.onSubmit.bind(this)}
              color="primary"
              className="fullWidth rounded-xl"
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export default ChangePasswordForm;
