import actionTypes from '@redux/actionTypes';
import BaseListingReducer from './baseListing';

class UserGroupListReducer extends BaseListingReducer<IUserGroupListState> {
  public actions: IDictionary<(state: IUserGroupListState, action: IAction) => IUserGroupListState>;

  public initialState: IUserGroupListState;

  protected actionTypes: IUserGroupActionTypes;

  constructor() {
    super();

    this.actionTypes = actionTypes.userGroup;

    this.initialState = {
      isLoading: true,
      error: null,
      list: [],
      hasRecords: false,
      isCreateForbidden: false,
      isUpdateForbidden: false,
      accessIsForbidden: false,
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IUserGroupListState,
    action: IAction) => IUserGroupListState> => ({
    [this.actionTypes.LIST_LOADING]: this.setLoading,
    [this.actionTypes.LIST_FAILED]: this.setError,
    [this.actionTypes.LIST_GET]: this.getList,
    [this.actionTypes.LIST_SET_NOT_FOUND]: this.setNotFound,
    [this.actionTypes.LIST_SET_FORBIDDEN]: this.setForbidden,
    [this.actionTypes.LIST_CHANGE_SEARCH_KEY]: this.setSearchKeyword,
    [this.actionTypes.LIST_CHANGE_PAGE]: this.setPageNumber,
    [this.actionTypes.LIST_CHANGE_PAGE_SIZE]: this.setPageSize,
    [this.actionTypes.LIST_APPLY_FILTERS]: this.applyFilter,
    [this.actionTypes.LIST_CHANGE_FILTERS]: this.changeFilter,
    [this.actionTypes.LIST_RESET]: this.reset,
    [this.actionTypes.CREATE_SET_FORBIDDEN]: this.setCreateForbidden,
    [this.actionTypes.UPDATE_SET_FORBIDDEN]: this.setUpdateForbidden,
  })

  protected getList = (state:IUserGroupListState, action: IAction):IUserGroupListState => ({
    ...state,
    isLoading: false,
    error: null,
    list: action.payload.list,
  })
}

export default UserGroupListReducer;
