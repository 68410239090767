import React from "react";
import { Row, Col, Input, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export class Paginate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      perPage: 10,
    };
  }

  componentWillReceiveProps(newProps) {
    const { pageable } = newProps;
    let pageSize = 10;
    if (pageable) pageSize = pageable.pageSize || 10;
    this.setState({ perPage: pageSize });
  }

  calculateNumberPagination(currentPage, totalPages) {
    const numOfPagination = 4;
    // example :
    // note : index start from 0
    // currentPage = 4 total page = 10
    // startIndex = parseInt(4 / 4) * 4 = 4
    // endIndex   = 4 + 4 = 8
    let startIndex = parseInt(currentPage / numOfPagination) * numOfPagination;
    let endIndex = startIndex + numOfPagination;
    if (endIndex > totalPages) endIndex = totalPages;

    let numbers = [];
    for (let idx = startIndex; idx < endIndex; idx++) {
      numbers.push(idx);
    }
    return {
      startIndex: startIndex,
      numbers: numbers,
      endIndex: endIndex
    }
  }

  paginateHandler = num => {
    this.props.onClick(num, this.state.perPage);
  }

  perPaginateHandler = e => {
    this.props.onClick(0, e.target.value);
  }

  render() {
    const { first, last, totalPages, currentPage, totalElement } = this.props;
    const { perPage } = this.state;
    const paginationData = this.calculateNumberPagination(currentPage, totalPages);

    if (totalElement > 10 ||  totalPages > 1) {
      return (
        <Row>
          <Col sm="8">
            <Pagination aria-label="Page navigation example">
              <PaginationItem className={first ? 'disabled' : ''} data-test="first-page">
                <PaginationLink onClick={e => this.paginateHandler(0) }>
                  <span aria-hidden="true">&lsaquo;</span>
                  <span>First</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem className={first ? 'disabled' : ''} data-test="prev-page">
                <PaginationLink onClick={e => this.paginateHandler(currentPage - 1) }>
                  <span aria-hidden="true">&laquo;</span>
                  <span>Previous </span>
                </PaginationLink>
              </PaginationItem>

              {
                paginationData.numbers.map(num => {
                  return (
                    <PaginationItem key={num} active={num === currentPage} data-test="item-page">
                      <PaginationLink onClick={e => this.paginateHandler(num)} >
                        {num + 1}
                      </PaginationLink>
                    </PaginationItem>
                  )
                })
              }

              <PaginationItem className={last ? 'disabled' : ''} data-test="next-page">
                <PaginationLink onClick={e => this.paginateHandler(currentPage + 1) }>
                  <span>Next </span>
                  <span aria-hidden="true">&raquo;</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem className={last ? 'disabled' : ''} data-test="last-page">
                <PaginationLink onClick={e => this.paginateHandler(totalPages - 1) }>
                  <span>Last </span>
                  <span aria-hidden="true">&rsaquo;</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>          
          <Col sm="4" className="text-right customRowshow">
            <span className="customNrow">Show</span>
            <Input style={{ width: '80px', display: 'inline', margin: '0 10px', padding: '6px 20px 6px 12px' }} type="select" name="select" id="per-paginate" value={perPage} onChange={this.perPaginateHandler.bind(this)}>
              <option>10</option>
              <option>20</option>
              <option>30</option>
              <option>40</option>
              <option>50</option>
            </Input>
            <span className="customNrow">rows per page</span>
          </Col>
        </Row>
      );
    } else {
      return (null);
    }
  }
}
