import {policyConstants} from '../constants';
import {HANDLE_FILTER_CLICKED} from '../../../../_constants';
import {setHasRecords} from '../../../../utils';

const defaultProperties = {
  DOB: '',
  City: '',
  State: '',
  Gender: '',
  Address: '',
  Country: '',
  'Place of Birth': '',
  'ID Type': '',
  Postcode: '',
  'Full Name': '',
  'ID Number': '',
  Nationality: 'Malaysian',
  Beneficiaries: [
    {
      id: 'Adult 1',
      Email: '',
      'ID Type': '',
      'Full Name': '',
      'ID Number': '',
      Relationship: '',
      'Same as Main': false,
      'Contact Number': '',
    },
  ],
  'Email Address': '',
  'Other Insured': [
    {
      id: 'Adult 1',
      DOB: '',
      Type: '',
      Gender: '',
      'ID Type': '',
      'Full Name': '',
      'ID Number': '',
      Nationality: 'Malaysian',
    },
  ],
  'Contact Number': '',
  'Endorse Remarks': '',
  'Cancel Remarks': '',
};
const defaultState = {
  id: null,
  refCode: null,
  common: {
    createdOn: (new Date()).toString(),
    createdBy: '',
    updatedOn: (new Date()).toString(),
    updatedBy: '',
    domain: '',
    detatedOn: null,
  },
  state: '',
  status: null,
  productCode: '',
  stateHistory: null,
  description: null,
  properties: {
    ...defaultProperties,
    Plan: { Total: 0 },
  },
  quotationTmpltId: null,
};
const initialState = {
  canViewList: true,
  hasRecords: true,
  policy: null,
  notification: null,
  notificationType: null,
  fields: [],
  products: [],
  currentProduct: null,

  hasTemplate: false, /** default should be false otherwise it will show
  an empty list rather than the proper message */

  keepFilters: false, /** when backing to list from detail page, list should not get refreshed */
  filterTodayPolicy: false,
  showPriceUpdateModal: false,
};

function mappingStateToDefaultState(state) {
  const newState = { properties: defaultProperties };
  const { properties } = state;

  if (properties !== undefined) {
    Object.keys(defaultProperties).map((key, index) => {
      if (['Endorse Remarks', 'Cancel Remarks'].includes(key)) {
        newState.properties[key] = '';
      } else {
        newState.properties[key] = properties[key];
      }
    });
  }

  return newState;
}

function mappingPaymentForm(state) {
  const { properties } = state;
  return {
    properties: {
      'Payment Mode': properties['Payment Mode'] || '',
      'Amount Paid': properties['Amount Paid'] || '',
      'Receipt No': properties['Receipt No'] || '',
      'Payment Received By': properties['Payment Received By'] || '',
    },
  };
}

function policyManagement(state = initialState, action) {
  let response = {};
  switch (action.type) {
    case policyConstants.SET_CSV_BLOB:
      return { ...state, csvBlob: action.blob };
    case policyConstants.RESET_CSV_BLOB:
      return { ...state, csvBlob: undefined };
    case policyConstants.CLEAR_BLOB:
      return { ...state, policyDetailBlob: undefined };
    case policyConstants.DOWNLOAD_POLICY_DETAIL:
      return { ...state, policyDetailBlob: action.blob };
    case policyConstants.SET_ENDORSEMENT_PERMISSIONS:
      return { ...state, endorsementPermissions: action.payload };
    case policyConstants.GET_POLICY_DETAIL:
      return {
        ...state,
        policy: {
          ...state.policy,
          ...(action.item || defaultState),
        },
        detailCollections: action.collections,
        keepFilters: true,
        policyForm: mappingStateToDefaultState(action.item || defaultState),
        paymentDetailForm: mappingPaymentForm(action.item || defaultState),
        notification: action.notification,
        notificationType: action.notificationType,
      };
    case policyConstants.UPDATE_POLICY_DETAIL:
      response = {
        ...state,
        policy: {
          ...state.policy,
          ...(action.item || defaultState),
        },
        policyForm: mappingStateToDefaultState(action.item || defaultState),
        paymentDetailForm: mappingPaymentForm(action.item || defaultState),
      };
      if (action.notification) {
        response = {
          ...response,
          notification: action.notification,
          notificationType: action.notificationType,
        };
      }
      return response;
    case policyConstants.SET_POLICY_ENDORSEMENT_CHECK_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case policyConstants.TOGGLE_PRICE_UPDATE_MODAL:
      return {
        ...state,
        showPriceUpdateModal: !state.showPriceUpdateModal,
      };
    case policyConstants.SET_FORBIDDEN_POLICIES:
      return {
        ...initialState,
        canViewList: false,
      };
    case policyConstants.GET_POLICIES_LIST:
      let pagination = {};
      if (!action.payload.list.error) {
        pagination = {
          first: action.payload.list.list.first,
          last: action.payload.list.list.last,
          currentPage: action.payload.list.list.number,
          pageable: action.payload.list.list.pageable,
          totalElement: action.payload.list.list.totalElements,
          totalPages: action.payload.list.list.totalPages,
        };
      }
      const hasRecords = setHasRecords(action.payload.list.list.content);
      return {
        ...state,
        hasRecords,
        canViewList: true,
        filterTodayPolicy: false, /** reset it */
        hasTemplate: action.payload.hasTemplate,
        filterOptions: action.payload.list.filters, // state.filterOptions || processFilters(action.payload.list.filters),
        initialFilterOptions: action.payload.list.filters, // processFilters(action.payload.list.filters),
        policiesList: action.payload.list.list ? action.payload.list.list.content : [],
        fields: action.payload.list.fields || [],
        collections: action.payload.list.collections,
        pagination,
        notification: action.payload.notification,
        notificationType: action.payload.notificationType,
      };
    case policyConstants.POLICIES_APPLY_FILTER:
      // const dynamicOptions = !action.payload.filter ?
      //   processFilters(action.payload.filters) :
      //   action.payload.filter;
      const filtersWithExtraProperties = action.payload.filter;
      return {
        ...state,
        policiesList: action.payload.list.content === '' ? state.orgList : action.payload.list.content,
        fields: action.payload.fields || [],
        // collections: state.collections,
        filters: action.payload.filters,
        filterOptions: filtersWithExtraProperties, // action.payload.filter === "" ? state.filterOptions : dynamicOptions,
        pagination: {
          first: action.payload.list.first,
          last: action.payload.list.last,
          currentPage: action.payload.list.number,
          pageable: action.payload.list.pageable,
          totalElement: action.payload.list.totalElements,
          totalPages: action.payload.list.totalPages,
        },
        notification: action.notification,
        notificationType: action.notificationType,
      };
    case policyConstants.TODAY_POLICY_APPLY_FILTER:
      return {
        ...state,
        filterOptions: action.payload,
      };
    case policyConstants.CLEAR_POLICY_DETAIL:
      return {
        ...state,
        policy: null,
      };
    case HANDLE_FILTER_CLICKED:
      return {
        ...state,
        filterOptions: action.payload.filterOptions,
      };
    case policyConstants.POLICY_RESET_FILTER:
      return {
        ...state,
        filterOptions: state.initialFilterOptions,
      };
    case policyConstants.GET_POLICY_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        currentProduct: action.payload[0],
      };
    case policyConstants.CHANGE_CURRENT_POLICY_PRODUCT:
      return {
        ...state,
        hasTemplate: action.payload.hasTemplate,
        currentProduct: action.payload.selectedProductCode,
      };
    case policyConstants.CLEAR_POLICY_LIST:
      return {
        ...state,
        policiesList: [],
      };
    case policyConstants.GET_STATES:
      return {
        ...state,
        detailCollections: {
          ...state.detailCollections,
          states: action.payload,
        },
      };
    case policyConstants.SET_CURRENT_POLICY_PRODUCT_TO_ALL:
      return {
        ...state,
        currentProduct: action.payload.currentProduct,
      };
    case policyConstants.RESET_KEEP_FILTERS:
      return {
        ...state,
        keepFilters: false,
      };
    case policyConstants.SET_APPLY_TODAY_POLICY_FILTER:
      return {
        ...state,
        filterTodayPolicy: true,
      };
    case policyConstants.POLICY_UPDATE_COLLECTIONS:
      return {
        ...state,
        detailCollections: {
          ...state.detailCollections,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
export default policyManagement;
