import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import lib from "../../../utils/lib";

const BasicDetails =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            initValues,
            defaults,
            next
        },
    } = props;

    useEffect(() => {
        if(!item.properties?.['Car Details']){
            initValues([
                {
                    id: model.fields.vehicleRegistrationNumber.id,
                    value:''
                }
            ]);
        }
    }, []);

    return (
        <Row>
            <Col md='12'>
                <h5 className='sub-title'>{model.title}</h5>
                <RenderFields
                    onChange={(pairs: IPair[]) => {
                        onChange('onChange', {pairs})
                    }}
                    collections={collections}
                    item={item}
                    ext={lib.checkIsDemoSite()}
                    validationErrors={validationErrors}
                    fields={model.basicDetailFields.fields}
                />
            </Col>
        </Row>
    )
}

export default BasicDetails;