import env from 'env';
import Request from './request';
import Response from './response';
import { currentUser } from 'utils';
class SystemConfigService{

    protected env = env.tsd;
    protected api = 'identity/orgunits';
    protected orgUnitCode = currentUser ? currentUser.domain : '';

    public getSystemConfigs = async (): Promise<any> => {
        const url = `${this.env}/${this.api}/${this.orgUnitCode}/sys-config`;
        const request = new Request();
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public updateSystemConfigs = async (newConfigs: IObject) : Promise<any> =>{
        const url = `${this.env}/${this.api}/sys-config`;
        const request = new Request(newConfigs,"PUT");
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data; 
    }


}

export default SystemConfigService;