import stringLib from './string';

const convertToPairList = (list: any[], keys = ['id', 'name']): IPair[] => (list.map((item: any) => (
    { id: stringLib.toString(item[keys[0]]), value: item[keys[1]] } as IPair
)));

const extendWithIDValueForKeys = (list: any[], keys = ['id', 'name']): IObject[] => (list.map((item: any) => (
  { ...item, id: stringLib.toString(item[keys[0]]), value: item[keys[1]] }
)));

const convertToIdValueTypeList = (list: any[], keys = ['id', 'name', 'type']): IObject[] => (list.map((item: any) => (
  { id: stringLib.toString(item[keys[0]]), value: item[keys[1]], type: item[keys[2]] } as IObject
)));

const updateItemInList = (list: any[], index: number, value: any): any[] => {
  const before = list.slice(0, index);
  const after = list.slice(index + 1);
  return [
    ...before,
    value,
    ...after,
  ];
};

const hasItems = (list: any[]): boolean => list && list.length > 0;

const isArray = (obj: any) => Array.isArray(obj);

const findByDynamicKey = (list: IObject[], key: string, keyValue: string): IObject | null => {
  const obj = list.find((collection: IObject) => (collection[key] === keyValue));
  return obj || null;
};

const listLib = {
  convertToPairList,
  hasItems,
  isArray,
  convertToIdValueTypeList,
  extendWithIDValueForKeys,
  updateItemInList,
  findByDynamicKey,
};

export default listLib;
