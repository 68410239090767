import React from 'react';
import * as reactstrap from 'reactstrap';

const ButtonCircular = (props: IButtonFromControlModal) => {
  const {
    handler, className, icon,
    autoFocus, id, disabled, type, color
  } = props;

  const onClick = (e: any): void => {
    e.stopPropagation();
    const pair: IPair = { id, value: '' };
    handler(pair);
  };

  return (
    <reactstrap.Button
      id={id}
      className={`xc-button-circular mx-1  ${className || ''}`}
      onClick={(e) => onClick(e)}
      disabled={disabled}
      autoFocus={autoFocus}
      type={type}
      color={color}
    >
      <i className={`icon-${icon}`} />
    </reactstrap.Button>
  );
};

export default ButtonCircular;
