export const getQuoteConstants ={
  CREATE_QUOTATIONS: "CREATE_QUOTATIONS",
  UPDATE_QUOTATIONS: "UPDATE_QUOTATIONS",
  GET_STATES: "GET_STATES",
  UPDATE_STATE_PER_STEP: "UPDATE_STATE_PER_STEP",
  RESET_STATE_PRE_STEP: "RESET_STATE_PRE_STEP",
  GET_QUOTATION: "GET_QUOTATION",
  RESET_QUOTATION: "RESET_QUOTATION",
  GET_QUOTE_GET_PRODUCTS: "GET_QUOTE_GET_PRODUCTS",
  START_REQUEST: "START_REQUEST",
  END_REQUEST: "END_REQUEST",
  GET_QUOTE_SELECT_PRODUCT: "GET_QUOTE_SELECT_PRODUCT",
  GET_QUOTE_GET_COLLECTIONS: 'GET_QUOTE_GET_COLLECTIONS',
};