import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from '@xc-core/components/core';

const ThankYouPage = (props: ILoadableComponentProps) => {
  const {
    model: {
      onChange,
    },
  } = props;

  return (
    <div className='text-center'>
      <h3>Thank you! / សូមអរគុណ!</h3>
      <p>The Certificate of Insurance has been generated and sent to the Main Insured’s email address.</p>
      <p>វិញ្ញាបនប័ត្រធានារ៉ាប់រងត្រូវបានបង្កើតនិងបញ្ជូនទៅអាសយដ្ឋានអ៊ីមែលរបស់អ្នកត្រូវបានធានារ៉ាប់រង។</p>
      <Row className='justify-content-center mt-5'>
        <Col sm='6' lg='4' xl='3'>
          <Button
            id='view-certificate'
            name='View Certificate / បង្ហាញវិញ្ញាបនប័ត្រធានារ៉ាប់រង'
            className='col-12 my-2 rounded-xl btn-outline-primary'
            handler={() => onChange('downloadCertificate')}
          />
        </Col>
        <Col sm='6' lg='4' xl='3'>
          <Button
            id='get-another-quote'
            name='Get Another Quote / ទាញយកតារាងតម្លៃធានារ៉ាប់រងមួយទៀត'
            className='col-12 my-2 rounded-xl btn-primary'
            handler={() => onChange('getAnotherQuote')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ThankYouPage;
