import React from 'react';

const OthersBenefitTable = (props) => {
    return(
			<div className="benefit-table">
				<p>TABEL MANFAAT PRODUK STANDAR / STANDARD PRODUCT BENEFIT TABLE ASURANSI PERJALANAN / <i>TRAVEL INSURANCE</i></p>
        <table>
						<thead>
							<tr>
								<th rowSpan={1} colSpan={5} className="invisible"></th>
								<th rowSpan={1} colSpan={1}>USD</th>
								<th rowSpan={1} colSpan={1}>USD</th>
							</tr>
							<tr>
								<th rowSpan={2} colSpan={3}>LUAS JAMINAN / <i>COVERAGE</i></th>
								<th rowSpan={2} colSpan={2}>MANFAAT / <i>BENEFIT</i></th>
								<th rowSpan={1} colSpan={1}>STANDARD</th>
								<th rowSpan={1} colSpan={1}>EXECUTIVE</th>
							</tr>
							<tr>
								<th rowSpan={1} colSpan={1}>BATASAN GANTI RUGI / <i>LIMIT OF LIABILITY</i></th>			
								<th rowSpan={1} colSpan={1}>BATASAN GANTI RUGI / <i>LIMIT OF LIABILITY</i></th>
							</tr>
						</thead>
            <tbody>
							<tr>
								<td colSpan={1} rowSpan={5} className="category">A</td>
								<td colSpan={2} rowSpan={5} className="category">Biaya Pengobatan &amp; Layanan Darurat / <i>Emergency Service Expenses</i></td>
								<td colSpan={2} rowSpan={1}>Biaya Pengobatan dan Rawat Inap / <i>Medical and hospitalization expenses</i></td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
								<td colSpan={1} rowSpan={1} className="price">70,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Biaya Transportasi atau Repatriasi Ketika Sakit atau Kecelakaan / <i>Transport or repatriation in the event of illness or accident expenses &amp; repatriation expenses</i></td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Biaya Repatriasi Jenazah / <i>Repatriation of Mortal Remain expenses</i></td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
								<td colSpan={1} rowSpan={1} className="price">70,000</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Biaya Perjalanan Salah Satu Anggota Keluarga Dekat / <i>Travel of one immediate family member expenses</i></td>
								<td colSpan={1} rowSpan={1} className="price">1,800</td>
								<td colSpan={1} rowSpan={1} className="price">3,500</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Biaya penjagaan/pedampingan anak / <i>Child Guard expenses</i></td>
								<td colSpan={1} rowSpan={1} className="price">100</td>
								<td colSpan={1} rowSpan={1} className="price">200</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>

							<tr>
								<td colSpan={1} rowSpan={1} className="category">B</td>
								<td colSpan={2} rowSpan={1} className="category">Biaya Pembatalan Perjalanan / Travel Cancellation Expenses</td>
								<td colSpan={2} rowSpan={1}>Biaya pembatalan atau pengurangan perjalanan / <i>Travel cancellation or curtailment expenses</i></td>
								<td colSpan={1} rowSpan={1} className="price">700</td>
								<td colSpan={1} rowSpan={1} className="price">1,500</td>
							</tr>

							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">C</td>
								<td colSpan={2} rowSpan={4} className="category">Manfaat Atas Kehilangan dan Penundaan / <i>Benefits for Losses dan Delay</i></td>
								<td colSpan={2} rowSpan={1}>Gangguan perjalanan / <i>Travel disruption</i></td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
								<td colSpan={1} rowSpan={1} className="price">700</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Penundaan perjalanan / <i>Travel postponement</i></td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
								<td colSpan={1} rowSpan={1} className="price">700</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Tertinggal perjalanan lanjutan / <i>Travel misconnection</i></td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Keterlambatan keberangkatan / <i>Delayed Departure</i></td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">D</td>
								<td colSpan={2} rowSpan={4} className="category">
									Manfaat Untuk Barang Bawaan /Benefits For Luggage
									<p className="regular mt-2">Limit per Family / Individual &amp; Spouse / Individual &amp; Children: 200% of Individual Limit</p>
								</td><td colSpan={2} rowSpan={1}>Kompensasi kehilangan di dalam pesawat, perampokan atau perusakan bagasi / <i>Compensation for in-flight loss, robbery or destruction of baggage checked-in</i></td>
								<td colSpan={1} rowSpan={1} className="price">1,600 (max 400 per item)</td>
								<td colSpan={1} rowSpan={1} className="price">800 (max 200 per item)</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Keterlambatan kedatangan bagasi / <i>Delay in the arrival of luggage</i></td>
								<td colSpan={1} rowSpan={1} className="price">180 (45 every 6 hours)</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Kehilangan paspor, SIM atau KTP/ <i>Loss of passport, driving license or ID Card</i></td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
								<td colSpan={1} rowSpan={1} className="price">350</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Penemuan dan pengiriman bagasi dan barang pribadi / <i>Discovery &amp; delivery of luggage and personal effects</i></td>
								<td colSpan={1} rowSpan={1} className="price">100</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={4} className="category">E</td>
								<td colSpan={2} rowSpan={4} className="category">Manfaat Tambahan / <i>Additional Benefit</i></td>
								<td colSpan={2} rowSpan={1}>Pengiriman obat-obatan / <i>Drugs delivery</i></td>
								<td colSpan={1} rowSpan={1} className="price">100</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Pembajakan pesawat terbang / <i>Aircraft Hijacking</i></td>
								<td colSpan={1} rowSpan={1} className="price">35 per hari (max 350)</td>
								<td colSpan={1} rowSpan={1} className="price">70 per hari (max 700)</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Biaya telepon darurat / <i>Emergency phone call</i></td>
								<td colSpan={1} rowSpan={1} className="price">100</td>
								<td colSpan={1} rowSpan={1} className="price">180</td>
							</tr>
							<tr>
							<td colSpan={2} rowSpan={1}>Perpanjangan periode pertanggungan otomatis / <i>Automatic of extension perio</i></td>
								<td colSpan={1} rowSpan={1} className="price">Ya / Yes</td>
								<td colSpan={1} rowSpan={1} className="price">Ya / Yes</td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={2} className="category">F</td>
								<td colSpan={2} rowSpan={2} className="category">Manfaat Kecelakaan Diri dan Cacat Tetap / <i>Benefits For Personal Accident and Disability</i></td>
								<td colSpan={2} rowSpan={1}>Santunan Kematian dan Cacat Tetap Akibat Kecelakaan / <i>Death &amp; Permanent Disablement due to accident</i></td>
								<td colSpan={1} rowSpan={1} className="price">35,000</td>
								<td colSpan={1} rowSpan={1} className="price">70,000</td>
							</tr>
							<tr>
								<td colSpan={2} rowSpan={1}>Cacat Tetap Sebagian / <i>Partial Permanent Disablement</i></td>
								<td colSpan={1} rowSpan={1} className="price">Limit up to Injury Table Percentage / <i>Sesuai Persentase Tabel Santunan</i></td>
								<td colSpan={1} rowSpan={1} className="price">Limit up to Injury Table Percentage / <i>Sesuai Persentase Tabel Santunan</i></td>
							</tr>
							<tr className="empty-row"><td colSpan={7}></td></tr>
							<tr>
								<td colSpan={1} rowSpan={2} className="category">G</td>
								<td colSpan={2} rowSpan={2} className="category">Manfaat Tanggung Gugat Pribadi / <i>Benefits For Personal Liability</i></td>
								<td colSpan={2} rowSpan={2}>Tanggung gugat pribadi / <i>Personal Liability</i></td>
								<td colSpan={1} rowSpan={2} className="price">35,000</td>
								<td colSpan={1} rowSpan={2} className="price">70,000</td>
							</tr>
            </tbody>
        </table>
    </div>);
}

export default OthersBenefitTable;