import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import lib from "../../../utils/lib";
import {dateLib} from "../../../@xc-core/lib";

const BasicDetails =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            db,
            initValues
        },
    } = props;
    let basicDetailModelFields = [...model.basicDetailFields.fields]
    const properties= item?.properties;
    const yearValue = lib.propertyAgeHandler('properties.Property Age' ,properties?.['Property Age']).value;

    if(item?.['properties']?.['Coverage Type'] === 'Third Party Liability'){
        basicDetailModelFields = basicDetailModelFields.filter((el)=>{
            return el.id !== 'properties.Sum Insured' && el.id !== 'properties.Property Age'
        });
    }

    useEffect(()=>{
        if(properties?.['Start Date'] === undefined){
            initValues([
                {
                    id:'properties.Start Date',
                    value: dateLib.formattedDate()
                },
                {
                    id:`properties.End Date`,
                    value: dateLib.addOneYearFromDate()
                }
            ])
        }
    },[properties])

    const changeHandler=(pairs: IPair[])=>{
        let updatedPairs= [...pairs];
        const {id ,value} = updatedPairs[0];
        let additionalPair:IPair[]= [];
        if( id === 'properties.Coverage Type'){
            const isThirdPartyLiability = value === 'Third Party Liability';
            additionalPair = [
                {
                    id:'properties.Sum Insured',
                    value: isThirdPartyLiability ? 0 : ''
                },
                {
                    id:'properties.Property Age',
                    value: isThirdPartyLiability ? 0 : null
                }
            ]
        }

        if(id === 'properties.Property Age'){
            updatedPairs = [lib.propertyAgeHandler(id ,value)]
        }

        if(id === 'properties.Start Date'){
            additionalPair = [
                {
                    id:'properties.End Date',
                    value: dateLib.addOneYearFromDate(value)
                }
            ]
        }

        onChange('onChange', {pairs:[
                ...updatedPairs,
                ...additionalPair
            ]})
    }

    return (
        <Row>
            <Col md='12'>
                <h5 className='sub-title'>{lib.withKhmerText(model.title,db)}</h5>
                <RenderFields
                    onChange={changeHandler}
                    collections={collections}
                    item={{
                        ...item,
                        properties:{
                            ...properties,
                            'Property Age': yearValue === 0 ? new Date().getFullYear().toString() : yearValue
                        }
                    }}
                    ext={false}
                    validationErrors={validationErrors}
                    fields={basicDetailModelFields}
                />
            </Col>
        </Row>
    )
}

export default BasicDetails;