import Notification from 'react-notification-system-redux';
import { getNotificationMessage } from "../../../_services";
import { resConstants } from "../constants";
import { resServices } from "../services";
import _ from "lodash";

function getList() {
  return async (dispatch) => {
    let list = await resServices.getResList();
    if (list.message === "Forbidden") return dispatch({ type: resConstants.SET_FORBIDDEN_RES });
    if (list.message) {
      dispatch(Notification.error(getNotificationMessage('An error occurred in getting resources')));
      return [];
    } else {
      list = _.sortBy( list, 'sortOrder' );
      return list;
    }
  };
}


function getManageList() {
  return async (dispatch) => {
    let list = await resServices.getResManageList();
    if (list.message === "Forbidden") return dispatch({ type: resConstants.SET_FORBIDDEN_RES });
    if (list.message) {
      dispatch(Notification.error(getNotificationMessage('An error occurred in getting resources')));
      return [];
    } else {
      list = _.sortBy( list, 'sortOrder' );
      return list;
    }
  };
}

function updateRes(data){
  return async dispatch => {
    try {
      const response = await resServices.updateRes(data);
      if(!response.error){
        dispatch(Notification.success(getNotificationMessage("Saved Successfully.")));
      }else {
        dispatch(Notification.error(getNotificationMessage(response.message)));
      }
      return response;
    } catch (e) {
      return e;
    }
  }
}

function deleteRes(id){
  return async dispatch => {
    try {
      const response = await resServices.deleteRes(id);
      if(!response.error){
        dispatch(Notification.success(getNotificationMessage("Deleted successfully.")));
      }else {
        dispatch(Notification.error(getNotificationMessage(response.message)));
      }
      return response;
    } catch (e) {
      return e;
    }
  }
}

function getOrganizationList() {
  return async (dispatch) => {
    let list = await resServices.getOrganizationList();
    if (list.message === "Forbidden") return dispatch({ type: resConstants.SET_FORBIDDEN_RES });
    if (list.message) {
      dispatch(Notification.error(getNotificationMessage('An error occurred in getting organization')));
      return [];
    } else {
      list = _.sortBy( list, 'name' );
      return list;
    }
  };
}

export const resActions = {
  getList,
  getManageList,
  updateRes,
  deleteRes,
  getOrganizationList
};
