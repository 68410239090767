import { quotationConstants } from '../constants';
import { quotationServices } from '../services';
import { getQuoteServices } from "../../GetQuote/services/index"
import {HANDLE_FILTER_CLICKED, CLEAR_FILTER_SELECTION} from "_constants";
import {utils} from "utils";
import filterUtil from "_components/filter/utils";
import config from "_config";
const clearQuotationDetail = () => {
  return {
    type: quotationConstants.CLEAR_QUOTATION_DETAIL
  }
}
const resetFilter = () => {
  return {
    type: quotationConstants.QUOTATION_RESET_FILTER
  }
}

const resetKeepFilters = () => {
  return {
    type: quotationConstants.RESET_KEEP_FILTERS,
  };
};

function updateList(list, type, notification={ notification: null, notificationType: null }) {
  return {
    type: type,
    payload: {
      hasTemplate: true,
      list,
      ...notification,
    }

  };
}


function exportToCsv(exportObjParams) {
  return async () => {
    const response = await quotationServices.exportToCsv(exportObjParams);
    utils.lib.exportResponseToFile(response);
  }
}

function resetCsvBlob() {
  return dispatch => {
    dispatch({
      type: quotationConstants.RESET_CSV_BLOB,
    });
  }
}

function setupNotification(list) {
  let notifyObj = { notification: null, notificationType: null }
  if (list && list.error) {
    notifyObj = {
      notification: list.error,
      notificationType: 'error'
    }
  } else if (!list) {
    notifyObj = {
      notification: 'Failed to fetch quotations',
      notificationType: 'error'
    }
  }
  return notifyObj;
}

const getProducts = () => {
  return async (dispatch) => {
    const products = await getQuoteServices.getProducts();
    if (!products.error) {
      const list = products.list.length < 2 ? [...products.list] : [{name: "All", refCode: "", id: ""}, ...products.list];
      dispatch({
        type: quotationConstants.GET_QUOTATION_PRODUCTS,
        payload: list,
      });
      return list; // this method is being called in componentDidMount,
      // returning products because we are calling it in the same cycle
      // of getting the current product from props, which is null and
      // will be ready on the next cycle!
    }
    if (products.message === "Forbidden") return dispatch({type: quotationConstants.SET_FORBIDDEN_QUOTATIONS});
  };
}

function getList(page, perPage, productCode) {
  return async (dispatch) => {
    const list = await quotationServices.getQuotationList(page, perPage, productCode);
    if (!list.error) {
      list.filters = filterUtil.addExtraPropertiesToFilters(list.filters);
      return dispatch({
        payload: { list, hasTemplate: true },
        type: quotationConstants.GET_QUOTATIONS_LIST,
      });
    }
    if (list.message === "Quotation Template Not Found") {
      return dispatch({
        type: quotationConstants.CLEAR_QUOTATION_LIST,
        payload: {
          hasTemplate: false,
        },
      });
    }
    if (list.message === "Forbidden") {
      return dispatch({ type: quotationConstants.SET_FORBIDDEN_QUOTATIONS });
    }
  };
}

function getTodayListForAllProducts(params, productCode = '') {
  return async (dispatch) => {
    const response = await quotationServices.applyFilter(params, productCode);
    if (!response.error) {
      const filtersWithExtraProperties = filterUtil.addExtraPropertiesToFilters(response.filters);
      const updatedResponse = {
        ...response,
        filters: filterUtil.getTodayFilterOptions(filtersWithExtraProperties),
      };
      return dispatch({
        type: quotationConstants.GET_QUOTATIONS_LIST,
        payload: { list: updatedResponse, hasTemplate: true },
      });
    }
    if (response.message === "Quotation Template Not Found") {
      return dispatch({
        type: quotationConstants.CLEAR_QUOTATION_LIST,
        payload: {
          hasTemplate: false,
        },
      });
    }
    if (response.message === "Forbidden") {
      return dispatch({ type: quotationConstants.SET_FORBIDDEN_QUOTATIONS });
    }
  };
}

function getDetail(quotationId) {
  return dispatch => {
    quotationServices.getQuotationDetail(quotationId)
      .then(
        list =>{
          const notification = setupNotification(list);
          !list.error && dispatch({
            type: quotationConstants.GET_QUOTATION_DETAIL,
            payload: { item: list.item, collections: list.collections },
            ...notification,
          });
        }
      );
  };
}

function applyFilters(filterObjectParams) {
  const { stringParams } = filterObjectParams;
  return async (dispatch, getState) => {
    const { quotationManagement: { currentProduct } } = getState();
    const response = await quotationServices.applyFilter(stringParams, currentProduct.id);
    const updatedResponse = { ...response, filter: filterObjectParams.filterObject };
    if (!response.error) {
      return dispatch({
        type: quotationConstants.QUOTATIONS_APPLY_FILTER,
        payload: updatedResponse,
      });
    }
  };
}

const setApplyTodayQuotationFilter = () => {
  return {
    type: quotationConstants.SET_APPLY_TODAY_QUOTATION_FILTER,
  };
};
const setCurrentProductToAll = () => {
  return (dispatch, getState) => {
    const { quotationManagement: { products } } = getState();
    const allProduct = products.length === 1 ? products[0]
      : products.find((product) => product.refCode === config.products.all.id);
    return dispatch({
      type: quotationConstants.SET_CURRENT_QUOTATION_PRODUCT_TO_ALL,
      payload: {
        currentProduct: allProduct,
      },
    });
  };
};

function handleOptionChanged(filterOptions) {
  return {
    type: HANDLE_FILTER_CLICKED,
    payload: {filterOptions}
  }
}
function clearFilterSelection(filterOptions) {
  return {
    type: CLEAR_FILTER_SELECTION,
    payload: {filterOptions}
  }
}
function changeProduct(productCode) {
  return async (dispatch, getState) => {
    const { quotationManagement: { products } } = getState();
    const selectedProduct = products.find((product) => product.refCode === productCode);
    const list = await quotationServices.getQuotationList(0, 10, productCode);
    const notification = setupNotification(list);
    if (!list.error) {
      dispatch(updateList(list, quotationConstants.GET_QUOTATIONS_LIST, notification));
      dispatch({ type: quotationConstants.QUOTATION_RESET_FILTER });
      dispatch({
        type: quotationConstants.CHANGE_CURRENT_QUOTATION_PRODUCT,
        payload: { selectedProduct, hasTemplate: true },
      });
    } else if (list.message === "Quotation Template Not Found") {
      dispatch({ type: quotationConstants.CLEAR_QUOTATION_LIST });
      dispatch({
        type: quotationConstants.CHANGE_CURRENT_QUOTATION_PRODUCT,
        payload: { selectedProduct, hasTemplate: false },
      });
    } else if (list.message === "Forbidden") {
      dispatch({ type: quotationConstants.SET_FORBIDDEN_QUOTATIONS });
    }
  };
}

export const quotationActions = {
  getList,
  getDetail,
  applyFilters,
  exportToCsv,
  resetCsvBlob,
  clearQuotationDetail,
  handleOptionChanged,
  clearFilterSelection,
  resetFilter,
  changeProduct,
  getProducts,
  resetKeepFilters,
  getTodayListForAllProducts,
  setApplyTodayQuotationFilter,
  setCurrentProductToAll,
};