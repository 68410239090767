const toString = (value: TValues): string => {
  if (value === null || value === undefined || value === false) return '';
  if (value === 0) return '0';
  return String(value);
};

const isEmpty = (value: string): boolean => value === '' || value === null || value === undefined;

const includesBracket = (value: string): boolean => {
  const openIndex = value.indexOf('[');
  const closeDotIndex = value.indexOf('].');
  return openIndex !== -1 && closeDotIndex !== -1;
};

const capitalizeFirstOfSentence = (str: string): string => {
  if (!str || typeof str !== 'string') return '';
  console.log('str', str);
  const sentences = str.replace(/\s+/g, ' ').trim().split('.');
  // console.log('sentences', sentences);
  const capitalized: string[] = [];
  sentences.forEach((sentence: string) => {
    capitalized.push(sentence.trim().charAt(0).toUpperCase() + sentence.trim().slice(1));
  });
  return capitalized.join('. ');
};

const string = {
  toString,
  includesBracket,
  isEmpty,
  capitalizeFirstOfSentence,
};
export default string;
