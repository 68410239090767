import React from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import Notifications from "react-notification-system-redux";
import {
  ModalUi,
  TitleComponent,
  FlashMessage,
  Filter,
  Filterable,
  Table,
  AddButton,
} from "_components";
import {
  loadingActions,
  searchAction,
  decrementCheckedFieldsCount,
  incrementCheckedFieldsCount,
  resetCheckedFieldsCount,
  hasPermissionTo,
} from "_actions";
import { orgActions } from "./actions";
import CreateOrgForm from "./component/CreateOrgForm";
import OrganizationChart from "./component/OrganisationChart";
import "react-orgchart/index.css";
import organizationGraphic from "../../images/bannerGraphicImages/organisations.png";
import orgImage from "../../images/emptyStateImages/organisations.png";

const heading = [
  {
    id: 1,
    headerName: "Organisation Name",
    key: "name"
  },
  {
    id: 2,
    headerName: "Organisation Type",
    key: "extendedProperties",
    subKey: "type"
  },
  {
    id: 3,
    headerName: "Parent Organisation",
    key: "parent"
  },
  {
    id: 4,
    headerName: "Status",
    key: "status"
  },
  {
    id: 5,
    headerName: "",
    editButton: true,
    icons: [
      {
        name: "pencil",
        id: 'code',
        actionName: 'editPress',
      }
    ]
  }
];

class OrganizationModule extends Filterable {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      name: "",
      phone: "",
      multiline: "",
      email: "",
      hint: "",
      label: "",
      status: [],
      currentView: "orgListing",
      isGettingList: false,
      pagination: { page: 0, perPage: 10 },
      filterOptions: this.props.filterOptions,
    };

    this.handleEdit.bind(this);
  }

  toggle = async () => {
    const canCreateOrg = await this.props.hasPermissionTo("Create Organisation");
    canCreateOrg && this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  async componentWillMount() {
    const { filterOptions, keepFilters, resetKeepFilters } = this.props;
    if (keepFilters) {
      this.handleFilter(filterOptions);
    } else {
      this.setState({isGettingList: true});
      await this.props.getList(0, 10);
      this.setState({isGettingList: false});
    }
    resetKeepFilters();
  }

  componentWillReceiveProps(props) {
    this.setState({
      modal: props.modalClose
    });

    const newProps = props;
    if (JSON.stringify(newProps.filterOptions) !== JSON.stringify(this.state.filterOptions)) {
      this.setState({ filterOptions: newProps.filterOptions });
    }

    if (props.notification === "success") {
      let notificationMessage = {
        // uid: 'once-please', // you can specify your own uid if required
        title: "Organisation created successfully.",
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Close",
          callback: () => console.log("close")
        }
      };

      this.props.notifications(notificationMessage, "success");
      setTimeout(() => {
        this.props.removeNotification();
      }, 900);
    }
  }

  handleSubmit = (data) => this.props.createOrg(data);

  /**
   * If you want to access the event properties in an asynchronous way, 
   * you should call event.persist() on the event, 
   * which will remove the synthetic event from the pool and allow 
   * references to the event to be retained by user code.
   * event.persist() doesn't imply that currentTarget
   * This means that if you want to access currentTarget in async way, you need to cache it in a variable
   * so we use destructing method to get currentTarget
   */
  handleEdit = async ({currentTarget}) => {
    const canEditOrganisation = await this.props.hasPermissionTo("Edit Organization");
    canEditOrganisation && this.props.history.push(
      `/organizationsManagement/${currentTarget.dataset.name}`
    );
  };
  
  goToDetails = async (organization) => {
    const canEditOrganisation = await this.props.hasPermissionTo("Edit Organization");
    canEditOrganisation && this.props.history.push(`/organizationsManagement/${organization.code}`);
    // canEditOrganisation && this.props.history.push(`/organizationsManagement/${organization.name}`);
  };

  onFilterCheckChange = (newFilterOptions) => {
    /** change inner state */
    this.setState({
      filterOptions: newFilterOptions,
    });
  }

  cancelFilter = () => {
    /** change inner state */
    const { filterOptions } = this.props;
    this.setState({
      filterOptions,
    });
  }

  applyFilter = (newFilterOptions) => {
    /** change both inner state and props state */
    this.setState({ filterOptions: newFilterOptions });
    const resetPage = true;
    this.handleFilter(newFilterOptions, resetPage);
  }

  render() {
    const {modal, currentView, isGettingList, filterOptions} = this.state;
    const {
      orgList, collections, canViewList,
      getOrganisationsChart,
      pagination, hasRecords, orgChart
    } = this.props;
    // if (!Array.isArray(orgList)) return <div className="loader" />;
    // if (!canViewList) return (<FlashMessage flashMessage="you are not allowed to view Organisations."/>);
    const isFilterLoading = !filterOptions || filterOptions.length === 0;
    return (
      <>
      <div className={'loader '+ (!Array.isArray(orgList) ? '' : 'd-none')} />
      <div className={'container-fluid px-0 '+ (!Array.isArray(orgList) ? 'd-none' : 'd-block')}>
        <TitleComponent
          title="Organisations"
          bannerGraphic={organizationGraphic}
        />
        <div className="row d-flex justify-content-center my_30">
            <Col sm="11" className="my-2">
              <div className="d-flex justify-content-between">
                <div>
                  {currentView === "orgListing"
                  && <Filter
                  filterOptions={filterOptions || []}
                  isLoading={isFilterLoading}
                  applyFilter={(newFilterOptions) => this.applyFilter(newFilterOptions)}
                  cancelFilter={() => this.cancelFilter()}
                  onCheckChange={(newFilterOptions) => this.onFilterCheckChange(newFilterOptions)}
                />
                  
                  }
                </div>
                <div>
                  <button
                    className={`
                    bg-transparent mx-2 org-icon
                    ${currentView === "orgChart" ? "text-primary" : ""} 
                    cursor-pointer
                    `}
                    type="button"
                    style={{fontSize: "1.2em"}}
                    onClick={() => this.setState({currentView: "orgChart" })}
                  >
                    <i className="icon-stats"/>
                  </button>
                  <button
                    className={`
                    org-icon
                    ${currentView === "orgListing" ? "text-primary" : ""} 
                    active mx-2
                    cursor-pointer
                    `}
                    type="button"
                    style={{fontSize: "1.2em"}}
                    onClick={() => this.setState({currentView: "orgListing"})}
                  >
                    <i className="icon-list"/>
                  </button>
                </div>
              </div>
            </Col>
            {
              currentView === "orgListing" ? (
                <div className="col-sm-11">
                  <Table
                    titles={heading}
                    records={orgList !== undefined ? orgList : []}
                    hasRecords={hasRecords}
                    image={orgImage}
                    goToDetails={this.goToDetails}
                    handleSearchKeyword={(obj) => this.handleChange("hint", obj.value)}
                    searchKeyword={this.state.hint}
                    handlePageClick={this.handlePagination}
                    pagination={{ ...pagination, perPage: this.state.pagination.perPage }}
                  >
                    <AddButton
                      text="Add Organisation"
                      onClick={this.toggle}
                    />
                  </Table>
                  <ModalUi
                    title="Create Organisation"
                    isOpen={modal}
                    toggle={this.toggle}
                  >
                    <CreateOrgForm
                      handleSubmmit={this.handleSubmit}
                      collections={collections}
                      toggle={this.toggle}
                      getList={this.props.getList}
                      // commissionType={
                      //   collections !== undefined ? collections.commissionType : []
                      // }
                      // orgType={collections !== undefined ? collections.orgUnitType : []}
                      // orgParents={collections !== undefined ? collections.parents : []}
                      // dataScope={collections ? collections.dataScope : []}
                      // use this prop method "getList" to get updated list after creating new organization
                    />
                  </ModalUi>
                </div>
              ) : (
                <OrganizationChart getOrganisationsChart={getOrganisationsChart} orgChart={orgChart}/>
              )
            }
        </div>
      </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    orgList, canViewList,
    hasRecords,
    collections,
    filterOptions,
    notification,
    modalClose,
    pagination,
    orgChart,
    keepFilters,
  } = state.organisations;
  const { loading, searchValue } = state;
  return {
    orgList,
    canViewList,
    hasRecords,
    collections,
    filterOptions,
    notification,
    modalClose,
    pagination,
    loading,
    searchValue,
    orgChart,
    keepFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applyFilters: (filterObject) => dispatch(orgActions.applyFilters(filterObject)),
    handleOptionChanged: (filterPayload) => dispatch(orgActions.handleOptionChanged(filterPayload)),
    getList: (page, perPage) => dispatch(orgActions.getList(page, perPage)),
    createOrg: (data) => dispatch(orgActions.createOrg(data)),
    notifications: (message, type) =>
      dispatch(Notifications.show(message, type)),
    removeNotification: () => dispatch(orgActions.updateNotification()),
    startLoading: () => dispatch(loadingActions.startLoading()),
    endLoading: () => dispatch(loadingActions.endLoading()),
    updateSearchValue: (value) => dispatch(searchAction(value)),
    incrementCheckedFieldsCount: () => dispatch(incrementCheckedFieldsCount()),
    decrementCheckedFieldsCount: () => dispatch(decrementCheckedFieldsCount()),
    resetCheckedFieldsCount: () => dispatch(resetCheckedFieldsCount()),
    hasPermissionTo: (scope) => dispatch(hasPermissionTo(scope)),
    getOrganisationsChart: () => dispatch(orgActions.getOrganisationsChart()),
    resetKeepFilters: () => dispatch(orgActions.resetKeepFilters()),

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationModule);
