const main = [
  { id: 'Full Name', name: 'Full Name' },
  { id: 'Nationality', name: 'Nationality' },
  { id: 'CNIC Number', name: 'CNIC Number' },
  { id: 'CNIC Issuance Date', name: 'CNIC Issuance Date' },
  { id: 'Passport Number', name: 'Passport Number' },
  { id: 'DOB', name: 'Date of Birth' },
  { id: 'Gender', name: 'Gender' },
  { id: 'Address', name: 'Address' },
  { id: 'City', name: 'City / Town' },
  { id: 'State', name: 'State / Province / Region' },
  { id: 'Country', name: 'Country' },
  { id: 'Postcode', name: 'Postcode' },
  { id: 'Email Address', name: 'Email Address' },
  { id: 'Contact Number', name: 'Phone Number' },
];

const beneficiary = [
  { id: 'Full Name', name: 'Full Name' },
  { id: 'Relationship', name: 'Relationship' },
  { id: 'ID Type', name: 'ID Type' },
  { id: 'ID Number', name: 'ID Number' },
  { id: 'Address', name: 'Address' },
  { id: 'City', name: 'City / Town' },
  { id: 'State', name: 'State / Province / Region' },
  { id: 'Country', name: 'Country' },
  { id: 'Postcode', name: 'Postcode' },
  { id: 'Email', name: 'Email Address' },
  { id: 'Contact Number', name: 'Phone Number' },
];

const other = [
  { id: 'Full Name', name: 'Full Name' },
  { id: 'Nationality', name: 'Nationality' },
  { id: 'CNIC Number', name: 'CNIC Number' },
  { id: 'CNIC Issuance Date', name: 'CNIC Issuance Date' },
  { id: 'Passport Number', name: 'Passport Number' },
  { id: 'DOB', name: 'Date of Birth' },
  { id: 'Gender', name: 'Gender' },
];

export { main, beneficiary, other };
