import numeral from "numeral";
import moment from "moment";
import { dateLib } from '@xc-core/lib';
import products from "./products";
import validations from "./validations";
import lengths from "./lengths";
import userMessages from "./userMessages";
import constants from "./constants";
import lib from "./lib";
import useToggle from "./hooks";
import * as dobRanges from './dobRanges';

const getCurrentUser = () => {
  const user = sessionStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

const currentUser = getCurrentUser();
const config = currentUser && currentUser.config;
const CONFIG_DATE_MOMENT_FORMAT = config && config.dateFormat ? config.dateFormat : "DD/MMM/YYYY";
const dateFormatWithTime = config && config.dateTimeFormat ? config.dateTimeFormat : "DD/MM/YYYY h:mm A";
const CONFIG_DATE_PICKER_FORMAT = config && config.dateFormatServer ? config.dateFormatServer : "dd/MMM/yyyy";
const DATE_PICKER_FORMATS = [CONFIG_DATE_PICKER_FORMAT, 'dd/MM/yyyy'];
const ISO_UTC_DATE_TIME = "YYYY-MM-DDTHH:mm:ss.SSSZ";


const makeThousandsSeparated = (numberToTransform = "") => {
	if (numberToTransform === null) return "";

	const numberArray = `${numberToTransform}`.split(".");
	const number = numberArray[0];
	let tracker = 0;
	/**
   * We reverse the resulting Array because the reduce goes from the first to the last
   * However, we should reverse again at the end before returning to return the appropriete Number
   * @let {myNumber}
   * @type {string}
   */
	let myNumber = number.split("").reverse().reduce((accumulator, num) => {
		let tempNumber = accumulator.concat(num);
		tracker += 1;
		if (tracker === 3) {
			tempNumber = tempNumber.concat(".");
			tracker = 0;
		}
		return tempNumber;
	}, "");

	if (myNumber[myNumber.length - 1] === ".") {
		const digitsArray = myNumber.split("");
		myNumber = digitsArray.slice(0, digitsArray.length - 1).join("");
	}

	return myNumber.split("").reverse().join("").concat(
		numberArray.length > 1 ? `,${numberArray[1]}` : "",
	);
};

const getRequestOptions = (parameters = {}) => {
	/**
   * parameters should be an object containing fields like body/method
   */

	if (parameters === null) parameters = {};

	const { body, method = "GET", contentType = "application/json" } = parameters;
  let headers = { "X-Auth-Token": currentUser ? currentUser.token : "" }
  if(contentType === "application/json") headers['Content-Type'] = 'application/json'

	return ({
		headers: new Headers(headers),
		method,
		body: body ? (contentType === "application/json") ? JSON.stringify(body) : body : null,
	});
};

const createFilterParams = (val = []) => {
  if (!Array.isArray(val)) return "";
  let stringParam = "";
  val.forEach((data) => {
    if (data.list) {
      if (data.type === "dateRange" && data.checkedCount > 0) {
        const dateFormat = "DD/MM/YYYY";
        const startDate = encodeURIComponent(moment(data.list.find((d) => d.code === "startDate").value).format(dateFormat));
        const endDate = encodeURIComponent(moment(data.list.find((d) => d.code === "endDate").value).format(dateFormat));
        stringParam = `${stringParam}&${data.filterProperty}=${startDate},${endDate}`;
      } else {
        data.list.forEach((option) => {
          if (option.isChecked === true) {
            const value = option.code ? encodeURIComponent(option.code) : encodeURIComponent(option.name);
            stringParam = `${stringParam}&${data.filterProperty}=${value}`;
          }
        });
      }
    }
  });

  /**
   * remove the "&" from the first index 0
   */
	return stringParam.slice(1);
};

const processFilters = (serverFilters = {}) => {
	let parsedServerData = [];
	serverFilters.forEach((serverFilter) => {
		let tempList = [];
		serverFilter.list.forEach((list) => {
			let tempListItem = {
				...list,
				isChecked: false,
				group: serverFilter.filterProperty,
			};
			tempList.push(tempListItem);
		});
		const tempFilter = {
			...serverFilter,
			checkedCount: 0,
			list: tempList,
			name: serverFilter.displayName,
		};
		parsedServerData.push(tempFilter);
	});
	return parsedServerData;
};

const isValidDate = (str) => {
	if (!str) return false;

	const d = new Date(str);
	return d instanceof Date && !isNaN(d);
};
/**
 *
 * @param {string, number} currency - the currency to format
 * @return {string} - formatted currency number
 */
const formatCurrency = (currency) => { 
  if (!currency || currency === "") return null;
  let num = numeral(currency);
	return num.format(config.currencyFormat);
};
const formatDate = (date, options) => {
	if (!date || date === "" || moment(date).format() === "Invalid date") return "";
	if (options && options.withTime) return moment(date).format(dateFormatWithTime);
	return moment(date).format(CONFIG_DATE_MOMENT_FORMAT);
};

/**
 * @function setHasRecords
 * @param {Array} list - an array of the item we are getting
 * @return {boolean} - value of whether there are records or not
 */
function setHasRecords(list) {
	return list.length !== 0;
}
function getHeadings(fields) {
  const headers = [];
  headers.push(
    {
      id: "viewChildren",
      viewChildren: true,
      headerName: "",
    },
  );
  fields.forEach((field, index) => {
    if (!field.id.includes(".")) {
      headers.push({
      	id: index,
      	headerName: field.displayName,
      	key: field.id,
      });
    } else {
    const keys = field.id.split(".");
    if (keys.length === 2) {
    	headers.push({
    		id: index,
    		headerName: field.displayName,
    		key: keys[0],
    		subKey: keys[1],
    	});
    } else {
    		headers.push({
    			id: index,
    			headerName: field.displayName,
    			key: keys[0],
    			subKey: keys.slice(1).join(","),
    		});
    	}
    }
  });
  // add the edit button on the table row
  if (headers.length) {
    headers.push({
      id: "editButton",
      editButton: true,
      headerName: "",
      icons: [
        {
          name: "file",
          id: "id",
          actionName: "goToDetails",
        },
      ],
    });
  }
  return headers;
}
const isDatePassed = (date) => {
	const currentDate = new Date();
	const currentNum = Number(moment(currentDate, CONFIG_DATE_MOMENT_FORMAT).format("YYYYMMDD"));
	const dateNum = Number(moment(date, CONFIG_DATE_MOMENT_FORMAT).format("YYYYMMDD"));
	return currentNum > dateNum;
};
const isDateInFuture = (date) => {
	const currentDate = new Date();
	const currentNum = Number(moment(currentDate, CONFIG_DATE_MOMENT_FORMAT).format("YYYYMMDD"));
	const dateNum = Number(moment(date, CONFIG_DATE_MOMENT_FORMAT).format("YYYYMMDD"));
	return currentNum < dateNum;
};
const isAfter = (date1, date2) => {
  const num1 = Number(moment(date1, CONFIG_DATE_MOMENT_FORMAT).format("YYYYMMDD"));
  const num2 = Number(moment(date2, CONFIG_DATE_MOMENT_FORMAT).format("YYYYMMDD"));
  return num1 > num2;
};
const utils = {
	products, validations, lengths, userMessages, lib, constants,
}; /** for having a namespace */

const ageBoundaryRule = (val) => {

  if (!val) return false;

  const selectedDate = moment(val.value,
    CONFIG_DATE_MOMENT_FORMAT).format(CONFIG_DATE_MOMENT_FORMAT);
  const { maxAge, minAge } = val.validationParams;
  const today = dateLib.getCurrentDate(CONFIG_DATE_MOMENT_FORMAT);
  let yearsDiff = dateLib.getDiff(today, selectedDate, 'years');
  if (yearsDiff === 0) { // when selected date is bigger than today
    yearsDiff = dateLib.compare(today, selectedDate, CONFIG_DATE_MOMENT_FORMAT);
  }
  return (yearsDiff >= 0) ? yearsDiff <= maxAge && yearsDiff >= minAge : false;
  };

const ktpIdNumberDobInFutureRule = {
    message: "The ID number is not valid",
    rule: (value, params, validator) => {
      const dobM = calculateDobMomentFromKTP(value);
      return dobM.isValid() ? isDateInFuture(dobM.format(CONFIG_DATE_MOMENT_FORMAT)) === false : true;
    },
};

const cnicIdNumberDobInFutureRule = {
  message: "The ID number is not valid",
  rule: (value, params, validator) => {
    const dobM = calculateDobMomentFromCNIC(value);
    return dobM.isValid() ? isDateInFuture(dobM.format(CONFIG_DATE_MOMENT_FORMAT)) === false : true;
  },
};

const icIdNumberDobInFutureRule = {
  message: "The ID number is not valid",
  rule: (value, params, validator) => {
    const dobM = calculateDobMomentFromIC(value);
    return dobM.isValid() ? isDateInFuture(dobM.format(CONFIG_DATE_MOMENT_FORMAT)) === false : true;
  },
};

const maximumReturnDaysValidationRule = ({ startDate, endDate, maxNoOfDay }, params, validator) => {
  const sM = moment(startDate, CONFIG_DATE_MOMENT_FORMAT);
  const eM = moment(endDate, CONFIG_DATE_MOMENT_FORMAT);
  const noOfDays = moment(eM.format("L")).diff(moment(sM.format("L")), "days") + 1;
  return noOfDays <= maxNoOfDay;
};
const getOneYearInclusiveEndMoment = (str) => moment(str, CONFIG_DATE_MOMENT_FORMAT).add(1, "year").subtract(1, "day");
const getMaximumReturnTripEndDate = (mnt, maxNum) => mnt.add(maxNum - 1, 'days')._d;

const isLeapYear = (year) => moment([year]).isLeapYear();

const updateIndicesByType = (indices, type) => {
  switch (type) {
    case "Adult":
      indices.adult += 1;
      return indices.adult;
    case "Senior Citizen":
      indices.senior += 1;
      return indices.senior;
    case "Child":
      indices.child += 1;
      return indices.child;
    default:
      return indices;
  }
};

const calculateGenderFromKTP = (val) => {
  let birthGender = "";
  let birthDate = Number(String(val).substr(6, 2));
  // Auto-populate Gender field based on ID number
  // Indonesia id card adds 40 to female birth date
  if (birthDate > 40) {
    birthGender = "Female";
    birthDate -= 40; // Deduct 40 so that we can use the real birth date to autofill DOB
  } else if (birthDate < 40) {
    birthGender = "Male";
  }
  return birthGender;
};

const calculateDobMomentFromKTP = (val) => {
  // Birth date on indonesian id card starts at the 7th digit
  // For example 012345[020993]3456 = 02-09-93 with format DD-MM-YYYY
  // so basically we'll get the first 6 digits starting from the 7th digit (zero based index)
  let birthDate = Number(String(val).substr(6, 2));
  const birthMonth = String(val).substr(8, 2);
  let birthYear = Number(String(val).substr(10, 2));

  // Auto-populate Gender field based on ID number
  // Indonesia id card adds 40 to female birth date
  if (birthDate > 40) {
    birthDate -= 40; // Deduct 40 so that we can use the real birth date to autofill DOB
  }
  // if (birthMonth > 12) {
  //   return; // more than 12 will cause DOB autofill to crash
  // }
  const maxAgeLimiter = 90;
  const maxInsuredYear = Number(String(moment().year() - maxAgeLimiter).substr(2, 2));
  if (birthYear < 10) {
    birthYear = `0${birthYear}`;
  }
  if (birthYear < maxInsuredYear) {
    birthYear = `20${String(birthYear)}`;
  } else if (birthYear >= maxInsuredYear) {
    birthYear = `19${String(birthYear)}`;
  }

  return moment(`${birthYear}-${birthMonth}-${String(birthDate)}`);
};

const calculateDobMomentFromCNIC = (val) => {
  // Birth date on indonesian id card starts at the 7th digit
  // For example 012345[020993]3456 = 02-09-93 with format DD-MM-YYYY
  // so basically we'll get the first 6 digits starting from the 7th digit (zero based index)
  let birthDate = Number(String(val).substr(6, 2));
  const birthMonth = String(val).substr(8, 2);
  let birthYear = Number(String(val).substr(10, 2));
  // Auto-populate Gender field based on ID number
  // Indonesia id card adds 40 to female birth date
  if (birthDate > 40) {
    birthDate -= 40; // Deduct 40 so that we can use the real birth date to autofill DOB
  }

  const maxAgeLimiter = 90;
  const maxInsuredYear = Number(String(moment().year() - maxAgeLimiter).substr(2, 2));
  if (birthYear < 10) {
    birthYear = `0${birthYear}`;
  }
  if (birthYear < maxInsuredYear) {
    birthYear = `20${String(birthYear)}`;
  } else if (birthYear >= maxInsuredYear) {
    birthYear = `19${String(birthYear)}`;
  }
  const dobM = moment(`${birthYear}-${birthMonth}-${String(birthDate)}`);
  return dobM;
};

const calculateGenderFromCNIC = (val) => {
  // Birth date on indonesian id card starts at the 7th digit
  // For example 012345[020993]3456 = 02-09-93 with format DD-MM-YYYY
  // so basically we'll get the first 6 digits starting from the 7th digit (zero based index)
  const birthDate = Number(String(val).substr(6, 2));
  let birthGender = "";
  // Auto-populate Gender field based on ID number
  // Indonesia id card adds 40 to female birth date
  if (birthDate > 40) {
    birthGender = "Female";
  } else if (birthDate < 40) {
    birthGender = "Male";
  }
  return birthGender;
};

const calculateGenderFromIC = (val) => {
  // yymmdd#####x, length of 12
  // x is gender, odd for male and even for female
  const genderIndicator = Number(String(val).substr(utils.lengths.IC - 1, 1));
  return genderIndicator % 2 === 0 ? "Female" : "Male";
};

const calculateDobMomentFromIC = (val) => {
  // yymmdd#####x, length of 12
  const birthDate = Number(String(val).substr(4, 2));
  const birthMonth = String(val).substr(2, 2);
  let birthYear = Number(String(val).substr(0, 2));
  // if (birthMonth > 12) {
  //   return;// more than 12 will cause DOB autofill to crash
  // }
  const maxAgeLimiter = 90;
  const maxInsuredYear = Number(String(moment().year() - maxAgeLimiter).substr(2, 2));
  if (birthYear < 10) {
    birthYear = `0${birthYear}`;
  }
  if (birthYear < maxInsuredYear) {
    birthYear = `20${String(birthYear)}`;
  } else if (birthYear >= maxInsuredYear) {
    birthYear = `19${String(birthYear)}`;
  }
  const dobM = moment(`${birthYear}-${birthMonth}-${birthDate}`);
  return dobM;
};

const convertToInt = (value) => {
  if (!value) return 0;

  const valueInInt = parseInt(value, 10);
  return Number.isNaN(valueInInt) ? 0 : valueInInt;
};

const arrayHasDuplicates = (array) => {
  const duplicates = array && array.filter((item, index) => array.indexOf(item) !== index);
  return duplicates.length !== 0;
};

const removeKey = (obj, deleteKey) => {
  let clone = JSON.parse(JSON.stringify(obj))
  delete clone[deleteKey];
  return clone;
}

export {
  utils,
  useToggle,
  makeThousandsSeparated,
  getRequestOptions,
  createFilterParams,
  processFilters,
  CONFIG_DATE_MOMENT_FORMAT,
  isValidDate,
  formatCurrency,
  formatDate,
  currentUser,
  config,
  setHasRecords,
  getHeadings,
  isDatePassed,
  isDateInFuture,
  ISO_UTC_DATE_TIME,
  ageBoundaryRule,
  getOneYearInclusiveEndMoment,
  isAfter,
  CONFIG_DATE_PICKER_FORMAT,
  maximumReturnDaysValidationRule,
  getMaximumReturnTripEndDate,
  updateIndicesByType,
  calculateGenderFromKTP,
  calculateDobMomentFromKTP,
  ktpIdNumberDobInFutureRule,
  cnicIdNumberDobInFutureRule,
  icIdNumberDobInFutureRule,
  calculateGenderFromIC,
  calculateDobMomentFromIC,
  calculateGenderFromCNIC,
  calculateDobMomentFromCNIC,
  isLeapYear,
  DATE_PICKER_FORMATS,
  dobRanges,
  convertToInt,
  arrayHasDuplicates,
  removeKey,
};
