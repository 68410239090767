import { dateLib, objLib } from '@xc-core/lib';
import config from "../../../@xc-core/config";

function defaultDisabled() {
  return false;
}

function defaultHandler(pair) {
  return [pair];
}

const model = {
  header: {
    id: '',
    name: '',
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    items: [
      {
        current: { id: 'properties.Price.Total', name: '', config: { currency: true } },
        prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: 'Price Breakdown',
        name: 'Price Breakdown',
        items: [
          {
            current: { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Main Policy', name: 'Gross Premium', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Admin Fee', name: 'Admin Fee', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Admin Fee', name: 'Admin Fee', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      {
        id: 'Other Details',
        name: 'Policy Details',
        items: [ 
          {
            current: {
              id: 'properties.Occupation Class',
              name: 'Occupation Class',
              type: 'Select',
              collectionId: 'occupationClass',
              disabled: defaultDisabled,
              handler: defaultHandler,
              ext: ' / ចំណាត់ថ្នាក់ ឬមុខរបរ',
              config: {}
            },
            prev: { id: 'properties.Occupation Class', name: 'Occupation Class', ext: ' / ចំណាត់ថ្នាក់ ឬមុខរបរ', config: {} },
            config: {isEditable: true},
          },
          {
            current: {
              id: 'properties.Plan',
              name: 'Plan',
              type: 'Select',
              collectionId: 'plans',
              disabled: defaultDisabled,
              handler: defaultHandler,
              ext: '',
              config: {isEditable: true}
            },
            prev: { id: 'properties.Plan', name: 'Plan', ext: '', config: {isEditable: true} },
            config: {isEditable: true},
          }
        ],
        config: { isEditable: true },
      }
    ],
  },
};

export default model;
