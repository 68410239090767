
import BaseReducer from './base';

export default abstract class BaseListingReducer<T extends IObject> extends BaseReducer<T> {
  protected setTab = (state:T, action: IAction):T => ({
    ...state,
    tab: action.payload,
  })

  protected setSearchKeyword = (state:T, action: IAction):T => ({
    ...state,
    searchKeyword: action.payload,
  })

  protected setPageNumber = (state:T, action: IAction):T => ({
    ...state,
    pageInfo: {
      ...state.pageInfo,
      pageNumber: action.payload,
    },
  })

  protected setPageSize = (state:T, action: IAction):T => ({
    ...state,
    pageInfo: {
      ...state.pageInfo,
      pageSize: action.payload,
    },
  })

  protected applyFilter = (state:T, action: IAction):T => ({
    ...state,
    filters: [...action.payload],
    prevFilters: [...action.payload],
  })

  protected changeFilter = (state:T, action: IAction):T => ({
    ...state,
    filters: [...action.payload],
  })
}
