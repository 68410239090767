import moment from 'moment';
import { CONFIG_DATE_MOMENT_FORMAT } from '../../../utils';

/**
 * Days to subscribe should be either 0 or 364
 * @param yearsFromNow (this is the number of years to subtract from today)
 * @param monthsToSubtract
 * @param daysToSubtract (this is the number of days before getting 
 * into another category ex: from adult to senior)
 * @return Moment string date
 */
// const getDateByYearMonthDay = (yearsFromNow = 0, monthsToSubtract = 0, daysToSubtract = 0) => {
//     return moment(new Date())
//       .subtract(`${yearsFromNow}`, 'years')
//       .subtract(`${monthsToSubtract}`, 'months')
//       // @ts-ignore
//       .subtract(`${daysToSubtract}`, 'days')._d;
//   };

  /* since we cannot calculate accurate DOB based on age, we add 365 days to get an estimate of
boundary and validate data when user selects the actual DOB  */

/** A Child’s valid age should be 0 to 17 years old in days. */
/** (0 year 0 month 0 day) to  (17 years 365 days) */
const childrenRange = {
    min:{
      years: 0,
      months: 0,
      days: 0
    },
    max:{
      years: 17,
      months: 0,
      days: 365
    }
  };
  
  /** An Adult’s valid age should be 18 to 65 years old in days. */
  /** (18 years 0 month 0 day) to (65 years 365 days) */
  const adultRange = {
    min:{
      years: 18,
      months: 0,
      days: 0
    },
    max:{
      years: 65,
      months: 0,
      days: 365
    }
    
  };
  
  /** A Senior Citizen’s valid age should be 66 to 80 years old in days. */
  /** (66 years 0 month 0 day) to (80 years 365 days) */
  const seniorRange = {
    min:{
      years: 66,
      months: 0,
      days: 0
    },
    max:{
      years: 80,
      months: 0,
      days: 364
    }
  };

  const dobRanges = {
    'Adult' :adultRange,
    'Children': childrenRange,
    'Senior Citizen': seniorRange
  }


const properties : IObject = {
  'Start Date': moment().format(CONFIG_DATE_MOMENT_FORMAT),
  'End Date': moment().format(CONFIG_DATE_MOMENT_FORMAT),
};

const country = 'Nepal';

const insuredType = 'Individual';

const stateListParam = 'state';

const cnicLength = 13;

const postcodeLength = 5;

const debitCreditNoLength = 16;

const defaults = {
  childrenRange,
  adultRange,
  seniorRange,
  country,
  stateListParam,
  cnicLength,
  postcodeLength,
  debitCreditNoLength,
  properties,
  insuredType,
  dobRanges
};

export default defaults;
