
import { DataDumpService } from '@xc-core/services';
import NotificationAction from './notification';
import BaseListAction from './baseListing';

class DataDumpActions extends BaseListAction {
  constructor() {
    super();
    this.service = new DataDumpService();
  }

  // protected actions = actionTypes.auditLog;
  protected actions = {};

  protected service: DataDumpService;

  protected csvExportScope = '';

  protected createScope = '';

  protected editScope = '';

  public setList = () => async () => undefined

  public getSearchParams = () => (): IRequestParam => ({});

  public setHasRecordsInitially= () => async () => {
  }

  public generateFiles = (date: string) => async (dispatch: any) => {
    const errorMsg = 'Oops, something went wrong. Please try again.';
    const notificationAction = new NotificationAction();
    try {
      const res = await this.service.generateFiles(date);
      if (res.status === 'Success') {
        dispatch(notificationAction.success('success', 'Sales File Generation initiated. It may take a few minutes. It will be transferred to GBSN SFTP folder once completed.'));
      } else {
        dispatch(notificationAction.error('error', errorMsg));
      }
    } catch (error) {
      dispatch(notificationAction.error('error', errorMsg));
      console.error('dataDump file generation', error);
    }
  }
}


export default new DataDumpActions();
