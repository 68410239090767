
const addExtraPropertiesToFilters = (filters = []) => {
  const filtersWithExtraProperties = [];
  filters.forEach((filter) => {
    const tempList = [];
    filter.list.forEach((listItem) => {
      const tempItem = {
        ...listItem,
        isChecked: false,
        group: filters.filterProperty,
      };
      if (filter.type === "dateRange") {
        tempItem.value = "";
      }
      tempList.push(tempItem);
    });
    const tempFilter = {
      ...filter,
      checkedCount: 0,
      list: tempList,
      name: filter.displayName,
    };
    filtersWithExtraProperties.push(tempFilter);
  });
  return filtersWithExtraProperties;
};

const getTodayFilterOptions = (filterOptions) => {
  let newFilterOptions = [...filterOptions];
  const createdOnIndex = newFilterOptions.findIndex((filter) => filter.id === "createdOn");

  const newValues = newFilterOptions[createdOnIndex].list.map((value) => {
    const filterValue = value;
    filterValue.value = new Date();
    filterValue.isChecked = true;
    return filterValue;
  });
  const changedOption = { ...newFilterOptions[createdOnIndex], checkedCount: 1, list: newValues };
  newFilterOptions = [
    ...newFilterOptions.slice(0, createdOnIndex),
    { ...changedOption },
    ...newFilterOptions.slice(createdOnIndex + 1),
  ];
  return newFilterOptions;
};

const filterUtil = { addExtraPropertiesToFilters, getTodayFilterOptions };
export default filterUtil;
