import React from 'react';
import StepConnector from '@material-ui/core/StepConnector';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { withStyles } from '@material-ui/core/styles';
import { utils } from "utils"
import lib from 'utils/lib';

const styles = (theme: any) => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    width: '90%',
  },
  removePadding: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#fcfcfc',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100],
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
    borderTopWidth: 3,
    borderRadius: 7,
  },
});

interface IProps { currentStep: IStep, steps: IStep[], classes: any}

const CustomStepper = (props: IProps) => {
  const { steps, currentStep, classes } = props;

  const getTitle = () => {
    console.log(currentStep.order)
    switch (currentStep.order){
      case 1:
        return "Basic Details - Step 1 of 5"
      case 3:
        return "Insured Details - Step 2(a) of 5"
      case 4:
        return "Additional Drivers - Step 2(b) of 7"
      case 5:
        return "Summary - Step 3 of 5"
      case 6:
        return "Payment Details - Step 4 of 5"
      default:
        return `${currentStep.name} - Step ${currentStep.order} of ${steps.length} 
      ${(currentStep.khmerName && lib.checkIsDemoSite()) ? `/ ${currentStep.khmerName} - ជំហានទី${utils.constants.khmerNumber[currentStep.order - 1]}នៃជំហានទាំង${utils.constants.khmerNumber[steps.length - 1]}` : ''}`
    }
  };
  const show = () => currentStep.order < steps.length;

  const connector = (
    <StepConnector
      classes={{
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  );

  return (
    show() === false ? <></>
      : (
        <>
          <h5 className='text-medium'>{getTitle()}</h5>
          <Stepper
            className='stepper'
            orientation='horizontal'
            classes={{ horizontal: classes.removePadding }}
            activeStep={currentStep.order}
            connector={connector}
          >
            {steps.map((step:IStep) => (<Step key={step.name} />))}
          </Stepper>
        </>
      ));
};

export default withStyles(styles)(CustomStepper);
