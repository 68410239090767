
/**
 * this function will unify all events across application
 * @param e: event
 */
const getPair = (e: any): IPair => {
  const pair = { id: '', value: '' };
  if (!e) return pair;
  const id = e.target && e.target.id;
  let value = e.target && e.target.value;
  if (value.trim() === '') value = value.trim();
  return { id, value } as IPair;
};

const event = { getPair };
export default event;
