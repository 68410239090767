import React from "react";
import PropTypes from "prop-types";

const Header = (props) => {
  const { fields } = props;
  return (
    <thead className="tableHeader">
      <tr>
        {fields.map((field) => (
          <th key={field.id} scope="col">
            {field.headerName}
          </th>
        ))}
      </tr>
    </thead>
  );
};

Header.propTypes = {
  fields: PropTypes.array.isRequired,
};
export default Header;
