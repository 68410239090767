const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE";
const START_LOADING = "START_LOADING";
const END_LOADING = "END_LOADING";
const INCREMENT_CHECKED_FIELDS_COUNT = "INCREMENT_CHECKED_FIELDS_COUNT";
const DECREMENT_CHECKED_FIELDS_COUNT = "DECREMENT_CHECKED_FIELDS_COUNT";
const RESET_CHECKED_FIELDS_COUNT = "RESET_CHECKED_FIELDS_COUNT";
const SHOW_FORBIDDEN_MESSAGE = "SHOW_FORBIDDEN_MESSAGE";
const SET_FLASH_MESSAGES = "SET_FLASH_MESSAGES";
const HIDE_FORBIDDEN_MESSAGE = "HIDE_FORBIDDEN_MESSAGE";
const HANDLE_FILTER_CLICKED = "HANDLE_FILTER_CLICKED";
const CLEAR_FILTER_SELECTION = "CLEAR_FILTER_SELECTION";


export {
	UPDATE_SEARCH_VALUE,
	START_LOADING,
	END_LOADING,
	INCREMENT_CHECKED_FIELDS_COUNT,
	DECREMENT_CHECKED_FIELDS_COUNT,
	RESET_CHECKED_FIELDS_COUNT,
	HIDE_FORBIDDEN_MESSAGE,
	SHOW_FORBIDDEN_MESSAGE,
	SET_FLASH_MESSAGES,
	HANDLE_FILTER_CLICKED,
	CLEAR_FILTER_SELECTION,
};
