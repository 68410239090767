/* eslint-disable object-curly-newline */
import lib from "utils/lib";
import {dateLib} from "../../../@xc-core/lib";
import { modelId } from "../../../Products/shared/utils/modelUtil";

type ObjectFromList<T extends ReadonlyArray<string>, V = string> = {
  [K in T extends ReadonlyArray<infer U> ? U : never]: V;
};
type Type = ObjectFromList<typeof fields, string>;

const mainInsuredObjectBased = "properties.Main Insured";
const insuredPropertyObjectBased = "properties.Insured Property";
const beneficiaryObjectBased = "properties.beneficiaries";
const fields = [
  "Name",
  "Business Occupation",
  "Address",
  "Country",
  "City",
  "State",
  "Postcode",
  "Email Address",
  "Mobile Number",
];

const keyName = (modelType: "Individual" | "Company", keyList: string[]) => {
  let keyObjs: Type = keyList.reduce(
    (r, key): Type /* HERE */ => {
      return {
        ...r,
        [key.replace(/\s/g, "")]: `${modelType} ${key}`,
      };
    },
    {} as Type
  );

  return keyObjs;
};

const detailFieldModel = (
  modelType: "Individual" | "Company",
  basedPath: string
): IFieldModel2 => {
  const {
    Name,
    BusinessOccupation,
    Address,
    Country,
    City,
    State,
    Postcode,
    EmailAddress,
    MobileNumber: MobileNumber,
  } = keyName(modelType, fields) as Type;

  return {
    [Name]: {
      id: modelId(basedPath, Name),
      label: modelType === "Individual" ? "Full Name" : "Company Name",
      ext: "",
      type: "Input",
      placeholder: `Enter ${
        modelType === "Individual" ? "Full Name" : "Company Name"
      }`,
    },
    ...(modelType === "Company" && {
      "Company VATIN": {
        id: modelId(basedPath, "Company VATIN"),
        label: "VAT TIN",
        type: "Input",
        placeholder: "Enter VAT TIN",
      }
    }),
    [BusinessOccupation]: {
      id: modelId(basedPath, BusinessOccupation),
      label: "Business Occupation",
      type: "Input",
      placeholder: "Enter business occupation",
    },
    ...(modelType === "Individual" && {
      "Individual ID Type": {
        id: modelId(basedPath, "Individual ID Type"),
        label: "ID Type",
        ext: "",
        type: "Select",
        collectionId: "idTypes",
        placeholder: "Select Option IDType",
      },
      "Individual ID Number": {
        id: modelId(basedPath, "Individual ID Number"),
        label: "ID Number",
        ext: "",
        type: "Input",
        placeholder: "Enter ID number",
        handler:'handleAlphanumeric'
      },
    }),
    [Address]: {
      id: modelId(basedPath, Address),
      label: "Address",
      ext: "",
      type: "Input",
      placeholder: "Enter address",
      style:{col:12}
    },
    [Country]: {
      id: modelId(basedPath, Country),
      label: "Country",
      ext: "",
      type: "Select",
      collectionId:'countries',
      placeholder: "Select Option Country",
    },
    [State]: {
      id: modelId(basedPath, State),
      label: "City/Province",
      ext: "",
      type: "Select",
      placeholder: "Select Option City/Province",
      collectionId:'cb_auto_states'
    },
    [Postcode]: {
      id: modelId(basedPath, Postcode),
      label: "Postcode",
      ext: "",
      type: "Input",
      placeholder: "Enter postcode",
      handler: "handlePostcode",
    },
    [City]: {
      id: modelId(basedPath, City),
      label: "State/Region",
      ext: "",
      type: "Input",
      placeholder: "Enter State/Region",
    },
    [EmailAddress]: {
      id: modelId(basedPath, EmailAddress),
      label: "Email Address",
      ext: "",
      type: "Input",
      placeholder: "Enter email address",
    },
    [MobileNumber]: {
      id: modelId(basedPath, MobileNumber),
      label: "Mobile Number",
      ext: "",
      type: "Input",
      placeholder: "Enter mobile number",
      handler: "handleNumber",
    },
  };
};

const mainInsuredObjectModel: IFieldModel2={
    isPropertyBelongToCompany: {
      id: modelId(
          mainInsuredObjectBased,
          "Belongs to Company"
      ),
      label: "Does this property belongs to a Company?",
      type: "RadioButtonGroup",
      style:{col:12},
      collectionItem:[
        {
          label:'Yes',
          value:'Yes'
        },
        {
          label:'No, this is a Private property',
          value:'No'
        }
      ]
    }
};

const autoFilledCheckbox: IFieldModel2 = {
  autoFilledValue: {
    id: modelId(beneficiaryObjectBased, "Same to insured details"),
    label: "Same as main insured's details",
    type: 'Checkbox',
  },
}

const companyInsuredObjectModel: IFieldModel2={
  ...detailFieldModel('Company', mainInsuredObjectBased),
};

const individualInsuredObjectModel: IFieldModel2={
  ...detailFieldModel("Individual", mainInsuredObjectBased),
};

const companyBeneficiaryObjectModel: IFieldModel2={
  ...detailFieldModel('Company', beneficiaryObjectBased),
};

const individualBeneficiaryObjectModel: IFieldModel2={
  ...detailFieldModel("Individual", beneficiaryObjectBased),
};

const insuredPropertyObjectModel: IFieldModel2 = {
  "Registration No.": {
    id: modelId(insuredPropertyObjectBased, `Registration No`),
    label: "Registration No.",
    ext: "",
    type: "Input",
    placeholder: "Enter Registration No.",
  },
  "Chassis No./Engine No.": {
    id: modelId(insuredPropertyObjectBased, "Chassis No/Engine No"),
    label: "Chassis No./Engine No.",
    ext: "",
    type: "Input",
    placeholder: "Enter Chassis No./Engine No.",
  },
  "Make": {
    id: modelId(insuredPropertyObjectBased, "Make"),
    label: "Make",
    ext: "",
    type: "Select",
    collectionId:'cb_auto_make_model',
    placeholder: "Select Option Make",
    infoMessage:'Contact sales team of CB General Insurance if the car brand is not available in the options.'
  },
  "Model": {
    id: modelId(insuredPropertyObjectBased, "Model"),
    label: "Model",
    ext: "",
    type: "Select",
    collectionId:'cb_auto_model',
    placeholder: "Select Option Model",
    infoMessage:'Contact sales team of CB General Insurance if the car model is not available in the options.'
  },
  "Number of Seats": {
    id: modelId(insuredPropertyObjectBased, "Number of Seats"),
    label: "Number of Seats",
    ext: "",
    type: "Input",
    placeholder: "Enter number of seats",
    handler: "handleNumber",
  }
};

const basicDetailFieldsModel:IFieldModel2={
  "Product Type": {
    id: modelId("properties", "Product Type"),
    label: "Product Type",
    type: "Select",
    collectionId: "product_types",
    style:{col:12},
    placeholder: `Select Option Product`,
  },
  "Coverage Type": {
    id: modelId("properties", "Coverage Type"),
    label: "Coverage Type",
    type: "Select",
    collectionId: "coverage_types",
    style:{col:12},
    placeholder: `Select Option Coverage Type`,
  },
  "Cubic Capacity": {
    id: modelId("properties", "Cubic Capacity"),
    label: "Cubic Capacity",
    type: "Input",
    placeholder: `Enter Cubic Capacity`,
    style:{col:12},
    handler:'rangeNumberHandler',
  },
  "Year of Manufacture": {
    id: modelId("properties", "Property Age"),
    label: "Year of Manufacture",
    ext: "",
    type: "Select",
    collectionId:"years_of_manufacture",
    placeholder: "Select Option year of manufacture",
    style:{col:12},
    handler: "handleNumber"
  },
  "Sum Insured": {
    id: modelId("properties", "Sum Insured"),
    label: "Sum Insured",
    type: "Input",
    placeholder: `Enter Sum Insured`,
    style:{col:12},
    handler:`rangeNumberHandler`,
  },
  "Start Date": {
    id: modelId("properties", "Start Date"),
    label: "Start Date",
    type: "DatePicker",
    placeholder:'Select Date',
    getMax:()=>dateLib.getDOBRange({
      years:0,
      months:-3,
      days:1
    }),
    getMin:()=>dateLib.getDOBRange({
      years:0,
      months:0,
      days:0
    }),
  },
  "End Date": {
    id: modelId("properties", "End Date"),
    label: "End Date",
    type: "DatePicker",
    placeholder:'Select Date',
    disabled:()=> true,
  }
}

const paymentModel: IFieldModel2 = {
  paymentMode: {
    id: "properties.Payment Mode",
    label: "Mode of Payment",
    ext: " / មធ្យោបាយនៃការបង់ប្រាក់",
    type: "Select",
    collectionId: "paymentMethod",
    placeholder: `Select Option Payment`,
    style:{col:12}
  },
  receiptNo: {
    id: "properties.Receipt No",
    label: "Receipt No",
    ext: " / លេខវិក្កយប័ត្រ",
    type: "Input",
    placeholder: `Enter receipt no`,
    style:{col:12}
  },
  paymentReceivedBy: {
    id: "properties.Payment Received By",
    label: "Payment Received By",
    ext: " / ធ្វើការទូទាត់ដោយ",
    type: "Select",
    collectionId: "currentOuUsers",
    placeholder: `Select Option Received By`,
    style:{col:12}
  },
};

const fieldsModel= {
  ...basicDetailFieldsModel,
  ...mainInsuredObjectModel,
  ...companyInsuredObjectModel,
  ...individualInsuredObjectModel,
  ...insuredPropertyObjectModel,
  ...individualBeneficiaryObjectModel,
  ...companyBeneficiaryObjectModel,
  ...paymentModel,
  ...autoFilledCheckbox
}

const model = {
  basicDetailFieldsModel,
  mainInsuredObjectModel,
  individualBeneficiaryObjectModel,
  companyBeneficiaryObjectModel,
  insuredPropertyObjectModel,
  paymentModel,
  fieldsModel,
  companyInsuredObjectModel,
  individualInsuredObjectModel,
  autoFilledCheckbox,
  fields
};

export default model;
