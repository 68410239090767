import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Accordion} from "../../../_components/Accordion";

import {
  Card,
  CardFooter,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup
} from "reactstrap";
import Switch from "react-toolbox/lib/switch/Switch.js";
import SimpleReactValidator from "simple-react-validator";

import {CommonInputTypes} from "../../../_components/CommonInputTypes";
import MultiSelect from "../../../_components/MultiSelect";
import {usersActions} from "../actions";
import Notifications from "react-notification-system-redux";

import { TitleComponent, Products} from "_components";
import userGraphic from "../../../images/bannerGraphicImages/users.png";  
import {handleError} from '@xc-core/lib';

class UserManagementDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acitveSwitch: "",
      userGrps: null,
      inputValue: "",
      updatedResourceTree: [],
      updatedResourceTreeObj: [],
      backPath: '',
      products: [],
    };
    this.validator = new SimpleReactValidator({
      // element: (message, className) => <div className="invalid-feedback d-block">{message}</div>,
      className: "text-danger",
      messages: {
        email: "That is not an email."
        // default: "Womp! That's not right!"
      },
      validators: {
        ip: {
          // name the rule
          message: "The :attribute must be a valid IP address.", // give a message that will display when there is an error. :attribute will be replaced by the name you supply in calling it.
          rule: function (val, params, validator) {
            // return true if it is succeeds and false it if fails validation. the testRegex method is available to give back a true/false for the regex and given value
            // check that it is a valid IP address and is not blacklisted
            return (
              validator.helpers.testRegex(
                val,
                /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i
              ) && params.indexOf(val) === -1
            );
          }
        }
      }
    });
  }

  async componentDidMount() {
    const {
      match: {params},
      dispatch
    } = this.props;
    await dispatch(usersActions.getUserDetails(params.name));
    const backPath = this.props.history.location.pathname.split('/');
    this.setState({backPath: [...backPath.slice(0, 2)].join('/')})
  }

  componentWillReceiveProps(props) {
    props.userDetails && this.setState({
      userDetails: props.userDetails.item,
      collections: props.userDetails.collections,
      acitveSwitch: props.userDetails.item.isActive,
      userGrps: props.userDetails.item.groups,
      resourceTree: props.userDetails.resourceTree
    });
  }

  handleExpiryChange = (event) => {
    const { userDetails } = this.state;
    const { name } = event.target;
    let { value } = event.target;
    if (value === 'true') value = true;
    else if (value === 'false') value = false;
    userDetails[name] = value;
    this.setState({
      userDetails,
    });
  };

  handleInputChange = (val, event) => {
    const {userDetails, updatedResourceTree} = this.state;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (val === "resourceTree") {
      updatedResourceTree[name] = value;
      let createObj = {scope: name, status: value};
      this.setState({
        updatedResourceTree,
        updatedResourceTreeObj: [
          ...this.state.updatedResourceTreeObj,
          createObj
        ]
      });
    } else {
      userDetails[name] = value;
      this.setState({
        userDetails
      });
    }
  }

  onInputChange = (inputValue, {action}) => {
    switch (action) {
      case "input-change":
        this.setState({inputValue});
        return;
      case "menu-close":
        let menuIsOpen = undefined;
        if (this.state.inputValue) {
          menuIsOpen = true;
        }
        this.setState({
          menuIsOpen
        });
        return;
      default:
        return;
    }
  };

  handleMultipleChange = (newValue, actionMeta) => {
    this.setState({
      userGrps: newValue
    });
  };

  handleChange = (name, value) => {
    this.setState({...this.state, [name]: value});
  };

  onSubmit = async e => {
    e.preventDefault();
    const {
      match: {params},
      dispatch
    } = this.props;
    const userUpdatedNotificationOpts = {
      title: 'User updated successfully.',
      position: 'tr',
      autoDismiss: 2,
    };
    if (this.validator.allValid()) {
      const {
        userDetails,
        acitveSwitch,
        userGrps,
        products,
        updatedResourceTreeObj
      } = this.state;
      let updatedGrp = userGrps.map(data => {
        let copyOfObj = data.name;
        return copyOfObj;
      });
      let userDetailsValue = {
        products,
        name: userDetails.name,
        email: userDetails.email,
        username: userDetails.username,
        dataScope: userDetails.dataScope,
        isActive: acitveSwitch,
        extendedProperties: {},
        orgUnit: userDetails.orgUnit,
        groups: updatedGrp,
        resourceTree: updatedResourceTreeObj,
        noExpiry: userDetails.noExpiry,
      };
      try{
        await dispatch(usersActions.updateUser(params.name, userDetailsValue));
        dispatch(Notifications.success(userUpdatedNotificationOpts));
      } catch(e){
        handleError(e);
      }
      
      this.props.history.push('/userManagement');
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  /**
   * @function handleProductChange should be refactored in a separate file
   * because it is very similar to the one on organisationDetails
   */
  handleProductChange = (productIndex, event) => {
    const productToChange = this.state.collections.products.find((product, index) => (index === productIndex));
    const changedProduct = {...productToChange, status: event.target.value};
    const currentChangedProducts = this.state.products;
    let newProducts;

    /**check if this product has already been changed**/
    if (currentChangedProducts.find((product) => product.productName === changedProduct.productName)) {
      const indexOnState = currentChangedProducts
        .findIndex((product) => (product.productName === changedProduct.productName));

      newProducts = [
        ...currentChangedProducts.slice(0, indexOnState),
        changedProduct,
        ...currentChangedProducts.slice(indexOnState + 1)
      ];
    } else newProducts = [...currentChangedProducts, changedProduct];
    this.setState({products: newProducts,});
  };

  resendActivation = async () => {
    const {userDetails: {item: {username}}, dispatch} = this.props;
    await dispatch(usersActions.resendActivation(username));
  };

  render() {
    let {
      userDetails,
      collections,
      userGrps,
      inputValue,
      resourceTree
    } = this.state;
    // console.log(collections);
    return (
      <div className="container-fluid px-0">
        <TitleComponent
          title="Edit - User Details"
          bannerGraphic={userGraphic}
          backTitle="Back to listing"
          backPath={this.state.backPath}
          history={this.props.history}
        >
        </TitleComponent>
        <Row className="justify-content-center my_50">
          <Col sm="10">
            <Card className="rounded-xl border-0 shadow-sm overflow-visible">
              {userDetails === undefined ? (
                <div
                  className={
                    userDetails === undefined ? "loader d-block" : "d-none"
                  }
                />
              ) : (
                <Fragment>
                  <CardBody className="p-3">
                    <h6 className="text-primary card-title sub-title-container">User Details </h6>
                    <Row>
                      <Col md={4}>
                        <CommonInputTypes
                          group={false}
                          placeholder="Enter User's Name"
                          idName="name"
                          type="input"
                          value={
                            userDetails !== undefined ? userDetails.name : ""
                          }
                          onChange={this.handleInputChange.bind(this, "")}
                          title="Name"
                          id="1"
                          validator={this.validator}
                        />
                      </Col>

                      <Col md={4}>
                        <CommonInputTypes
                          group={false}
                          idName="username"
                          placeholder="Enter username"
                          type="text"
                          value={
                            userDetails !== undefined ? userDetails.username : ""
                          }
                          onChange={this.handleInputChange.bind(this, "")}
                          title="Username"
                          id="2"
                          validator={this.validator}
                        />
                      </Col>
                      <Col md={4}>
                        <CommonInputTypes
                          group={false}
                          idName="email"
                          placeholder="Enter User's Email-id"
                          type="email"
                          value={
                            userDetails !== undefined ? userDetails.email : ""
                          }
                          onChange={this.handleInputChange.bind(this, "")}
                          title="Email"
                          id="3"
                          validator={this.validator}
                        />
                      </Col>
                      <Col md={4}>
                        <CommonInputTypes
                          idName="orgName"
                          group={false}
                          option={collections.orgUnits}
                          type="select"
                          title="Organisation"
                          value={
                            userDetails !== undefined ? userDetails.orgUnit : ""
                          }
                          onChange={this.handleInputChange.bind(this, '')}
                          id="4"
                          disabled={true}
                          validator={this.validator}
                        />
                      </Col>
                      <Col md={4}>
                        <CommonInputTypes
                          idName='noExpiry'
                          group={false}
                          option={[{ code: 'false', name: 'No' }, { code: 'true', name: 'Yes' }]}
                          type='select'
                          title='API User?'
                          value={userDetails.noExpiry ? 'true' : 'false'}
                          onChange={this.handleExpiryChange}
                          id='4'
                          validator={this.validator}
                        />
                      </Col>
                      {/*<Col md={4}>*/}
                      {/*  <CommonInputTypes*/}
                      {/*    idName="dataScope"*/}
                      {/*    group={false}*/}
                      {/*    option={collections.dataScope}*/}
                      {/*    type="select"*/}
                      {/*    title="Data Scope"*/}
                      {/*    value={userDetails && userDetails.dataScope ? userDetails.dataScope : ""}*/}
                      {/*    onChange={this.handleInputChange.bind(this, "")}*/}
                      {/*    id="5"*/}
                      {/*    disabled={false}*/}
                      {/*    validator={''}*/}
                      {/*    optional={true}*/}
                      {/*  />*/}
                      {/*</Col>*/}
                      <Col>
                        <MultiSelect
                          title="User Group(s)"
                          idName="userGrp"
                          value={userGrps}
                          onInputChange={this.onInputChange.bind(this)}
                          inputValue={inputValue}
                          options={collections.groups}
                          handleChange={this.handleMultipleChange.bind(this)}
                          validator={this.validator}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col className="d-flex justify-content-between">
                        <Switch
                          theme={{
                            on: "switchOn switch",
                            thumb: "thumb",
                            off: "switch"
                          }}
                          checked={this.state.acitveSwitch}
                          label={ this.state.acitveSwitch ? "Status: Active" : "Status: Inactive" }
                          on={"#ffff"}
                          onChange={this.handleChange.bind(this, "acitveSwitch")}
                        />
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Button
                          className="btn-primary"
                          onClick={this.resendActivation}
                        ><i className="icon-dooKey"/> Resend Activation</Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Fragment>
              )}
            </Card>
          </Col>
        </Row>
        <Products
          handleProductChange={this.handleProductChange}
          collections={collections}
          productsOnState={this.state.products}
        />
        {userDetails !== undefined && (
          <Row className="justify-content-center my_50">
            <Col sm="10">
              <Card className="rounded-xl border-0 shadow-sm">
                <CardBody className="p-3">
                  <h6 className="text-primary card-title sub-title-container">
                    Override Permission
                  </h6>
                  { (resourceTree === undefined || 
                  ( Array.isArray(resourceTree) && resourceTree.length === 0)) && (
                    <p>You do not have the permission to override user permissions. Please contact your System Administrator.</p>
                  ) }
                  {resourceTree !== undefined && (
                    <Fragment>
                      {resourceTree.map((data, key) => (
                        <Accordion
                          key={key}
                          headerComponent={<Fragment>{data.name} ({data.children.length})</Fragment>}
                          containerClasses={'shadow-none'}
                          bodyComponent={data.children.map((val, key) => (
                            <FormGroup
                              row
                              className={"mt-2 align-items-center pl-3 pr-3"}
                              key={key}
                            >
                              <Col sm={10}>
                                <p className="text-secondary">{val.name}</p>
                              </Col>
                              <Col sm={2}>
                                <CommonInputTypes
                                  group={false}
                                  idName={val.name}
                                  option={collections.permissionStatuses}
                                  type="select"
                                  title=""
                                  value={
                                    this.state.updatedResourceTree[val.name] ?
                                      this.state.updatedResourceTree[val.name] : val.permissionStatus
                                  }
                                  onChange={this.handleInputChange.bind(
                                    this,
                                    "resourceTree"
                                  )}
                                  id="6"
                                  disabled={false}
                                  validator={""}
                                />
                              </Col>
                            </FormGroup>
                          ))}
                        />
                      ))}
                    </Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Col sm="11">
          <div className="row justify-content-end mb-5" id="modalButton">
            <div className="col-2 text-center">
              <Button
                onClick={() => this.props.history.push("/userManagement")}
                className="text-info"
                color="link"
              >
                Cancel
              </Button>
            </div>
            <div className="col-2">
              <Button
                color="primary"
                onClick={this.onSubmit.bind(this)}
                className="fullWidth rounded-xl"
              >
                Save
              </Button>
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

// const mapDispatchToProps = (dispatch) => ({
//   notify: (action) => dispatch(action)
// });
function mapStateToProps(state) {
  const {userDetails} = state.userManagement;
  return {userDetails};
}

export default connect(mapStateToProps)(UserManagementDetails);
