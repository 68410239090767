import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Row, Col} from 'reactstrap';
import {withStyles} from '@material-ui/core/styles';
import {TitleComponent, FlashMessage} from "_components";
import Endpoint from "./components/singleEndpointDocumentation";
import AsideContainer from "./components/asideContainer";
import TabSection from "./components/tabSection";
import {env} from "_config/env";
import documentationActions from "./actions";
const {apiURL} = env;

const styles = ({
  root: {
    flexGrow: 1,
    marginTop: "30px",
  },
});
const authObj = [
  {
    name: "username",
    dataType: "string",
    description: "The username for login.",
    required: "true",
  },
  {
    name: "password",
    dataType: "string",
    description: "The password for login in clear text",
    required: "true",
  },
];
const authHeading = [
  {id: 1, headerName: "Name", key: "Name"},
  {id: 2, headerName: "Type", key: "DataType"},
  {id: 2, headerName: "Description", key: "Description"},
];

class DocumentationTabs extends React.Component {
  state = {
    isGettingDocumentation: false
  };
  componentDidMount() {
    return this.getDocumentation();
  }
  getDocumentation = async () => {
    this.setState({isGettingDocumentation: true});
    await this.props.getDocEndpoints();
    this.setState({isGettingDocumentation: false});
  };
  setRequestObject = (requestObj) => this.forceUpdate(() => this.setState({requestObj}));
  render() {
    const {classes, canViewDocumentation} = this.props;
    const {isGettingDocumentation} = this.state;
    if (isGettingDocumentation) return <div className="loader" />;
    if (!canViewDocumentation) return <FlashMessage flashMessage="You are not allowed to view the Documentation" />;
    const getApiUrl=()=>{
      if(apiURL.toString().startsWith("http")){
        return apiURL
      }
      return `${window.location.origin}${apiURL}`
    }
    return (
      <Col className={`${classes.root} documentation-container`}>
        <Row>
          <Col>
            <TitleComponent
              title="API Documentation"
              noBorderBottom={true}
            >
              <p>Version: 1.0</p>
            </TitleComponent>
            <Col>
              <Row>
                <Col>
                  <div style={{
                    marginRight: "3%",
                    marginLeft: "3%"
                  }}>
                    <div className="text-dark">
                      <h5>Preface</h5>
                      <p style={{color: "#ccc"}}>This documentation contains the API specifications needed for integration with external systems.</p>
                    </div>
                    <h5>Authentication</h5>
                    <div className="text-dark">
                      <p className="alert-dark p-3 rounded-xl text-info">Team Directory Services:
                        <a href={`${getApiUrl}`}>{getApiUrl()}</a>
                      </p>
                    </div>
                    <Endpoint
                      open={true}
                      host={apiURL}
                      path="/identity/login"
                      method="POST"
                      properties={authObj}
                      propertiesHeading={authHeading}
                      requestObject={{
                        "username": "string",
                        "password": "string"
                      }}
                      responses={[{status: 200, body: {token: "string"}}]}
                    />
                    {/*==========================TABS=====================================*/}
                    <section>
                      {this.props.products.length !== 0 && <TabSection products={this.props.products}/>}
                    </section>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
          <div className="col-md-4">
            <AsideContainer/>
          </div>
        </Row>
      </Col>
    );
  }
}

DocumentationTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({documentation: {canViewDocumentation, products}}) => ({
  canViewDocumentation, products,
});
const mapDispatchToProps = (dispatch) => ({
  getDocEndpoints: () => dispatch(documentationActions.getDocEndpoints())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentationTabs));

