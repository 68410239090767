import { combineReducers } from 'redux';
import QuotationListReducer from './quotationList';
import ProductListReducer from './productList';
import PolicyListReducer from './policyList';
import LeadListReducer from './leadListReducer';
import auditLogReducer from './auditLogReducer';
import passwordManagementReducer from './passwordManagementReducer';
import UserListReducer from './userList';
import UserReducer from './user';
import UserGroupListReducer from './userGroupList';
import OrganizationListReducer from './organizationList';
import ApiDocumentationReducer from './apiDocumentation';
import ModalMessageReducer from './modalMessage';
import SystemConfigReducer from './systemConfigReducer';
// import FiscalYearReducer from './fiscalYearReducer';
import fiscalYearReducer from "./fiscalYearReducer";
import DistributionControlReducer from './distributionControlReducer'
import ScopeManagementReducer from './scopeManagementReducer'

const quotationListReducer = new QuotationListReducer();
const productListReducer = new ProductListReducer();
const policyListReducer = new PolicyListReducer();
const leadListReducer = new LeadListReducer();
const userListReducer = new UserListReducer();
const userReducer = new UserReducer();

const userGroupListReducer = new UserGroupListReducer();
const organizationListReducer = new OrganizationListReducer();
const apiDocListReducer = new ApiDocumentationReducer();
const modalMessageReducer = new ModalMessageReducer();

const reducers = combineReducers({
  quotationList: quotationListReducer.changeState.bind(quotationListReducer),
  policyList: policyListReducer.changeState.bind(policyListReducer),
  leadList: leadListReducer.changeState.bind(leadListReducer),
  auditLog: auditLogReducer.changeState.bind(auditLogReducer),
  passwordManagement: passwordManagementReducer.changeState.bind(passwordManagementReducer),
  userList: userListReducer.changeState.bind(userListReducer),
  user: userReducer.changeState.bind(userReducer),
  userGroupList: userGroupListReducer.changeState.bind(userGroupListReducer),
  productList: productListReducer.changeState.bind(productListReducer),
  organizationList: organizationListReducer.changeState.bind(organizationListReducer),
  apiDocumentation: apiDocListReducer.changeState.bind(apiDocListReducer),
  modalMessage: modalMessageReducer.changeState.bind(modalMessageReducer),
  systemConfig: SystemConfigReducer.changeState.bind(SystemConfigReducer),
  fiscalYear: fiscalYearReducer.changeState.bind(fiscalYearReducer),
  distributionControl: DistributionControlReducer.changeState.bind(DistributionControlReducer),
  scopeManagement : ScopeManagementReducer.changeState.bind(ScopeManagementReducer)
});

export default reducers;
