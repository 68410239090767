import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

const ModalUi = (props) => (
  <Modal id="modalForm"
         className={`
         customModalHeader
         ${props.className ? props.className : ""}
         `}
         modalClassName="d-flex" isOpen={props.isOpen} toggle={props.toggle}>
    {props.title && <ModalHeader className="text-light" toggle={props.toggle}> {props.title}</ModalHeader>}
    <ModalBody>
      {props.children}
    </ModalBody>
  </Modal>
);

ModalUi.defaultProps = {
  toggle: () => console.log('toggle'),
  isOpen: false,
  // title: 'Modal Title'
};

export default ModalUi;
