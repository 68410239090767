/** Product Description */

const products = {
  all: {
    name: 'All',
    id: '',
  },
  travelInsurance: {
    name: 'Travel Insurance',
    id: 1998034,
    type: 'TravelInsuranceProduct',
    defaultValues: {
      country: 'Indonesia',
    },
  },
  hreTravelInsurance: {
    name: 'Travel Insurance',
    id: 2106357,
    type: 'TravelInsuranceProduct',
    defaultValues: {
      country: 'Malaysia',
    },
  },
  termLifeInsurance: { name: 'Term Life', id: 2028414, type: 'TermLifeInsuranceProduct' },
  walletInsurance: {
    name: 'Wallet Insurance',
    id: 2036273,
    type: 'PersonalAccidentInsuranceProduct',
    getQuote: {
      minAge: 18,
    },
    defaultValues: {
      country: 'Pakistan',
    },
  },
  cashForHospitalization: {
    name: 'Cash for Hospitalization',
    id: 2036091,
    type: 'MedicalInsuranceProduct',
    getQuote: {
      minAge: 18,
      maxAge: 64,
    },
    defaultValues: {
      country: 'Pakistan',
    },
  },
  alfalahTravelInsurance: {
    name: 'Travel Insurance Alfalah',
    id: 2062582,
    type: 'TravelInsuranceProduct',
    defaultValues: {
      country: 'Pakistan',
    },
  },
  hgiInsurance: {
    name: 'HGI Nepal Travel Insurance',
    id: 436773,
    type: 'TravelInsuranceProduct',
    defaultValues: {

    },
  },
  abcTravelInsurance: {
    name: 'ABC Travel',
    id: 2339001,
    type: '',
    defaultValues: {
      country: 'Indonesia',
    },
  },
  cbTravelInsurance: {
    name: 'CB Travel',
    id: 2339002,
    type: '',
    defaultValues: {
      country: 'Indonesia',
    },
  },
  flexiPa: {
    name: 'Flexi Pa',
    id: 619031,
    type: '',
    defaultValues: {
      country: 'Indonesia',
    },
  },
  gbsnICare: {
    name: 'i-Care',
    id: 291312,
    type: 'TermLifeInsuranceProduct',
    defaultValues: {
      country: 'Malaysia',
    },
  },
  gbsnIMed: {
    name: 'i-Med',
    id: 296052,
    type: 'TravelInsuranceProduct',
    defaultValues: {
      country: 'Malaysia',
    },
  },
  gbsnIProtect: {
    name: 'i-Protect',
    id: 296005,
    type: 'TravelInsuranceProduct',
    defaultValues: {
      country: 'Malaysia',
    },
  },
  hgiTravelInsurance: {
    name: 'i-Protect',
    id: 436773,
    type: 'TravelInsuranceProduct',
    defaultValues: {
      country: 'Nepal',
    }
  },
  medicalInsurance: {
    name: 'Medical Insurance',
    id: 630706,
    type: 'MedicalInsuranceProduct',
    defaultValues: {
      country: 'Indonesia',
    },
  },
  motorInsurance: {
    name: 'Motor Insurance',
    id: 629134,
    type: 'MotorInsuranceProduct',
    defaultValues: {
      country: 'Malaysia',
    },
  },
  personalAccident: {
    name: 'Personal Accident',
    id: 634220,
    type: 'PersonalAccidentInsuranceProduct',
    defaultValues: {
      country: 'Malaysia',
    },
  },
  myMotorInsurance: {
    name: 'My Motor Insurance',
    id: 691093,
    type: 'MyMotorInsuranceProduct',
    defaultValues: {
      country: 'Malaysia',
    },
  },
  autoInsurance: {
    name: 'Auto Insurance',
    id: 686205,
    type: 'AutoInsurance',
    defaultValues: {
      country: 'Malaysia',
    },
  },
  homeInsurance: {
    name: 'Home Insurance',
    id: 635366,
    type: 'HomeInsurance',
    defaultValues: {
      country: 'Malaysia',
    },
  }
  /** temp */
  // hreTravelInsurance: { name: 'HRe Travel Insurance', id: 1998035, type: 'TEMP' },
};

export default products;
