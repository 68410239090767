import React from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PropTypes from "prop-types";

export default class DateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.dateRangePickerRef = React.createRef();
  }

  onDateRangeChange = (dateRange) => {
    const { onSelected } = this.props;
    this.setState((prevState) => ({
      ...prevState, dateRange,
    }));
    onSelected({ startDate: dateRange[0], endDate: dateRange[1] });
  };


  onCalendarClose = () => (this.setState({ isOpen: false }, () => {
    /** we do this otherwise the calendar will be closed, have to be manually triggered */
    this.setState({ isOpen: true });
  }));

  render() {
    const { isOpen } = this.state;
    const { dateRange } = this.props;
    return (
      <>
        <div className="calendar-container">
          <DateRangePicker
            ref={this.dateRangePickerRef}
            onChange={this.onDateRangeChange}
            onCalendarClose={this.onCalendarClose}
            value={dateRange}
            isOpen={isOpen}
          />
        </div>
        <p className={`${false ? "d-none" : "calendar-notice" }`}>Please select both start and end date</p>
      </>
    );
  }
}

DateRange.propTypes = {
  style: PropTypes.object,
  onSelected: PropTypes.func.isRequired,
  dateRange: PropTypes.array.isRequired,
};
