import storage from '@xc-core/storage';

const user = storage.getItem('user');
const config = user ? user.config : null;

const dateRequestParamFormat = 'DD/MM/YYYY';
const dateMomentFormat = config && config.dateFormat ? config.dateFormat : 'DD/MMM/YYYY';
const dateTimeMomentFormat = config && config.dateTimeFormat ? config.dateTimeFormat : 'DD/MM/YYYY h:mm A';
const datePickerFormat = config && config.dateFormatServer ? config.dateFormatServer : 'dd/MMM/yyyy';
const datePickerFormats = [datePickerFormat, 'dd/MM/yyyy'];
const currencyFormat = config && config.currencyFormat ? config.currencyFormat : '';
const currencySymbol = config && config.currencySymbol ? config.currencySymbol : '';
const token = user ? user.token : '';
const showExtendedCommissionSettings = config && config.showExtendedCommissionSettings;
const defaultPriceField = config && config.defaultPriceField;
const numberFormat = config && config.numberFormat;

const customConfig = {
  dateRequestParamFormat,
  dateMomentFormat,
  dateTimeMomentFormat,
  datePickerFormats,
  currencyFormat,
  currencySymbol,
  token,
  showExtendedCommissionSettings,
  defaultPriceField,
  user,
  numberFormat
};
export default customConfig;
