const getQuoteActionTypes: IGetQuoteActionTypes = {
  CREATE_SET_FORBIDDEN: 'GET_QUOTE_CREATE_SET_FORBIDDEN',
  UPDATE_SET_FORBIDDEN: 'GET_QUOTE_UPDATE_SET_FORBIDDEN',
  GET_QUOTE_LOADING: 'GET_QUOTE_LOADING',
  GET_QUOTE_FAILED: 'GET_QUOTE_FAILED',
  GET_QUOTE_SET: 'GET_QUOTE_SET',
  GET_QUOTE_CREATE: 'GET_QUOTE_CREATE',
  GET_QUOTE_NEXT: 'GET_QUOTE_NEXT',
  GET_QUOTE_SET_STEPS: 'GET_QUOTE_SET_STEPS',
  GET_QUOTE_PREV: 'GET_QUOTE_PREV',
  GET_QUOTE_RESET: 'GET_QUOTE_RESET',
  SET_PRODUCT: 'GET_QUOTE_SET_PRODUCT',
  GET_QUOTE_RESET_ITEM: 'GET_QUOTE_RESET_ITEM',
  GET_QUOTE_SET_COLLECTIONS: 'GET_QUOTE_SET_COLLECTIONS',
};

export default getQuoteActionTypes;
