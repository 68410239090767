import React, {useEffect,useState} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import {SidebarCard} from "../../../@xc-core/components";
import {modelId} from "../../../Products/shared/utils/modelUtil";
import lib from "../../../utils/lib";

const InsuredDetails =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            initValues,
            sidebar,
            isKhmer,
            productIcon,
            db,
            updateStateItem
        },
    } = props;
    const basicContactKey =["Name","VATIN","Business Occupation","ID Type",'ID Number']
    const isBelongToCompany = item.properties?.['Main Insured']?.['Belongs to Company'] === 'Yes'
    const insuredDetailModel:IField[]= isBelongToCompany ? model.companyInsuredFields.fields :model.individualInsuredFields.fields
    const [cb_auto_model, setModelCollections] = useState<{id:string,value:string,name:string,code:string}[]>([]);

    useEffect(()=>{
        if(!item.properties?.['Main Insured']?.['Belongs to Company']){
            initValues([
                {
                    id:'properties.Main Insured.Belongs to Company',
                    value:'No'
                },
                {
                    id:`properties.Main Insured.${isBelongToCompany ? 'Company' : 'Individual'} Country`,
                    value:"Cambodia"
                },
                {
                    id:'properties.Insured Property',
                    value:{}
                }
            ])
        }
    },[])

    useEffect(()=>{
        const makeValue= item.properties?.['Insured Property']?.['Make'];
        if(!!makeValue && cb_auto_model.length === 0){
            modelCollectionHandler(makeValue);
        }
    },[item.properties?.['Insured Property']?.['Make'] ,cb_auto_model])

    const getPartOfTheModel=(modelType:'basicInfo'|'contactDetail')=>{
        return insuredDetailModel.filter((el)=>{
            const val = basicContactKey.some((el2)=>
                modelId('properties.Main Insured' ,`${isBelongToCompany ? 'Company' : 'Individual'} ${el2}`) === el.id);
            return modelType === "basicInfo" ? val : !val
        });
    }

    const changeInsuredTypeHandler=(pairs: IPair[])=>{
        const newInsuredProperties= Object.keys(item?.properties?.['Main Insured']).reduce((prevValue:any, currentValue:any)=>{
            if(currentValue !== 'Belongs to Company' && currentValue.indexOf(pairs[0].value === 'Yes' ? 'Individual' : 'Company') !== -1){
                return {
                    ...prevValue,
                    ...{}
                }
            }
            return {
                ...prevValue,
                ...{
                    ...item,
                    properties:{
                        ...item?.properties,
                        'Main Insured':{
                            'Belongs to Company':pairs[0].value,
                            [`${pairs[0].value === 'Yes' ? 'Company' : 'Individual'} Country`]:'Cambodia'
                        }
                    }
                }
            }
        },{})
        updateStateItem(newInsuredProperties)
    }

    const modelCollectionHandler=(value:string)=>{
        setModelCollections(collections.cb_auto_make_model
            .find((el:any)=> el.name === value)
            ?.extendedProperties?.models?.map((el:string)=> {
                return {code:el ,id:el, name:el,value:el}
            }) || [])
    }

    const insurePropertyHandler=(pairs: IPair[])=>{
        let addedPairs:IPair[]= [];
        if(pairs[0].id === 'properties.Insured Property.Make'){
            addedPairs = [{id:'properties.Insured Property.Model',value:''}]
            modelCollectionHandler(pairs[0].value)
        }
        onChange('onChange', {
            pairs:[...pairs, ...addedPairs]
        })
    }

    return (
        <Row>
            <Col md={'8'}>
                <h5 className='sub-title'>{lib.withKhmerText(model.title,db)}</h5>
                <Col className={'pl-0'} md='12'>
                    <RenderFields
                        onChange={changeInsuredTypeHandler}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={model.mainInsuredFields.fields}
                    />
                </Col>
                <Col className={'pl-0'} md='12'>
                    <p className='custom-text-primary'>{lib.withKhmerText(isBelongToCompany ? 'Company Details' : 'Individual Details',db)}</p>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={getPartOfTheModel('basicInfo')}
                    />
                </Col>
                <Col className={'pl-0'} md='12'>
                    <p className='custom-text-primary'>{lib.withKhmerText("Contact Details",db)}</p>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={getPartOfTheModel('contactDetail')}
                    />
                </Col>
                <Col className={'pl-0'} md='12'>
                    <p className='custom-text-primary'>{lib.withKhmerText('Property Details',db)}</p>
                    <RenderFields
                        onChange={insurePropertyHandler}
                        collections={{
                            ...collections,
                            cb_auto_model
                        }}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={model.insuredDetailFields.fields}
                    />
                </Col>
            </Col>
            <Col md='4'>
                <SidebarCard.Quotation
                    customIconProduct={productIcon}
                    item={item}
                    model={sidebar}
                    ext={isKhmer}
                />
            </Col>

        </Row>
    )
}

export default InsuredDetails;