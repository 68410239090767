import React from 'react';
import { Row, Col } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import Detail from './detail';
import lib from 'utils/lib';

const Summary = (props: ILoadableComponentProps) => {
  const {
    model: {
      item,
      model, sidebar
    },
  } = props;

  return (
    <Row>
      <Col md='8'>
        <Detail
          item={item}
          mainModel={model.mainModel}
          additionalDriverModel={model.additionalDriverModel}
        />
      </Col>
      <Col md='4'>
        <SidebarCard.Quotation
          customIconProduct={"icon-motor-insurance"}
          item={item}
          model={sidebar}
          ext={lib.checkIsDemoSite()}
        />
      </Col>
    </Row>
  );
};

export default Summary;
