import React, {useEffect, useState} from 'react';
import { connect, useDispatch } from "react-redux";
import { actions } from '@redux';
import {
  Col
} from 'reactstrap';
import { Header } from '../../@xc-core/components/core/header';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import { Table, Loader } from '../../@xc-core/components/core';
import orgImage from "images/emptyStateImages/organisations.png";
import DeleteModal from "./deleteModal";
import {ScopeManagementService} from '@xc-core/services';
import Notifications from 'react-notification-system-redux';
import { getNotificationMessage } from '../../_services';
import { ScopeManagementActions } from '@redux/actions';
import { handleError } from '@xc-core/lib';

const scopeManagementActions = actions.ScopeManagementActions;

const searchHeading: any = [
  {
    "id": "name",
    "type": "string",
    "displayName": "Name",
    "value": "Name"
  },
  {
    "id": "parents",
    "type": "string",
    "displayName": "Path",
    "value": "Path"
  },
  {
    "id": "type",
    "type": "string",
    "displayName": "Type",
    "value": "Type"
  },
  {
    "id": "resource",
    "type": "string",
    "displayName": "Resource",
    "value": "Resource"
  }
]

const heading: any = [
  {
    "id": "name",
    "type": "string",
    "displayName": "Name",
    "value": "Name"
  },
  {
    "id": "type",
    "type": "string",
    "displayName": "Type",
    "value": "Type"
  },
  {
    "id": "resource",
    "type": "string",
    "displayName": "Resource",
    "value": "Resource"
  }
];

const ScopeManagementList = (props: IObject)=> {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [requestResourceToDelete, setRequestedResourceToDlete] = useState({})
    const {getList, list, search} = props;
    const [searchKey,setSearchKey] = useState<string>("");
    const dispatch = useDispatch();


const service =  new ScopeManagementService();
    useEffect(()=>{
      if(!props.isTestCase)
        getList()
    },[getList,props.isTestCase])

    const editResource = (item: IObject) => {
      props.history.push(`/authorization/resources/tree/edit/${item.id}`);
    }

    const addNewResource = () => {
      props.history.push(`/authorization/resources/tree/add`);
    }

    const deleteRequest = (item: IObject) => {
      setRequestedResourceToDlete(item)
      setDeleteModalIsOpen(true);
    }

    const handleDelete = async (item: IObject) => {
      try{
        await service.deleteResource(item.id)
        getList(item.id)
        setDeleteModalIsOpen(false);
        // @ts-ignore
        dispatch(Notifications.success(getNotificationMessage('Deleted successfully')))
      }
      catch(e){
        handleError(e)
      }
    }

    const onSearchRequest = (pair:IPair)=>{
      setSearchKey(pair.value)
      if(pair.value !== ""){
        search(pair.value)
      } else {
        getList()
      }

    }
    if (list.length === 0) return <Loader />
    return (
      <div data-testid="containter">
        <Header
          title='Scope Management'
          bannerGraphic={bannerImg}
          withBorderBottom
        />
          <div data-testid="containter-inner" className='container-fluid px-0'>
            <div className="row d-flex justify-content-center my_30">
              <Col sm="11" className="my-2">
              
              <Table
                    titles={searchKey ? searchHeading : heading}
                    records={list !== undefined ? list : []}
                    hasRecords={list.length > 0}
                    image={orgImage}
                    onRowClicked={editResource}
                    handlePageClick={(pair: IPair)=> {}}
                    handlePageSizeChange={(pair: IPair) => {}}
                    pagination={{}}
                    onRowButtonClicked={deleteRequest}
                    handleSearchKeyword={(pair: IPair) => onSearchRequest(pair)}
                    searchKeyword={searchKey}
                    buttonIcon={"garbage"}
                  >
                    
                  </Table>
              </Col>
              <Col sm="11" className="my-2">
              <button
                      type='button'
                      className='btn btn-primary d-flex ml-auto'
                      onClick={()=> addNewResource()}
                      >
                      Add New Scope
                    </button>

              </Col>
            </div>
            <DeleteModal
              isOpen={deleteModalIsOpen}
              toggleModal={()=>setDeleteModalIsOpen(!deleteModalIsOpen)}
              record={requestResourceToDelete}
              handleDelete={(record:IObject)=> handleDelete(record)}
            />
          </div>
      </div>
      );
}

const mapStateToProps = (state: IStore) => ({
  list: state.scopeManagement.list,
  isTestCase: state.isTestCase
});
const mapDispatchToProps = (dispatch: any) => ({
    getList: () => dispatch(scopeManagementActions.getList()),
    search: (key:string) => dispatch(ScopeManagementActions.search(key))
});
export default connect(mapStateToProps, mapDispatchToProps)(ScopeManagementList);