import React from 'react';
import { Row, Col } from 'reactstrap';
import { sidebarModel, DetailModel } from 'models';
import { SidebarCard, InsuredDetail } from '@xc-core/components';
import { TravelHReConfig } from '@products';

class Summary extends React.Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    this.props.onRef(this);
  }
  getAlert = () => true;

  render() {
    const { item } = this.props.curentThis.props;
    const { properties, productName } = this.props.curentThis.props.item;
    return (
      <Row>
        <Col lg='8'>
          <InsuredDetail
            item={{
              insureds: [{ ...properties }, ...properties['Other Insured']],
              beneficiaries: properties.Beneficiaries,
            }}
            model={DetailModel.travelHRe}
            insuredTypes={TravelHReConfig.insuredTypes}
          />
        </Col>
        <Col lg='4'>
          <SidebarCard.Quotation
            properties={properties}
            data={properties}
            productName={productName}
            model={sidebarModel.quotation.travelHRe}
            item={item}
          />
        </Col>
      </Row>
    );
  }
}

export default Summary;
