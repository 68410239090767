import React, {useEffect} from 'react';
import ElementPan from "react-element-pan"
import OrgChart from 'react-orgchart';

const Charts = ({orgChart, getOrganisationsChart}) => {
  useEffect(() => {
    getOrganisationsChart();
  }, []);
  const MyNodeComponent = ({node}) => (
    <div className="initechNode shadow">
      <p className="org-name"> {node.name} </p>
      <p className="org-type"> {node.extendedProperties.type}</p>
    </div>
  );
  if (!orgChart) return (<div className="loader"/>);
  return (
    <div className="container-fluid p-0">
      <ElementPan
        startX={771} // Optional X coordinate to start at
        startY={360} // Optional Y coordinate to start at
      >
        <div className="m-5" id="initechOrgChart">
          <OrgChart tree={orgChart} NodeComponent={MyNodeComponent}/>
        </div>
      </ElementPan>
    </div>
  );
};

export default Charts;