import { policyActions as policyActionsLegacy } from 'Products/shared/policyListing/actions';
import { userMessages } from '@xc-core/constants';
import { actions } from '@redux';

const quotationActions = new actions.QuotationActions();
const policyActions = new actions.PolicyActions();
const modalMessageAction = new actions.ModalMessageActions();

const dispatchToProps = (dispatch: any) => ({
  // --------------quotation--------------------
  setQuotationOccupationList: () => dispatch(quotationActions.setOccupationList()),
  getQuoteWitnessSignature: (id: string) => dispatch(quotationActions.getWitnessSignatureURL(id)),
  // --------------policy-----------------------
  setPolicyOccupationList: () => dispatch(policyActions.setOccupationList()),
  reissuePolicy: (id: number) => dispatch(policyActionsLegacy.reissuePolicy(id)),
  cancelPolicy: (id: number, properties: IObject) => dispatch(
    policyActionsLegacy.cancelPolicy(id, properties),
  ),
  cancelInEffectPermission: () => dispatch(policyActionsLegacy.hasPermission('Cancellation (In-Effect Certificate)')),
  cancelNotInEffectPermission: () => dispatch(policyActionsLegacy.hasPermission('Cancellation (Not In Effect Certificate)')),
  getReissuePermission: () => dispatch(policyActionsLegacy.hasPermission('Reissue Certificate')),
  getPolicyWitnessSignature: (id: string) => dispatch(policyActions.getWitnessSignatureURL(id)),
  // --------------modal message----------------
  showForbiddenModal: () => dispatch(modalMessageAction.show(userMessages.PERMISSION_TITLE,
    userMessages.PERMISSION_MESSAGE)),
});

export default dispatchToProps;
