import Request from './request';
import Response from './response';

abstract class BaseListingService implements IListingService {
  protected abstract api: string;

  protected abstract env: string;

  protected abstract withApi: string;

  public getList = async (params: string): Promise<any> => {
    const url = `${this.env}/${this.api}/search?${params}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public getDetail = async (id: number | string): Promise<any> => {
    const url = `${this.env}/${this.api}/${id}/${this.withApi}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public create = async (body: object): Promise<void> => {
    const url = `${this.env}/${this.api}`;
    const request = new Request(body, 'POST');
    const response = new Response(url, request);
    await response.handle();
  }

  public update = async (id: number | string, body: object): Promise<void> => {
    const url = `${this.env}/${this.api}/${id}/${this.withApi}`;
    const request = new Request(body, 'PUT');
    const response = new Response(url, request);
    await response.handle();
  }

  public getCsvFile = async (params: string): Promise<IFileResponse> => {
    const url = `${this.env}/${this.api}/export?${params}`;
    const request = new Request();
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return { file: result.data, contentDisposition: result.contentDisposition };
  }
}

export default BaseListingService;
