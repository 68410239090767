import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input } from 'reactstrap';
import {formatDate} from 'utils';
import {CommonInputTypes} from '_components/CommonInputTypes';
import PropTypes from 'prop-types';
import { utils } from 'utils';
import { getValidationDates, generateDobErrorMessageByType } from '../../alfalahUtils';
import { defaultValues } from '@products/AlfalahWallet/config';
const ValidationMessages = ({validator, disabled, value='', inputName, validationRules, validationParams}) => {
  return (<div>
      {
        validator && !disabled
        && validator.message(inputName, validationParams
          ? { value, validationParams } : value, validationRules || '')
      }
    </div>);
};
ValidationMessages.propTypes = {
  inputName: PropTypes.string.isRequired,
  validator: PropTypes.object.isRequired,
  validationRules: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

class InsuredDetail extends React.Component {

  getAllEnteredInsuredIdNumber = (key) => {
    const { properties } = this.props;
    let collections = [];
    const mainIdNumber = properties[key];
    if (mainIdNumber) collections.push(mainIdNumber);

    if (properties['Other Insured'] && properties['Other Insured'].length) {
      properties['Other Insured'].forEach((other) => {
        const number = other[key];
        if (number) collections.push(number);
      });
    }
    return collections;
  };

  getRelevantEnteredInsuredIdNumber = (key, index) => {
    const { properties } = this.props;
    if (index === 0) { // main
      const idNumber = properties[key];
      return [idNumber];
    }
    if (properties['Other Insured'] && properties['Other Insured'].length) {
      const idNumber = properties['Other Insured'][index - 1][key];
      return [idNumber];
    }
    return [];
  }

  handleIdNumberBeneficiary = async (e, index) => {
    const { beneficiaryChange, beneficiaries } = this.props;
    const idType = beneficiaries['ID Type'];
    const key = e.target.name;
    let value = e.target.value;

    if (idType === 'CNIC') {
      value = value && value.length > utils.lengths.CNIC
        ? value.slice(0, utils.lengths.CNIC) : value;
      value = value === '' || utils.lib.isOnlyNumber(value)
        ? value : beneficiaries[key];
    } else {
      value = value === '' || utils.lib.isOnlyNumberOrAlphabet(value)
        ? value : beneficiaries[key];
    }

    beneficiaryChange({target:{id: key, value}}, index);
  }

  handleLength = (val, length) => {
    let value = val;
    if (value && value.length > length) {
      value = value.slice(0, length);
    }
    return value;
  }

  handleInsuredPostCode = (e, index) => {
    const { id } = e.target;
    let { value } = e.target;
    value = this.handleLength(value, defaultValues.postcodeLength);
    this.props.inputChange({ target: { id, value } }, index);
  };

  handleBeneficiaryPostCode = (e, index) => {
    const { id } = e.target;
    let { value } = e.target;
    value = this.handleLength(value, defaultValues.postcodeLength);
    this.props.beneficiaryChange({ target: { id, value } }, index);
  };

  handleIdTypeBeneficiary = async (e, index) => {
    const { beneficiaryChange } = this.props;
    await beneficiaryChange(e, index);
    await beneficiaryChange({target: {id: 'ID Number', value: ''}}, index);
  }

  handleIdTypeInsurerChange = async (e, index) => {
    const { inputChange } = this.props;
    await inputChange(e, index);
    await inputChange({target: {id: 'ID Number', value: ''}}, index);
  }

  handleIdNumberInsurerChange = async (e, index) => {
    const { inputChange, data } = this.props;
    const key = e.target.name;
    let value = e.target.value;
    const idType = data['ID Type'];
    let gender = data.Gender;
    if (idType === 'CNIC') {
      value = value && value.length > utils.lengths.CNIC
        ? value.slice(0, utils.lengths.CNIC) : value;
      value = value === '' || utils.lib.isOnlyNumber(value)
        ? value : data[key];
      gender = value && value.length === utils.lengths.CNIC 
        ? utils.lib.getGenderFromN(value[utils.lengths.CNIC-1]) : gender;
    } else {
      value = value === '' || utils.lib.isOnlyNumberOrAlphabet(value)
        ? value : data[key];
    }
    await inputChange({target:{id: key, value}}, index);
    await inputChange({target:{id: 'Gender', value: gender}}, index);
  }

  handleCNICNumberInsurerChange = async (e, index) => {
    const { inputChange, data } = this.props;
    const key = e.target.name;
    let value = e.target.value;
    let gender = data.Gender;

    value = value && value.length > utils.lengths.CNIC
      ? value.slice(0, utils.lengths.CNIC) : value;
    value = value === '' || utils.lib.isOnlyNumber(value)
      ? value : data[key];
    gender = value && value.length === utils.lengths.CNIC 
      ? utils.lib.getGenderFromN(value[utils.lengths.CNIC-1]) : gender;

    await inputChange({target:{id: key, value}}, index);
    await inputChange({target:{id: 'Gender', value: gender}}, index);
  }

  handlePassportNumberInsurerChange = (e, index) => {
    const { inputChange, data } = this.props;
    const key = e.target.name;
    let value = e.target.value;

    value = value === '' || utils.lib.isOnlyNumberOrAlphabet(value)
      ? value : data[key];

    inputChange({target:{id: key, value}}, index);
  }

  handleContactNumberChange = (e, beneficiaryIndex, callback) => {
    let { value } = e.target;
    value = value && value.length > defaultValues.contactNumberLength
      ? value.slice(0, defaultValues.contactNumberLength) : value;
    if (Number.isInteger(Number(value))) {
      callback({ target: { id: e.target.id, value } }, beneficiaryIndex);
    }
  }

  render() {
    const {
      data, beneficiaries, main, editMode, beneficiaryIndex, index,
      detailCollections, validator, uniqueId, mainInsurerIsAdult,
    } = this.props;
    // if (validator) debugger;
    const beneficiary = beneficiaries || {};
    if (data === undefined) return null;
    let insuredType;
    if (main && mainInsurerIsAdult) insuredType = 'Adult';
    else if (main) insuredType = data['Main Policyholder Type'];
    else insuredType = data.Type;
    const validationDates = getValidationDates(insuredType);
    return (
      <Row>
        <Col sm={12}>
          <h4 className='beneficiary-header my-3 p-3'>
          { `${main ? `${insuredType} [1] (Main Applicant)` : `${data.Type} [${index}]`}`}
          </h4>
          <Row className='wordBreak'>
            <Col sm={6}>
              <div className='custom-q-text-primary bold my-2'>Insured Person Details</div>

              <Row>
                <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='Full Name'
                        name='Full Name'
                        value={data['Full Name']}
                        onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        placeholder={'Enter Full Name'}
                      />
                      <ValidationMessages
                        validator={validator}
                        validationRules='customRequired|alfalah_full_name_valid_characters'
                        value={data['Full Name']}
                        inputName={`Full Name`}
                      />
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['Full Name'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Nationality</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                     <Input
                      type='select'
                      id='Nationality'
                      name='Nationality'
                      value={data['Nationality']}
                      onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                      placeholder={'Select Options'}
                    >
                      {
                        detailCollections.nationalities.map(country => {
                          return ( <option key={country.id}>{country.name}</option>);
                        })
                      }
                    </Input>
                      <ValidationMessages validator={validator} value={data['Nationality']} inputName='Nationality'
                        validationRules='customRequired'
                      />
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['Nationality'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>CNIC Number</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='CNIC Number'
                        name='CNIC Number'
                        value={data['CNIC Number']}
                        onChange={(e) => this.handleCNICNumberInsurerChange(e, beneficiaryIndex) }
                        placeholder={'Enter CNIC Number'}
                      />
                      <ValidationMessages
                        // validationRules={`customRequired|numbers_only|min:${utils.lengths.CNIC}|max:${utils.lengths.CNIC}`}
                        // validator={this.props.validator}
                        validator={validator}
                        value={data['CNIC Number']}
                        validationRules={`customRequired|insured_no_same_Id|cnic_length`}
                        validationParams={{ idCollections: this.getAllEnteredInsuredIdNumber('CNIC Number') }}
                        inputName={`${uniqueId}: CNIC Number`}
                      />
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['CNIC Number'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>CNIC Issuance Date</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <CommonInputTypes
                        dateStyle={{
                          marginBottom: 0,
                        }}
                        validator={''}
                        group={false}
                        type='date'
                        value={data['CNIC Issuance Date']}
                        onChange={(date) => this.props.inputChange({ target: { id: 'CNIC Issuance Date', value: date } }, beneficiaryIndex)}
                        maxDate={new Date()}
                        openToDate={new Date()}
                        id='CNIC Issuance Date'
                        className='datePickerBorderLeft'
                        placeholder={'Enter CNIC Issuance Date'}
                      />
                      <ValidationMessages
                        validationRules='customRequired|issuance_date_boudary'
                        validator={validator}
                        value={{
                          value: data['CNIC Issuance Date'],
                          validationParams: validationDates
                        }}
                        inputName={`${uniqueId}: CNIC Issuance Date`}
                      />
                    </>
                  ) : null }
                  { editMode ? null : (data && formatDate(data['CNIC Issuance Date']))}
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Passport Number</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                      id='Passport Number'
                      name='Passport Number'
                      value={data['Passport Number']}
                      onChange={(e) => this.handlePassportNumberInsurerChange(e, beneficiaryIndex) }
                      placeholder={'Enter Passport Number'}
                    />
                      <ValidationMessages
                        // validationRules={`customRequired`}
                        // validator={this.props.validator}
                        validator={validator}
                        value={data['Passport Number']}
                        inputName={`${uniqueId}: Passport Number`}
                        validationRules={`customRequired|alpha_num_passport|insured_no_same_Id`}
                        validationParams={{ idCollections: this.getAllEnteredInsuredIdNumber('Passport Number') }}
                      />
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['Passport Number'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Date of Birth</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <CommonInputTypes
                        dateStyle={{
                          marginBottom: 0,
                        }}
                        validator={''}
                        group={false}
                        type='date'
                        value={data.DOB}
                        onChange = {(date) => this.props.inputChange({ target: { id: 'DOB', value: date } }, beneficiaryIndex)}
                        maxDate={getValidationDates(insuredType).maxDateOfBirth}
                        minDate={getValidationDates(insuredType).minDateOfBirth}
                        openToDate={data.DOB}
                        id='DOB'
                        className='datePickerBorderLeft'
                      />
                      <ValidationMessages
                        validationRules={'customRequired|validate_age_boundary'}
                        validator={validator}
                        value={{
                          value: data['DOB'],
                          validationParams: validationDates
                        }}
                        inputName={generateDobErrorMessageByType(insuredType)}
                        // inputName={`${uniqueId}: DOB`}
                      />
                    </>
                  ) : null }
                  { editMode ? null : (data && formatDate(data['DOB']))}
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Gender</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        type='select'
                        id='Gender'
                        name='Gender'
                        value={data.Gender}
                        onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                      >
                        {
                          detailCollections.genders.map(gender=> {
                            return (<option key={gender.id}>{gender.name}</option>);
                          })
                        }
                      </Input>
                      <ValidationMessages validationRules='customRequired' validator={validator} value={data.Gender} inputName='Gender' />
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['Gender'] : '-') }
                </Col>
              </Row>

              { main ? (
                <Row>
                  <Col className='pt-2 summary-label' sm={6}>Address</Col>
                  <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id='Address'
                          name='Address'
                          value={data['Address']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                          placeholder={'Enter Address'}
                        />
                        <ValidationMessages validator={validator} value={data['Address']} inputName='Address'
                          validationRules='customRequired'
                        />
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Address'] : '-') }
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>City / Town</Col>
                  <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id='City'
                          name='City'
                          value={data['City']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                          placeholder={'Enter City / Town'}
                        />
                        <ValidationMessages validator={validator} value={data['City']} inputName='City'
                          validationRules='customRequired'
                        />
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['City'] : '-') }
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>State / Province / Region</Col>
                  <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          // type='select'
                          id='State'
                          name='State'
                          value={data['State']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                          placeholder={'Enter State'}
                        >
                          {/*{*/}
                          {/*  detailCollections.states.map(state=> {*/}
                          {/*    return ( <option key={state.id}>{state.name}</option>);*/}
                          {/*  })*/}
                          {/*}*/}
                        </Input>
                        {/*<ValidationMessages validator={validator} value={data['State']} inputName='State'*/}
                        {/*  validationRules='customRequired'*/}
                        {/*/>*/}
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['State'] : '-') }
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>Country</Col>
                  <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          disabled={true}
                          type='select'
                          id='Country'
                          name='Country'
                          value={data['Country'] || 'Indonesia'}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        >
                          {
                            detailCollections.countries.map(country => {
                              return ( <option key={country.id}>{country.name}</option>);
                            })
                          }
                        </Input>
                        <ValidationMessages validator={validator} value={data['Country']} inputName='Country'
                          validationRules='customRequired'
                        />
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Country'] || 'Indonesia' : '-') }
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>Postcode</Col>
                  <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id='Postcode'
                          name='Postcode'
                          value={data['Postcode']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                          placeholder={'Enter Postcode'}
                        />
                        {/*<ValidationMessages*/}
                        {/*  validationRules={`numeric|min:${defaultValues.postcodeLength}|max:${defaultValues.postcodeLength}`}*/}
                        {/*  validator={validator} value={data['Postcode']}*/}
                        {/*  inputName='Postcode'*/}
                        {/*/>*/}
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Postcode'] : '-') }
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>Email Address</Col>
                  <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id='Email Address'
                          name='Email Address'
                          value={data['Email Address']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                          placeholder={'Enter Email Address'}
                        />
                        <ValidationMessages validationRules='email|customRequired' validator={validator} value={data['Email Address']} inputName='Email Address'/>
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Email Address'] : '-') }
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>Phone Number</Col>
                  <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id='Contact Number'
                          name='Contact Number'
                          value={data['Contact Number']}
                          onChange={(e) => this.handleContactNumberChange(e, beneficiaryIndex, this.props.inputChange)}
                          placeholder={'Enter Phone Number'}
                        />
                        <ValidationMessages
                          validationRules={`numeric|customRequired|min:${defaultValues.contactNumberLength}|max:${defaultValues.contactNumberLength}`}
                          validator={validator}
                          value={data['Contact Number']}
                          inputName='Contact Number'
                        />
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Contact Number'] : '-') }
                  </Col>
                </Row>
              ) : null }

            </Col>
            <Col sm={6}>
              <div className='custom-q-text-primary bold my-2'>Beneficiary Details</div>

              <Row className='wordBreak'>
                <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='Full Name'
                        name='Full Name'
                        value={beneficiary['Full Name']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter Full Name'}
                      />
                      {/*<ValidationMessages */}
                      {/*  validator={validator} */}
                      {/*  validationRules='customRequired|alfalah_full_name_valid_characters'*/}
                      {/*  value={beneficiary['Full Name']} */}
                      {/*  inputName={`${uniqueId} - benf: Full Name`}/>*/}
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Full Name'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Relationship</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        // type='select'
                        id='Relationship'
                        name='Relationship'
                        value={beneficiary['Relationship']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter Relationship'}
                      >
                        {/*{*/}
                        {/*  detailCollections.relations.map(relation => {*/}
                        {/*    return ( <option key={relation.id}>{relation.name}</option>);*/}
                        {/*  })*/}
                        {/*}*/}
                      </Input>
                      {/*<ValidationMessages*/}
                      {/*  validator={validator}*/}
                      {/*  value={beneficiary['Relationship']}*/}
                      {/*  inputName={`${uniqueId} - benf: Relationship`}*/}
                      {/*  validationRules='customRequired'*/}
                      {/*/>*/}
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Relationship'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>ID Type</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='ID Type'
                        name='ID Type'
                        value={beneficiary['ID Type']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter ID Type'}
                      >
                        {/*{*/}
                        {/*  detailCollections.idTypes.map(idType => {*/}
                        {/*    return ( <option key={idType.id}>{idType.name}</option>);*/}
                        {/*  })*/}
                        {/*}*/}
                      </Input>
                      {/*<ValidationMessages*/}
                      {/*  validator={validator}*/}
                      {/*  value={beneficiary['ID Type']}*/}
                      {/*  inputName={`${uniqueId} - benf: ID Type`}*/}
                      {/*  validationRules='customRequired'*/}
                      {/*/>*/}
                    </>
                    ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['ID Type'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>ID Number</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='ID Number'
                        name='ID Number'
                        value={beneficiary['ID Number']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter ID Number'}
                      />
                      {/*<ValidationMessages*/}
                      {/*  validator={validator}*/}
                      {/*  // validationRules={*/}
                      {/*  //   beneficiary['ID Type'] === 'CNIC'*/}
                      {/*  //     ? `customRequired|numbers_only|min:${utils.lengths.CNIC}|max:${utils.lengths.CNIC}` : 'customRequired'*/}
                      {/*  // }*/}
                      {/*  value={beneficiary['ID Number']}*/}
                      {/*  inputName={`${uniqueId} - benf: ID Number`}*/}

                      {/*  validationRules={`customRequired|beneficiary_no_same_Id|${beneficiary['ID Type'] === 'CNIC' ? 'cnic_length' : 'customRequired'}`}*/}
                      {/*  validationParams={{ idCollections: this.getRelevantEnteredInsuredIdNumber(beneficiary['ID Type'] === 'CNIC'*/}
                      {/*    ? 'CNIC Number' : 'Passport Number', beneficiaryIndex) }}*/}
                      {/*/>*/}
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['ID Number'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Address</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='Address'
                        name='Address'
                        value={beneficiary['Address']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter Address'}
                      />
                      {/*<ValidationMessages */}
                      {/*  validator={validator} */}
                      {/*  validationRules='customRequired'*/}
                      {/*  value={beneficiary['Address']} */}
                      {/*  inputName={`${uniqueId} - benf: Address`}/>*/}
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Address'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>City</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='City'
                        name='City'
                        value={beneficiary['City']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter City'}
                      />
                      {/*<ValidationMessages */}
                      {/*  validator={validator} */}
                      {/*  validationRules='customRequired'*/}
                      {/*  value={beneficiary['City']} */}
                      {/*  inputName={`${uniqueId} - benf: City`}/>*/}
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['City'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>State/Province/Region</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        // type='select'
                        id='State'
                        name='State'
                        value={beneficiary['State']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter State'}
                      >
                        {/*{*/}
                        {/*  detailCollections.states.map(state => {*/}
                        {/*    return ( <option key={state.id}>{state.name}</option>);*/}
                        {/*  })*/}
                        {/*}*/}
                      </Input>
                      {/*<ValidationMessages*/}
                      {/*  validator={validator}*/}
                      {/*  value={beneficiary['State']}*/}
                      {/*  inputName={`${uniqueId} - benf: State`}*/}
                      {/*  validationRules='customRequired'*/}
                      {/*/>*/}
                    </>
                    ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['State'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Country</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        // type='select'
                        id='Country'
                        name='Country'
                        value={beneficiary['Country']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter Country'}
                      >
                        {/*{*/}
                        {/*  detailCollections.countries.map(country => {*/}
                        {/*    return ( <option key={country.id}>{country.name}</option>);*/}
                        {/*  })*/}
                        {/*}*/}
                      </Input>
                      {/*<ValidationMessages*/}
                      {/*  validator={validator}*/}
                      {/*  value={beneficiary['Country']}*/}
                      {/*  inputName={`${uniqueId} - benf: Country`}*/}
                      {/*  validationRules='customRequired'*/}
                      {/*/>*/}
                    </>
                    ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Country'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Postcode</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='Postcode'
                        name='Postcode'
                        value={beneficiary['Postcode']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter Postcode'}
                      />
                      {/*<ValidationMessages */}
                      {/*  validator={validator} */}
                      {/*  validationRules={`numeric|min:${defaultValues.postcodeLength}|max:${defaultValues.postcodeLength}`}*/}
                      {/*  value={beneficiary['Postcode']} */}
                      {/*  inputName={`${uniqueId} - benf: Postcode`}/>*/}
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Postcode'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Email Address</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='Email'
                        name='Email'
                        value={beneficiary['Email']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                        placeholder={'Enter Email'}
                      />
                      {/*<ValidationMessages validationRules='email' validator={validator} value={beneficiary['Email']} inputName={`${uniqueId} - benf: Email`}/>*/}
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Email'] : '-') }
                </Col>

                <Col className='pt-2 summary-label' sm={6}>Phone Number</Col>
                <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id='Contact Number'
                        name='Contact Number'
                        value={beneficiary['Contact Number']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex, this.props.beneficiaryChange)}
                        placeholder={'Enter Phone Number'}
                      />
                      {/*<ValidationMessages*/}
                      {/*  validationRules={`numeric|customRequired|min:${defaultValues.contactNumberLength}|max:${defaultValues.contactNumberLength}`}*/}
                      {/*  validator={validator}*/}
                      {/*  value={beneficiary['Contact Number']}*/}
                      {/*  inputName={`${uniqueId} - benf: Contact Number`}*/}
                      {/*/>*/}
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Contact Number'] : '-') }
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

InsuredDetail.defaultProps = {
  editMode: false,
}

function mapStateToProps(state) {
  const { detailCollections } = state.policyManagement;

  return { detailCollections };
}

export default connect(mapStateToProps)(InsuredDetail);
