import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';

interface IProps {
    patchDetails: IObject[]
  }
  
const PatchDetailsCard = (props: IProps) => {
    const { patchDetails } = props;
    return (
        <div className='sidebarCard'>
        <div>
            <Card className='mt-4 custom-shadow-qc' style={{ borderRadius: '10px' }}>
            <CardBody className='px-3'>
                <div className='pt-2 text-primary'>
                <Row>
                    <Col sm={9} className='payment-title-qc font-size-normal text-secondary mt-2 mb-2'>
                        Patch Notes
                    </Col>
                </Row>
                </div>
                <hr className='my-2' />
                <div className='pt-2'>
                <Row className='pt-1 pb-3'>
                {
                    patchDetails && patchDetails.map((p:IObject) => (
                        <Col sm={12}>{p.displayRemarks}</Col>
                    ))
                }
            </Row>
                </div>
            </CardBody>
            </Card>
        </div>
        </div>
    );
};

export default PatchDetailsCard;