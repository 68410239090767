
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan.Product Name', name: '', config: {} },
    items: [
      {
        current: { id: 'properties.Plan.Total', name: '', config: { currency: true } },
        prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: 'Price Breakdown',
        name: 'Price Breakdown',
        items: [
          {
            current: { id: 'properties.Plan.Main Policy', name: 'Gross Premium', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Main Policy', name: 'Gross Premium', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Plan.Admin Fee', name: 'Admin Fee', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Admin Fee', name: 'Admin Fee', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      {
        id: 'Other Details',
        name: 'Other Details',
        items: [
          { current: { id: 'properties.Destination', name: 'Destination', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Trip Type', name: 'Trip Type', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Start Date', name: 'Start Date', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.End Date', name: 'End Date', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Insured Type', name: 'Insured Type', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Adults', name: 'Adults', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Children', name: 'Children', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Senior Citizens', name: 'Senior Citizens', config: {} }, prev: { config: {} }, config: {} },
        ],
        config: {},
      },
    ],
  },
};

export default model;
