import React from "react";
import { Col } from "reactstrap";
import { CommonInputTypes } from "_components";

const ContactDetails = (props) => {
	const { fields, collections } = props;
  return(
	  <>
      <Col sm="12">
        <CommonInputTypes
	    		id={1}
	    		idName="address"
          type="text"
          title="Address"
          group={false}
	    		placeholder="Enter address"
          onChange={(e) => props.handleChange(e)}
          validator={props.validator}
          validationRules="customRequired"
          value={fields.address}
        />
      </Col>
      <Col sm="6">
        <CommonInputTypes
          id={2}
	    		idName="country"
          type="select"
          onChange={(e) => props.handleChange(e)}
          title="Country"
          group={false}
	    		option={collections.countries}
          value={fields.country}
          disabled
      />
      </Col>
      <Col sm="6">
        <CommonInputTypes
	    		id={3}
	    		idName="state"
          type="select"
	    		title="State/Province/Region"
          group={false}
          option={collections.states}
          value={fields.state}
          onChange={(e) => props.handleChange(e)}
          validator={props.validator}
          validationRules="customRequired"
        />
      </Col>
      <Col sm="6">
        <CommonInputTypes
          id={4}
	    		idName="postcode"
	    		title="Postcode"
	    		type="text"
          placeholder="Enter postcode"
          group={false}
	    		onChange={(e) => props.handleNumberFormatFields(e)}
          validator={props.validator}
          validationRules="customRequired|numbersOnly"
          value={fields.postcode}
        />
      </Col>
      <Col sm="6">
        <CommonInputTypes
          id={5}
	    		idName="city"
	    		type="text"
	    		title="City/Town"
	    		group={false}
          placeholder="Enter City/Town"
	    		onChange={(e) => props.handleChange(e)}
          validator={props.validator}
          validationRules="customRequired"
          value={fields.city}
        />
      </Col>
      <Col sm="6">
        <CommonInputTypes
	    		id={6}
	    		idName="email"
          type="email"
	    		title="Email Address"
          group={false}
	    		placeholder="Enter email address"
	    		onChange={(e) => props.handleChange(e)}
          validator={props.validator}
          validationRules="customRequired|email"
          value={fields.email}
        />
      </Col>  
      <Col sm="6">
        <CommonInputTypes
          id={7}
	    		idName="contactNumber"
          type="text"
	    		title="Contact Number"
          group={false}
	    		placeholder="Enter contact number"
	    		onChange={(e) => props.handleNumberFormatFields(e)}
          validator={props.validator}
          validationRules="customRequired|numbersOnly"
          value={fields.contactNumber}
        />
      </Col>
	  </>
  );
};

export default ContactDetails;
