import React from 'react';
import {
  Button,
  CardFooter,
} from 'reactstrap';

const FilterFooter = (props: { onApplyClick: Function, onCancelClick: Function }) => {
  const { onApplyClick, onCancelClick } = props;
  return (
    <CardFooter className='text-muted'>
      <div className='row justify-content-end' id='modalButton'>
        <div className='col-2'>
          <Button
            className='text-info'
            color='link'
            onClick={() => onCancelClick()}
          >
            Cancel
          </Button>
        </div>
        <div className='col-3'>
          <Button
            onClick={() => onApplyClick()}
            color='primary'
            className='fullWidth rounded-xl'
          >
            Apply
          </Button>
        </div>
      </div>
    </CardFooter>
  );
};

export default FilterFooter;
