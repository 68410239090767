import { orgConstants } from "../constants";
import { HANDLE_FILTER_CLICKED } from "../../../_constants";
import { setHasRecords } from "../../../utils";

const initialState = {
  canViewList: true,
  hasRecords: true,
  keepFilters: false, /** when backing to list from detail page, list should not get refreshed */
};
function organisations(state = initialState, action) {
  let pagination;
  switch (action.type) {
    case orgConstants.GET_ORG_LIST:
      pagination = {
        first: action.list.list.first,
        last: action.list.list.last,
        currentPage: action.list.list.number,
        pageable: action.list.list.pageable,
        totalElement: action.list.list.totalElements,
        totalPages: action.list.list.totalPages,
      };
      const hasRecords = setHasRecords(action.list.list.content);
      return {
        ...state,
        pagination,
        hasRecords,
        canViewList: true,
        orgList: action.list.list.content,
        collections: action.list.collections,
        modalClose: false,
        filterOptions: action.list.filters, // processFilters(action.list.filters),
      };
    case orgConstants.SET_FORBIDDEN_ORG:
      return {
        ...initialState,
        canViewList: false,
      };
    case orgConstants.GET_ORG_DETAILS:
      return {
        ...state,
        keepFilters: true,
        orgDetails: action.data,
      };
    case orgConstants.UPDATE_PRODUCTS:
      return {
        ...state,
        orgDetails: { ...state.orgDetails, products: action.data },
      };
    case orgConstants.UPDATE_ORG_LIST:
      return {
        ...state,
        orgList: [...state.orgList, action.list],
        collections: state.collections,
        modalClose: false,
        notification: action.notification,
      };
    case orgConstants.ORGS_APPLY_FILTER:
      pagination = {
        first: action.list.list.first,
        last: action.list.list.last,
        currentPage: action.list.list.number,
        pageable: action.list.list.pageable,
        totalElement: action.list.list.totalElements,
        totalPages: action.list.list.totalPages,
      };
      return {
        ...state,
        pagination,
        orgList: action.list.list.content === "" ? state.orgList : action.list.list.content,
        collections: state.collections,
        modalClose: false,
        filterOptions: action.list.filter === "" ? state.filterOptions : action.list.filter,
      };
    case orgConstants.ORG_REMOVE_NOTIFICATION:
      return {
        ...state,
        orgList: state.orgList,
        collections: state.collections,
        modalClose: false,
        notification: "",
      };
    case orgConstants.GET_ORG_CHART:
      return {
        ...state,
        orgChart: action.payload,
      };
    case HANDLE_FILTER_CLICKED:
      return {
        ...state,
        filterOptions: action.payload.filterOptions,
      };
    case orgConstants.RESET_KEEP_FILTERS:
      return {
        ...state,
        keepFilters: false,
      };
    default:
      return state;
  }
}

export default organisations;
