import Notifications from 'react-notification-system-redux';
import {handleRequest, handleResponse, getNotificationMessage, checkPermission} from "../../../_services";
import { store } from "../../../_helpers";
import {getRequestOptions} from "../../../utils";

const Config = require('_config/env');

const apiUrl = Config.env.apiVersion;
const privacyUrl = Config.env.policyUrl;

export const resServices = {
  getResList,
  hasPermission,
  updateRes,
  getResManageList,
  deleteRes,
  downloadResource,
  getOrganizationList
};

async function getResList() {
  const url = `${privacyUrl}/resource-center`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  let res;
  if (serverResponse.error) {
    store.dispatch(Notifications.error(getNotificationMessage('An error occurred in getting resources.')));
  } else res =  await handleResponse(serverResponse);
  return res || serverResponse;
}

async function getResManageList() {
  const url = `${privacyUrl}/resource-center/manage-resources`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  let res;
  if (serverResponse.error) {
    store.dispatch(Notifications.error(getNotificationMessage('An error occurred in getting resources.')));
  } else res =  await handleResponse(serverResponse);
  return res || serverResponse;
}

async function updateRes(body){
  const url = `${privacyUrl}/resource-center/add-resource`;
  const serverResponse = await handleRequest(url, getRequestOptions({ body, method:"Post", contentType: "multipart/form-data" }));
  const res = await handleResponse(serverResponse);
  return res;
}

async function deleteRes(id){
  const url = `${privacyUrl}/resource-center/${id}`;
  const serverResponse = await handleRequest(url, getRequestOptions({ method:"Delete" }));
  const res = await handleResponse(serverResponse);
  return res;
}

async function getOrganizationList() {
  const url = `${apiUrl}/identity/orgunits`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  let res;
  if (serverResponse.error) {
    store.dispatch(Notifications.error(getNotificationMessage('An error occurred in getting organization.')));
  } else res =  await handleResponse(serverResponse);
  return res || serverResponse;
}

async function downloadResource (id) {
  const url = `${privacyUrl}/resource-center/download/${id}`;
  const response = await handleRequest(url, getRequestOptions());
  try {
    return await response.blob();
  } catch (e) {
    return {
      error: e,
      message: e.message
    }
  }
}

async function hasPermission (scope) {
  const permission = await checkPermission(scope);
  let isPermitted = true;
  if(!permission.error) {
    if(permission.status !== "permitted") {
      isPermitted = false;
    }
    return isPermitted;
  }
}