const genders = {
  FEMALE: "Female",
  MALE: "Male",
};
const formats = {
  date: {
    default: "DD/MM/YYYY",
    long: "YYYY-MM-DDTHH:mm:ss.SSSZ",
    dateFormatShortMonthName: "DD MMM YYYY",
  },
};
const khmerNumber = [
  '១', '២', '៣', '៤', '៥', '៦', '៧'
]
const viewTypes = [{ name: "Nested View" }, { name: "List View" }];
const defaultTravelNoOfDays = 4;
const defaultTuguTravelMaximumNoOfReturnDays = 185;
const defaultAlfalahTravelMaximumNoOfReturnDays = 180;

const constants = {
  genders,
  formats,
  viewTypes,
  defaultTravelNoOfDays,
  defaultTuguTravelMaximumNoOfReturnDays,
  defaultAlfalahTravelMaximumNoOfReturnDays,
  khmerNumber
};
export default constants;
