import React, { createRef } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { BaseComponent } from '@xc-core/components';
import { dateLib } from '@xc-core/lib';
import config from '@xc-core/config';

interface IProps {
  // style: any,
  onSelected: (start: string, end: string) => void,
  start: string,
  end:string
}
interface IState { isOpen: boolean }
class DateRange extends BaseComponent<IProps, IState> {
  private dateRangePickerRef = createRef<DateRange>();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  onDateRangeChange = (dateRange: any[]) => {
    const { onSelected } = this.props;
    const start = dateLib.toString(dateRange[0], config.dateMomentFormat);
    const end = dateLib.toString(dateRange[1], config.dateMomentFormat);
    this.setState((prevState) => ({
      ...prevState,
      start,
      end,
    }));
    onSelected(start, end);
  };


  onCalendarClose = () => (this.setState({ isOpen: false }, () => {
    /** we do this otherwise the calendar will be closed, it has to be manually triggered */
    this.setState({ isOpen: true });
  }));

  getDateRange = (start: string, end: string) => {
    const range = [dateLib.toDate(start, config.dateMomentFormat),
      dateLib.toDate(end, config.dateMomentFormat)];
    return range;
  }

  render() {
    const { isOpen } = this.state;
    const { start, end } = this.props;
    return (
      <>
        <div className='calendar-container'>
          <DateRangePicker
            ref={this.dateRangePickerRef}
            onChange={this.onDateRangeChange}
            onCalendarClose={this.onCalendarClose}
            value={this.getDateRange(start, end)}
            isOpen={isOpen}
          />
        </div>
        {/* <p className={`${ false ? 'd-none' : 'calendar-notice' }`}>
        Please select both start and end date</p> */}
        <p className='calendar-notice'>Please select both start and end date</p>
      </>
    );
  }
}

export default DateRange;
