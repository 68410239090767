import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import lib from "../../../utils/lib";
import DetailsComponent from "./DetailsComponent";

const SumAssuredStep =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            initValues,
        },
    } = props;

    useEffect(() => {
        if(!item.properties?.['Sum Assured']){
            initValues([
                {
                    id: model.sumAssuredFields.id,
                    value:'40000'
                },
                {
                    id: 'properties.NCD',
                    value:45
                }
            ]);
        }
    }, []);

    return (
        <Row>
            <Col md='12' style={{marginBottom:5}}>
                <h5 className='sub-title'>{model.title}</h5>
                <RenderFields
                    onChange={(pairs: IPair[]) => {
                        onChange('onChange', {pairs})
                    }}
                    collections={collections}
                    item={item}
                    ext={lib.checkIsDemoSite()}
                    validationErrors={validationErrors}
                    fields={model.sumAssuredDetails.fields}
                    style={{
                        justifyContent:'center'
                    }}
                />
            </Col>
            <Col md='12'  style={{textAlign:'center' ,marginBottom:40}}>
                <span className={'summary-label-2'}>{`The current market value for your vehicle is RM${item.properties?.['Sum Assured']}`}</span>
            </Col>
            <Col md='6'>
                <h5 className='sub-title'>Car Details</h5>
                <DetailsComponent
                  item={item}
                  model={model}
                  objModels={(['vehicleRegistrationNumber' , ...model.carDetailsFields] as tObjModel)}
                  itemPropertiesKey={'Car Details'}
                />
            </Col>
            <Col md='6'>
                <h5 className='sub-title'>Car Owner's Details</h5>
                <DetailsComponent
                    item={item}
                    model={model}
                    objModels={(model.carOwnerFields as tObjModel)}
                    itemPropertiesKey={'Car Owner Details'}
                />
            </Col>
        </Row>
    )
}

export default SumAssuredStep;