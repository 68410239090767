import { START_LOADING, END_LOADING } from "../_constants";

export function loading(state = false, { type }) {
	switch (type) {
	case START_LOADING:
		return true;
	case END_LOADING:
		return false;
	default:
		return state;
	}
}
