import React from 'react';
import { Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row } from "reactstrap";
import { RadioButton , MultiValueInput } from '@xc-core/components/core';
import { Accordion } from  '_components/Accordion';
import Switch from 'react-toolbox/lib/switch/Switch.js';
import {
  FormGroup,
  Label,
} from "reactstrap";

const GroupInput = (props) => {
  const { mailingList, productIdx, stateIdx, handler , showErrorMessage } = props;
  return (
    <Col sm={8}>
      <Col sm={12}>
        <Switch theme={{ on: 'switchOn switch', thumb: 'thumb', off: 'switch'}}
          checked={mailingList?.products[productIdx]?.states[stateIdx]?.enabled}
          label={ mailingList?.products[productIdx]?.states[stateIdx]?.enabled ? 'ON' :'OFF'}
          on={'#ffff'}
          onChange={() => handler(productIdx, stateIdx,
            { id: 'enabled', value: mailingList?.products[productIdx]?.states[stateIdx]?.enabled })}
        />
      </Col>

      {
        mailingList?.products[productIdx]?.states[stateIdx]?.enabled ?
        <Col sm={12}>
          <FormGroup>
            <Label for='cc'>CC to:</Label>
            <MultiValueInput
              values={mailingList?.products[productIdx]?.states[stateIdx]?.cc?.length ? mailingList?.products[productIdx]?.states[stateIdx]?.cc : []}
              id={`cc`}
              name={'CC'}
              handler={(pair) => handler(productIdx, stateIdx, pair)}
              validationMessage={mailingList?.products[productIdx]?.states[stateIdx]?.enabled &&
                showErrorMessage &&
                !mailingList?.products[productIdx]?.states[stateIdx]?.cc?.length
                ? 'Required'
                : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for='bcc'>BCC to:</Label>
            <MultiValueInput
              values={mailingList?.products[productIdx]?.states[stateIdx]?.bcc?.length ? mailingList?.products[productIdx]?.states[stateIdx]?.bcc : []}
              id={`bcc`}
              name={'BCC'}
              handler={(pair) => handler(productIdx, stateIdx, pair)}
            />
          </FormGroup>
          </Col>
          : <></>
      }
    </Col>
  )
}

const Mailing = (props) => {

  const { orgDetails, handleConfigChange, collections, handleProductConfigChange, validationError } = props;
  const mailingList = orgDetails?.extendedProperties?.config?.mailingList;
    return (
      <Row className="justify-content-center my_50">
        <Col sm={10}>
        <ListGroup  className="list shadow-sm rounded-xl">
          <ListGroupItemHeading className="text-primary p-3">
          <ListGroupItemText>
              Email CC &amp; BCC Settings
          </ListGroupItemText>
          </ListGroupItemHeading>

          <Row className="text-primary pl-4 pr-4">
            <Col sm={12} className="p-1 form-set">
              <RadioButton
                id={'1'}
                name= {'enabled'}
                label={'Do not CC or BCC any email addresses in customer emails (New Certificate, Endorsement, Cancellation)'}
                value={mailingList?.enabled ? 'true' : 'false'}
                checked={!mailingList?.enabled}
                handler={handleConfigChange}
                validationMessage=''
              />
            </Col>
            <Col sm={12} className="p-1 form-set">
              <RadioButton
                id={'1'}
                name= {'enabled'}
                label ={'Specify which email addresses should be CC-ed or BCC-ed in customer emails (New Certificate, Endorsement, Cancellation)'}
                value={mailingList?.enabled ? 'true' : 'false'}
                checked={mailingList?.enabled}
                handler={handleConfigChange}
                validationMessage=''
                />
            </Col>
          </Row>

          { collections && collections.products.length  ?
            collections.products.map((product, index) => {
              return (
                <ListGroupItem key={index} className={`with-transition ${mailingList?.enabled ? 'actived' : 'deactived' } border-0 border py-0`}>
                  <Row className="justify-content-between align-items-center">
                    <Col sm={12} className="justify-end">
                    <Accordion
                        key={index}
                        headerComponent={product.productName}
                        containerClasses={'shadow-none'}
                        bodyComponent={
                          <Row>
                            <Col sm={12}>
                              <Row sm={12} className="justify-content-center p-3 ml-2">
                                <Col sm={4}>
                                  <p className='font-weight-bold'>New Certificates</p>
                                  <p>Receive a copy of the email with a generated certificate attached for every new sale of this product.</p>
                                </Col>

                                <GroupInput
                                  mailingList={mailingList}
                                  productIdx={index}
                                  stateIdx={0}
                                  handler={handleProductConfigChange}
                                  showErrorMessage={validationError}
                                />
                              </Row>

                              <Row sm={12} className="justify-content-center p-3 ml-2">
                                <Col sm={4}>
                                  <p className='font-weight-bold'>Endorsement</p>
                                  <p>Receive a copy of the email with an endorsed certificate attached for every endorsement of existing certificates of this product.</p>
                                </Col>

                                <GroupInput
                                  mailingList={mailingList}
                                  productIdx={index}
                                  stateIdx={1}
                                  handler={handleProductConfigChange}
                                  showErrorMessage={validationError}
                                />
                              </Row>

                              <Row sm={12} className="justify-content-center p-3 ml-2">
                                <Col sm={4}>
                                  <p className='font-weight-bold'>Cancellation</p>
                                  <p>Receive a copy of the notification email for every cancellation of existing certificates of this product.</p>
                                </Col>

                                <GroupInput
                                  mailingList={mailingList}
                                  productIdx={index}
                                  stateIdx={2}
                                  handler={handleProductConfigChange}
                                  showErrorMessage={validationError}
                                />
                              </Row>
                            </Col>
                          </Row>
                        }
                    />
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })
            : null
          }
        </ListGroup>
        </Col>
      </Row>
    );
}

export default Mailing;