import env from 'env';
import Request from './request';
import Response from './response';

class DistributionControlService{

    protected env = env.policy;
    protected api = 'PolicyLimitConfig';

    public getList = async (pagination?:IObject):Promise<any>=>{
        let url = ''
        if(pagination){
            url = `${this.env}/${this.api}?page=${pagination.page}&pagesize=${pagination.pagesize}`
        } else {
            url = `${this.env}/${this.api}`;
        }
        const request = new Request();
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public getListWithParams = async (data:IObject):Promise<any> => {
        const url = `${this.env}/${this.api}/search`;
        const request = new Request(data,'POST');
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public getDetails = async (id:any):Promise<any>=>{
        const url = `${this.env}/${this.api}/${id}`;
        const request = new Request();
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public saveSettings = async (data:IObject):Promise<any> =>{
        const url = `${this.env}/${this.api}`;
        const request = new Request(data,'POST');
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public deleteLimit = async (data:IObject):Promise<any> =>{
        const url = `${this.env}/${this.api}-details`;
        const request = new Request(data,'DELETE');
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public search = async (data:IObject):Promise<any> =>{
        const url = `${this.env}/${this.api}/search`;
        const request = new Request(data,'POST');
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }
}

export default DistributionControlService;