
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { objLib } from '@xc-core/lib';
import lib from 'utils/lib';

const PrepareProduct = (props: ILoadableComponentProps) => {
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            initValues,
            defaults,
            next
        },
    } = props;

    useEffect(() => {
        if(next && typeof next==="function"){
            next()
        }
    }, []);

    return (
        <Row>
            <h1>Hello</h1>
        </Row>
    );
};

export default PrepareProduct;
