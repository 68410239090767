const model: IObject[] = [
  {
    title: 'Forced Password Expiry of Existing Accounts',
    values: [{
      label: 'Grace Period',
      type: 'number',
      meta: 'days',
      code: 'gracePeriod',
      toolTip: `If you save any changes to the Password Requirements on this page, then all existing users must reset their password during the Grace Period.<br /> <br />
        When the Grace Period ends, any old passwords that have not been reset will be force-expired. <br /> <br />
        The Grace Period does not apply to users created after the Password Requirements have changed. New users must follow the new Password Requirements immediately.`,
    }],
  },
  {
    title: 'Password Length',
    values: [{
      label: 'Minimum password Length', type: 'number', meta: 'characters', code: 'minLength',
    }, {
      label: 'Maximum password Length', type: 'number', meta: 'characters', code: 'maxLength',
    }],
  },
  {
    title: 'Password Complexity',
    values: [
      {
        label: 'Must contain special characters or symbols', type: 'checkbox', code: 'complexityList', value: 'SPECIAL_CHAR',
      },
      {
        label: 'Must contain uppercase letters', type: 'checkbox', code: 'complexityList', value: 'UPPER',
      },
      {
        label: 'Must contain lowercase letters', type: 'checkbox', code: 'complexityList', value: 'LOWER',
      },
      {
        label: 'Must contain numbers', type: 'checkbox', code: 'complexityList', value: 'NUMBER',
      },
    ],
  },
  {
    title: 'Disable Inactive user',
    values: [{
      label: 'Disable if user hasn\'t logged-in for', type: 'number', meta: 'days', code: 'maxIdleDays',
    }],
  },
  {
    title: 'Password Expiry',
    values: [{
      label: 'Current password will expire in', type: 'number', meta: 'days', code: 'maxValidDays',
    }],
  },
  {
    title: 'Old Password Lock Duration ',
    values: [{
      label: 'Old password cannot be used for', type: 'number', meta: 'days', code: 'maxPrevUsageDurationDays',
    }],
  },
  {
    title: 'Failed Login Attempt Threshold',
    values: [{
      label: 'Lock user if they fail to login', type: 'number', meta: 'times', code: 'maxLoginAttempts',
    }],
  },
];

export default model;
