import React from 'react';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';

const Notification = (props: { notifications: any }) => {
  const { notifications } = props;
  const style = {
    NotificationItem: {
      DefaultStyle: { margin: '10px 5px 2px 1px' },
      success: { color: 'green' },
    },
  };
  return (
    <Notifications
      notifications={notifications}
      style={style}
    />
  );
};

const mapStateToProps = (state: IObject) => ({ notifications: state.notifications });

export default connect(mapStateToProps)(Notification);
