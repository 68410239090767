import React from "react";

const styles = {
  root: {
    fontFamily:
      '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
    fontWeight: 300
  }
};

const MaterialTitlePanel = props => {
  const rootStyle = props.style
    ? { ...styles.root, ...props.style }
    : styles.root;

  return <div style={rootStyle}>{props.children}</div>;
};

MaterialTitlePanel.propTypes = {
  // children: PropTypes.array
};

export default MaterialTitlePanel;
