import React from 'react';
import { objLib } from '@xc-core/lib';

const Switch = (props: ISwitchFormControlModel)=>{

    const {
        handler, id, value, name, className,
        disabled, autoFocus, validator,
        rules, validationMessage,validationName,
        validationData, validationRules,
      } = props;

      const onChange = (e: any) => {
        const pair = objLib.getPair(id, e.target.checked);
        handler(pair)
      }



      return <div className={`xc-switch ${className ? className : ''} ${disabled ? 'disabled' : ''}`}>
          <label htmlFor={id}>
            <input id={id} type="checkbox" name={name} checked={value} onChange={onChange} autoFocus={autoFocus}/>
            <span className='box'></span>
            <span className='lever'></span>
        </label>
        {validator 
          ? <div>{validator.message(validationName ? validationName : name,
            validationData ? validationData : value,
            validationRules ? validationRules : "") }</div>
          : ''}

        {
          validationMessage ? <div className='text-danger-step'>{validationMessage}</div> : <></>
        }
      </div>

}

export default Switch;