import React from 'react';
import Input from 'react-toolbox/lib/input';

const InputAnimatedLabel = (props: IInputFormControlModel) => {
  const {
    className, validator, value, name, placeholder, id, rules, customMessage, handler,
    icon,
  } = props;

  const onChange = (val: any) => {
    const pair: IPair = { id, value: val };
    handler(pair);
  };
  const iconElement = <i className={`icon-${icon}`} />;
  return (
    <>
      <Input
        className={`py-2 ${className}`}
        type='text'
        label={placeholder}
        icon={iconElement}
        value={value}
        onChange={(val: any) => onChange(val)}
      />
      {/* {
        validator
          ? validator.validateFieldAndGetMessage({
            name,
            value,
            rules: rules || [],
            customMessage,
          }) : ''
      } */}
    </>
  );
};

export default InputAnimatedLabel;
