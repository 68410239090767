import React, {Fragment} from "react";
import {Col, Label} from "reactstrap";
import {CommonInputTypes} from "../../../../_components/CommonInputTypes"
import { CONFIG_DATE_MOMENT_FORMAT, utils, calculateDobMomentFromIC, calculateGenderFromIC } from "../../../../utils";
import moment from "moment";
class BasicInfoForm extends React.Component {
  handleICInput = (e) => {
    const {target} = e;
    const {curentThis} = this.props;

    if (String(target.value).length >= 6) {
      this.handleICGenderDOBAutofill(target.value);
    }
    curentThis.handleInputChange(e);
  };

  handleOtherInsuredICInput = (id, isBeneficiary, e) => {
    const {target} = e;
    const {curentThis} = this.props;
    // const {OtherInsured} = this.props.curentThis.state;
    const curID = id;

    if (isBeneficiary === false && String(target.value).length >= 6) {
      this.handleICGenderDOBAutofill(target.value, curID);
    }
    if (!isBeneficiary) {
      curentThis.updateOtherInsured(curID, e);
    } else {
      curentThis.updateBeneficiaryDetails(curID, e);
    }
  };

  handleICGenderDOBAutofill(val, id) {
    const { curentThis } = this.props;
    const curID = id;
    const birthGender = calculateGenderFromIC(val);
    const dobM = calculateDobMomentFromIC(val);
    if (dobM.isValid()) {
      if (curID === undefined) {
        curentThis.forceUpdate(() => {
          curentThis.setState({
            properties:
              {
                ...curentThis.state.properties,
                "DOB": dobM.format(CONFIG_DATE_MOMENT_FORMAT),
                "Gender": birthGender
              }
          });
        })
      } else {
        curentThis.state.OtherInsured.map((data, index) => {
          if (curID === data.id) {
            // console.log(curentThis.state.OtherInsured[index]);
            //creates a clone of the state
            let updatedArr = curentThis.state.OtherInsured.slice();

            updatedArr[index] = {
              ...curentThis.state.OtherInsured[index],
              "ID Number": val,
              "DOB": dobM.format(CONFIG_DATE_MOMENT_FORMAT),
              "Gender": birthGender
            };

            curentThis.forceUpdate(() => {
              curentThis.setState({OtherInsured: updatedArr})
            })
          }
        })
      }
    } else {
      if (curID === undefined) {
        curentThis.forceUpdate(() => {
          curentThis.setState({
            properties:
              {
                ...curentThis.state.properties,
                "DOB": "",
                "Gender": birthGender
              }
          });
        })
      } else {
        curentThis.state.OtherInsured.map((data, index) => {
          if (curID === data.id) {
            // console.log(curentThis.state.OtherInsured[index]);
            //creates a clone of the state
            let updatedArr = curentThis.state.OtherInsured.slice();

            updatedArr[index] = {
              ...curentThis.state.OtherInsured[index],
              "ID Number": val,
              "DOB": "",
              "Gender": birthGender
            };

            curentThis.forceUpdate(() => {
              curentThis.setState({OtherInsured: updatedArr})
            })
          }
        })
      }
    }
  }

  handleContactNumberChange = (e) => {
    const {value} = e.target;
    if (Number.isInteger(Number(value))) this.props.handleInputChange(e);
  };
  handleDobInputDateChange = (date) => {
    this.props.handleDobInputDateChange(date);
  }

  getAlert() {
    if (this.validator.allValid()) {
      // this.props.curentThis.setState({disabled:false});
      return true;
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      // this.props.curentThis.setState({disabled:true});
      return false;
    }
  }

  handleIdTypeChange = (e, isMain) => {
    const { value } = e.target;
    const {curentThis: {state: {properties}}, handleInputChange} = this.props;
    if(isMain) {
      const currentIdNumber = properties["ID Number"];
      if (value === "IC" && currentIdNumber && String(currentIdNumber).length === utils.lengths.IC  ) {
        this.handleICGenderDOBAutofill(currentIdNumber);
      }
    }
    handleInputChange(e)
  };
  render() {
    const {
      updatedVal, collections, beneficiary, disableAll,
      curentStep, curentID,
      openToDateOfBirth, maxDateOfBirth,
      minDateOfBirth, index,
    } = this.props;
    // const {IDNumberError} = curentThis.state;
    let disable = disableAll ? disableAll : false;

    const isMain = !beneficiary && index === "base";

    return (
      <Fragment>
        <Col sm="6">
          <CommonInputTypes
            idName={"Full Name"}
            idNameIndex={`Full Name-${index}`}
            type={"input"}
            value={updatedVal["Full Name"] || ""}
            onChange={this.props.handleInputChange}
            title={"Full Name"}
            id={1}
            group={false}
            placeholder={"Enter full name"}
            validator={disable ? "" : this.props.validator}
            validationRules="custom_required|full_name_valid_characters"
            disabled={disable}
            autoFocus= {this.props.autoFocus}
          />
        </Col>
        {!beneficiary &&
        <Col sm="6">
          <CommonInputTypes
            idName={"Nationality"}
            idNameIndex={`Nationality-${index}`}
            type="select"
            value={updatedVal["Nationality"]}
            onChange={this.props.handleInputChange}
            title={"Nationality"}
            id={1}
            group={false}
            option={collections.nationalities}
            validator={disable ? "" : this.props.validator}
            validationRules="custom_required"
            disabled={disable}
          />
        </Col>
        }
        {beneficiary &&
        <Col sm="6">
          <CommonInputTypes
            idName={"Relationship"}
            idNameIndex={`Relationship-${index}`}
            type="select"
            value={updatedVal["Relationship"]}
            onChange={this.props.handleInputChange}
            title={"Relationship"}
            id={1}
            group={false}
            option={collections.relations}
            validator={this.props.validator}
            validationRules="custom_required"
          />
        </Col>
        }

        <Col sm="6">
          <CommonInputTypes
            idName={"ID Type"}
            idNameIndex={`ID Type-${index}`}
            type="select"
            value={updatedVal["ID Type"] || ""}
            onChange={(e) => this.handleIdTypeChange(e, isMain) }
            title={"ID Type"}
            id={1}
            group={false}
            option={collections.idTypes}
            validator={disable ? "" : this.props.validator}
            validationRules="idtype|custom_required"
            disabled={disable}
            curentID={curentID}
          />
        </Col>

        <Col sm="6">
          <CommonInputTypes
            idName="ID Number"
            idNameIndex={`ID Number-${index}`}
            type="input"
            value={updatedVal["ID Number"] || ""}
            onChange={updatedVal["ID Type"] === "IC" ?
            (isMain ? this.handleICInput : this.handleOtherInsuredICInput.bind(curentStep, curentID, beneficiary)) :
            this.props.handleInputChange
          }
            title="ID Number"
            id={1}
            group={false}
            placeholder="Enter ID Number"
            validationRules={
              updatedVal["ID Type"] === "IC" ?
              `custom_required|ic|idNumber_dob_inFuture|min:${utils.lengths.IC}|max:${utils.lengths.IC}`
              : "alpha_num_passport|custom_required"
            }
            validator={disable ? "" : this.props.validator}
            disabled={updatedVal["ID Type"] === undefined || updatedVal["ID Type"] === "" || disable}
            curentID={curentID}
          />
        </Col>

        {!beneficiary && (
          <Fragment>
            <Col sm="6">
            <Label for="dob">Date of Birth</Label>
            <CommonInputTypes
                idName={"DOB"}
                idNameIndex={`DOB-${index}`}
                type="date"
                validator={this.props.validator}
                disabled={updatedVal["ID Type"] === "IC" ? true : disable}
                value={updatedVal["DOB"]}
                id={1}
                group={false}
                placeholder="Select Date of Birth"
                className="datePickerBorderLeft"
                onChange={(date) => 
                  {
                  this.props.onChange(date);
                }}
                maxDate={maxDateOfBirth}
                minDate={minDateOfBirth}
                openToDate={updatedVal["DOB"] || openToDateOfBirth}
                validationRules="custom_required|validate_age_boundary"
                validationParams={{maxDateOfBirth, minDateOfBirth}}
                handleInputDateChange = {(date) => this.handleDobInputDateChange(date)} //text input
              />
            </Col>
            <Col sm="6">
              <CommonInputTypes
                idName="Gender"
                idNameIndex={`Gender-${index}`}
                type="select"
                value={updatedVal["Gender"]}
                onChange={this.props.handleInputChange}
                title="Gender"
                id={1}
                group={false}
                option={collections.genders}
                validator={disable ? "" : this.props.validator}
                validationRules="custom_required"
                disabled={updatedVal["ID Type"] === "IC" ? true : disable}
                // placeholder={updatedVal["ID Type"] === "IC" ? "" : null}
              />
            </Col>
            <Col sm="12">
              <CommonInputTypes
                idName="Place of Birth"
                idNameIndex={`Place of Birth-${index}`}
                type="select"
                value={updatedVal["Place of Birth"]}
                onChange={this.props.handleInputChange}
                title="Place of Birth"
                id={1}
                group={false}
                option={collections.placeOfBirth}
                validator={disable ? "" : this.props.validator}
                validationRules="custom_required"
                disabled={disable}
              />
            </Col>
          </Fragment>
        )}
        {beneficiary && (
          <Fragment>
            <Col sm="6">
              <CommonInputTypes
                idName="Email"
                idNameIndex={`Email-${index}`}
                type="email"
                value={updatedVal["Email"]}
                onChange={this.props.handleInputChange}
                title="Email Address"
                id={1}
                group={false}
                validator={disable ? "" : this.props.validator}
                disabled={disable}
                placeholder="Enter email address"
                validationRules="email|custom_required"
              />
            </Col>
            <Col sm="6">
              <CommonInputTypes
                idName="Contact Number"
                idNameIndex={`Contact Number-${index}`}
                type="input"
                value={updatedVal["Contact Number"]}
                onChange={(e) => this.handleContactNumberChange(e)}
                title="Contact Number"
                id={1}
                group={false}
                validator={disable ? "" : this.props.validator}
                validationRules="integer|custom_required"
                disabled={disable}
                placeholder="Enter contact number"
              />
            </Col>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default BasicInfoForm;
