import { BaseComponent } from '@xc-core/components';
import {
  numberLib,
} from '@xc-core/lib';

class BaseListingContainer<P extends IBaseListingComponentProps, S={}> extends BaseComponent<P, S> {
  async componentDidMount() {
    const { initProduct, setHasRecordsInitially } = this.props;
    if (initProduct) {
      await initProduct();
      await this.setList();
      setHasRecordsInitially();
    } else {
      await this.setList();
      setHasRecordsInitially();
    }
  }

  onProductChange = async (product: IProduct) => {
    const {
      changeProduct,
      changePage,
      resetFilters,
      setHasRecordsInitially,
    } = this.props;
    await changePage(0);
    await changeProduct(product);
    await resetFilters();
    await this.setList();
    setHasRecordsInitially();
  }

  onChangeTab = async (pair: IPair) => {
    const { changeTab, changePage } = this.props;
    await changePage(0);
    await changeTab(pair);
    this.setList();
  }

  onApplyFilter = async (filters: IListingFilter[]) => {
    const { applyFilters } = this.props;
    await applyFilters(filters);
    this.setList();
  }

  onCancelFilter = (prevFilters: IListingFilter[]) => {
    const { cancelFilters } = this.props;
    cancelFilters(prevFilters);
  }

  onFilterCheckChange = (filters: IListingFilter[]) => {
    const { changeFilters } = this.props;
    changeFilters(filters);
  }

  onExport = (pii: boolean, fileName: string) => {
    const { exportCsv, getSearchParams } = this.props;
    const params = getSearchParams();
    params.export_pii = [pii ? 'true' : 'false'];
    exportCsv(params, fileName);
  }

  onSearchChange = async (pair: IPair) => {
    const { changeSearchKey, changePage } = this.props;
    changePage(0);
    changeSearchKey(pair.value);
    await this.setList();
  }

  onPageClick = async (pair: IPair) => {
    const { changePage } = this.props;
    const number = numberLib.toInt(pair.value);
    await changePage(number);
    this.setList();
  }

  onPageSizeChange = async (pair: IPair) => {
    const { changePageSize, changePage } = this.props;
    const number = numberLib.toInt(pair.value);
    await changePage(0);
    await changePageSize(number);
    this.setList();
  }

  onRowClicked = (path: string, id: string) => {
    this.props.history.push(`/${path}/${id}`);
  }

  setList = async (): Promise<void> => {
    const { getSearchParams, setList } = this.props;
    const params = getSearchParams();
    setList(params);
  }
}

export default BaseListingContainer;
