const minAge = {
  years: 18,
  months: 0,
  days: 0,
};

const country = 'Pakistan';

const stateListParam = 'state_alf';

const cnicLength = 13;

const postcodeLength = 5;

const debitCreditNoLength = 16;

const contactNumberLength = 11;

const defaults = {
  minAge,
  country,
  stateListParam,
  cnicLength,
  postcodeLength,
  debitCreditNoLength,
  contactNumberLength,
};

export default defaults;
