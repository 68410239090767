import React from 'react';
import { connect } from 'react-redux';
import Sidebar from 'react-sidebar';
import {
  QuotationActions,
  PolicyActions,
  UserActions,
  OrganizationActions,
  // UserGroupActions,
} from '@redux/actions';
import { currentUser } from 'utils';
import { ModalMessage } from '@containers';
import MaterialTitlePanel from './MaterialTitlePanel';
import SidebarContent from './SidebarContent';
import NavBar from '../_components/navBarOptions';
import Main from './Main';
import UnauthenticatedSection from '../TeamManagementModule/UnauthenticatedSection/Routes';
import NotificationComponent from './NotificationComponent';
import { ForbiddenModal } from '../_components';

const mql = window.matchMedia('(min-width: 800px)');

const quotationActions = new QuotationActions();
const policyActions = new PolicyActions();
const userActions = new UserActions();
const orgActions = new OrganizationActions();
// const userGrpActions = new UserGroupActions();

interface Props {
  resetQuotationList: () => void,
  resetPolicyList: () => void,
  resetOrgList: () => void,
  resetUserList: () => void,
}

interface State {
  docked: boolean,
  open: boolean,
  transitions: boolean,
  touch: boolean,
  shadow: boolean,
  pullRight: boolean,
  touchHandleWidth: number,
  dragToggleDistance: number,
  active: string,
  navBarTitle: string, 
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      docked: mql.matches,
      open: false,
      transitions: false,
      touch: true,
      shadow: false,
      pullRight: false,
      touchHandleWidth: 20,
      dragToggleDistance: 30,
      active: '',
      navBarTitle: '',
    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
    this.menuButtonClick = this.menuButtonClick.bind(this);
  }

  onSetOpen(open: boolean) {
    this.setState({ open });
  }

  menuButtonClick(ev: MouseEvent) {
    ev.preventDefault();
    this.onSetOpen(!this.state.open);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged() {
    this.setState({ docked: mql.matches, open: false });
  }

  clicked = (id: string, name: string) => {
    this.resetStatesInStore();
    this.setState({
      active: id,
      navBarTitle: name,
    });
  };

  resetStatesInStore = () => {
    const {
      resetQuotationList, resetPolicyList,
      resetOrgList, resetUserList,
    } = this.props;

    resetQuotationList();
    resetPolicyList();
    resetUserList();
    resetOrgList();
  }

  render() {
    const { active } = this.state;
    const sidebar = (
      <SidebarContent
        click={(id: string, name: string) => this.clicked(id, name)}
        active={active}
        menuArray={ currentUser ? currentUser.modules : []}
      />
    );

    const sidebarStyles = {
      root: {
        top: this.state.open ? '0px' : '72px',
      },
      sidebar: {
        overflowY: 'hidden'
      }
    };

    const sidebarProps = {
      sidebar,
      styles: sidebarStyles,
      docked: this.state.docked,
      sidebarClassName: 'custom-sidebar-class shadow-md',
      contentId: 'custom-sidebar-content-id',
      open: this.state.open,
      touch: this.state.touch,
      shadow: false,
      pullRight: this.state.pullRight,
      touchHandleWidth: this.state.touchHandleWidth,
      dragToggleDistance: this.state.dragToggleDistance,
      transitions: this.state.transitions,
      onSetOpen: this.onSetOpen
    };

    return currentUser ? (
        <div>
          <div className='d-flex flex-column'>
            <NavBar
              showSideBar={this.menuButtonClick}
              clickedNav={this.clicked}
              navbarPulledRight={this.state.pullRight}
            />
          </div>
          <Sidebar {...sidebarProps}>
            <MaterialTitlePanel>
              <NotificationComponent />
              <ModalMessage className='forbidden' />
              <Main />
            </MaterialTitlePanel>
          </Sidebar>
          <ForbiddenModal />
        </div>
    ) : (
      <>
        <NotificationComponent />
        <UnauthenticatedSection />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  resetQuotationList: () => dispatch(quotationActions.resetList()),
  resetPolicyList: () => dispatch(policyActions.resetList()),
  resetOrgList: () => dispatch(orgActions.resetList()),
  resetUserList: () => dispatch(userActions.resetList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
