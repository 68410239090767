import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
} from 'reactstrap';
import BaseComponent from '@xc-core/components/baseComponent';
import ButtonCircular from '@xc-core/components/core/buttonCircular';

interface IState { editMode: boolean, submitting: boolean }
interface IProps { validator: any, resetPaymentDetailsForm: any, notification: any
  handleSubmit: any, item: any, collections: any, handleInputChange: any}

export default class PaymentCard extends BaseComponent<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      editMode: false,
      submitting: false,
    };
  }

  componentWillReceiveProps(newProps: any) {
    const { editMode, submitting } = this.state;
    if (editMode && submitting) {
      this.setState({ editMode: false, submitting: false });
    }
  }

  toggleEditMode = () => {
    const { validator } = this.props;
    validator.hideMessages();

    this.setState((prevState) => ({
      editMode: !prevState.editMode,
      submitting: false,
    }));
  }

  cancel = () => {
    const { resetPaymentDetailsForm } = this.props;
    resetPaymentDetailsForm();
    this.toggleEditMode();
  }

  handleSubmit = (e: any) => {
    const { validator, handleSubmit } = this.props;
    this.setState({ submitting: true });
    if (validator.allValid()) {
      handleSubmit(e);
    } else validator.showMessages();
  };

  render() {
    const {
      item, collections,
      handleInputChange,
    } = this.props;
    const { editMode } = this.state;
    return (
      <div className='sidebarCard'>
        <div>
          <Card className='mt-4 shadow-md border-0' style={{ borderRadius: '10px' }}>
            <CardBody className='px-3'>
              <div className='pt-2 text-primary'>
                <Row>
                  <Col sm={9} className='payment-title-qc font-size-normal text-secondary mt-2 mb-2'>
                    Payment Details
                  </Col>
                  <Col sm={3} className={editMode ? 'd-none' : 'text-right mt-2 mb-2'}>
                    <ButtonCircular
                      className='payment-button'
                      id='payment'
                      name='payment'
                      icon='pencil'
                      handler={this.toggleEditMode}
                    />
                  </Col>
                </Row>
              </div>
              <hr className='my-2' />
              <div className='pt-2'>
                <Row>
                  <Col sm={6}>Mode of Payment</Col>
                  <Col className='text-right summary-label-3' sm={6}>
                    { editMode ? (

                      <Input
                        type='select'
                        id='Payment Mode'
                        name='Payment Mode'
                        value={item ? item['Payment Mode'] : '-'}
                        onChange={handleInputChange}
                      >
                        <option disabled value=''>Select Option</option>
                        {
                          collections.paymentMethod.map((pm: any) => (
                            <option key={pm.id}>{pm.code}</option>
                          ))
                        }
                      </Input>
                    ) : null }
                    { editMode ? null : (item ? item['Payment Mode'] : '-') }
                    {/* {editMode && validator.message('Mode of Payment', item['Payment Mode'], 'required')} */}
                  </Col>
                  <Col className='pt-3' sm={6}>Receipt No.</Col>
                  <Col className='text-right pt-3 summary-label-3' sm={6}>
                    { editMode ? (
                      <Input
                        id='Receipt No'
                        name='Receipt No'
                        value={item['Receipt No']}
                        onChange={handleInputChange}
                      />
                    ) : null }
                    { editMode ? null : (item ? item['Receipt No'] : '-') }
                    {/* {editMode && validator.message('Receipt No', item['Receipt No'], 'required')} */}
                  </Col>

                  <Col className='py-3' sm={6}>Payment Received By</Col>
                  <Col className='text-right py-3 summary-label-3' sm={6}>
                    { editMode ? (
                      <Input
                        type='select'
                        id='Payment Received By'
                        name='Payment Received By'
                        value={item['Payment Received By']}
                        onChange={handleInputChange}
                      >
                        <option disabled value=''>Select Option</option>
                        {
                          collections.currentOuUsers.map((pm: any) => {
                            return (<option key={pm.id}>{pm.name}</option>);
                          })
                        }
                      </Input>
                    ) : null }
                    { editMode ? null : (item ? item['Payment Received By'] : '-') }
                    {/* {editMode && validator.message('Payment Received By', item['Payment Received By'], 'required')} */}
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <div className={editMode ? 'float-right' : 'd-none'}>
            <Button
              color='info'
              className='my-4 customInfobtn mr-2'
              onClick={this.cancel}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              className='my-4'
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
