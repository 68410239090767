const reissueable = (policy:any) => {
    if (!policy) return false;
    return ['not in effect', 'in effect'].includes(String(policy.status).toLowerCase())
        && ['new', 'endorsement'].includes(String(policy.policyState).toLowerCase());
}

const cancelable = (policy:any, additionalStatuses = [], additionalStates = []) => {
    if (!policy) return false;

    const statuses = ['not in effect', 'in effect'].concat(additionalStatuses.map((s:string) => s.toLowerCase()));
    const states = ['new', 'endorsement'].concat(additionalStates.map((s:string) => s.toLowerCase()));

    return statuses.includes(String(policy.status).toLowerCase())
        && states.includes(String(policy.policyState).toLowerCase());
}

const downloadable = (policy:any) => {
    if (!policy) return false;
    return !(['cancelled'].includes(String(policy.status).toLowerCase())
        && ['cancellation'].includes(String(policy.policyState).toLowerCase()));
}

const endorseable = (policy:any, additionalStatuses = [], additionalStates = []) => {
    if (!policy) return false;

    const statuses = ['not in effect', 'in effect'].concat(additionalStatuses.map((s:string) => s.toLowerCase()));
    const states = ['new', 'endorsement'].concat(additionalStates.map((s:string) => s.toLowerCase()));

    return statuses.includes(String(policy.status).toLowerCase())
        && states.includes(String(policy.policyState).toLowerCase());
}

const handleBackToListing = (history:any) => {
    history.push(`/policies`);
}

export {
    downloadable,
    endorseable,
    handleBackToListing,
    reissueable,
    cancelable
}