import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import {SidebarCard} from "../../../@xc-core/components";
import {modelId} from "../../../Products/shared/utils/modelUtil";
import lib from "../../../utils/lib";

const BeneficiaryDetails =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            initValues,
            sidebar,
            isKhmer,
            productIcon,
            db,
        },
    } = props;
    const [autoFilled, setAutoFilled] = useState<'Yes' | 'No'>('No')
    const isBelongToCompany = item.properties?.['Main Insured']?.['Belongs to Company'] === 'Yes'
    const basicContactKey =["Name","VATIN","Business Occupation","ID Type",'ID Number']
    const insuredDetailModel:IField[]= isBelongToCompany ? model.companyBeneficiaryFields.fields :model.individualBeneficiaryFields.fields

    useEffect(()=>{
        if(!item.properties?.beneficiaries){
            initValues([
                {
                    id:'properties.beneficiaries',
                    value:{}
                }
            ])
        }
    },[])

    const getPartOfTheModel=(modelType:'basicInfo'|'contactDetail')=>{
        return insuredDetailModel.filter((el)=>{
            const val = basicContactKey.some((el2)=>
                modelId('properties.beneficiaries' ,`${isBelongToCompany ? 'Company' : 'Individual'} ${el2}`) === el.id);
            return modelType === "basicInfo" ? val : !val
        }).map((el)=>{
            return {
                ...el,
                disabled: item?.properties?.beneficiaries?.['Same to insured details'] === 'Yes' ? ()=>true : el.disabled
            }
        });
    }

    const autoFilledHandler = (pair: IPair) => {
        setAutoFilled(pair.value)
        const additionalField = !isBelongToCompany ? ['ID Type' , 'ID Number'] : ['VATIN']
        onChange('onChange', {
            pairs: [
                {
                    id: pair.id,
                    value:pair.value ? "Yes" : "No"
                },
                ...[...model.fields, ...additionalField].reduce((prevVal: IPair[], currentVal: string) => {
                    const currentKey= `${isBelongToCompany ? 'Company' : 'Individual'} ${currentVal}`;
                    return [
                        ...prevVal,
                        {
                            id: `properties.beneficiaries.${currentKey}`,
                            value: pair.value ? item.properties['Main Insured'][currentKey] : ""
                        }
                    ]
                }, [])
            ]
        })
    }

    return (
        <Row>
            <Col md='8'>
                <h5 className='sub-title'>{lib.withKhmerText(model.title,db)}</h5>
                <RenderFields
                    onChange={(pairs: IPair[]) => autoFilledHandler(pairs[0])}
                    collections={collections}
                    item={item}
                    validationErrors={validationErrors}
                    fields={model.autoFilledCheckbox.fields}
                />
                <Col className={'pl-0'} md={12}>
                    <p className='custom-text-primary'>{lib.withKhmerText(isBelongToCompany ? 'Company Details' : 'Individual Details',db)}</p>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={getPartOfTheModel('basicInfo')}
                    />
                </Col>
                <Col className={'pl-0'} md={12}>
                    <p className='custom-text-primary'>{lib.withKhmerText("Contact Details",db)}</p>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={getPartOfTheModel('contactDetail')}
                    />
                </Col>
            </Col>
            <Col md='4'>
                <SidebarCard.Quotation
                    customIconProduct={productIcon}
                    item={item}
                    model={sidebar}
                    ext={isKhmer}
                />
            </Col>
        </Row>
    )
}

export default BeneficiaryDetails;