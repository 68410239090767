import React , {useState} from 'react';
import {useDispatch} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import PaymentForm from "../../@products/AutoInsurance/components/PaymentForm";
import { isDatePassed } from 'utils';
import { showForbiddenMessage } from '_actions';
import {checkPermission} from '_services'

const PrevButton = (props:any) => {
    const { quotation, editQuotation } = props;
    return (
        <button
            className={`btn mx-2 px-4 btn-light`}
            onClick={() => editQuotation(quotation)}
        >
            Previous
        </button>
    );
};

const RetryButton = (props:any) => {
    const { retryQuotation } = props;

    return (
        <Button
            className={`btn mx-2 px-5 btn-primary`}
            onClick={() => retryQuotation()}
        >
            Retry
        </Button>
    );
};

const PaymentFailed =(props:any)=>{
    const { quotation , history } = props;
    const [redirectToGateway, setRedirectToGateway] = useState<boolean>(false);
    const dispatch= useDispatch()
    const retryQuotation = async () => {
        setRedirectToGateway(true)
    }

    const showForbiddenMessageHandler=(message?:string)=>{
        dispatch(showForbiddenMessage(message))
    }

    const editQuotation = async (quotation:any) => {
        const startDate = quotation.properties['Start Date'];
        if (isDatePassed(startDate)) {
            showForbiddenMessageHandler('This quotation is outdated and cannot be edited.')
        } else {
            const {status} = await checkPermission('Edit Quotation') as {status:string};
            if (status === 'permitted') {
                const urlData = {
                    pathname: `/editQuotation/${quotation.productCode}/${quotation.id}`,
                    state: 'reset',
                };
                history.push(urlData);
            } else showForbiddenMessageHandler();
        }
    }

    if (redirectToGateway) return <PaymentForm item={quotation}/>
    return (
        <div className='container-fluid px-0'>
            <div className='row d-flex justify-content-center my_50' id='quotation-detail'>
                <Col sm={11} className='quotation-detail-wrapper'>
                    <Row>
                        <Col sm={12}>
                            <div className="my-5 pb-3 text-dark border-bottom bg-white text-center">
                                <h3 className="payment-failed-title">Payment Failed</h3>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="mb-3 text-center">The payment could not be processed. Please try again.</div>
                            <div className="text-center">
                                <PrevButton quotation={quotation} editQuotation={editQuotation} />
                                <RetryButton quotation={quotation} retryQuotation={retryQuotation} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </div>
    )
}

export default PaymentFailed;