const db: ILanguage = {
  "Basic Details - Step 1 of 6": "ព័ត៌មានលម្អិតបឋម - ជំហ៊ានទី១នៃ៦",
  "Please enter the following details": "សូមផ្ដល់ព័ត៌មានលម្អិតដូចខាងក្រោម",
  "Product Type": "ប្រភេទផលិតផល",
  "Coverage Type": "ប្រភេទហានិភ័យដែលត្រូវបានធានារ៉ាប់រង",
  "Cubic Capacity": "ទំហំស៊ីឡាំង",
  "Enter Cubic Capacity": "សូមបំពេញទំហំស៊ីឡាំង",
  "Sum Insured": "ទឹកប្រាក់ធានា",
  "Start Date":"ថ្ងៃចាប់ផ្ដេីមធានា",
  "End Date":"ថ្ងៃផុតកំណត់ធានា",
  "Select Date":"",
  "Enter Sum Insured": "សូមបំពេញចំនួនទឹកប្រាក់ធានា",
  Next: "ទៅមុខ",
  "Insured Details - Step 2 of 6":
    "ព័ត៌មានលម្អិតរបស់អ្នកត្រូវបានធានារ៉ាប់រង - ជំហ៊ានទី២ នៃ៦",
  "Please enter customer and property details":
    "សូមបញ្ចូលព័ត៌មានលម្អិតរបស់អតិថិជន និងទ្រព្យដែលត្រូវបានធានារ៉ាប់រង",
  "Does this property belongs to a Company?":
    "តើទ្រព្យសម្បត្តិនេះគឺជារបស់ក្រុមហ៊ុនមែនទេ?",
  Yes: "មែនហើយ",
  "No, this is a Private property": "មិនមែនទេ នេះគឺជាទ្រព្យឯកជន",
  "Individual Details": "ព័ត៌មានលម្អិតរបស់រូបវ័ន្ដបុគ្គល",
  "Full Name": "ឈ្មោះពេញ",
  "Enter Full Name": "សូមបំពេញឈ្មោះពេញ",
  "ID Type": "ប្រភេទអត្តសញ្ញាណប័ណ្ណ",
  "Enter ID Type": "ជ្រើសរើសប្រភេទអត្តសញ្ញាណប័ណ្ណ",
  "ID Number": "លេខអត្តសញ្ញាណប័ណ្ណ",
  "Enter ID number": "សូមបំពេញលេខអត្តសញ្ញាណប័ណ្ណ",
  "Business Occupation": "មុខរបរអាជីវកម្ម",
  "Enter business occupation": "សូមបំពេញមុខរបរអាជីវកម្ម",
  "Company Details": "ព័ត៌មានលម្អិតរបស់ក្រុមហ៊ុន",
  "Company Name": "ឈ្មោះក្រុមហ៊ុន",
  "Enter Company Name": "សូមបំពេញឈ្មោះក្រុមហ៊ុន",
  "VAT TIN": "លេខអ.ត.ប",
  "Enter VAT TIN": "សូមបំពេញលេខអ.ត.ប",
  "Contact Details": "ព័ត៌មានទំនាក់ទំនង",
  Address: "អាសយដ្ឋាន",
  "Enter address": "សូមបំពេញអាសយដ្ឋាន",
  Country: "ប្រទេស",
  "Enter country": "សូមបំពេញឈ្មោះប្រទេស",
  "State/Province/Region": "ក្រុង/ខេត្ត/តំបន់",
  "City/Province": "ខេត្តក្រុង",
  "Enter state": "សូមបំពេញឈ្មោះក្រុង",
  "Select Option City/Province": "សូមជ្រើសរើសខេត្តក្រុង",
  Postcode: "លេខកូដតំបន់",
  "Enter postcode": "សូមបំពេញលេខកូដតំបន់",
  City: "ក្រុង",
  "State/Region": "តំបន់",
  "Enter State/Region": "សូមបំពេញតំបន់",
  "Enter city": "សូមបំពេញឈ្មោះក្រុង",
  "Email Address": "អាសយដ្ឋានអ៊ីម៉ែល",
  "Enter email address": "សូមបំពេញអាសយដ្ឋានអ៊ីម៉ែល",
  "Mobile Number": "លេខទូរស័ព្ទ",
  "Enter mobile number": "សូមបំពេញលេខទូរស័ព្ទ",
  "Property Details": "ព័ត៌មានអំពីវត្ថុដែលត្រូវបានធានា",
  "Registration No.": "លេខចុះបញ្ជី",
  "Enter Registration No.": "សូមបំពេញលេខចុះបញ្ជី",
  "Chassis No./Engine No.": "លេខតួ/លេខម៉ាស៊ីន",
  "Enter Chassis No./Engine No.": "សូមបំពេញលេខតួ/លេខម៉ាស៊ីន",
  "Year of Manufacture": "ឆ្នាំផលិត",
  "Select Option year of manufacture": "សូមបំពេញឆ្នាំផលិត",
  "Make/Model": "ម៉ាក/មូដែល",
  'Make':'ម៉ាក',
  'Model':'ម៉ូឌែល',
  'Select Option Make':'សូមជ្រេីសរេីសម៉ាក',
  'Select Option Model':'សូមជ្រេីសរេីសម៉ូឌែល',
  "Enter make/model": "សូមបំពេញម៉ាក/មូដែល",
  "Number of Seats": "ចំនួនកៅអី",
  "Enter number of seats": "សូមបំពេញចំនួនកៅអី",
  Previous: "ត្រលប់ក្រោយ",
  "Total Price": "តម្លៃសរុប",
  "Price Breakdown": "តម្លៃលម្អិត",
  "Gross Premium": "បុព្វលាភដុល",
  "Admin Fee": "សេវារដ្ឋបាល",
  "Other Details": "ព័ត៌មានលម្អិតផ្សេងទៀត",
  "Select Option Country":'សូមបំពេញឈ្មោះប្រទេស',
  "Select Option Product":'ជ្រើសរើសប្រភេទផលិតផល',
  "Select Option Coverage Type":'ជ្រើសរើសប្រភេទហានិភ័យ',
  "Select Option IDType": 'ជ្រើសរើសប្រភេទអត្តសញ្ញាណប័ណ្ណ',
  "Mode of Payment": "មធ្យោបាយនៃការទូទាត់",
  "Receipt No": "លេខវិក្កយប័ត្រ",
  "Payment Received By": "ការទូទាត់ទទួលបានដោយ",
  "Select Option Payment" : 'ជ្រើសរើសមធ្យោបាយនៃការទូទាត់',
  "Select Option Received By": 'ជ្រើសរើសជម្រើស',
  "Enter receipt no": "សូមបំពេញលេខវិក្កយប័ត្រ",
  "Please enter beneficiary details":"សូមបំពេញព័ត៌មានលម្អិតរបស់អ្នកទទួលផល",
  "Beneficiaries - Step 3 of 6":"ព័ត៌មានលម្អិតរបស់អ្នកទទួលផល - ជំហ៊ានទី៣នៃ៦",
  "Summary - Step 4 of 6": "សង្ខេប - ជំហ៊ាន ទី៤នៃ៦",
  "Payment Details - Step 5 of 6":"ព័ត៌មានលម្អិតអំពីការទូទាត់ - ជំហ៊ានទី៥នៃ៦",
  "Fill in payment details (optional)": "សូមបំពេញព័ត៌មាននៃការទូទាត់ (ប្រសិនបើមាន)",
  "The Certificate of Insurance has been generated and sent to the Main Insured’s email address.": "វិញ្ញាបនប័ត្រធានារ៉ាប់រងត្រូវបានបង្កើតនិងបញ្ជូនទៅអាសយដ្ឋានអ៊ីមែលរបស់អ្នកត្រូវបានធានារ៉ាប់រង។",
};

export { db };
