export const quotationConstants ={
  GET_QUOTATIONS_LIST: "GET_QUOTATIONS_LIST",
  QUOTATIONS_APPLY_FILTER: "QUOTATIONS_APPLY_FILTER",
  GET_QUOTATION_DETAIL: "GET_QUOTATION_DETAIL",
  SET_CSV_BLOB: "SET_QUO_CSV_BLOB",
  RESET_CSV_BLOB: "RESET_QUO_CSV_BLOB",
  SET_FORBIDDEN_QUOTATIONS: "SET_FORBIDDEN_QUOTATIONS",
  CLEAR_QUOTATION_DETAIL: "CLEAR_QUOTATION_DETAIL",
  QUOTATION_RESET_FILTER: "QUOTATION_RESET_FILTER",
  CHANGE_CURRENT_QUOTATION_PRODUCT: "CHANGE_CURRENT_QUOTATION_PRODUCT",
  GET_QUOTATION_PRODUCTS: "GET_QUOTATION_PRODUCTS",
  CLEAR_QUOTATION_LIST: "CLEAR_QUOTATION_LIST",
  RESET_KEEP_FILTERS: "RESET_KEEP_FILTERS",
  SET_APPLY_TODAY_QUOTATION_FILTER: "SET_APPLY_TODAY_QUOTATION_FILTER",
  RESET_TODAY_QUOTATION_FILTER: "RESET_TODAY_QUOTATION_FILTER",
  QUOTATION_APPLY_TODAY_FILTER: "QUOTATION_APPLY_TODAY_FILTER",
  SET_CURRENT_QUOTATION_PRODUCT_TO_ALL: "SET_CURRENT_QUOTATION_PRODUCT_TO_ALL",
  QUOTATION_UPDATE_COLLECTIONS: 'QUOTATION_UPDATE_COLLECTIONS',
};
