import React from 'react';
import { objLib } from '@xc-core/lib';

interface IProps { model: [], item: object }
const Individual = (props:IProps) => {
  const { model, item } = props;
  return (
    <ul className='list-group'>
      {
        model.map((m: any) => (
          <li key={m.id} className='list-group-item d-flex justify-content-between border-0 px-0 py-1 grey-bg summary-label'>
            {m.name}
            <span className='col-5 p-0 wordBreak summary-label-2'>{objLib.getValueFromPath(item, m.id)}</span>
          </li>
        ))
      }
    </ul>
  );
};

export default Individual;
