import React from "react";
import { Col, Row } from "reactstrap";
import { CommonInputTypes } from "_components";
import {utils} from 'utils';
import { sidebarModel } from 'models';
import { SidebarCard } from '@xc-core/components';

const BeneficiaryDetails = (props) => {
    const {
      fields,
      collections,
      quotation,
      handleIdTypeChange,
      handleIdNumberChange,
      handleNumberFormatFields
    } = props;
  return(
      <Row>
          <Col md='8'>
          <h5 className="sub-title">Please enter beneficiary details</h5>
          <p className="custom-text-primary">Main Insured’s Beneficiary</p>
          <Row>
          <Col sm="6">
          <CommonInputTypes
              idName="name"
              id={1}
              type="input"
              title="Full Name"
              group={false}
              placeholder="Enter full name"
              onChange = {(e) => props.handleChange("beneficiaryDetails", e)}
              value={fields.beneficiaryDetails.name}
              validator={props.validator}
              validationRules="customRequired|alfalah_full_name_valid_characters"
              autoFocus={true}
          />
          </Col>
          <Col sm="6">
          <CommonInputTypes
              idName="relationship"
              type="select"
              title="Relationship"
              id={2}
              group={false}
              option={collections.relations}
              onChange = {(e) => props.handleChange("beneficiaryDetails",e)}
              value={fields.beneficiaryDetails.relationship}
              validator={props.validator}
              validationRules="customRequired"
              autoFocus={true}
          />
      </Col>
      <Col sm="6">
            <CommonInputTypes
                idName="idType"
                type="select"
                title="ID Type"
                id={3}
                group={false}
                option={collections.idTypes}
                onChange = {(e) => handleIdTypeChange("beneficiaryDetails",e)}
                value={fields.beneficiaryDetails.idType}
                validator={props.validator}
                validationRules="customRequired"
                autoFocus={true}
            />
            </Col>
            <Col sm="6">
            <CommonInputTypes
                idName="idNumber"
                type="input"
                title="ID Number"
                id={4}
                placeholder="Enter ID Number"
                onChange = {(e) => handleIdNumberChange("beneficiaryDetails",e)}
                value={fields.beneficiaryDetails.idNumber}
                validator={props.validator}
                validationRules={fields.beneficiaryDetails.idType === "CNIC"
                ? `customRequired|numbersOnly|min:${utils.lengths.CNIC}|max:${utils.lengths.CNIC}` : "customRequired"}
                autoFocus={true}
            />
            </Col>
            <Col sm="6">
              <CommonInputTypes
                id={5}
                idName="email"
                type="email"
                title="Email Address"
                group={false}
                placeholder="Enter email address"
                onChange={(e) => props.handleChange("beneficiaryDetails", e)}
                validator={props.validator}
                validationRules="customRequired|email"
                value={fields.beneficiaryDetails.email}
                autoFocus={true}
              />
            </Col>
            <Col sm="6">
            <CommonInputTypes
                idName="contactNumber"
                type="input"
                title="Contact Number"
                id={6}
                placeholder="Contact Number"
                onChange = {(e) => handleNumberFormatFields("beneficiaryDetails",e)}
                value={fields.beneficiaryDetails.contactNumber}
                validator={props.validator}
                validationRules="customRequired"
                autoFocus={true}
            />
            </Col>
          </Row>
          </Col>
          <Col md='4'>
          <SidebarCard.Quotation
            item={quotation}
            model={sidebarModel.quotation.walletAlfalah}
          />
  		</Col>
      </Row>
  );
};

export default BeneficiaryDetails;
