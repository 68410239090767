import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { fieldLib, objLib } from '@xc-core/lib';
import Checkbox from "@material-ui/core/Checkbox";

const BeneficiaryDetail = (props: ILoadableComponentProps) => {

  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
      initValues
    },
  } = props;

  useEffect(() => {
    const beneficiary = objLib.getValueWithLodash(item, model.fields.beneficiary.id) || {};
    const pairs: IPair[] = [{ id: model.fields.beneficiary.id, value: beneficiary }];
    
    initValues(pairs);
  }, []);

  function handleSameAsMainCheck (index: number, e: any) {
    updateBeneficiaryDetails(index, e);
  }

  function updateBeneficiaryDetails(index: number, e: any) {
    const mainBeneficiary = item.properties['Main Applicant'].Beneficiary;

    const pairs: IPair[] = [{
      id: `properties.Other Insured[${index}].Beneficiary['Same as Main']`,
      value: e.target.checked
    }];

    for (const [key, value] of Object.entries(mainBeneficiary)) {
      pairs.push({
        "id": `properties.Other Insured[${index}].Beneficiary.${key}`,
        "value": e.target.checked ? value : undefined
      });
    }

    onChange('onChange', {pairs: pairs})
  }

  return (
    <Row>
      <Col md='8'>
        <h5 className='sub-title'>Please enter the beneficiary(s) details</h5>
        <p className='custom-text-primary'>Main Insured's Beneficiary</p>

        {item && item.properties &&
          <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={collections}
          item={item}
          validationErrors={validationErrors}
          fields={model.beneficiaryDetail.fields }
        />
        }

        {item && item.properties && item.properties['Other Insured'] &&
         item.properties['Other Insured'].map((value:IObject,index:number)=>{
          const SameAsMain = item.properties['Other Insured'][index].Beneficiary && item.properties['Other Insured'][index].Beneficiary["Same as Main"];
            return <div key={index}>
              <br/>
              <hr/>
              <p className='custom-text-primary'>{value['Full Name']}'s Beneficiary</p>
              <span className="d-flex align-items-center font-size-small mb-3">
                <Checkbox
                  checked={SameAsMain}
                  disabled={
                    !item.properties['Main Applicant'].Beneficiary || !item.properties['Main Applicant'].Beneficiary["Full Name"]
                  }
                  onChange={(e) => handleSameAsMainCheck(index, e)}
                  id="Same as Main"
                  className="p-0"
                  color="primary"
                />
                Same as main insured’s beneficiary
              </span>
              <RenderFields
                    onChange={(pairs: IPair[]) => onChange('onChange', { pairs : pairs})}
                    disableAll={SameAsMain}
                    collections={collections}
                    item={item}
                    validationErrors={validationErrors}
                    // fields={model.otherDetail.fields}
                    fields={fieldLib.addIndexToFields(model.otherBeneficiaryDetail.fields,'Other Insured',index)}
                  />
            </div>
        })}

      </Col>
      <Col md='4'>
        <SidebarCard.Quotation
          item={item}
          model={sidebarModel.quotation.hgiTravel}
        />
      </Col>
    </Row>
  );
};

export default BeneficiaryDetail;
