import React, {Component} from 'react';
import {UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu} from 'reactstrap';
import logo from "../images/CoverPlusLogo.svg";
import {logout} from "../_services";


class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      rowReversed: false
    }
  }

  componentDidMount() {
    this.setState({
      currentUser: JSON.parse(localStorage.getItem('user'))
    })
  }

  logout = async () => {
    await logout();
    localStorage.clear();
    window.location.reload();
  };

  render() {
    return (
      <nav className={
        `navbar navbar-expand-lg navbar-light 
          border-bottom d-flex 
          justify-content-lg-between py-0
          ${this.state.rowReversed ? "flex-row-reverse" : "flex-row"}
          `
      }
      >
        <button
          onClick={this.props.showSideBar}
          className="navbar-toggler hideOnLarge"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <span className="border-right">
          <img src={logo} className="hideOnSmall logo" alt="Logo"/>
        </span>

        <UncontrolledDropdown className="customMdropdown">
          <DropdownToggle caret color="transparent" className="text-secondary border-0">
            <span className="icon-user mr-2 p-1 navbar-icon-user border user-logo"/>
            {this.state.currentUser ? this.state.currentUser.name : ''}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              // onClick={() => this.props.clickedNav()}
              onClick={() => window.location.href = "#/profile"}
              className="border-bottom"
            >
              <span>My Account</span>
              {/* <button to="/profile">My Account</button> */}
            </DropdownItem>
            <DropdownItem onClick={() => this.logout()}>
              <span color="danger" className="text-danger">Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </nav>
    )
  }
}

export default NavBar