const auditActionTypes = {
  LIST_FAILED: 'AUDIT_LIST_FAILED',
  LIST_LOADING: 'AUDIT_LIST_LOADING',
  LIST_SET_FORBIDDEN: 'AUDIT_LIST_SET_FORBIDDEN',
  SET_CURRENT_AUDIT_INFO: 'SET_CURRENT_AUDIT_INFO',

  LIST_GET: 'AUDIT_LIST_GET',
  DETAIL_GET: 'AUDIT_DETAIL_GET',
  LIST_SET_NOT_FOUND: 'AUDIT_LIST_SET_NOT_FOUND',
  LIST_RESET: 'AUDIT_LIST_RESET',
  LIST_CHANGE_SEARCH_KEY: 'AUDIT_LIST_CHANGE_SEARCH_KEY',
  LIST_CHANGE_PAGE: 'AUDIT_LIST_CHANGE_PAGE',
  LIST_CHANGE_PAGE_SIZE: 'AUDIT_LIST_CHANGE_PAGE_SIZE',
  LIST_CHANGE_FILTERS: 'AUDIT_LIST_CHANGE_FILTERS',
  LIST_RESET_FILTERS: 'AUDIT_LIST_RESET_FILTERS',
  LIST_APPLY_FILTERS: 'AUDIT_LIST_APPLY_FILTERS',
  LIST_SET_HAS_RECORDS_INITIALLY: 'AUDIT_LIST_SET_HAS_RECORDS_INITIALLY',

  ENTITY_LOADING: 'AUDIT_LOG_ENTITY_LOADING',
  ENTITY_FAILED: 'AUDIT_LOG_ENTITY_FAILED',
  ENTITY_GET: 'AUDIT_LOG_ENTITY_GET',
  ENTITY_SET_FORBIDDEN: 'AUDIT_LOG_ENTITY_SET_FORBIDDEN',
  // ENTITY_UPDATE: 'USER_ENTITY_UPDATE',
  // ENTITY_CREATE: 'USER_ENTITY_CREATE',



  CSV_EXPORT_LOADING: 'AUDIT_CSV_EXPORT_LOADING',
  CSV_EXPORT_FAILED: 'AUDIT_CSV_EXPORT_FAILED',
  CSV_EXPORT_SET_FORBIDDEN: 'AUDIT_CSV_EXPORT_SET_FORBIDDEN',
  CSV_EXPORT_GET: 'AUDIT_CSV_EXPORT_GET',

};

export default auditActionTypes;
