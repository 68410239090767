import React, { Fragment } from "react";
import { Col } from "reactstrap";
import {CommonInputTypes} from "../../../../_components/CommonInputTypes"

class PersonalInfo extends React.Component {
  componentDidMount() {
    const { props } = this;
    const { curentThis, collections: { countries } } = props;
    /**
     * set the country automatically if there is only one country
     */
    if (countries && countries.length === 1) {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          properties: {...curentThis.state.properties, Country: countries[0].name}
        })
      })
    }
  }

  handleContactNumberChange = (e) => {
    const {value} = e.target;
    if (Number.isInteger(Number(value))) this.props.handleInputChange(e);
  };

  render() {
    const { updatedVal,collections } = this.props;
    return (
      <Fragment>
        <Col sm="12">
          <CommonInputTypes
            idName={"Address"}
            type={"input"}
            value={updatedVal["Address"] || ""}
            onChange={this.props.handleInputChange}
            title={"Address"}
            id={1}
            group={false}
            placeholder={"Enter address"}
            validator={this.props.validator}
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"Country"}
            type="select"
            value={updatedVal["Country"]}
            onChange={this.props.handleInputChange}
            title={"Country"}
            id={1}
            group={false}
            option={collections.countries}
            validator={this.props.validator}
            disabled={collections.countries.length <= 1}
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"State"}
            type="select"
            value={updatedVal["State"]}
            onChange={this.props.handleInputChange}
            title={"State/Province/Region"}
            id={1}
            group={false}
            option={collections.states ? collections.states : []}
            validator={this.props.validator}
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"Postcode"}
            type="input"
            value={updatedVal["Postcode"] || ""}
            onChange={this.props.handleInputChange}
            title={"Postcode"}
            id={1}
            group={false}
            validator={this.props.validator}
            validationRules="numbers_only"
            placeholder={"Enter postcode"}
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"City"}
            type="input"
            value={updatedVal["City"] || ""}
            onChange={this.props.handleInputChange}
            title={"City/Town"}
            id={1}
            group={false}
            validator={this.props.validator}
            placeholder={"Enter City/Town"}
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"Email Address"}
            type="email"
            value={updatedVal["Email Address"] || ""}
            onChange={this.props.handleInputChange}
            title={"Email Address"}
            id={1}
            group={false}
            validator={this.props.validator}
            validationRules="email"
            placeholder={"Enter email address"}
          />
        </Col>  
        <Col sm="6">
          <CommonInputTypes
            idName={"Contact Number"}
            type="input"
            value={updatedVal["Contact Number"] || ""}
            onChange={(e) => this.handleContactNumberChange(e)}
            title={"Contact Number"}
            id={1}
            group={false}
            validator={this.props.validator}
            validationRules="integer"
            placeholder={"Enter contact number"}
          />
        </Col>  
      </Fragment>
    );
  }
}

export default PersonalInfo;
