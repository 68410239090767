import React, {Fragment} from "react";
import {Col, Label} from "reactstrap";
import {CommonInputTypes} from "../../../../_components/CommonInputTypes"
import { CONFIG_DATE_MOMENT_FORMAT, calculateDobMomentFromKTP, calculateGenderFromKTP } from "utils";
import moment from "moment";

class BasicInfoForm extends React.Component {
  handleKTPInput = (e) => {
    const {target} = e;
    const {curentThis} = this.props;

    if (String(target.value).length === 16) {
      this.handleGenderDOBAutofill(target.value);
    }
    curentThis.handleInputChange(e);
  };

  handleOtherInsuredKTPInput = (id, isBeneficiary, e) => {
    const {target} = e;
    const {curentThis} = this.props;
    // const {OtherInsured} = this.props.curentThis.state;
    const curID = id;

    if (isBeneficiary === false && String(target.value).length === 16) {
      this.handleGenderDOBAutofill(target.value, curID);
    }
    if (!isBeneficiary) {
      curentThis.updateOtherInsured(curID, e);
    } else {
      curentThis.updateBeneficiaryDetails(curID, e);
    }
  };

  handleGenderDOBAutofill(val, id) {
    const {curentThis} = this.props;
    const curID = id;
    const birthGender = calculateGenderFromKTP(val);
    const dobM = calculateDobMomentFromKTP(val);

    if (dobM.isValid()) {
      if (curID === undefined) {
        curentThis.forceUpdate(() => {
          curentThis.setState({
            properties:
              {
                ...curentThis.state.properties,
                "DOB": dobM.format(CONFIG_DATE_MOMENT_FORMAT),
                "Gender": birthGender,
              }
          });
        });
      } else {
        curentThis.state.OtherInsured.map((data, index) => {
          if (curID === data.id) {
            let updatedArr = curentThis.state.OtherInsured.slice();

            updatedArr[index] = {
              ...curentThis.state.OtherInsured[index],
              "ID Number": val,
              "DOB": dobM.format(CONFIG_DATE_MOMENT_FORMAT),
              "Gender": birthGender
            };

            curentThis.forceUpdate(() => {
              curentThis.setState({OtherInsured: updatedArr})
            });
          }
        });
      }
    } else {
      if (curID === undefined) {
        curentThis.forceUpdate(() => {
          curentThis.setState({
            properties:
              {
                ...curentThis.state.properties,
                "DOB": "",
                "Gender": birthGender
              }
          });
        })
      } else {
        curentThis.state.OtherInsured.map((data, index) => {
          if (curID === data.id) {
            // console.log(curentThis.state.OtherInsured[index]);

            //creates a clone of the state
            let updatedArr = curentThis.state.OtherInsured.slice();

            updatedArr[index] = {
              ...curentThis.state.OtherInsured[index],
              "ID Number": val,
              "DOB": "",
              "Gender": birthGender
            };

            curentThis.forceUpdate(() => {
              curentThis.setState({OtherInsured: updatedArr})
            })
          }
        })
      }
    }
  }

  handleContactNumberChange = (e) => {
    const {value} = e.target;
    if (Number.isInteger(Number(value))) this.props.handleInputChange(e);
  };
  handleDobInputDateChange = (date) => {
    this.props.handleDobInputDateChange(date);
  }

  getAlert() {
    if (this.validator.allValid()) {
      // this.props.curentThis.setState({disabled:false});
      return true;
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      // this.props.curentThis.setState({disabled:true});
      return false;
    }
  }

  handleIdTypeChange = (e, isMain) => {
    const { value } = e.target;
    const {curentThis: {state: {properties}}, handleInputChange} = this.props;
    if(isMain) {
      const currentIdNumber = properties["ID Number"];
      if (value === "KTP" && currentIdNumber && String(currentIdNumber).length === 16 ) {
        this.handleGenderDOBAutofill(currentIdNumber);
      }
    }
    handleInputChange(e)
  };

  getIdNumberMainValidationRules = () => {
    const { updatedVal } = this.props;
    let rules = '';
    // updatedVal["ID Type"] === "KTP" ?
    // `idnumrequired|numbers_only|max:16|min:16|custom_required|validate_ktp_id_number
    // ${ beneficiary ? "|idNumber_dob_inFuture": ""}` :
    // "idnumrequired|alpha_num_passport|custom_required"
    if (updatedVal["ID Type"] === "KTP") {
      rules = 'idnumrequired|numbers_only|max:16|min:16|custom_required|validate_ktp_id_number';
    } else {
      rules = 'idnumrequired|alpha_num_passport|custom_required';
    }
    return rules;
  }

  getIdNumberBeneficiaryValidationRules = () => {
    const { updatedVal } = this.props;
    let rules = '';
    if (!updatedVal["ID Number"]) return rules;

    // updatedVal["ID Type"] === "KTP" ?
    // `idnumrequired|numbers_only|max:16|min:16|custom_required|validate_ktp_id_number
    // ${ beneficiary ? "|idNumber_dob_inFuture": ""}` :
    // "idnumrequired|alpha_num_passport|custom_required"
    if (updatedVal["ID Type"] === "KTP") {
      rules = 'idnumrequired|numbers_only|max:16|min:16|custom_required|validate_ktp_id_number|idNumber_dob_inFuture';
    } else {
      rules = 'idnumrequired|alpha_num_passport|custom_required';
    }
    return rules;
  }

  needValidator = (value) => {
    const { beneficiary } = this.props;
    if (beneficiary) {
      return value || false;
    }
    return true;
  }

  render() {
    const {
      updatedVal, collections, beneficiary, disableAll,
      curentStep, curentID, startDate,
      openToDateOfBirth, maxDateOfBirth,
      minDateOfBirth, index,
      maxAge, minAge,
    } = this.props;
    // const {IDNumberError} = curentThis.state;
    let disable = disableAll ? disableAll : false;

    const isMain = !beneficiary && index === "base";
    return (
      <Fragment>
        <Col sm="6">
          <CommonInputTypes
            idName={"Full Name"}
            idNameIndex={`Full Name-${index}`}
            type={"input"}
            value={updatedVal["Full Name"] || ""}
            onChange={this.props.handleInputChange}
            title={"Full Name"}
            id={1}
            group={false}
            placeholder={"Enter full name"}
            validator={disable || this.needValidator(updatedVal["Full Name"]) === false ? "" : this.props.validator}
            validationRules={`${beneficiary ? `${updatedVal["Full Name"] ? 'full_name_valid_characters' : ''}` : 'custom_required|full_name_valid_characters'}`}
            disabled={disable}
            autoFocus= {this.props.autoFocus}
          />
        </Col>
        {!beneficiary &&
        <Col sm="6">
          <CommonInputTypes
            idName={"Nationality"}
            idNameIndex={`Nationality-${index}`}
            type="select"
            value={updatedVal["Nationality"]}
            onChange={this.props.handleInputChange}
            title={"Nationality"}
            id={1}
            group={false}
            option={collections.nationalities}
            validator={disable ? "" : this.props.validator}
            validationRules="custom_required"
            disabled={disable}
          />
        </Col>
        }
        {beneficiary &&
        <Col sm="6">
          <CommonInputTypes
            idName={"Relationship"}
            idNameIndex={`Relationship-${index}`}
            type="select"
            value={updatedVal["Relationship"]}
            onChange={this.props.handleInputChange}
            title={"Relationship"}
            id={1}
            group={false}
            option={collections.relations}
          />
        </Col>
        }

        <Col sm="6">
          <CommonInputTypes
            idName={"ID Type"}
            idNameIndex={`ID Type-${index}`}
            type="select"
            value={updatedVal["ID Type"] || ""}
            onChange={(e) => this.handleIdTypeChange(e, isMain) }
            title={"ID Type"}
            id={1}
            group={false}
            option={collections.idTypes}
            validator={disable || this.needValidator(updatedVal["ID Type"]) === false ? "" : this.props.validator}
            validationRules={`${beneficiary ? `${updatedVal['ID Type'] ? 'idtype' : ''}` : 'idtype|custom_required'}`}
            disabled={disable}
            curentID={curentID}
          />
        </Col>

        <Col sm="6">
          <CommonInputTypes
            idName="ID Number"
            idNameIndex={`ID Number-${index}`}
            type="input"
            value={updatedVal["ID Number"] || ""}
            onChange={updatedVal["ID Type"] === "KTP" ?
            (isMain ? this.handleKTPInput : this.handleOtherInsuredKTPInput.bind(curentStep, curentID, beneficiary)) :
            this.props.handleInputChange
          }
            title="ID Number"
            id={1}
            group={false}
            placeholder="Enter ID Number"
            // validationRules={
            //   updatedVal["ID Type"] === "KTP" ?
            //     `idnumrequired|numbers_only|max:16|min:16|custom_required|validate_ktp_id_number
            //     ${ beneficiary ? "|idNumber_dob_inFuture": ""}` :
            //     "idnumrequired|alpha_num_passport|custom_required"
            // }
            validationRules = {beneficiary ? this.getIdNumberBeneficiaryValidationRules() : this.getIdNumberMainValidationRules()}
            validator={disable || this.needValidator(updatedVal["ID Number"]) === false ? "" : this.props.validator}
            disabled={updatedVal["ID Type"] === undefined || updatedVal["ID Type"] === "" || disable}
            curentID={curentID}
          />
        </Col>

        {!beneficiary && (
          <Fragment>
            <Col sm="6">
              <Label for="dob">Date of Birth</Label>
              <CommonInputTypes
                idName={"DOB"}
                idNameIndex={`DOB-${index}`}
                type="date"
                validator={this.props.validator}
                disabled={updatedVal["ID Type"] === "KTP" ? true : disable}
                value={updatedVal["DOB"]}
                id={1}
                group={false}
                placeholder="Select Date of Birth"
                className="datePickerBorderLeft"
                onChange={(date) => this.props.onChange(date)}
                maxDate={maxDateOfBirth}
                minDate={minDateOfBirth}
                openToDate={startDate || openToDateOfBirth}
                validationRules="custom_required|validate_age_boundary"
                // validationParams={{maxDateOfBirth, minDateOfBirth}}
                validationParams={{ maxDateOfBirth, minDateOfBirth, minAge, maxAge }}
                // handleInputDateChange = {(date) => this.handleDobInputDateChange(date)} //text input
              />
            </Col>
            <Col sm="6">
              <CommonInputTypes
                idName="Gender"
                idNameIndex={`Gender-${index}`}
                type="select"
                value={updatedVal["Gender"]}
                onChange={this.props.handleInputChange}
                title="Gender"
                id={1}
                group={false}
                option={collections.genders}
                validator={disable ? "" : this.props.validator}
                validationRules="custom_required"
                disabled={updatedVal["ID Type"] === "KTP" ? true : disable}
                placeholder={updatedVal["ID Type"] === "KTP" ? "" : null}
              />
            </Col>
            <Col sm="12">
              <CommonInputTypes
                idName="Place of Birth"
                idNameIndex={`Place of Birth-${index}`}
                type="select"
                value={updatedVal["Place of Birth"]}
                onChange={this.props.handleInputChange}
                title="Place of Birth"
                id={1}
                group={false}
                option={collections.placeOfBirth}
                validator={disable ? "" : this.props.validator}
                validationRules="custom_required"
                disabled={disable}
              />
            </Col>
          </Fragment>
        )}
        {beneficiary && (
          <Fragment>
            <Col sm="6">
              <CommonInputTypes
                idName="Email"
                idNameIndex={`Email-${index}`}
                type="email"
                value={updatedVal["Email"]}
                onChange={this.props.handleInputChange}
                title="Email Address"
                id={1}
                group={false}
                validator={disable || this.needValidator(updatedVal["Email"]) === false ? "" : this.props.validator}
                disabled={disable}
                placeholder="Enter email address"
                validationRules={`${ updatedVal["Email"] ? 'email' : ''}`}
              />
            </Col>
            <Col sm="6">
              <CommonInputTypes
                idName="Contact Number"
                idNameIndex={`Contact Number-${index}`}
                type="input"
                value={updatedVal["Contact Number"]}
                onChange={(e) => this.handleContactNumberChange(e)}
                title="Contact Number"
                id={1}
                group={false}
                validator={disable || this.needValidator(updatedVal["Contact Number"]) === false ? "" : this.props.validator}
                validationRules={`${ updatedVal["Contact Number"] ? 'integer' : ''}`}
                disabled={disable}
                placeholder="Enter contact number"
              />
            </Col>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default BasicInfoForm;
