import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import products from '_config/products';
import { sidebarModel } from 'models';
import stateToProps from '@containers/storeState';
import dispatchToProps from '@containers/dispatches';
import { objLib } from '@xc-core/lib';
import { Loader } from '@xc-core/components/core';
import BaseQuotationDetails from '../base-quotation-detail';
import fields from './fields';
import models from './model';
import util from '../i-med/util';

interface IProps {
  history: any
  quotationManagement: IObject
  setQuotationOccupationList: () => {}
}
const IProtectQuotationDetails = (props: IProps) => {
  const {
    history,
    quotationManagement: { quotation, collections },
    setQuotationOccupationList,
  } = props;

  const [updatedModels, setModels] = useState(models as IObject);

  // console.log('collections', collections);
  // console.log('quotation', quotation);

  useEffect(() => {
    /** only add femaleDisorder question to model when applicant is a female
     */
    if (objLib.isEmpty(quotation) === false) {
      setModels(util.applyQuestionNumber(util.tryAddFemaleDisorderAndNumberOfCigarette(quotation,
        models, fields)));
    }
  }, [quotation]);

  if (objLib.isEmpty(quotation)) return <Loader />;
  return (
    <BaseQuotationDetails
      setOccupationList={setQuotationOccupationList}
      fields={fields}
      models={{ ...updatedModels, sidebar: sidebarModel.quotation.iProtectGbsn }}
      history={history}
      quotation={quotation}
      collections={collections}
      defaultCountry={products.gbsnIProtect.defaultValues.country}
    />
  );
};

export default connect(stateToProps, dispatchToProps)(IProtectQuotationDetails);
