import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from 'reactstrap';
import Notifications from "react-notification-system-redux";

import { quotationActions } from "Products/shared/quotationListing/actions";
import {checkPermission} from "_services";
import {showForbiddenMessage} from "_actions";
import { TitleComponent } from "_components";
import getQuoteGraphic from "../../../../images/bannerGraphicImages/get-quote.png"
import { isDatePassed } from 'utils';
import { sidebarModel, DetailModel } from "models";
import { DetailTitle, SidebarCard, InsuredDetail } from '@xc-core/components';
import { TravelAlfalahConfig } from '@products';
import {adjustAlfalahSideBar} from "../../alfalahUtils";

class QuotationDetail extends React.Component {
  componentWillMount() {
    const backPath = this.props.history.location.pathname.split('/');
    this.setState({backPath: [...backPath.slice(0,2)].join('/')})
  }

  componentWillReceiveProps(newProps) {
    const { notification, notificationType } = newProps;
    if (notification){
      this.props.notify(notification, notificationType);
    }
  }

  async editQuotation(quotation) {
    const startDate = quotation.properties['Start Date'];
    if(isDatePassed(startDate)) {
      this.props.showForbiddenMessage('This quotation is outdated and cannot be edited.');
    } else {
      const { status } = await checkPermission("Edit Quotation");
      if (status === "permitted") {
        const urlData = {
          pathname: `/editQuotation/${quotation.productCode}/${quotation.id}`,
          state: 'reset'
        };
        this.props.history.push(urlData);
      } else this.props.showForbiddenMessage();
    }
  }

  render() {
    const { quotation } = this.props;
    let mainInsurerIsAdult;
    if (quotation && quotation.properties) mainInsurerIsAdult = !!Number(quotation.properties.Adults);
    const otherInsured = quotation.properties['Other Insured'];
    const insureds = [{ ...quotation.properties }];
    if (otherInsured) insureds.push(...otherInsured);
    const premiumSidebar = adjustAlfalahSideBar(sidebarModel.quotation.travelAlfalah , quotation.properties)

    return (
      <div className="container-fluid px-0">
        <TitleComponent
            title="Quotation Details"
            bannerGraphic={getQuoteGraphic}
            backTitle="Back to listing"
            backPath={this.state.backPath}
            history={this.props.history}
          >
          </TitleComponent>
          <div className="row d-flex justify-content-center my_50" id="quotation-detail">
            <Col sm={11} className="quotation-detail-wrapper">
              { quotation === undefined ||
                //because after clearing policy detail policy object is set to {}
                ( Object.keys(quotation).length === 0 && quotation.constructor === Object )?
                ( <div className={"loader d-block"} /> )
                :
                (
                  <Row>
                    <Col sm={8}>
                      <DetailTitle.Quotation quotation={quotation} />
                      <InsuredDetail
                        item={{
                          insureds,
                          beneficiaries: quotation.properties.Beneficiaries,
                        }}
                        model={DetailModel.travelAlfalah}
                        insuredTypes={TravelAlfalahConfig.insuredTypes}
                      />
                    </Col>
                    <Col sm={4}>
                      <div className="mb-3 text-right">
                        <Button
                          className={ quotation.state === 'Completed' ? 'customPrimarybtn d-none' : 'customPrimarybtn' }
                          onClick={() => this.editQuotation(quotation)}
                          color='primary'
                          >
                          Edit Quotation
                        </Button>
                      </div>
                      <SidebarCard.Quotation
                        properties={quotation.properties}
                        data={quotation.properties}
                        productName={quotation.productName}
                        model={premiumSidebar}
                        item={quotation}
                      />
                    </Col>
                  </Row>
                )
              }
            </Col>
          </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.clearQuotationDetail();
  }
}

function mapStateToProps(state) {
  const {
    quotation,
    notification,
    notificationType,
  } = state.quotationManagement;

  return {
    quotation,
    notification,
    notificationType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetail: (quotationId) => dispatch(quotationActions.getDetail(quotationId)),
    notify: (message, type) => dispatch(Notifications.show({ message }, type)),
    showForbiddenMessage: (message) => dispatch(showForbiddenMessage(message)),
    clearQuotationDetail: () => dispatch(quotationActions.clearQuotationDetail()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationDetail);
