import { documentationConstants } from "../constants";

const initialState = {
  canViewDocumentation: true,
  request: {
    requestObjectString: "",
    method: "",
    path: "",
    host: "",
  },
  response: {},
  products: [],
  headers: [
    { key: "X-Auth-Token", value: "" },
    { key: "Content-Type", value: "application/json" },
  ],
};
function documentation(state = initialState, { type, payload }) {
  switch (type) {
    case documentationConstants.SET_CURRENT_REQUEST_OBJECT:
      return { ...state, request: { ...payload } };
    case documentationConstants.SET_RESPONSE:
      return { ...state, response: payload };
    case documentationConstants.RESET_OBJECTS:
      return { ...initialState, products: state.products };
    case documentationConstants.GET_DOCUMENTATION_ENDPOINTS:
      return {
        ...state,
        canViewDocumentation: true,
        products: payload,
      };
    case documentationConstants.SET_FORBIDDEN_DOC:
      return { ...initialState, canViewDocumentation: false };
    case documentationConstants.SET_HEADER:
      const currentHeaders = state.headers;
      const indexToEdit = currentHeaders.findIndex((header) => (header.key === payload.key));
      const headerToEdit = currentHeaders.find(header => header.key === payload.key);
      const editedHeader = {...headerToEdit, value: payload.value};
      const updatedHeaders = [
        ...currentHeaders.slice(0, indexToEdit),
        editedHeader,
        ...currentHeaders.slice(indexToEdit + 1)
      ];
      return { ...state, headers: updatedHeaders };
    default:
      return state;
  }
}
export default documentation;
