import { useState } from 'react';

const useToggle = (init: any) => {
  const [value, setValue] = useState(init);
  const toggle = () => setValue(!value);
  return [value, toggle];
};

const useBoolean = (initial: boolean): [boolean, () => void, () => void] => {
  const [value, setValue] = useState(initial);
  const setTrue = () => setValue(true);
  const setFalse = () => setValue(false);
  return [value, setTrue, setFalse];
};

const hooks = { useToggle, useBoolean };
export default hooks;
