import BaseReducer from "./base";
import actionTypes from '@redux/actionTypes';

class SystemConfigReducer extends BaseReducer<any> {
    
    public initialState: IObject;
    public actions: IDictionary<(state: IListState, action: IAction) => object>;
    protected actionTypes: IObject;

    constructor() {
        super();
        this.actionTypes = actionTypes.systemConfig;
        this.initialState = {
          isLoading: true,
          originalConfigs:{
            orgUnitCode: "",
            currencySymbol: "IDR",
            currencySymbolBase: "USD",
            useExchangeRateFeature: true,
            prioritiesManualRate: true,
            exchangeRateList: [
                {
                    name: "Manual",
                    value: "",
                    updatedOn: "",
                    updatedBy: "",
                    status: false,
                    statusDescription: ""
                },
                {
                    name: "API",
                    value: "",
                    updatedOn: "",
                    updatedBy: "",
                    status: false,
                    statusDescription: ""
                }
            ]
          },
          configs :{
            orgUnitCode: "",
            currencySymbol: "IDR",
            currencySymbolBase: "USD",
            useExchangeRateFeature: true,
            prioritiesManualRate: true,
            exchangeRateList: [
                {
                    name: "Manual",
                    value: "",
                    updatedOn: "",
                    updatedBy: "",
                    status: false,
                    statusDescription: ""
                },
                {
                    name: "API",
                    value: "",
                    updatedOn: "",
                    updatedBy: "",
                    status: false,
                    statusDescription: ""
                }
            ]
          }
        };
        this.actions = this.createActions();
    
    }  
    
    createActions = (): IDictionary<(state: IListState, action: IAction) => IListState> => ({

        [this.actionTypes.GET_SYSTEM_CONFIGS]: this.getSystemConfigs,
        [this.actionTypes.UPDATE_SYSTEM_CONFIGS]: this.updateSystemConfigs,
        [this.actionTypes.SAVE_SYSTEM_CONFIGS]: this.saveSystemConfigs,
      })

      private getSystemConfigs = (state: IListState, action: IAction) =>{
        return ({
          ...state,
          isLoading: false,
          configs: action.payload,
          originalConfigs: action.payload
        })
      };

      private updateSystemConfigs = (state: IListState, action: IAction) =>{
        return ({
          ...state,
          configs: action.payload,
        })
      };

      private saveSystemConfigs = (state: IListState, action: IAction) =>{
        return ({
          ...state,
          configs: action.payload,
          originalConfigs: action.payload
        })
      };



      // private resetSystemConfigs = (state: IListState,action: IAction)=>{
      //   return({
      //     ...state,
      //     temp: {
      //       ...action.payload,
      //     }
      //   })
      // }


}

export default new SystemConfigReducer();