import {getRequestOptions} from "../../../utils";
import {handleRequest} from "../../../_services";
import {env} from "_config/env";

const {apiURL} = env;

const createPassword = async params => {
  const url = `${apiURL}/identity/users/reset-password`;
  const requestParams = {body: {...params}, method: "PUT"};
  const serverResponse = await handleRequest(url, getRequestOptions(requestParams));
  try {
    return await serverResponse.json();
  } catch (e) {
    return { error: "something went wrong."}
  }
};
const validateToken = async token => {
  const url = `${apiURL}/identity/users/valid-token?token=${token}`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  return await serverResponse.json();
};

export {
  createPassword,
  validateToken
}