import React from 'react';
import * as reactstrap from 'reactstrap';
import { objLib } from '@xc-core/lib';


const Select = (props: ISelectFormControlModel) => {
  const {
    handler, id, value, name, placeholder, className,
    disabled, autoFocus, collections, validator,
    customMessage, rules, validationMessage,validationName,
    validationData, validationRules
  } = props;
  const onChange = (e: any) => {
    const selectedValue = e && e.target && e.target.value;
    // const selectedPair = collections.find((c: IPair) => c.value === selectedValue);
    // const selectedId = selectedPair ? selectedPair.id : '';
    const pair = objLib.getPair(id, selectedValue);
    handler(pair);
  };

  return (
    <>
      <reactstrap.Input
        id={id}
        className={`xc-select ${className}`}
        value={value}
        onChange={(e: any) => onChange(e)}
        type='select'
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
      >
        <option disabled value=''>{placeholder || 'Select Option'}</option>
        {collections && collections.map((collection: IPair) => (
          <option
            key={collection.id}
            // value={collection && collection.code ? collection.code : collection.name}
            value={collection && collection.value}
          >
            {collection.value}
          </option>
        ))}
      </reactstrap.Input>
      {validator 
        ? <div>{validator.message(validationName ? validationName : name,
          validationData ? validationData : value,
          validationRules ? validationRules : "") }</div>
        : ''}
      {/* {
      validator
        ? validator.validateFieldAndGetMessage({
          name,
          value,
          rules: rules || [],
          customMessage,
        }) : ''
      } */}
      {
        validationMessage ? <div className='text-danger-step'>{validationMessage}</div> : <></>
      }
    </>
  );
};

export default Select;
