const userGroupActionTypes: IUserGroupActionTypes = {
  LIST_FAILED: 'USER_GROUP_LIST_FAILED',
  LIST_LOADING: 'USER_GROUP_LIST_LOADING',
  LIST_GET: 'USER_GROUP_LIST_GET',
  LIST_SET_FORBIDDEN: 'USER_GROUP_LIST_SET_FORBIDDEN',
  LIST_CHANGE_SEARCH_KEY: 'USER_GROUP_LIST_CHANGE_SEARCH_KEY',
  LIST_CHANGE_PAGE: 'USER_GROUP_LIST_CHANGE_PAGE',
  LIST_CHANGE_PAGE_SIZE: 'USER_GROUP_LIST_CHANGE_PAGE_SIZE',
  LIST_CHANGE_FILTERS: 'USER_GROUP_LIST_CHANGE_FILTERS',
  LIST_RESET_FILTERS: 'USER_GROUP_LIST_RESET_FILTERS',
  LIST_RESET: 'USER_GROUP_LIST_RESET',
  LIST_APPLY_FILTERS: 'USER_GROUP_LIST_APPLY_FILTERS',
  LIST_SET_NOT_FOUND: 'USER_GROUP_LIST_SET_NOT_FOUND',
  LIST_SET_HAS_RECORDS_INITIALLY: 'USER_GROUP_LIST_SET_HAS_RECORDS_INITIALLY',

  DETAIL_LOADING: 'USER_GROUP_DETAIL_LOADING',
  DETAIL_FAILED: 'USER_GROUP_DETAIL_FAILED',
  DETAIL_SET_FORBIDDEN: 'USER_GROUP_DETAIL_FORBIDDEN',
  DETAIL_GET: 'USER_GROUP_DETAIL_GET',

  CREATE_LOADING: 'USER_GROUP_CREATE_LOADING',
  CREATE_FAILED: 'USER_GROUP_CREATE_FAILED',
  CREATE_GET: 'USER_GROUP_CREATE_GET',

  UPDATE_LOADING: 'USER_GROUP_UPDATE_LOADING',
  UPDATE_FAILED: 'USER_GROUP_UPDATE_FAILED',
  UPDATE_GET: 'USER_GROUP_UPDATE_GET',

  CREATE_SET_FORBIDDEN: 'ORG_CREATE_SET_FORBIDDEN',
  UPDATE_SET_FORBIDDEN: 'ORG_UPDATE_SET_FORBIDDEN',

};

export default userGroupActionTypes;
