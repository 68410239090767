import storage from '@xc-core/storage';
import { ResponseError } from '@xc-core/lib';

type ResponseType = 'json' | 'file' | 'text';
class Response {
  constructor(url: string, request: IRequest, type: ResponseType = 'json') {
    this.url = url;
    this.request = request;
    this.type = type;
    this.error = null;
  }

  private parseError(error: any, url: string) {
    const customError = new ResponseError(error, url);
    this.error = customError.parse();
  }

  public url : string;

  public request: IRequest;

  public error: IError | null;

  public type: ResponseType;

  public async handle() : Promise<IResponseResult> {
    try {
      const response = await fetch(this.url, this.request.options);
      if (response.status === 200) {
        const contentDisposition = response.headers.get('Content-Disposition');
        const data = await this.parseResponse(response);
        return { data, contentDisposition } as IResponseResult;
      }
      if (response.status === 401) {
        // await logout();  calling logout here is not a good practice
        // and will make a cycle dependency with userService here
        storage.clear();
        window.location.reload();
      }
      const error = await response.json();
      this.parseError(error, this.url);
      throw this.error;
    } catch (error) {
      // debugger;
      if (error.message === 'Failed to fetch') { // is it really a good practice to force application to logout?
        setTimeout(() => {
          storage.clear();
          window.location.reload();
        }, 2000);
      }
      this.parseError(error, this.url);
      throw this.error;
    }
  }

  private parseResponse = (response: any): any => {
    switch (this.type) {
      case 'json':
        return response.json();
      case 'file':
        return response.blob();
      case 'text':
        return response.text();
      default:
        return null;
    }
  }
}

export default Response;
