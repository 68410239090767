import React from "react";
import { Col, Row } from "reactstrap";

import { MainInsuredDetails, ContactDetails } from "Products/WalletInsurance/getQuote/components";
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';

const CustomerDetails = (props) => {
	const {
		classes,
		fields,
		collections,
		properties,
		quotation,
		maxDate
	} = props;
	return (
		<Row>
			<Col md="8">
				<h5 className="sub-title">Please enter customer details</h5>
				<p className="custom-text-primary">Main Insured Details </p>
				<Row>
					<MainInsuredDetails
						fields = {fields.mainInsured}
						collections={collections}
						validator={props.validator}
						handleChange = {(e) => props.handleChange('mainInsured',e)}
						handleIdNumberChange = {(e) => props.handleIdNumberChange('mainInsured',e)}
						handleIdTypeChange = {(e) => props.handleIdTypeChange('mainInsured',e)}
						handleNumberFormatFields = {(e) => props.handleNumberFormatFields('mainInsured',e)}
						handleDob= {(dob) => props.handleDob('mainInsured',dob) }
						maxDate={maxDate}
					/>
				</Row>
				<p className="custom-text-primary">Contact Details </p>
				<Row>
					<ContactDetails 
						fields = {fields.contactDetails}
						collections = {collections}
						validator={props.validator}
						handleChange = {(e) => props.handleChange("contactDetails",e)}
						handleNumberFormatFields = {(e) => props.handleNumberFormatFields("contactDetails",e)} />
				</Row>
			</Col>
			<Col md="4">
				<SidebarCard.Quotation
                    item={quotation}
                    model={sidebarModel.quotation.walletAlfalah}
        		/>
			</Col>
		</Row>
	);
};

export default CustomerDetails;
