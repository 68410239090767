import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import {
  hooks,
  objLib,
  fieldLib,
  listLib,
} from '@xc-core/lib';
import products from '_config/products';
import { BaseQuotationSectionDetails } from '@containers';
import SignatureModal from './signature-modal';

interface IProps {
  item: IObject
  fields: IObject
  models: IObject
  collections: IObject
  getWitnessSignature: (id: string) => Promise<any>
  signatureId: string
}

const NomineesWitnessDetails = (props: IProps) => {
  const {
    item,
    fields,
    models,
    collections,
    getWitnessSignature,
    signatureId,
  } = props;
  const [preparedQuotation, setPreparedQuotation] = useState({});
  const [isViewSignOpen, toggleSingView] = hooks.useToggle(false);
  const [signImgSrc, setSignImgSrc] = useState('');

  const nominees = objLib.getValueFromPath(item, fields.nominees.id) as IObject[];
  const hasNominees = nominees ? nominees.length > 0 : false;

  const getCollectionValue = (id: string, list: IObject[]): string => {
    const code = objLib.getValueWithLodash(item, id);
    const obj = listLib.findByDynamicKey(list, 'code', code);
    return obj ? obj.name : '';
  };

  const getNomineeId = (index: number, id: string): string => fieldLib.applyIndexToRawId(index, id);

  const prepareNomineesWitnessInQuotationObj = (): any => {
    let obj = { ...item };
    if (hasNominees === false) return obj;

    obj = objLib.setValueWithLodash(obj, fields.witnessMobile.id,
      `${objLib.getValueWithLodash(obj, fields.witnessMobileCountryCode.id)}
      ${objLib.getValueWithLodash(obj, fields.witnessMobileBody.id)}`);

    nominees.forEach((nominee: IObject, index: number) => {
      obj = objLib.setValueWithLodash(obj, getNomineeId(index,
        fields.nomineeAddress.id),
      `${objLib.getValueWithLodash(obj, getNomineeId(index, fields.nomineeAddress1.id))},
      ${objLib.getValueWithLodash(obj, getNomineeId(index, fields.nomineeAddress2.id)) || ''}`);

      obj = objLib.setValueWithLodash(obj,
        getNomineeId(index, fields.nomineeStateName.id),
        getCollectionValue(getNomineeId(index, fields.nomineeState.id),
          collections[fields.nomineeState.collectionId]));

      obj = objLib.setValueWithLodash(obj,
        getNomineeId(index, fields.nomineeNationalityName.id),
        getCollectionValue(getNomineeId(index, fields.nomineeNationality.id),
          collections[fields.nomineeNationality.collectionId]));

      obj = objLib.setValueWithLodash(obj,
        getNomineeId(index, fields.nomineeCountry.id),
        products.gbsnICare.defaultValues.country);

      obj = objLib.setValueWithLodash(obj, getNomineeId(index,
        fields.nomineeMobile.id),
      `${objLib.getValueWithLodash(obj, getNomineeId(index, fields.nomineeMobileCountryCode.id))}
      ${objLib.getValueWithLodash(obj, getNomineeId(index, fields.nomineeMobileBody.id))}`);
    });
    return obj;
  };

  useEffect(() => {
    setPreparedQuotation(prepareNomineesWitnessInQuotationObj());
  }, [item]);

  const viewSignature = async () => {
    toggleSingView();
    if (isViewSignOpen) return;
    const src = await getWitnessSignature(signatureId);
    setSignImgSrc(src);
  };

  if (objLib.isEmpty(preparedQuotation)) return <></>;
  return (
    <>
      {
        hasNominees === false ? <></> : (
          <>
            {
              nominees.map((nominee: IObject, index: number) => (
                <BaseQuotationSectionDetails
                  key={objLib.getValueFromPath(nominees,
                    fieldLib.applyIndexToRawId(index, fields.nomineeIdNumber.id))}
                  title={`Nominee ${index + 1}`}
                  left={{
                    title: 'Nominee Details',
                    item: preparedQuotation,
                    model: fieldLib.applyIndexToFieldsRawId(index, models.detailsNomineeModel),
                  }}
                />
              ))
            }
            <BaseQuotationSectionDetails
              title='Witness'
              left={{ title: 'Witness Details', item: preparedQuotation, model: models.detailsWitnessModel }}
            >
              <Row>
                <Col md='6'>
                  <ul className='list-group'>
                    <li className='list-group-item d-flex justify-content-between border-0 px-0 py-1 grey-bg summary-label'>
                      Signature
                      <button type='button' className='col-5 p-0 wordBreak summary-label-2 button-as-link d-flex align-self-start' onClick={viewSignature}>
                        Click to view
                      </button>
                    </li>
                  </ul>
                </Col>
              </Row>

            </BaseQuotationSectionDetails>
            <SignatureModal isOpen={isViewSignOpen} toggle={toggleSingView} imgSrc={signImgSrc} />
          </>
        )
      }

    </>
  );
};

export default NomineesWitnessDetails;
