
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    total: { id: 'properties.Price.Total', name: 'Total Price', config: {} },
    config: {},
  },
  body: {
    items: [
      {
        id: 1,
        title: { id: 'Other Details', name: '' },
        items: [
          { id: 'properties.Gender', name: 'Gender', config: {} },
          { id: 'properties.Age', name: 'Age', config: {} },
          { id: 'properties.Plan', name: 'Plan', config: {} },
          { id: 'properties.Payment Frequency', name: 'Payment Frequency', config: {} },
        ],
        config: {},
      },
    ],
    config: {},
  },
  config: {},
};

export default model;
