import actionTypes from '@redux/actionTypes';
import BaseReducer from './base';

interface IAPIDocState extends IInitialState {
  list: object[] | null,
}
class PolicyDetailReducer extends BaseReducer<IAPIDocState> {
  public actions: IDictionary<(state: IAPIDocState,
    action: IAction) => IAPIDocState>;

  public initialState: IAPIDocState;

  protected actionTypes: IAPIDocActionTypes;

  constructor() {
    super();

    this.actionTypes = actionTypes.apiDoc;

    this.initialState = {
      isLoading: true,
      error: null,
      list: null,
      accessIsForbidden: false,
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IAPIDocState,
    action: IAction) => IAPIDocState> => ({
    [this.actionTypes.LOADING]: this.setLoading,
    [this.actionTypes.FAILED]: this.setError,
    [this.actionTypes.SET_FORBIDDEN]: this.setForbidden,
    [this.actionTypes.GET]: this.get,
  })

  public get = (state:IAPIDocState, action: IAction):IAPIDocState => ({
    ...state,
    isLoading: false,
    error: null,
    collections: action.payload.collections,
    item: action.payload.item,
    fields: action.payload.fields,
    filters: action.payload.filters,
  })
}

export default PolicyDetailReducer;
