import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Img from 'images/bannerGraphicImages/get-quote.png';
import { TitleComponent } from '_components';
import PathDetailsCard from '@xc-core/components/patchDetailsCard/patchDetailsCard';

interface IProps {
  policy: IObject
  title: JSX.Element
  children: JSX.Element
  sidebar: JSX.Element
  paymentCard: JSX.Element
  history: IObject | null
  actionButtons?: JSX.Element
  marketingInfoCard?: JSX.Element
}

const BasePolicyDetail = (props: IProps) => {
  const {
    policy,
    title,
    children,
    sidebar,
    history,
    actionButtons,
    paymentCard,
    marketingInfoCard,
  } = props;


  const [backPath, setBackPath] = useState('');
  useEffect(() => {
    let path = '';
    if (history) {
      path = [...history.location.pathname.split('/').slice(0, 2)].join('/');
    }
    setBackPath(path);
  }, []);

  return (
    <div className='container-fluid px-0'>
      <TitleComponent
        title='Certificate Details'
        bannerGraphic={Img}
        backTitle='Back to listing'
        backPath={backPath}
        history={history}
      />
      <div className='row d-flex justify-content-center my_50' id='policy-detail'>
        <Col sm={11} className='policy-detail-wrapper'>
          { policy === null ? <div className='loader d-block' />
            : (
              <Row>
                <Col sm={8}>
                  {title}
                  {children}
                </Col>
                <Col sm={4}>
                  {actionButtons}
                  {sidebar}
                  {paymentCard}
                  {marketingInfoCard}
                  {policy.patchDetails && Array.isArray(policy.patchDetails) && policy.patchDetails.length > 0 &&
                  <PathDetailsCard patchDetails={policy.patchDetails}/>}
                </Col>
              </Row>
            )}
        </Col>
      </div>
    </div>
  );
};

export default BasePolicyDetail;
