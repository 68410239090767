
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    items: [
      {
        current: { id: 'properties.Price.Total', name: '', config: { currency: true } },
        prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
        config: {},
      },
    ],
    config: {},
  },
  body: {
    items: [
      {
        id: 'Other Details',
        name: '',
        items: [
          { current: { id: 'properties.Gender', name: 'Gender', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Age', name: 'Age', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Product', name: 'Product', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Plan', name: 'Plan', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Payment Frequency', name: 'Payment Frequency', config: {} }, prev: { config: {} }, config: {} },
        ],
        config: {},
      },
    ],
  },
};

export default model;
