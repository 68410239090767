import React from 'react';
import { objLib, numberLib } from '@xc-core/lib';
import config from '@xc-core/config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';


interface IPlanButtonProps {
  type: string
  isSelected: boolean
  value: string
  onClick: () => void
}
const PlanButton = (props: IPlanButtonProps) => {
  const {
    type,
    isSelected,
    value,
    onClick,
  } = props;
  return (
    <Grid item xs={12} md={4} key={type}>
      <Card className={`${isSelected ? 'cardActive' : ''}`}>
        <CardActionArea
          onClick={onClick}
          data-set={1}
          type='button'
          autoFocus={isSelected}
        >
          <div
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              color: '#064e8c',
            }}
          >
            <i className={isSelected ? 'icon-checkedOutline font-size-normal' : ''} />
          </div>
          <CardContent className='d-flex flex-column p-4'>
            <h5>{type}</h5>
            <h3 className='text-primary font-weight-bold'>{value}</h3>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const LoadablePlanSelectionComponent = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      model,
      selectedPlan,
      handler,
    },
  } = props;

  const getType = (plan: IObject) => objLib.getValueWithLodash(plan, model.typeId);
  const getValue = (plan: IObject) => objLib.getValueWithLodash(plan, model.priceId);
  const formatPlanValue = (plan: IObject) => (
    `${config.currencySymbol} ${numberLib.applyFormat(getValue(plan), config.currencyFormat)}`);
  return (
    <Grid container spacing={24}>
      {collections.map((plan: IObject) => (
        <PlanButton
          key={getType(plan)}
          isSelected={selectedPlan ? getType(plan) === selectedPlan : false}
          type={getType(plan)}
          value={formatPlanValue(plan)}
          onClick={() => handler(plan)}
        />
      ))}
    </Grid>
  );
};

export default LoadablePlanSelectionComponent;
