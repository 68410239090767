import React from 'react';
import { Card } from 'react-toolbox/lib/card';
import Loader from '../loader';

const FilterCard = (props: { isVisible: boolean, children: any, isLoading: boolean }) => {
  const { isVisible, children, isLoading } = props;
  return (
    <Card
      id='xc-filter'
      className={
        isVisible ? 'd-block rounded-xl shadow card mt-2 xc-filter' : 'd-none'
      }
    >
      {/* {children} */}
      {isLoading ? <Loader /> : <>{children}</>}
    </Card>
  );
};

export default FilterCard;
