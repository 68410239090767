import React from 'react';
import { connect } from 'react-redux';
import { ModalMessageActions } from '@redux/actions';
import { Modal, Button } from '@xc-core/components/core';

const modalMessageActions = new ModalMessageActions();

interface IModalMessageProps {
  isVisible: boolean,
  title: string,
  message: string,
  hide: Function,
  className?: string
}
const ModalMessage = (props: IModalMessageProps) => {
  const {
    isVisible, title, message, hide, className,
  } = props;
  const children = (<p>{message}</p>);
  const hideModal = () => hide();
  return (
    <Modal
      className={className || ''}
      id='modalMessage'
      title={title}
      isOpen={isVisible}
      toggle={hideModal}
    >
      {children}
      <Button id='modalMessageButton' className='btn btn-primary float-right' name='OK' handler={hideModal} />
    </Modal>
  );
};

const mapStateToProps = (state: IObject) => ({
  title: state.modalMessage.title,
  message: state.modalMessage.message,
  isVisible: state.modalMessage.isVisible,
});

const mapDispatchToProps = (dispatch: any) => ({
  hide: () => dispatch(modalMessageActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalMessage);
