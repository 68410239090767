import React from 'react';
import { CardBody, Row } from 'reactstrap';

const FilterBody = (props: {children:any}) => {
  const { children } = props;
  return (
    <CardBody>
      <Row className='row-eq-height'>
        {children}
      </Row>
    </CardBody>
  );
};

export default FilterBody;
