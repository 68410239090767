import React from 'react';
import { connect } from 'react-redux';
import { BaseComponent } from '@xc-core/components';
import { Loader, Message } from '@xc-core/components/core';
import { userMessages } from '@xc-core/constants';
import { GetQuoteActions, ProductActions } from '@redux/actions';

// --------------Old------------------
import { getQuoteActions as oldGetQuoteActions } from 'Products/shared/GetQuote/actions';
import { checkPermission as oldCheckPermission } from '_services';
// -----------------------------------

import RenderWizard from './renderWizards';

const getQuoteActions = new GetQuoteActions();
const productActions = new ProductActions();

// const NEW_PRODUCT_CODES = [2036273];
const OLD_PRODUCT_CODES = [2036091, 1998034, 2106357, 2062582];

class EditQuote extends BaseComponent {
  constructor() {
    super();
    this.state = {
      hasPermission: true,
      productCode: null,
    };
  }

  async componentDidMount() {
    const {
      match: { params: { quotationId, productCode } },
      oldGetQuotation, oldResetQuotation, history, oldItemInGetQuote,
      setGetQuote, setEditQuotePermission, setValidationModule,
    } = this.props;
    this.setState({ productCode });

    if (this.isOld(productCode)) {
      // ----------------------------Old---------------------------------------------
      if (oldItemInGetQuote) await oldResetQuotation();
      const { status } = await oldCheckPermission('Edit Quotation');
      if (status === 'permitted') {
        const { payload: { item: { state } } } = await oldGetQuotation(quotationId);
        if (state === 'Completed') history.push('/getQuote');

        setGetQuote(quotationId); // to set the object in newGetQuote,
        // because render wizard checks for current product in this object
      } else this.setState({ hasPermission: false });
      //------------------------------------------------------------------------------
    } else {
      await setEditQuotePermission();
      setGetQuote(quotationId);
      setValidationModule(productCode);
    }
  }

  render() {
    const { getQuote, history, oldItemInGetQuote } = this.props;
    const { productCode } = this.state;

    if (productCode === null || getQuote.product === null) return <Loader />;

    if (this.isOld(productCode)) {
      if (!this.state.hasPermission) return <Message message={userMessages.ACCESS_DENIED} />;
      if (!oldItemInGetQuote) return <Loader />;
    } else {
      if (getQuote.accessIsForbidden) return <Message message={userMessages.ACCESS_DENIED} />;
      if (!getQuote.product) return <Loader />;
    }

    return <RenderWizard history={history} />;
  }

  isOld = (productCode) => OLD_PRODUCT_CODES.includes(Number(productCode));
}

const mapDispatchToProps = (dispatch) => ({
  setEditQuotePermission: () => dispatch(getQuoteActions.setEditPermission()),
  setGetQuote: (id) => dispatch(getQuoteActions.setGetQuote(id)),
  setValidationModule: (id) => dispatch(productActions.setQuotationValidationModuleInStorage(id)),
  // --------------------------Old----------------------------------------------------------
  oldGetQuotation: (quotationId) => dispatch(oldGetQuoteActions.getQuotation(quotationId)),
  oldResetQuotation: () => dispatch(oldGetQuoteActions.resetQuotation()),
  oldGetProducts: () => dispatch(oldGetQuoteActions.getProducts()),
});

const mapStateToProps = (state) => ({
  getQuote: state.newGetQuote,
  oldItemInGetQuote: state.getQuote.item,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditQuote);
