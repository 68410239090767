import React from 'react';
import {connect} from "react-redux";
import ModalUi from "./ModalUi";
import {store} from "../_helpers";
import {HIDE_FORBIDDEN_MESSAGE} from "../_constants";



const ForbiddenModalComponent = (({showForbiddenMessage, message}) => {
  return (
    <ModalUi
      title={"Insufficient Permissions"}
      isOpen={showForbiddenMessage}
      toggle={() => store.dispatch({type: HIDE_FORBIDDEN_MESSAGE})}
    >
      <div>
        <p>{message}</p>
        <button
          className="btn btn-primary float-right"
          onClick={() => store.dispatch({type: HIDE_FORBIDDEN_MESSAGE})}
        >OK</button>
      </div>
    </ModalUi>
  );
});

const mapStateToProps = ({global: {showForbiddenMessage, message }}) => ({
  showForbiddenMessage,
  message,
});

export const ForbiddenModal = connect(mapStateToProps)(ForbiddenModalComponent);