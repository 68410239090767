const distributionControlActionTypes = {
    GET_LIST: 'GET_LIST_ORGS',
    GET_DETAILS: 'GET_DETAILS_ORG',
    TOGGLE_EDIT_LIMIT: 'TOGGLE_EDIT_LIMIT',
    ADD_LIMIT: 'ADD_LIMIT',
    SAVE_LIMIT_SETTINGS: 'SAVE_LIMIT_SETTINGS',
    HANDLE_CHANGE_LIMIT: 'HANDLE_CHANGE_LIMIT',
    SET_DISTRIBUTION_LOADING: 'SET_DISTRIBUTION_LOADING',
    DELETE_LIMIT: 'DELETE_LIMIT',
    LIMIT_RESET_ORG_DETAILS: 'LIMIT_RESET_ORG_DETAILS'
  };
  
  export default distributionControlActionTypes;
  