import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { CommonInputTypes } from '_components';
import Moment from 'moment';

const minAgeDays = 29;
const maxAgeYrs = 80;

const ValidationMessages = ({validator, disabled, value='', inputName, validationRules}) => {
  return (
    <div>
      {
        validator && !disabled
        && validator.message(inputName, value,
          validationRules ? `${validationRules}|customRequired` : 'customRequired')
      }
    </div>
  );
};

const PolicyEndorsement = (props) => {
  const {
    properties,
    collections,
    handleChange,
    handleNumberFormatFields,
    validator,
  } = props;

  const { 'Car Details':carDetails , 'Car Owner Details': carOwnerDetails } = properties;

  const getMinDOB = () => {
    return Moment(new Date())
    .subtract(`${0}`, 'years')
    .subtract(`${0}`, 'months')
    .subtract(`${minAgeDays}`, 'days')._d;
  };

  const getMaxDOB = () => {
    return Moment(new Date())
    .subtract(`${maxAgeYrs}`, 'years')
    .subtract(`${0}`, 'months')
    .subtract(`${365}`, 'days')._d;
  };
  return (
    <Row>
      <Col sm={12}>
        <Row className='wordBreak'>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Car Owner Details</div>
            <Row>
              <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Full Name'
                    name='Full Name'
                    placeholder='Enter full name'
                    value={carOwnerDetails['Full Name']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='full_name_valid_characters'
                    value={carOwnerDetails['Full Name']}
                    inputName='Full Name'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Occupation</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Occupation'
                    name='Occupation'
                    placeholder='Enter Occupation'
                    value={carOwnerDetails['Occupation']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='full_name_valid_characters'
                    value={carOwnerDetails['Occupation']}
                    inputName='Occupation'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Type</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='ID Type'
                    name='ID Type'
                    value={carOwnerDetails['ID Type']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                >
                  {
                      collections && collections.idTypes && collections.idTypes.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='ID Number'
                    name='ID Number'
                    placeholder='Enter ID number'
                    value={carOwnerDetails['ID Number']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={carOwnerDetails['ID Number']}
                    inputName='ID Number'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Date of Birth</Col>
              <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                <CommonInputTypes
                  id='DOB'
                  dateStyle={{ marginBottom: 0 }}
                  validator={validator}
                  group={false}
                  type='date'
                  value={carOwnerDetails.DOB}
                  onChange={(date) => handleChange({ target: { id: 'DOB', value: date } })}
                  openToDate={carOwnerDetails.DOB}
                  className='datePickerBorderLeft'
                />
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Gender</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='Gender'
                    name='Gender'
                    value={carOwnerDetails.Gender}
                    onChange={(e) => handleChange(e)}
                >
                  {
                      collections && collections.genders && collections.genders.map(gender => {
                      return (<option key={gender.id}>{gender.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Marital Status</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='Marital Status'
                    name='Marital Status'
                    value={carOwnerDetails['Marital Status']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                >
                  {
                      collections && collections.maritalStatus && collections.maritalStatus.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Address</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Address'
                    name='Address'
                    placeholder='Enter address'
                    value={carOwnerDetails['Address']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={carOwnerDetails.Address}
                    inputName='Address'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>City / Town</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='City'
                    name='City'
                    placeholder='Enter city'
                    value={carOwnerDetails.City}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={carOwnerDetails.Address}
                    inputName='City'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>State / Province / Region</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                      type='select'
                      id='State'
                      name='State'
                      placeholder='Select Option'
                      value={carOwnerDetails.State}
                      onChange={(e) => handleChange(e)}
                      autoFocus
                  >
                    {
                        collections && collections.provinces && collections.provinces.map(province => {
                          return (<option key={province.id}>{province.name}</option>);
                        })
                    }
                  </Input>
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Postcode</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Postcode'
                    name='Postcode'
                    placeholder='Enter postcode'
                    value={carOwnerDetails.Postcode}
                    onChange={(e) => handleNumberFormatFields(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='numbersOnly'
                    value={carOwnerDetails.Postcode}
                    inputName='Postcode'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Phone Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Mobile Number'
                    name='Mobile Number'
                    placeholder='Enter phone number'
                    value={carOwnerDetails['Mobile Number']}
                    onChange={(e) => handleNumberFormatFields(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='numbersOnly'
                    value={carOwnerDetails['Mobile Number']}
                    inputName='Mobile Number'
                  />
                </>
              </Col>
            </Row>
          </Col>

          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Car Details</div>
            <Row>
              <Col className='pt-2 summary-label' sm={6}>Vehicle Registration Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Vehicle Registration Number'
                    name='Vehicle Registration Number'
                    placeholder='Enter Vehicle Registration Number'
                    value={carDetails['Vehicle Registration Number']}
                    onChange={(e) => handleChange(e , 'Car Details')}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='customRequired'
                    value={carDetails['Vehicle Registration Number']}
                    inputName='Vehicle Registration Number'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Make</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    type='select'
                    id='Make'
                    name='Make'
                    placeholder='Make'
                    value={carDetails['make']}
                    onChange={(e) => handleChange(e , 'Car Details')}
                    autoFocus
                  >
                    {
                      collections && collections.make && collections.make.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                  </Input>
                </>
              </Col>
              
              <Col className='pt-2 summary-label' sm={6}>Model</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    type='select'
                    id='Model'
                    name='Model'
                    placeholder='Model'
                    value={carDetails['model']}
                    onChange={(e) => handleChange(e , 'Car Details')}
                    autoFocus
                  >
                    {
                      collections && collections.model && collections.model.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                  </Input>
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Manufacture Year</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    type='select'
                    id='Manufacture Year'
                    name='Manufacture Year'
                    placeholder='Manufacture Year'
                    value={carDetails['manufacture year']}
                    onChange={(e) => handleChange(e , 'Car Details')}
                    autoFocus
                  >
                    {
                      collections && collections.manufactureYear && collections.manufactureYear.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                  </Input>
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Engine CC</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    type='select'
                    id='Engine CC'
                    name='Engine CC'
                    placeholder='Enter Engine CC'
                    value={carDetails['Engine CC']}
                    onChange={(e) => handleChange(e , 'Car Details')}
                    autoFocus
                  >
                  {
                    collections && collections.engineCC && collections.engineCC.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                  </Input>
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Transmission</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    type='select'
                    id='Transmission'
                    name='Transmission'
                    placeholder='Enter Transmission'
                    value={carDetails['Transmission']}
                    onChange={(e) => handleChange(e , 'Car Details')}
                    autoFocus
                  >
                  {
                    collections && collections.transmission && collections.transmission.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                  </Input>
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Variant</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                      type='select'
                      id='Variant'
                      name='Variant'
                      placeholder='Enter Variant'
                      value={carDetails['Variant']}
                      onChange={(e) => handleChange(e , 'Car Details')}
                      autoFocus
                  >
                    {
                        collections && collections.variant && collections.variant.map(idType => {
                          return (<option key={idType.id}>{idType.name}</option>);
                        })
                    }
                  </Input>
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Vehicle details verified</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    type='select'
                    id='Vehicle details verified'
                    name='Vehicle details verified'
                    placeholder='Vehicle details verified'
                    value={carDetails['Vehicle details verified']}
                    onChange={(e) => handleChange(e , 'Car Details')}
                    autoFocus
                  >
                    <option key='Yes'>Yes</option>
                    <option key='No'>No</option>
                  </Input>
                </>
              </Col>

            </Row>
          </Col>
          
        </Row>
      </Col>
    </Row>
  );
}

export default PolicyEndorsement;
