import { OrganizationService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import {
  requestLib,
  filterLib,
} from '@xc-core/lib';
import BaseListAction from './baseListing';

class OrganizationAction extends BaseListAction {
  constructor() {
    super();
    this.service = new OrganizationService();
  }

  protected actions = actionTypes.organization;

  protected service: OrganizationService;

  protected csvExportScope = '';

  protected editScope = 'Edit Organisation';

  protected createScope = 'Create Organisation';

  public setList = (params: IRequestParam) => async (dispatch: any, getState: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      const { filters } = getState().organizationList;
      const stringParams = requestLib.convertToRequestParams(params);
      let result = await this.service.getList(stringParams);
      const initialFilters = filterLib.parse(result.filters, params);
      const fields = this.createFields();
      result = {
        ...result,
        fields,
        list: this.prepareList(result.list.content),
        collections: this.prepareCollections(result.collections),
        initialFilters,
        filters: filters.length > 0 ? filters : initialFilters,
        prevFilters: filters.length > 0 ? filters : initialFilters,
        pageInfo: this.createPageInfo(result.list),
        searchKeyword: params.name ? params.name[0] : '',
      };
      dispatch(this.createAction<IObject>(this.actions.LIST_GET, result));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      if (error.status === 404) {
        dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }

  public getSearchParams = () => (dispatch: any, getState: any): IRequestParam => {
    const { organizationList } = getState();
    return this.getSearchParamsByItem(organizationList);
  }

  public setHasRecordsInitially= () => async (dispatch: any, getState: any) => {
    const { list } = getState().organizationList;
    const hasRecords = list && list.length > 0;
    dispatch(this.createAction<boolean>(this.actions.LIST_SET_HAS_RECORDS_INITIALLY, hasRecords));
  }

  private prepareList = (list: IObject[]): IObject[] => {
    const prepared = this.setActiveValueFor(list, 'status');
    return prepared;
  };

  private prepareCollections = (obj: IObject): IObject => ({
    ...obj,
    viewType: this.getViewTypeList(),
  })

  private getViewTypeList = (): IPair[] => [{ value: 'Chart', id: 'stats' },
    { value: 'List', id: 'list' }];

  private createFields = (): IPair[] => ([
    { id: 'name', value: 'Organisation Name' },
    { id: 'extendedProperties.type', value: 'Organisation Type' },
    { id: 'parent', value: 'Parent Organisation' },
    { id: 'status', value: 'Status' },
  ]);
}

export default OrganizationAction;
