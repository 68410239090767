import React from "react";
import PropTypes from "prop-types";
import { CardBody, Row } from "reactstrap";

const FilterBody = (props) => {
  const { children } = props;
  return (
    <CardBody>
      <Row className="row-eq-height">
        {children}
      </Row>
    </CardBody>
  );
};
FilterBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FilterBody;
