import React from 'react';
import * as reactstrap from 'reactstrap';


const RadioButton = (props: ICheckBoxRadioButtonFormControl) => {
  const {
    handler, checked,
    className, id, name, value, validationMessage , label
  } = props;

  const onClick = (e: any) => {
    var pair:IPair;
    if (!label) {
      pair = {id:id.slice(0,id.indexOf(name)), value:value}
    } else {
      pair = {id: name, value:value}
    }
    handler(pair);
  };
  const getIcon = () => (checked ? 'radio-checked' : 'radio-unchecked');

  return (
    <reactstrap.InputGroup
      id={id}
      className={`xc-radio-button ${className}`}
      name={label ? label  : name}
      onClick={(e: any) => onClick(e)}
    >
      <reactstrap.InputGroupAddon addonType='prepend'>
        <reactstrap.InputGroupText>
          <i className={`icon-${getIcon()}`} />
          {' '}
        </reactstrap.InputGroupText>
      </reactstrap.InputGroupAddon>
      <reactstrap.Label className='form-control' id={id}>
        {label ? label : name}
      </reactstrap.Label>
      {
        validationMessage ? <div className='text-danger-step'>{validationMessage}</div> : <></>
      }
    </reactstrap.InputGroup>
  );
};

export default RadioButton;
