import React from "react";
import { connect } from "react-redux";
import Notifications from 'react-notification-system-redux';
import { Button, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import moment from "moment";
import { store } from "_helpers";
import { SHOW_FORBIDDEN_MESSAGE } from '_constants';
import { policyActions } from "../../../shared/policyListing/actions";
import EditableInsuredDetail from "./InsuredDetail";
import EndorseForm from "../../../shared/policyListing/components/EndorseForm";
import CancelForm from "../../../shared/policyListing/components/CancelForm";

import { TitleComponent } from "_components";
import getGuoteGraphic from "../../../../images/bannerGraphicImages/get-quote.png";
import config from '_config';
import { utils } from "utils";
import { sidebarModel } from "models";
import { DetailModel } from 'models';
import BasePolicyDetail from 'Products/CBTravelInsurance/basePolicyDetail';
import {getIndicesForTitles, updateIndicesByType, noAddOnCountries, adjustAlfalahSideBar} from '../../alfalahUtils';
import { DetailTitle, SidebarCard, PaymentCard, InsuredDetail } from '@xc-core/components';
import { TravelAlfalahConfig } from '@products';
import {objLib} from "../../../../@xc-core/lib";
import lib from "../../../../utils/lib";
import {CONFIG_DATE_MOMENT_FORMAT,getOneYearInclusiveEndMoment} from "../../../../utils";
import EndorsementCheckPriceModal from "../../../../@containers/SharedPage/LowerPremiumEndorsementModal";
import {RemarksCard} from "../../../../@xc-core/components";

class PolicyDetail extends BasePolicyDetail {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator(utils.validations.alfalahValidationObject);

    this.state = {
      policy: props.policy,
      paymentDetailForm: props.paymentDetailForm,
      initialPaymentDetailForm: props.paymentDetailForm,
      dropdownOpen: false,
      endorseModalIsOpen: false,
      cancelModalIsOpen: false,
      editMode: false,
      sendingRequest: false,
      endorseRemarkIsValid: true,
    }
  }

  componentWillReceiveProps(newProps) {
    const { endorseModalIsOpen, cancelModalIsOpen } = this.state;
    const { notification, notificationType } = newProps;
    // if(newProps.policyForm !== undefined) {
    if (newProps.policy) {
      this.setState({
        ...this.state,
        poolicy: {
          ...newProps.policy, // .policyForm,
        },
        paymentDetailForm: {
          ...newProps.paymentDetailForm,
        }
      });
      if (!Object.keys(this.state.initialPaymentDetailForm).length && newProps.paymentDetailForm) {
        this.setState({initialPaymentDetailForm: newProps.paymentDetailForm})
      }
    }


    if (this.props.policyDetailBlob === undefined && newProps.policyDetailBlob) {
      const url = window.URL.createObjectURL(new Blob([newProps.policyDetailBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `policy-${newProps.policy.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      this.props.clearBlobPolicy();
    }
    if (notification) this.props.notify(notification, notificationType);
    if (endorseModalIsOpen) this.setState(Object.assign({}, { endorseModalIsOpen: false, editMode: false }))
    if (cancelModalIsOpen) this.setState(Object.assign({}, { cancelModalIsOpen: false }))
  }

  async componentDidMount() {
    const backPath = this.props.history.location.pathname.split('/');
    this.setState({backPath: [...backPath.slice(0,2)].join('/')});
    const { policy } = this.props;
    const country = policy && policy.properties.Country ? 
      policy.properties.Country : config.products.travelInsurance.defaultValues.country;
    await this.props.getStates(country, '?collection=state_alf');
  }

  toggleEndorseModal= async () => {
    const { endorseModalIsOpen,policy:{properties} } = this.state;
    const { showPriceUpdateModal, togglePriceUpdateModal } = this.props;
    if (showPriceUpdateModal) await togglePriceUpdateModal();
    this.setState((prevState) => (
        {
          ...prevState,
          policy: {
            ...this.state.policy,
            properties:{
              ...properties,
              'Endorse Remarks': ''
            },
          },
        }
    ), async () => {
      this.setState((prevState) => (
          {
            ...prevState,
            endorseModalIsOpen: !endorseModalIsOpen,
            endorseRemarkIsValid: true,
          }
      ));
    });
  }

  handleSubmitEndorse = async ()=>{
    const { policy:{properties},endorseModalIsOpen } = this.state;
    const { endorsementCheckData: { endorsementRequestId }, endorseConfirm } = this.props;
    if (this.validator.allValid() && properties['Endorse Remarks']) {
      this.setState({ sendingRequest: true });
      await endorseConfirm(endorsementRequestId,properties["Endorse Remarks"]).then((res)=>{
            this.setState((prevState)=>(
                {
                  ...prevState,
                  endorseModalIsOpen: !endorseModalIsOpen,
                  policy:{
                    ...res.item,
                    properties:{
                      ...res.item.properties,
                      'Endorse Remarks' : ''
                    }
                  }}
            ));
      });
      this.setState({sendingRequest: false, endorseRemarkIsValid: true});
    } else {
      this.setState({ endorseRemarkIsValid: false });
    }
  }


  toggleEditMode = async () => {
    const {
      endorsementPermissions: {
        financialEndorsementPermitted,
        nonFinancialEndorsementPermitted,
      },
    } = this.props;
    if (!financialEndorsementPermitted && !nonFinancialEndorsementPermitted) {
      store.dispatch({ type: SHOW_FORBIDDEN_MESSAGE });
      return;
    }
    const { editMode } = this.state;
    if (editMode) {
      this.setState({
        ...this.state,
        policy: this.props.policy, // .policyForm,
        editMode: !editMode
      })
    } else {
      this.setState({ ...this.state, editMode: !editMode });
    }
  } // end of toggleEditMode

  reissuePolicy = async (id) => {
    const isPermitted = await this.props.reissuePermission();
    if (!isPermitted) {
      store.dispatch({ type: SHOW_FORBIDDEN_MESSAGE });
      return;
    }else {
      this.props.reissuePolicy(this.props.policy.id);
    }
  } // end of reissuePolicy

  handleDOBChange = (date , idx) => {
  }

  handleDOBInputChange = (date , idx) => {
  }

  handleInputChange = (e, index) => {
    const { policy: { properties } } = this.state;
    const key= (e && e.target && e.target.id) || e?.id;
    const id = `${key.indexOf('properties') === -1 ? "properties." : ""}${key}`;
    let value = (e && e.target && e.target.value) || e?.value;
    let additionalProperties = {};
    if(id === 'properties.Trip Type' || id === 'properties.Start Date'){
      const startDate = id === 'properties.Start Date' ? value : properties['Start Date']
      const startM = moment(startDate, CONFIG_DATE_MOMENT_FORMAT);
      const endM = (value === 'Single Trip' || (id === 'properties.Start Date' && properties['Trip Type'] === 'Single Trip') ? startM.clone().add(utils.constants.defaultTravelNoOfDays - 1, 'days'):
          getOneYearInclusiveEndMoment(startDate)).format(CONFIG_DATE_MOMENT_FORMAT);
      additionalProperties= {
        ...additionalProperties,
        'Start Date': startM.format(CONFIG_DATE_MOMENT_FORMAT),
        'End Date': endM
      }
    }

    if(id === 'properties.destinationCountry'){
      additionalProperties={
        ...additionalProperties,
        Destination: value === 'United States' || value === 'Canada' ? 'Worldwide' : 'Worldwide excluding U.S. & Canada'
      }
    }

    if (value instanceof Date) {
      value = utils.lib.applyConfigFormat(value);
    }
    this.setState({
      ...this.state,
      endorseRemarkIsValid: true,
      policy: {...objLib.setValueWithLodash({
          ...this.state.policy,
          properties:{
            ...properties,
            ...additionalProperties
          }
        }, id, value)}
    })
  };

  handlePaymentInputChange = (e) => {
    this.setState({
      ...this.state,
      paymentDetailForm: {
        properties: {
          ...this.state.paymentDetailForm.properties,
          [e.target.id]: e.target.value
        }
      }
    });
  }

  handleInputOtherInsured = (e, index) => {
    const { policy: {properties}, policy } = this.state;
    const id = e.target.id;
    let value = e.target.value;
    if (value instanceof Date) {
      value = utils.lib.applyConfigFormat(value);
    }

    let newObject = Object.assign([], properties['Other Insured'], {
      [index-1]: {
        ...properties["Other Insured"][index - 1],
        [id]: value,
      }
    });

    this.setState({
      ...this.state,
      policy: {
        ...policy,
        properties: {
          ...properties,
          "Other Insured": newObject,
        }
      }
    });
  }

  handleInputBeneficiary = (e, index) => {
    const { policy, policy: { properties: { Beneficiaries }, properties } } = this.state;
    const key = e.target.id;
    const value = e.target.value;

    let newObject = Object.assign([], Beneficiaries, {
      [index]: {
        ...Beneficiaries[index],
        [key]: value,
      },
    });

    this.setState({
      ...this.state,
      policy: {
        ...policy,
        properties: {
          ...properties,
          Beneficiaries: newObject,
        },
      },
    });
  }

  renderOtherInsured(properties) {
    const { editMode } = this.state;
    const indices = getIndicesForTitles(properties);

    if (properties && properties['Other Insured']) {
      return (
        <div>
          { properties['Other Insured'].map((data, index) => {
            const currentId = index + 1;
            return (
              <EditableInsuredDetail
                index={updateIndicesByType(indices, data.Type)}
                beneficiaries={properties['Beneficiaries'][currentId]}
                beneficiaryIndex={currentId}
                key={index}
                data={data}
                main={false}
                editMode={editMode}
                inputChange={this.handleInputOtherInsured.bind(this)}
                beneficiaryChange={this.handleInputBeneficiary.bind(this)}
                validator={this.validator}
                uniqueId={`other ${currentId}`}
                properties={properties}
              />
            )
          })
          }
        </div>
      )
    } else {
      return null;
    }
  }

  toggleDropDown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleBackToListing() {
    this.props.history.push(`/policies`);
  }

  handleSubmitPayment = () => {
    const { policy } = this.props;
    const { paymentDetailForm } = this.state;

    this.props.updatePolicy(policy.id, paymentDetailForm, true);
    this.setState({
      initialPaymentDetailForm: paymentDetailForm, // the initial is being used for cancel,
      // and after every submitting this initial
      // should be updated
    });
  }

  handleSubmit = async () => {
    let isFinancialEndorsement= false;
    const { checkEndorsement,policy} = this.props;
    const { policy: { properties } } = this.state;
    if (this.validator.allValid()) {
      this.setState({sendingRequest: true});
      const financialField = ['Destination','Trip Type' , 'destinationCountry' ,'Start Date','End Date','Plan'];
      if(!financialField.every((el)=>properties[el] === policy.properties[el])){
        isFinancialEndorsement = true;
      }
     const response = await checkEndorsement({
        isFinancialEndorsement,
        policy: {
          refId: this.state.policy.refId,
          productSlug: this.state.policy.productSlug,
          ...this.preparePropertiesForServer(properties),
        },
      })
      if (!response || response.type !== 'TOGGLE_PRICE_UPDATE_MODAL') {
        this.toggleEndorseModal();
      }
      this.setState({sendingRequest: false});
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  preparePropertiesForServer = (properties) => {
    const startDate = properties['Start Date'];
    const endDate = properties['End Date'];
    const noOfDays = moment(endDate,CONFIG_DATE_MOMENT_FORMAT).diff(moment(startDate,CONFIG_DATE_MOMENT_FORMAT),"days") + 1

    return {
      properties: {
        'Other Insured': properties['Other Insured'],
        Beneficiaries: properties.Beneficiaries,
        'Full Name': properties['Full Name'],
        'Nationality': properties['Nationality'],
        'CNIC Number': properties['CNIC Number'],
        'CNIC Issuance Date': properties['CNIC Issuance Date'],
        'Passport Number': properties['Passport Number'],
        'DOB': properties['DOB'],
        'Gender': properties['Gender'],
        'Address': properties['Address'],
        'City / Town': properties['City / Town'],
        'State': properties['State'],
        'Country': properties['Country'],
        'Postcode': properties['Postcode'],
        'Email Address': properties['Email Address'],
        'Contact Number': properties['Contact Number'],
        'Endorse Remarks': properties['Endorse Remarks'],
        'BCC Email': properties['BCC Email'],
        'CC Email': properties['CC Email'],
        'tripDuration':properties['Trip Type'] === 'Annual' ? undefined: 'Custom',
        'Destination':properties['Destination'],
        'Trip Type': properties['Trip Type'],
        'destinationCountry': properties['Trip Type'] === 'Annual' ? null : properties['destinationCountry'],
        'Start Date': startDate,
        'End Date':endDate,
        'No. of Days': noOfDays,
        'Plan': properties['Plan']
      },
    };
  };

  componentWillUnmount() {
    this.props.clearPolicyDetail();
  }

  resetPaymentDetailsForm= () => {
    this.setState((prevState) => ({
      paymentDetailForm: prevState.initialPaymentDetailForm,
    }));
  }

  isEndorsementAllowed = (endorsementType) => {
    const { endorsementPermissions } = this.props;
    if (!endorsementPermissions) return false;
    if (endorsementType === 'non-financial') {
      return endorsementPermissions.nonFinancialEndorsementPermitted;
    }
    return endorsementPermissions.financialEndorsementPermitted;
  };

  filterCollections=(collections,item)=>{
    const {properties} = item;
    const isAddonPermitted = noAddOnCountries.indexOf(properties['destinationCountry'] || '') === -1
    return {
      ...collections,
      plans: collections.plans.filter((el)=> el.id !== 'Basic'),
      travelDestinationCountry: collections.travelDestinationCountry.filter((el)=>{
        return isAddonPermitted ? noAddOnCountries.indexOf(el.id) === -1 : el
      })
    }

  }

  render() {
    const { policy, notificationType, detailCollections, downloadPolicy,endorsementPermissions ,showPriceUpdateModal,togglePriceUpdateModal,endorsementCheckData} = this.props;
    const { policy: { properties }, paymentDetailForm, endorseModalIsOpen, cancelModalIsOpen, editMode } = this.state;
    let mainInsurerIsAdult;
    if (policy && policy.properties) mainInsurerIsAdult = !!Number(policy.properties.Adults);

    let premiumSidebarModel = adjustAlfalahSideBar(sidebarModel.policy.travelAlfalah, properties);
    const checkPriceModel= lib.removeSidebarBodyItem(sidebarModel.policy.travelAlfalah,[
      {
        parentKey:'Other Details',
        removeParent:true
      },
      {
        parentKey: 'COVID-19 Add On Details',
        removeParent: true
      }
    ])

    return (
      <div className="container-fluid px-0">
        <TitleComponent 
          title="Certificate Details"
          bannerGraphic={getGuoteGraphic}
          backTitle="Back to listing"
          backPath={this.state.backPath}
          history={this.props.history}
        >
        </TitleComponent>       
        <div className="row d-flex justify-content-center my_50" id="quotation-detail">
          <Col sm={11} className="quotation-detail-wrapper">
            { 
              policy === null ? <div className={"loader d-block"} /> :
                (
                  <Row>
                    <Col sm={8}>
                      <DetailTitle.Policy policy={policy}>
                        <h5 className='text-medium-qc font-size-large'>{`Base Policy: ${policy.originalRefCode}`}</h5>
                      </DetailTitle.Policy>
                      {/** -----------------------
                       * temporary until make the reusable editable version for InsuredDetail--------------------- */}
                      {
                        editMode === false || !this.isEndorsementAllowed('non-financial') ? (
                          <InsuredDetail
                            item={{
                              insureds: [{ ...properties }, ...properties['Other Insured']],
                              beneficiaries: properties.Beneficiaries,
                            }}
                            model={DetailModel.travelAlfalah}
                            insuredTypes={TravelAlfalahConfig.insuredTypes}
                          />
                        ) : (
                          <EditableInsuredDetail
                            beneficiaries={properties ? properties['Beneficiaries'][0] : []}
                            beneficiaryIndex={0}
                            data={properties}
                            main={true}
                            editMode={editMode}
                            inputChange={this.handleInputChange.bind(this)}
                            beneficiaryChange={this.handleInputBeneficiary.bind(this)}
                            validator={this.validator}
                            policyProperties={policy ? policy.properties : null}
                            uniqueId="main"
                            mainInsurerIsAdult={mainInsurerIsAdult}
                            properties={properties}
                          />
                        )
                      }
                      { editMode === true ? this.renderOtherInsured(properties) : <></> }
                      {/** -------------------------------------------- */}
                      <div className="text-right">
                        { editMode
                          ? (
                            <div>
                              <Button
                                color="muted"
                                className="my-4 mr-4 customLeastbtn"
                                onClick={this.toggleEditMode.bind(this)}
                              >
                                Cancel
                              </Button>
                              <Button
                                color="primary"
                                className="my-4 customPrimarybtn"
                                onClick={()=>{
                                  this.handleSubmit()
                                }}
                              >
                                Update & Reissue Certificate
                              </Button>
                            </div>
                          ) : (
                            <Button
                              color="info"
                              className="my-4 customInfobtn"
                              onClick={this.handleBackToListing.bind(this)}
                            >
                              Back to Listing
                            </Button>
                          )
                        }
                      </div>
                    </Col>
                    <Col sm={4}>
                      { editMode || notificationType === 'error' ? null : (
                        <Dropdown className="mb-3 text-right" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                          <DropdownToggle caret className='btn-action btn-action-primary'>
                            Actions
                          </DropdownToggle>
                          <DropdownMenu>
                            { this.downloadable(policy) ? <DropdownItem onClick={() => downloadPolicy(policy.id)}>Download Certificate</DropdownItem> : <></>}
                            { this.downloadable(policy) === false ? <DropdownItem onClick={() => downloadPolicy(policy.id)}>Download Cancellation Confirmation</DropdownItem> : <></>}
                            { this.reissueable(policy) ? <DropdownItem onClick={this.reissuePolicy}>Reissue Certificate</DropdownItem> : <></> }
                            { this.endorseable(policy) ? <DropdownItem onClick={this.toggleEditMode}>Endorse Certificate</DropdownItem> : <></>}
                            { this.cancelable(policy, ['In Effect']) ? <DropdownItem className='text-danger' onClick={this.toggleCancelModal}>Cancel Certificate</DropdownItem> : <></>}
                          </DropdownMenu>
                        </Dropdown>
                      )}
                      <SidebarCard.Policy
                        model={premiumSidebarModel}
                        status={policy.status}
                        item={this.state.policy}
                        collections={this.filterCollections(detailCollections,this.state.policy)}
                        validator={this.validator}
                        endorseMode={editMode}
                        handleChange={(e,index)=>this.handleInputChange(e,index,'financial')}
                        endorsementPermissions={endorsementPermissions}
                        isEndorsementAllowed={this.isEndorsementAllowed}
                      />
                      <PaymentCard
                        validator={this.validator}
                        resetPaymentDetailsForm={this.resetPaymentDetailsForm}
                        handleSubmit={this.handleSubmitPayment}
                        item={paymentDetailForm.properties}
                        collections={detailCollections}
                        handleInputChange={this.handlePaymentInputChange}
                        notification={this.props.notification}
                      />
                      <RemarksCard
                          properties={policy ? policy.properties : {}}
                          status={policy ? policy.status : ''}
                      />
                    </Col>
                    <EndorseForm
                      properties={properties}
                      isOpen={endorseModalIsOpen}
                      toggleModal={this.toggleEndorseModal.bind(this)}
                      handleInputChange={this.handleInputChange}
                      handleSubmit={this.handleSubmitEndorse.bind(this)}
                      disableSubmit={this.state.sendingRequest}
                      endorseRemarkIsValid={this.state.endorseRemarkIsValid}
                      hasAdditionalEmails
                    />
                    <EndorsementCheckPriceModal
                        isOpen={showPriceUpdateModal}
                        toggleModal={togglePriceUpdateModal}
                        handleSubmit={this.toggleEndorseModal}
                        endorsementCheckData={endorsementCheckData}
                        previousPolicy={policy}
                        sidebar={checkPriceModel}
                    />
                    <CancelForm
                      isOpen={cancelModalIsOpen}
                      toggleModal={this.toggleCancelModal.bind(this)}
                      properties={properties}
                      policy={this.props.policy}
                      hasAdditionalEmails
                    />
                  </Row>
                )
            }
          </Col>
        </div>
      </div>
    );
  }
}

PolicyDetail.defaultProps = {
  policy: undefined
}

function mapStateToProps(state) {
  const {
    policy,
    policyForm,
    notification,
    notificationType,
    policyDetailBlob,
    paymentDetailForm,
    detailCollections,
    showPriceUpdateModal,
    endorsementPermissions,
    endorsementCheckData
  } = state.policyManagement;

  return {
    policy,
    policyForm,
    notificationType,
    notification,
    policyDetailBlob,
    paymentDetailForm,
    detailCollections,
    showPriceUpdateModal,
    endorsementPermissions,
    endorsementCheckData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetail: policyId => dispatch(policyActions.getDetail(policyId)),
    reissuePolicy: policyId => dispatch(policyActions.reissuePolicy(policyId)),
    updatePolicy: (policyId, properties, updatePayment=false) => dispatch(policyActions.updatePolicy(policyId, properties, updatePayment)),
    downloadPolicy: policyId => dispatch(policyActions.downloadPolicy(policyId)),
    clearBlobPolicy: () => dispatch(policyActions.clearBlobPolicy()),
    endorsePermission: () => dispatch(policyActions.hasPermission('Non-Financial Endorsement of Certificates')),
    cancelPermission: () => dispatch(policyActions.hasPermission('Cancel Certificate')),
    cancelInEffectPermission: () => dispatch(policyActions.hasPermission('Cancellation (In-Effect Certificate)')),
    cancelNotInEffectPermission: () => dispatch(policyActions.hasPermission('Cancellation (Not In Effect Certificate)')),
    reissuePermission: () => dispatch(policyActions.hasPermission('Reissue Certificate')),
    clearPolicyDetail: () => dispatch(policyActions.clearPolicyDetail()),
    getStates: (country, param) => dispatch(policyActions.getStates(country, param)),
    notify: (message, type) => dispatch(Notifications.show({ message }, type)),
    togglePriceUpdateModal: () => dispatch(policyActions.togglePriceUpdateModal()),
    checkEndorsement: (newPolicy) => dispatch(
        policyActions.checkEndorsement(newPolicy),
    ),
    endorseConfirm: (endorsementRequestId, remark) => dispatch(
        policyActions.endorseConfirm(endorsementRequestId, remark),
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyDetail);
