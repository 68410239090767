import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { objLib } from '@xc-core/lib';
import { Modal } from '@xc-core/components/core';
import fields from './base-fields';

interface IProps {
  item: IObject
  model: IObject[]
}

const URLModal = (props: { isOpen: boolean, toggle: any, url: string}) => {
  const { isOpen, toggle, url } = props;
  return (
    <Modal
      id='marketing-url'
      title='Full URL'
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className='text-center wordBreak' style={{ maxWidth: '600px' }}>
        {url || ''}
      </div>
    </Modal>
  );
};

const MarketingInfoCard = (props: IProps) => {
  const { item, model } = props;
  const [isURLDialogOpen, setURLDialogOpen] = useState(false);
  const toggle = () => setURLDialogOpen(!isURLDialogOpen);
  const url = objLib.getValueFromPath(item, fields.quotation.marketingURL.id);

  return (
    <div className='sidebarCard'>
      <div>
        <Card className='mt-4 shadow-md border-0' style={{ borderRadius: '10px' }}>
          <CardBody className='px-3'>
            <div className='pt-2 text-primary'>
              <Row>
                <Col sm={9} className='payment-title-qc font-size-normal text-secondary mt-2 mb-2'>
                  Marketing Info
                </Col>
              </Row>
            </div>
            <hr className='my-2' />
            <div className='pt-2'>
              <>
                <Row className='pt-1 pb-3'>
                  <Col sm={6}>URL</Col>
                  <Col className='text-right summary-label-3 d-flex justify-content-end' sm={6}>
                    {
                      !url ? "-" :<button
                          type='button'
                          className='p-0 wordBreak summary-label-2 button-as-link'
                          onClick={() => setURLDialogOpen(true)}
                      >
                        View Full URL
                      </button>
                    }
                  </Col>
                </Row>
                {
                  model.map((m:IObject) => (
                    <Row className='pt-1 pb-3' key={m.id}>
                      <Col sm={6}>{m.label}</Col>
                      <Col className='text-right summary-label-3' sm={6}>
                        { objLib.getValueFromPath(item, m.id) || '-' }
                      </Col>
                    </Row>
                  ))
                }
              </>
            </div>
          </CardBody>
        </Card>
      </div>
      <URLModal
        toggle={toggle}
        isOpen={isURLDialogOpen}
        url={url}
      />
    </div>
  );
};

export default MarketingInfoCard;