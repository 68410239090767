import React from 'react';

const Message = (props: { message: string }) => {
  const { message } = props;
  return (
    <div
      className='d-flex justify-content-center align-items-center empty-products-container flash-message-component'
    >
      <h2>{message}</h2>
    </div>
  );
};

export default Message;
