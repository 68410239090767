/* eslint-disable object-curly-newline */


const fieldsModel: IFieldModel = {
    // Properties
    plan: { id: 'properties.Plan', label: 'Plan', type: 'PlanButton', collectionId: 'priceList' },
    destination: { id: 'properties.Destination', label: 'Destination', type: 'Select', collectionId: 'destinations' },
    tripType: { id: 'properties.Trip Type', label: 'Trip Type', type: 'Select', collectionId: 'tripTypes' },
    insuredType: { id: 'properties.Insured Type', label: 'Insured Type', type: 'Select', collectionId: 'insuredType' },
    adults: { id: 'properties.Adults', label: 'Adults (18-65)', type: 'Input' },
    children: { id: 'properties.Children', label: 'Children (0-17)', type: 'Input' },
    seniorCitizen: { id: 'properties.Senior Citizen', label: 'Senior Citizen (66 >)', type: 'Input' },
    

    


    // Main Application Object
    fullName: { id: 'properties.Main Applicant.Full Name', label: 'Full Name', type: 'Input', placeholder: 'Enter full name' },
    nationality: { id: 'properties.Main Applicant.Nationality', label: 'Nationality', type: 'Select', collectionId: 'nationalities' },
    idType: { id: 'properties.Main Applicant.ID Type', label: 'ID Type', type: 'Select', collectionId: 'idTypes' },
    idNumber: { id: 'properties.Main Applicant.ID Number', label: 'ID Number', type: 'Input', placeholder: 'Enter ID number' },
    dob: { id: 'properties.Main Applicant.DOB', label: 'Date of Birth', type: 'DatePicker', placeholder: 'Select Date of Birth' },
    gender: { id: 'properties.Main Applicant.Gender', label: 'Gender', type: 'Select', collectionId: 'genders' },
    occupation: { id: 'properties.Main Applicant.Occupation', label: 'Occupation', type: 'Input', placeholder: 'Enter occupation' },
    address: { id: 'properties.Main Applicant.Address', label: 'Address', type: 'Input', placeholder: 'Enter address' },
    city: { id: 'properties.Main Applicant.City/Town', label: 'City/Town', type: 'Input', placeholder: 'Enter city/town' },
    postCode: { id: 'properties.Main Applicant.Postcode', label: 'Postcode', type: 'Input', placeholder: 'Enter postcode' },
    state: { id: 'properties.Main Applicant.State/Province/Region', label: 'State/Province/Region', type: 'Select', collectionId: 'cities', placeholder: 'Enter State/Province/Region' },
    country: { id: 'properties.Main Applicant.Country', label: 'Country', type: 'Select', collectionId: 'countries' },
    contactNo: { id: 'properties.Main Applicant.Phone Number', label: 'Contact Number', type: 'Input', placeholder: 'Enter contact number' },
    email: { id: 'properties.Main Applicant.Email Address', label: 'Email Address', type: 'Input', placeholder: 'Enter email address' },
    type: {id: 'properties.Main Applicant.Type', label: '', type: 'Input'},
    adultRadio: {id: 'properties.Adults', label: 'Adult (18-65)', type: 'RadioButton', value: 'Adult',},
    seniorCitizenRadio: {id: 'properties.Senior Citizen', label: 'Senior Citizen (66 >)', type: 'RadioButton', value: 'Senior Citizen'},
    beneficiary: { id: 'properties.Main Applicant.Beneficiary',label: '', type: 'object' },
    beneficiaryName: { id: 'properties.Main Applicant.Beneficiary.Full Name', label: 'Full Name', type: 'Input', placeholder: 'Enter full name' },
    beneficiaryRelation: { id: 'properties.Main Applicant.Beneficiary.Relationship', label: 'Relationship', type: 'Select', collectionId: 'relations' },
    beneficiaryIdType: { id: 'properties.Main Applicant.Beneficiary.ID Type', label: 'ID Type', type: 'Select', collectionId: 'idTypes' },
    beneficiaryIdNumber: { id: 'properties.Main Applicant.Beneficiary.ID Number', label: 'ID Number', type: 'Input', placeholder: 'Enter ID number' },
    beneficiaryEmail: { id: 'properties.Main Applicant.Beneficiary.Email Address', label: 'Email Address', type: 'Input', placeholder: 'Enter email address' },
    beneficiaryPhone: { id: 'properties.Main Applicant.Beneficiary.Phone Number', label: 'Contact Number', type: 'Input', placeholder: 'Enter contact number' },


    // Other Insured
    otherInsured:{id: 'properties.Other Insured',label:'',type: 'array'},
    otherInsuredFullName: { id: 'properties.Other Insured.Full Name', label: 'Full Name', type: 'Input', placeholder: 'Enter full name' },
    otherInsuredNationality: { id: 'properties.Other Insured.Nationality', label: 'Nationality', type: 'Select',  collectionId: 'nationalities' },
    otherInsuredIdType: { id: 'properties.Other Insured.ID Type', label: 'ID Type', type: 'Select', collectionId: 'idTypes' },
    otherInsuredIdNumber: { id: 'properties.Other Insured.ID Number', label: 'ID Number', type: 'Input', placeholder: 'Enter ID number' },
    otherInsuredDob: { id: 'properties.Other Insured.DOB', label: 'Date of Birth', type: 'DatePicker', placeholder: 'Select Date of Birth' },
    otherInsuredGender: { id: 'properties.Other Insured.Gender', label: 'Gender', type: 'Select', collectionId: 'genders' },
    otherInsuredEmail: { id: 'properties.Other Insured.Email Address', label: 'Email Address', type: 'Input', placeholder: 'Enter email address' },
    otherInsuredContactNo: { id: 'properties.Other Insured.Phone Number', label: 'Contact Number', type: 'Input', placeholder: 'Enter contact number' },
    otherInsuredOccupation: { id: 'properties.Other Insured.Occupation', label: 'Occupation', type: 'Input', placeholder: 'Enter occupation' },
    otherInsuredBeneficiaryName: { id: 'properties.Other Insured.Beneficiary.Full Name', label: 'Full Name', type: 'Input', placeholder: 'Enter full name' },
    otherInsuredBeneficiaryRelation: { id: 'properties.Other Insured.Beneficiary.Relationship', label: 'Relationship', type: 'Select', collectionId: 'relations' },
    otherInsuredBeneficiaryIdType: { id: 'properties.Other Insured.Beneficiary.ID Type', label: 'ID Type', type: 'Select', collectionId: 'idTypes' },
    otherInsuredBeneficiaryIdNumber: { id: 'properties.Other Insured.Beneficiary.ID Number', label: 'ID Number', type: 'Input', placeholder: 'Enter ID number' },
    otherInsuredBeneficiaryEmail: { id: 'properties.Other Insured.Beneficiary.Email Address', label: 'Email Address', type: 'Input', placeholder: 'Enter email address' },
    otherInsuredBeneficiaryPhone: { id: 'properties.Other Insured.Beneficiary.Phone Number', label: 'Contact Number', type: 'Input', placeholder: 'Enter contact number' },

    startDate: { id: 'properties.Start Date', label: 'Start Date', type: 'DatePicker', placeholder: 'Set Start date', start: 'properties.Start Date', end: 'properties.End Date' },
    endDate: { id: 'properties.End Date', label: 'End Date', type: 'DatePicker', placeholder: 'Set End date', start: 'properties.Start Date', end: 'properties.End Date' },
    // countryOfOrigin: { id: 'properties.Country of Origin', label: 'Country of origin', type: 'Select', collectionId: 'countries' },
    
    paymentMode: { id: 'properties.Payment Mode', label: 'Mode of Payment', type: 'Select', collectionId: 'paymentMethod' },
    receiptNo: { id: 'properties.Receipt No', label: 'Receipt No', type: 'Input', placeholder: 'Enter receipt no' },
    paymentReceivedBy: { id: 'properties.Payment Received By', label: 'Payment Received By', type: 'Select', collectionId: 'currentOuUsers' },
    // ptoduct code
    // source
    
  };
  
  const mainDetailModel = [
    fieldsModel.fullName,
    fieldsModel.nationality,
    fieldsModel.idType,
    fieldsModel.idNumber,
    fieldsModel.dob,
    fieldsModel.gender,
    fieldsModel.address,
    fieldsModel.city,
    fieldsModel.state,
    fieldsModel.country,
    fieldsModel.postCode,
    fieldsModel.email,
    fieldsModel.contactNo,
    fieldsModel.occupation,
  ];
  
  const otherDetailModel = [
    fieldsModel.otherInsuredFullName,
    fieldsModel.otherInsuredNationality,
    fieldsModel.otherInsuredIdType,
    fieldsModel.otherInsuredIdNumber,
    fieldsModel.otherInsuredDob,
    fieldsModel.otherInsuredGender,
    fieldsModel.otherInsuredEmail,
    fieldsModel.otherInsuredContactNo,
    fieldsModel.otherInsuredOccupation,
    
  ];

  const otherBeneficiaryDetailModel = [
    fieldsModel.otherInsuredBeneficiaryName,
    fieldsModel.otherInsuredBeneficiaryRelation,
    fieldsModel.otherInsuredBeneficiaryIdType,
    fieldsModel.otherInsuredBeneficiaryIdNumber,
    fieldsModel.otherInsuredBeneficiaryEmail,
    fieldsModel.otherInsuredBeneficiaryPhone,
  ]
  const mainBeneficiaryDetail = [
    fieldsModel.beneficiaryName,
    fieldsModel.beneficiaryRelation,
    fieldsModel.beneficiaryIdType,
    fieldsModel.beneficiaryIdNumber,
    fieldsModel.beneficiaryEmail,
    fieldsModel.beneficiaryPhone
  ]
  
  const model = { fieldsModel, mainDetailModel, otherDetailModel,otherBeneficiaryDetailModel, mainBeneficiaryDetail };
  
  export default model;
  