import {bool} from "prop-types";
import React from "react";
import Switch from "react-toolbox/lib/switch/Switch";
import {Col, Input, Label, Row} from "reactstrap";
import config from '@xc-core/config';

type TInputValue= {value:string |boolean,name:string};

const RenderCommissionSetting = (props:any) => {
    const {
        orgDetails,
        collections,
        canEditCommission,
        handleCommission,
        product,
        commissionValidationError
    } = props;

    const showPriceField = () => {
        const commissionType = orgDetails.extendedProperties.commission.type;
        return commissionType === 'Percentage';
    };

    const targetCommissionItem:ICommission | undefined= orgDetails?.extendedProperties?.commissions?.find((el:ICommission)=>{
            return el.product_code === product.productCode
        })

    const isEditable = canEditCommission && orgDetails.extendedProperties?.type !== 'Agency' && targetCommissionItem?.sameRateForAgencyBranches;

    const getCommissionExtendedProperties=(value:string)=>{
        return (collections?.commissionType?.find((el:ICommissionTypeCollection)=> el.name === value) as ICommissionTypeCollection)?.extendedProperties;
    }

    const commissionHandler=(inputValue:TInputValue,currentProduct:any, currentCommission?:ICommission)=>{
        const {value,name}= inputValue;
        let extendedProperty:ICommissionTypeExtendedProperty | undefined;
        const isTypeSelection = name === 'type';
        if(isTypeSelection){
            extendedProperty = getCommissionExtendedProperties(value as string)
        }
        const updatedCommission = {
            ...(currentCommission ?
                currentCommission :
                {
                    product_code:product.productCode,
                    product_name:product.productName,
                    product_slug:product.productSlug,
                }
            ),
            code: isTypeSelection ? value : targetCommissionItem?.code,
            priceField: isTypeSelection ? extendedProperty?.priceField : targetCommissionItem?.priceField,
            [name]: isTypeSelection ? extendedProperty?.type : value
        }
        handleCommission(updatedCommission)
    }

    return (
        product.status === 'permitted' ? <>
            <Col md={8} className={'pb-3 pl-5 pr-3 pt-3'}>
                <div className='commission'>
                    <Label className={'font-weight-bold text-dark'}>Commission Settings</Label>
                    <Row className='align-items-center org-details' style={{marginLeft:-33.5}}>
                        <div className='col-6' style={{paddingLeft:'2.1em'}}>
                            <Label>All Sales from this Organisation</Label>
                        </div>
                        <div style={{fontSize:14}} className='col-6 pl-0 pr-1 d-flex flex-row flex-wrap'>
                            <div className='custom-input-number percentage-container less-half-width'>
                                <input
                                    type='number'
                                    name='value'
                                    data-id=''
                                    id='percentage'
                                    placeholder='Enter a value'
                                    value={targetCommissionItem?.value}
                                    onChange={(e)=>commissionHandler({
                                        value:e.target.value,
                                        name:e.target.name
                                    },product,targetCommissionItem)}
                                    style={{height:'33px'}}
                                    disabled={isEditable}
                                />
                            </div>
                            <div className='select-container d-flex align-items-center justify-content-end more-half-width'>
                                <Input
                                    className='blue-back'
                                    type='select'
                                    name='type'
                                    id='type'
                                    style={{height:'33px'}}
                                    value={targetCommissionItem?.code}
                                    onChange={(e)=>commissionHandler({
                                        value:e.target.value,
                                        name:e.target.name
                                    },product,targetCommissionItem)}
                                    disabled={isEditable}
                                >
                                    <option selected disabled value=''>Select Option</option>
                                    {collections?.commissionType?.map((data:any) => {
                                        return (
                                            <option value={data.name} key={data.code}>
                                                {data.name}
                                            </option>
                                        );
                                    })}
                                </Input>
                                <i
                                    style={{
                                        fontSize:14,
                                        color:'#212529'
                                     }}
                                    className={`icon-downArrow px-2 ${!!isEditable ? 'disabled' : ''}`}
                                />
                            </div>
                            {
                                commissionValidationError.length > 0 && commissionValidationError.find((el:ICommissionErrorMessage)=>{
                                    return el.productName === (targetCommissionItem?.product_name || product.productName);
                                }) ? <div className={'text-danger-step'}>Required</div> : null
                            }
                        </div>
                    </Row>
                </div>
            </Col>
            {
                orgDetails?.extendedProperties?.type === 'Agency' ? <Col>
                    <Row md={12} style={{display:'flex',flexDirection:'row',flexWrap:'nowrap', padding:'2.6em 0 0 1.5em'}}>
                        <Col md={7} className={'p-0'}>
                            <Label className={'custom-text-primary text-dark text-break'}>Same commission rate for all Agency Branches?</Label>
                        </Col>
                        <Col md={2} style={{display:'flex', flexWrap:'nowrap'}}>
                            <Switch
                                theme={{
                                    on: 'switchOn switch',
                                    thumb: 'thumb',
                                    off: 'switch'
                                }}
                                checked={targetCommissionItem === undefined ? false : targetCommissionItem.sameRateForAgencyBranches}
                                // @ts-ignore
                                on={'#ffff'}
                                onChange={(value:boolean)=>commissionHandler({value,name:'sameRateForAgencyBranches'},product,targetCommissionItem)}
                            />
                        </Col>
                    </Row>
                </Col> : null
            }
        </>: null
    );
};

export default RenderCommissionSetting;