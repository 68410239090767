import type from "history";
import React, {useEffect,useState} from 'react';
import {useDispatch} from "react-redux";
import {Button} from "reactstrap";
import {actions} from "../../@redux";
import {Header, Loader} from "../../@xc-core/components/core";
import bannerImg from "../../images/bannerGraphicImages/get-quote.png";

interface IProps{
    history:type.History
}

const policyActions = new actions.PolicyActions();

const FailPage =(props:IProps)=>{
    const {history} = props;
    const [cancellationObj , setCancellationObj]= useState<{
        requestId:string;
        remarks:string
    }>();
    const [loading , setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        const toBeDeletedItem = localStorage.getItem('toBedDeletedPolicies');
        if(!toBeDeletedItem){
            return history.push('/bulkCancellation');
        }

        const item= JSON.parse(toBeDeletedItem || '')
        if(item){
            setCancellationObj({
                requestId:item.requestId,
                remarks:item.remarks
            })
        }

    },[])

    const bulkCancellationConfirm=async ()=>{
        if(!cancellationObj){
            return;
        }
        setIsLoading(true)
        const res:any = await dispatch(policyActions.confirmBulkCancellation(cancellationObj))

        if(res.status === "Confirmed"){
            history.push('/bulkCancellation/complete')
            localStorage.removeItem('toBedDeletedPolicies');
            localStorage.removeItem('policyIds');
            setIsLoading(false)
        }else{
            setIsLoading(false)
        }
    }

    return (
        <>
            <Header
                title='Bulk Cancellation'
                bannerGraphic={bannerImg}
                withBorderBottom
            />
            {loading && <Loader/>}
            <div className={'text-center'} style={{marginTop:'4rem'}}>
                <h1 className={'custom-text-primary text-dark'}>Cancellation Unsuccessful</h1>
                <p className={'mt-3'}>The cancellation could not be processed. Please try again.</p>
                <div>
                    <Button
                        className={'col-1 m-1'}
                        onClick={()=>history.push('/bulkCancellation/previewPage')}
                        style={{
                            borderRadius:5
                        }}
                        outline={true}
                        color={'primary'}
                    >
                        Back
                    </Button>
                    <Button
                        className={'col-1 m-1'}
                        onClick={bulkCancellationConfirm}
                        style={{
                            borderRadius:5
                        }}
                        color={'primary'}
                    >
                        Retry
                    </Button>
                </div>
            </div>
        </>
    )
}

export default FailPage;