import actionTypes from '@redux/actionTypes';
import BaseListingReducer from './baseListing';

class PasswordManagementReducer extends BaseListingReducer<any> {
  public actions: IDictionary<(state: IListState, action: IAction) => object>;

  public initialState: IObject;

  protected actionTypes: IObject;

  constructor() {
    super();
    this.actionTypes = actionTypes.passwordManagement;

    this.initialState = {
      item: {
        active: true,
        minLength: 8,
        gracePeriod: 7,
        maxLength: 20,
        complexityList: [],
        maxIdleDays: 30,
        maxValidDays: 90,
        maxPrevUsageDurationDays: 120,
        maxLoginAttempts: 5,
      },
      isLoading: true,
      error: null,
      accessIsForbidden: false,
      notFound: false,
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IListState, action: IAction) => IListState> => ({
    [this.actionTypes.SET_ITEM]: this.setItem,
    [this.actionTypes.LIST_SET_FORBIDDEN]: this.setForbidden,
    [this.actionTypes.LIST_LOADING]: this.listLoading,
  })

  private setItem = (state: IListState, action: IAction) => ({
    ...state,
    item: action.payload.item,
  });

  private listLoading = (state: IListState, action: IAction) => ({
    ...state,
    isLoading: action.payload,
  });
}
export default new PasswordManagementReducer();
