import React, {useState,useEffect} from 'react';
import { Row, Col } from 'reactstrap';
import { objLib,fieldLib } from '../../@xc-core/lib';
import { model, defaultValues } from '../../@products/HGITravel/config';
import Field from '@containers/getQuote/wizard/field';
import {createMainInsuredForm,
   createMainInsuredBeneficiaryForm,
   createOtherInsuredForm,
   createOtherInsuredBeneficiaryForm} from './PolicyEndorsementForm';


// const ValidationMessages = ({validator, disabled, value='', inputName, validationRules}) => {
//   return (
//     <div>
//       {
//         validator && !disabled
//         && validator.message(inputName, value,
//           validationRules ? `${validationRules}|customRequired` : 'customRequired')
//       }
//     </div>
//   );
// };

const PolicyEndorsement = (props) => {
  const {
    properties,
    collections,
    handleChangeEndorsement,
    errorMessages
  } = props;

  const [typesOrderedList, setTypesOrderedList] = useState([{type:"",index:""}]);
  const [filteredCountries, setFilteredCountries] = useState((collections ? collections.countries : {}));

  
  const { 'Other Insured' : otherInsured, 'Main Applicant': mainApplicant } = properties || {};

  const createTypesOrderedList = (item) =>{
    let typesOrderedList = [];
    const mainType = objLib.getValueWithLodash(item, model.fieldsModel.type.id)
    const otherInsured = objLib.getValueWithLodash(item, model.fieldsModel.otherInsured.id);
    const typesCount = {
      'Adult': mainType === 'Adult' ? 1 : 0,
      'Senior Citizen': mainType === 'Senior Citizen' ? 1 : 0,
      'Children': 0
    }
    typesOrderedList.push({type :mainType, index:typesCount[mainType]})

    if(otherInsured){
      otherInsured.forEach((val)=>{
        typesCount[val['Type']] = typesCount[val['Type']]+1;
        typesOrderedList.push({type:val['Type'],index:typesCount[val['Type']]})
      })
    }


    setTypesOrderedList(typesOrderedList)

  }

  useEffect(()=>{
    createTypesOrderedList({properties})
    
  },[])
  useEffect(()=>{
    if(collections && collections.countries){
      setFilteredCountries(collections.countries.filter((val)=> val.name === 'Nepal'))
    }
  },[collections])
  useEffect(()=>{
    // console.log(errorMessages)
  },[errorMessages])

  // const getDOBRange = (id) =>{
  //   return defaultValues.dobRanges[objLib.getType(properties,id)]
  // }
  const mainApplicantFields = createMainInsuredForm().fields;
  const mainApplicantBeneficiaryFields = createMainInsuredBeneficiaryForm().fields;
  const otherInsuredFields = createOtherInsuredForm().fields;
  const otherInsuredBeneficiaryFields = createOtherInsuredBeneficiaryForm().fields;
  return (
    <Row>
      <Col sm={12}>
        <h4 className='beneficiary-header my-3 p-3'>{`${typesOrderedList[0].type}[${typesOrderedList[0].index}]`} - Main Applicant</h4>
        <Row className='wordBreak'>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Customer Details</div>
            <Row>
              {mainApplicantFields.map((field)=>{
                 const errorMessage = errorMessages.find((error) => error.referral.includes(field.id));
                return <React.Fragment key={field.id}>
                  <Col key={field.id+'label'} className='pt-2 summary-label' sm={6}>{field.label}</Col>
                  <Col key={field.id+'field'} className='pt-2 summary-label-2' sm={6}>
                  <Field
                    item={{properties}}
                    collections={{
                      ...collections,
                      countries : filteredCountries
                    }}
                    model={field}
                    onChange={handleChangeEndorsement}
                    validationError={{ message: errorMessage ? errorMessage.message : '', referral: field.id }}
                   />
                  </Col>
                  </React.Fragment>
                
              })}
             
            </Row>
          </Col>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Beneficiary Details</div>
            <Row className='wordBreak'>
              {mainApplicantBeneficiaryFields.map((field)=>{
                  const errorMessage = errorMessages.find((error) => error.referral.includes(field.id));
                  return <React.Fragment key={field.id}>
                    <Col key={field.id+'label'} className='pt-2 summary-label' sm={6}>{field.label}</Col>
                    <Col key={field.id+'field'} className='pt-2 summary-label-2' sm={6}>
                    <Field
                      item={{properties}}
                      collections={collections}
                      model={field}
                      onChange={handleChangeEndorsement}
                      validationError={{ message: errorMessage ? errorMessage.message : '', referral: field.id }}
                    />
                    </Col>
                    </React.Fragment>
                })}
            </Row>
          </Col>
        </Row>
      </Col>
      {
        otherInsured && otherInsured.map((other,index) => (
          
          <Col sm={12} key={index}>
            <h4 className='beneficiary-header my-3 p-3'>{typesOrderedList[index+1] ? `${typesOrderedList[index+1].type} [${typesOrderedList[index+1].index}]` : ''}</h4>
            <Row className='wordBreak'>
              <Col sm={6}>
                <div className='custom-q-text-primary bold my-2'>Customer Details</div>
                <Row>
                {otherInsuredFields && fieldLib.addIndexToFields(otherInsuredFields,'Other Insured',index).map((field)=>{
                  const errorMessage = errorMessages.find((error) => error.referral.includes(field.id));
                   return <React.Fragment key={field.id}>
                     <Col key={field.id+'label'} className='pt-2 summary-label' sm={6}>{field.label}</Col>
                     <Col key={field.id+'field'} className='pt-2 summary-label-2' sm={6}>
                     <Field
                       item={{properties}}
                       collections={collections}
                       model={field}
                       onChange={handleChangeEndorsement}
                       validationError={{ message: errorMessage ? errorMessage.message : '', referral: field.id }}
                     />
                     </Col>
                     </React.Fragment>
                })
                }
                </Row>
              </Col>
              <Col sm={6}>
                <div className='custom-q-text-primary bold my-2'>Beneficiary Details</div>
                <Row className='wordBreak'>
                {fieldLib.addIndexToFields(otherInsuredBeneficiaryFields,'Other Insured',index).map((field)=>{
                  const errorMessage = errorMessages.find((error) => error.referral.includes(field.id));
                   return <React.Fragment key={field.id}>
                     <Col key={field.id+'label'} className='pt-2 summary-label' sm={6}>{field.label}</Col>
                     <Col key={field.id+'field'} className='pt-2 summary-label-2' sm={6}>
                     <Field
                       item={{properties}}
                       collections={collections}
                       model={field}
                       onChange={handleChangeEndorsement}
                       validationError={{ message: errorMessage ? errorMessage.message : '', referral: field.id }}
                     />
                     </Col>
                     </React.Fragment>
                })
                }
                </Row>
              </Col>  
            
            </Row>
          </Col>
        ))
      }
    </Row>
  );
}

export default PolicyEndorsement;
