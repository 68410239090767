import env from 'env';
import config from "../config";
import BaseListingService from './baseListing';
import Request from './request';
import Response from './response';

class ProductService extends BaseListingService {
  protected env = env.quotation;

  protected api = 'products';

  protected withApi = '';

  public getList = async (): Promise<any> => {
    const url = `${this.env}/${this.api}/active`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data.list; // response should be a list rather than an object and
    // this list should be removed from response
  };

  public getApiDocumentation = async (): Promise<any> => {
    const url = `${this.env}/${this.api}/api-documentation`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public getQuotationValidationModule = async (id: number): Promise<string> => {
    const url = `${this.env}/${this.api}/${id}/validation-module`;
    const request = new Request();
    const response = new Response(url, request, 'text');
    const result = await response.handle();
    return result.data;
  }

  public getTemplateByProductCode = async (type:'policy' | 'quotation',productCode: string): Promise<any>=> {
    const url = `${type === 'policy' ? `${env.policy}/policy-templates` : `${env.quotation}/quotationtmplts`}/template/${productCode}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public async updateProductTemplateJSON(type:'policy' | 'quotation', obj:IObject): Promise<any> {
    const url = `${type === 'policy' ? `${env.policy}/policy-templates` : `${env.quotation}/quotationtmplts`}/template/${obj.productCode}`;
    const request = new Request(obj, 'PUT');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
  public getAssetsByProductSlug = async (productSlug: string): Promise<any>=> {
    const url = `${env.policy}/assets/list/${productSlug}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
  public uploadAssetsOfProduct = async (body: IObject): Promise<any>=> {
    const url = `${env.policy}/assets`;
    const request = new Request(body, 'POST', {
      'content-type': undefined
    });    
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
  public getAssetsOfProduct = async (productSlug: string, assetName: string): Promise<any>=> {
    const url = encodeURI(`${env.policy}/assets/${productSlug}/${assetName}`);
    const request = new Request(null, 'GET');
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }
  public deleteAssetsOfProduct = async (productSlug: string, assetName: string): Promise<any>=> {
    const url = encodeURI(`${env.policy}/assets/${productSlug}/${assetName}`);
    const request = new Request(null, 'DELETE');
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }
}

export default ProductService;
