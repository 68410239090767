import {handleRequest, handleResponse} from "../../../../_services";
import {getRequestOptions} from "../../../../utils";
import { utils } from "utils";

const Config = require('_config/env');
const apiUrl = Config.env.quotationUrl;

export const quotationServices = {
  getQuotationDetail,
  getQuotationList,
  applyFilter,
  exportToCsv,
};

async function getQuotationList(page, perPage, productCode) {
  const response = await fetch(
    `${apiUrl}/quotations/with-collections?page=${page}&pageSize=${perPage}&productCode=${productCode}`,
    getRequestOptions()
  );
  return await handleResponse(response);
}

async function exportToCsv({ pii, stringParameters, currentProduct }) {
  let url = `${apiUrl}/quotations/export?export_pii=${pii}&${stringParameters}`;
  if (currentProduct && currentProduct.id) {
    url += `&productCode=${currentProduct.id}`;
  }
  let fileName = "";
  const response = await handleRequest(url, getRequestOptions());
  const blobResponse = await handleResponse(response, true);
  const contentDisposition = response.headers.get("Content-Disposition");

  fileName = contentDisposition && contentDisposition.indexOf("filename=")
    ? contentDisposition.split("filename=")[1] : fileName;
  fileName = fileName.replace(/"/g, "");
  if (utils.lib.isEdge()) {
    const isQuotation = true;
    fileName = utils.lib.generateCsvFileName(isQuotation, pii, currentProduct.orgUnit);
  }
  return { fileName, blobResponse };
}

function getQuotationDetail(quotationId) {
  return fetch(`${apiUrl}/quotations/${quotationId}/with-collections`, getRequestOptions())
    .then(async serverResponse => {
      return await handleResponse(serverResponse);
    })
}

async function applyFilter(params, productCode) {
  const url = `${apiUrl}/quotations/search?${params}&productCode=${productCode}`;
  const response = await handleRequest(url, getRequestOptions());
  const handledResponse = await handleResponse(response);
  return handledResponse;
}
