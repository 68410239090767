
import React from 'react';
import { Col, Row } from 'reactstrap';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';

const PaymentDetail = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
    },
  } = props;
  return (
    <Row>
      <Col md='12'>
        <h5 className='sub-title'>Fill in payment details (optional)</h5>
        <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={collections}
          item={item}
          validationErrors={validationErrors}
          fields={model.paymentDetail.fields}
        />
      </Col>
    </Row>
  );
};

export default PaymentDetail;
