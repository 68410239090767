
import {store} from '../../_helpers/store';
import Notifications from 'react-notification-system-redux';
import { getNotificationMessage } from '../../_services';

class ResponseError extends Error {
  status: number;

  path: string;

  timestamp: number;

  url: string;

  constructor(error: any, url = '') {
    super(error.message);
    this.status = error.status;
    this.path = error.path;
    this.timestamp = error.timestamp;
    this.url = url;
  }

  parse = (): IError => ({
    url: this.url,
    message: this.message,
    status: this.status,
    path: this.path,
    timestamp: this.timestamp,
  } as IError);
}

interface IRule {
  status: number | string,
  error : number | string
}
interface IException {
  handler: Function,
  rules: IObject[]
}

const handleError = (e:any,exceptions?:IException[]) => {
  const { status, message} = e;
  let anyRulMatched = 0;
  if(exceptions){
    anyRulMatched = exceptions.reduce((result, exception)=>{
      const ruleMatched = exception.rules.some((rule)=>{
        let matched = true;
        for (let key of Object.keys(rule)) {
          if(!e[key] || e[key] !== rule[key]){
            matched = false;
          }
        }
        return matched;  
      })
      if(ruleMatched){
        exception.handler(e);
        return result+1;
      } else {
        return result
      }
    },0)
  }
  if(anyRulMatched === 0){
    if(status === 403){
      // @ts-ignore
      store.dispatch(Notifications.error(getNotificationMessage('You are not authorized to perform this action')))
    } else if(status === 400){
      // @ts-ignore
      store.dispatch(Notifications.error(getNotificationMessage(message)))
    } else if (status > 403){
      // @ts-ignore
      store.dispatch(Notifications.error(getNotificationMessage('We are sorry something went wrong')))
    }
  }
  return e;

}

// eslint-disable-next-line import/prefer-default-export
export { ResponseError, handleError };
