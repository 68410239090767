import React from 'react';
import { History } from 'history';
import {Details} from '../../../@containers/quotation'
import HomeInsurance from 'models/quotation/homeInsurance';
import {sidebarModel} from 'models';

interface IProps{
    history:History;
    backPath:string;
    quotation: any;
    sidebarModel:any;
    editQuotation:()=>void;
}

const QuotationDetail =(props:IProps)=>{
    const {quotation} = props
    const model = HomeInsurance.body;

    return (
        <>
            <Details
                {...props}
                type={"quotation"}
                data={quotation}
                sidebarModel={sidebarModel.quotation.homeInsurance}
                components={[
                    {
                        title:'Applicant Details',
                        model:model.items.mainApplicant,
                        type:'primary'
                    },
                    {
                        title:'Insured Property',
                        model:model.items.insuredProperty,
                        type:'secondary'
                    }
                ]}
                productIcon={'icon-home-insurance'}
            />
        </>
    )
}

export default QuotationDetail;