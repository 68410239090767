import actionTypes from '@redux/actionTypes';
import BaseReducer from './base';

class UserCreateReducer extends BaseReducer<IInitialState> {
  public actions: IDictionary<(state: IInitialState, action: IAction) => IInitialState>;

  public initialState: IInitialState;

  protected actionTypes: IUserActionTypes;

  constructor() {
    super();

    this.actionTypes = actionTypes.user;

    this.initialState = {
      isLoading: true,
      error: null,
      accessIsForbidden: false,
      item: null,
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IInitialState,
    action: IAction) => IInitialState> => ({
    [this.actionTypes.ENTITY_LOADING]: this.setLoading,
    [this.actionTypes.ENTITY_FAILED]: this.setError,
    [this.actionTypes.ENTITY_CREATE]: this.create,
    // [this.actionTypes.ENTITY_UPDATE]: this.update,
    // [this.actionTypes.ENTITY_GET]: this.get,
  })

  protected create = (state:IInitialState):IInitialState => ({
    ...state,
    isLoading: false,
    error: null,
  })
}

export default UserCreateReducer;
