
import React from "react";
import { Form, FormGroup, Button } from 'reactstrap';
import { ModalUi } from "../../_components";

const DeleteModal = (props:IObject) =>{
    const {isOpen, toggleModal, handleDelete, record} = props;

    return (
        <ModalUi isOpen={isOpen} toggle={() => toggleModal()}>
          <Form>
            <FormGroup>
              <div className="d-flex justify-content-center">You are deleting below collection, are you sure?</div>
              <br />
              <div data-test="name">Name: {record.name}</div>
              <div data-test="code">Code: {record.code}</div>
            </FormGroup>
            <FormGroup className="text-right mb-0">
              <Button data-test="no-btn" color="muted" className="mr-3 customLeastbtn" onClick={() => toggleModal()} >No</Button>
              <Button data-test="delete-btn" onClick={() => handleDelete(record)} className="customPrimarybtn" color="primary">Delete</Button>
            </FormGroup>
          </Form>
        </ModalUi>
      )
}

export default DeleteModal;