import { UserService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import {
  requestLib,
  filterLib,
} from '@xc-core/lib';
import BaseListAction from './baseListing';

class UserAction extends BaseListAction {
  constructor() {
    super();
    this.service = new UserService();
  }

  protected actions = actionTypes.user;

  protected service: UserService;

  protected csvExportScope = '';

  protected editScope = 'Edit User';

  protected createScope = 'Create User';

  public setList = (params: IRequestParam) => async (dispatch: any, getState: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      const { filters } = getState().userList;
      const stringParams = requestLib.convertToRequestParams(params);
      let result = await this.service.getList(stringParams);
      const initialFilters = filterLib.parse(result.filters, params);
      const fields = this.createFields();
      result = {
        ...result,
        list: this.prepareList(result.list.content),
        fields,
        initialFilters,
        filters: filters.length > 0 ? filters : initialFilters,
        prevFilters: filters.length > 0 ? filters : initialFilters,
        pageInfo: this.createPageInfo(result.list),
        searchKeyword: params.name ? params.name[0] : '',
      };
      dispatch(this.createAction<IObject>(this.actions.LIST_GET, result));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      if (error.status === 404) {
        dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }

  public getSearchParams = () => (dispatch: any, getState: any): IRequestParam => {
    const { userList } = getState();
    return this.getSearchParamsByItem(userList);
  }

  public setHasRecordsInitially= () => async (dispatch: any, getState: any) => {
    const { list } = getState().userList;
    const hasRecords = list && list.length > 0;
    dispatch(this.createAction<boolean>(this.actions.LIST_SET_HAS_RECORDS_INITIALLY, hasRecords));
  }

  private prepareList = (list: IObject[]): IObject[] => {
    const prepared = this.setActiveValueFor(list, 'isActive');
    return prepared;
  };

  private createFields = (): IPair[] => ([
    { id: 'name', value: 'Name' },
    { id: 'email', value: 'Email' },
    { id: 'orgUnit', value: 'Organisation' },
    { id: 'groups', value: 'User Group' },
    { id: 'isActive', value: 'Status' },
  ]);
}

export default UserAction;
