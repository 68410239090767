
// import {handleRequest , handleResponse} from '../../../_services';
import {getRequestOptions} from '../../../utils';
import { env } from '_config/env';

const api = env.policyUrl;

const fetchDashboard = () => {
    const url = `${api}/policies/dashboard`;
      return fetch(url,getRequestOptions())
      .then(handleResponse)
      .then(data => {
        return data;
      }, error => {
        console.log(`error in fetchDashboard ${error}`)});
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    return data;
  });
}

export default { fetchDashboard }