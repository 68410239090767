/* eslint-disable object-curly-newline */

import lib from "utils/lib";


const fieldsModel: IFieldModel = {
  sumAssured: { id: 'properties.Sum Assured', label: '', ext: ' / ', type: 'PriceRange', placeholder: `Sum Assured ${lib.checkIsDemoSite() ? '/ ' : ''}` },
  engineCC: { id: 'properties.Engine CC', label: 'Engine CC', ext: ' / ', type: 'Input', placeholder: `Enter engine CC ${lib.checkIsDemoSite() ? '/ ' : ''}` },  
  plan: { id: 'properties.Plan', label: 'Plan', ext: ' / ', type: 'Select', collectionId: 'plans', placeholder: `Select Plan ${lib.checkIsDemoSite() ? '/ ' : ''}` },  
  additionalDriverAddon: {id: 'properties.Additional Driver Addon', label: 'Additional Driver Addon', type: 'Select',collectionId:"additionalDriverAddon"},
  noOfAdditionalDrivers: { id: 'properties.Number of Additional Drivers', label: 'No. of Additional Drivers', ext: ' / ', type: 'Select',collectionId:"noOfAdditionalDrivers", placeholder: `Enter No. of Additional Drivers ${lib.checkIsDemoSite() ? '/ ' : ''}` },

  fullName: { id: 'properties.main applicant.Full Name', label: 'Full Name', ext: ' / ឈ្មោះពេញ', type: 'Input', placeholder: `Enter full name ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះពេញ' : ''}` },
  idType: { id: 'properties.main applicant.ID Type', label: 'ID Type', ext: ' / ប្រភេទអត្តសញ្ញាណបណ្ណ', type: 'Select', collectionId: 'idTypes', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសប្រភេទអត្តសញ្ញាណបណ្ណ' : ''}` },
  idNumber: { id: 'properties.main applicant.ID Number', label: 'ID Number', ext: ' / លេខអត្តសញ្ញាណបណ្ណ', type: 'Input', placeholder: `Enter ID number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខអត្តសញ្ញាណបណ្ណ' : ''}` },
  dob: { id: 'properties.main applicant.DOB', label: 'Date of Birth', ext: ' / ថ្ងៃខែឆ្នាំកំណើត', type: 'DatePicker', placeholder: `Select Date of Birth ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសថ្ងៃខែឆ្នាំកំណើត' : ''}` },
  gender: { id: 'properties.main applicant.Gender', label: 'Gender', ext: ' / ភេទ', type: 'Select', collectionId: 'genders', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសភេទ' : ''}` },

  address: { id: 'properties.main applicant.Address', label: 'Address', ext: ' / អាសយដ្ឋាន', type: 'Input', placeholder: `Enter address ${lib.checkIsDemoSite() ? '/ សូមបំពេញអាសយដ្ឋាន' : ''}` },
  country: { id: 'properties.main applicant.Country', label: 'Country', ext: ' / ប្រទេស', type: 'Select', collectionId: 'countries' },
  state: { id: 'properties.main applicant.State', label: 'State/Province/Region', ext: ' / សូមបំពេញ ក្រុង/ខេត្ត/តំបន់', type: 'Input', placeholder: 'Enter State/Province/Region' },
  postCode: { id: 'properties.main applicant.Postcode', label: 'Postcode', ext: ' / លេខកូដតំបន់', type: 'Input', placeholder: `Enter postcode ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខកូដតំបន់' : ''}` },
  city: { id: 'properties.main applicant.City', label: 'City/Town', ext: ' / ទីក្រុង', type: 'Input', placeholder: `Enter city/town ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះទីក្រុង' : ''}` },
  email: { id: 'properties.main applicant.Email Address', label: 'Email Address', ext: ' / អាសយដ្ឋានអ៊ីមែល', type: 'Input', placeholder: `Enter email address ${lib.checkIsDemoSite() ? '/ សូមបំពេញអាសយដ្ឋានអ៊ីមែល' : ''}` },
  mobileNo: { id: 'properties.main applicant.Mobile Number', label: 'Mobile Number', ext: ' / លេខទូរសព្ទ័ទំនាក់ទំនង', type: 'Input', placeholder: `Enter contact number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខទូរសព្ទ័ទំនាក់ទំនង' : ''}` },

  additionalDrivers: { id: 'properties.additional drivers', label: '', type: 'array' },
  additionalDriverName: { id: 'properties.additional drivers[_#_].Full Name', label: 'Full Name', ext: ' / ឈ្មោះពេញ', type: 'Input', placeholder: `Enter full name ${lib.checkIsDemoSite() ? '/ សូមបំពេញឈ្មោះពេញ' : ''}` },
  additionalDriverIdType: { id: 'properties.additional drivers[_#_].ID Type', label: 'ID Type', ext: ' / ប្រភេទអត្តសញ្ញាណបណ្ណ', type: 'Select', collectionId: 'idTypes', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសប្រភេទអត្តសញ្ញាណបណ្ណ' : ''}` },
  additionalDriverIdNumber: { id: 'properties.additional drivers[_#_].ID Number', label: 'ID Number', ext: ' / លេខអត្តសញ្ញាណបណ្ណ', type: 'Input', placeholder: `Enter ID number ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខអត្តសញ្ញាណបណ្ណ' : ''}` },
  additionalDriverDob: { id: 'properties.additional drivers[_#_].DOB', label: 'Date of Birth', ext: ' / ថ្ងៃខែឆ្នាំកំណើត', type: 'DatePicker', placeholder: `Select Date of Birth ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសថ្ងៃខែឆ្នាំកំណើត' : ''}` },

  paymentMode: { id: 'properties.Payment Mode', label: 'Mode of Payment', ext: ' / មធ្យោបាយនៃការបង់ប្រាក់', type: 'Select', collectionId: 'paymentMethod', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសមធ្យោបាយនៃការបង់ប្រាក់' : ''}` },
  receiptNo: { id: 'properties.Receipt No', label: 'Receipt No', ext: ' / លេខវិក្កយប័ត្រ', type: 'Input', placeholder: `Enter receipt no ${lib.checkIsDemoSite() ? '/ សូមបំពេញលេខវិក្កយប័ត្រ' : ''}` },
  paymentReceivedBy: { id: 'properties.Payment Received By', label: 'Payment Received By', ext: ' / ធ្វើការទូទាត់ដោយ', type: 'Select', collectionId: 'currentOuUsers', placeholder: `Select Option ${lib.checkIsDemoSite() ? '/ ជ្រើសរើសជម្រើសនៃការទូទាត់' : ''}` },
};

const mainDetailModel = [
  fieldsModel.fullName,
  fieldsModel.idType,
  fieldsModel.idNumber,
  fieldsModel.dob,
  fieldsModel.gender,
  fieldsModel.address,
  fieldsModel.city,
  fieldsModel.state,
  fieldsModel.country,
  fieldsModel.postCode,
  fieldsModel.email,
  fieldsModel.mobileNo,
];

const additionalDriverModel = [
  fieldsModel.additionalDriverName,  
  fieldsModel.additionalDriverIdType,
  fieldsModel.additionalDriverIdNumber,
  fieldsModel.additionalDriverDob,
];

const model = { fieldsModel, mainDetailModel, additionalDriverModel };

export default model;
