import React, { createRef } from 'react';
import * as reactstrap from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateLib, eventLib } from '@xc-core/lib';
import { BaseComponent } from '@xc-core/components';
import config from '@xc-core/config';
import {createPortal} from 'react-dom';

class CustomDatePicker extends BaseComponent<IDatePickerFormControlModel, {}> {
  private datePickerRef = createRef<DatePicker>();

  openCalendar = (): void => {
    const datePicker = this.datePickerRef.current;
    if (datePicker) {
      datePicker.setOpen(true);
    }
  }

  onChange = (date: any) => {
    const { handler, id } = this.props;
    const value = dateLib.toString(date, config.dateMomentFormat);
    const pair = eventLib.getPair({ target: { id, value } });
    handler(pair);
  }

  toDate = (value: string) => {
    const date = dateLib.toDate(value, config.dateMomentFormat);
    // because the date picker component accept date | undefined as an acceptable type
    return date || undefined;
  };

  render() {
    const {
      className, id, disabled, value,
      min, max, start, end, openTo, validator, placeholder,
      name, customMessage, rules, validationMessage, lefIcon = false,
      container
    } = this.props;
    return (
      <div className={`xc-date-picker mb-0 ${className}`}>
        <reactstrap.InputGroup>
          {lefIcon
            ? (
              <reactstrap.InputGroupAddon addonType='prepend'>
                <reactstrap.InputGroupText
                  className='dateIconStyleLeft'
                  onClick={this.openCalendar}
                >
                  <i className='icon-calendar' />
                </reactstrap.InputGroupText>
              </reactstrap.InputGroupAddon>
            )
            : ''}
          <DatePicker
            id={id}
            ref={this.datePickerRef}
            disabled={disabled || false}
            className={`${lefIcon ? 'datePickerBorderRight' : 'datePickerBorderLeft'} ${className}`}
            placeholderText={placeholder}
            selectsStart
            selected={this.toDate(value)}
            startDate={this.toDate(start)}
            endDate={this.toDate(end)}
            minDate={this.toDate(min)}
            maxDate={this.toDate(max)}
            openToDate={this.toDate(openTo)}
            onChange={(date: Date) => this.onChange(date)}
            dateFormat={config.datePickerFormats}
            showDisabledMonthNavigation
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            popperContainer={container ? ({children}) => createPortal(children,document.body) : undefined}
          />
          {!lefIcon
            ? (
              <reactstrap.InputGroupAddon addonType='prepend'>
                <reactstrap.InputGroupText
                  className='dateIconStyle'
                  onClick={this.openCalendar}
                >
                  <i className='icon-calendar' />
                </reactstrap.InputGroupText>
              </reactstrap.InputGroupAddon>
            ) : ''}
        </reactstrap.InputGroup>
        {
        validator
          ? validator.validateFieldAndGetMessage({
            name,
            value,
            rules: rules || [],
            customMessage,
          }) : ''
        }
        {
          validationMessage ? <div className='text-danger-step'>{validationMessage}</div> : <></>
        }
      </div>
    );
  }
}
export default CustomDatePicker;
