import { FiscalYearService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import Notifications from 'react-notification-system-redux';
import { dateLib, handleError } from '@xc-core/lib';
import { getNotificationMessage } from '../../_services';
import BaseAction from './base';

class FiscalYearAction extends BaseAction {
    protected createScope: string = '';

    protected editScope: string = '';

    protected service: FiscalYearService;

    protected actions = actionTypes.fiscalYear;

    constructor() {
      super();
      this.service = new FiscalYearService();
    }

    public getList = () => async (dispatch: Function) => {
      try{
        const results = await this.service.getList();
        if (!results.error) {
          dispatch(this.createAction(this.actions.GET_LIST, results));
        }
      } catch(e){
        handleError(e)
      }
      
    }

    public toggleEdit = (index: number) => async (dispatch: Function) => dispatch(
      this.createAction(this.actions.TOGGLE_EDIT, { index }),
    );

  public handleChange = (pair: IPair) => async (dispatch: Function) => {
    dispatch(this.createAction(this.actions.HANDLE_CHANGE, { pair }));
  }

    public addFiscalYear = () => async (dispatch: Function) => {
      dispatch(this.createAction(this.actions.ADD_FISCAL_YEAR));
    }

    public saveRecord = () => async (dispatch: Function, getState: Function) => {
      const { fiscalYear } = getState();
      const { editItem, records, isEditMode, editIndex } = fiscalYear;
      let {
        startDate: startDateItem, endDate: endDateItem,
        first: firstItem, second: secondItem,
      } = editItem;

      if (!records.length) {
        // @ts-ignore
        return dispatch(Notifications.error(getNotificationMessage('Please add at least 1 Fiscal Year.')));
      }
      const isCreate = !(records[records.length - 1].id);
      if (!isEditMode) {
        return null;
      }
      
      let newRecord;
      if(isCreate){
        newRecord = records[records.length - 1];
      } else {
        newRecord = records[editIndex]
      }
      if (startDateItem === '') startDateItem = newRecord.startDate;
      if (endDateItem === '') endDateItem = newRecord.endDate;
      // eslint-disable-next-line prefer-destructuring
      if (firstItem === '') firstItem = newRecord.fiscalYear.split('/')[0];
      // eslint-disable-next-line prefer-destructuring
      if (secondItem === '') secondItem = newRecord.fiscalYear.split('/')[1];
      const newStartDateObj = dateLib.toDate(startDateItem, dateLib.config.dateMomentFormat);
      const newEndDateObj = dateLib.toDate(endDateItem, dateLib.config.dateMomentFormat);
      if (firstItem.length !== 2 || secondItem.length !== 2) {
        // @ts-ignore
        return dispatch(Notifications.error(getNotificationMessage('Fiscal year should be at least 2 characters.')));
      }
      if (newStartDateObj && newEndDateObj && (newStartDateObj > newEndDateObj)) {
        // @ts-ignore
        return dispatch(Notifications.error(getNotificationMessage('Start date should be before end date.')));
      }
      const errorMessages: Set<String> = new Set<String>();
        records.forEach((record:any,i:number) => {
          if(i === editIndex){
            return
          }
        const { endDate, fiscalYear: fiscalYearRecord, startDate } = record;
        const [start, end] = fiscalYearRecord.split('/');
        const startDateObj = dateLib.toDate(startDate, dateLib.config.dateMomentFormat);
        const endDateObj = dateLib.toDate(endDate, dateLib.config.dateMomentFormat);
        if ((start < firstItem && firstItem < end) || (start < firstItem && secondItem < end)) {
          errorMessages.add('New fiscal Year should not intersect with existing ones.');
        }
        if (
          (startDateObj && newStartDateObj && endDateObj && newEndDateObj)
          && ((startDateObj <= newStartDateObj && newStartDateObj <= endDateObj)
          || (startDateObj <= newEndDateObj && newEndDateObj <= endDateObj))
        ) {
          errorMessages.add('New Start dates and End dates should not intersect with existing ones.');
        }
      });
      if (errorMessages.size) {
        errorMessages.forEach((message) => {
          // @ts-ignore
          dispatch(Notifications.error(getNotificationMessage(message)));
        });
        return null;
      }
      const finalItem = {
        description: null,
        fiscalYear: `${firstItem}/${secondItem}`,
        startDate: startDateItem,
        endDate: endDateItem,
      };
      let response: IObject = {};
      try {
        if (isCreate) {
          response = await this.service.createFiscalYear(finalItem);
          if (response.error) {
            // @ts-ignore
            return dispatch(Notifications.error(getNotificationMessage(response.message)));
          }
        } else {
          response = await this.service.updateFiscalYear(finalItem, newRecord.id);
          if (response.error) {
            // @ts-ignore
            return dispatch(Notifications.error(getNotificationMessage(response.message)));
          }
        }
        // @ts-ignore
        dispatch(Notifications.success(getNotificationMessage(response.message)));
        return dispatch(this.createAction(this.actions.SAVE_RECORD, response));
      } catch (e) {
        handleError(e)
      }
    }

    public resetFiscalYearDetails = () => (dispatch: Function) =>{
      dispatch(this.createAction(this.actions.RESET_FISCAL_YEAR_DETAILS));
    }
}


export default new FiscalYearAction();
