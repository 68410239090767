import React from 'react';
import {Row} from 'reactstrap';
import Details from "../../../@containers/Details/details";

const Summary = (props: ILoadableComponentProps) => {
    const {
        model: {
            item,
            model,
            isKhmer,
            sidebar,
            productIcon
        }
    } = props;

    const generateDetailModel = (fields: IField[]) => {
        return {
            items: fields.map((el: IField) => {
                return {
                    id: el.id,
                    name: el.label,
                    config: {}
                }
            })
        }
    }
    return (
        <Row>
            <Details
                editQuotation={() => {
                }}
                {...props}
                type={"summary"}
                data={item}
                sidebarModel={sidebar}
                components={[
                    {
                        title: `Basic Details`,
                        model: generateDetailModel(model.basicDetailFields.fields),
                        type: 'primary'
                    },
                    {
                        title: `Applicant Details`,
                        model: generateDetailModel(model.insuredDetailFields.fields.filter((el: IField) => el.group === 'applicantDetails')),
                        type: 'primary'
                    },
                    {
                        title: `Mailing Address`,
                        model: generateDetailModel(model.insuredDetailFields.fields.filter((el: IField) => el.group === 'mailingAddress')),
                        type: 'primary'
                    },
                    {
                        title: 'Insured Property',
                        model: generateDetailModel(model.insuredPropertyFields.fields),
                        type: 'secondary'
                    }
                ]}
                productIcon={productIcon}
                isKhmer={isKhmer}
            />
        </Row>
    );
};

export default Summary;
