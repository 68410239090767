import Notifications from "react-notification-system-redux";
import filterUtil from "_components/filter/utils";
import { usersServices } from '../services';
import { getNotificationMessage } from "../../../_services";
import { usersConstants } from '../constants';
import { HANDLE_FILTER_CLICKED } from "../../../_constants";

function updateList(list,type,notification) {
  return {
    type: type,
    list,
    notification
  }
}

function updateNotification(){
  return{
    type: usersConstants.USER_REMOVE_NOTIFICATION,
    notification: "",
  }
}

function getList(page, perPage) {
  return async (dispatch) => {
    const list = await usersServices.getUsersList(page, perPage);
    if (list.message === "Forbidden") return dispatch({ type: usersConstants.SET_FORBIDDEN_USERS });
    if (list.error) {
      return dispatch(Notifications.error(getNotificationMessage("An error occurred getting users.")));
    }
    list.filters = filterUtil.addExtraPropertiesToFilters(list.filters);
    return dispatch(updateList(list, usersConstants.GET_USERS_LIST, ""));
  };
}

function createUsers(data) {
  return async (dispatch) => {
    const response = await usersServices.createUser(data);
    if (response.error) {
      dispatch(Notifications.error(getNotificationMessage(response.message)));
    } else {
      dispatch(Notifications.success(getNotificationMessage("User added successfully.")));
    }
    return response;
  }
}


function getUserDetails(name) {
  return async (dispatch) => {
    const data = await usersServices.getUsersDetails(name);
    if (!data.error) return dispatch(showDetails(data));
  };

  function showDetails(data) {
    return {
      type: usersConstants.GET_USERS_DETAILS,
      data,
    }
  }
}

function applyFilters(filterObjectParams){
  return dispatch => usersServices.applyFilter(filterObjectParams)
    .then(
      response => {
        let updatedResponse= {...response,...{filter: filterObjectParams.filterObject}};
        if (!updatedResponse.error) return dispatch(updateList(updatedResponse,usersConstants.USER_APPLY_FILTER))
      })
}

function updateUser(name,data){
  return async dispatch => {
    try{
      const res = await usersServices.updateUserDetails(name,data);
      if(res.error){
        throw res
      }
      return res;
    } catch(e){
      return Promise.reject(e)
    }
  }
}

function resendActivation(username) {
  return async dispatch => {
    dispatch(Notifications.info(getNotificationMessage('Please wait...')));
    const response = await usersServices.resendActivation(username);
    if (response.status === "success") return dispatch(
      Notifications.success(getNotificationMessage('Activation email resent!'))
    );
    //else return dispatch(Notifications.error(getNotificationMessage('Something went wrong.')));
    else return dispatch(Notifications.error(getNotificationMessage(response.message)));
  }
}
function handleOptionChanged(filterOptions) {
  return {
    type: HANDLE_FILTER_CLICKED,
    payload: {filterOptions}
  }
}

const resetKeepFilters = () => {
  return {
    type: usersConstants.RESET_KEEP_FILTERS,
  };
};

export const usersActions = {
  getList,
  createUsers,
  getUserDetails,
  applyFilters,
  updateUser,
  updateNotification,
  resendActivation,
  handleOptionChanged,
  resetKeepFilters,
};