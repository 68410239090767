import {getQuoteConstants} from '../constants';
import {SET_FLASH_MESSAGES} from "../../../../_constants";

const initialState = {
  products: null, // [],
  selectedProductCode: null,
  selectedProduct: null,
  productsFetched: false,
  flashMessage: "",
  collections: null,
  item: null, /** the current quotation in the journey */
};
export default function getQuote(state =initialState, action) {
  switch (action.type) {
    case getQuoteConstants.CREATE_QUOTATIONS:
      return {
        ...state,
        item: action.list.item,
        collections: action.list.collections
    };
    case getQuoteConstants.UPDATE_QUOTATIONS:
      return {
        ...state,
        item: action.list,
        // collections: state.collections
      };
    case getQuoteConstants.GET_QUOTATION:
      return {
        ...state,
        item: action.payload.item,
        collections: state.collections ?
          {...state.collections, ...action.payload.collections} :
          action.payload.collections,
      };
    case getQuoteConstants.RESET_QUOTATION:
      return initialState;
    case  getQuoteConstants.GET_STATES: // these are states for countries
      return {
        ...state,
        collections: {
          ...state.collections,
          states: action.states
        }
      };
    case getQuoteConstants.GET_QUOTE_GET_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        productsFetched: true,
      };
    case SET_FLASH_MESSAGES:
      return {
        ...state,
        flashMessage: action.payload.message,
      };
    case getQuoteConstants.GET_QUOTE_SELECT_PRODUCT:
      return {
        ...state,
        selectedProductCode: action.payload.product.id,
        selectedProduct: action.payload.product,
    };
    case getQuoteConstants.GET_QUOTE_GET_COLLECTIONS:
      return {
        ...state,
        collections: action.payload.collections,
    };
    default:
      return state
  }
}