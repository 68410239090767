
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan.Product Name', name: '', config: {} },
    total: { id: 'properties.Plan.Total', name: 'Total Price', config: {} },
    config: {},
  },
  body: {
    items: [
      {
        id: 0,
        title: { id: 'Price Breakdown', name: 'Price Breakdown' },
        items: [
          { id: 'properties.Plan.Main Policy', name: 'Gross Premium', config: { currency: true } },
          { id: 'properties.Plan.Admin Fee', name: 'Admin Fee', config: { currency: true } },
        ],
        config: {},
      },
      {
        id: 1,
        title: { id: 'Other Details', name: 'Other Details' },
        items: [
          { id: 'properties.Destination', name: 'Destination', config: {} },
          { id: 'properties.Trip Type', name: 'Trip Type', config: {} },
          { id: 'properties.Start Date', name: 'Start Date', config: {} },
          { id: 'properties.End Date', name: 'End Date', config: {} },
          { id: 'properties.Insured Type', name: 'Insured Type', config: {} },
          { id: 'properties.Adults', name: 'Adults', config: {} },
          { id: 'properties.Children', name: 'Children', config: {} },
          { id: 'properties.Senior Citizens', name: 'Senior Citizens', config: {} },
        ],
        config: {},
      },
    ],
  },
};

export default model;
