import { model, defaultValues } from '../../@products/HGITravel/config';
import { objLib, dateLib } from '../../@xc-core/lib';
const defaultFieldStyle = { col: '6' };

const defaultDisabled = () => {
    return false;
  }

const alwaysDisabled = () => true;
  
const defaultHandler = (pair) => {
    return {pair, type:'default'};
  }

const handleFullName = (pair) =>{
    return {pair, type:'fullName'};
}

const handleIdNumber = (pair) =>{
    return {pair, type:'idNumber'};
}

const handlePostCode = (pair) =>{
    return {pair, type:'postCode'};
}

const handleNumber = (pair) =>{
    return {pair, type:'number'};
}

const getMaxDob = (item,id )  => {
    let range = defaultValues.dobRanges[objLib.getType(item,id)] || {min:{}}
    return dateLib.subtractFromNow(range.min.years, range.min.months, range.min.days);
}
const  getMinDob = (item,id)  => {
    let range = defaultValues.dobRanges[objLib.getType(item,id)] || {max:{}}
    return dateLib.subtractFromNow(range.max.years, range.max.months, range.max.days);
}

const createMainInsuredForm = () =>{
    const fullName ={
        id: model.fieldsModel.fullName.id,
        label: model.fieldsModel.fullName.label,
        type: model.fieldsModel.fullName.type,
        style: defaultFieldStyle,
        handler: handleFullName,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.fullName.placeholder,
    };
    const nationality = {
        id: model.fieldsModel.nationality.id,
        label: model.fieldsModel.nationality.label,
        type: model.fieldsModel.nationality.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        collectionId: model.fieldsModel.nationality.collectionId,
        disabled: defaultDisabled,
      };
      const idType = {
        id: model.fieldsModel.idType.id,
        label: model.fieldsModel.idType.label,
        type: model.fieldsModel.idType.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        collectionId: model.fieldsModel.idType.collectionId,
      };
      const idNumber = {
        id: model.fieldsModel.idNumber.id,
        label: model.fieldsModel.idNumber.label,
        type: model.fieldsModel.idNumber.type,
        style: defaultFieldStyle,
        handler: handleIdNumber,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.idNumber.placeholder,
      };
      const dob = {
        id: model.fieldsModel.dob.id,
        label: model.fieldsModel.dob.label,
        type: model.fieldsModel.dob.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        getMax: (item,id) => getMaxDob(item,id),
        getMin: (item,id) => getMinDob(item,id),
        // current: this.getMaxDob(),
        placeholder: model.fieldsModel.dob.placeholder,
      };
      const gender = {
        id: model.fieldsModel.gender.id,
        label: model.fieldsModel.gender.label,
        type: model.fieldsModel.gender.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        collectionId: model.fieldsModel.gender.collectionId,
      };
      const occupation = {
        id: model.fieldsModel.occupation.id,
        label: model.fieldsModel.occupation.label,
        type: model.fieldsModel.occupation.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.occupation.placeholder,
      };
      const address = {
        id: model.fieldsModel.address.id,
        label: model.fieldsModel.address.label,
        type: model.fieldsModel.address.type,
        style: { col: '12' },
        handler: defaultHandler,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.address.placeholder,
      };
      const country = {
        id: model.fieldsModel.country.id,
        label: model.fieldsModel.country.label,
        type: model.fieldsModel.country.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: alwaysDisabled,
        collectionId: model.fieldsModel.country.collectionId,
      };
      const state = {
        id: model.fieldsModel.state.id,
        label: model.fieldsModel.state.label,
        type: model.fieldsModel.state.type,
        collectionId: model.fieldsModel.state.collectionId,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
      };
      const postCode = {
        id: model.fieldsModel.postCode.id,
        label: model.fieldsModel.postCode.label,
        type: model.fieldsModel.postCode.type,
        style: defaultFieldStyle,
        handler: handlePostCode,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.postCode.placeholder,
      };
      const city = {
        id: model.fieldsModel.city.id,
        label: model.fieldsModel.city.label,
        type: model.fieldsModel.city.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.city.placeholder,
      };
      const email = {
        id: model.fieldsModel.email.id,
        label: model.fieldsModel.email.label,
        type: model.fieldsModel.email.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.email.placeholder,
      };
      const contactNo = {
        id: model.fieldsModel.contactNo.id,
        label: model.fieldsModel.contactNo.label,
        type: model.fieldsModel.contactNo.type,
        style: defaultFieldStyle,
        handler: handleNumber,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.contactNo.placeholder,
      };
    return {
        fields: [fullName, nationality,idType,idNumber,dob,gender,occupation,address,country,state,postCode,city,email,contactNo ]
    }
}

const createMainInsuredBeneficiaryForm = () =>{
    const beneficiaryName = {
        id: model.fieldsModel.beneficiaryName.id,
        label: model.fieldsModel.beneficiaryName.label,
        type: model.fieldsModel.beneficiaryName.type,
        style: defaultFieldStyle,
        handler: handleFullName,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.beneficiaryName.placeholder,
      };
      const beneficiaryRelation = {
        id: model.fieldsModel.beneficiaryRelation.id,
        label: model.fieldsModel.beneficiaryRelation.label,
        type: model.fieldsModel.beneficiaryRelation.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        collectionId: model.fieldsModel.beneficiaryRelation.collectionId,
      };
      const beneficiaryIdType = {
        id: model.fieldsModel.beneficiaryIdType.id,
        label: model.fieldsModel.beneficiaryIdType.label,
        type: model.fieldsModel.beneficiaryIdType.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        collectionId: model.fieldsModel.beneficiaryIdType.collectionId,
      };
      const beneficiaryIdNumber = {
        id: model.fieldsModel.beneficiaryIdNumber.id,
        label: model.fieldsModel.beneficiaryIdNumber.label,
        type: model.fieldsModel.beneficiaryIdNumber.type,
        style: defaultFieldStyle,
        handler: handleIdNumber,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.beneficiaryIdNumber.placeholder,
  
      };
      const beneficiaryEmail = {
        id: model.fieldsModel.beneficiaryEmail.id,
        label: model.fieldsModel.beneficiaryEmail.label,
        type: model.fieldsModel.beneficiaryEmail.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.beneficiaryEmail.placeholder,
      };
      const beneficiaryPhone = {
        id: model.fieldsModel.beneficiaryPhone.id,
        label: model.fieldsModel.beneficiaryPhone.label,
        type: model.fieldsModel.beneficiaryPhone.type,
        style: defaultFieldStyle,
        handler: handleNumber,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.beneficiaryPhone.placeholder,
      };
      return {
        fields: [beneficiaryName, beneficiaryRelation, beneficiaryIdType,
          beneficiaryIdNumber, beneficiaryEmail, beneficiaryPhone],
      };
}

const createOtherInsuredForm = () =>{
    const fullName = {
        id: model.fieldsModel.otherInsuredFullName.id,
        label: model.fieldsModel.otherInsuredFullName.label,
        type: model.fieldsModel.otherInsuredFullName.type,
        style: defaultFieldStyle,
        handler: handleFullName,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredFullName.placeholder,
      };
      const nationality = {
        id: model.fieldsModel.otherInsuredNationality.id,
        label: model.fieldsModel.otherInsuredNationality.label,
        type: model.fieldsModel.otherInsuredNationality.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        collectionId: model.fieldsModel.otherInsuredNationality.collectionId,
        disabled: defaultDisabled,
      };
      const idType = {
        id: model.fieldsModel.otherInsuredIdType.id,
        label: model.fieldsModel.otherInsuredIdType.label,
        type: model.fieldsModel.otherInsuredIdType.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        collectionId: model.fieldsModel.otherInsuredIdType.collectionId,
      };
      const idNumber = {
        id: model.fieldsModel.otherInsuredIdNumber.id,
        label: model.fieldsModel.otherInsuredIdNumber.label,
        type: model.fieldsModel.otherInsuredIdNumber.type,
        style: defaultFieldStyle,
        handler: handleIdNumber,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredIdNumber.placeholder,
      };
      const dob = {
        id: model.fieldsModel.otherInsuredDob.id,
        label: model.fieldsModel.otherInsuredDob.label,
        type: model.fieldsModel.otherInsuredDob.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        getMax: (item,id) => getMaxDob(item,id),
        getMin: (item,id) => getMinDob(item,id),
        placeholder: model.fieldsModel.otherInsuredDob.placeholder,
      };
      const gender = {
        id: model.fieldsModel.otherInsuredGender.id,
        label: model.fieldsModel.otherInsuredGender.label,
        type: model.fieldsModel.otherInsuredGender.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        collectionId: model.fieldsModel.otherInsuredGender.collectionId,
      };
      const occupation = {
        id: model.fieldsModel.otherInsuredOccupation.id,
        label: model.fieldsModel.otherInsuredOccupation.label,
        type: model.fieldsModel.otherInsuredOccupation.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredOccupation.placeholder,
      };
      const email = {
        id: model.fieldsModel.otherInsuredEmail.id,
        label: model.fieldsModel.otherInsuredEmail.label,
        type: model.fieldsModel.otherInsuredEmail.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredEmail.placeholder,
      };
      const contactNo = {
        id: model.fieldsModel.otherInsuredContactNo.id,
        label: model.fieldsModel.otherInsuredContactNo.label,
        type: model.fieldsModel.otherInsuredContactNo.type,
        style: defaultFieldStyle,
        handler: handleNumber,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredContactNo.placeholder,
      };
      return {
        fields: [fullName, nationality, idType,idNumber, dob,gender, occupation,email,contactNo],
      };
  
}

const createOtherInsuredBeneficiaryForm = () =>{
    const beneficiaryName = {
        id: model.fieldsModel.otherInsuredBeneficiaryName.id,
        label: model.fieldsModel.otherInsuredBeneficiaryName.label,
        type: model.fieldsModel.otherInsuredBeneficiaryName.type,
        style: defaultFieldStyle,
        handler: handleFullName,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredBeneficiaryName.placeholder,
      };
      const beneficiaryRelation = {
        id: model.fieldsModel.otherInsuredBeneficiaryRelation.id,
        label: model.fieldsModel.otherInsuredBeneficiaryRelation.label,
        type: model.fieldsModel.otherInsuredBeneficiaryRelation.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        collectionId: model.fieldsModel.otherInsuredBeneficiaryRelation.collectionId,
      };
      const beneficiaryIdType = {
        id: model.fieldsModel.otherInsuredBeneficiaryIdType.id,
        label: model.fieldsModel.otherInsuredBeneficiaryIdType.label,
        type: model.fieldsModel.otherInsuredBeneficiaryIdType.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        collectionId: model.fieldsModel.otherInsuredBeneficiaryIdType.collectionId,
      };
      const beneficiaryIdNumber = {
        id: model.fieldsModel.otherInsuredBeneficiaryIdNumber.id,
        label: model.fieldsModel.otherInsuredBeneficiaryIdNumber.label,
        type: model.fieldsModel.otherInsuredBeneficiaryIdNumber.type,
        style: defaultFieldStyle,
        handler: handleIdNumber,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredBeneficiaryIdNumber.placeholder,
  
      };
      const beneficiaryEmail = {
        id: model.fieldsModel.otherInsuredBeneficiaryEmail.id,
        label: model.fieldsModel.otherInsuredBeneficiaryEmail.label,
        type: model.fieldsModel.otherInsuredBeneficiaryEmail.type,
        style: defaultFieldStyle,
        handler: defaultHandler,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredBeneficiaryEmail.placeholder,
      };
      const beneficiaryPhone = {
        id: model.fieldsModel.otherInsuredBeneficiaryPhone.id,
        label: model.fieldsModel.otherInsuredBeneficiaryPhone.label,
        type: model.fieldsModel.otherInsuredBeneficiaryPhone.type,
        style: defaultFieldStyle,
        handler: handleNumber,
        disabled: defaultDisabled,
        placeholder: model.fieldsModel.otherInsuredBeneficiaryPhone.placeholder,
      };
      return {
        fields: [beneficiaryName, beneficiaryRelation, beneficiaryIdType,
          beneficiaryIdNumber, beneficiaryEmail, beneficiaryPhone],
      };
}

export {createMainInsuredForm,
     createMainInsuredBeneficiaryForm,
      createOtherInsuredForm,
      createOtherInsuredBeneficiaryForm
    }