import React from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { env } from "_config/env";

const Captcha = (props) => {
  return <ReCAPTCHA
          name = "recaptcha"
          sitekey={env.siteKey}
          onChange={(e)=> props.handleReCaptchaChange(e)}
        />
}

export default Captcha;