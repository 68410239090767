import React, {useEffect,useState} from 'react';
import {
    Col, Row, Input
  } from 'reactstrap';
import { connect } from "react-redux";
import { Header } from '../../@xc-core/components/core/header';
import bannerImg from 'images/bannerGraphicImages/get-quote.png'
import { TablesUi } from "../../_components";
import model from './model';
import { Modal } from '@xc-core/components/core';
import {Switch} from "react-toolbox/lib/switch";
import { actions } from '@redux';
import { Loader } from '../../@xc-core/components/core';
import {objLib} from '@xc-core/lib'

const systemConfigActions = actions.SystemConfigActions;

const heading = [
  {
    id: 1,
    headerName: "Name",
    key: "name"
  },
  {
    id: 2,
    headerName: "Exchange Rate",
    key: "exchangeRate",
  },
  {
    id: 3,
    headerName: "Active?",
    key: "active",
  },
  {
    id: 4,
    headerName: "Last Updated",
    key: "lastUpdated",
  },
  {
    id: 5,
    headerName: "Last Updated By",
    key: "lastUpdatedBy",
  },
  {
    id: 6,
    headerName: "Remarks",
    key: "remarks",
  },
];

const SystemConfigsComponent = (props: IObject)=>{
  const [modalOpen, setModalOpen] = useState(false);
  const [generalSettingsChanges, setGeneralSettingsChanges] = useState({});
  const [modalSettingsChanges, setModalSettingsChanges] = useState<IObject>({});
  const [exchangeConfigList,setExchangeConfigList] = useState([{}]);
  useEffect(()=>{
    if(!props.isTestCase)
      props.getSystemConfigs()
  },[])

  useEffect(()=>{
    setExchangeConfigList(createExchangeConfigList())
  },[props.configs])


  const renderGeneralSettingsFields = () => {
    return model.map((item: IObject) => (
      <div key={item.title}>
        <p className='font-weight-bold mt-4'>{item.title}</p>
        {item.values.map((value: IObject) => (
          <Row key={value.label}>
            <Col sm={3}>
              <p className='d-flex'>
                <span className=''>{value.label}</span>
              </p>
            </Col>
            <Col
              sm={1}
              className={`'bg-info'`}
              style={{ marginLeft: value.type === 'checkbox' ? '20px' : '' }}
            >
              <Input
                type={value.type}
                value={props.configs[value.id] ? props.configs[value.id] : ""}
                name={value.id}
                onChange={(e) => handleInputChange({ event: e, id: value.id })}
                
              />
            </Col>
            <Col sm={2}>
              <p>{value.meta}</p>
            </Col>
          </Row>
        ))}
      </div>
    ));
  };

  const handleInputChange = (data :IObject)=>{
    props.updateSystemConfigs(objLib.setValueWithLodash(props.configs, data.id,data.event.target.value));
    setGeneralSettingsChanges(objLib.setValueWithLodash(generalSettingsChanges, data.id,data.event.target.value))
  }
  const handleModalInputChange = (data: IObject) =>{
    props.updateSystemConfigs(objLib.setValueWithLodash(props.configs, data.id,data.value));
    setModalSettingsChanges(objLib.setValueWithLodash(modalSettingsChanges, data.id,data.value))
  }




  const switchExchangeRate = (newType: string) =>{
    let newConfig = {}
    if(newType === "manual"){
      newConfig = objLib.setValueWithLodash(newConfig, 'exchangeRateList[0].status',true)
      newConfig = objLib.setValueWithLodash(newConfig, 'exchangeRateList[1].status',false)
    } else if(newType === "api"){
      newConfig = objLib.setValueWithLodash(newConfig, 'exchangeRateList[0].status',false)
      newConfig = objLib.setValueWithLodash(newConfig, 'exchangeRateList[1].status',true)
    }
    props.updateSystemConfigs(objLib.deepMergeWithLodash(props.configs, newConfig));
    setGeneralSettingsChanges(objLib.deepMergeWithLodash(generalSettingsChanges, newConfig))
  }

  const createExchangeConfigList = ()=>{
    return [
      {
        key: props.configs.exchangeRateList[1].name,
        name: `1 ${props.configs.currencySymbolBase} : ${props.originalConfigs.currencySymbol}`,
        exchangeRate: props.configs.exchangeRateList[1].value,
        active: <Input type="checkbox" checked={props.configs.exchangeRateList[1].status} onChange={()=>switchExchangeRate('api')} disabled={props.originalConfigs.prioritiesManualRate} />,
        lastUpdated: props.configs.exchangeRateList[1].updatedOn,
        lastUpdatedBy: props.configs.exchangeRateList[1].updatedBy,
        remarks: props.configs.exchangeRateList[1].statusDescription
      },
      {
        key: props.configs.exchangeRateList[0].name,
        name: `1 ${props.configs.currencySymbolBase} : ${props.originalConfigs.currencySymbol}`,
        exchangeRate: <Input className="col-sm-2" type="text" value={props.configs.exchangeRateList[0].value ? props.configs.exchangeRateList[0].value : ''  } onChange={(e)=>handleInputChange({event:e, id: 'exchangeRateList[0].value'})}  />,
        active: <Input type="checkbox" id={props.configs.exchangeRateList[0].name} checked={props.configs.exchangeRateList[0].status} onChange={()=>switchExchangeRate('manual')}  disabled={props.originalConfigs.prioritiesManualRate}/>,
        lastUpdated: props.configs.exchangeRateList[0].updatedOn,
        lastUpdatedBy: props.configs.exchangeRateList[0].updatedBy,
        remarks: props.configs.exchangeRateList[0].statusDescription
      }
    ]
  }

  const toggleModal = (open: boolean)=> {
    setModalOpen(open);
    if(!open){
      setModalSettingsChanges({})
      let newConfig = {}
      newConfig = objLib.setValueWithLodash(props.configs, 'useExchangeRateFeature',props.originalConfigs.useExchangeRateFeature)
      newConfig = objLib.setValueWithLodash(newConfig, 'prioritiesManualRate',props.originalConfigs.prioritiesManualRate)
      props.updateSystemConfigs(newConfig);
    }
  }

  const handleSaveGeneralSettings = async () =>{
    try{
      await props.saveSystemConfigs({...generalSettingsChanges, 'orgUnitCode' : props.originalConfigs.orgUnitCode},{})
      setGeneralSettingsChanges({});
    } catch(e) {
    }
  }

  const handleSaveModalSettings = async () =>{
    // what if modal save returns an error and user cancel modal.
    if(modalSettingsChanges.prioritiesManualRate){
      setGeneralSettingsChanges(objLib.deleteKeyWithLodash(generalSettingsChanges,['exchangeRateList[0].status','exchangeRateList[1].status']))
    }
    try{
      await props.saveSystemConfigs({...modalSettingsChanges,'orgUnitCode' : props.originalConfigs.orgUnitCode},generalSettingsChanges)
      setModalSettingsChanges({})
      setModalOpen(false);
    } catch (e){
    }
  }

  let tableUiProps = {
    tableClasses: "center",
    heading: heading,
    tableData: exchangeConfigList
  }
  if (props.isLoading) return <Loader />;
  return (
          <div data-testid="containter">
              <Header
                title='System Configuration'
                bannerGraphic={bannerImg}
                withBorderBottom
              />
              <div data-testid="containter-inner" className='container-fluid px-0 normal-gray-text'>
                <Row className='d-flex justify-content-center my_30'>
                  <Col sm={11} className='col-sm-11' style={{ padding: '0 4%' }}>
                    {renderGeneralSettingsFields()}
                  </Col>
                </Row>
              </div>
              <div className='container-fluid px-0 normal-gray-text'>
                 <Row className='d-flex justify-content-center my_30'>
                   <Col sm={11} className='col-sm-11' style={{ padding: '0 4%' }}>
                   <p className="font-weight-bold mt-4">Exchange Rate Configuration</p>
                   <TablesUi
                    {...tableUiProps}
                   >
                   </TablesUi>
                   </Col>
                 </Row>
                 <Row className='d-flex justify-content-center my_30'>
                      <Col sm={11} className='col-sm-11 d-flex justify-content-end' style={{ padding: '0 4%' }}>
                     <button className='btn btn-outline-primary mt-4' onClick={()=> toggleModal(true)}>Exchange Rate Settings</button>
                     <button className='btn btn-primary mt-4 ml-4' onClick={()=> handleSaveGeneralSettings()}>Save</button>
                     </Col> 
                 </Row>
               </div>
               <Modal
                className={"max-width-500"}
                id='modalMessage'
                title={"Exchange Rate Settings"}
                isOpen={modalOpen}
                toggle={()=>toggleModal(!modalOpen)}
              >
                <Row className="px-3">
                  <Col sm="12 mb-3"><span className="font-weight-bold">Exchange Rate ON/OFF</span></Col>
                  <Col sm="9"><span>Disable all currency conversion?</span></Col>
                  <Col sm="3" className="d-flex justify-content-end">
                  <span className="mr-2">{ !props.configs.useExchangeRateFeature ? "Yes" : "No" }</span>
                  <Switch
                          theme={{
                            on: "switchOn switch",
                            thumb: "thumb",
                            off: "switch"
                          }}
                          checked={!props.configs.useExchangeRateFeature}
                          onChange={()=> handleModalInputChange({id:'useExchangeRateFeature', value:!props.configs.useExchangeRateFeature})}
                        />
                  </Col>
                  <Col sm="12 mb-3"><span className="font-weight-bold">Manual Exchange Rate</span></Col>
                  <Col sm="9"><span>Use Manual Exchange Rate exclusively</span></Col>
                  <Col sm="3" className="d-flex justify-content-end">
                  <span className="mr-2">{ props.configs.prioritiesManualRate ? "Yes" : "No" }</span>
                  <Switch
                          theme={{
                            on: "switchOn switch",
                            thumb: "thumb",
                            off: "switch"
                          }}
                          checked={props.configs.prioritiesManualRate}
                          onChange={()=> handleModalInputChange({id:'prioritiesManualRate', value: !props.configs.prioritiesManualRate})}
                        />
                  </Col>
                  <Col sm="12" className="mb-4 d-flex justify-content-center">
                    <button className='btn btn-link mt-4' onClick={()=> toggleModal(false)}>Cancel</button>
                    <button className='btn btn-primary mt-4 ml-4' onClick={()=> handleSaveModalSettings()}>Confirm</button>
                  </Col>
                </Row>
              </Modal>
          </div>
        );

}    
const mapStateToProps = (state: IStore) => ({
  originalConfigs: state.systemConfig.originalConfigs,
  configs: state.systemConfig.configs,
  isLoading: state.systemConfig.isLoading,
  isTestCase: state.systemConfig.isTestCase
});
const mapDispatchToProps = (dispatch: any) => ({
  updateSystemConfigs: (updatedConfig: object) => dispatch(systemConfigActions.updateSystemConfigs(updatedConfig)),
  saveSystemConfigs: (updatedConfig:IObject,toMerge:IObject) => dispatch(systemConfigActions.saveSystemConfigs(updatedConfig,toMerge)),
  getSystemConfigs: () => dispatch(systemConfigActions.getSystemConfigs()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SystemConfigsComponent);