import React from "react";
import PropTypes from "prop-types";

const AddButton = (props) => {
  const { onClick, text } = props;
  return (
    <button
      type="button"
      className="btn btn-primary pl-1 btn-sm rounded-xl"
      onClick={onClick}
    >
      <i className="icon-add mx-2 font-size-small" />
      {text}
    </button>
  );
};

AddButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default AddButton;
