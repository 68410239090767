import config from '@xc-core/config';
import { el } from 'date-fns/locale';

class Request {
  method: string;
  body: any;
  options: IObject;

  constructor(obj: object | null = null, method = 'GET', headers: object = {}) {
    this.method = method;
    this.setBody(obj);
    this.options = {
      headers: this.getHeaders(obj),
      method,
      body: this.body,
    };
  }

  private setBody(obj: object | FormData | null) {
    if (obj instanceof FormData) {
      this.body = obj;
    } else {
      this.body = obj ? JSON.stringify(obj) : null;
    }    
  }

  private getHeaders(obj: object | FormData | null) {
    const headers = new Headers();
    headers.append('x-auth-token', config.token);
    
    if (!(obj instanceof FormData)) {
      headers.append('content-type', 'application/json');
    }

    return headers;
  }
}


export default Request;
