import React, {useCallback, useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import lib from "../../../utils/lib";
import {defaultValues} from '../config'

const CarDetails =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            initValues
        },
    } = props;
    const carDetails = item.properties?.['Car Details'];

    useEffect(() => {
        if(!carDetails?.Make){
            initValues(Object.entries(defaultValues.carDetailDefaultValue).map((val)=>{
                return {
                    id: val[0],
                    value:val[1]
                }
            }))
        }
    }, []);

    const checkProperties=(collectionItem:any,currentItem:any,targetKey:string)=>{
        const targetModel = collectionItem.find((el:any)=> el.id === carDetails?.['Model']);
        return targetModel? (targetModel.extendedProperties[targetKey].indexOf(currentItem.id) !== -1
            && targetModel.extendedProperties.make === carDetails?.['Make'])
            : true
    }

    const collectionsFilterHandler=useCallback(()=>{
        const {model,variant,engineCC,transmission,manufactureYear} = collections;
        return {
            ...collections,
            model: model.filter((el:any)=>{
                const {make} = el.extendedProperties;
                return make === item.properties?.['Car Details']?.['Make']
            }),
            variant: variant.filter((el:any)=>{
                return checkProperties(model,el,'variants')
            }),
            engineCC:engineCC.filter((el:any)=>{
                return checkProperties(model,el,'engineCcs')
            }),
            transmission: transmission.filter((el:any)=>{
                return checkProperties(model,el,'transmissions')
            }),
            manufactureYear: manufactureYear.filter((el:any)=>{
                return checkProperties(model,el,'manufactureYears')
            }),
        }
    } , [
        carDetails.Make,
        carDetails.Model
    ])

    const onChangeHandler=(pairs: IPair[])=>{
        const pairId= pairs[0].id;
        const ids = ["properties.Car Details.Make" , "properties.Car Details.Model"];
        const addedPairs = ids.indexOf(pairId) !== -1  ? [
             pairId === "properties.Car Details.Model" ? null : {id:'properties.Car Details.Model' , value:''},
            {id:'properties.Car Details.Manufacture Year' , value:''},
            {id:'properties.Car Details.Engine CC' , value:''},
            {id:'properties.Car Details.Transmission' , value:''},
            {id:'properties.Car Details.Variant' , value:''}
        ].filter((el)=> el !== null) : []

        onChange('onChange', {
            pairs:[...pairs, ...addedPairs]
        })
    }

    return (
        <Row>
            <Col md='12'>
                <h5 className='sub-title'>{model.title}</h5>
                <RenderFields
                    onChange={onChangeHandler}
                    collections={collectionsFilterHandler()}
                    item={item}
                    ext={lib.checkIsDemoSite()}
                    validationErrors={validationErrors}
                    fields={model.carDetails.fields}
                />
            </Col>
        </Row>
    )
}

export default CarDetails;