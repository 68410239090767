import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import SimpleReactValidator from "simple-react-validator";
import {
  PlanSelection, /**step2 */
  CustomerDetails, /**step3 */
  BeneficiaryDetails, /**step4 */
  Summary, /**step5 */
  PaymentDetails, /**step6 */
} from "./containers";

import {
  FlashMessage,
  TitleComponent,
  Stepper,
  ThankYouPage,
} from "_components";
import { utils } from "utils";
import config from "_config";
import { getQuoteActions } from "../../shared/GetQuote/actions";
import { styles } from "../../shared/utils/utils";
import getQuoteGraphic from "../../../images/bannerGraphicImages/get-quote.png";
import moment from "moment";
import { CONFIG_DATE_MOMENT_FORMAT } from "../../../utils";

class GetQuoteWalletInsuranceWizard extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator(utils.validations.alfalahValidationObject);
    const { item } = this.props;

    this.state = {
      activeStep: 0,
      sendingRequest: false,
      fields: {
        ...this.prepareFields(item),
      }
    };
  }

  async componentDidMount() {
    const { item } = this.props;
    const { steps } = utils.products.walletInsurance;

    if (item) {
      this.setState({
        activeStep: steps.indexOf(item.state) < 0 ? 0 : steps.indexOf(item.state),
        fields: {
          ...this.prepareFields(item),
        }
      })
    } else {
      this.setState({activeStep: 0});
    }
    const country = item && item.properties["Country"]
      ? item.properties["Country"] : config.products.walletInsurance.defaultValues.country;
    await this.props.getStates(country, '?collection=state_alf');
  }

  renderSteps = () => {
    const {classes, collections, item, history} = this.props;
    const {fields} = this.state;
    switch (this.state.activeStep) {
      case 0:
        return (
          <PlanSelection
            classes = {classes}
            fields = {fields.planSelection}
            collections = {collections}
            handlePlanChange = {(stepKey, obj) => this.handlePlanChange(stepKey, obj)}
          />
        );
      case 1:
        return (
          <CustomerDetails
          fields = {fields}
          properties = {item.properties}
          quotation = {item}
          collections = {collections}
          handleChange = {(stepKey, e) => this.handleChange(stepKey, e)}
          handleIdNumberChange = {(stepKey, e) => this.handleIdNumberChange(stepKey, e)}
          handleNumberFormatFields = {(stepKey, e) => this.handleNumberFormatFields(stepKey,e)} 
          handleIdTypeChange= {(stepKey, e) => this.handleIdTypeChange(stepKey,e)}
          handleDob= {(stepKey, dob) => this.handleDob(stepKey,dob)}
          validator = {this.validator}
          maxDate={this.getYoungestValidDob()}
          />
        );
      case 2:
        return (
          <BeneficiaryDetails
          fields = {fields}
          properties = {item.properties}
          quotation = {item}
          collections = {collections}
          handleChange = {(stepKey,e) => this.handleChange(stepKey, e)}
          handleIdNumberChange = {(stepKey, e) => this.handleIdNumberChange(stepKey, e)}
          handleNumberFormatFields = {(stepKey, e) => this.handleNumberFormatFields(stepKey,e)} 
          handleIdTypeChange= {(stepKey, e) => this.handleIdTypeChange(stepKey,e)}
          validator = {this.validator}
          />
        );
      case 3:
        return (
          <Summary
          fields = {fields}
          properties = {item.properties}
          quotation = {item}
          />
        );
      case 4:
        return (
          <PaymentDetails
          fields = {fields.paymentDetails}
          collections = {collections}
          handleChange = {(stepKey,e) => this.handleChange(stepKey, e)}
          handlePaymentModeChange = {(stepKey,e) => this.handlePaymentModeChange(stepKey, e)}
          />
        );
      default:
      return <div>undefined step!, {this.state.activeStep}</div>
    }
  }

  getYoungestValidDob = () => (
    utils.lib.subtractTodayByAge(config.products.walletInsurance.getQuote.minAge));

  handleChange = (stepKey, e) => {
    let key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;

    if (stepKey === "contactDetails" && key === "country") {
      /**when the country has changed send the request otherwise the states are same as before */
      if(this.state.fields[stepKey][key] !== value) {
        this.getStates(e.target.value);
      }
    }
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [stepKey]: {
          ...this.state.fields[stepKey],
          [key]: value,
        },
      }
    });
  }

  handleDob = (stepKey, date) => {
    const key = "dob";
    let value = date;

    /** check if selected birthday is greater than maximum age */
    const maxDate = this.getYoungestValidDob();
    value = utils.lib.isAfter(value, maxDate) ? maxDate : value;
 
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [stepKey]: {
          ...this.state.fields[stepKey],
          [key]: utils.lib.applyConfigFormat(value),
        },
      },
    });
  }

  handlePaymentModeChange = (stepKey, e) => {
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
    let receivedBy = this.state.fields[stepKey].receivedBy;
    if(!receivedBy) {
      const { name } = JSON.parse(sessionStorage.getItem('user'));
      receivedBy = name;
    }

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [stepKey]: {
          ...this.state.fields[stepKey],
          [key]: value,
          receivedBy,
        },
      }
    });
  }

  handleIdTypeChange = (stepKey, e) => {
    const key = e && e.target && e.target.id;
    const value = e && e.target && e.target.value;
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [stepKey]: {
          ...this.state.fields[stepKey],
          [key]: value,
          idNumber: "",
        },
      }
    });
  }

  handleIdNumberChange = (stepKey, e) => {
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
    let gender = this.state.fields.mainInsured.gender;
    if(this.state.fields[stepKey].idType === "CNIC") {
      value = value.length > utils.lengths.CNIC ? value.slice(0,utils.lengths.CNIC) : value;
      value = utils.lib.isOnlyNumber(value) || value === "" ? value : this.state.fields[stepKey].idNumber;
       /**autofill gender based on cnic number */
       gender = value && value.length === utils.lengths.CNIC ? utils.lib.getGenderFromN(value[utils.lengths.CNIC-1]) : gender;
    } else {
      value = value === "" || utils.lib.isOnlyNumberOrAlphabet(value) ? value : this.state.fields[stepKey].idNumber;
    }


    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [stepKey]: {
          ...this.state.fields[stepKey],
          [key]: value,
          gender: gender,
        },
      }
    });
    
  }

  handleNumberFormatFields = (stepKey, e) => {
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;

    value = utils.lib.isOnlyNumber(value) || value === "" ? value : this.state.fields[stepKey][key];

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [stepKey]: {
          ...this.state.fields[stepKey],
          [key]: value,
        },
      }
    });
  }

  validateFields = () => {		
		if (this.validator.allValid()) { //this.validator && 
      return true;
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  prepareFields = (item) => {
    const {collections} = this.props;
    const defaultCountry = collections.countries.find(country => {
      return country.name === config.products.walletInsurance.defaultValues.country
    });
    return {
      planSelection: {
        plan: item ? item.properties["Plan Type"] : "",
        priceObj: {},  
        // priceList: collections["priceList"], //item ? item.properties["priceList"] : {},
      },
      mainInsured: {
        fullName: item ? item.properties["Full Name"] : "",
        nationality: item ? item.properties["Nationality"] : "",
        idNumber: item ? item.properties["ID Number"] : "",
        idType: item ? item.properties["ID Type"] : "",
        gender: item ? item.properties["Gender"] : utils.constants.genders.MALE,
        dob: item ? item.properties["DOB"] : "", // utils.lib.getDate(item.properties["DOB"], utils.constants.formats.date.long)  : "",
        debitAccountNo: item ? item.properties["Debit Card or Account No"] : "",
      },
      contactDetails:{
        address: item ? item.properties["Address"] : "",
        country: item && item.properties["Country"] ? item.properties["Country"] : 
                 (defaultCountry ? defaultCountry.name : ""),
        state: item ? item.properties["State"] : "",
        postcode: item ? item.properties["Postcode"] : "",
        city: item ? item.properties["City"] : "",
        email: item ? item.properties["Email Address"] : "",
        contactNumber: item ? item.properties["Contact Number"] : "",
      },
      beneficiaryDetails: {
        name: item && item.properties.Beneficiaries && item.properties.Beneficiaries.length > 0
         ? item.properties.Beneficiaries[0]['Full Name'] :'',
        relationship: item && item.properties.Beneficiaries && item.properties.Beneficiaries.length > 0
        ? item.properties.Beneficiaries[0]['Relationship'] :'',
        idType: item && item.properties.Beneficiaries && item.properties.Beneficiaries.length > 0
        ? item.properties.Beneficiaries[0]['ID Type'] :'',
        idNumber:  item && item.properties.Beneficiaries && item.properties.Beneficiaries.length > 0
        ? item.properties.Beneficiaries[0]['ID Number'] :'',
        email: item && item.properties.Beneficiaries && item.properties.Beneficiaries.length > 0
        ? item.properties.Beneficiaries[0]["Email Address"] : '',
        contactNumber: item && item.properties.Beneficiaries && item.properties.Beneficiaries.length > 0
        ? item.properties.Beneficiaries[0]["Contact Number"] : '',
      },
      paymentDetails: {
        paymentMode: item ? item.properties["Payment Mode"] :"",
        receiptNo: item ? item.properties["Receipt No"] :"",
        receivedBy: item ? item.properties["Payment Received By"] :"",
      }
    };
  }

  prepareRequestObjForStep = step => {
    const {collections} = this.props;
    const {fields} = this.state;
    const {item} = this.props;

    let requestObj = {productCode: config.products.walletInsurance.id, properties: {"Source": "B2B Portal"}};
    requestObj.properties = item && item.properties ? {...item.properties} : requestObj.properties;

    switch(step) {
      case 0:
          requestObj.properties = {
          ...requestObj.properties,
          "Plan Type": fields.planSelection.plan,
          "Price": {
            "Main Policy":fields.planSelection.priceObj["Main Policy"],
            "Total": fields.planSelection.priceObj["Total"],
            "Admin Surcharge": fields.planSelection.priceObj['Admin Surcharge'],
            "Federal Excise Duty": fields.planSelection.priceObj['Federal Excise Duty'],
            "Plan Type": fields.planSelection.priceObj['Plan Type'],
            "Fixed Insurance Fee": fields.planSelection.priceObj['Fixed Insurance Fee'],
            "Stamp Duty": fields.planSelection.priceObj['Stamp Duty'],
            "Withholding Tax": fields.planSelection.priceObj['Withholding Tax'],
          },
          "priceList": collections["priceList"],
        };
        return requestObj;
      case 1:
          requestObj.properties = {
            ...requestObj.properties,
            "Full Name": fields.mainInsured.fullName,
            "Nationality": fields.mainInsured.nationality,
            "ID Number": fields.mainInsured.idNumber,
            "ID Type": fields.mainInsured.idType,
            "Gender": fields.mainInsured.gender,
            "DOB": fields.mainInsured.dob, // utils.lib.getDateString(fields.mainInsured.dob,utils.constants.formats.date.long),
            "Debit Card or Account No": fields.mainInsured.debitAccountNo,
            "Address" : fields.contactDetails.address,
            "Country": fields.contactDetails.country,
            "State": fields.contactDetails.state,
            "Postcode": fields.contactDetails.postcode,
            "City": fields.contactDetails.city,
            "Email Address": fields.contactDetails.email,
            "Contact Number": fields.contactDetails.contactNumber,

          };
          return requestObj;
      case 2: /**beneficiaryDetails */
      case 3: /**summary */
      requestObj.properties = {
        ...requestObj.properties,
        Beneficiaries: [{
          "Full Name": fields.beneficiaryDetails.name,
          Relationship: fields.beneficiaryDetails.relationship,
          "ID Type": fields.beneficiaryDetails.idType,
          "ID Number": fields.beneficiaryDetails.idNumber,
          "Email Address": fields.beneficiaryDetails.email,
          "Contact Number": fields.beneficiaryDetails.contactNumber,
        }],
      };
      return requestObj;
      case 4: /**payment details */
      case 5: /**thankyou */
      if(fields.paymentDetails.paymentMode && 
         fields.paymentDetails.receiptNo && 
         fields.paymentDetails.receivedBy) {
          requestObj.properties = {
            ...requestObj.properties,
            "Payment Mode": fields.paymentDetails.paymentMode,
            "Receipt No": fields.paymentDetails.receiptNo,
            "Payment Received By": fields.paymentDetails.receivedBy,
          };
         }
      return requestObj;
      default:
        break;
    }
  }

  handlePlanChange = (stepKey, obj) => {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [stepKey]: {
          ...this.state.fields[stepKey],
          plan: obj.name,
          priceObj: obj.priceObj,
        },
      }
    }, () => {
      /**plan selection step */
      this.handleNext();
    });
  }

  handleNext = async () => {
    const {item} = this.props;
    const {fields} = this.state;

    if(this.validateFields()) {
      /**because we use one validator in parent for all child forms, 
       * every time we should reset the validator for the next step. */
      this.validator = new SimpleReactValidator(utils.validations.alfalahValidationObject);

      this.setState({sendingRequest: true});
      const quotationId = item ? item.id : 0;
      
      const requestObj = this.prepareRequestObjForStep(this.state.activeStep);
      const response = await this.props.updateQuotation(requestObj, quotationId, "next");
      if(response && response.list) {
        this.setState({
          sendingRequest: false, 
          activeStep: this.state.activeStep + 1,
          fields: {
            ...fields,
          }
        });
      }
    }
  };

  handleBack = async () => {
    const {item} = this.props;
    this.setState({sendingRequest: true});
    const reqObj = {productCode: item.productCode};
    await this.props.updateQuotation(reqObj,item.id,   "prev");
    this.setState({sendingRequest: false});
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1 < 0 ? 0 : prevState.activeStep - 1,
    }));
  };

  handleReset = async () => {
    const {history, resetQuotation} = this.props;
    await resetQuotation();
    history.push("/getQuote");
  };

  getStates = async (country) => {
    this.props.getStates(country);
  }

	render() {
    this.validator.purgeFields(); 
		const {classes, item, products, productsFetched, flashMessage} = this.props;
		const {
			activeStep, productSelected, fields,
			planSelected, disabled, properties, sendingRequest,
		} = this.state;
		const values = {productSelected, planSelected};
		const steps = utils.products.walletInsurance.steps;
    const showNext = activeStep !== 0; /**PlanSelection */
		let shouldSkipOnLastStep;
		if ((activeStep + 1 === steps.length - 1) && !(fields.paymentDetails.paymentMode || fields.paymentDetails.receiptNo)) {
			shouldSkipOnLastStep = true;
		}

		/**
		 * display proper message if fetching products returned an empty collection
		 * @const productsFetched
		 * */

		if (productsFetched && !products.length) return (<FlashMessage flashMessage={utils.userMessages.UNIMPLEMENTED_PRODUCT}/>);
		if (flashMessage !== "") return (<FlashMessage flashMessage={flashMessage}/>);
		if (activeStep === null) return (<FlashMessage flashMessage={utils.userMessages.WAIT}/>);
		return (
			<div className="container-fluid px-0">
				<TitleComponent
					title="Get Quote"
					bannerGraphic={getQuoteGraphic}
				/>
				<div className="row d-flex justify-content-center my_50">
					<div className="col-10">
						<div className={classes.root}>  
            <Stepper 
                steps = {utils.products.walletInsurance.steps}
                activeStep={activeStep} 
                classes={classes} 
              />
						{
              steps[activeStep] === steps[steps.length - 1] ? /**completed */
              <ThankYouPage certificateId={item ? item.policyRefCode : null}
                        handleReset={this.handleReset.bind(this)}/> :
              <>
              <div className={classes.instructions}>
                {this.renderSteps()}
							</div>
							<Row className="text-right justify-content-end">
								<Col sm="2">
									{activeStep > 0 && steps[activeStep] !== steps[steps.length - 2] && (
										/**not payment details */
										<button
											className="btn btn-light col-12 my-2"
											onClick={this.handleBack}
											disabled={sendingRequest}>
											Previous
										</button>
									)}
								</Col>
								{
									showNext && <Col sm="2">
										<button
											className="btn btn-primary col-12 my-2 rounded-xl"
											onClick={this.handleNext}
											disabled={sendingRequest} //{disabled || sendingRequest}
										>
											{shouldSkipOnLastStep ? "Skip" : "Next"}
										</button>
									</Col>
								}
							</Row>
              </>
            }
						</div>
					</div>
				</div>
			</div>
		);
	}
}

GetQuoteWalletInsuranceWizard.propTypes = {
  // classes: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
  updateQuotation: (data, id, direction) => dispatch(getQuoteActions.updateQuotation(data, id, direction)),
  createQuotation: (data) => dispatch(getQuoteActions.createQuotation(data)),
  getStates: (country, param) => dispatch(getQuoteActions.getStates(country, param)),
  getQuotation: (quotationId) => dispatch(getQuoteActions.getQuotation(quotationId)),
  getProducts: () => dispatch(getQuoteActions.getProducts()),
  resetQuotation: () => dispatch(getQuoteActions.resetQuotation()),
  checkEditPermission: () => dispatch(getQuoteActions.checkEditPermission()),
  checkGetQuotePermission: () => dispatch(getQuoteActions.checkGetQuotePermission()),
});
function mapStateToProps(state) {
  const {item, collections, products, productsFetched, flashMessage, selectedProductCode} = state.getQuote;
  return {
    item,
    collections,
    productsFetched,
    products,
    selectedProductCode,
    flashMessage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GetQuoteWalletInsuranceWizard));
