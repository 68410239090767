/* eslint-disable react/prop-types */
import React from 'react';
import Moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleReactValidator from 'simple-react-validator';

import {
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from 'reactstrap';
import {
  CONFIG_DATE_MOMENT_FORMAT, getOneYearInclusiveEndMoment, utils,
  getMaximumReturnTripEndDate,
} from 'utils';

import productConfig from '_config';

import moment from 'moment';
import { CommonInputTypes } from '../../../../_components/CommonInputTypes';

const countArray = [
  {
    name: '0',
  },
  {
    name: '1',
  },
  {
    name: '2',
  },
  {
    name: '3',
  },
  {
    name: '4',
  },
  {
    name: '5',
  },
  {
    name: '6',
  },
  {
    name: '7',
  },
  {
    name: '8',
  },
  {
    name: '9',
  },
  {
    name: '10',
  },
];

const noAddOnCountries = ['Andorra', 'Argentina', 'Aruba', 'Bahrain', 'Brazil', 'Costa Rica', 'Czechia', 'Czech Republic', 'France', 'French Polynesia', 'Guam', 'Israel', 'Kuwait', 'Maldives', 'Montenegro', 'Occupied Palestinian territory', 'Panama', 'Peru', 'Puerto Rico', 'Saint Martin', 'Spain', 'Serbia and Montenegro'];

const maximumIndividualsNumberError = {
  anyError: true,
  errorMessage: 'The total number of Adults, Children and Senior Citizens cannot be more than 125.',
};
const maximumGroupNumberError = {
  anyError: true,
  errorMessage: 'The total number of Adults, Children and Senior Citizens cannot be more than 125.',
};
const minimumGroupNumberError = {
  anyError: true,
  errorMessage: `The total number of Adults, Children and Senior Citizens must be at least 2, 
                and there must be a minimum of 1 Adult or Senior Citizen within the group.`,
};

const minimumFamilyAdultChildrenError = {
  anyError: true,
  errorMessage: 'At least 2 Adults or 1 Adult & 1 Child must be specified in order to purchase this plan.',
};

const noError = { anyError: false, errorMessage: '' };

class TripDetails extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator(utils.validations.alfalahValidationObject);
    this.initialState = {};

    this.state = {
      ...this.props.curentThis.state,

      /* this is for individuals insuredType radio buttons */
      individualIsAdult: this.props.isIndividualAdult(),
    };
  }

  async componentDidMount() {
    this.props.onRef(this);
    this.initialState = this.state;
    this.props.curentThis.setState({ disabled: true });

    const { curentThis: { props: { item } } } = this.props;
    const country = (item && item.properties.Country) || productConfig.products.alfalahTravelInsurance.defaultValues.country;
    await this.props.getStates(country, '?collection=state_alf');
  }

  async getAlert() {
    const { validator, props } = this;
    const { curentThis } = props;
    if (validator.allValid()) {
      await curentThis.setState({ disabled: false });
      await curentThis.setInvalidForm(false);
      await curentThis.createOtherInsuredObj();
      await curentThis.populateBeneficiaryObj();
      return true;
    }
    validator.showMessages();
    curentThis.setState({ disabled: true });
    await curentThis.setInvalidForm(true);
    return false;
  }

  componentDidUpdate(prevProps) {
    const { curentThis, getSeniorCitizenCount } = this.props;
    const citizensNumber = getSeniorCitizenCount(); // Number(curentThis.state.properties["Senior Citizen (66-75)"]);
    const adultsNumber = Number(curentThis.state.properties.Adults);
    const childrenNumber = Number(curentThis.state.properties.Children);

    if (
      (curentThis.state.disabled !== true && !this.validator.allValid())
      || (curentThis.state.disabled !== true && curentThis.state.seniorCitizenErrors.anyError === true)
      || (curentThis.state.disabled !== true && curentThis.state.adultsErrors.anyError === true)
      || (curentThis.state.disabled !== true && curentThis.state.childrenErrors.anyError === true)
      || (curentThis.state.disabled !== true && curentThis.state.insuredTypeError.anyError === true)
    ) {
      curentThis.setState({ disabled: true });
    } else if (
      curentThis.state.disabled === true
      && this.validator.allValid()
      && curentThis.state.seniorCitizenErrors.anyError === false
      && curentThis.state.adultsErrors.anyError === false
      && curentThis.state.childrenErrors.anyError === false
      && curentThis.state.insuredTypeError.anyError === false
      && citizensNumber + adultsNumber + childrenNumber !== 0) {
      curentThis.setState({ disabled: false });
    }
  }

  handleTripTypeInputChange = async (e) => {
    const { curentThis } = this.props;
    const { target } = e;
    const startM = Moment(curentThis.state.properties['Start Date'], CONFIG_DATE_MOMENT_FORMAT);

    if (target.name === 'Trip Type' && target.value === 'Annual') {
      curentThis.setState({ disableDomestic: true, disableArrival: true }, () => {
        const newEndDate = getOneYearInclusiveEndMoment(curentThis.state.properties['Start Date']).format(CONFIG_DATE_MOMENT_FORMAT);
        curentThis.handleDateChange({ startDate: curentThis.state.properties['Start Date'], endDate: newEndDate });
      });
    } else if (target.name === 'Trip Type' && target.value === 'Single Trip') {
      const endM = startM.clone().add(utils.constants.defaultTravelNoOfDays - 1, 'days');
      curentThis.setState({
        disableDomestic: false,
        disableArrival: false,
      }, () => {
        curentThis.handleDateChange({
          startDate: startM.format(CONFIG_DATE_MOMENT_FORMAT),
          endDate: endM.format(CONFIG_DATE_MOMENT_FORMAT),
        });
        curentThis.handleTripDuration({ tripDuration: null });
      });
    }
    await curentThis.handleInputChange(e);
    this.validator.allValid() && curentThis.setInvalidForm(false);
    const currentProperties = { ...curentThis.state.properties };
    delete currentProperties.Destination;
    delete currentProperties.destinationCountry;
    await curentThis.setState({
      ...curentThis.state,
      properties: { ...currentProperties, 'Covid-19 Add On': 'No Add On' },
    });
  };

  handleDestinationInputChange = async (e) => {
    const { curentThis } = this.props;
    const { props: { collections: { travelDestinationCountry } } } = curentThis;
    const { value, id } = e.target;
    await curentThis.handleInputChange({ target: { id, value } });
    if (id === 'destinationCountry') {
      const destination = travelDestinationCountry
        .find((country) => country.name === value)
        .extendedProperties.region;
      await curentThis.handleInputChange({ target: { id: 'Destination', value: destination } });
    } else {
      // await curentThis.handleInputChange({ target: { id: 'destinationCountry', value: '' } });
      const currentProperties = { ...curentThis.state.properties };
      delete currentProperties.destinationCountry;
      await curentThis.setState({
        ...curentThis.state,
        properties: { ...currentProperties },
      });
    }
    this.validator.allValid() && curentThis.setInvalidForm(false);

    if (noAddOnCountries.includes(value) || id === 'Destination') {
      await curentThis.setState({
        ...curentThis.state,
        properties: { ...curentThis.state.properties, 'Covid-19 Add On': 'No Add On' },
      });
    }
  };

  handleTripDuration = async ({ target: { id, value } }) => {
    const { curentThis: { handleDateChange, handleTripDuration, setInvalidForm } } = this.props;
    const dateM = Moment();
    const startDate = dateM.format(CONFIG_DATE_MOMENT_FORMAT);
    await handleTripDuration({ [id]: value });
    this.validator.allValid() && await setInvalidForm(false);
    if (value !== 'Custom') {
      const durationDays = Number(value);
      const endDate = dateM.add(durationDays - 1, 'days').format(CONFIG_DATE_MOMENT_FORMAT);
      return handleDateChange({ startDate, endDate });
    }
  };

  handleInsuredTypeInputChange = async (e) => {
    const { curentThis } = this.props;
    const { target } = e;
    curentThis.setState({
      insuredTypeError: {
        anyError: false,
        errorMessage: '',
      },
    });

    // Error handling for Adult/Senior Citizen/Children number when Insured Type is Individual
    if (target.value === 'Group') { // "Individual or Group"
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: false,
          hideSeniorField: false,
          disableChildrenField: false,
          disableSeniorField: false,
          disableAdultsField: false,
          seniorCitizenErrors: noError,
          adultsErrors: noError,
          childrenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            'Senior Citizen (66-75)': 0,
            'Senior Citizen (76-80)': 0,
            'Senior Citizen (81-85)': 0,
            Children: 0,
            Adults: 2,
          },
        });
      });
      await curentThis.handleInputChange(e);
      this.validator.allValid() && curentThis.setInvalidForm(false);
    }

    if (target.value === 'Individual') {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: false,
          hideSeniorField: false,
          disableChildrenField: false,
          disableSeniorField: false,
          disableAdultsField: false,
          seniorCitizenErrors: noError,
          adultsErrors: noError,
          childrenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            'Senior Citizen (66-75)': 0,
            'Senior Citizen (76-80)': 0,
            'Senior Citizen (81-85)': 0,
            Children: 0,
            Adults: 1,
          },
        });
      });
      await curentThis.handleInputChange(e);
      this.validator.allValid() && curentThis.setInvalidForm(false);
    }
    // Error handling for Adult/Senior Citizen/Children number when Insured Type is Family
    if (target.value === 'Family') {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: false,
          disableChildrenField: false,
          hideSeniorField: true,
          disableSeniorField: true,
          disableAdultsField: false,
          childrenErrors: noError,
          adultsErrors: noError,
          seniorCitizenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            'Senior Citizen (66-75)': 0,
            'Senior Citizen (76-80)': 0,
            'Senior Citizen (81-85)': 0,
            Children: 1,
            Adults: 1,
          },
        });
      });
      await curentThis.handleInputChange(e);
      this.validator.allValid() && curentThis.setInvalidForm(false);
    }

    // Error handling for Adult/Senior Citizen/Children number when Insured Type is Individual and Children
    if (target.value === 'Individual and Children') {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: false,
          disableChildrenField: false,
          hideSeniorField: true,
          disableSeniorField: true,
          disableAdultsField: true,
          childrenErrors: noError,
          adultsErrors: noError,
          seniorCitizenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            'Senior Citizen (66-75)': 0,
            'Senior Citizen (76-80)': 0,
            'Senior Citizen (81-85)': 0,
            Children: 1,
            Adults: 1,
          },
        });
      });
      await curentThis.handleInputChange(e);
      this.validator.allValid() && curentThis.setInvalidForm(false);
    }

    // Error handling for Adult/Senior Citizen/Children number when Insured Type is Individual and Spouse
    if (target.value === 'Individual and Spouse') {
      curentThis.forceUpdate(() => {
        curentThis.setState({
          hideChildrenField: true,
          disableChildrenField: true,
          hideSeniorField: false,
          disableSeniorField: false,
          disableAdultsField: false,
          seniorCitizenErrors: noError,
          adultsErrors: noError,
          childrenErrors: noError,
          properties: {
            ...curentThis.state.properties,
            'Senior Citizen (66-75)': 0,
            'Senior Citizen (76-80)': 0,
            'Senior Citizen (81-85)': 0,
            Adults: 2,
            Children: 0,
          },
        });
      });
      await curentThis.handleInputChange(e);
      this.validator.allValid() && curentThis.setInvalidForm(false);
    }
  };

  getSeniorCitizenCountWithExclusiveKey = (exclusiveName) => {
    const { getSeniorCitizenCount, curentThis } = this.props;
    return getSeniorCitizenCount() - Number(curentThis.state.properties[exclusiveName]);
  };

  handleSeniorCitizenInputChange = async (e) => {
    const { target } = e;
    const { curentThis } = this.props;
    const value = Number(target.value) || 0;
    const adults_count = Number(curentThis.state.properties.Adults);
    const seniors_count = this.getSeniorCitizenCountWithExclusiveKey(target.name); /*  we need the other senior citizens count not the current one */
    const children_count = Number(curentThis.state.properties.Children);

    // Check if Insured Type is empty
    if (!curentThis.state.properties['Insured Type'].length) {
      curentThis.setState({
        insuredTypeError: {
          anyError: true,
          errorMessage: 'Please select insured type.',
        },
      });
      return;
    }
    curentThis.setState({
      insuredTypeError: {
        anyError: false,
        errorMessage: '',
      },
    });

    // Check with other Insured counts for different Insured Types
    if (curentThis.state.properties['Insured Type'] === 'Group') {
      if (value + adults_count + seniors_count + children_count > 125) {
        curentThis.setState({
          seniorCitizenErrors: maximumGroupNumberError,
          childrenErrors: maximumGroupNumberError,
          adultsErrors: maximumGroupNumberError,
        });
      } else if ((value + adults_count + seniors_count) < 2 && children_count === 0) {
        curentThis.setState({
          seniorCitizenErrors: minimumGroupNumberError,
          adultsErrors: minimumGroupNumberError,
        });
      } else if (value + adults_count + seniors_count + children_count < 2) {
        curentThis.setState({
          seniorCitizenErrors: minimumGroupNumberError,
          adultsErrors: minimumGroupNumberError,
        });
      } else {
        curentThis.setState({
          seniorCitizenErrors: noError,
          adultsErrors: noError,
          childrenErrors: noError,
        });
      }
    } else if (curentThis.state.properties['Insured Type'] === 'Individual and Spouse') {
      const error = {
        anyError: true,
        errorMessage: 'The total number of Senior Citizen and Adults must be 2 for “Individual & Spouse” plan.',
      };

      if (value && adults_count) {
        const error = {
          anyError: true,
          errorMessage: 'For “Individual & Spouse” plan, you should select either 2 Adults or 2 Senior Citizens.',
        };
        curentThis.setState({
          adultsErrors: error,
          seniorCitizenErrors: error,
        });
      } else if (value + adults_count !== 2) {
        curentThis.setState({
          seniorCitizenErrors: error,
          adultsErrors: error,
        });
      } else if (value + adults_count === 2) {
        curentThis.setState({
          seniorCitizenErrors: noError,
          adultsErrors: noError,
        });
      }
    } else if (curentThis.state.properties['Insured Type'] === 'Family') {
    }
    const { target: { id, name } } = e;
    await curentThis.handleInputChange({ target: { value, id, name } });
    this.validator.allValid() && curentThis.setInvalidForm(false);
  };

  handleAdultInputChange = async (e) => {
    const { curentThis, getSeniorCitizenCount } = this.props;
    const { target } = e;
    const value = Number(target.value) || 0; // adult count
    const seniorCount = getSeniorCitizenCount();
    const childrenCount = Number(curentThis.state.properties.Children);
    const { state: { childrenErrors, adultsErrors } } = curentThis;
    // Check if Insured Type is empty
    if (!curentThis.state.properties['Insured Type'].length) {
      curentThis.setState({
        insuredTypeError: {
          anyError: true,
          errorMessage: 'Please select insured type.',
        },
      });
      return;
    }
    curentThis.setState({
      insuredTypeError: {
        anyError: false,
        errorMessage: '',
      },
    });

    // Check with other Insured counts for different Insured Types
    if (curentThis.state.properties['Insured Type'] === 'Group') { // "Individual or Group"
      if (value + seniorCount + childrenCount > 125) {
        curentThis.setState({
          adultsErrors: maximumGroupNumberError,
          seniorCitizenErrors: maximumGroupNumberError,
          childrenErrors: maximumGroupNumberError,
        });
      } else if ((value + seniorCount) < 2 && childrenCount === 0) {
        curentThis.setState({
          adultsErrors: minimumGroupNumberError,
          seniorCitizenErrors: minimumGroupNumberError,
        });
      } else if ((value + seniorCount + childrenCount) < 2) {
        curentThis.setState({
          adultsErrors: minimumGroupNumberError,
          seniorCitizenErrors: minimumGroupNumberError,
        });
      } else {
        curentThis.setState({
          adultsErrors: noError,
          seniorCitizenErrors: noError,
          childrenErrors: noError,
        });
      }
    }
    else if (curentThis.state.properties['Insured Type'] === 'Individual and Spouse') {
      if (value && seniorCount) {
        const error = {
          anyError: true,
          errorMessage: 'For “Individual & Spouse” plan, you should select either 2 Adults or 2 Senior Citizens.',
        };
        curentThis.setState({
          adultsErrors: error,
          seniorCitizenErrors: error,
        });
      } else if (value + seniorCount > 2) {
        curentThis.setState({
          adultsErrors: {
            anyError: true,
            errorMessage: 'The total number of Senior Citizen and Adults must be 2 for “Individual & Spouse” plan.',
          },
        });
      } else if (value + seniorCount < 2) {
        curentThis.setState({
          adultsErrors: {
            anyError: true,
            errorMessage: 'The total number of Senior Citizen and Adults must be 2 for “Individual & Spouse” plan.',
          },
        });
      } else if (value + seniorCount === 2) {
        curentThis.setState({
          seniorCitizenErrors: {
            anyError: false,
            errorMessage: '',
          },
          adultsErrors: {
            anyError: false,
            errorMessage: '',
          },
        });
      }
    }
    else if (curentThis.state.properties['Insured Type'] === 'Family') {
      if (value < 1) {
        curentThis.setState({
          adultsErrors: {
            anyError: true,
            errorMessage: 'Please enter the number of Adults here.',
          }
        });
      } else if (value > 2) {
        curentThis.setState({
          adultsErrors: {
            anyError: true,
            errorMessage: 'The total number of Adult cannot be more than 2 for “Family” plan.',
          }
        });
      } else if (value === 1 && childrenCount < 1) {
        curentThis.setState({
          adultsErrors: minimumFamilyAdultChildrenError,
          childrenErrors: minimumFamilyAdultChildrenError,
        });
      } else if (
        adultsErrors.errorMessage === minimumFamilyAdultChildrenError.errorMessage &&
        childrenErrors.errorMessage === minimumFamilyAdultChildrenError.errorMessage &&
        (value > 0 && childrenCount > 0 || value > 1)
      ) {
        curentThis.setState({ adultsErrors: noError, childrenErrors: noError });
      } else {
        curentThis.setState({
          adultsErrors: {
            anyError: false,
            errorMessage: '',
          },
        });
      }
    }
    else if (value + seniorCount + childrenCount > 125) {
      curentThis.setState({
        adultsErrors: maximumIndividualsNumberError,
      });
    } else {
      curentThis.setState({
        adultsErrors: {
          anyError: false,
          errorMessage: '',
        },
      });
    }

    const { target: { id, name } } = e;
    await curentThis.handleInputChange({ target: { value, id, name } });
    this.validator.allValid() && curentThis.setInvalidForm(false);
  };

  handleChildrenInputChange = async (e) => {
    const { curentThis, getSeniorCitizenCount } = this.props;
    const { target } = e;
    const value = Number(target.value) || 0;
    const adults_count = Number(curentThis.state.properties.Adults);
    const senior_count = getSeniorCitizenCount();
    const { state: { childrenErrors, adultsErrors } } = curentThis;

    // Check if Insured Type is empty
    if (!curentThis.state.properties['Insured Type'].length) {
      curentThis.setState({
        insuredTypeError: {
          anyError: true,
          errorMessage: 'Please select insured type.',
        },
      });
      return;
    }
    // this.setState({insuredTypeError: this.initialState.insuredTypeError});
    curentThis.setState({
      insuredTypeError: {
        anyError: false,
        errorMessage: '',
      },
    });

    // Check with other Insured counts for different Insured Types
    if (curentThis.state.properties['Insured Type'] === 'Family') {
      if (value > 3) {
        curentThis.setState({
          childrenErrors: {
            anyError: true,
            errorMessage: 'The total number of Children cannot be more than 3 for “Family” plan.',
          },
        });
      // } else if (value === 0) {
      //   curentThis.setState({
      //     childrenErrors: {
      //       anyError: true,
      //       errorMessage: 'Please enter the number of Children here.',
      //     },
      //   });
      } else if (value === 0 && adults_count <= 1) {
        curentThis.setState({
          adultsErrors: minimumFamilyAdultChildrenError,
          childrenErrors: minimumFamilyAdultChildrenError,
        });
      } else if (
        adultsErrors.errorMessage === minimumFamilyAdultChildrenError.errorMessage &&
        childrenErrors.errorMessage === minimumFamilyAdultChildrenError.errorMessage &&
        (value === 0 && adults_count > 1 || value >= 1 && adults_count >= 1)
      ) {
        curentThis.setState({ adultsErrors: noError, childrenErrors: noError });
      } else if (value >= 0 && value <= 3) {
        curentThis.setState({ childrenErrors: noError });
      }
    } else if (curentThis.state.properties['Insured Type'] === 'Individual and Children') {
      if (value > 3) {
        curentThis.setState({
          childrenErrors: {
            anyError: true,
            errorMessage: 'The total number of Children cannot be more than 3 for Individual and Children" plan.',
          },
        });
      } else if (value === 0) {
        curentThis.setState({
          childrenErrors: {
            anyError: true,
            errorMessage: 'Please enter the number of Children here.',
          },
        });
      } else if (value > 0 && value <= 3) {
        curentThis.setState({
          childrenErrors: {
            anyError: false,
            errorMessage: '',
          },
        });
      }
    } else if (curentThis.state.properties['Insured Type'] === 'Group') { // "Individual or Group"
      if (value + senior_count + adults_count > 125) {
        curentThis.setState({
          seniorCitizenErrors: maximumIndividualsNumberError,
          adultsErrors: maximumIndividualsNumberError,
          childrenErrors: maximumIndividualsNumberError,
        });
      } else if ((adults_count + senior_count === 0) || ((value + senior_count + adults_count) < 2)) {
        curentThis.setState({
          adultsErrors: minimumGroupNumberError,
          seniorCitizenErrors: minimumGroupNumberError,
        });
      } else {
        curentThis.setState({
          childrenErrors: noError,
          adultsErrors: noError,
          seniorCitizenErrors: noError,
        });
      }
    } else {
      curentThis.setState({
        childrenErrors: {
          anyError: false,
          errorMessage: '',
        },
      });
    }
    const { target: { id, name } } = e;
    await curentThis.handleInputChange({ target: { value, id, name } });
    this.validator.allValid() && curentThis.setInvalidForm(false);
  };

  handleStartDateChange = (date) => {
    const { curentThis: { handleChangeStart, handleDateChange, state: { properties: { tripDuration } } } } = this.props;
    const dateM = Moment(date, CONFIG_DATE_MOMENT_FORMAT);
    const startDate = dateM.format(CONFIG_DATE_MOMENT_FORMAT);
    if (tripDuration && tripDuration !== 'Custom') {
      const durationDays = Number(tripDuration);
      const endDate = dateM.add(durationDays - 1, 'days').format(CONFIG_DATE_MOMENT_FORMAT);
      return handleDateChange({ startDate, endDate });
    }
    handleChangeStart(startDate);
  };

  handleEndDateChange = async (date) => {
    const { curentThis } = this.props;
    const dateM = Moment(date, CONFIG_DATE_MOMENT_FORMAT);
    await curentThis.handleChangeEnd(dateM.format(CONFIG_DATE_MOMENT_FORMAT));
    this.validator.showMessageFor('end date');
  }

  computeOpenToDate = (type, currentThis) => {
    let date;
    if (currentThis && currentThis.state && currentThis.state.properties) {
      if (type === 'start') date = currentThis.state.properties['Start Date'];

      else if (type === 'end') date = currentThis.state.properties['End Date'];
    }
    const currentDate = moment().format(CONFIG_DATE_MOMENT_FORMAT);
    return date || currentDate;
  }

  handleAddOnChange = (currentThis, id) => {
    if (currentThis && currentThis.state && currentThis.state.properties) {
      currentThis.forceUpdate(() => {
        currentThis.setState({
          ...currentThis.state,
          properties: {
            ...currentThis.state.properties,
            'Covid-19 Add On': id,
          },
        });
      });
    }
  };

  radioButtonHandleChange = (currentThis, id) => {
    if (currentThis && currentThis.state && currentThis.state.properties) {
      currentThis.forceUpdate(() => {
        currentThis.setState({
          ...currentThis.state,
          properties: {
            ...currentThis.state.properties,
            'Senior Citizen (66-75)': id === 'Senior Citizen (66-75)' ? 1 : 0,
            'Senior Citizen (76-80)': id === 'Senior Citizen (76-80)' ? 1 : 0,
            'Senior Citizen (81-85)': id === 'Senior Citizen (81-85)' ? 1 : 0,
            Adults: id === 'Adults' ? 1 : 0,
          },
        }, () => currentThis.handleMainInsuredTypeChange());
      });
    }
    this.setState({
      ...this.state,
      individualIsAdult: id === 'Adults',
    }, () => currentThis.handleMainInsuredTypeChange());
  }

  render() {
    const {
      curentThis,
      curentThis: { state: { properties }, props: { collections } },
    } = this.props;
    // const { collections } = this.props.curentThis.props;
    const regionsToDisplay = collections && collections.regions.map((region) => {
      // this conversion in not currently needed but we are keeping it for potential future use.
      // if (curentThis.state.disableDomestic && region.name.includes('Domestic')) disabled = true;
      return { ...region, disabled: false };
    });
    const {
      seniorCitizenErrors, adultsErrors, insuredTypeError, childrenErrors,
    } = curentThis.state;
    const { computeOpenToDate } = this;
    const maxEndDate = getMaximumReturnTripEndDate(
      properties['Start Date']
        ? Moment(properties['Start Date'], CONFIG_DATE_MOMENT_FORMAT)
        : Moment(new Date(), CONFIG_DATE_MOMENT_FORMAT),
      utils.constants.defaultAlfalahTravelMaximumNoOfReturnDays,
    );
    this.validator.purgeFields();
    return (
      <div className='row my_50 justify-content-center'>
        <div className='col-12 col-md-10'>
          <h5 className='sub-title'>Fill in trip details</h5>
          <Form>
            <FormGroup>
              <CommonInputTypes
                idName='Trip Type'
                iconName='aeroplane'
                group
                type='select'
                option={collections !== undefined ? collections.tripTypes : []}
                value={curentThis.state.properties['Trip Type']}
                onChange={this.handleTripTypeInputChange}
                title='Trip Type'
                id='1'
                validator={this.validator}
                autoFocus
              />
              {properties['Trip Type'] === 'Single Trip' && (
                <CommonInputTypes
                  idName='destinationCountry'
                  iconName='placeholder'
                  group
                  type='select'
                  option={collections.travelDestinationCountry || []}
                  value={curentThis.state.properties.destinationCountry}
                  onChange={this.handleDestinationInputChange}
                  title='Country'
                  id='2'
                  validator={this.validator}
                />
              )}
              <CommonInputTypes
                idName='Destination'
                iconName='placeholder'
                group
                type='select'
                option={regionsToDisplay || []}
                value={curentThis.state.properties.Destination}
                onChange={this.handleDestinationInputChange}
                title='Destination'
                id='2'
                disabled={curentThis.state.properties['Trip Type'] === 'Single Trip'}
                validator={this.validator}
              />
              {properties['Trip Type'] === 'Single Trip' && (
                <CommonInputTypes
                  idName='tripDuration'
                  iconName='aeroplane'
                  group
                  type='select'
                  option={collections.tripDurations || []}
                  value={curentThis.state.properties.tripDuration}
                  onChange={this.handleTripDuration}
                  title='Trip Duration'
                  id='2'
                  validator={this.validator}
                />
              )}
              <FormGroup>
                <Row>
                  <Col md={6} xs='auto'>
                    <Label for='Start Date'>Start Date</Label>
                    <InputGroup className='fill-children-width'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText className='terminal-icon'>
                          <i className='icon-depart' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <div className='terminal-input'>
                        <CommonInputTypes
                          dateStyle={{
                            marginBottom: 0,
                          }}
                          validator={this.validator}
                          idName='Start Date'
                          placeholder='Select Start Date'
                          group={false}
                          type='date'
                          value={properties['Start Date'] ? properties['Start Date'] : null}
                          startDate={properties['Start Date'] ? properties['Start Date'] : null}
                          endDate={properties['End Date'] ? properties['End Date'] : null}
                          onChange={this.handleStartDateChange} // datepicker onchange
                          minDate={moment().format(CONFIG_DATE_MOMENT_FORMAT)}
                          openToDate={computeOpenToDate('start', curentThis)}
                          id='2'
                          // handleInputDateChange = {(date) => this.handleStartInputDateChange(date)} //text input on change
                        />
                      </div>
                    </InputGroup>
                  </Col>
                  <Col md='6' xs='auto'>
                    <Label for='End Date'>End Date</Label>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText className='terminal-icon'>
                          <i className='icon-arrival' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <div className='terminal-input'>
                        <CommonInputTypes
                          dateStyle={{
                            marginBottom: 0,
                          }}
                          className={`${curentThis.state.disableArrival || curentThis.state.properties.tripDuration !== 'Custom' ? 'custom-disabled' : ''}`}
                          disabled={curentThis.state.disableArrival || curentThis.state.properties.tripDuration !== 'Custom'}
                          validator={this.validator}
                          placeholder='Select End Date'
                          idName='End Date'
                          group={false}
                          type='date'
                          value={properties['End Date'] ? properties['End Date'] : null}
                          startDate={properties['Start Date'] ? properties['Start Date'] : null}
                          endDate={properties['End Date'] ? properties['End Date'] : null}
                          onChange={this.handleEndDateChange} // datepicker onchange
                          id='2'
                          maxDate={maxEndDate}
                          minDate={moment().format(CONFIG_DATE_MOMENT_FORMAT)}
                          openToDate={computeOpenToDate('end', curentThis)}
                          // handleInputDateChange = {(date) => this.handleEndInputDateChange(date)} //text input on change
                        />
                      </div>
                    </InputGroup>
                    {properties['Trip Type'] === 'Single Trip' && this.validator.message(
                      'end date',
                      {
                        startDate: properties['Start Date'],
                        endDate: properties['End Date'],
                        maxNoOfDay: utils.constants.defaultAlfalahTravelMaximumNoOfReturnDays,
                      },
                      'validate_maximum_return_days',
                    )}
                  </Col>
                </Row>
              </FormGroup>
              <CommonInputTypes
                idName='Insured Type'
                iconName='user'
                group
                type='select'
                option={collections ? collections.insuredType : []}
                value={curentThis.state.properties['Insured Type']}
                onChange={this.handleInsuredTypeInputChange}
                title='Insured Type'
                id='1'
                validator={this.validator}
              >
                {insuredTypeError.anyError
                && <span className='error'>{insuredTypeError.errorMessage}</span>}
              </CommonInputTypes>
              {
                curentThis.state.properties['Insured Type'] !== 'Individual' // check the insuredType for showing options
                  ? (
                    <FormGroup>
                      <Row>
                        <Col xs='12' md='6' lg='4'>
                          <CommonInputTypes
                            idName='Adults'
                            iconName='user'
                            group
                            type='number'
                            placeholder='0'
                            option={countArray}
                            value={curentThis.state.properties.Adults}
                            onChange={this.handleAdultInputChange}
                            title='Adult (18-65)'
                            id='1'
                            validator={this.validator}
                            disabled={curentThis.state.disableAdultsField}
                            min={0}
                          >
                            {adultsErrors.anyError
                      && <span className='error'>{adultsErrors.errorMessage}</span>}
                          </CommonInputTypes>
                        </Col>
                        {!curentThis.state.hideChildrenField
                  && (
                  <Col xs='12' md='6' lg='4'>
                    <CommonInputTypes
                      idName='Children'
                      iconName='user'
                      group
                      type='number'
                      placeholder='0'
                      option={countArray}
                      value={curentThis.state.properties.Children}
                      onChange={this.handleChildrenInputChange}
                      title='Child (0-17)'
                      id='1'
                      validator={this.validator}
                      disabled={curentThis.state.disableChildrenField}
                      min={0}
                    >
                      {childrenErrors.anyError
                      && <span className='error'>{childrenErrors.errorMessage}</span>}
                    </CommonInputTypes>
                  </Col>
                  )}
                        {!curentThis.state.hideSeniorField
                  && (
                  <>
                    <Col xs='12' md='6' lg='4'>
                      <CommonInputTypes
                        idName='Senior Citizen (66-75)'
                        title='Senior Citizen (66-75)'
                        iconName='user'
                        group
                        type='number'
                        placeholder='0'
                        min={0}
                        option={countArray}
                        value={curentThis.state.properties['Senior Citizen (66-75)']}
                        onChange={this.handleSeniorCitizenInputChange}
                        disabled={curentThis.state.disableSeniorField}
                        id='1'
                        validator={this.validator}
                      >
                        {seniorCitizenErrors.anyError
                        && <span className='error'>{seniorCitizenErrors.errorMessage}</span>}
                      </CommonInputTypes>
                    </Col>
                    <Col xs='12' md='6' lg='4'>
                      <CommonInputTypes
                        idName='Senior Citizen (76-80)'
                        title='Senior Citizen (76-80)'
                        iconName='user'
                        group
                        type='number'
                        placeholder='0'
                        min={0}
                        option={countArray}
                        value={curentThis.state.properties['Senior Citizen (76-80)']}
                        onChange={this.handleSeniorCitizenInputChange}
                        disabled={curentThis.state.disableSeniorField}
                        id='1'
                        validator={this.validator}
                      >
                        {seniorCitizenErrors.anyError
                        && <span className='error'>{seniorCitizenErrors.errorMessage}</span>}
                      </CommonInputTypes>
                    </Col>
                    <Col xs='12' md='6' lg='4'>
                      <CommonInputTypes
                        idName='Senior Citizen (81-85)'
                        title='Senior Citizen (81-85)'
                        iconName='user'
                        group
                        type='number'
                        placeholder='0'
                        min={0}
                        option={countArray}
                        value={curentThis.state.properties['Senior Citizen (81-85)']}
                        onChange={this.handleSeniorCitizenInputChange}
                        disabled={curentThis.state.disableSeniorField}
                        id='1'
                        validator={this.validator}
                      >
                        {seniorCitizenErrors.anyError
                        && <span className='error'>{seniorCitizenErrors.errorMessage}</span>}
                      </CommonInputTypes>
                    </Col>
                  </>
                  )}
                      </Row>
                    </FormGroup>
                  )
                  : (
                    <FormGroup>
                      <Row>
                        <Col md='6' xs='12'>
                          <CommonInputTypes
                            type='radio'
                            idName='Adults'
                            title='Adult (18-65)'
                            checked={this.state.individualIsAdult === true}
                            onChange={(e) => this.radioButtonHandleChange(curentThis, e)}
                            iconName={curentThis.state.properties.Adults === 1 ? 'radio-checked' : 'radio-unchecked'}
                          />
                        </Col>
                        <Col md='6' xs='12'>
                          <CommonInputTypes
                            type='radio'
                            idName='Senior Citizen (66-75)'
                            title='Senior Citizen (66-75)'
                            checked={this.state.individualIsAdult === false}
                            onChange={(e) => this.radioButtonHandleChange(curentThis, e)}
                            iconName={curentThis.state.properties['Senior Citizen (66-75)'] === 1 ? 'radio-checked' : 'radio-unchecked'}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CommonInputTypes
                            type='radio'
                            idName='Senior Citizen (76-80)'
                            title='Senior Citizen (76-80)'
                            checked={this.state.individualIsAdult === false}
                            onChange={(e) => this.radioButtonHandleChange(curentThis, e)}
                            iconName={curentThis.state.properties['Senior Citizen (76-80)'] === 1 ? 'radio-checked' : 'radio-unchecked'}
                          />
                        </Col>
                        <Col>
                          <CommonInputTypes
                            type='radio'
                            idName='Senior Citizen (81-85)'
                            title='Senior Citizen (81-85)'
                            checked={this.state.individualIsAdult === false}
                            onChange={(e) => this.radioButtonHandleChange(curentThis, e)}
                            iconName={curentThis.state.properties['Senior Citizen (81-85)'] === 1 ? 'radio-checked' : 'radio-unchecked'}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  )
              }
            </FormGroup>
            { properties.destinationCountry && properties.destinationCountry !== '' && !noAddOnCountries.includes(properties.destinationCountry) && properties['Trip Type'] === 'Single Trip' && (
              <FormGroup>
                <Label>Covid-19 Add-On?</Label>
                <Row>
                  <Col md='6' xs='12'>
                    <CommonInputTypes
                      type='radio'
                      idName='No Add On'
                      title='No Add On'
                      checked={curentThis.state.properties['Covid-19 Add On'] !== ''}
                      onChange={(e) => this.handleAddOnChange(curentThis, e)}
                      iconName={
                        curentThis.state.properties['Covid-19 Add On'] === 'No Add On'
                          ? 'radio-checked' : 'radio-unchecked'
                      }
                    />
                  </Col>
                  <Col md='6' xs='12'>
                    <CommonInputTypes
                      type='radio'
                      idName='COVID-19 Medical Expenses of USD 5,000'
                      title='COVID-19 Medical Expenses of USD 5,000'
                      checked={curentThis.state.properties['Covid-19 Add On'] !== ''}
                      onChange={(e) => this.handleAddOnChange(curentThis, e)}
                      iconName={
                        curentThis.state.properties['Covid-19 Add On'] === 'COVID-19 Medical Expenses of USD 5,000'
                          ? 'radio-checked' : 'radio-unchecked'
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CommonInputTypes
                      type='radio'
                      idName='COVID-19 Medical Expenses of USD 25,000'
                      title='COVID-19 Medical Expenses of USD 25,000'
                      checked={curentThis.state.properties['Covid-19 Add On'] !== ''}
                      onChange={(e) => this.handleAddOnChange(curentThis, e)}
                      formGroupClasses='mb-0'
                      iconName={
                        curentThis.state.properties['Covid-19 Add On'] === 'COVID-19 Medical Expenses of USD 25,000'
                          ? 'radio-checked' : 'radio-unchecked'
                      }
                    />
                  </Col>
                  <Col>
                    <CommonInputTypes
                      type='radio'
                      idName='COVID-19 Medical Expenses of USD 50,000'
                      title='COVID-19 Medical Expenses of USD 50,000'
                      checked={curentThis.state.properties['Covid-19 Add On'] !== ''}
                      onChange={(e) => this.handleAddOnChange(curentThis, e)}
                      formGroupClasses='mb-0'
                      iconName={
                        curentThis.state.properties['Covid-19 Add On'] === 'COVID-19 Medical Expenses of USD 50,000'
                          ? 'radio-checked' : 'radio-unchecked'
                      }
                    />
                  </Col>
                </Row>
                {properties['No. of Days'] && properties['No. of Days'] > 90 && (
                  <Row>
                    <Col>
                      <small className='text-primary font-italic'>
                        COVID-19 Add On coverage will be capped at 90 days of Travel Duration
                      </small>
                    </Col>
                  </Row>
                )}
              </FormGroup>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default TripDetails;
