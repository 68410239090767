import env from 'env';
import Request from './request';
import Response from './response';

class ScopeManagementService{

    protected env = env.tsd;
    protected api = 'authorization/resources';

    public getList = async (): Promise<any> => {
        const url = `${this.env}/${this.api}/tree`;
        const request = new Request();
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public add = async (body:IObject): Promise<any> => {
        const url = `${this.env}/${this.api}`;
        const request = new Request({ ...body }, 'POST');
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public edit = async (body:IObject,name:string): Promise<any> => {
        const url = `${this.env}/${this.api}/${name}`;
        const request = new Request({ ...body }, 'PUT');
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public deleteResource = async (name:string): Promise<any> => {
        const url = `${this.env}/${this.api}/${name}`;
        const request = new Request(null, 'DELETE');
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public getDetail = async (name:string): Promise<any> => {
        const url = `${this.env}/${this.api}/${name}/tree`;
        const request = new Request();
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

    public search = async (key:string): Promise<any> => {
        const url = `${this.env}/${this.api}/search?name=${key}`;
        const request = new Request();
        const response = new Response(url, request);
        const result = await response.handle();
        return result.data;
    }

}

export default ScopeManagementService;