import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  CardFooter,
} from "reactstrap";

const FilterFooter = (props) => {
  const { onApplyClick, onCancelClick } = props;
  return (
    <CardFooter className="text-muted">
      <div className="row justify-content-end" id="modalButton">
        <div className="col-2">
          <Button
            className="text-info"
            color="link"
            onClick={() => onCancelClick()}
          >
            Cancel
          </Button>
        </div>
        <div className="col-3">
          <Button
            // disabled={isApplyDisabled}
            onClick={() => onApplyClick()}
            color="primary"
            className="fullWidth rounded-xl"
          >
            Apply
          </Button>
        </div>
      </div>
    </CardFooter>
  );
};
FilterFooter.propTypes = {
  // isApplyDisabled: PropTypes.bool,
  onCancelClick: PropTypes.func.isRequired,
  onApplyClick: PropTypes.func.isRequired,
};
FilterFooter.defaultProps = {
  // isApplyDisabled: false,
};

export default FilterFooter;
