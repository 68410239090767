import React from 'react';
import { Row,Col,Button } from 'reactstrap';
import { downloadCertificate } from "../../../shared/GetQuote/services";


class ThankYouPage extends React.Component{
  downloadCertificate = async () => {
    const certificateId = this.props.certificateId;
    if (!certificateId) return alert("policy id not available");
    const blob = await downloadCertificate(certificateId);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "certificate.pdf");  
    }
    else {
      const downloadUrl = URL.createObjectURL(blob);
      window.open(downloadUrl, '_blank');  
    }
  };
  render(){
    return(
      <div className="text-center">
          <h3>
          Thank you!
          </h3>
          <p>
          The Certificate of Insurance has been generated and sent to the Main Insured’s email address.
          </p>
          <Row className="justify-content-center mt-5">
            <Col sm="6" lg="4" xl="3" >
            <Button
              onClick={this.downloadCertificate}
              className="col-12 my-2 rounded-xl" outline color="primary">View Certificate</Button>
            </Col>
            <Col sm="6" lg="4" xl="3">
              <Button onClick={this.props.handleReset} className="col-12 my-2 rounded-xl" color="primary">Get Another Quote</Button>
            </Col>
          </Row>
      </div>
    )
  }
}

export default ThankYouPage;