import React from "react";
import ReactJson from 'react-json-view'
import {connect} from "react-redux";
import {Button, Input} from "reactstrap";
import documentationActions from "../actions";
import Styled from "styled-components";

const AsideContainer = ({request, setRequestObject, response, generateResponse, reset, headers, handleHeaderChange}) => {
  const MethodContainer = Styled.small`
  &:before {
        position: absolute;
        display: inline-table;
        bottom: 30px;
        left: -12px;
        content: 'Try Out';
        color: #333;
        border-bottom: 5px #fff solid;
        margin: 10px;
        padding: 0 5px 0 5px;
        line-height: 14px;
        width: 100%;
    }
  position: relative
  border: 1px #fff solid;
  color: #fff;
  margin: 5px;
  padding: 5px;
  border-radius: 6px;
  `;
  const SlugContainer = Styled.small`
  color: #fff;
  margin: 5px;
  padding: 5px;
  `;

  return (
    <div className="side-fixed-container">
      <div className="d-flex flex-column" style={{height: "50%"}}>
        <div className="p-3">
          <MethodContainer>{request.method || "method"}</MethodContainer>
          <SlugContainer>{request.path || ""}</SlugContainer>
        </div>
        <div className="shadow-sm pl-3 pr-3">
          <small>Headers</small>
          <table className="headers-table ">
            <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {headers.map((header, index) => (
              <tr key={index}>
                <td>{header.key}</td>
                <td>
                  <Input
                    placeholder={`enter ${header.key}`}
                    value={header.value}
                    name={header.key}
                    onChange={({target: {value, name}}) => handleHeaderChange({value, key: name})}
                  />
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        {/********************************************************************************/}
        <div className="flex-grow-1 overflow-auto">
          {/*<p className="hidden_content">this heading is entirely for styling purposes and should never be displayed on*/}
          {/*  the*/}
          {/*  page</p>*/}
          <ReactJson
            src={{...request.tempRequestObject}}
            name={false}
            displayDataTypes={false}
            displayObjectSize={false}
            theme="monokai"
            onEdit={({updated_src}) => { setRequestObject({...request, tempRequestObject: updated_src})}}
            enableClipboard={false}
            style={{
              fontSize: "0.7em",
              backgroundColor: "transparent",
              padding: "15px",
              // maxHeight: "250px",
              // borderRadius: "7px",
              // overflow: "auto"
            }}
          />
          {/*<p className="m-0">{`{`}</p>*/}
          {/*<TextArea*/}
          {/*  value={request.requestObjectString}*/}
          {/*  className="bg-transparent borderRadius text-white border-0 ml-4"*/}
          {/*  onChange={({target: {value}}) => setRequestObject({...request, requestObjectString: value.trim()})}*/}
          {/*/>*/}
          {/*<p>{`}`}</p>*/}
        </div>
        {/***************************ce with a modern JS framework (AngularJS, Reac*****************************************************/}
        <div className="d-flex justify-content-end my-2">
          <Button className="bg-transparent border-0" onClick={() => reset()}>Reset</Button>
          <Button
            className="bg-white text-dark"
            onClick={() => generateResponse(request)}
          >Generate</Button>
        </div>
      </div>
      <div className="bg-info p-2 overflow-auto" style={{height: "50%", borderRadius: "15px"}}>
        <h6 className="m-2">Results</h6>
        <div className="p-3">
          <ReactJson
            src={{...response}}
            name={false}
            displayDataTypes={false}
            enableClipboard={false}
            displayObjectSize={false}
            // onEdit={(e) => {
            //   debugger;
            // }}
            style={{fontSize: "0.9em"}}
          />

          {/*<pre>{`${Object.keys(response).length ? JSON.stringify(response, null, 3) : ""}`}</pre>*/}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({documentation}) => {
  const {request, response, headers} = documentation;
  return {request, response, headers};
};
const mapDispatchToProps = (dispatch) => ({
  setRequestObject: (requestObj) => dispatch(documentationActions.setRequestObject(requestObj)),
  generateResponse: (requestObj) => dispatch(documentationActions.generateResponse(requestObj)),
  handleHeaderChange: (newHeader) => dispatch(documentationActions.handleHeaderChange(newHeader)),
  reset: () => dispatch(documentationActions.reset())
});
export default connect(mapStateToProps, mapDispatchToProps)(AsideContainer);