import React from 'react';
import { objLib } from '@xc-core/lib';
import Pair from './pair';

const createInsuredsTitle = (model: any, item: any) => (model.map((type: any) => (
  {
    id: type.id,
    type: type.type,
    count: Number(objLib.getValueFromPath(item, type.id)),
    titleIndex: 0,
  }
)));

const Title = (props: {title: string}) => {
  const { title } = props;
  return (<h4 className='insured-detail-header my-3 p-3'>{title}</h4>);
};

interface IInsuredTypes { id: string, type: string }
interface IModel { main: [any], other: [any], beneficiary: [any] }
interface IItem { insureds: [any], beneficiaries: [any] }
interface IProps { model: IModel, item: IItem, insuredTypes: [IInsuredTypes] }
const Detail = (props: IProps) => {
  const { model, item: { insureds, beneficiaries }, insuredTypes } = props;

  const getOtherInsuredPair = () => {
    const others = insureds.length > 1 ? [...insureds.slice(1)] : [];
    const othersBeneficiaries = beneficiaries && beneficiaries.length > 1 ? [...beneficiaries.slice(1)] : [];
    return others.map((other, index) => (
      { insured: other, beneficiary: othersBeneficiaries[index] }
    ));
  };

  const mainInsuredPair = {
    insured: insureds.length > 0 ? insureds[0] : null,
    beneficiary: beneficiaries && beneficiaries.length > 0 ? beneficiaries[0] : null,
  };
  const otherInsuredPair = getOtherInsuredPair();
  let insuredsTitle = insuredTypes ? createInsuredsTitle(insuredTypes, mainInsuredPair.insured)
    : [];
  const mainInsuredTitleInfo = insuredsTitle.find((type: any) => type.count > 0);

  const UpdateInsuredsTitleIndex = (type: string) => {
    const itemIndex = insuredsTitle.findIndex((title: any) => (title.type === type));
    insuredsTitle = [
      ...insuredsTitle.slice(0, itemIndex),
      {
        ...insuredsTitle[itemIndex],
        titleIndex: insuredsTitle[itemIndex].titleIndex + 1,
      },
      ...insuredsTitle.slice(itemIndex + 1),
    ];
  };

  const getTitle = (type: string, additionalText = '') => {
    UpdateInsuredsTitleIndex(type);
    const itemIndex = insuredsTitle.findIndex((title: any) => (title.type === type));
    return `${type} [${insuredsTitle[itemIndex].titleIndex}] ${additionalText}`;
  };

  const hasDifferentInsuredTypes = insuredTypes && insuredTypes.length > 0;
  return (
    <>
      <Pair
        model={{ insured: model.main, beneficiary: model.beneficiary }}
        item={mainInsuredPair}
      >
        {hasDifferentInsuredTypes ? <Title title={getTitle(mainInsuredTitleInfo.type, '(Main Applicant)')} /> : <></>}
      </Pair>
      {
        otherInsuredPair.map((other: any) => (
          <Pair
            key={other.insured.id}
            model={{ insured: model.other, beneficiary: model.beneficiary }}
            item={other}
          >
            {hasDifferentInsuredTypes ? <Title title={getTitle(other.insured.Type)} /> : <></>}
          </Pair>
        ))
      }
    </>
  );
};

export default Detail;
