const model = {
    body: {
        items: {
            mainApplicant: {
                id: 'properties.main applicant',
                items: [
                    { id: 'properties.main applicant.Full Name', name: 'Full Name', config: {} },
                    { id: 'properties.main applicant.DOB', name: 'DOB', config: {} },
                    { id: 'properties.main applicant.ID Type', name: 'ID Type', config: {} },
                    { id: 'properties.main applicant.ID Number', name: 'ID Number', config: {} },
                    { id: 'properties.main applicant.Gender', name: 'Gender', config: {} },
                    { id: 'properties.main applicant.Email Address', name: 'Email Address', config: {} },
                    { id: 'properties.main applicant.Mobile Number', name: 'Mobile Number', config: {} },
                    { id: 'properties.main applicant.Address', name: 'Address', config: {} },
                    { id: 'properties.main applicant.City', name: 'City', config: {} },
                    { id: 'properties.main applicant.State', name: 'State / Province / Region', config: {} },
                    { id: 'properties.main applicant.Postcode', name: 'Postcode', config: {} },
                    { id: 'properties.main applicant.Country', name: 'Country', config: {} },
                ],
                config: {},
            },
            insuredProperty: {
                id: 'properties.Insured Property',
                items: [
                    { id: 'properties.Insured Property.Address', name: 'Address', config: {} },
                    { id: 'properties.Insured Property.City', name: 'City', config: {} },
                    { id: 'properties.Insured Property.State', name: 'State/Province/Region', config: {} },
                    { id: 'properties.Insured Property.Postcode', name: 'Postcode', config: {} },
                    { id: 'properties.Insured Property.Country', name: 'Country', config: {} },

                ],
                config: {},
            },
        },
        config: {},
    },
};

export default model;
