import React from 'react';
import {Row, Col} from 'reactstrap';
import {utils} from 'utils';

const RenderDetails = (props) => {
  const { model, item, type} = props;
  return (
    <>
      {
        model ? model.items.map((modelItem) => {
          return <RenderDetail key={modelItem.id} model={modelItem} item={item} type={type} />;
        }) : <></>
      }
    </>
  );
};

const RenderDetail = (props) => {
  const {item, model, type} = props;
  const { lib: { resolveObjKey } } = utils;
  return (
    <>
      <Col className='pt-2 summary-label' sm={6}>{model.name}</Col>
      <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
      {item ? resolveObjKey(
        item, model.id.replace(type === 'owner' ? "properties.Car Owner Details." : "properties.Car Details.", "")) : ''}
        </Col>
    </>
  );
};
const RenderInsuredDetails = (props) => {
  const {model, item} = props;
  const carOwnerDetails = item?.properties["Car Owner Details"];
  const carDetails = item?.properties["Car Details"];
  return (
    <Row>
      <Col sm={12}>
        <Row className='wordBreak'>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Car Owner's Complete Details</div>
            <Row>
                <RenderDetails model={model.carOwnerDetails} item={carOwnerDetails} type='owner'/>
            </Row>
          </Col>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Car Details</div>
            <Row>
                <RenderDetails model={model.carDetails} item={carDetails} type='car'/>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    );
};
export default RenderInsuredDetails;
