import React ,{useEffect} from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from '@xc-core/components/core';

interface IButton { label: string, type: 'submit' | 'button' | 'reset', action: any }

interface IFooterProps {
  onNext: () => void,
  onPrevious: () => void,
  onSkip: () => void,
  sendingRequest: boolean,
  invalidForm: boolean,
  view: IObject,
  isPaymentSelected: boolean,
  isKhmer?: boolean,
  isBeneficirayEnterd: boolean,
  currentStep: IStep,
}
const Footer = (props: IFooterProps) => {
  const {
    onNext,
    onPrevious,
    onSkip,
    sendingRequest,
    invalidForm,
    view,
    isPaymentSelected,
    isKhmer,
    isBeneficirayEnterd,
    currentStep,
  } = props;
  return (
    <Row className='text-right justify-content-end'>
      {
        view.actionButtons.map((button: IButton) => {
          var label = button.label;
          if (currentStep.name === 'Payment Details') {
            label = isPaymentSelected ? 'Next' : 'Skip'; // dirty because this view is not dynamic currently,
            // and front only has one button here which is Skip
          }
          
          // its dirty it can be manage better
          if (currentStep.name === 'Beneficiaries' && !isBeneficirayEnterd && button.label === 'Next') {
            return null;
          }
          if (currentStep.name === 'Beneficiaries' && isBeneficirayEnterd && button.label === 'Skip') {
            return null;
          }

          if(isKhmer){
            label += (label === 'Skip') ? ' / រំលង' : (label === 'Previous') ? ' / ត្រលប់ក្រោយ' : ' / ទៅមុខ'
          }

          return (
            <Col sm='2' key={label}>
              <Button
                id={label}
                name={label}
                className={`btn ${button.type === 'submit' ? 'btn-primary' : 'btn-light '}`}
                handler={
                  button.action === 'next' ? onNext
                  : button.action === 'skip' ? onSkip
                  : onPrevious}
                disabled={button.type === 'submit' ? sendingRequest || invalidForm : sendingRequest}
              />
            </Col>
          );
        })
      }
    </Row>
  );
};

export default Footer;
