import React, {useEffect,useState, useRef, useCallback} from 'react';
import { connect } from "react-redux";
import {useParams, useLocation} from 'react-router-dom';
import {
    Col, Row, Input
  } from 'reactstrap';
import { actions } from '@redux';
import Select from "react-select";
import { ScopeManagementActions } from '@redux/actions';
import {
    objLib, handleError
  } from '@xc-core/lib';
import {ScopeManagementService} from '@xc-core/services';
import { Loader } from '@xc-core/components/core';
import { Header } from '../../@xc-core/components/core/header';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import simpleValidator from '@xc-core/lib/validation';
import Field from '@containers/getQuote/wizard/field';    
import {nameModel, resourceModel, methodModel,
     typeModel, displayNameModel, linkModel,
     parentResourceModel, dependenciesModel,
     auditedModel, isPermissionVisibaleModel,
     isNavVisibleModel, iconModel, visibleToModel
    } from "./model";

const scopeManagementActions = actions.ScopeManagementActions;

const defaultItem = {
    id: null,
    name:"",
    resource: "",
    method: "",
    description: "",
    parentResource: "",
    type: "",
    dependencies: [],
    orderGroup: "",
    audited: false,
    extendedProperties:{
        isPermissionVisibale: false,
        isNavVisible: false,
        icon: "",
        displayName:"",
        link: "",
        isPermissionVisibleType: []
    }
}
const colourStyles = {
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: "none",
        border: "1px solid #2B61B4",
        borderRadius: "4px"
      };
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "#2B61B4"
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "#2B61B4",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "none",
        color: "red"
      }
    })
  };

const iconOptions = [
    {value:'hospital', id:'hospital'},
    {value:'wallet', id:'wallet'},
    {value:'planeProduct1', id:'planeProduct1'},
    {value:'plan', id:'plan'},
    {value:'dooKey', id:'dooKey'},
    {value:'padLock', id:'padLock'},
    {value:'teamDirectory', id:'teamDirectory'},
    {value:'search', id:'search'},
    {value:'planeProduct', id:'planeProduct'},
    {value:'add', id:'add'},
    {value:'leftArrow', id:'leftArrow'},
    {value:'downArrow', id:'downArrow'},
    {value:'up-arrow', id: 'up-arrow'},
    {value:'rightArrow',id:'rightArrow'},
    {value:'error',id:'error'},
    {value:'errorOutline',id:'errorOutline'},
    {value:'checkedOutline',id:'checkedOutline'},
    {value:'checked',id:'checked'},
    {value:'aeroplane',id:'aeroplane'},
    {value:'arrival',id:'arrival'},
    {value:'depart',id:'depart'},
    {value:'building',id:'building'},
    {value:'copy',id:'copy'},
    {value:'event',id:'event'},
    {value:'file',id:'file'},
    {value:'fileEdit',id:'fileEdit'},
    {value:'fileSetting',id:'fileSetting'},
    {value:'garbage',id:'garbage'},
    {value:'handMoney',id:'handMoney'},
    {value:'hyperlink',id:'hyperlink'},
    {value:'stats',id:'stats'},
    {value:'list',id:'list'},
    {value:'log',id:'log'},
    {value:'pdf',id:'pdf'},
    {value:'pencil',id:'pencil'},
    {value:'placeholder',id:'placeholder'},
    {value:'product',id:'product'},
    {value:'puzzle',id:'puzzle'},
    {value:'rotateArrow',id:'rotateArrow'},
    {value:'stopwatch',id:'stopwatch'},
    {value:'user',id:'user'},
    {value:'userSetting',id:'userSetting'},
    {value:'multipleUser',id:'multipleUser'},
    {value:'settings',id:'settings'},
    {value:'home',id:'home'},
    {value:'brokenLink',id:'brokenLink'},
    {value:'warning',id:'warning'},
    {value:'notice',id:'notice'},
    {value:'note',id:'note'},
    {value:'alert-circle', id:'alert-circle'},
    {value:'radio-checked', id: 'radio-checked'},
    {value:'radio-unchecked', id: 'radio-unchecked'},
    {value:'up-arrow-line', id: 'up-arrow-line'},
    {value:'down-arrow-line', id: 'down-arrow-line'},
    {value:'info', id: 'info'},
]  

const createListOfResources = (data: string | string[]) => {
    let newData:any 
    if(!data){
        return ""
    }
    if(Array.isArray(data)){
        newData = data.map((item) => {
            return {name: item, id:item, value:item, label:item}
        })
    } else {
        newData = {name: data, id:data, value:data, label:data}
    }
    return newData;
}  

const customValidationConfig = {
    validators: {
        customRequired: {
          message: 'The :attribute field is required.',
          rule: (val:any, params:any, validator:ISimpleValidator) => {
            if(val.type === 'Module' && val.isNav === true && !val.value){
                return false
            } else {
                return true;
            }
          },
          required: true,
        },
        visibleTypeRequired:{
            message: 'The :attribute field is required.',
            rule: (val:any, params:any, validator:ISimpleValidator) => {
                if(val.isVisible){
                    if(Array.isArray(val.value) && val.value.length > 0){
                        return true
                    } else{
                        return false;
                    }
                } else {
                    return true;
                }
                // if(val.isVisible && Array.isArray(val.value) && val.value.length > 0){
                //     return true
                // } else {
                //     return false;
                // }
              },
              required: true,
        }
    }
}

const service =  new ScopeManagementService();
const EditScope = (props:IObject) =>{
    const [item, setItem] = useState<IObject>(defaultItem);
    const [isLoading, setIsLoading] = useState(false);
    const { name } = useParams<IObject>();
    const location = useLocation();
    const {resourcesList,getList, add, edit, moduleResources, domainResources, actionResources, orgUnitType } = props;
    const isEditPage = useRef(false);
    const [changeByUser, setChangeByUser] = useState(false);

    const [validator, setValidator] = useState<ISimpleValidator>(simpleValidator(customValidationConfig));

    const getData = useCallback(async ()=>{
        if(!resourcesList){
            getList();
        } else {
            if(isEditPage.current){
                setIsLoading(true)
                try{
                    const node = await service.getDetail(name)
                    setItem({
                        ...node.Items,
                        parentResource: createListOfResources(node.Items.parentResource),
                        dependencies: createListOfResources(node.Items.dependencies),
                        type: node.Items.type.charAt(0).toUpperCase() + node.Items.type.slice(1),
                        orderGroup: node.Items.orderGroup || "", 
                        extendedProperties: {
                            isPermissionVisibale: node.Items.isPermissionVisable,
                            isNavVisible: node.Items.isNavVisable,
                            icon: {label: node.Items.icon, value:node.Items.icon},
                            displayName: node.Items.displayName,
                            link: node.Items.link,
                            isPermissionVisibleType: createListOfResources(node.Items.isPermissionVisibleType)
                        }
                    })
                } catch(e) {
                    handleError(e)
                } finally {
                    setIsLoading(false)
                    setChangeByUser(true);
    
                }
    
            } else {
                setChangeByUser(true);
            }
        }
        
    },[getList,resourcesList,name])

    const handleSubmit = () =>{

        if(validator.allValid()){
            if(isEditPage.current){
                edit(item);
            } else {
                add(item)
            }
        } else {
            validator.showMessages();
            setValidator({...validator,showMessage:true})
        }
        
    }

    const handleInputChange = useCallback((pair:IPair,item:IObject)=>{
        setItem(objLib.setValueWithLodash(item,pair.id,pair.value))
    },[])

    useEffect(()=>{
        isEditPage.current = location.pathname.includes('authorization/resources/tree/edit');
        getData()
    },[getData,location.pathname])

    useEffect(()=>{
        if(changeByUser){
            handleInputChange({id:'parentResource', value:null},item)
        }
    },[item.type,handleInputChange])



    const handleDependenciesChange = (pairs:IPair[]) => {
        console.log(item.dependencies,pairs)
        onChange(pairs)
    }

    const handleOrderChange = (pair:IPair) => {
        if(!isNaN(pair.value)){
            handleInputChange(pair,item)
        }
    }

    const handleIconChange = (pair:IPair) => {
        handleInputChange({id:pair.id,value: pair.value.name},item)
    }

    const onChange = (pairs:IPair[]) =>{
        pairs.forEach((pair:IPair)=>{
            handleInputChange({id:pair.id,value: pair.value},item)
        })
    }  


    if (!resourcesList || isLoading) return <Loader />;
    return <div className='my_30' style={{ padding: '0 4%' }}>
        <Header
                title={isEditPage.current ? 'Edit Resource' : 'Add Resource'}
                bannerGraphic={bannerImg}
              />

        <Row>
            <Col sm={2}>
                Name:<span className="text-danger">*</span>
            </Col>
            <Col sm={3}>
                <Field 
                    model={nameModel}
                    item={item}
                    onChange={onChange}
                    validationRules={'required'}
                    validator={validator}
                />
            </Col>
        </Row>
        <br />
        <Row>
            <Col sm={2}>
                Resource:<span className="text-danger">*</span>
            </Col>
            <Col sm={3}>
                <Field 
                    model={resourceModel}
                    item={item}
                    onChange={onChange}
                    validationRules={'required'}
                    validator={validator}
                />
            </Col>
        </Row>
        <br />
        <Row>
            <Col sm={2}>
                Method: <span className="text-danger">*</span>
            </Col>
            <Col sm={3}>
                <Field 
                    model={methodModel}
                    item={item}
                    onChange={onChange}
                    validationRules={'required'}
                    collections={{method: [{id:'get',value:'GET'},{id:'post',value:'POST'},{id:'put',value:'PUT'},{id:'delete',value:'DELETE'}]}}
                    validator={validator}
                />
            </Col>
        </Row>
        <br />
        <Row>
            <Col sm={2}>
                Description:
            </Col>
            <Col sm={3} className='d-flex' >
                <Input
                    type={'textarea'}
                    value={item.description}
                    name={'description'}
                    onChange={(e) => handleInputChange({id:'description',value: e.target.value},item)}
                    >
                </Input>
            </Col>
        </Row>
        <br />
        <Row>
            <Col sm={2}>
                Type:<span className="text-danger">*</span>
            </Col>
            <Col sm={3}>
                <Field 
                    model={typeModel}
                    item={item}
                    onChange={onChange}
                    validationRules={'required'}
                    collections={{type: [{id:'module',value:'Module'},{id:'action',value:'Action'}]}}
                    validator={validator}
                />
            </Col>
        </Row>
        <br />
        <Row>
            <Col sm={2}>
                Parent Resource:<span className="text-danger">*</span>
            </Col>
            <Col sm={3} >
                <Field 
                    model={{...parentResourceModel,disabled: ()=> !item.type}}
                    item={item}
                    onChange={onChange}
                    validationRules={'required'}
                    collections={item.type === 'Module' ?  {parentResource:domainResources} :  {parentResource:moduleResources}}
                    validator={validator}
                />
            </Col>
        </Row>
        <br />
        {item.type === 'Action' &&
        <>
            <Row>
                <Col sm={2}>
                    Dependencies:
                </Col>
                <Col sm={5} >
                <Field 
                    model={dependenciesModel}
                    item={item}
                    onChange={onChange}
                    collections={{dependencies:actionResources}}
                />    
                </Col>
            </Row>
        <br />
        </>
        }
        
        
        <Row>
            <Col sm={2}>
                Order:
            </Col>
            <Col sm={1} className='d-flex' >
                <Input
                    type={'text'}
                    value={item.orderGroup}
                    name={'orderGroup'}
                    onChange={(e) => handleOrderChange({id:'orderGroup', value: e.target.value})}
                    >
                </Input>
            </Col>
        </Row>
        <br />
        <Row>
            <Col sm={2}>
                Audited:
            </Col>
            <Col sm={2}>
                <Field
                    model={auditedModel}
                    item={item}
                    onChange={onChange}
                />
            </Col>
        </Row>
        <br />
        <Row>
            <Col sm={2}>
                Is this permission visible?
            </Col>
            <Col sm={2}>
                <Field
                    model={isPermissionVisibaleModel}
                    item={item}
                    onChange={onChange}
                />
            </Col>
        </Row>
        <br />
        {
        item.extendedProperties.isPermissionVisibale && <><Row className='pl-3'>
            <Col sm={2}>Visible to:</Col>
            <Col sm={4}>
                <Field
                    model={visibleToModel}
                    item={item}
                    onChange={onChange}
                    collections={{orgUnitType:orgUnitType}}
                />
            </Col>
        </Row>
        <br />
        </>
        }
        {item.type === 'Module' && 
            <Row>
                <Col sm={2}>
                Visible in navbar?
                </Col>
                <Col sm={2}>
                    <Field
                        model={isNavVisibleModel}
                        item={item}
                        onChange={onChange}
                    />
                </Col>
            </Row>
    }
        {(item.extendedProperties.isNavVisible && item.type === 'Module') &&
        <>
            <br />
            <Row className='pl-3'>
                <Col sm={2}>
                Display Name:<span className="text-danger">*</span>
                </Col>
                <Col sm={2}>
                    <Field 
                        model={displayNameModel}
                        item={item}
                        onChange={onChange}
                        validationRules={'customRequired'}
                        validationData={{value: item.extendedProperties.displayName,type:item.type,isNav: item.extendedProperties.isNavVisible}}
                        validator={validator}
                    />
                </Col>
            </Row>
            <br />
            <Row className='pl-3'>
                <Col sm={2}>
                Icon:
                </Col>
                <Col sm={3}>
                    <Field
                        model={iconModel}
                        item={item}
                        onChange={onChange}
                        collections={{icons: iconOptions}}
                    />
                </Col>
            </Row>
            <br />
            <Row className='pl-3'>
                <Col sm={2}>
                Link:<span className="text-danger">*</span>
                </Col>
                <Col sm={2}>
                    <Field 
                        model={linkModel}
                        item={item}
                        onChange={onChange}
                        validationRules={'customRequired'}
                        validationData={{value: item.extendedProperties.link,type:item.type,isNav: item.extendedProperties.isNavVisible}}
                        validator={validator}
                    />
                </Col>
            </Row>
        </>}
        <Row className='my_50'>
          <Col sm={7} className='d-flex justify-content-end' style={{ padding: '0 4%' }}>
            <button
              type='button'
              className='btn btn-primary mt-4 ml-4'
              onClick={handleSubmit}
            >
              Save
            </button>
          </Col>
        </Row>
    </div>

}


const mapStateToProps = (state: IStore) => ({
    resourcesList : state.scopeManagement.collections.resources,
    actionResources: state.scopeManagement.collections['action-resources'],
    moduleResources : state.scopeManagement.collections['module-resources'],
    domainResources : state.scopeManagement.collections['domain-resources'],
    orgUnitType : state.scopeManagement.collections['orgUnitType']

});
const mapDispatchToProps = (dispatch: any) => ({
    add: (item:IObject)=> dispatch(ScopeManagementActions.add(item)),
    edit: (item:IObject) => dispatch(ScopeManagementActions.edit(item)),
    getList: () => dispatch(scopeManagementActions.getList())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditScope);