import React from 'react';

const Header = (props: {fields: IPair[] | null}) => {
  const { fields } = props;
  return (
    <thead className='tableHeader'>
      <tr data-testid="table-header-tr">
        <th scope='col'> </th>
        {fields && fields.map((field) => (
          <th key={field.id} scope='col'>
            {field.value}
          </th>
        ))}
        <th scope='col'> </th>
      </tr>
    </thead>
  );
};

export default Header;
