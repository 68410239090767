import {modelId} from "../../../Products/shared/utils/modelUtil";

const insuredPropertyObjectBased = "properties.Insured Property";
const basicDetailObjectBased = "properties";
const mainApplicantObjectBased = 'properties.main applicant'

const basicDetailsModel: IFieldModel2 = {
    "Building Type": {
        id: modelId(basicDetailObjectBased, `Building Type`),
        label: "Building Type",
        ext: "",
        type: "Select",
        collectionId: "buildingTypes",
        placeholder: "Select Options",
    },
    "Building Sum Assured": {
        id: modelId(basicDetailObjectBased, "Building Sum Assured"),
        label: "Desired Building Sum Assured",
        ext: "",
        type: "Input",
        placeholder: "Enter Building Sum Assured",
        handler: "handleNumberValue"
    },
    "Construction Type": {
        id: modelId(basicDetailObjectBased, "Construction Type"),
        label: "Construction Type",
        ext: "",
        type: "Select",
        collectionId: "constructionTypes",
        placeholder: "Select Options",
    },
    "Contents Sum Assured": {
        id: modelId(basicDetailObjectBased, "Contents Sum Assured"),
        label: "Desired Contents Sum Assured",
        ext: "",
        type: "Input",
        placeholder: "Enter Contents Sum Assured",
        handler: "handleNumberValue",
    },
}

const autoFilledCheckbox: IFieldModel2 = {
    autoFilledValue: {
        id: modelId('properties', "autoFilledValue"),
        label: 'Same as applicant mailing address',
        type: 'Checkbox',
    },
}

const insuredPropertyObjectModel: IFieldModel2 = {
    "Address": {
        id: modelId(insuredPropertyObjectBased, "Address"),
        label: "Address",
        ext: "",
        type: "Input",
        placeholder: "Enter Address",
        style: {col: 12}
    },
    "Country": {
        id: modelId(insuredPropertyObjectBased, `Country`),
        label: "Country",
        ext: "",
        type: "Input",
        placeholder: "Enter Country",
    },
    "State": {
        id: modelId(insuredPropertyObjectBased, "State"),
        label: "State/Province/Region",
        ext: "",
        type: "Input",
        placeholder: "Enter State/Province/Region",
    },
    "Postcode": {
        id: modelId(insuredPropertyObjectBased, "Postcode"),
        label: "Postcode",
        ext: "",
        type: "Input",
        placeholder: "Enter postcode",
        handler: "handleNumber",
    },
    "City": {
        id: modelId(insuredPropertyObjectBased, "City"),
        label: "City",
        ext: "",
        type: "Input",
        placeholder: "Enter city",
    }
};

const mainApplicant: IFieldModel2 = {
    "Full Name": {
        id: modelId(mainApplicantObjectBased, "Full Name"),
        label: "Full Name",
        type: "Input",
        placeholder: "Enter Full Name",
        group: 'applicantDetails'
    },
    "DOB": {
        id: modelId(mainApplicantObjectBased, "DOB"),
        label: "DOB",
        type: "DatePicker",
        placeholder: `Select Date`,
        group: 'applicantDetails'
    },
    "ID Type": {
        id: modelId(mainApplicantObjectBased, "ID Type"),
        label: "ID Type",
        type: "Select",
        collectionId: 'idTypes',
        placeholder: `Select Options`,
        group: 'applicantDetails'
    },
    "ID Number": {
        id: modelId(mainApplicantObjectBased, "ID Number"),
        label: "ID Number",
        type: "Input",
        placeholder: "Enter ID Number",
        group: 'applicantDetails'
    },
    "Gender": {
        id: modelId(mainApplicantObjectBased, "Gender"),
        label: "Gender",
        type: "Select",
        collectionId: "genders",
        placeholder: "Select Options",
        group: 'applicantDetails'
    },
    "Email Address": {
        id: modelId(mainApplicantObjectBased, "Email Address"),
        label: "Email Address",
        type: "Input",
        placeholder: "Enter Email Address",
        group: 'applicantDetails'
    },
    "Mobile Number": {
        id: modelId(mainApplicantObjectBased, "Mobile Number"),
        label: "Mobile Number",
        type: "Input",
        placeholder: `Enter Mobile Number`,
        group: 'applicantDetails'
    },
    "Address": {
        id: modelId(mainApplicantObjectBased, "Address"),
        label: "Address",
        type: "Input",
        placeholder: `Enter Address`,
        style: {col: 12},
        group: 'mailingAddress'
    },
    "Country": {
        id: modelId(mainApplicantObjectBased, "Country"),
        label: "Country",
        type: "Select",
        collectionId: "countries",
        placeholder: "Select Options",
        group: 'mailingAddress'
    },
    "State": {
        id: modelId(mainApplicantObjectBased, "State"),
        label: "State/Province/Region",
        type: "Input",
        placeholder: "Enter State/Province/Region",
        group: 'mailingAddress'
    },
    "Postcode": {
        id: modelId(mainApplicantObjectBased, "Postcode"),
        label: "Postcode",
        type: "Input",
        placeholder: "Enter Postcode",
        handler: "handleNumber",
        group: 'mailingAddress'
    },
    "City": {
        id: modelId(mainApplicantObjectBased, "City"),
        label: "City",
        type: "Input",
        placeholder: "Enter City",
        group: 'mailingAddress'
    },
}


const paymentModel: IFieldModel2 = {
    paymentMode: {
        id: "properties.Payment Mode",
        label: "Mode of Payment",
        ext: " / មធ្យោបាយនៃការបង់ប្រាក់",
        type: "Select",
        collectionId: "paymentMethod",
        placeholder: `Select Option`,
        style: {col: 12}
    },
    receiptNo: {
        id: "properties.Receipt No",
        label: "Receipt No",
        ext: " / លេខវិក្កយប័ត្រ",
        type: "Input",
        placeholder: `Enter receipt no`,
        style: {col: 12}
    },
    paymentReceivedBy: {
        id: "properties.Payment Received By",
        label: "Payment Received By",
        ext: " / ធ្វើការទូទាត់ដោយ",
        type: "Select",
        collectionId: "currentOuUsers",
        placeholder: `Select Option`,
        style: {col: 12}
    },
};


const fieldsModel = {
    ...basicDetailsModel,
    ...insuredPropertyObjectModel,
    ...mainApplicant,
    ...paymentModel,
    ...autoFilledCheckbox
}

const model = {
    basicDetailsModel,
    insuredPropertyObjectModel,
    mainApplicant,
    paymentModel,
    fieldsModel,
    autoFilledCheckbox
};

export default model;
