import React from 'react';
import { connect } from 'react-redux';
import { dateLib } from '@xc-core/lib';
import { Col } from 'reactstrap';
import {
 Filter, Header, Table,
} from '@xc-core/components/core';
import * as routes from '@xc-core/routes';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import { actions } from '@redux';
import BaseListingContainer from '../baseListingContainer';

const auditLogActions = actions.AuditLogActions;


class AuditList extends BaseListingContainer<any> {
  async componentDidMount(): Promise<void> {
    const { setList, getSearchParams } = this.props;
    setList(getSearchParams());
  }

  private rowClicked = (row: IObject) => {
    const { setCurrentAuditInfo } = this.props;
    setCurrentAuditInfo(row);
    this.onRowClicked(routes.audit.base, row.id);
  }

  onAuditExport = (pii: boolean) => {
    const date = dateLib.getCurrentDate('YYYYMMDD');
    const fileName = `Audit_Export_${date}.csv`;
    this.onExport(pii, fileName);
  }

  render() {
    const {
      list, hasRecordsInitially = [], pageInfo, isLoading, filters = [],
      prevFilters = [], fields, searchKeyword,
    } = this.props;
    return (
      <div data-testid="containter">
        <Header
          title='Audit Log'
          bannerGraphic={bannerImg}
          withBorderBottom
        />
        <div data-testid="containter-inner" className='container-fluid px-0'>
          <div className='row d-flex justify-content-center my_30'>
            <Col sm='12' className='my-2 filter-viewType-container'>
              <Filter
                isLoading={isLoading}
                filterOptions={filters}
                onCheckChange={
                  (newFilters: IListingFilter[]) => this.onFilterCheckChange(newFilters)
                }
                applyFilter={(newFilters: IListingFilter[]) => this.onApplyFilter(newFilters)}
                cancelFilter={() => this.onCancelFilter(prevFilters)}
              />
            </Col>
            <div className='col-sm-12' data-testid="table-data">
              <Table
                titles={fields}
                records={list}
                hasRecords={hasRecordsInitially}
                image={bannerImg}
                onRowClicked={this.rowClicked}
                onRowButtonClicked={this.rowClicked}
                handleSearchKeyword={(pair: IPair) => this.onSearchChange(pair)}
                searchKeyword={searchKeyword}
                handlePageClick={(pair: IPair) => this.onPageClick(pair)}
                handlePageSizeChange={(pair: IPair) => this.onPageSizeChange(pair)}
                pagination={pageInfo}
                buttonIcon='pencil'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auditLog }: any) => ({
  item: auditLog,
  list: auditLog.list,
  searchKeyword: auditLog.searchKeyword,
  fields: auditLog.fields,
  filters: auditLog.filters,
  isLoading: auditLog.isLoading,
  pageInfo: auditLog.pageInfo,
  prevFilters: auditLog.prevFilters,
});

const mapDispatchToProps = (dispatch: any) => ({
  setList: (params: IRequestParam) => dispatch(auditLogActions.setList(params)),
  getSearchParams: () => dispatch(auditLogActions.getSearchParams()),
  setCurrentAuditInfo: (row: object) => dispatch(auditLogActions.setCurrentAuditInfo(row)),
  changePage: (value: number) => dispatch(auditLogActions.changePage(value)),
  changePageSize: (value: number) => dispatch(auditLogActions.changePageSize(value)),
  exportCsv: (params: IRequestParam, fileName: string) => dispatch(
    auditLogActions.getCsvFile(params, fileName),
  ),
  applyFilters: (filters: IListingFilter[]) => dispatch(auditLogActions.applyFilters(filters)),
  changeSearchKey: (keyword: string) => dispatch(auditLogActions.changeSearchKey(keyword)),
  changeFilters: (filters: IListingFilter[]) => dispatch(auditLogActions.changeFilters(filters)),
  cancelFilters: (prefFilters: IListingFilter[]) => dispatch(
    auditLogActions.cancelFilters(prefFilters),
  ),
  resetFilters: () => dispatch(auditLogActions.resetFilters()),
  setHasRecordsInitially: () => dispatch(auditLogActions.setHasRecordsInitially()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditList);
