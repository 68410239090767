
import React from 'react';
import {
  Col, Row, Label, FormGroup,
} from 'reactstrap';
import Field from '@containers/getQuote/wizard/field';
import lib from 'utils/lib';

interface IRenderFieldsProps {
  collections: IObject
  item: IObject
  validationErrors: IScriptValidationError[]
  fields: IField[]
  onChange: Function
  fieldsIndex?: number
  disableAll?: boolean,
  ext?: boolean
  style?:React.CSSProperties
 }
const RenderFields = (props: IRenderFieldsProps) => {
  const {
    collections,
    item,
    validationErrors,
    fields,
    onChange,
    disableAll,
    ext,
    style
  } = props;
  return (
    <Row style={{...style}}>
      {
        fields.map((field: IField) => 
          {
            field.disabled = disableAll ? ():boolean => disableAll : field.disabled
            return field.hidden && field.hidden(item) ? null :
            <Col sm={field.style.col} key={field.id}>
            <FormGroup style={{
              display: field.type === "Checkbox" ?'flex' :undefined
            }}>
              {field.label && field.type !== 'RadioButtonGroup' ? <Label style={{
                order: field.type === 'Checkbox' ? 2 : "unset"
              }} dangerouslySetInnerHTML={{ __html: `${field.label}${(lib.checkIsDemoSite() && ext) ? field.ext : ''}` }} /> : <></> }
              <Field
                model={field}
                item={item}
                collections={collections}
                onChange={onChange}
                validationError={validationErrors.find((validation:IScriptValidationError) => (
                  validation.referral.includes(field.id)
                ))}
              />
              {field.infoMessage ?<div className={'info-message'}>{field.infoMessage || ''}</div> : null}
            </FormGroup>
          </Col> 
          }
        )
      }
    </Row>
  );
};

export default RenderFields;
