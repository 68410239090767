import React from "react";
import {connect} from 'react-redux';
import GetQuoteTravelInsuranceWizard from "Products/TravelInsurance/GetQuote";
import GetQuoteHreTravelInsuranceWizard from "Products/HreTravelInsurance/GetQuote";
import GetQuoteWalletInsuranceWizard from 'Products/WalletInsurance/getQuote';
import GetQuoteCashForHospitalizationWizard from 'Products/CashForHospitalization/getQuote';
import GetQuoteAlfalahTravelInsuranceWizard from "Products/AlfalahTravelInsurance/GetQuote";




import FlashMessage from "_components/FlashMessage";
import config from '_config';
import {utils} from 'utils';

const RenderProductsWizard = (props) => {

  const {selectedProductCode, history} = props;
  if (!selectedProductCode) {
    history.push("/getQuote");
    return null;
  }
  switch (selectedProductCode) {
    case config.products.travelInsurance.id:
      return <GetQuoteTravelInsuranceWizard {...props} />;

    case config.products.hreTravelInsurance.id:
      return <GetQuoteHreTravelInsuranceWizard {...props} />;

    case config.products.walletInsurance.id:
      return <GetQuoteWalletInsuranceWizard {...props} />;
    case config.products.cashForHospitalization.id:
      return <GetQuoteCashForHospitalizationWizard {...props} />;
    case config.products.alfalahTravelInsurance.id:
      return <GetQuoteAlfalahTravelInsuranceWizard {...props} />;
    default:
      return (<FlashMessage flashMessage={utils.userMessages.UNIMPLEMENTED_PRODUCT} />);
  }
};

const mapStateToProps = ({getQuote}) => {
  const {selectedProductCode} = getQuote;
  return {selectedProductCode};
};
export default connect(mapStateToProps)(RenderProductsWizard);
