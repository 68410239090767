import React from 'react';
import { connect } from 'react-redux';
import {
    objLib, fieldLib,
    dateLib, stringLib,
} from '@xc-core/lib';
import { userMessages } from '@xc-core/constants';
import { Message, Loader } from '@xc-core/components/core';
import BaseWizard from '@containers/getQuote/wizard/baseWizard';
import RenderWizard from '@containers/getQuote/wizard/renderWizard';
import { ComponentLoader } from '@xc-core/components';
import { GetQuoteActions, PolicyActions } from '@redux/actions';
import config from '@xc-core/config';
import * as repo from './components';
import { model, defaultValues } from './config';
// import PaymentForm from './components/paymentForm';
import lib from 'utils/lib'
import { sidebarModel } from 'models';

class MyMotorInsurance extends BaseWizard<IBaseWizardProps, IBaseWizardState> {
  protected defaultCountry = defaultValues.country;

  protected stateListParam = defaultValues.stateListParam;

  protected model = model;

  private CNICLength = defaultValues.cnicLength;

  private PostCodeLength = defaultValues.postcodeLength;

  private DebitCreditNoLength = defaultValues.debitCreditNoLength;

  private hiddenStepIndexes = [5];

  constructor(props: IBaseWizardProps) {
    super(props);
    this.state = {
      sendingRequest: false,
      invalidForm: false,
      item: {},
      validationErrors: [],
      redirectToGateway: false,
    };
  }

  protected prepareSteps = (steps: IStep[]): IStep[] =>
    steps.map((step: IStep) => {
      const metadata = this.getStepMetadata(step.order);
      return {
        ...step,
        componentType: metadata.componentType,
        formContainer: metadata.formContainer,
      };
    });

  protected vehicleVerifiedHandler = (pair: IPair) => {
    return [
      {
        ...pair,
        value: pair.value ? "Yes" : null,
      },
    ];
  };

  private generateForm = (objs: tObjModel, title: string) => {
    return {
      getTitle: () => title,
      fields: objs.map((val) => {
        const valType = typeof val;
        const { keyName, handler, style, maxVal, minVal,currencyType } =
          typeof val === "object"
            ? val
            : {
                keyName: undefined,
                handler: undefined,
                style: undefined,
                maxVal: undefined,
                minVal: undefined,
                currencyType:undefined
              };
        return {
          ...model.fieldsModel[
            (valType === "string" ? val : keyName) as string
          ],
          handler: handler ? this[handler] : this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          style: style ? style : { col: "6" },
          getMax: maxVal ? maxVal : undefined,
          getMin: minVal ? minVal : undefined,
          currencyType
        } as IField;
      }),
    };
  };

  protected getStepMetadata = (
    order: number
  ): { componentType: string; formContainer: IFormContainer } => {
    switch (order) {
      case 1:
        return {
          componentType: "BasicDetails",
          formContainer: {
            title: "Please enter your vehicle registration number",
            basicDetailFields: this.generateForm(model.basicDetailsFields, ""),
            fields: this.model.fieldsModel,
          },
        };
      case 2:
        return {
          componentType: "CarDetails",
          formContainer: {
            title: "Please ensure your vehicle details are correct",
            carDetails: this.generateForm(model.carDetailsFields, ""),
            fields: this.model.carDetailsFields,
          },
        };
      case 3:
        return {
          componentType: "CarOwnerDetails",
          formContainer: {
            title: `Please fill in the vehicle owner's details`,
            carOwnerDetails: this.generateForm(model.carOwnerDetailsFields, ""),
            fields: this.model.carOwnerDetailsFields,
          },
        };
      case 4:
        return {
          componentType: "SumAssuredStep",
          formContainer: {
            title: `Select Sum Assured for your vehicle`,
            sumAssuredDetails: this.generateForm(
              [
                {
                  keyName: "sumAssured",
                  minVal: () => "10000",
                  maxVal: () => "100000",
                  currencyType:'MYR',
                  style: { col: 6 },
                },
              ],
              ""
            ),
            carOwnerFields: this.model.carOwnerDetailsFields,
            carDetailsFields: this.model.carDetailsFields,
            sumAssuredFields: this.model.fieldsModel.sumAssured,
            fields: this.model.fieldsModel,
          },
        };
      case 5:
        return {
          componentType: "Pricing",
          formContainer: {
            title: "",
          },
        };
      case 6:
        return {
          componentType: "CarOwnerDetails",
          formContainer: {
            title: `Please fill in the vehicle owner's details`,
            carOwnerDetails: this.generateForm(model.carOwnerCompleteDetailsFields, ""),
            fields: this.model.carOwnerCompleteDetailsFields,
          },
        };
      case 7:
        return {
          componentType: "PaymentDetails",
          formContainer: {
            title: `Fill in payment details (optional)`,
            paymentDetails: this.generateForm(model.paymentDetails, ""),
            fields: this.model.paymentDetails,
          },
        };
      case 8:
        return {
          componentType: 'CompletedSalesJourneyPage',
          formContainer: {
            title: '',
          },
        }
      default:
        return {
          componentType: "",
          formContainer: {
            title: "",
          },
        };
    }
  };

  protected handleMainIdType = (pair: IPair, item: IObject): IPair[] => {
    let idNumber = objLib.getValueWithLodash(
      item,
      model.fieldsModel.idNumber.id
    );
    const idType = objLib.getValueWithLodash(item, pair.id);
    if (idType !== pair.value) idNumber = ""; // reset dependentId if idType has changed
    return [pair, { id: model.fieldsModel.idNumber.id, value: idNumber }];
  };

  protected handleStartEndDate = (pair: IPair, item: IObject): IPair[] => {
    const pairsToUpdate: IPair[] = [];
    const prevStartDate = objLib.getValueWithLodash(
      item,
      model.fieldsModel.startDate.id
    );
    const prevEndDate = objLib.getValueWithLodash(
      item,
      model.fieldsModel.endDate.id
    );
    const tripType = objLib.getValueWithLodash(
      item,
      model.fieldsModel.tripType.id
    );
    let noOfDays = 0;
    if (pair.id.includes("Start Date") && prevEndDate) {
      if (tripType === "Annual") {
        const endDate = this.getEndDateForAnnualType(pair.value);
        pairsToUpdate.push({
          id: model.fieldsModel.endDate.id,
          value: endDate,
        });
        noOfDays = this.getNoOfDays(endDate, pair.value);
      } else {
        noOfDays = this.getNoOfDays(prevEndDate, pair.value);
        const isStartDateGreater =
          dateLib.compare(pair.value, prevEndDate, config.dateMomentFormat) > 0;
        if (isStartDateGreater) {
          const newEndDate = dateLib.addToDate(pair.value, 0, 0, 4);
          noOfDays = this.getNoOfDays(newEndDate, pair.value);
          pairsToUpdate.push({
            id: model.fieldsModel.endDate.id,
            value: newEndDate,
          });
        }
      }
    } else if (pair.id.includes("End Date") && prevStartDate) {
      noOfDays = this.getNoOfDays(pair.value, prevStartDate);
    }
    pairsToUpdate.push({ id: pair.id, value: pair.value });
    pairsToUpdate.push({ id: ["properties", "No. of Days"], value: noOfDays });
    return pairsToUpdate;
  };

  private getNoOfDays = (date1: string, date2: string) =>
    dateLib.getDiff(date1, date2, "days") + 1;

  private getEndDateForAnnualType = (startDate: string) => {
    let endDate = dateLib.addToDate(startDate, 1, 0, 0);
    endDate = dateLib.subtractFromDate(endDate, 0, 0, 1);
    return endDate;
  };

  private getMaxDob = (): string => {
    const { minAge } = defaultValues;
    return dateLib.subtractFromNow(minAge.years, minAge.months, minAge.days);
  };

  private getMinDob = (): string => {
    const { maxAge } = defaultValues;
    return dateLib.subtractFromNow(maxAge.years, maxAge.months, maxAge.days);
  };

  private isEndDateDisabled = (item: IObject): boolean => {
    const tripTypeId = model.fieldsModel.tripType.id;
    return objLib.getValueWithLodash(item, tripTypeId) === "Annual";
  };

  private handlePaymentMode = (pair: IPair, item: IObject): IPair[] => {
    let receivedByValue = objLib.getValueWithLodash(
      item,
      model.fieldsModel.paymentReceivedBy.id
    );
    if (!receivedByValue) {
      const { user } = config;
      receivedByValue = user.name;
    }
    return [
      pair,
      { id: model.fieldsModel.paymentReceivedBy.id, value: receivedByValue },
    ];
  };

  private getMinEndDate = (item: IObject): string => {
    const startDate =
      objLib.getValueWithLodash(item, model.fieldsModel.startDate.id) || "";
    const currentDate = dateLib.getCurrentDate(config.dateMomentFormat);
    return startDate || currentDate;
  };

  private handleMainIdNumber = (pair: IPair, item: IObject): IPair[] => {
    let { value } = pair;
    let gender =
      objLib.getValueWithLodash(item, model.fieldsModel.gender.id) || "";
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const idType = objLib.getValueWithLodash(item, model.fieldsModel.idType.id);
    if (idType === "CNIC") {
      value =
        value.length > this.CNICLength
          ? value.slice(0, this.CNICLength)
          : value;
      value = this.restrictToNumber(value, preValue);
      gender =
        value && value.length === this.CNICLength
          ? this.getGenderFromValue(value[this.CNICLength - 1])
          : gender;
    } else {
      value = this.restrictToAlphanumeric(value, preValue);
    }
    return [
      { id: pair.id, value },
      { id: model.fieldsModel.gender.id, value: gender },
    ];
  };

  protected handlePostcode = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    // value = value.length > this.PostCodeLength ? value.slice(0, this.PostCodeLength) : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  };

  protected handleDebitCreditNo = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    value =
      value.length > this.DebitCreditNoLength
        ? value.slice(0, this.DebitCreditNoLength)
        : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  };

  render() {
    const {
      getQuote,
      getQuote: { collections, product, steps },
      history,
    } = this.props;
    const { item, validationErrors, sendingRequest, invalidForm } = this.state;
    const currentStep = this.getCurrentStep(this.hiddenStepIndexes);
    const paymentSelected = !stringLib.isEmpty(
      objLib.getValueWithLodash(
        item,
        this.model.fieldsModel.paymentReceivedBy.id
      )
    );

    var isBeneficirayEnterd = false;
    if (product === null) return <Message message={userMessages.ERROR} />;
    if (collections === null || steps.length === 0) return <Loader />;
    // if (this.state.redirectToGateway) return <PaymentForm item={item}/>
    const sidebar = sidebarModel.quotation.myMotorInsurance;

    return (
      <RenderWizard
        getQuote={{
          ...getQuote,
          steps: steps.filter(
            (el, index) => this.hiddenStepIndexes.indexOf(index) === -1
          ),
        }}
        getCurrentStep={() => this.getCurrentStep(this.hiddenStepIndexes)}
        handleNext={()=>{
          const expectedPropertiesName = currentStep.properties.map((el)=>el.name)
          let newProperties = {...this.state.item.properties}
          expectedPropertiesName.forEach((val)=>{
            if(!item.properties[val]){
              newProperties = {
                ...newProperties,
                [val]:{}
              }
            }
          })
          this.setState({
            item:{
              ...this.state.item,
              properties:{...newProperties}
            }
          },()=>{
            this.next().then(()=>{
              if(this.hiddenStepIndexes.indexOf(currentStep.order + 1) !== -1){
                this.next();
              }
            })
          })
        }}
        handlePrev={()=>this.previous().then((res)=>{
          if(res?.state === 'Pricing'){
            this.previous()
          }
        })}
        handleSkip={this.skip}
        paymentSelected={paymentSelected}
        isBeneficirayEnterd={isBeneficirayEnterd}
        sendingRequest={sendingRequest}
        invalidForm={invalidForm}
        isKhmer={lib.checkIsDemoSite()}
      >
        <ComponentLoader
          name={currentStep.componentType}
          repo={repo}
          history={history}
          propsModel={{
            onChange: (handler: string, obj: IHandledValue) =>
              this[handler](obj),
            initValues: (pairs: IPair[]) => this.setValues(pairs),
            collections,
            item,
            currentStep,
            model: currentStep.formContainer,
            validationErrors,
            defaults: { country: this.defaultCountry },
            sidebar
          }}
        />
      </RenderWizard>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
    getQuote: state.newGetQuote,
});

const getQuoteActions = new GetQuoteActions();
const policyActions = new PolicyActions();

const mapDispatchToProps = (dispatch: any) => ({
    init: (productCode: number, country: string,
           stateListParam: string) => dispatch(getQuoteActions.init(productCode, country, stateListParam)),
    setStateInCollections: (country: string,
                            param: string) => dispatch(getQuoteActions.setStateInCollections(country, param)),
    setSteps: (steps: IStep[]) => dispatch(getQuoteActions.setSteps(steps)),
    getItemOnNext: (productCode: number, id: number,
                    properties: IObject) => dispatch(getQuoteActions.getItemOnNext(productCode, id, properties)),
    getItemOnSkip: (productCode: number, id: number,
                    properties: IObject) => dispatch(getQuoteActions.getItemOnSkip(productCode, id, properties)),
    getItemOnPrevious: (productCode: number,
                        id: number) => dispatch(getQuoteActions.getItemOnPrevious(productCode, id)),
    downloadCertificate: (id: string) => dispatch(policyActions.download(id)),
    reset: () => dispatch(getQuoteActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyMotorInsurance);
