
const userActionTypes: IUserActionTypes = {
  LIST_FAILED: 'USER_LIST_FAILED',
  LIST_LOADING: 'USER_LIST_LOADING',
  LIST_GET: 'USER_LIST_GET',
  LIST_SET_FORBIDDEN: 'USER_LIST_SET_FORBIDDEN',
  LIST_CHANGE_SEARCH_KEY: 'USER_LIST_CHANGE_SEARCH_KEY',
  LIST_CHANGE_PAGE: 'USER_LIST_CHANGE_PAGE',
  LIST_CHANGE_PAGE_SIZE: 'USER_LIST_CHANGE_PAGE_SIZE',
  LIST_CHANGE_FILTERS: 'USER_LIST_CHANGE_FILTERS',
  LIST_RESET_FILTERS: 'USER_LIST_RESET_FILTERS',
  LIST_RESET: 'USER_LIST_RESET',
  LIST_APPLY_FILTERS: 'USER_LIST_APPLY_FILTERS',
  LIST_SET_NOT_FOUND: 'USER_LIST_SET_NOT_FOUND',
  LIST_SET_HAS_RECORDS_INITIALLY: 'USER_LIST_SET_HAS_RECORDS_INITIALLY',

  ENTITY_LOADING: 'USER_ENTITY_LOADING',
  ENTITY_FAILED: 'USER_ENTITY_FAILED',
  ENTITY_SET_FORBIDDEN: 'USER_ENTITY_SET_FORBIDDEN',
  ENTITY_GET: 'USER_ENTITY_GET',
  ENTITY_UPDATE: 'USER_ENTITY_UPDATE',
  ENTITY_CREATE: 'USER_ENTITY_CREATE',

  CREATE_SET_FORBIDDEN: 'ORG_CREATE_SET_FORBIDDEN',
  UPDATE_SET_FORBIDDEN: 'ORG_UPDATE_SET_FORBIDDEN',


  // CREATE_LOADING: 'USER_CREATE_LOADING',
  // CREATE_FAILED: 'USER_CREATE_FAILED',
  // CREATE_GET: 'USER_CREATE_GET',

  // UPDATE_LOADING: 'USER_UPDATE_LOADING',
  // UPDATE_FAILED: 'USER_UPDATE_FAILED',
  // UPDATE_GET: 'USER_UPDATE_GET',
};

export default userActionTypes;
