import React from 'react';
import { Col } from 'reactstrap';
import Checkbox from 'react-toolbox/lib/checkbox';
import { filterLib } from '@xc-core/lib';
import DateRange from '../dateRange';

interface IRenderValueProps {
  values: any,
  searchKeyword: string,
  isCalendar: boolean,
  onDateRangeChange: (startDate: string, endDate: string) => void,
  onValueCheckChange: Function,
}
const RenderValues = (props: IRenderValueProps) => {
  const {
    values, searchKeyword, isCalendar,
    onDateRangeChange, onValueCheckChange,
  } = props;
  if (values.length === 0) return (<>No results found</>);
  const filteredValues = values.filter((value: any) => {
    if (value.name) {
      return value.name.toLowerCase().includes(searchKeyword.toLowerCase());
    }
    return '';
  });
  if (isCalendar) {
    const start = values.find((val: any) => val.code === 'startDate').value;
    const end = values.find((val: any) => val.code === 'endDate').value;
    return (
      <DateRange
        start={start}
        end={end}
        onSelected={(startDate: any, endDate: any) => onDateRangeChange(startDate, endDate)}
      />
    );
  }

  return filteredValues.map((value: any) => (
    <Checkbox
      key={Math.random().toString()}
      theme={{ check: 'bg-light xc-checkbox' }}
      className='p-2 mb-4'
      checked={value.isChecked}
      label={value.name}
      onChange={() => onValueCheckChange({ ...value, isChecked: !value.isChecked })}
    />
  ));
};

interface IFilterValuesColProps {
  searchKeyword: string,
  values: any,
  valuesType: string,
  onSearchKeywordChange: Function,
  onDateRangeChange: (startDate: string, endDate: string) => void,
  onValueCheckChange: Function,
}
const FilterValuesCol = (props: IFilterValuesColProps) => {
  const {
    searchKeyword, onSearchKeywordChange,
    onDateRangeChange, values, onValueCheckChange, valuesType,
  } = props;
  const isCalendar = valuesType === filterLib.dateRangeType;
  return (
    <Col sm='5' className='border-top-0 border-bottom-0 border-left-0 border p-0'>
      <div id='searchOption'>
        <div className={`card-header p-2 ${isCalendar ? 'd-none' : ''}`}>
          <div className='input-group col-md-12 p-0'>
            <input
              className='form-control py-2 border-0 rounded-0'
              type='search'
              value={searchKeyword}
              onChange={(range) => onSearchKeywordChange(range)}
              placeholder='Search'
              id='example-search-input'
            />
            <span className='input-group-append'>
              <button className='btn rounded-0' type='button'>
                <i className='icon-search' />
              </button>
            </span>
          </div>
        </div>
        <div className='card-body'>
          <RenderValues
            values={values}
            isCalendar={isCalendar}
            searchKeyword={searchKeyword}
            onValueCheckChange={(value: any) => onValueCheckChange(value)}
            onDateRangeChange={(startDate: string, endDate: string) => (
              onDateRangeChange(startDate, endDate)
            )}
          />
        </div>
      </div>
    </Col>
  );
};

export default FilterValuesCol;
