import React from 'react';

interface IHeaderProps {
  title: string,
  bannerGraphic: any,
  children?: any,
  withBorderBottom?: boolean,
}

const Header = (props: IHeaderProps) => {
  const {
    title, children, bannerGraphic, withBorderBottom,
  } = props;
  return (
    <div
      id='menu-title'
      data-testid="header-title"
      className={
        `mx-3 text-dark bg-white align-items-center
        ${withBorderBottom ? 'border-bottom bg-white' : ''}`
      }
    >
      <div>
        <div className='title-container'>
          <h2 className={`menu-title-banner ${children ? 'mb-4 mr-2' : ''}`}>
            {title}
          </h2>
          {children}
        </div>
      </div>
      <img src={bannerGraphic} alt={title} />
    </div>
  );
};

Header.defaultProps = {
  withBorderBottom: false,
  children: null,
};

export default Header;
