import React from 'react';
import { Modal } from '@xc-core/components/core';

const SignatureModal = (props: { isOpen: boolean, toggle: any, imgSrc: string}) => {
  const { isOpen, toggle, imgSrc } = props;
  return (
    <Modal
      id='witness-signature'
      title='Witness Signature'
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className='text-center'>
        <img src={imgSrc} alt='signature' />
      </div>
    </Modal>
  );
};

export default SignatureModal;
