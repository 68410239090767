export const orgConstants ={
  GET_ORG_LIST: "GET_ORG_LIST",
  GET_ORG_DETAILS: "GET_ORG_DETAILS",
  CREATE_ORG: "CREATE_ORG",
  UPDATE_ORG_LIST: "UPDATE_ORG_LIST",
  UPDATE_DETAILS: "UPDATE_DETAILS",
  ORGS_APPLY_FILTER: "ORGS_APPLY_FILTER",
  ORG_REMOVE_NOTIFICATION: "ORG_REMOVE_NOTIFICATION",
  UPDATE_PRODUCTS: "UPDATE_PRODUCTS",
  GET_ORG_CHART: "GET_ORG_CHART",
  SET_FORBIDDEN_ORG: "SET_FORBIDDEN_ORG",
  RESET_KEEP_FILTERS: "RESET_KEEP_FILTERS",
};