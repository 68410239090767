import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import {SidebarCard} from "../../../@xc-core/components";

const InsuredDetails = (props: ILoadableComponentProps) => {
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            productIcon,
            sidebar,
            isKhmer,
            // initValues
        },
    } = props;

    // useEffect(()=>{
    //     if(item.properties["main applicant"] === undefined){
    //         console.log("masuk")
    //         initValues([
    //             {
    //                 id:'properties.main applicant',
    //                 value:{}
    //             }
    //         ])
    //     }
    // },[])

    return (
        <Row>
            <Col md={'8'}>
                <Col className={'pl-0'} md='12'>
                    <h5 className='sub-title'>Applicant Details</h5>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        validationErrors={validationErrors}
                        fields={model.insuredDetailFields.fields.filter((el: IField) => el.group === 'applicantDetails')}
                    />
                </Col>
                <Col className={'pl-0'} md='12'>
                    <h5 className='sub-title'>Mailing Address</h5>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        validationErrors={validationErrors}
                        fields={model.insuredDetailFields.fields.filter((el: IField) => el.group === 'mailingAddress')}
                    />
                </Col>
            </Col>
            <Col md='4'>
                <SidebarCard.Quotation
                    customIconProduct={productIcon}
                    item={item}
                    model={sidebar}
                    ext={isKhmer}
                />
            </Col>
        </Row>
    )
}

export default InsuredDetails;