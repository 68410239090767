const passwordManagementActionTypes = {
  SET_ITEM: 'SET_ITEM',

  LIST_FAILED: 'PASS_MANAGE_LIST_FAILED',
  LIST_LOADING: 'PASS_MANAGE_LIST_LOADING',
  LIST_SET_FORBIDDEN: 'PASS_MANAGE_LIST_SET_FORBIDDEN',
  SET_CURRENT_PASS_MANAGE_INFO: 'SET_CURRENT_PASS_MANAGE_INFO',

  LIST_GET: 'PASS_MANAGE_LIST_GET',
  DETAIL_GET: 'PASS_MANAGE_DETAIL_GET',
  LIST_SET_NOT_FOUND: 'PASS_MANAGE_LIST_SET_NOT_FOUND',
  LIST_RESET: 'PASS_MANAGE_LIST_RESET',
  LIST_CHANGE_SEARCH_KEY: 'PASS_MANAGE_LIST_CHANGE_SEARCH_KEY',
  LIST_CHANGE_PAGE: 'PASS_MANAGE_LIST_CHANGE_PAGE',
  LIST_CHANGE_PAGE_SIZE: 'PASS_MANAGE_LIST_CHANGE_PAGE_SIZE',
  LIST_CHANGE_FILTERS: 'PASS_MANAGE_LIST_CHANGE_FILTERS',
  LIST_RESET_FILTERS: 'PASS_MANAGE_LIST_RESET_FILTERS',
  LIST_APPLY_FILTERS: 'PASS_MANAGE_LIST_APPLY_FILTERS',
  LIST_SET_HAS_RECORDS_INITIALLY: 'PASS_MANAGE_LIST_SET_HAS_RECORDS_INITIALLY',

  ENTITY_LOADING: 'PASS_MANAGE_LOG_ENTITY_LOADING',
  ENTITY_FAILED: 'PASS_MANAGE_LOG_ENTITY_FAILED',
  ENTITY_GET: 'PASS_MANAGE_LOG_ENTITY_GET',
  ENTITY_SET_FORBIDDEN: 'PASS_MANAGE_LOG_ENTITY_SET_FORBIDDEN',

};

export default passwordManagementActionTypes;
