import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import { sidebarModel } from 'models';
import BasicInfoForm from "../containers/BasicInfoForm";
import PersonalInfo from "../containers/PersonalInfo";
import {datesOfBirth} from "../../../shared/utils/utils";
import {validationObject} from "../utils/step4.utils";
import config from "_config";
import { updateIndicesByType } from "utils"; 
import { SidebarCard } from '@xc-core/components';

class CustomerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator(validationObject);
    this.state = this.props.curentThis.state;
  }

  async componentDidMount() {
    const {curentThis} = this.props;
    const {
      props: {
        item: {properties},
        history: {location: {pathname}},
      }
    } = curentThis;
    if (pathname.includes("editQuotation")) {
      const country = properties.Country || config.products.hreTravelInsurance.defaultValues.country;
      await this.props.getStates(country);
    //   curentThis.createOtherInsuredObj();
    //   curentThis.createBeneficiaryObj();
    // } else if (!properties["Other Insured"]) {
    //   curentThis.setState(prevState => ({
    //     properties: {...prevState.properties, "Other Insured": OtherInsured}
    //   }));
      if (curentThis.state.OtherInsured && !curentThis.state.OtherInsured.length) {
        curentThis.createOtherInsuredObj();
      }
    }
    this.props.onRef(this);
  }

  getAlert() {
    if (this.validator.allValid()) {
      // this.props.curentThis.setState({disabled:false});
      return true;
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      // this.props.curentThis.setState({disabled:true});
      return false;
    }
  }


  render() {
    this.validator.purgeFields();
    // const { properties } = this.props.curentThis.props.item;
    const {curentThis} = this.props;
    const {props: {item, item: {properties, productName}}} = curentThis;
    const {OtherInsured} = this.props.curentThis.state;
    const curentStep = this;
    const mainInsurerIsAdult = !!Number.parseInt(properties.Adults); // set it to true if adult if not 0
    let adultIndex = mainInsurerIsAdult ? 1 : 0;
    let seniorIndex = !mainInsurerIsAdult ? 1 : 0;
    let childIndex = 0;
    const indices = { adult: adultIndex, child: childIndex, senior: seniorIndex };
    return (
      <Row>
        <Col md="8">
          <h5 className="sub-title">Please enter the insured person(s) details</h5>
          <p className="custom-text-primary">
            {`${mainInsurerIsAdult ? "Adult 1" : "Senior Citizen 1"} - `}
            Main Insured Details
          </p>
          <Row>
            <BasicInfoForm
              updatedVal={curentThis.state.properties}
              handleInputChange={curentThis.handleInputChange}
              index="base"
              startDate={curentThis.state.properties["DOB"]}

              openToDateOfBirth={mainInsurerIsAdult ? datesOfBirth.adult.maxDate : datesOfBirth.seniorCitizen.maxDate}
              maxDateOfBirth={mainInsurerIsAdult ? datesOfBirth.adult.maxDate : datesOfBirth.seniorCitizen.maxDate}
              minDateOfBirth={mainInsurerIsAdult ? datesOfBirth.adult.minDate : datesOfBirth.seniorCitizen.minDate}

              onChange={curentThis.handleDOB}
              collections={curentThis.props.collections}
              validator={this.validator}
              beneficiary={false}
              curentThis={curentThis}
              handleDobInputDateChange = {curentThis.handleDOB}
              autoFocus = {true}
            />
          </Row>
          <p className="mt-4"> Contact Details </p>
          <Row>
            <PersonalInfo
              updatedVal={curentThis.state.properties}
              handleInputChange={curentThis.handleInputChange}
              index=""
              startDate={curentThis.state.properties["DOB"]}
              onChange={curentThis.handleDOB}
              collections={curentThis.props.collections}
              validator={this.validator}
              curentThis={curentThis}
            />
          </Row>
          {OtherInsured.length >= 1 &&
          OtherInsured.map((data, index) => {
            let dateOfBirth;
            switch (data.Type) {
              case "Adult":
                dateOfBirth = datesOfBirth.adult;
                break;
              case "Senior Citizen":
                dateOfBirth = datesOfBirth.seniorCitizen;
                break;
              case "Child":
                dateOfBirth = datesOfBirth.children;
                break;
              default:
                dateOfBirth = datesOfBirth.adult;
            }
            return (
              <Fragment>
                <hr/>
                <p className="custom-text-primary">{`${data.Type} ${updateIndicesByType(indices, data.Type)}`}</p>
                <Row>
                  <BasicInfoForm
                    key={index}
                    updatedVal={curentThis.state.OtherInsured[index]}
                    handleInputChange={curentThis.updateOtherInsured.bind(
                      this,
                      data.id
                    )}
                    index={index}
                    startDate={curentThis.state.OtherInsured[index]["DOB"]}
                    openToDateOfBirth={dateOfBirth.maxDate}
                    maxDateOfBirth={dateOfBirth.maxDate}
                    minDateOfBirth={dateOfBirth.minDate}
                    onChange={curentThis.updateOtherInsured.bind(
                      this,
                      data.id
                    )}
                    validator={this.validator}
                    collections={curentThis.props.collections}
                    beneficiary={false}
                    curentThis={curentThis}
                    curentStep={curentStep}
                    curentID={data.id}
                    handleDobInputDateChange = {curentThis.updateOtherInsured.bind(this,data.id)}
                    autoFocus = {false}   
                  />
                </Row>
              </Fragment>
            );
          })}
        </Col>
        <Col md="4">
          <SidebarCard.Quotation
            properties={properties}
            data={properties}
            productName={productName}
            model={sidebarModel.quotation.travelHRe}
            item={item}
          />
        </Col>
      </Row>
    );
  }
}

export default CustomerDetails;
