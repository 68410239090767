import React from 'react';
import {Row, Col} from 'reactstrap';
import {objLib, fieldLib} from '@xc-core/lib';
import lib from 'utils/lib';

const Individual = (props: { model: IObject[], item: IObject, index: number, ext?: boolean }) => {
    const {model, item, index, ext} = props;
    return (
        <ul className='list-group'>
            {
                model.map((m: any) => {
                    const id = fieldLib.applyIndexToRawId(index, m.id);
                    return (
                        <li key={id} className='list-group-item d-flex justify-content-between border-0 px-0 py-1 grey-bg summary-label'>
                            {m.label}{(lib.checkIsDemoSite()) && ((ext && m.label === 'Relationship') ? ' / ទំនាក់ទំនង' : m.ext)}
                            <span className='col-5 p-0 wordBreak summary-label-2'>{objLib.getValueWithLodash(item, id)}</span>
                        </li>
                    );
                })
            }
        </ul>
    );
};

const Pair = (props: {
    model: {
        additionalDriver: IObject[],
        insured: IObject[]
    }, item: IObject, index: number
}) => {
    const {model, item, index} = props;    
    const numberOfAdditionalDriver = item.properties["Number of Additional Drivers"]
    return (
        <>
            <Row>
                <Col md='6'>
                    <p className='custom-text-primary'>Insured Person Details {lib.checkIsDemoSite() && '/ ព័ត៌មានលម្អិតរបស់អ្នកត្រូវបានធានារ៉ាប់រង'}</p>
                    <Individual model={model.insured} item={item} index={index} ext={lib.checkIsDemoSite()}/>
                </Col>
                <Col md='6'>
                    {numberOfAdditionalDriver >= 1 &&
                        (<>
                            <p className='custom-text-primary'>Additional Driver Details 1 </p>
                            <Individual model={model.additionalDriver}

                                        item={item} index={index} ext={lib.checkIsDemoSite()}/>
                        </>
                        )
                    }
                    {numberOfAdditionalDriver >= 2 &&
                        (<>
                                <p className='custom-text-primary'>Additional Driver Details 2 </p>
                                <Individual model={model.additionalDriver} item={item} index={index} ext={lib.checkIsDemoSite()}/>
                            </>
                        )
                    }
                    {numberOfAdditionalDriver >= 3 &&
                        (<>
                                <p className='custom-text-primary'>Additional Driver Details 3 </p>
                                <Individual model={model.additionalDriver} item={item} index={index} ext={lib.checkIsDemoSite()}/>
                            </>
                        )
                    }
                    {numberOfAdditionalDriver >= 4 &&
                        (<>
                                <p className='custom-text-primary'>Additional Driver Details 4 </p>
                                <Individual model={model.additionalDriver} item={item} index={index} ext={lib.checkIsDemoSite()}/>
                            </>
                        )
                    }
                </Col>
            </Row>
        </>
    );
};

const Detail = (props: {
    item: IObject,
    mainModel: IObject[], additionalDriverModel: IObject[]
}) => {
    const {
        item,
        mainModel,
        additionalDriverModel,
    } = props;
    return (
        <Pair
            index={0}
            item={item}
            model={{
                insured: mainModel,
                additionalDriver: additionalDriverModel,
            }}
        />
    );
};

export default Detail;
