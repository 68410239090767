import React, {useState, useEffect, useRef} from 'react';
import { Header } from '../../@xc-core/components/core/header';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import { Col, Row } from 'reactstrap';
import Pagination from '@xc-core/components/core/table/pagination';
import CollectionManagementService from '@xc-core/services/collectionManagement.service';
import Search from './search';
import Body from './body';
import DeleteModal from "./deleteModal";
import { handleError, mediaLib } from '@xc-core/lib';
import Notifications from 'react-notification-system-redux';
import { getNotificationMessage } from '../../_services';
import { useDispatch } from 'react-redux';

const service = new CollectionManagementService();

const preparePagination = (result :IObject) =>{
  return {
    empty: result.empty,
    first: result.first,
    last: result.last,
    pageNumber: result.pageable.pageNumber,
    pageSize: result.pageable.pageSize,
    totalElements: result.totalElements,
    totalPages: result.totalPages,
    numberOfElements: result.numberOfElements,
  }
}

function CollectionManagement (props:IObject) {
    const [ collections, setCollections] = useState<IObject[]>([])
    const [ editItemIndex, setEditItemIndex] = useState<number>(-1);
    const [ editItemIsNew, setEditItemIsNew] = useState<boolean>(false);
    const [ editItem, setEditItem] = useState<IObject>({});
    const fileInput = useRef<HTMLInputElement>(document.createElement("input"));
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
    const [requestRecordToDelete, setRequestRecordToDelete] = useState<IObject>({});
    const [searchKey, setSearchKey] = useState<string>("");
    const [pagination, setPagination] = useState<IPageInfo>(preparePagination({pageable:{}}));
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const dispatch = useDispatch();


    useEffect(()=>{
       getCollections();
    },[])

    useEffect(()=>{
      updateList();
    },[page,pageSize])

    useEffect(()=> {
      if(page === 0){
        updateList()
      } else{
        setPage(0);
      }
    },[searchKey])

    const updateList = ()=> {
      setEditItemIndex(-1);
      setEditItemIsNew(false);
      setEditItem({});
      if(searchKey.length > 0){
          searchCollections()
      } else{
        if(collections.length > 0){
          getCollections()
        }
      }
    }



    const searchCollections = async () => {
      try{
          let result =  await service.search(searchKey,page,pageSize); 
          setCollections(result.content)
          setPagination(preparePagination(result))
      } catch(e){
        handleError(e)
      }
    }

    const getCollections = async () => {
      try{
          let result =  await service.getCollections(page,pageSize); 
          setCollections(result.content)
          setPagination(preparePagination(result))
       } catch (e){
        handleError(e)
      }
  }

    const paginationPageHandler = (pair:IPair) =>{
      setPage(pair.value)
    }

    const paginationPageSizeHandler = (pair:IPair) =>{
      setPageSize(pair.value)
      setPage(0)
    }

    

    const onEdit = (index:number) => {
      if(editItemIndex === -1){
        if(fileInput.current){
          fileInput.current.value = "";
        }
        setEditItem(collections[index])
        setEditItemIndex(index)
      }
    }

    const onAddNew = () => {
      
        if(editItemIndex === -1){
          setCollections([...collections,{common:{}}])
          setEditItemIsNew(true);
          setEditItemIndex(collections.length)
          // fileInput.current.value = "";
        }
    }

    const onSave = async () => {
        if(editItemIndex !== -1){
          const formData = new FormData();
          if(!editItem.name || !editItem.code){
            // @ts-ignore
            dispatch(Notifications.error(getNotificationMessage("Name and Code must not be empty")))
            return;
          }
          if(editItemIsNew){
            formData.append('data',JSON.stringify({name:editItem.name,code:editItem.code}))
            if(fileInput.current.files && fileInput.current.files[0]){
              formData.append('file',fileInput.current.files[0])
            } else {
              // @ts-ignore
              dispatch(Notifications.error(getNotificationMessage('Add the collection csv file')))
              return;
            }
          } else {
            formData.append('data',JSON.stringify({id:collections[editItemIndex].id, name:editItem.name,code:editItem.code}))
  
            if(fileInput.current.files && fileInput.current.files[0]){
              formData.append('file',fileInput.current.files[0])
            }
          }
          
          
          try{
            await service.save(formData);
            await getCollections();
            // @ts-ignore
            dispatch(Notifications.success(getNotificationMessage('Saved Successfully')))
            setEditItem({})
            setEditItemIsNew(false);
            setEditItemIndex(-1);
            setSearchKey("");
          } catch (e){
            handleError(e)
          }
  
        }
      
    }

    const onCancel = () => {
      if(editItemIsNew){
        setCollections(collections.slice(0,collections.length-1))
      }
      setEditItemIsNew(false);
      setEditItemIndex(-1);
      setEditItem({})
    }

    const onDelete = (index:number) =>{
      setDeleteModalIsOpen(true)
      setRequestRecordToDelete(collections[index])
    }

    const onDownloadCollection = async (code: string) =>{
      try{
        const blob = await service.downloadCollection(code)
        mediaLib.downloadFile(code+'.csv', blob, false);
      } catch(e){
        handleError(e)
      }
    }

    const handleInputChange = (pairs:IPair[])=>{
      console.log(pairs)
    }

    const confirmDelete = async (record:IObject) => {

      try{
        await service.delete({id:record.id})
        setDeleteModalIsOpen(false)
        setRequestRecordToDelete({})
        setEditItemIndex(-1);
        setEditItem({})
        setCollections(collections.filter(item => item.id !== record.id))
        // @ts-ignore
        dispatch(Notifications.success(getNotificationMessage('Deleted Successfully')))

      } catch (e){
        handleError(e)
      }
    }

    // if(collections.length === 0) return <Loader />

    return (
    <div data-testid="containter">
        <Header
                title={"Collections Management"}
                bannerGraphic={bannerImg}
                withBorderBottom
              />
            <Row className='d-flex justify-content-center my_30'>
                <Col sm={12} style={{padding : '0 4%'}}>
                    <Search
                      searchKey={searchKey}
                      setSearchKey={setSearchKey}
                    >
                        <button className="btn btn-primary" onClick={()=> onAddNew()}>Add New</button>
                    </Search>
                </Col>
                <Col sm={12} className='d-flex' style={{ padding: '0 4%' }}>
                  <Body
                    onEdit={onEdit}
                    collections={collections}
                    setEditItem={setEditItem}
                    editItem={editItem}
                    editItemIndex={editItemIndex}
                    editItemIsNew={editItemIsNew}
                    fileInput={fileInput}
                    onDelete={onDelete}
                    onDownload={onDownloadCollection}
                  />
                </Col>   
                <Col sm={12} className="mt-4" style={{ padding: '0 4%' }}>
                  <Pagination 
                    pageHandler={(pair: IPair) => paginationPageHandler(pair)}
                    pageSizeHandler={(pair: IPair) => paginationPageSizeHandler(pair)}
                    pagination={pagination}
                  />
                </Col>   

                <Col sm={12} className='col-sm-12 d-flex justify-content-end' style={{ padding: '0 4%' }}>
                {(editItemIndex !== -1) && <button
                    type='button'
                    className='btn btn-outline-primary mt-4'
                    onClick={onCancel}>
                    Cancel
                  </button>}
                <button
                  type='button'
                  className='btn btn-primary mt-4 ml-4'
                  onClick={onSave}
                >
                  Save
                </button>
                </Col> 
                <DeleteModal
                  isOpen={deleteModalIsOpen}
                  toggleModal={()=>setDeleteModalIsOpen(!deleteModalIsOpen)}
                  record={requestRecordToDelete}
                  handleDelete={(record:IObject)=> confirmDelete(record)}
                />
            </Row>
    </div>
    )

}

export default CollectionManagement;