
import React from 'react';
import { listLib } from '@xc-core/lib';
import Select from '../select';
import Header from './header';

interface IMultiProductHeaderProps {
  title: string,
  bannerGraphic: any,
  collections: IProduct[],
  selectedProduct: IProduct,
  handler: (product: IProduct) => void,
}
const MultiProductHeader = (props: IMultiProductHeaderProps) => {
  const {
    title, bannerGraphic, handler,
    collections, selectedProduct,
  } = props;
  const showCollections = collections && collections.length > 1;
  const pairCollection = listLib.convertToPairList(collections, ['id', 'name']);
  const selectedValue = selectedProduct.name;
  const onProductChange = (pair: IPair) => {
    const product = collections.find((item: IProduct) => item.name === pair.value)
      || collections[0];
    handler(product);
  };

  const ProductSelection = () => (
    <>
      {
        showCollections ? (
          <Select
            id='products'
            name='products'
            className='px-2 pr-4'
            value={selectedValue}
            handler={(pair: IPair) => onProductChange(pair)}
            collections={pairCollection}
          />
        ) : <></>
      }
    </>
  );

  return (
    <Header title={title} bannerGraphic={bannerGraphic}>
      <ProductSelection />
    </Header>
  );
};

export default MultiProductHeader;
