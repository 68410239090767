import React from 'react';
import { connect } from 'react-redux';
import BaseWizard from '@containers/getQuote/wizard/baseWizard';
import { model, defaultValues } from './config';
import { Message, Loader } from '@xc-core/components/core';
import {
    objLib, fieldLib,
    dateLib, stringLib,
  } from '@xc-core/lib';
import config from '@xc-core/config';
import { GetQuoteActions, PolicyActions } from '@redux/actions';
import { userMessages } from '@xc-core/constants';
import RenderWizard from '@containers/getQuote/wizard/renderWizard';
import { ComponentLoader } from '@xc-core/components';
import * as repo from './components';

class HGITravelWizard extends BaseWizard<IBaseWizardProps, IBaseWizardState> {
    protected defaultCountry = defaultValues.country;

    protected stateListParam = defaultValues.stateListParam;

    protected model = model;
  
    constructor(props: IBaseWizardProps) {
        super(props);
        this.state = {
          sendingRequest: false,
          invalidForm: false,
          item: {},
          validationErrors: [],
        };
      }

    
    protected prepareSteps = (steps: IStep[]): IStep[] => (steps.map((step: IStep) => {
        const metadata = this.getStepMetadata(step.order);
        return {
          ...step,
          componentType: metadata.componentType,
          formContainer: metadata.formContainer,
        };
    }))


    protected getStepMetadata = (order: number): { componentType: string,
        formContainer: IFormContainer } => {

            switch (order) {
                case 1:
                  return {
                    componentType: 'TripDetails',
                    formContainer: {
                      title: 'Fill in trip details',
                      tripDetail: this.createTripDetailsForm(),
                      fields: this.model.fieldsModel,
                    },
                  };
                case 2:
                    return {
                    componentType: 'PlanSelection',
                    formContainer: {
                        title: '',
                        forms: this.createPlanSelectionForm(),
                    },
                };
                case 3:
                    return {
                    componentType: 'InsuredDetails',
                    formContainer: {
                        title: 'Please enter the insured person(s) details',
                        mainDetail: this.createMainInsuredFormModel(),
                        contactDetail: this.createContactDetailFormModel(),
                        otherDetail: this.createOtherInsuredFormModel(),
                        fields: this.model.fieldsModel,
                    },
                };
                case 4:
                    return {
                    componentType: 'BeneficiaryDetail',
                    formContainer: {
                        title: 'Please enter beneficiary details',
                        beneficiaryDetail: this.createBeneficiaryFormModel(),
                        otherBeneficiaryDetail: this.createOtherBeneficiaryFormModel(),
                        fields: this.model.fieldsModel,
                    },
                };
                case 5:
                    return {
                    componentType: 'Summary',
                    formContainer: {
                        title: '',
                        mainModel: model.mainDetailModel,
                        mainBeneficiaryModel: model.mainBeneficiaryDetail,
                        otherModel: model.otherDetailModel,
                        otherBeneficiaryModel: model.otherBeneficiaryDetailModel
                    },
                };
                case 6:
                    return {
                      componentType: 'PaymentDetail',
                      formContainer: {
                        title: '',
                        paymentDetail: this.createPaymentFormModel(),
                      },
                    };
                case 7:
                    return {
                        componentType: 'ThankyouPage',
                        formContainer: {
                        title: '',
                        },
                    };
                default:
                  return {
                    componentType: '',
                    formContainer: {
                      title: '',
                    },
                };
            }
        }
        

    // step 1
    private createTripDetailsForm = (): IForm => {
        const title = 'Trip Details';
        const tripType: IField = {
            id: model.fieldsModel.tripType.id,
            label: model.fieldsModel.tripType.label,
            type: model.fieldsModel.tripType.type,
            style: { col: '12' },
            handler: this.handleTripType,
            collectionId: model.fieldsModel.tripType.collectionId,
            disabled: this.defaultFieldDisabled,
        };
        const destination: IField = {
            id: model.fieldsModel.destination.id,
            label: model.fieldsModel.destination.label,
            type: model.fieldsModel.destination.type,
            style: { col: '12' },
            handler: this.defaultFieldHandler,
            collectionId: model.fieldsModel.destination.collectionId,
            disabled: this.defaultFieldDisabled,
        };
        const startDate: IField = {
            id: model.fieldsModel.startDate.id,
            label: model.fieldsModel.startDate.label,
            type: model.fieldsModel.startDate.type,
            style: this.defaultFieldStyle,
            handler: this.handleStartEndDate,
            disabled: this.defaultFieldDisabled,
            placeholder: model.fieldsModel.startDate.placeholder,
            getMin: () => dateLib.getCurrentDate(config.dateMomentFormat),
            start: model.fieldsModel.startDate.start,
            end: model.fieldsModel.endDate.end,
        };
        const endDate: IField = {
            id: model.fieldsModel.endDate.id,
            label: model.fieldsModel.endDate.label,
            type: model.fieldsModel.endDate.type,
            style: this.defaultFieldStyle,
            handler: this.handleStartEndDate,
            disabled: this.isEndDateDisabled,
            placeholder: model.fieldsModel.endDate.placeholder,
            getMin: (properties: IObject) => this.getMinEndDate(properties),
            start: model.fieldsModel.startDate.start,
            end: model.fieldsModel.endDate.end,
        };
        const insuredType: IField = {
            id: model.fieldsModel.insuredType.id,
            label: model.fieldsModel.insuredType.label,
            type: model.fieldsModel.insuredType.type,
            style: { col: '12' },
            handler: this.handleInsuredType,
            collectionId: model.fieldsModel.insuredType.collectionId,
            disabled: this.defaultFieldDisabled,
        };
        const adults: IField = {
          id: model.fieldsModel.adults.id,
          label: model.fieldsModel.adults.label,
          type: model.fieldsModel.adults.type,
          style: { col: '4' },
          handler: this.handleInsureds,
          disabled: this.defaultFieldDisabled,
          hidden: this.isIndividual
        };
        const children: IField = {
          id: model.fieldsModel.children.id,
          label: model.fieldsModel.children.label,
          type: model.fieldsModel.children.type,
          style: { col: '4' },
          handler: this.handleInsureds,
          disabled: this.defaultFieldDisabled,
          hidden: this.isIndividual
        };
        const seniorCitizen: IField = {
          id: model.fieldsModel.seniorCitizen.id,
          label: model.fieldsModel.seniorCitizen.label,
          type: model.fieldsModel.seniorCitizen.type,
          style: { col: '4' },
          handler: this.handleInsureds,
          disabled: this.defaultFieldDisabled,
          hidden: this.isIndividual
        };
        const adultRadio: IField = {
          id: model.fieldsModel.adultRadio.id, //+ model.fieldsModel.adultRadio.label,
          label: model.fieldsModel.adultRadio.label,
          type: model.fieldsModel.adultRadio.type,
          value: model.fieldsModel.adultRadio.value,
          style: { col: '4' },
          handler: this.handleIndividualTypeRadio,
          check: this.handleIndividualTypeCheck,
          disabled: this.defaultFieldDisabled,
          hidden: this.isNotIndividual
        };
        const seniorCitizenRadio: IField = {
          id: model.fieldsModel.seniorCitizenRadio.id,// +model.fieldsModel.seniorCitizenRadio.label,
          label: model.fieldsModel.seniorCitizenRadio.label,
          type: model.fieldsModel.seniorCitizenRadio.type,
          value: model.fieldsModel.seniorCitizenRadio.value,
          style: { col: '4' },
          handler: this.handleIndividualTypeRadio,
          check: this.handleIndividualTypeCheck,
          disabled: this.defaultFieldDisabled,
          hidden: this.isNotIndividual

        };
        return {
            getTitle: () => title,
            fields: [tripType, destination, startDate, endDate, insuredType,adultRadio, seniorCitizenRadio, adults, children, seniorCitizen],
        };
    }
    protected handleTripType = (pair: IPair, item: IObject): IPair[] => {
        const { value } = pair;
        const noOfDaysId = ['properties', 'No. of Days'];
        const startDateId = model.fieldsModel.startDate.id;
        const endDateId = model.fieldsModel.endDate.id;
        // const destination = objLib.getValueWithLodash(item, model.fieldsModel.destination.id);
    
        let endDate;
        const prevStartDate:string = objLib.getValueWithLodash(item, startDateId);
    
        if (value === 'Annual') {
          endDate = this.getEndDateForAnnualType(prevStartDate);
        } else {
          endDate = dateLib.addToDate(prevStartDate, 0, 0, 4);
        }
        const noOfDays = this.getNoOfDays(prevStartDate, endDate);
    
        return [
          { id: pair.id, value: pair.value },
          { id: endDateId, value: endDate },
          { id: startDateId, value: prevStartDate },
          { id: noOfDaysId, value: noOfDays },
          { id: model.fieldsModel.destination.id, value: '' },
        ];
    }
    private isEndDateDisabled = (item: IObject): boolean => {
        const tripTypeId = model.fieldsModel.tripType.id;
        return objLib.getValueWithLodash(item, tripTypeId) === 'Annual';
    };
    private getMinEndDate = (item: IObject): string => {
        const startDate = objLib.getValueWithLodash(item, model.fieldsModel.startDate.id) || '';
        const currentDate = dateLib.getCurrentDate(config.dateMomentFormat);
        return startDate || currentDate;
    }
    protected handleStartEndDate = (pair: IPair, item: IObject): IPair[] => {
        const pairsToUpdate: IPair[] = [];
        const prevStartDate = objLib.getValueWithLodash(item, model.fieldsModel.startDate.id);
        const prevEndDate = objLib.getValueWithLodash(item, model.fieldsModel.endDate.id);
        const tripType = objLib.getValueWithLodash(item, model.fieldsModel.tripType.id);
        let noOfDays = 0;
        if (pair.id.includes('Start Date') && prevEndDate) {
          if (tripType === 'Annual') {
            const endDate = this.getEndDateForAnnualType(pair.value);
            pairsToUpdate.push({ id: model.fieldsModel.endDate.id, value: endDate });
            noOfDays = this.getNoOfDays(endDate, pair.value);
          } else {
            noOfDays = this.getNoOfDays(prevEndDate, pair.value);
            const isStartDateGreater = dateLib.compare(pair.value, prevEndDate,
              config.dateMomentFormat) > 0;
            if (isStartDateGreater) {
              const newEndDate = dateLib.addToDate(pair.value, 0, 0, 4);
              noOfDays = this.getNoOfDays(newEndDate, pair.value);
              pairsToUpdate.push({ id: model.fieldsModel.endDate.id, value: newEndDate });
            }
          }
        } else if (pair.id.includes('End Date') && prevStartDate) {
          noOfDays = this.getNoOfDays(pair.value, prevStartDate);
          pairsToUpdate.push({ id: model.fieldsModel.startDate.id, value: prevStartDate });
        }
        pairsToUpdate.push({ id: pair.id, value: pair.value });
        pairsToUpdate.push({ id: ['properties', 'No. of Days'], value: noOfDays });
        return pairsToUpdate;
    }
    private getNoOfDays = (date1: string, date2: string) => dateLib.getDiff(date1, date2, 'days') + 1;

    private getEndDateForAnnualType = (startDate: string) => {
        let endDate = dateLib.addToDate(startDate, 1, 0, 0);
        endDate = dateLib.subtractFromDate(endDate, 0, 0, 1);
        return endDate;
    };
    private isIndividual = (item: IObject) =>{
      return item.properties['Insured Type'] === 'Individual';
    }
    private isNotIndividual = (item: IObject) =>{
      return item.properties['Insured Type'] !== 'Individual';
    }

    private handleInsuredType = (pair:IPair, item:IObject): IPair[] =>{
      return [
        { id: pair.id, value:pair.value},
        { id: 'properties.Main Applicant.Type', value: ''},
        { id: 'properties.Adults', value: 0},
        { id: 'properties.Children', value: 0},
        { id: 'properties.Senior Citizen', value: 0},
        { id: ['properties', 'Other Insured'], value: []}
      ]
    }

    private handleInsureds = (pair: IPair, item:IObject): IPair[] =>{
      let count: IObject ={
        'Adults' : item.properties['Adults'],
        'Children': item.properties['Children'],
        'Senior Citizen': item.properties['Senior Citizen']
      }
      count[pair.id.slice(pair.id.indexOf('.')+1)] = pair.value

      let mainInsuredType = '';
      if(count['Adults'] <= 0 && count['Senior Citizen'] > 0){
        mainInsuredType = 'Senior Citizen'
        count['Senior Citizen'] = count['Senior Citizen'] -1;
      } else {
        mainInsuredType = 'Adult'
        count['Adults'] = count['Adults'] -1;
      }
      let otherInsured = [];
      for(let i = 0;i<count['Adults'];i++){
       otherInsured.push({Type:'Adult'}) 
      }
      for(let i = 0;i<count['Senior Citizen'];i++){
        otherInsured.push({Type:'Senior Citizen'}) 
      }
      for(let i = 0;i<count['Children'];i++){
        otherInsured.push({Type:'Children'}) 
      }

      const preValue = objLib.getValueWithLodash(item, pair.id);
      const value = parseInt(this.restrictToNumber(pair.value, preValue));
      let result = [
        { id: pair.id, value: isNaN(value) ? 0 : parseInt((value + "").substr(0,3)) },
        { id: ['properties', 'Main Applicant', 'Type'], value: mainInsuredType},
        { id: ['properties', 'Other Insured'], value: otherInsured}
      ];
      if(!pair.id.includes('Adults')){
        result.push({id: 'properties.Adults', value: item.properties['Adults']})
      }
      return result;
    }

    private handleIndividualTypeCheck = (id: string,item: IObject) =>{
      let mainType = objLib.getValueWithLodash(item, model.fieldsModel.type.id);
      return id.includes(mainType) && mainType.length > 0;
    }

    private handleIndividualTypeRadio = (pair: IPair, item:IObject) :IPair[] =>{
      let toAddType = '';
      let toRemoveType = '';
      if(pair.value.includes('Adult')){
        toAddType = 'Adults';
        toRemoveType = 'Senior Citizen'
      } else if (pair.value.includes('Senior Citizen')){
        toAddType = 'Senior Citizen';
        toRemoveType = 'Adults'
      }
      return [
        { id: 'properties.Main Applicant.Type', value: pair.value},
        { id: `properties.${toRemoveType}`, value: 0},
        { id: `properties.${toAddType}`, value: 1},
      ];
    }


    // step 2
    private createPlanSelectionForm = (): IForm => {
        const title = '';
        const plan: HGIPlanField = {
          id: model.fieldsModel.plan.id,
          label: model.fieldsModel.plan.label,
          type: model.fieldsModel.plan.type,
          style: this.defaultFieldStyle,
          handler: this.handlePlan,
          disabled: this.defaultFieldDisabled,
          collectionId: model.fieldsModel.plan.collectionId,
        };
        return { getTitle: () => title, fields: [plan] };
    }
    private handlePlan = async (step: IStep, plan: IObject) => {
        const { getQuote, getItemOnNext } = this.props;
        const { item: { properties: { priceList } } } = getQuote;
        if (getQuote.product && priceList) {
          let { properties } = getQuote.item;
          const Price: IObject = {};
          // eslint-disable-next-line no-return-assign
          Object.keys(plan).filter((key: string) => key !== 'Plan').forEach((key: string) => Price[key] = plan[key]);
          properties = {
            ...properties,
            priceList,
            Price,
            Plan: plan.Plan,
          };
          this.setState((prevState: IBaseWizardState) => ({
            ...prevState,
            item: {
              ...prevState.item,
              properties: {
                ...prevState.item.properties,
                ...properties,
              },
            },
          }));
          const updatedItem = await getItemOnNext(getQuote.product.id, getQuote.item.id, properties);
          await this.updateStateItem(updatedItem);
          this.setValues([{ id: model.fieldsModel.country.id, value: this.defaultCountry }]);
        }
    }

    // step 3
    private createMainInsuredFormModel = (): IForm => {
        const title = 'Main Insured Details';
        const fullName: IField = {
          id: model.fieldsModel.fullName.id,
          label: model.fieldsModel.fullName.label,
          type: model.fieldsModel.fullName.type,
          style: this.defaultFieldStyle,
          handler: this.handleFullName,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.fullName.placeholder,
        };
        const nationality: IField = {
          id: model.fieldsModel.nationality.id,
          label: model.fieldsModel.nationality.label,
          type: model.fieldsModel.nationality.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          collectionId: model.fieldsModel.nationality.collectionId,
          disabled: this.defaultFieldDisabled,
        };
        const idType: IField = {
          id: model.fieldsModel.idType.id,
          label: model.fieldsModel.idType.label,
          type: model.fieldsModel.idType.type,
          style: this.defaultFieldStyle,
          handler: this.handleMainIdType,
          disabled: this.defaultFieldDisabled,
          collectionId: model.fieldsModel.idType.collectionId,
        };
        const idNumber: IField = {
          id: model.fieldsModel.idNumber.id,
          label: model.fieldsModel.idNumber.label,
          type: model.fieldsModel.idNumber.type,
          style: this.defaultFieldStyle,
          handler: this.handleMainIdNumber,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.idNumber.placeholder,
        };
        const dob: IField = {
          id: model.fieldsModel.dob.id,
          label: model.fieldsModel.dob.label,
          type: model.fieldsModel.dob.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          getMax: (item,id) => this.getMaxDob(item,id),
          getMin: (item,id) => this.getMinDob(item,id),
          // current: this.getMaxDob(),
          placeholder: model.fieldsModel.dob.placeholder,
        };
        const gender: IField = {
          id: model.fieldsModel.gender.id,
          label: model.fieldsModel.gender.label,
          type: model.fieldsModel.gender.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          collectionId: model.fieldsModel.gender.collectionId,
        };
        const occupation: IField = {
          id: model.fieldsModel.occupation.id,
          label: model.fieldsModel.occupation.label,
          type: model.fieldsModel.occupation.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.occupation.placeholder,
        };
        return {
          getTitle: () => title,
          fields: [fullName, nationality, idType, idNumber, dob, gender, occupation],
        };
    }
    private createContactDetailFormModel = (): IForm => {
        const title = 'Contact Details';
        const address: IField = {
          id: model.fieldsModel.address.id,
          label: model.fieldsModel.address.label,
          type: model.fieldsModel.address.type,
          style: { col: '12' },
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.address.placeholder,
        };
        const country: IField = {
          id: model.fieldsModel.country.id,
          label: model.fieldsModel.country.label,
          type: model.fieldsModel.country.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.alwaysDisabled,
          collectionId: model.fieldsModel.country.collectionId,
        };
        const state: IField = {
          id: model.fieldsModel.state.id,
          label: model.fieldsModel.state.label,
          type: model.fieldsModel.state.type,
          collectionId: model.fieldsModel.state.collectionId,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
        };
        const postCode: IField = {
          id: model.fieldsModel.postCode.id,
          label: model.fieldsModel.postCode.label,
          type: model.fieldsModel.postCode.type,
          style: this.defaultFieldStyle,
          handler: this.handlePostcode,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.postCode.placeholder,
        };
        const city: IField = {
          id: model.fieldsModel.city.id,
          label: model.fieldsModel.city.label,
          type: model.fieldsModel.city.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.city.placeholder,
        };
        const email: IField = {
          id: model.fieldsModel.email.id,
          label: model.fieldsModel.email.label,
          type: model.fieldsModel.email.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.email.placeholder,
        };
        const contactNo: IField = {
          id: model.fieldsModel.contactNo.id,
          label: model.fieldsModel.contactNo.label,
          type: model.fieldsModel.contactNo.type,
          style: this.defaultFieldStyle,
          handler: this.handleNumber,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.contactNo.placeholder,
        };
        return {
          getTitle: () => title,
          fields: [address, country, state, postCode, city, email, contactNo],
        };
    }

    private createOtherInsuredFormModel = (): IForm =>{
      const title = 'Other Insured Details';
      const fullName: IField = {
        id: model.fieldsModel.otherInsuredFullName.id,
        label: model.fieldsModel.otherInsuredFullName.label,
        type: model.fieldsModel.otherInsuredFullName.type,
        style: this.defaultFieldStyle,
        handler: this.handleFullName,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredFullName.placeholder,
      };
      const nationality: IField = {
        id: model.fieldsModel.otherInsuredNationality.id,
        label: model.fieldsModel.otherInsuredNationality.label,
        type: model.fieldsModel.otherInsuredNationality.type,
        style: this.defaultFieldStyle,
        handler: this.defaultFieldHandler,
        collectionId: model.fieldsModel.otherInsuredNationality.collectionId,
        disabled: this.defaultFieldDisabled,
      };
      const idType: IField = {
        id: model.fieldsModel.otherInsuredIdType.id,
        label: model.fieldsModel.otherInsuredIdType.label,
        type: model.fieldsModel.otherInsuredIdType.type,
        style: this.defaultFieldStyle,
        handler: this.handleOtherIdType,
        disabled: this.defaultFieldDisabled,
        collectionId: model.fieldsModel.otherInsuredIdType.collectionId,
      };
      const idNumber: IField = {
        id: model.fieldsModel.otherInsuredIdNumber.id,
        label: model.fieldsModel.otherInsuredIdNumber.label,
        type: model.fieldsModel.otherInsuredIdNumber.type,
        style: this.defaultFieldStyle,
        handler: this.handleOtherIdNumber,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredIdNumber.placeholder,
      };
      const dob: IField = {
        id: model.fieldsModel.otherInsuredDob.id,
        label: model.fieldsModel.otherInsuredDob.label,
        type: model.fieldsModel.otherInsuredDob.type,
        style: this.defaultFieldStyle,
        handler: this.defaultFieldHandler,
        disabled: this.defaultFieldDisabled,
        getMax: (item,id) => this.getMaxDob(item,id),
        getMin: (item,id) => this.getMinDob(item,id),
        // current: this.getMaxDob(),
        placeholder: model.fieldsModel.otherInsuredDob.placeholder,
      };
      const gender: IField = {
        id: model.fieldsModel.otherInsuredGender.id,
        label: model.fieldsModel.otherInsuredGender.label,
        type: model.fieldsModel.otherInsuredGender.type,
        style: this.defaultFieldStyle,
        handler: this.defaultFieldHandler,
        disabled: this.defaultFieldDisabled,
        collectionId: model.fieldsModel.otherInsuredGender.collectionId,
      };
      const occupation: IField = {
        id: model.fieldsModel.otherInsuredOccupation.id,
        label: model.fieldsModel.otherInsuredOccupation.label,
        type: model.fieldsModel.otherInsuredOccupation.type,
        style: this.defaultFieldStyle,
        handler: this.defaultFieldHandler,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredOccupation.placeholder,
      };
      const email: IField = {
        id: model.fieldsModel.otherInsuredEmail.id,
        label: model.fieldsModel.otherInsuredEmail.label,
        type: model.fieldsModel.otherInsuredEmail.type,
        style: this.defaultFieldStyle,
        handler: this.defaultFieldHandler,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredEmail.placeholder,
      };
      const contactNo: IField = {
        id: model.fieldsModel.otherInsuredContactNo.id,
        label: model.fieldsModel.otherInsuredContactNo.label,
        type: model.fieldsModel.otherInsuredContactNo.type,
        style: this.defaultFieldStyle,
        handler: this.handleNumber,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredContactNo.placeholder,
      };
      return {
        getTitle: () => title,
        fields: [fullName, nationality, idType,idNumber, dob,gender, occupation,email,contactNo],
      };
    }

    protected handleMainIdType = (pair: IPair, item: IObject): IPair[] => {
      let idNumber = objLib.getValueWithLodash(item, model.fieldsModel.idNumber.id);
      const idType = objLib.getValueWithLodash(item, pair.id);
      if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed
  
      return [pair, { id: model.fieldsModel.idNumber.id, value: idNumber }];
    }

    private handleMainIdNumber = (pair: IPair, item: IObject): IPair[] => {
        let { value } = pair;
        const preValue = objLib.getValueWithLodash(item, pair.id);
        value = this.restrictToAlphanumeric(value, preValue);
        return [
          { id: pair.id, value },
          ];
    }

    private handleOtherIdType = (pair: IPair, item: IObject) : IPair[] =>{
      const correspondingIdNumber = fieldLib.getCorrespondingId(pair.id,model.fieldsModel.otherInsuredIdNumber.id);
      let idNumber = objLib.getValueWithLodash(item, correspondingIdNumber);
      const idType = objLib.getValueWithLodash(item, pair.id);
      if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed
  
      return [pair, { id: correspondingIdNumber, value: idNumber }];
    }
    private handleOtherIdNumber = (pair: IPair, item: IObject) : IPair[] =>{
      let { value } = pair;
      const preValue = objLib.getValueWithLodash(item, pair.id);
      value = this.restrictToAlphanumeric(value, preValue);
      return [
        { id: pair.id, value },
      ];
    }

    private getMaxDob = (item:IObject,id: string): string => {
      // @ts-ignore
      let range = defaultValues.dobRanges[objLib.getType(item,id)]
      return dateLib.subtractFromNow(range.min.years, range.min.months, range.min.days);
    }
    private getMinDob = (item:IObject,id:string): string => {
      // @ts-ignore
      let range = defaultValues.dobRanges[objLib.getType(item,id)]
      return dateLib.subtractFromNow(range.max.years, range.max.months, range.max.days);
    }
    protected handlePostcode = (pair: IPair, item: IObject): IPair[] => {
      const pre = objLib.getValueWithLodash(item, pair.id);
      let { value } = pair;
      // value = value.length > this.PostCodeLength ? value.slice(0, this.PostCodeLength) : value;
      value = this.restrictToNumber(value, pre);
      return [{ id: pair.id, value }];
    }

    private alwaysDisabled = (): boolean => true;

    // step 4
    private createBeneficiaryFormModel = (): IForm => {
        const beneficiaryName: IField = {
          id: model.fieldsModel.beneficiaryName.id,
          label: model.fieldsModel.beneficiaryName.label,
          type: model.fieldsModel.beneficiaryName.type,
          style: this.defaultFieldStyle,
          handler: this.handleFullName,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.beneficiaryName.placeholder,
        };
        const beneficiaryRelation: IField = {
          id: model.fieldsModel.beneficiaryRelation.id,
          label: model.fieldsModel.beneficiaryRelation.label,
          type: model.fieldsModel.beneficiaryRelation.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          collectionId: model.fieldsModel.beneficiaryRelation.collectionId,
        };
        const beneficiaryIdType: IField = {
          id: model.fieldsModel.beneficiaryIdType.id,
          label: model.fieldsModel.beneficiaryIdType.label,
          type: model.fieldsModel.beneficiaryIdType.type,
          style: this.defaultFieldStyle,
          handler: this.handleBeneficiaryIdType,
          disabled: this.defaultFieldDisabled,
          collectionId: model.fieldsModel.beneficiaryIdType.collectionId,
        };
        const beneficiaryIdNumber: IField = {
          id: model.fieldsModel.beneficiaryIdNumber.id,
          label: model.fieldsModel.beneficiaryIdNumber.label,
          type: model.fieldsModel.beneficiaryIdNumber.type,
          style: this.defaultFieldStyle,
          handler: this.handleBeneficiaryIdNumber,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.beneficiaryIdNumber.placeholder,
    
        };
        const beneficiaryEmail: IField = {
          id: model.fieldsModel.beneficiaryEmail.id,
          label: model.fieldsModel.beneficiaryEmail.label,
          type: model.fieldsModel.beneficiaryEmail.type,
          style: this.defaultFieldStyle,
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.beneficiaryEmail.placeholder,
        };
        const beneficiaryPhone: IField = {
          id: model.fieldsModel.beneficiaryPhone.id,
          label: model.fieldsModel.beneficiaryPhone.label,
          type: model.fieldsModel.beneficiaryPhone.type,
          style: this.defaultFieldStyle,
          handler: this.handleNumber,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.beneficiaryPhone.placeholder,
        };
        return {
          getTitle: () => 'beneficiary form',
          fields: [beneficiaryName, beneficiaryRelation, beneficiaryIdType,
            beneficiaryIdNumber, beneficiaryEmail, beneficiaryPhone],
        };
    }

    private createOtherBeneficiaryFormModel = () : IForm =>{
      const beneficiaryName: IField = {
        id: model.fieldsModel.otherInsuredBeneficiaryName.id,
        label: model.fieldsModel.otherInsuredBeneficiaryName.label,
        type: model.fieldsModel.otherInsuredBeneficiaryName.type,
        style: this.defaultFieldStyle,
        handler: this.handleFullName,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredBeneficiaryName.placeholder,
      };
      const beneficiaryRelation: IField = {
        id: model.fieldsModel.otherInsuredBeneficiaryRelation.id,
        label: model.fieldsModel.otherInsuredBeneficiaryRelation.label,
        type: model.fieldsModel.otherInsuredBeneficiaryRelation.type,
        style: this.defaultFieldStyle,
        handler: this.defaultFieldHandler,
        disabled: this.defaultFieldDisabled,
        collectionId: model.fieldsModel.otherInsuredBeneficiaryRelation.collectionId,
      };
      const beneficiaryIdType: IField = {
        id: model.fieldsModel.otherInsuredBeneficiaryIdType.id,
        label: model.fieldsModel.otherInsuredBeneficiaryIdType.label,
        type: model.fieldsModel.otherInsuredBeneficiaryIdType.type,
        style: this.defaultFieldStyle,
        handler: this.handleOtherBeneficiaryIdType,
        disabled: this.defaultFieldDisabled,
        collectionId: model.fieldsModel.otherInsuredBeneficiaryIdType.collectionId,
      };
      const beneficiaryIdNumber: IField = {
        id: model.fieldsModel.otherInsuredBeneficiaryIdNumber.id,
        label: model.fieldsModel.otherInsuredBeneficiaryIdNumber.label,
        type: model.fieldsModel.otherInsuredBeneficiaryIdNumber.type,
        style: this.defaultFieldStyle,
        handler: this.handleOtherBeneficiaryIdNumber,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredBeneficiaryIdNumber.placeholder,
  
      };
      const beneficiaryEmail: IField = {
        id: model.fieldsModel.otherInsuredBeneficiaryEmail.id,
        label: model.fieldsModel.otherInsuredBeneficiaryEmail.label,
        type: model.fieldsModel.otherInsuredBeneficiaryEmail.type,
        style: this.defaultFieldStyle,
        handler: this.defaultFieldHandler,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredBeneficiaryEmail.placeholder,
      };
      const beneficiaryPhone: IField = {
        id: model.fieldsModel.otherInsuredBeneficiaryPhone.id,
        label: model.fieldsModel.otherInsuredBeneficiaryPhone.label,
        type: model.fieldsModel.otherInsuredBeneficiaryPhone.type,
        style: this.defaultFieldStyle,
        handler: this.handleNumber,
        disabled: this.defaultFieldDisabled,
        placeholder: model.fieldsModel.otherInsuredBeneficiaryPhone.placeholder,
      };
      return {
        getTitle: () => 'other beneficiary form',
        fields: [beneficiaryName, beneficiaryRelation, beneficiaryIdType,
          beneficiaryIdNumber, beneficiaryEmail, beneficiaryPhone],
      };
    }

    protected handleBeneficiaryIdType = (pair: IPair, item: IObject): IPair[] => {
      const idNumberId = fieldLib.applyIndexToRawId(fieldLib.getIndex(pair.id),
        model.fieldsModel.beneficiaryIdNumber.id);
      let idNumber = objLib.getValueWithLodash(item, idNumberId);
      const idType = objLib.getValueWithLodash(item, pair.id);
      if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed
  
      return [pair, { id: idNumberId, value: idNumber }];
  }

    private handleBeneficiaryIdNumber = (pair: IPair, item: IObject): IPair[] => {
        let { value } = pair;
        const preValue = objLib.getValueWithLodash(item, pair.id);
        value = this.restrictToAlphanumeric(value, preValue);
        return [{ id: pair.id, value }];
    }


    private handleOtherBeneficiaryIdType = (pair: IPair, item: IObject): IPair[] => {
      const correspondingIdNumber = fieldLib.getCorrespondingId(pair.id,model.fieldsModel.otherInsuredBeneficiaryIdNumber.id);
      let idNumber = objLib.getValueWithLodash(item, correspondingIdNumber);
      const idType = objLib.getValueWithLodash(item, pair.id);
      if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed

      return [pair, { id: correspondingIdNumber, value: idNumber }];
    }

    private handleOtherBeneficiaryIdNumber = (pair: IPair, item: IObject): IPair[] => {
      let { value } = pair;
      const preValue = objLib.getValueWithLodash(item, pair.id);
      value = this.restrictToAlphanumeric(value, preValue);
      return [{ id: pair.id, value }];
    }

    // step 6
    private createPaymentFormModel = (): IForm => {
        const paymentMode: IField = {
          id: model.fieldsModel.paymentMode.id,
          label: model.fieldsModel.paymentMode.label,
          type: model.fieldsModel.paymentMode.type,
          style: { col: '12' },
          handler: this.handlePaymentMode,
          disabled: this.defaultFieldDisabled,
          collectionId: this.model.fieldsModel.paymentMode.collectionId,
        };
        const receiptNo: IField = {
          id: model.fieldsModel.receiptNo.id,
          label: model.fieldsModel.receiptNo.label,
          type: model.fieldsModel.receiptNo.type,
          style: { col: '12' },
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          placeholder: model.fieldsModel.receiptNo.placeholder,
        };
        const paymentReceivedBy: IField = {
          id: model.fieldsModel.paymentReceivedBy.id,
          label: model.fieldsModel.paymentReceivedBy.label,
          type: model.fieldsModel.paymentReceivedBy.type,
          style: { col: '12' },
          handler: this.defaultFieldHandler,
          disabled: this.defaultFieldDisabled,
          collectionId: model.fieldsModel.paymentReceivedBy.collectionId,
        };
        return {
          getTitle: () => 'payment details',
          fields: [paymentMode, receiptNo, paymentReceivedBy],
        };
    }

    private handlePaymentMode = (pair: IPair, item: IObject): IPair[] => {
        let receivedByValue = objLib.getValueWithLodash(item, model.fieldsModel.paymentReceivedBy.id);
        if (!receivedByValue) {
          const { user } = config;
          receivedByValue = user.name;
        }
        return [pair, { id: model.fieldsModel.paymentReceivedBy.id, value: receivedByValue }];
    }
    

    render() {
        const { getQuote, getQuote: { collections, product, steps } } = this.props;
        const {
            item, validationErrors, sendingRequest, invalidForm,
        } = this.state;
        const currentStep = this.getCurrentStep();
        const paymentSelected = stringLib.isEmpty(objLib.getValueWithLodash(item,
            this.model.fieldsModel.paymentReceivedBy.id)) === false;
        if (product === null) return <Message message={userMessages.ERROR} />;
        if (collections === null || steps.length === 0) return <Loader />;
        const isBeneficirayEnterd = true;
        return (
            <RenderWizard
              getQuote={getQuote}
              getCurrentStep={this.getCurrentStep}
              handleNext={this.next}
              handlePrev={this.previous}
              handleSkip={this.skip}
              paymentSelected={paymentSelected}
              isBeneficirayEnterd={isBeneficirayEnterd}
              sendingRequest={sendingRequest}
              invalidForm={invalidForm}
            >
              <ComponentLoader
                name={currentStep.componentType}
                repo={repo}
                propsModel={{
                  onChange: (handler: string, obj: IHandledValue) => this[handler](obj),
                  initValues: (pairs: IPair[]) => this.setValues(pairs),
                  collections,
                  item,
                  currentStep,
                  model: currentStep.formContainer,
                  validationErrors,
                  defaults: { country: this.defaultCountry },
                }}
              />
            </RenderWizard>
          );
    }

}

const getQuoteActions = new GetQuoteActions();
const policyActions = new PolicyActions();

const mapStateToProps = (state: IStore) => ({
    getQuote: state.newGetQuote,
  });
  
const mapDispatchToProps = (dispatch: any) => ({
    init: (productCode: number, country: string,
        stateListParam: string) => dispatch(getQuoteActions.init(productCode, country, stateListParam)),
    setStateInCollections: (country: string,
        param: string) => dispatch(getQuoteActions.setStateInCollections(country, param)),
    setSteps: (steps: IStep[]) => dispatch(getQuoteActions.setSteps(steps)),
    getItemOnNext: (productCode: number, id: number,
        properties: IObject) => dispatch(getQuoteActions.getItemOnNext(productCode, id, properties)),
    getItemOnPrevious: (productCode: number,
        id: number) => dispatch(getQuoteActions.getItemOnPrevious(productCode, id)),
    downloadCertificate: (id: string) => dispatch(policyActions.download(id)),
    reset: () => dispatch(getQuoteActions.reset()),
});
export default connect(mapStateToProps, mapDispatchToProps)(HGITravelWizard);