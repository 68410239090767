import lib from '../../../utils/lib';
function defaultDisabled() {
  return false;
}

function defaultHandler(pair: IPair) {
  return pair;
}

function numberValue(pair:IPair){
  return {id:pair.id, value:parseInt(pair.value)}
}

function agePropertyHandler(pair:IPair){
  const {id,value} = pair;
  return lib.propertyAgeHandler(id,value)
}

const model = {
  header: {
    productName: { id: "productName", name: "", config: {} },
    plan: { id: 'properties.Coverage Type', name: '', config: {} },
    items: [
      {
        current: {
          id: "properties.Price.Total",
          name: "Total Price",
          config: { currency: true },
        },
        prev: {
          id: "properties.Previous Price.Total",
          name: "",
          config: { currency: true, className: "cross-line gray bold" },
        },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: "Price Breakdown",
        name: "Price Breakdown",
        items: [
          {
            current: {
              id: "properties.Price.Main Policy",
              name: "Gross Premium",
              config: { currency: true },
            },
            prev: {
              id: "properties.Previous Price.Main Policy",
              name: "",
              config: { currency: true, className: "cross-line gray bold" },
            },
            config: {},
          },
          {
            current: {
              id: "properties.Price.Admin Fee",
              name: "Admin Fee",
              config: { currency: true },
            },
            prev: {
              id: "properties.Previous Price.Admin Fee",
              name: "",
              config: { currency: true, className: "cross-line gray bold" },
            },
            config: {},
          },
        ],
        config: {},
      },
      {
        id: "Other Details",
        name: "Other Details",
        items: [
          {
            current: {
              id: "properties.Product Type",
              name: "Product Type",
              config: {isEditable: false},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Sum Insured",
              name: "Sum Insured",
              type: "Input",
              disabled: defaultDisabled,
              handler: numberValue,
              config: {},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Coverage Type",
              name: "Coverage Type",
              type: "Select",
              collectionId: "coverage_types",
              disabled: defaultDisabled,
              handler: defaultHandler,
              config: {},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Cubic Capacity",
              name: "Cubic Capacity",
              type: "Input",
              disabled: defaultDisabled,
              handler: numberValue,
              config: {},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Property Age",
              name: "Year of Manufacture",
              type: "Select",
              collectionId:"years_of_manufacture",
              placeholder: "Select Option year of manufacture",
              disabled: defaultDisabled,
              handler: agePropertyHandler,
              config: {},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Start Date",
              name: "Start Date",
              type: "DatePicker",
              disabled: defaultDisabled,
              handler: defaultHandler,
              placeholder: "Select Date",
              config: {},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.End Date",
              name: "End Date",
              type: "DatePicker",
              disabled: ()=>true,
              handler: defaultHandler,
              placeholder: "Select Date",
              config: {},
            },
            prev: { config: {} },
            config: {},
          }
        ],
        config: { isEditable: true },
      },
    ],
  },
};

export default model;
