import React from "react";
import PropTypes from "prop-types"
import SelectedFiltersChip from "./SelectedFiltersChip";
import FilterButton from "./FilterButton";
import FilterFooter from "./FilterFooter";
import FilterCard from "./FilterCard";
import FilterBody from "./FilterBody";
import SelectedFiltersCol from "./SelectedFiltersCol";
import FilterValuesCol from "./FilterValuesCol";
import FilterTypesCol from "./FilterTypesCol";


class Filter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCardVisible: false,
      activeIndex: 0,
      searchKeyword: "",
    };
  }

  setActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };

  onSearchKeywordChange = (e) => {
    const value = e && e.target && e.target.value;
    this.setState({ searchKeyword: value });
  };

  onAddFilterClicked = () => {
    this.setState({
      isCardVisible: true,
    });
  }

  onCancelClick = () => {
    const { cancelFilter } = this.props;
    this.setState({
      isCardVisible: false,
      searchKeyword: "",
      activeIndex: 0,
    });
    cancelFilter();
  }

  onApplyClick = () => {
    const { applyFilter, filterOptions } = this.props;
    this.setState({ isCardVisible: false, searchKeyword: "", activeIndex: 0 });
    applyFilter(filterOptions);
  }

  getFilterTypeForIndex = (index) => {
    const { filterOptions } = this.props;
    if (!filterOptions || !filterOptions.length) return [];
    if (index >= filterOptions.length) {
      this.setActiveIndex(0);
      return filterOptions[0] || [];
    }
    return filterOptions[index] || [];
  }

  getFilterIndexById = (id) => {
    const { filterOptions } = this.props;
    return filterOptions.findIndex((filter) => filter.id === id);
  }

  removeChipByFilterId = (filterId) => {
    const { filterOptions, applyFilter } = this.props;
    const index = this.getFilterIndexById(filterId);
    const selectedFilter = filterOptions[index];
    const clearSelectedFilterList = selectedFilter.list.map((option) => {
      const newOption = { ...option, isChecked: false };
      if (selectedFilter.type === "dateRange") {
        newOption.value = "";
      }
      return newOption;
    });
    const clearSelectedFilter = {
      ...selectedFilter,
      list: clearSelectedFilterList,
      checkedCount: 0,
    };
    const newFilterOptions = [
      ...filterOptions.slice(0, index),
      { ...clearSelectedFilter },
      ...filterOptions.slice(index + 1),
    ];
    applyFilter(newFilterOptions);
  };

  onDateRangeChange = (rangeObj) => {
    const { startDate, endDate } = rangeObj;
    const { activeIndex } = this.state;
    const { filterOptions, onCheckChange } = this.props;

    const filterToChange = filterOptions[activeIndex];
    let checkedCount = 0;
    const newValues = filterToChange.list.map((value) => {
      const newValue = value;
      if (newValue.code === "startDate") {
        newValue.value = startDate;
        newValue.isChecked = true;
      }
      if (newValue.code === "endDate") {
        newValue.value = endDate;
        newValue.isChecked = true;
      }
      return newValue;
    });
    newValues.forEach((value) => {
      if (value.isChecked) checkedCount += 1;
    });
    const changedOption = { ...filterToChange, checkedCount, list: newValues };
    const newFilterOptions = this.getUpdatedOptionsArray(activeIndex, changedOption);
    onCheckChange(newFilterOptions);
  };

  onValueCheckChange = (changedValue) => {
    const { filterOptions, onCheckChange } = this.props;
    const { activeIndex } = this.state;
    const changedIndex = activeIndex;

    const filterToChange = filterOptions[changedIndex];
    let checkedCount = 0;
    const newValues = filterToChange.list.map((value) => {
      if (value.code === changedValue.code) return changedValue;
      return value;
    });
    newValues.forEach((value) => {
      if (value.isChecked) checkedCount += 1;
    });
    const changedOption = { ...filterToChange, checkedCount, list: newValues };
    const newFilterOptions = this.getUpdatedOptionsArray(changedIndex, changedOption);
    onCheckChange(newFilterOptions);
  }

  getUpdatedOptionsArray = (changedIndex, changedOption) => {
    const { filterOptions } = this.props;
    return [
      ...filterOptions.slice(0, changedIndex),
      { ...changedOption },
      ...filterOptions.slice(changedIndex + 1),
    ];
  };

  render() {
    const {
      isCardVisible,
      activeIndex,
      searchKeyword,
    } = this.state;
    const { filterOptions, isLoading } = this.props;
    const activeFilter = this.getFilterTypeForIndex(activeIndex);
    return (
      <>
        <div id="filterModal" className="d-flex align-items-center flex-wrap">
          <FilterButton onClick={this.onAddFilterClicked} />
          <SelectedFiltersChip
            filters={filterOptions}
            removeChip={(filterId) => this.removeChipByFilterId(filterId)}
          />
        </div>
        <FilterCard
          isVisible={isCardVisible}
          isLoading={isLoading}
        >
          <FilterBody>
            <FilterTypesCol
              filterOptions={filterOptions}
              activeIndex={activeIndex}
              setActiveIndex={this.setActiveIndex}
              getFilterIndexById={(id) => this.getFilterIndexById(id)}
            />
            <FilterValuesCol
              values={activeFilter.list}
              valuesType={activeFilter.type}
              searchKeyword={searchKeyword}
              onSearchKeywordChange={(e) => this.onSearchKeywordChange(e)}
              onValueCheckChange={(value) => this.onValueCheckChange(value)}
              onDateRangeChange={(rangeObj) => this.onDateRangeChange(rangeObj)}
            />
            <SelectedFiltersCol
              filters={filterOptions}
            />
          </FilterBody>
          <FilterFooter
            onApplyClick={() => this.onApplyClick()}
            onCancelClick={this.onCancelClick}
          />
        </FilterCard>
      </>
    );
  }
}
Filter.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  applyFilter: PropTypes.func.isRequired,
  onCheckChange: PropTypes.func.isRequired,
  cancelFilter: PropTypes.func.isRequired,
};
export default Filter;
