import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import {SidebarCard} from "../../../@xc-core/components";
import lib from "../../../utils/lib";

const CarOwnerDetails =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            sidebar,
            currentStep,
        },
    } = props;

    return (
        <Row>
            <Col md={`${currentStep.order === 3 ? '12' : '8'}`}>
                <h5 className='sub-title'>{model.title}</h5>
                <RenderFields
                    onChange={(pairs: IPair[]) => {
                        onChange('onChange', {pairs})
                    }}
                    collections={collections}
                    item={item}
                    ext={lib.checkIsDemoSite()}
                    validationErrors={validationErrors}
                    fields={model.carOwnerDetails.fields}
                />
            </Col>
            {
                currentStep.order !== 3 && <Col md='4'>
                    <SidebarCard.Quotation
                        customIconProduct={"icon-hospital"}
                        item={item}
                        model={sidebar}
                        ext={lib.checkIsDemoSite()}
                        currencySymbol={'RM'}
                    />
                </Col>
            }
        </Row>
    )
}

export default CarOwnerDetails;