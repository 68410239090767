import React from "react";
import {
  Row,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import PropTypes from "prop-types";

const CustomPagination = (props) => {
  const {
    // pagination,
    pagination: {
      first,
      last,
      totalPages,
      currentPage,
      totalElement,
      perPage,
    },
  } = props;

  const calculateNumberPagination = () => {
    const numOfPagination = 4;
    const startIndex = parseInt(currentPage / numOfPagination, 10) * numOfPagination;
    let endIndex = startIndex + numOfPagination;
    if (endIndex > totalPages) endIndex = totalPages;

    const numbers = [];
    for (let idx = startIndex; idx < endIndex; idx += 1) {
      numbers.push(idx);
    }
    return {
      startIndex,
      numbers,
      endIndex,
    };
  };

  const paginateHandler = (num) => {
    const { onClick } = props;
    onClick(num, perPage);
  };

  const perPaginateHandler = (e) => {
    const { onClick } = props;
    const { target: { value } } = e;
    onClick(0, value);
  };

  const paginationData = calculateNumberPagination();

  return (
    (totalElement > 10 || totalPages > 1)
      ? (
        <Row>
          <Col sm="8">
            <Pagination aria-label="Page navigation example">
              <PaginationItem className={first ? "disabled" : ""}>
                <PaginationLink onClick={() => paginateHandler(0)}>
                  <span aria-hidden="true">&lsaquo;</span>
                  <span>First</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem className={first ? "disabled" : ""}>
                <PaginationLink onClick={() => paginateHandler(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                  <span>Previous </span>
                </PaginationLink>
              </PaginationItem>
              {
                paginationData.numbers.map((num) => (
                  <PaginationItem key={num} active={num === currentPage}>
                    <PaginationLink onClick={() => paginateHandler(num)}>
                      {num + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))
              }
              <PaginationItem className={last ? "disabled" : ""}>
                <PaginationLink onClick={() => paginateHandler(currentPage + 1)}>
                  <span>Next </span>
                  <span aria-hidden="true">&raquo;</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem className={last ? "disabled" : ""}>
                <PaginationLink onClick={() => paginateHandler(totalPages - 1)}>
                  <span>Last </span>
                  <span aria-hidden="true">&rsaquo;</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
          <Col sm="4" className="text-right customRowshow">
            <label htmlFor="per-paginate" className="customNrow">Show</label>
            <Input
              style={{
                width: "80px",
                display: "inline",
                margin: "0 10px",
                padding: "6px 20px 6px 12px",
              }}
              type="select"
              name="select"
              id="per-paginate"
              value={perPage}
              onChange={perPaginateHandler}
            >
              <option>10</option>
              <option>20</option>
              <option>30</option>
              <option>40</option>
              <option>50</option>
            </Input>
            <span className="customNrow">rows per page</span>
          </Col>
        </Row>
      ) : <></>
  );
};
CustomPagination.propTypes = {
  pagination: PropTypes.object,
  onClick: PropTypes.func,
};
CustomPagination.defaultProps = {
  pagination: {
    first: 0,
    last: 0,
    totalPages: 1,
    currentPage: 1,
    totalElement: 10,
    perPage: 10,
  },
  onClick: () => {},
}
export default CustomPagination;

// const { perPage } = this.state;
// constructor(props) {
//   super(props);
//   this.state = {
//     perPage: 10,
//   };
// }
// componentWillReceiveProps(newProps) {
//   const { pageable } = newProps;
//   let pageSize = 10;
//   if (pageable) pageSize = pageable.pageSize || 10;
//   this.setState({ perPage: pageSize });
// }