import { env as legacyEnv } from '_config/env';

const env = {
  tsd: legacyEnv.apiURL,
  quotation: legacyEnv.quotationUrl,
  policy: legacyEnv.policyUrl,
  rate: legacyEnv.productsUrl,
  audit: legacyEnv.auditUrl,
  siteKey: legacyEnv.siteKey,
  isEnabledReCaptcha: legacyEnv.isEnabledReCaptcha
};

export default env;
