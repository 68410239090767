const orgActionTypes: IOrgActionTypes = {
  LIST_FAILED: 'ORG_LIST_FAILED',
  LIST_LOADING: 'ORG_LIST_LOADING',
  LIST_GET: 'ORG_LIST_GET',
  LIST_SET_FORBIDDEN: 'ORG_LIST_SET_FORBIDDEN',
  LIST_CHANGE_SEARCH_KEY: 'ORG_LIST_CHANGE_SEARCH_KEY',
  LIST_CHANGE_PAGE: 'ORG_LIST_CHANGE_PAGE',
  LIST_CHANGE_PAGE_SIZE: 'ORG_LIST_CHANGE_PAGE_SIZE',
  LIST_CHANGE_FILTERS: 'ORG_LIST_CHANGE_FILTERS',
  LIST_RESET_FILTERS: 'ORG_LIST_RESET_FILTERS',
  LIST_RESET: 'ORG_LIST_RESET',
  LIST_APPLY_FILTERS: 'ORG_LIST_APPLY_FILTERS',
  LIST_SET_NOT_FOUND: 'ORG_LIST_SET_NOT_FOUND',
  LIST_SET_HAS_RECORDS_INITIALLY: 'ORG_LIST_SET_HAS_RECORDS_INITIALLY',
  DETAIL_LOADING: 'ORG_DETAIL_LOADING',
  DETAIL_FAILED: 'ORG_DETAIL_FAILED',
  DETAIL_SET_FORBIDDEN: 'ORG_DETAIL_FORBIDDEN',
  DETAIL_GET: 'ORG_DETAIL_GET',

  CREATE_SET_FORBIDDEN: 'ORG_CREATE_SET_FORBIDDEN',
  UPDATE_SET_FORBIDDEN: 'ORG_UPDATE_SET_FORBIDDEN',

  CREATE_LOADING: 'ORG_CREATE_LOADING',
  CREATE_FAILED: 'ORG_CREATE_FAILED',
  CREATE_GET: 'ORG_CREATE_GET',

  UPDATE_LOADING: 'ORG_UPDATE_LOADING',
  UPDATE_FAILED: 'ORG_UPDATE_FAILED',
  UPDATE_GET: 'ORG_UPDATE_GET',
};

export default orgActionTypes;
