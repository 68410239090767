import React from 'react';
import { connect } from 'react-redux';
import {
  objLib, fieldLib,
  dateLib, stringLib,
} from '@xc-core/lib';
import { userMessages } from '@xc-core/constants';
import { Message, Loader } from '@xc-core/components/core';
import BaseWizard from '@containers/getQuote/wizard/baseWizard';
import RenderWizard from '@containers/getQuote/wizard/renderWizard';
import { ComponentLoader } from '@xc-core/components';
import { GetQuoteActions, PolicyActions } from '@redux/actions';
import config from '@xc-core/config';
import * as repo from './components';
import { model, defaultValues } from './config';
import PaymentForm from './components/paymentForm';
import lib from 'utils/lib'

class ABCTravelWizard extends BaseWizard<IBaseWizardProps, IBaseWizardState> {
  protected defaultCountry = defaultValues.country;

  protected stateListParam = defaultValues.stateListParam;

  protected model = model;

  private CNICLength = defaultValues.cnicLength;

  private PostCodeLength = defaultValues.postcodeLength;

  private DebitCreditNoLength = defaultValues.debitCreditNoLength;

  constructor(props: IBaseWizardProps) {
    super(props);
    this.state = {
      sendingRequest: false,
      invalidForm: false,
      item: {},
      validationErrors: [],
      redirectToGateway: false
    };
  }

  protected prepareSteps = (steps: IStep[]): IStep[] => (steps.map((step: IStep) => {
    const metadata = this.getStepMetadata(step.order);
    return {
      ...step,
      componentType: metadata.componentType,
      formContainer: metadata.formContainer,
    };
  }))

  protected getStepMetadata = (order: number): { componentType: string,
    formContainer: IFormContainer } => {
    switch (order) {
      case 1:
        return {
          componentType: 'TripDetails',
          formContainer: {
            title: 'Please enter Trip information',
            tripDetail: this.createTripDetailsForm(),
            fields: this.model.fieldsModel,
          },
        };
      case 2:
        return {
          componentType: 'PlanSelection',
          formContainer: {
            title: '',
            forms: this.createPlanSelectionForm(),
          },
        };
      case 3:
        return {
          componentType: 'InsuredDetails',
          formContainer: {
            title: 'Please enter customer details',
            mainDetail: this.createMainInsuredFormModel(),
            contactDetail: this.createContactDetailFormModel(),
            fields: this.model.fieldsModel,
          },
        };
      case 4:
        return {
          componentType: 'BeneficiaryDetail',
          formContainer: {
            title: 'Please enter beneficiary details',
            beneficiaryDetail: this.createBeneficiaryFormModel(),
            fields: this.model.fieldsModel,
          },
        };
      case 5:
        return {
          componentType: 'Summary',
          formContainer: {
            title: '',
            mainModel: model.mainDetailModel,
            beneficiaryModel: model.beneficiaryDetailModel,
          },
        };
      case 6:
        return {
          componentType: 'PaymentDetail',
          formContainer: {
            title: '',
            paymentDetail: this.createPaymentFormModel(),
          },
        };
      case 7:
        return {
          componentType: 'ThankyouPage',
          formContainer: {
            title: '',
          },
        };
      default:
        return {
          componentType: '',
          formContainer: {
            title: '',
          },
        };
    }
  }

  protected handleMainIdType = (pair: IPair, item: IObject): IPair[] => {
    let idNumber = objLib.getValueWithLodash(item, model.fieldsModel.idNumber.id);
    const idType = objLib.getValueWithLodash(item, pair.id);
    if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed

    return [pair, { id: model.fieldsModel.idNumber.id, value: idNumber }];
  }

  protected handleStartEndDate = (pair: IPair, item: IObject): IPair[] => {
    const pairsToUpdate: IPair[] = [];
    const prevStartDate = objLib.getValueWithLodash(item, model.fieldsModel.startDate.id);
    const prevEndDate = objLib.getValueWithLodash(item, model.fieldsModel.endDate.id);
    const tripType = objLib.getValueWithLodash(item, model.fieldsModel.tripType.id);
    let noOfDays = 0;
    if (pair.id.includes('Start Date') && prevEndDate) {
      if (tripType === 'Annual') {
        const endDate = this.getEndDateForAnnualType(pair.value);
        pairsToUpdate.push({ id: model.fieldsModel.endDate.id, value: endDate });
        noOfDays = this.getNoOfDays(endDate, pair.value);
      } else {
        noOfDays = this.getNoOfDays(prevEndDate, pair.value);
        const isStartDateGreater = dateLib.compare(pair.value, prevEndDate,
          config.dateMomentFormat) > 0;
        if (isStartDateGreater) {
          const newEndDate = dateLib.addToDate(pair.value, 0, 0, 4);
          noOfDays = this.getNoOfDays(newEndDate, pair.value);
          pairsToUpdate.push({ id: model.fieldsModel.endDate.id, value: newEndDate });
        }
      }
    } else if (pair.id.includes('End Date') && prevStartDate) {
      noOfDays = this.getNoOfDays(pair.value, prevStartDate);
    }
    pairsToUpdate.push({ id: pair.id, value: pair.value });
    pairsToUpdate.push({ id: ['properties', 'No. of Days'], value: noOfDays });
    return pairsToUpdate;
  }

  private getNoOfDays = (date1: string, date2: string) => dateLib.getDiff(date1, date2, 'days') + 1;

  private getEndDateForAnnualType = (startDate: string) => {
    let endDate = dateLib.addToDate(startDate, 1, 0, 0);
    endDate = dateLib.subtractFromDate(endDate, 0, 0, 1);
    return endDate;
  };

  protected handleTripType = (pair: IPair, item: IObject): IPair[] => {
    const { value } = pair;
    const noOfDaysId = ['properties', 'No. of Days'];
    const startDateId = model.fieldsModel.startDate.id;
    const endDateId = model.fieldsModel.endDate.id;
    // const destination = objLib.getValueWithLodash(item, model.fieldsModel.destination.id);

    let endDate;
    const currentStartDate:string = objLib.getValueWithLodash(item, startDateId);

    if (value === 'Annual') {
      endDate = this.getEndDateForAnnualType(currentStartDate);
    } else {
      endDate = dateLib.addToDate(currentStartDate, 0, 0, 4);
    }
    const noOfDays = this.getNoOfDays(currentStartDate, endDate);

    return [
      { id: pair.id, value: pair.value },
      { id: endDateId, value: endDate },
      { id: noOfDaysId, value: noOfDays },
      { id: startDateId, value: currentStartDate },
      { id: model.fieldsModel.destination.id, value: '' },
      { id: model.fieldsModel.countryOfOrigin.id, value: '' },
    ];
  }

  private handleCountryOfOrigin = (pair: IPair): IPair[] => [
    pair,
    { id: model.fieldsModel.destination.id, value: '' },
  ];

  // private handleDestination = (pair: IPair, item: IObject): IPair[] => {
  //   const { value } = pair;
  //   const tripType = objLib.getValueWithLodash(item, model.fieldsModel.tripType.id);
  //   const newPairs: IPair[] = [];
  //   if (tripType === 'Annual' && (value === 'Asia Pacific & Schengen' || value === 'Worldwide')) {
  //     newPairs.push({ id: model.fieldsModel.countryOfOrigin.id, value: 'Cambodia' });
  //   }
  //   return [pair, ...newPairs];
  // }

  private getMaxDob = (): string => {
    const { minAge } = defaultValues;
    return dateLib.subtractFromNow(minAge.years, minAge.months, minAge.days);
  }

  private getMinDob = (): string => {
    const { maxAge } = defaultValues;
    return dateLib.subtractFromNow(maxAge.years, maxAge.months, maxAge.days);
  }

  private isEndDateDisabled = (item: IObject): boolean => {
    const tripTypeId = model.fieldsModel.tripType.id;
    return objLib.getValueWithLodash(item, tripTypeId) === 'Annual';
  };


  private handlePaymentMode = (pair: IPair, item: IObject): IPair[] => {
    let receivedByValue = objLib.getValueWithLodash(item, model.fieldsModel.paymentReceivedBy.id);
    if (!receivedByValue) {
      const { user } = config;
      receivedByValue = user.name;
    }
    return [pair, { id: model.fieldsModel.paymentReceivedBy.id, value: receivedByValue }];
  }

  private getMinEndDate = (item: IObject): string => {
    const startDate = objLib.getValueWithLodash(item, model.fieldsModel.startDate.id) || '';
    const currentDate = dateLib.getCurrentDate(config.dateMomentFormat);
    return startDate || currentDate;
  }

  private handleMainIdNumber = (pair: IPair, item: IObject): IPair[] => {
    let { value } = pair;
    let gender = objLib.getValueWithLodash(item, model.fieldsModel.gender.id) || '';
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const idType = objLib.getValueWithLodash(item, model.fieldsModel.idType.id);
    if (idType === 'CNIC') {
      value = value.length > this.CNICLength ? value.slice(0, this.CNICLength) : value;
      value = this.restrictToNumber(value, preValue);
      gender = value && value.length === this.CNICLength
        ? this.getGenderFromValue(value[this.CNICLength - 1]) : gender;
    } else {
      value = this.restrictToAlphanumeric(value, preValue);
    }
    return [{ id: pair.id, value }, { id: model.fieldsModel.gender.id, value: gender }];
  }

  private handleBeneficiaryIdNumber = (pair: IPair, item: IObject): IPair[] => {
    let { value } = pair;
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const idTypeId = fieldLib.applyIndexToRawId(fieldLib.getIndex(pair.id),
      model.fieldsModel.beneficiaryIdType.id);
    const idType = objLib.getValueWithLodash(item, idTypeId);
    if (idType === 'CNIC') {
      value = value.length > this.CNICLength ? value.slice(0, this.CNICLength) : value;
      value = this.restrictToNumber(value, preValue);
    } else {
      value = this.restrictToAlphanumeric(value, preValue);
    }
    return [{ id: pair.id, value }];
  }

  protected handleBeneficiaryIdType = (pair: IPair, item: IObject): IPair[] => {
    const idNumberId = fieldLib.applyIndexToRawId(fieldLib.getIndex(pair.id),
      model.fieldsModel.beneficiaryIdNumber.id);
    let idNumber = objLib.getValueWithLodash(item, idNumberId);
    const idType = objLib.getValueWithLodash(item, pair.id);
    if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed

    return [pair, { id: idNumberId, value: idNumber }];
  }

  protected handlePostcode = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    // value = value.length > this.PostCodeLength ? value.slice(0, this.PostCodeLength) : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  }

  protected handleDebitCreditNo = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    value = value.length > this.DebitCreditNoLength
      ? value.slice(0, this.DebitCreditNoLength) : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  }

  private createTripDetailsForm = (): IForm => {
    const title = 'Trip Details';
    const tripType: IField = {
      id: model.fieldsModel.tripType.id,
      label: model.fieldsModel.tripType.label,
      ext: model.fieldsModel.tripType.ext,
      type: model.fieldsModel.tripType.type,
      style: { col: '6' },
      handler: this.handleTripType,
      collectionId: model.fieldsModel.tripType.collectionId,
      placeholder: model.fieldsModel.tripType.placeholder,
      disabled: this.defaultFieldDisabled,
    };
    const countryOfOrigin: IField = {
      id: model.fieldsModel.countryOfOrigin.id,
      label: model.fieldsModel.countryOfOrigin.label,
      ext: model.fieldsModel.countryOfOrigin.ext,
      type: model.fieldsModel.countryOfOrigin.type,
      style: { col: '6' },
      handler: this.handleCountryOfOrigin,
      collectionId: model.fieldsModel.countryOfOrigin.collectionId,
      placeholder: model.fieldsModel.countryOfOrigin.placeholder,
      disabled: this.defaultFieldDisabled,
    };
    const destination: IField = {
      id: model.fieldsModel.destination.id,
      label: model.fieldsModel.destination.label,
      ext: model.fieldsModel.destination.ext,
      type: model.fieldsModel.destination.type,
      style: { col: '6' },
      handler: this.defaultFieldHandler,
      collectionId: model.fieldsModel.destination.collectionId,
      placeholder: model.fieldsModel.destination.placeholder,
      disabled: this.defaultFieldDisabled,
    };
    const startDate: IField = {
      id: model.fieldsModel.startDate.id,
      label: model.fieldsModel.startDate.label,
      ext: model.fieldsModel.startDate.ext,
      type: model.fieldsModel.startDate.type,
      style: this.defaultFieldStyle,
      handler: this.handleStartEndDate,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.startDate.placeholder,
      getMin: () => dateLib.getCurrentDate(config.dateMomentFormat),
      start: model.fieldsModel.startDate.start,
      end: model.fieldsModel.endDate.end,
    };
    const endDate: IField = {
      id: model.fieldsModel.endDate.id,
      label: model.fieldsModel.endDate.label,
      ext: model.fieldsModel.endDate.ext,
      type: model.fieldsModel.endDate.type,
      style: this.defaultFieldStyle,
      handler: this.handleStartEndDate,
      disabled: this.isEndDateDisabled,
      placeholder: model.fieldsModel.endDate.placeholder,
      getMin: (properties: IObject) => this.getMinEndDate(properties),
      start: model.fieldsModel.startDate.start,
      end: model.fieldsModel.endDate.end,
    };
    const insuredType: IField = {
      id: model.fieldsModel.insuredType.id,
      label: model.fieldsModel.insuredType.label,
      ext: model.fieldsModel.insuredType.ext,
      type: model.fieldsModel.insuredType.type,
      style: { col: '6' },
      handler: this.defaultFieldHandler,
      collectionId: model.fieldsModel.insuredType.collectionId,
      disabled: this.defaultFieldDisabled,
    };
    return {
      getTitle: () => title,
      fields: [tripType, insuredType, countryOfOrigin, destination, startDate, endDate],
    };
  }

  private createPlanSelectionForm = (): IForm => {
    const title = '';
    const plan: ICBPlanField = {
      id: model.fieldsModel.plan.id,
      label: model.fieldsModel.plan.label,
      ext: model.fieldsModel.plan.ext,
      type: model.fieldsModel.plan.type,
      style: this.defaultFieldStyle,
      handler: this.handlePlan,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.plan.collectionId,
    };
    return { getTitle: () => title, fields: [plan] };
  }

  private createMainInsuredFormModel = (): IForm => {
    const title = 'Main Insured Details';
    const fullName: IField = {
      id: model.fieldsModel.fullName.id,
      label: model.fieldsModel.fullName.label,
      ext: model.fieldsModel.fullName.ext,
      type: model.fieldsModel.fullName.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.fullName.placeholder,
    };
    const nationality: IField = {
      id: model.fieldsModel.nationality.id,
      label: model.fieldsModel.nationality.label,
      ext: model.fieldsModel.nationality.ext,
      type: model.fieldsModel.nationality.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      collectionId: model.fieldsModel.nationality.collectionId,
      placeholder: model.fieldsModel.nationality.placeholder,
      disabled: this.defaultFieldDisabled,
    };
    const idType: IField = {
      id: model.fieldsModel.idType.id,
      label: model.fieldsModel.idType.label,
      ext: model.fieldsModel.idType.ext,
      type: model.fieldsModel.idType.type,
      style: this.defaultFieldStyle,
      handler: this.handleMainIdType,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.idType.collectionId,
      placeholder: model.fieldsModel.idType.placeholder,
    };
    const idNumber: IField = {
      id: model.fieldsModel.idNumber.id,
      label: model.fieldsModel.idNumber.label,
      ext: model.fieldsModel.idNumber.ext,
      type: model.fieldsModel.idNumber.type,
      style: this.defaultFieldStyle,
      handler: this.handleMainIdNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.idNumber.placeholder,
    };
    const dob: IField = {
      id: model.fieldsModel.dob.id,
      label: model.fieldsModel.dob.label,
      ext: model.fieldsModel.dob.ext,
      type: model.fieldsModel.dob.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      getMax: () => this.getMaxDob(),
      getMin: () => this.getMinDob(),
      // current: this.getMaxDob(),
      placeholder: model.fieldsModel.dob.placeholder,
    };
    const gender: IField = {
      id: model.fieldsModel.gender.id,
      label: model.fieldsModel.gender.label,
      ext: model.fieldsModel.gender.ext,
      type: model.fieldsModel.gender.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.gender.collectionId,
      placeholder: model.fieldsModel.gender.placeholder,
    };
    return {
      getTitle: () => title,
      fields: [fullName, nationality, idType, idNumber, dob, gender],
    };
  }

  private createContactDetailFormModel = (): IForm => {
    const title = 'Contact Details';
    const address: IField = {
      id: model.fieldsModel.address.id,
      label: model.fieldsModel.address.label,
      ext: model.fieldsModel.address.ext,
      type: model.fieldsModel.address.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.address.placeholder,
    };
    const country: IField = {
      id: model.fieldsModel.country.id,
      label: model.fieldsModel.country.label,
      ext: model.fieldsModel.country.ext,
      type: model.fieldsModel.country.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.country.collectionId,
    };
    const state: IField = {
      id: model.fieldsModel.state.id,
      label: model.fieldsModel.state.label,
      ext: model.fieldsModel.state.ext,
      type: model.fieldsModel.state.type,
      collectionId: model.fieldsModel.state.collectionId,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
    };
    const postCode: IField = {
      id: model.fieldsModel.postCode.id,
      label: model.fieldsModel.postCode.label,
      ext: model.fieldsModel.postCode.ext,
      type: model.fieldsModel.postCode.type,
      style: this.defaultFieldStyle,
      handler: this.handlePostcode,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.postCode.placeholder,
    };
    const city: IField = {
      id: model.fieldsModel.city.id,
      label: model.fieldsModel.city.label,
      ext: model.fieldsModel.city.ext,
      type: model.fieldsModel.city.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.city.placeholder,
    };
    const email: IField = {
      id: model.fieldsModel.email.id,
      label: model.fieldsModel.email.label,
      ext: model.fieldsModel.email.ext,
      type: model.fieldsModel.email.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.email.placeholder,
    };
    const contactNo: IField = {
      id: model.fieldsModel.contactNo.id,
      label: model.fieldsModel.contactNo.label,
      ext: model.fieldsModel.contactNo.ext,
      type: model.fieldsModel.contactNo.type,
      style: this.defaultFieldStyle,
      handler: this.handleNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.contactNo.placeholder,
    };
    return {
      getTitle: () => title,
      fields: [address, country, state, postCode, city, email, contactNo],
    };
  }

  private createBeneficiaryFormModel = (): IForm => {
    const beneficiaryName: IField = {
      id: model.fieldsModel.beneficiaryName.id,
      label: model.fieldsModel.beneficiaryName.label,
      ext: model.fieldsModel.beneficiaryName.ext,
      type: model.fieldsModel.beneficiaryName.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.beneficiaryName.placeholder,
    };
    const beneficiaryRelation: IField = {
      id: model.fieldsModel.beneficiaryRelation.id,
      label: model.fieldsModel.beneficiaryRelation.label,
      ext: model.fieldsModel.beneficiaryRelation.ext,
      type: model.fieldsModel.beneficiaryRelation.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.beneficiaryRelation.collectionId,
      placeholder: model.fieldsModel.beneficiaryRelation.placeholder,
    };
    const beneficiaryIdType: IField = {
      id: model.fieldsModel.beneficiaryIdType.id,
      label: model.fieldsModel.beneficiaryIdType.label,
      ext: model.fieldsModel.beneficiaryIdType.ext,
      type: model.fieldsModel.beneficiaryIdType.type,
      style: this.defaultFieldStyle,
      handler: this.handleBeneficiaryIdType,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.beneficiaryIdType.collectionId,
      placeholder: model.fieldsModel.beneficiaryIdType.placeholder,
    };
    const beneficiaryIdNumber: IField = {
      id: model.fieldsModel.beneficiaryIdNumber.id,
      label: model.fieldsModel.beneficiaryIdNumber.label,
      ext: model.fieldsModel.beneficiaryIdNumber.ext,
      type: model.fieldsModel.beneficiaryIdNumber.type,
      style: this.defaultFieldStyle,
      handler: this.handleBeneficiaryIdNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.beneficiaryIdNumber.placeholder,

    };
    const beneficiaryEmail: IField = {
      id: model.fieldsModel.beneficiaryEmail.id,
      label: model.fieldsModel.beneficiaryEmail.label,
      ext: model.fieldsModel.beneficiaryEmail.ext,
      type: model.fieldsModel.beneficiaryEmail.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.beneficiaryEmail.placeholder,
    };
    const beneficiaryPhone: IField = {
      id: model.fieldsModel.beneficiaryPhone.id,
      label: model.fieldsModel.beneficiaryPhone.label,
      ext: model.fieldsModel.beneficiaryPhone.ext,
      type: model.fieldsModel.beneficiaryPhone.type,
      style: this.defaultFieldStyle,
      handler: this.handleNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.beneficiaryPhone.placeholder,
    };
    return {
      getTitle: () => 'beneficiary form',
      fields: [beneficiaryName, beneficiaryRelation, beneficiaryIdType,
        beneficiaryIdNumber, beneficiaryEmail, beneficiaryPhone],
    };
  }

  private createPaymentFormModel = (): IForm => {
    const paymentMode: IField = {
      id: model.fieldsModel.paymentMode.id,
      label: model.fieldsModel.paymentMode.label,
      ext: model.fieldsModel.paymentMode.ext,
      type: model.fieldsModel.paymentMode.type,
      style: { col: '12' },
      handler: this.handlePaymentMode,
      disabled: this.defaultFieldDisabled,
      collectionId: this.model.fieldsModel.paymentMode.collectionId,
      placeholder: this.model.fieldsModel.paymentMode.placeholder,
    };
    const receiptNo: IField = {
      id: model.fieldsModel.receiptNo.id,
      label: model.fieldsModel.receiptNo.label,
      ext: model.fieldsModel.receiptNo.ext,
      type: model.fieldsModel.receiptNo.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.receiptNo.placeholder,
    };
    const paymentReceivedBy: IField = {
      id: model.fieldsModel.paymentReceivedBy.id,
      label: model.fieldsModel.paymentReceivedBy.label,
      ext: model.fieldsModel.paymentReceivedBy.ext,
      type: model.fieldsModel.paymentReceivedBy.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.paymentReceivedBy.collectionId,
      placeholder: model.fieldsModel.paymentReceivedBy.placeholder,
    };
    return {
      getTitle: () => 'payment details',
      fields: [paymentMode, receiptNo, paymentReceivedBy],
    };
  }

  private handlePlan = async (step: IStep, plan: IObject) => {
    const { getQuote, getItemOnNext } = this.props;
    const { item: { properties: { priceList } } } = getQuote;
    if (getQuote.product && priceList) {
      let { properties } = getQuote.item;
      const Price: IObject = {};
      // eslint-disable-next-line no-return-assign
      Object.keys(plan).filter((key: string) => key !== 'Plan').forEach((key: string) => Price[key] = plan[key]);
      properties = {
        ...properties,
        priceList,
        Price,
        Plan: plan.Plan,
      };
      this.setState((prevState: IBaseWizardState) => ({
        ...prevState,
        item: {
          ...prevState.item,
          properties: {
            ...prevState.item.properties,
            ...properties,
          },
        },
      }));
      const updatedItem = await getItemOnNext(getQuote.product.id, getQuote.item.id, properties);
      await this.updateStateItem(updatedItem);
      this.setValues([{ id: model.fieldsModel.country.id, value: this.defaultCountry }]);
    }
  }

  render() {
    const {
      getQuote, getQuote: { collections, product, steps },
      history,
    } = this.props;
    const {
      item, validationErrors, sendingRequest, invalidForm,
    } = this.state;
    const currentStep = this.getCurrentStep();
    const paymentSelected = stringLib.isEmpty(objLib.getValueWithLodash(item,
      this.model.fieldsModel.paymentReceivedBy.id)) === false;

    var isBeneficirayEnterd = false;
    for (const element of this.model.beneficiaryDetailModel) {
      if (stringLib.isEmpty(objLib.getValueWithLodash(item, element.id)) === false) {
        isBeneficirayEnterd = true;
      }
    }
    if (product === null) return <Message message={userMessages.ERROR} />;
    if (collections === null || steps.length === 0) return <Loader />;
    if (this.state.redirectToGateway) return <PaymentForm item={item}/>
    return (
      <RenderWizard
        getQuote={getQuote}
        getCurrentStep={this.getCurrentStep}
        handleNext={this.next}
        handlePrev={this.previous}
        handleSkip={this.skip}
        paymentSelected={paymentSelected}
        isBeneficirayEnterd={isBeneficirayEnterd}
        sendingRequest={sendingRequest}
        invalidForm={invalidForm}
        isKhmer={lib.checkIsDemoSite()}
      >
        <ComponentLoader
          name={currentStep.componentType}
          repo={repo}
          history={history}
          propsModel={{
            onChange: (handler: string, obj: IHandledValue) => this[handler](obj),
            initValues: (pairs: IPair[]) => this.setValues(pairs),
            collections,
            item,
            currentStep,
            model: currentStep.formContainer,
            validationErrors,
            defaults: { country: this.defaultCountry },
          }}
        />
      </RenderWizard>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  getQuote: state.newGetQuote,
  // products: state.productList.list,
});

const getQuoteActions = new GetQuoteActions();
const policyActions = new PolicyActions();

const mapDispatchToProps = (dispatch: any) => ({
  init: (productCode: number, country: string,
    stateListParam: string) => dispatch(getQuoteActions.init(productCode, country, stateListParam)),
  setStateInCollections: (country: string,
    param: string) => dispatch(getQuoteActions.setStateInCollections(country, param)),
  setSteps: (steps: IStep[]) => dispatch(getQuoteActions.setSteps(steps)),
  getItemOnNext: (productCode: number, id: number,
    properties: IObject) => dispatch(getQuoteActions.getItemOnNext(productCode, id, properties)),
  getItemOnSkip: (productCode: number, id: number,
    properties: IObject) => dispatch(getQuoteActions.getItemOnSkip(productCode, id, properties)),
  getItemOnPrevious: (productCode: number,
    id: number) => dispatch(getQuoteActions.getItemOnPrevious(productCode, id)),
  downloadCertificate: (id: string) => dispatch(policyActions.download(id)),
  reset: () => dispatch(getQuoteActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ABCTravelWizard);
