import React from 'react';
import { Row, Col } from 'reactstrap';
import { objLib } from '@xc-core/lib';

interface IModel { id: string, label: string }

interface IInfoProps {
  model: IModel[]
  item: IObject
  title: string
  children?: JSX.Element | undefined
}

const Info = (props: IInfoProps) => {
  const {
    model,
    item,
    title,
    children,
  } = props;
  return (
    <>
      <p className='custom-text-primary'>{title}</p>
      <ul className='list-group'>
        {
          model.map((m: IModel) => (
            <li key={m.id} className='list-group-item d-flex justify-content-between border-0 px-0 py-1 grey-bg summary-label'>
              {m.label}
              <span className='col-5 p-0 wordBreak summary-label-2'>
                {objLib.getValueWithLodash(item, m.id)}
              </span>
            </li>
          ))
        }
      </ul>
      {children}
    </>
  );
};

interface ISectionProps {
  left: IInfoProps,
  right?: IInfoProps
  title: string
  children?: JSX.Element | undefined
}

const Section = (props: ISectionProps) => {
  const {
    left,
    right,
    children,
    title,
  } = props;
  return (
    <>
      <h4 className='insured-detail-header my-3 p-3'>{title}</h4>
      <Row>
        <Col md='6'>
          <Info model={left.model} item={left.item} title={left.title}>
            {left.children}
          </Info>
        </Col>
        {
          right ? (
            <Col md='6'>
              <Info model={right.model} item={right.item} title={right.title}>
                {right.children}
              </Info>
            </Col>
          ) : <></>
        }
      </Row>
      {children}
    </>
  );
};

export default Section;
