const model = {
    header: {
        productName: {id: 'productName', name: '', config: {}},
        plan: {id: 'properties.Plan', name: '', config: {}},
        total: {id: 'properties.Price.Total', name: 'Total Price', ext: '', config: {}},
        config: {},
    },
    body: {
        items: [
            {
                id: 0,
                title: {id: 'Price Breakdown', name: 'Price Breakdown', ext: ''},
                items: [
                    {id: 'properties.Price.Main Policy', name: 'Gross Premium', ext: '', config: {currency: true}},
                    {id: 'properties.Price.Admin Fee', name: 'Admin Fee', ext: '', config: {currency: true}},
                    {id: 'properties.Price.Tax', name: 'Tax', ext: '', config: {currency: true}},
                ],
                config: {},
            },
            {
                id: 'otherDetails',
                title: {id: 'Other Details', name: 'Other Details', ext: ''},
                items: [
                    {id: 'properties.Building Type', name: 'Building Type', config: {}},
                    {id: 'properties.Construction Type', name: 'Construction Type', config: {}},
                    {id: 'properties.Building Sum Assured', name: 'Building Sum Assured', config: {currency: true}},
                    {id: 'properties.Contents Sum Assured', name: 'Contents Sum Assured', config: {currency: true}},
                ],
                config: {},
            }
        ],
    },
};

export default model;
