import React from 'react';
import { Col, Label } from 'reactstrap';
import {CommonInputTypes} from '../../../../_components/CommonInputTypes';
import moment from 'moment';
import { utils, CONFIG_DATE_MOMENT_FORMAT } from 'utils';
import config from '_config';
import { generateDobErrorMessageByType } from '../../alfalahUtils';
import { defaultValues } from '@products/AlfalahWallet/config';

class BasicInfoForm extends React.Component {

  getDefaultCountry = () => {
    const { disableDefaultCountry } = this.props;
    if (disableDefaultCountry) return undefined;
    return config.products.cashForHospitalization.defaultValues.country;
  };

  handleContactNumberChange = (e, length) => {
    let { value } = e.target;
    value = value && value.length > length
      ? value.slice(0, length) : value;
    if (Number.isInteger(Number(value))) {
      this.props.handleInputChange({ target: { id: e.target.id, name: e.target.name, value } });
    }
  };

  handleDobInputDateChange = (date) => {
    this.props.handleDobInputDateChange(date);
  }

  getAlert() {
    if (this.validator.allValid()) {
      // this.props.curentThis.setState({disabled:false});
      return true;
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      // this.props.curentThis.setState({disabled:true});
      return false;
    }
  }

  handleMainCNICPassportChange = (e) => {
    // handleKTPInput = (e) => {
    const { curentThis, validateForm } = this.props;

    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;

    let gender = curentThis.state.properties.Gender;
    if (key === 'CNIC Number') {
      value = value && value.length > utils.lengths.CNIC ? value.slice(0, utils.lengths.CNIC) : value;
      value = value === '' || utils.lib.isOnlyNumber(value) ? value : curentThis.state.properties[key];
      /** autofill gender based on cnic number */
      gender = value && value.length === utils.lengths.CNIC ? utils.lib.getGenderFromN(value[utils.lengths.CNIC-1]) : gender;
    } else {
      value = value === '' || utils.lib.isOnlyNumberOrAlphabet(value) ? value : curentThis.state.properties[key];
    }

    curentThis.forceUpdate(() => {
      curentThis.setState({
        properties:
          {
            ...curentThis.state.properties,
            [key]: value,
            Gender: gender,
          },
      }, () => validateForm && validateForm());
    });
    // curentThis.handleInputChange(e);
  };

  handleOtherInsuredCNICPassport = async (id, e) => {
    // handleOtherInsuredCNICPassport = (id, isBeneficiary, e) => {
    const {curentThis, validateForm} = this.props;
    const {OtherInsured} = this.props.curentThis.state;
    const objIndex =OtherInsured.findIndex((obj) => obj.id === id);
    const obj = OtherInsured[objIndex];

    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
    let gender = obj.Gender;

    if (key === 'CNIC Number') {
      value = value && value.length > utils.lengths.CNIC
        ? value.slice(0, utils.lengths.CNIC) : value;
      value = value === '' || utils.lib.isOnlyNumber(value) ? value : obj[key];
      /** autofill gender based on cnic number of other insured */
      gender = value && value.length === utils.lengths.CNIC
        ? utils.lib.getGenderFromN(value[utils.lengths.CNIC - 1]) : gender;
    } else {
      value = value === '' || utils.lib.isOnlyNumberOrAlphabet(value) ? value : obj[key];
    }
    await curentThis.updateOtherInsured(id, { target: { id: key, value } }, gender);
    if (validateForm) return validateForm();
  };

  handleBeneficiaryInsuredIdNumber = async (step, id, e) => {
    const { curentThis, validateForm } = this.props;
    const { BeneficiaryObj } = this.props.curentThis.state;
    const objIndex = BeneficiaryObj.findIndex((obj) => obj.id === id);
    const obj = BeneficiaryObj[objIndex];
    const idType = obj['ID Type'];
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;

    if (idType === 'CNIC') {
      value = value && value.length > utils.lengths.CNIC
        ? value.slice(0, utils.lengths.CNIC) : value;
      value = value === '' || utils.lib.isOnlyNumber(value) ? value : obj[key];
    } else {
      value = value === '' || utils.lib.isOnlyNumberOrAlphabet(value) ? value : obj[key];
    }
    await curentThis.updateBeneficiaryDetails(id, { target: { id: key, value } });
    if (validateForm) validateForm();
  };

  handleNumberChange = (id, e) => {
    const { curentThis, validateForm } = this.props;
    const { BeneficiaryObj } = this.props.curentThis.state;
    const objIndex = BeneficiaryObj.findIndex((obj) => obj.id === id);
    const obj = BeneficiaryObj[objIndex];
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
    value = value === '' || utils.lib.isOnlyNumber(value) ? value : obj[key];

    if (key === 'Postcode') {
      value = value && value.length > defaultValues.postcodeLength
        ? value.slice(0, defaultValues.postcodeLength) : value;
    }

    curentThis.updateBeneficiaryDetails(id, { target: { id: key, value } });
    if (validateForm) validateForm();
  }

  handleIdTypeChange = (e) => {
    const { handleInputChange } = this.props;
    handleInputChange(e);
  };

  getAllEnteredInsuredIdNumber = (key) => {
    const { properties } = this.props.curentThis.state;
    let { OtherInsured } = this.props.curentThis.state;
    if (properties['Other Insured'] && properties['Other Insured'].length > 0) {

      if (OtherInsured && OtherInsured.length === 0) {
        OtherInsured = [...properties['Other Insured']];
      }
    }
    let collections = [];
    const mainIdNumber = properties[key];
    if (mainIdNumber) collections.push(mainIdNumber);

    if (OtherInsured && OtherInsured.length) {
      OtherInsured.forEach((other) => {
        const number = other[key];
        if (number) collections.push(number);
      });
    }
    return collections;
  };

  getRelevantEnteredInsuredIdNumber = (key, index) => {
    const { properties } = this.props.curentThis.state;
    let { OtherInsured } = this.props.curentThis.state;
    if (properties['Other Insured'] && properties['Other Insured'].length > 0) {

      if (OtherInsured && OtherInsured.length === 0) {
        OtherInsured = [...properties['Other Insured']];
      }
    }
    if (index === 0) { // main
      const idNumber = properties[key];
      return [idNumber];
    }
    if (OtherInsured && OtherInsured.length) {
      const idNumber = OtherInsured[index - 1][key];
      return [idNumber];
    }
    return [];
  }

  render() {
    const {
      updatedVal, collections, beneficiary, disableAll,
      curentStep, curentID, startDate,
      openToDateOfBirth, maxDateOfBirth,
      minDateOfBirth, index, insurerType,
      minAge, maxAge, noValidation, handleInputChange,
    } = this.props;
    let disable = disableAll ? disableAll : false;
    const isMain = !beneficiary && index === 'base';
    return (
      <>
        <Col sm='6'>
          <CommonInputTypes
            idName={'Full Name'}
            idNameIndex={`Full Name-${index}`}
            type={'input'}
            value={updatedVal['Full Name'] || ''}
            onChange={this.props.handleInputChange}
            title={'Full Name'}
            id={1}
            group={false}
            placeholder={'Enter full name'}
            validator={disable || noValidation ? '' : this.props.validator}
            validationRules='custom_required|alfalah_full_name_valid_characters'
            disabled={disable}
            autoFocus= {this.props.autoFocus}
          />
        </Col>
        {
          !beneficiary && (
          <>
          <Col sm='6'>
            <CommonInputTypes
              idName={'Nationality'}
              idNameIndex={`Nationality-${index}`}
              type='select'
              value={updatedVal['Nationality']}
              onChange={this.props.handleInputChange}
              title={'Nationality'}
              id={1}
              group={false}
              option={collections.nationalities}
              validator={disable || noValidation ? '' : this.props.validator}
              validationRules='custom_required'
              disabled={disable}
            />
          </Col>
            <Col sm='6'>
            <CommonInputTypes
              idName='CNIC Number'
              idNameIndex={`CNIC Number-${index}`}
              type='input'
              value={updatedVal['CNIC Number'] || ''}
              onChange={(e) => isMain 
                ? this.handleMainCNICPassportChange(e) : this.handleOtherInsuredCNICPassport(curentID,e)
            }
              title='CNIC Number'
              id={1}
              group={false}
              placeholder='Enter CNIC Number'
              validationRules={`custom_required|insured_no_same_Id|cnic_length`}
              validator={!noValidation && this.props.validator}
              validationParams={{ idCollections: this.getAllEnteredInsuredIdNumber('CNIC Number') }}
              curentID={curentID}
            />
          </Col>
            <Col sm='6'>
              <Label for='cnicDate'>CNIC Issuance Date</Label>
              <CommonInputTypes
                idName='CNIC Issuance Date'
                type='date'
                validator={!noValidation && this.props.validator}
                value={updatedVal['CNIC Issuance Date']}
                id={1}
                group={false}
                maxDate={new Date()}
                placeholder='Select CNIC Issuance Date'
                className='datePickerBorderLeft'
                onChange={(date) => {
                  this.props.handleIssuanceDate(moment(date).format(CONFIG_DATE_MOMENT_FORMAT));
                }}
                openToDate={new Date()}
                validationRules='custom_required|issuance_date_boudary'
              />
            </Col>
            <Col sm='6'>
            <CommonInputTypes
              idName='Passport Number'
              idNameIndex={`Passport Number-${index}`}
              type='input'
              value={updatedVal['Passport Number'] || ''}
              onChange={(e) => {isMain 
                ? this.handleMainCNICPassportChange(e) : this.handleOtherInsuredCNICPassport(curentID, e)}
              }
              title='Passport Number'
              id={1}
              group={false}
              placeholder='Enter Passport Number'
              curentID={curentID}
              validationRules={`custom_required|alpha_num_passport|insured_no_same_Id`}
              validator={!noValidation && this.props.validator}
              validationParams={{ idCollections: this.getAllEnteredInsuredIdNumber('Passport Number') }}
            />
          </Col>
          <Col sm='6'>
            <Label for='dob'>Date of Birth</Label>
              <CommonInputTypes
                idName={'DOB'}
                idNameIndex={generateDobErrorMessageByType(insurerType)}
                type='date'
                validator={!noValidation && this.props.validator}
                disabled={disable}
                value={updatedVal['DOB']}
                id={1}
                group={false}
                placeholder='Select Date of Birth'
                className='datePickerBorderLeft'
                onChange={(date) => {
                  this.props.handleDobInputDateChange(moment(date).format(CONFIG_DATE_MOMENT_FORMAT));
                }}
                maxDate={maxDateOfBirth}
                minDate={minDateOfBirth}
                openToDate={startDate || openToDateOfBirth}
                
                validationRules='custom_required|validate_age_boundary'
                validationParams={{ maxDateOfBirth, minDateOfBirth, minAge, maxAge }}
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName='Gender'
                idNameIndex={`Gender-${index}`}
                type='select'
                value={updatedVal['Gender']}
                onChange={this.props.handleInputChange}
                title='Gender'
                id={1}
                group={false}
                option={collections.genders}
                validator={disable || noValidation ? '' : this.props.validator}
                validationRules='custom_required'
              />
            </Col>
          </>
          )
        }
        {beneficiary && (
          <>
            <Col sm='6'>
              <CommonInputTypes
                idName={'Relationship'}
                idNameIndex={`Relationship-${index}`}
                type='select'
                value={updatedVal['Relationship']}
                onChange={this.props.handleInputChange}
                title={'Relationship'}
                id={1}
                group={false}
                option={collections.relations}
                validator={!noValidation && this.props.validator}
                validationRules='custom_required'
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName={'ID Type'}
                idNameIndex={`ID Type-${index}`}
                type='select'
                value={updatedVal['ID Type'] || ''}
                onChange={handleInputChange}
                title={'ID Type'}
                id={1}
                group={false}
                option={collections.idTypes}
                validator={disable || noValidation ? '' : this.props.validator}
                validationRules='custom_required'
                disabled={disable}
                curentID={curentID}
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName='ID Number'
                idNameIndex={`ID Number-${index}`}
                type='input'
                value={updatedVal['ID Number'] || ''}
                onChange={handleInputChange}
                title='ID Number'
                id={1}
                group={false}
                placeholder='Enter ID Number'
                validator={disable || noValidation ? '' : this.props.validator}
                curentID={curentID}
                // validationRules={`custom_required|beneficiary_no_same_Id|${updatedVal['ID Type'] === 'CNIC' ? 'cnic_length' : ''}`}
                validationRules={`custom_required|beneficiary_no_same_Id|insured_no_same_Id|${updatedVal['ID Type'] === 'CNIC' ? 'cnic_length' : ''}`}
                // validationParams={{ idCollections: this.getRelevantEnteredInsuredIdNumber(updatedVal['ID Type'] === 'CNIC'
                validationParams={{ idCollections: this.getAllEnteredInsuredIdNumber(updatedVal['ID Type'] === 'CNIC'
                  ? 'CNIC Number' : 'Passport Number', index) }}
              />
            </Col>
            <Col sm='12'>
              <CommonInputTypes
                idName={'Address'}
                type={'input'}
                value={updatedVal['Address'] || ''}
                onChange={this.props.handleInputChange}
                title={'Address'}
                id={1}
                group={false}
                placeholder={'Enter address'}
                validator={!noValidation && this.props.validator}
                validationRules='custom_required'
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName={'Country'}
                type='select'
                value={updatedVal['Country'] || this.getDefaultCountry()}
                onChange={this.props.handleInputChange}
                title={'Country'}
                id={1}
                group={false}
                option={collections.countries}
                validator={!noValidation && this.props.validator}
                // disabled //={collections.countries.length <= 1}
                validationRules='custom_required'
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName={'State'}
                type='select'
                value={updatedVal['State']}
                onChange={this.props.handleInputChange}
                title={'State/Province/Region'}
                id={1}
                group={false}
                option={collections.states ? collections.states : []}
                // validator={!noValidation && this.props.validator}
                validationRules='custom_required'
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName={'City'}
                type='input'
                value={updatedVal['City'] || ''}
                onChange={this.props.handleInputChange}
                title={'City/Town'}
                id={1}
                group={false}
                validator={!noValidation && this.props.validator}
                placeholder={'Enter City/Town'}
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName={'Postcode'}
                type='input'
                value={updatedVal['Postcode'] || ''}
                onChange={this.props.handleInputChange}
                title={'Postcode'}
                id={1}
                group={false}
                // validator={updatedVal['Postcode'] && !noValidation ? this.props.validator : ''}
                validationRules={updatedVal['Postcode'] ? 
                  `min:${defaultValues.postcodeLength}|max:${defaultValues.postcodeLength}` : ''}
                // validationRules='numbers_only'
                placeholder={'Enter postcode'}
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName='Email'
                idNameIndex={`Email-${index}`}
                type='email'
                value={updatedVal['Email']}
                onChange={this.props.handleInputChange}
                title='Email Address'
                id={1}
                group={false}
                validator={disable || noValidation ? '' : this.props.validator}
                disabled={disable}
                placeholder='Enter email address'
                validationRules='email|custom_required'
              />
            </Col>
            <Col sm='6'>
              <CommonInputTypes
                idName='Contact Number'
                idNameIndex={`Contact Number-${index}`}
                type='input'
                value={updatedVal['Contact Number']}
                onChange={this.props.handleInputChange}
                title='Contact Number'
                id={1}
                group={false}
                validator={disable || noValidation ? '' : this.props.validator}
                validationRules={`integer|custom_required|min:${defaultValues.contactNumberLength}|max:${defaultValues.contactNumberLength}`}
                disabled={disable}
                placeholder='Enter contact number'
              />
            </Col>
          </>
        )}
      </>
    );
  }
}

export default BasicInfoForm;
