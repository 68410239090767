import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import "./index.scss";
import App from "./App/App";
import theme from "./toolbox/theme";
import ThemeProvider from "react-toolbox/lib/ThemeProvider";
import { store } from "./_helpers";
import * as serviceWorker from "./serviceWorker";

render(
	<Provider store={store}>
		<HashRouter>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</HashRouter>
	</Provider>, document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
