import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { CommonInputTypes } from '_components';
import Moment from 'moment';

const minAgeDays = 29;
const maxAgeYrs = 80;

const ValidationMessages = ({validator, disabled, value='', inputName, validationRules}) => {
  return (
    <div>
      {
        validator && !disabled
        && validator.message(inputName, value,
          validationRules ? `${validationRules}|customRequired` : 'customRequired')
      }
    </div>
  );
};

const PolicyEndorsement = (props) => {
  const {
    properties,
    collections,
    handleChange,
    handleNumberFormatFields,
    validator,
    beneficiaryValidator,
  } = props;
  const { 'spouse':spouse, 'main applicant': mainApplication } = properties;



  const getMinDOB = () => {
    return Moment(new Date())
    .subtract(`${0}`, 'years')
    .subtract(`${0}`, 'months')
    .subtract(`${minAgeDays}`, 'days')._d;
  };

  const getMaxDOB = () => {
    return Moment(new Date())
    .subtract(`${maxAgeYrs}`, 'years')
    .subtract(`${0}`, 'months')
    .subtract(`${365}`, 'days')._d;
  };
  return (
    <Row>
      <Col sm={12}>
        <h4 className='beneficiary-header my-3 p-3'>Main Applicant</h4>
        <Row className='wordBreak'>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Customer Details</div>
            <Row>
              <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Full Name'
                    name='Full Name'
                    placeholder='Enter full name'
                    value={mainApplication['Full Name']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='full_name_valid_characters'
                    value={mainApplication['Full Name']}
                    inputName='Full Name'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Type</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='ID Type'
                    name='ID Type'
                    value={mainApplication['ID Type']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                >
                  {
                      collections && collections.idTypes && collections.idTypes.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='ID Number'
                    name='ID Number'
                    placeholder='Enter ID number'
                    value={mainApplication['ID Number']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={mainApplication['ID Number']}
                    inputName='ID Number'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Date of Birth</Col>
              <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                <CommonInputTypes
                  id='DOB'
                  dateStyle={{ marginBottom: 0 }}
                  validator={validator}
                  validationRules='date_of_birth_boundary'
                  validationParams={{ minAgeDays, maxAgeYrs }}
                  group={false}
                  type='date'
                  value={mainApplication.DOB}
                  onChange={(date) => handleChange({ target: { id: 'DOB', value: date } })}
                  maxDate={getMinDOB()}
                  minDate={getMaxDOB()}
                  openToDate={mainApplication.DOB}
                  className='datePickerBorderLeft'
                />
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Gender</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='Gender'
                    name='Gender'
                    value={mainApplication.Gender}
                    onChange={(e) => handleChange(e)}
                >
                  {
                      collections && collections.genders && collections.genders.map(gender => {
                      return (<option key={gender.id}>{gender.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Address</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Address'
                    name='Address'
                    placeholder='Enter address'
                    value={mainApplication['Address']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={mainApplication.Address}
                    inputName='Address'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>City / Town</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='City'
                    name='City'
                    placeholder='Enter city'
                    value={mainApplication.City}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={mainApplication.Address}
                    inputName='City'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>State / Province / Region</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='State'
                    name='State'
                    placeholder='Enter state'
                    value={mainApplication.State}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={mainApplication.State}
                    inputName='State'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Postcode</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Postcode'
                    name='Postcode'
                    placeholder='Enter postcode'
                    value={mainApplication.Postcode}
                    onChange={(e) => handleNumberFormatFields(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='numbersOnly'
                    value={mainApplication.Postcode}
                    inputName='Postcode'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Country</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                  id='Country'
                  name='Country'
                  value={mainApplication.Country}
                  onChange={(e) => handleChange(e)}
                  autoFocus
                >
                  {
                      collections && collections.countries&&  collections.countries.map(country => {
                      return (<option key={country.id}>{country.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Email Address</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Email Address'
                    name='Email Address'
                    placeholder='Enter email address'
                    value={mainApplication['Email Address']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='email'
                    value={mainApplication['Email Address']}
                    inputName='Email Address'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Phone Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Mobile Number'
                    name='Mobile Number'
                    placeholder='Enter phone number'
                    value={mainApplication['Mobile Number']}
                    onChange={(e) => handleNumberFormatFields(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='numbersOnly'
                    value={mainApplication['Mobile Number']}
                    inputName='Mobile Number'
                  />
                </>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            {spouse && (
                <>
                  <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
                  <Col className='pt-2 summary-label-2' sm={6}>
                    <>
                      <Input
                          id='Full Name'
                          name='Full Name'
                          placeholder='Enter full name'
                          value={spouse['Full Name']}
                          onChange={(e) => handleChange(e,true)}
                          autoFocus
                      />
                      <ValidationMessages
                          validator={validator}
                          validationRules='full_name_valid_characters'
                          value={spouse['Full Name']}
                          inputName='Full Name'
                      />
                    </>
                  </Col>
                  <Col className='pt-2 summary-label' sm={6}>ID Type</Col>
                  <Col className='pt-2 summary-label-2' sm={6}>
                    <Input
                        type='select'
                        id='ID Type'
                        name='ID Type'
                        value={spouse['ID Type']}
                        onChange={(e) => handleChange(e,true)}

                        autoFocus
                    >
                      {
                          collections && collections.idTypes && collections.idTypes.map(idType => {
                            return (<option key={idType.id}>{idType.name}</option>);
                          })
                      }
                    </Input>
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>ID Number</Col>
                  <Col className='pt-2 summary-label-2' sm={6}>
                    <>
                      <Input
                          id='ID Number'
                          name='ID Number'
                          placeholder='Enter ID number'
                          value={spouse['ID Number']}
                          onChange={(e) => handleChange(e,true)}
                          autoFocus
                      />
                      <ValidationMessages
                          validator={validator}
                          value={mainApplication['ID Number']}
                          inputName='ID Number'
                      />
                    </>
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>Date of Birth</Col>
                  <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                    <CommonInputTypes
                        id='DOB'
                        dateStyle={{ marginBottom: 0 }}
                        validator={validator}
                        validationRules='date_of_birth_boundary'
                        validationParams={{ minAgeDays, maxAgeYrs }}
                        group={false}
                        type='date'
                        value={spouse.DOB}
                        onChange={(date) => handleChange({ target: { id: 'DOB', value: date } },true)}
                        maxDate={getMinDOB()}
                        minDate={getMaxDOB()}
                        openToDate={spouse.DOB}
                        className='datePickerBorderLeft'
                    />
                  </Col>

                  <Col className='pt-2 summary-label' sm={6}>Gender</Col>
                  <Col className='pt-2 summary-label-2' sm={6}>
                    <Input
                        type='select'
                        id='Gender'
                        name='Gender'
                        value={spouse.Gender}
                        onChange={(e) => handleChange(e,true)}
                    >
                      {
                          collections && collections.genders && collections.genders.map(gender => {
                            return (<option key={gender.id}>{gender.name}</option>);
                          })
                      }
                    </Input>
                  </Col>
                </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PolicyEndorsement;
