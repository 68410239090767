import React from 'react';
import {
  Row, Col, Pagination, PaginationItem, PaginationLink,
} from 'reactstrap';
import { stringLib } from '@xc-core/lib';
import Select from '../select';

interface IPaginationProps {
  pagination: IPageInfo,
  pageHandler: Function,
  pageSizeHandler: Function,
}
const CustomPagination = (props: IPaginationProps) => {
  const {
    pagination,
    pageHandler,
    pageSizeHandler,
  } = props;

  const pageCollections: IPair[] = [
    { id: '10', value: '10' }, { id: '20', value: '20' },
    { id: '30', value: '30' }, { id: '40', value: '40' },
    { id: '50', value: '50' },
  ];

  const calculateNumberPagination = () => {
    const numOfPagination = 4;
    const startIndex = Math.floor(pagination.pageNumber / numOfPagination) * numOfPagination;
    let endIndex = startIndex + numOfPagination;
    if (endIndex > pagination.totalPages) endIndex = pagination.totalPages;

    const numbers = [];
    for (let idx = startIndex; idx < endIndex; idx += 1) {
      numbers.push(idx);
    }
    return {
      startIndex,
      numbers,
      endIndex,
    };
  };

  const onPageChange = (num: number) => {
    const pair:IPair = { id: 'pageNumber', value: stringLib.toString(num) };
    pageHandler(pair);
  };

  const onPageSizeChange = (pair: IPair) => {
    pageSizeHandler(pair);
  };

  const paginationData = calculateNumberPagination();

  return (
    (pagination.totalElements > 10 || pagination.totalPages > 1)
      ? (
        <Row>
          <Col sm='8'>
            <Pagination aria-label='Page navigation example'>
              <PaginationItem className={pagination.first ? 'disabled' : ''}>
                <PaginationLink onClick={() => onPageChange(0)}>
                  <span aria-hidden='true'>&lsaquo;</span>
                  <span>First</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem className={pagination.first ? 'disabled' : ''}>
                <PaginationLink onClick={() => onPageChange(pagination.pageNumber - 1)}>
                  <span aria-hidden='true'>&laquo;</span>
                  <span>Previous </span>
                </PaginationLink>
              </PaginationItem>
              {
                paginationData.numbers.map((num) => (
                  <PaginationItem key={num} active={num === pagination.pageNumber}>
                    <PaginationLink onClick={() => onPageChange(num)}>
                      {num + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))
              }
              <PaginationItem className={pagination.last ? 'disabled' : ''}>
                <PaginationLink onClick={() => onPageChange(pagination.pageNumber + 1)}>
                  <span>Next </span>
                  <span aria-hidden='true'>&raquo;</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem className={pagination.last ? 'disabled' : ''}>
                <PaginationLink onClick={() => onPageChange(pagination.totalPages - 1)}>
                  <span>Last </span>
                  <span aria-hidden='true'>&rsaquo;</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
          <Col sm='4' className='text-right customRowshow'>
            <label htmlFor='per-paginate' className='customNrow'>Show</label>
            <Select
              className='per-page'
              id='per-paginate'
              name='pageSize'
              value={pagination.pageSize}
              handler={(pair) => onPageSizeChange(pair)}
              collections={pageCollections}
            />
            <span className='customNrow'>rows per page</span>
          </Col>
        </Row>
      ) : <></>
  );
};

export default CustomPagination;
