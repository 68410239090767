import env from 'env';
import BaseListingService from './baseListing';

class UserGroupService extends BaseListingService {
  protected env = env.tsd;

  protected api = 'identity/groups';

  protected withApi = 'with-associations';
}

export default UserGroupService;
