import field from "../../../@xc-core/lib/field";
import config from "../../../@xc-core/config";
import {dateLib} from "../../../@xc-core/lib";
import moment from "moment";
import {CONFIG_DATE_MOMENT_FORMAT,getMaximumReturnTripEndDate,utils} from "../../../utils";
import Moment from "moment";

const getMaxDate=(item)=>{
    const properties = {...item.properties};
    return getMaximumReturnTripEndDate(
        properties['Start Date']
            ? Moment(properties['Start Date'], CONFIG_DATE_MOMENT_FORMAT)
            : Moment(new Date(), CONFIG_DATE_MOMENT_FORMAT),
        utils.constants.defaultAlfalahTravelMaximumNoOfReturnDays,
    );
}

const disableDate=(item)=>{
    return item.properties['Trip Type'] === 'Annual'
}

const disableDestination=(item)=>{
    return item.properties['Trip Type'] === 'Single Trip'
}

const model = {
    header: {
        productName: {id: 'productName', name: '', config: {}},
        plan: {id: 'properties.Plan', name: '', config: {}},
        items: [
            {
                current: {id: 'properties.Price.Total', name: '', config: {currency: true}},
                prev: {
                    id: 'properties.Previous Price.Total',
                    name: '',
                    config: {currency: true, className: 'cross-line gray bold'}
                },
                config: {},
            },
        ],
    },
    body: {
        items: [
            {
                id: 'Price Breakdown',
                name: 'Price Breakdown',
                items: [
                    {
                        current: {id: 'properties.Price.Main Policy', name: 'Gross Premium', config: {currency: true}},
                        prev: {
                            id: 'properties.Previous Price.Main Policy',
                            name: 'Gross Premium',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {
                            id: 'properties.Price.Admin Surcharge',
                            name: 'Admin Surcharge',
                            config: {currency: true}
                        },
                        prev: {
                            id: 'properties.Previous Price.Admin Surcharge',
                            name: 'Admin Surcharge',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {
                            id: 'properties.Price.Federal Excise Duty',
                            name: 'Federal Excise Duty',
                            config: {currency: true}
                        },
                        prev: {
                            id: 'properties.Previous Price.Federal Excise Duty',
                            name: 'Federal Excise Duty',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {
                            id: 'properties.Price.Fixed Insurance Fee',
                            name: 'Fixed Insurance Fee',
                            config: {currency: true}
                        },
                        prev: {
                            id: 'properties.Previous Price.Fixed Insurance Fee',
                            name: 'Fixed Insurance Fee',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: {currency: true}},
                        prev: {
                            id: 'properties.Previous Price.Stamp Duty',
                            name: 'Stamp Duty',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {
                            id: 'properties.Price.Withholding Tax',
                            name: 'Withholding Tax',
                            config: {currency: true}
                        },
                        prev: {
                            id: 'properties.Previous Price.Withholding Tax',
                            name: 'Withholding Tax',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    // {
                    //   current: { id: 'properties.Price.COVID-19 Add On', name: 'Covid-19 Add-On', config: { currency: true } },
                    //   prev: { id: 'properties.Previous Price.COVID-19 Add On', name: 'Covid-19 Add-On', config: { currency: true, className: 'cross-line light-gray' } },
                    //   config: { currency: true },
                    // },

                ],
                config: {},
            },
            {
                id: 'Other Details',
                name: 'Other Details',
                items: [
                    {
                        current: {
                            id: 'properties.Plan',
                            name: 'Plan Type',
                            type: 'Select',
                            collectionId: 'plans',
                            disabled: field.defaultDisabled,
                            handler: field.defaultHandler,
                            config: { isEditable: true }
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.Destination',
                            name: 'Destination',
                            type: 'Select',
                            collectionId: 'regions',
                            disabled: disableDestination,
                            handler: field.defaultHandler,
                            config: { isEditable: true }
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.Trip Type',
                            name: 'Trip Type',
                            type: 'Select',
                            collectionId: 'tripTypes',
                            disabled: field.defaultDisabled,
                            handler: field.defaultHandler,
                            config: {isEditable: true}
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.destinationCountry',
                            name: 'Country',
                            type: 'Select',
                            collectionId: 'travelDestinationCountry',
                            disabled: field.defaultDisabled,
                            handler: field.defaultHandler,
                            config: {isEditable: true}
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.Start Date',
                            name: 'Start Date',
                            type: 'DatePicker',
                            placeholder: 'Set Start date',
                            start: 'properties.Start Date',
                            end: 'properties.End Date',
                            getMin:()=> moment().format(CONFIG_DATE_MOMENT_FORMAT),
                            disabled: field.defaultDisabled,
                            handler: field.defaultHandler,
                            config: {isEditable: true}
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {
                            id: 'properties.End Date',
                            name: 'End Date',
                            type: 'DatePicker',
                            placeholder: 'Set End date',
                            start: 'properties.Start Date',
                            end: 'properties.End Date',
                            getMax:getMaxDate,
                            getMin: ()=>dateLib.getCurrentDate(config.dateMomentFormat),
                            disabled: disableDate,
                            handler: field.defaultHandler,
                            config: {isEditable: true}
                        },
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {id: 'properties.Insured Type', name: 'Insured Type', config: {}},
                        prev: {config: {}},
                        config: {}
                    },
                    {current: {id: 'properties.Adults', name: 'Adults', config: {}}, prev: {config: {}}, config: {}},
                    {
                        current: {id: 'properties.Children', name: 'Children', config: {}},
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {id: 'properties.Senior Citizen (66-75)', name: 'Senior Citizen (66-75)', config: {}},
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {id: 'properties.Senior Citizen (76-80)', name: 'Senior Citizen (76-80)', config: {}},
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {id: 'properties.Senior Citizen (81-85)', name: 'Senior Citizen (81-85)', config: {}},
                        prev: {config: {}},
                        config: {}
                    },
                ],
                config: {},
            },
            {
                id: 'COVID-19 Add On Details',
                name: 'COVID-19 Add On Details',
                items: [
                    {
                        current: {id: 'properties.Covid-19 Add On', name: 'Type', config: {}},
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {id: 'properties.Add On Start Date', name: 'Start Date', config: {}},
                        prev: {config: {}},
                        config: {}
                    },
                    {
                        current: {id: 'properties.Add On End Date', name: 'End Date', config: {}},
                        prev: {config: {}},
                        config: {}
                    },
                ],
                config: {},
            },
        ],
    }
};

export default model;
