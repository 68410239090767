import actionTypes from '@redux/actionTypes';
import BaseListingReducer from './baseListing';

class ProductListReducer extends BaseListingReducer<IProductListState> {
  public actions: IDictionary<(state: IProductListState,
    action: IAction) => IProductListState>;

  public initialState: IProductListState;

  protected actionTypes: IProductActionTypes;

  constructor() {
    super();

    this.actionTypes = actionTypes.product;

    this.initialState = {
      isLoading: true,
      error: null,
      list: null,
      accessIsForbidden: false,
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IProductListState,
    action: IAction) => IProductListState> => ({
    [this.actionTypes.LIST_LOADING]: this.setLoading,
    [this.actionTypes.LIST_FAILED]: this.setError,
    [this.actionTypes.LIST_SET_FORBIDDEN]: this.setForbidden,
    [this.actionTypes.LIST_GET]: this.getList,
  })

  protected getList = (state:IProductListState, action: IAction):IProductListState => ({
    ...state,
    isLoading: false,
    error: null,
    list: action.payload,
  })
}
export default ProductListReducer;
