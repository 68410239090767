import dateLib from './date';

const downloadFile = (fileName: string, file: Blob, newWindow = false) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, fileName);
  } else {
    const objectUrl = (window.URL || (window as any).webkitURL).createObjectURL(file);
    if (newWindow) {
      window.open(objectUrl, '_blank');
    } else {
      const a = document.createElement('a');
      a.href = objectUrl;
      a.setAttribute('download', `${fileName}`);
      document.body.appendChild(a);
      a.click();
      if (a && a.parentNode) a.parentNode.removeChild(a);
    }
  }
};

const isEdge = () => navigator.appVersion.indexOf('Edge') > 1;

const generateCsvFileName = (isQuote: boolean, pii: boolean, productName = '') => {
  let parsedProductName = productName;
  if (productName.indexOf('-') > 0) {
    const splitProductName = productName.split('-');
    parsedProductName = `${String(splitProductName[0]).charAt(0).toUpperCase()}${String(splitProductName[0]).slice(1)}${String(splitProductName[1]).charAt(0).toUpperCase()}${String(splitProductName[1]).slice(1)}`;
  }
  const productType = isQuote ? 'Quotation' : 'Certificate';
  const piiType = pii ? 'AllFields' : 'NonPII';
  const date = dateLib.getCurrentDate('YYYYMMDD');
  return `${parsedProductName}_${productType}_${piiType}_${date}.csv`;
};

const media = { downloadFile, isEdge, generateCsvFileName };
export default media;
