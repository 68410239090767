import React from 'react';
import { Form, FormGroup, Button } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import {ModalUi} from "../../../_components";


const EndorsementCheckPriceModal = (props:any) => {
    const {
        isOpen,
        toggleModal,
        handleSubmit,
        disableSubmit,
        endorsementCheckData = {},
        previousPolicy = {},
        sidebar
    } = props;

    const newPolicy = endorsementCheckData.policy;
    const policy = { ...previousPolicy, ...newPolicy };
    let previousTotal;
    let newTotal;
    if (previousPolicy && newPolicy) {
        previousTotal = Number(previousPolicy.properties.Price.Total);
        newTotal = Number(newPolicy.properties.Price.Total);
    }

    return (
        <ModalUi isOpen={isOpen} title='Price Update' toggle={toggleModal}>
            {
                newTotal !== previousTotal
                    ? (
                        <>
                            <p>The endorsement that you have requested will cause the total premium to change.</p>
                            <p>Please verify that the new price is acceptable before proceeding.</p>
                        </>
                    )
                    : <p>The endorsement that you have requested will not change the total premium.</p>
            }

            <Form className='noshadow'>
                <SidebarCard.Policy
                    showTitle={false}
                    model={sidebar}
                    status={policy.status}
                    item={policy}
                    isModal={true}
                    priceChanged={newTotal !== previousTotal}
                />
                <FormGroup className='text-right mb-0'>
                    <Button className='customLeastbtn mr-3' color='muted' onClick={toggleModal} disabled={disableSubmit}>Back</Button>
                    <Button className='customPrimarybtn' onClick={handleSubmit} color='primary' disabled={disableSubmit}>Proceed</Button>
                </FormGroup>
            </Form>
        </ModalUi>
    );
};

export default EndorsementCheckPriceModal;
