import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import { stringLib, eventLib } from '@xc-core/lib';

const colourStyles = {
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: 'none',
    border: '1px solid #2B61B4',
    borderRadius: '4px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#2B61B4',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#2B61B4',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'none',
      color: 'red',
    },
  }),
};

const SelectMulti = (props: IMultiSelectFormControlModel) => {
  // const { options, inputValue,value} = props;
  const {
    // handler, inputHandler,
    id, value, name, className,
    collections, inputValue, validator,
    customMessage, rules, validationName, validationData,
    validationRules, isMulti, handler
  } = props;

  const onChange = (e:IPair[]) => {
    // console.log(e)
    // const pair = eventLib.getPair(e);
    handler({id,value:e})
  };
  const onInputChange = () => {

  };
  const getTheme = (theme :any) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary: '#2B61B4',
    },
  });

  const prepareOptions = () => (collections.map((collection: IPair) => (
    { value: stringLib.toString(collection.id), label: stringLib.toString(collection.value) }
  )));

  return (
    <>
      <Select
        id={id}
        name={name}
        isMulti={isMulti ? isMulti : false}
        value={value} // ??
        // inputValue={inputValue} // ??
        onChange={(e:any)=> onChange(e)} // ??
        onInputChange={onInputChange} // ?
        options={prepareOptions()}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        className={`basic-multi-select ${className}`}
        classNamePrefix='select'
        styles={colourStyles}
        components={makeAnimated()}
        theme={(theme) => getTheme(theme)}
      />
        {validator 
        ? <div>{validator.message(validationName ? validationName : name,
          validationData ? validationData : value,
          validationRules ? validationRules : "") }</div>
        : ''}
      {/* {
        validator
          ? validator.validateFieldAndGetMessage({
            name,
            value,
            rules: rules || [],
            customMessage,
          }) : ''
      } */}
    </>
  );
};

export default SelectMulti;
