import { AuthorizationService } from '@xc-core/services';

export default abstract class BaseAction {
  protected abstract actions: IBaseActionTypes;

  protected abstract service: IService;

  protected abstract createScope: string;

  protected abstract editScope: string;

  protected isResourceForbidden = async (scope: string): Promise<boolean> => {
    const authService = new AuthorizationService();
    const result = await authService.getResourcePermission(`scope=${scope}`);
    return result.status !== 'permitted';
  };

  protected createAction = <T>(type: string, payload?: T) => {
    if (payload !== undefined) return { type, payload };
    return { type };
  }

  public setCreatePermission = () => async (dispatch: any): Promise<void> => {
    try {
      const isForbidden = await this.isResourceForbidden(this.createScope);
      dispatch(this.createAction<boolean>(this.actions.CREATE_SET_FORBIDDEN, isForbidden));
    // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  public setEditPermission = () => async (dispatch: any): Promise<void> => {
    try {
      const isForbidden = await this.isResourceForbidden(this.editScope);
      dispatch(this.createAction<boolean>(this.actions.UPDATE_SET_FORBIDDEN, isForbidden));
    // eslint-disable-next-line no-empty
    } catch (error) {}
  };
}
