import React, {Fragment} from "react";

import {Row, Col} from "reactstrap";
import {connect} from "react-redux";

import {withStyles} from "@material-ui/core/styles";
import moment from "moment";
import TripDetails from "./component/Step2";
import PLanSelection from "./component/Step3";
import CustomerDetails from "./component/Step4";
import BeneficiaryDetails from "./component/Step5";
import Summary from "./component/Step6";
import Payment from "./component/Step7";
import { isDatePassed, utils, CONFIG_DATE_MOMENT_FORMAT, getOneYearInclusiveEndMoment, isAfter } from "utils";

import {getQuoteActions} from "../../shared/GetQuote/actions";
import ThankYouPage from "./containers/ThankYouPage";
import {TitleComponent, FlashMessage, Stepper} from "_components";
import {BeneficiaryBaseObj, getSteps, styles, initialParentState as initialState} from "../../shared/utils/utils";
import getGuoteGraphic from "../../../images/bannerGraphicImages/get-quote.png";

const PRODUCTCODE = 1998034;

class GetQuoteWizard extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = initialState;
    this.baseState = this.state;
  }

  async componentDidMount() {
    const { item, getCollections } = this.props;
    await getCollections(PRODUCTCODE, '?collection=state'); // so temp!
    if (item) {
      this.setState({
        activeStep: getSteps().indexOf(item.state),
        properties: item.properties,
        OtherInsured: item.properties['Other Insured'] ? item.properties['Other Insured'] : [],
        BeneficiaryObj: item.properties['Beneficiaries'],
      });
    } else this.setState({activeStep: 0});
  }

  getStepContent = (step, values, curentThis) => {
    switch (step) {
      case 0:
        return (
          <TripDetails
            onRef={ref => (this.child = ref)}
            curentThis={curentThis}
            getStates={(country) => this.props.getStates(country)}
          />
        );
      case 1:
        return (
          <PLanSelection
            onRef={ref => (this.child = ref)}
            curentThis={curentThis}
            values={values}
          />
        );
      case 2:
        return (
          <CustomerDetails
            onRef={ref => (this.child = ref)}
            curentThis={curentThis}
            getStates={(country) => this.props.getStates(country)}
          />
        );
      case 3:
        return (
          <BeneficiaryDetails
            onRef={ref => (this.child = ref)}
            curentThis={curentThis}
            getStates={(country) => this.props.getStates(country)}
          />
        );
      case 4:
        return (
          <Summary onRef={ref => (this.child = ref)} curentThis={curentThis} />
        );
      case 5:
        return (
          <Payment onRef={ref => (this.child = ref)} curentThis={curentThis} />
        );
      default:
        return <div>undefined step.{this.state.activeStep}</div>;
    }
  };

  handleNext = async () => {
    const {item} = this.props;
    const {OtherInsured, BeneficiaryObj, activeStep} = this.state;
    let quotationId = item ? item.id : null;

    // console.log('this.child', this.child);
    if (this.child.getAlert()) {
      const priceList = item && item.properties ? item.properties.priceList : [];
      const productCode = item && item.productCode ? item.productCode : PRODUCTCODE;
      let otherInsured = {};
      if (OtherInsured.length || (getSteps()[activeStep] === "Insured Details")) {
        otherInsured = {"Other Insured":  OtherInsured };
      }
      let beneficiaries = BeneficiaryObj && BeneficiaryObj.length ? {"Beneficiaries": BeneficiaryObj} : {};
      const data = {
        productCode,
        properties: {
          ...this.state.properties,
          ...otherInsured,
          ...beneficiaries,
          priceList: priceList,
        }
      };
      this.setState({sendingRequest: true});
      quotationId = quotationId || 0;
      const response = await this.props.updateQuotation(data, quotationId, "next");
      this.setState({sendingRequest: false});
      response.list && response.list.productCode && this.setState(state => ({
        activeStep: state.activeStep + 1,
        properties: response.list.properties,
      }));
    }
  };

  handleDateChange = (params) => {
    const { properties, disableArrival } = this.state;
    let { startDate, endDate } = params;

    startDate = startDate || properties["Start Date"];
    endDate = endDate || properties["End Date"];

    let startM = moment(startDate, CONFIG_DATE_MOMENT_FORMAT);
    let endM = moment(endDate, CONFIG_DATE_MOMENT_FORMAT);

    // check if plan is annual
    if (disableArrival) {
      endM = getOneYearInclusiveEndMoment(startDate);
    }

    // check if start is before today
    const todayM = moment(new Date());
    if (isAfter(todayM._d, startM._d)) {
      startM = todayM;
    }
    // check if start is after end
    if (isAfter(startM._d, endM._d)) {
      endM = startM.clone().add(utils.constants.defaultTravelNoOfDays - 1, "days");
    }
    // use format method to remove the time form date to get the appropriate days difference
    const diffDays = moment(endM.format("L")).diff(moment(startM.format("L")), "days") + 1;

    this.forceUpdate(() => {
      this.setState({
        properties: {
          ...properties,
          "Start Date": startM.format(CONFIG_DATE_MOMENT_FORMAT),
          "End Date": endM.format(CONFIG_DATE_MOMENT_FORMAT),
          "No. of Days": diffDays,
        },
      });
    });
  };

  handleChangeStart = (startDate) => this.handleDateChange({ startDate });

  handleChangeEnd = (endDate) => this.handleDateChange({ endDate });

  handleDOB = (dob) => {
    const { properties } = this.state;
    const dobM = moment(dob, CONFIG_DATE_MOMENT_FORMAT);

    this.setState({
      properties: {
        ...properties,
        DOB: dobM.format(CONFIG_DATE_MOMENT_FORMAT),
      },
    });
  };

  createOtherInsuredObj() {
    let copyObj = [];
    let OtherInsuredBaseObj = {
      "Full Name": "",
      Nationality: "",
      "ID Type": "",
      "ID Number": "",
      DOB: "",
      Gender: ""
    };
    // if (this.state.OtherInsured.length < 1) {
    const {properties} = this.state;
    let srCitizen = parseInt(properties["Senior Citizens"]);
    let adult = parseInt(properties["Adults"]);
    let children = parseInt(properties["Children"]);

    /**
     * Check if there is any adult,
     * If none, make other insured from srCitizen removing one who is considered Main insurer
     */
    if (!adult) {
      for (let i = 0; i < srCitizen - 1; i++) {
        copyObj.push({
          ...OtherInsuredBaseObj,
          id: "Senior Citizen " + (i + 2),
          Type: "Senior Citizen"
        });
      }
    } else {
      for (let i = 0; i < adult - 1; i++) {
        copyObj.push({
          ...OtherInsuredBaseObj,
          id: "Adult " + (i + 2),
          Type: "Adult"
        });
      }

      for (let i = 0; i < srCitizen; i++) {
        copyObj.push({
          ...OtherInsuredBaseObj,
          id: "Senior Citizen " + (i + 1),
          Type: "Senior Citizen"
        });
      }
    }

    for (let i = 0; i < children; i++) {
      copyObj.push({
        ...OtherInsuredBaseObj,
        id: "Child " + (i + 1),
        Type: "Child"
      });
    }

    this.setState({
      OtherInsured: copyObj
    });
    // }
  }

  populateBeneficiaryObj() {
    const Beneficiaries = this.state.properties && this.state.properties.Beneficiaries;
    if (Beneficiaries && Beneficiaries.length > 0) {
      this.setState({ BeneficiaryObj: [...Beneficiaries] });
    } else {
    const {properties} = this.state;
    let copyObj = [];

    // if (this.state.BeneficiaryObj.length < 1) {
    let srCitizen = parseInt(properties["Senior Citizens"]);
    let adult = parseInt(properties["Adults"]);
    let children = parseInt(properties["Children"]);
    for (let i = 0; i < adult; i++) {
      copyObj.push({...BeneficiaryBaseObj, id: "Adult " + (i + 1)});
    }

    for (let i = 0; i < srCitizen; i++) {
      copyObj.push({
        ...BeneficiaryBaseObj,
        id: "Senior Citizen " + (i + 1)
      });
    }

    for (let i = 0; i < children; i++) {
      copyObj.push({...BeneficiaryBaseObj, id: "Child " + (i + 1)});
    }

    this.setState({
      BeneficiaryObj: copyObj
    });
    // }
  }
}

  handleInputChange = async (e) => {
    const { id } = e.target;
    var new_properties_dict = {};
    for (var key in this.state.properties) {
      if (this.state.properties.hasOwnProperty(key)) {
        new_properties_dict[key] = this.state.properties[key];
      }
    }
    new_properties_dict[e.target.id] = e.target.value;
    this.setState({
      properties: new_properties_dict,
    }, () => {
      this.resetIfMainInsuredTypeChange(id);
    });

    /**
     * check if the input is country and then, send a request for getting states
     */
    if (e.target.name === "Country") {
      await this.props.getStates(e.target.value);
    }
  };

  resetIfMainInsuredTypeChange = (id) => {
    /** on main insured changes we need to reset properties for next steps, this should be 
     * a semi reset to initial state
     */
    if (['Insured Type', 'Children', 'Adults', 'Senior Citizens'].includes(id)) {
      this.setState((prevState) => ({
        ...prevState,
        OtherInsured: [...initialState.OtherInsured],
        BeneficiaryObj: [...initialState.BeneficiaryObj],
        properties: {
          ...initialState.properties,
          'Trip Type': prevState.properties['Trip Type'],
          'Start Date': prevState.properties['Start Date'],
          'End Date': prevState.properties['End Date'],
          'No. of Days': prevState.properties['No. of Days'],
          'Insured Type': prevState.properties['Insured Type'],
          Destination: prevState.properties.Destination,
          Children: prevState.properties.Children,
          Adults: prevState.properties.Adults,
          'Senior Citizens': prevState.properties['Senior Citizens'],
        },
      }));
    }
  }

  updateOtherInsured = (id, e) => {
    const {OtherInsured} = this.state;
    let updatedObj = {};
    const objIndex = OtherInsured.findIndex(obj => obj.id === id);
    if ((typeof e === "string") && (new Date(e).toString() !== "Invalid Date")) {
      const dob = utils.lib.applyConfigFormat(e);
      updatedObj = {...OtherInsured[objIndex], DOB: dob};
    } else if (e instanceof Date) {
      const dob = utils.lib.applyConfigFormat(e);
      updatedObj = {...OtherInsured[objIndex], DOB: dob};
    } else {
      updatedObj = {...OtherInsured[objIndex], [e.target.id]: e.target.value};
    }
    // make new object of updated object.

    // // make final new array of objects by combining updated object.
    const updatedData = [
      ...OtherInsured.slice(0, objIndex),
      updatedObj,
      ...OtherInsured.slice(objIndex + 1)
    ];
    this.setState({
      OtherInsured: updatedData
    });
  };

  updateBeneficiaryDetails = (id, e) => {
    const {BeneficiaryObj} = this.state;
    let updatedObj = {};

    const objIndex = BeneficiaryObj.findIndex(obj => obj.id === id);
    if (e.target.id === "Same as Main" && e.target.value === "false") {
      updatedObj = {
        ...BeneficiaryObj[objIndex],
        ...BeneficiaryBaseObj,
        id: BeneficiaryObj[objIndex].id
      };
    } else if (e.target.id === "Same as Main") {
      updatedObj = {
        ...BeneficiaryObj[objIndex],
        ...BeneficiaryObj[0],
        [e.target.id]: true,
        id: BeneficiaryObj[objIndex].id
      };
    } else {
      updatedObj = {
        ...BeneficiaryObj[objIndex],
        [e.target.id]: e.target.value
      };
    }

    // make new object of updated object.

    // // make final new array of objects by combining updated object.
    const updatedData = [
      ...BeneficiaryObj.slice(0, objIndex),
      updatedObj,
      ...BeneficiaryObj.slice(objIndex + 1)
    ];
    this.setState({
      BeneficiaryObj: updatedData
    });
  };

  updatedPlanSelected = key => {
    const {sendingRequest} = this.state;
    !sendingRequest && this.setState({
      planSelected: key,
      disabled: false,
      properties: {...this.state.properties, Plan: key}
    }, () => this.handleNext());
  };

  handleBack = async () => {
    const {item: {productCode, id: quotationId}} = this.props;
    const data = {productCode};
    this.setState({sendingRequest: true});
    await this.props.updateQuotation(data, quotationId, "prev");
    this.setState({sendingRequest: false});
    if (getSteps()[this.state.activeStep - 1] === "Select Plan") this.setState({planSelected: ""});
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
    if (this.state.activeStep !== 1) {
      this.setState({
        disabled: false
      });
    }
  };

  handleReset = async () => {
    const {history, resetQuotation} = this.props;
    await resetQuotation();
    history.push('/getQuote');
  };
  isQuotationExpired = (quote) => {
    return quote && isDatePassed(quote.properties["Start Date"]);
  };
  isQuotationEditable = (quote, activeStep) => {
    const steps = getSteps();
    const quoteState = quote && quote.state;
    return quoteState === "Completed" ? activeStep === steps.length : true;
  };
  render() {
    const {classes, item, products, productsFetched, flashMessage} = this.props;
    const {
      activeStep, productSelected,
      planSelected, disabled, properties, sendingRequest,
    } = this.state;
    const values = {productSelected, planSelected};
    const steps = getSteps();
    let shouldSkipOnLastStep; 
    if ((activeStep + 1 === steps.length - 1) && !(properties && (properties["Payment Mode"] || properties["Receipt No"]))) {
      shouldSkipOnLastStep = true;
    }
    let showNext = true;
    if (steps[activeStep] === "Select Plan" || steps[activeStep] === "Product Selection") {
      showNext = false;
    }

    /**
     * display proper message if fetching products returned an empty collection
     * @const productsFetched
     * */

    if (productsFetched && !products.length) return (<FlashMessage flashMessage="There are no products."/>);
    if (flashMessage !== "") return (<FlashMessage flashMessage={flashMessage}/>);
    if (activeStep === null) return (<FlashMessage flashMessage="Please wait..."/>);
    
    // if(this.isQuotationEditable(item, activeStep) === false) return(<FlashMessage flashMessage="This Quotation is completed and cannot be edited."/>);
    if(this.isQuotationExpired(item)) return(<FlashMessage flashMessage="This quotation is outdated and cannot be edited."/>);
    
    return (
      <div className="container-fluid px-0">
        <TitleComponent
          title="Get Quote"
          bannerGraphic={getGuoteGraphic}
        />
        <div className="row d-flex justify-content-center my_50">
          <div className="col-10">
            <div className={classes.root}>
            <Stepper 
                steps = {utils.products.travelInsurance.steps}
                activeStep={activeStep} 
                classes={classes} 
            />
              <Fragment>
                {steps[activeStep] === "Completed" ? (
                  <Fragment>
                    <ThankYouPage certificateId={item ? item.policyRefCode : null}
                                  handleReset={this.handleReset.bind(this)}/>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className={classes.instructions}>
                      {this.getStepContent(activeStep, values, this)}
                    </div>
                    <Row className="text-right justify-content-end">
                      <Col sm="2">
                        {activeStep > 0 && getSteps()[activeStep] !== "Payment Details" && (
                          <button
                            className="btn btn-light col-12 my-2"
                            onClick={this.handleBack}
                            disabled={sendingRequest}
                          >
                            Previous
                          </button>
                        )}
                      </Col>
                      {
                        showNext && <Col sm="2">
                          <button
                            className="btn btn-primary col-12 my-2 rounded-xl"
                            onClick={this.handleNext}
                            disabled={disabled || sendingRequest}
                          >
                            {shouldSkipOnLastStep ? "Skip" : "Next"}
                          </button>
                        </Col>
                      }
                    </Row>
                  </Fragment>
                )}
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GetQuoteWizard.propTypes = {
  // classes: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
  updateQuotation: (data, id, direction) => dispatch(getQuoteActions.updateQuotation(data, id, direction)),
  getStates: (country) => dispatch(getQuoteActions.getStates(country)),
  resetQuotation: () => dispatch(getQuoteActions.resetQuotation()),
  checkEditPermission: () => dispatch(getQuoteActions.checkEditPermission()),
  checkGetQuotePermission: () => dispatch(getQuoteActions.checkGetQuotePermission()),
  // createQuotation: (data) => dispatch(getQuoteActions.createQuotation(data)),
  getQuotation: (quotationId) => dispatch(getQuoteActions.getQuotation(quotationId)),
  getProducts: () => dispatch(getQuoteActions.getProducts()),
  getCollections: (productCode, stateParam) => dispatch(getQuoteActions.getCollections(productCode, stateParam)),
});

function mapStateToProps(state) {
  const {item, collections, products, productsFetched, flashMessage, selectedProductCode} = state.getQuote;
  return {
    item,
    collections,
    productsFetched,
    products,
    selectedProductCode,
    flashMessage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GetQuoteWizard));
