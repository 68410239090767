const model = {
    header: {
      productName: { id: 'productName', name: '', config: {} },
      plan: { id: 'properties.Plan', name: '', config: {} },
      total: { id: 'properties.Price.Total', name: 'Total Price', ext: ' / តម្លៃសរុប', config: {} },
      config: {},
    },
    body: {
      items: [
        {
          id: 0,
          title: { id: 'Price Breakdown', name: 'Price Breakdown', ext: ' / តម្លៃលំអិត' },
          items: [
            { id: 'properties.Price.Main Policy', name: 'Gross Premium', ext: ' / បុព្វលាភដុល', config: { currency: true } },
            { id: 'properties.Price.Admin Fee', name: 'Admin Fee', ext: ' / សេវារដ្ឋបាល', config: { currency: true } },
          ],
          config: {},
        },
        {
          id: 1,
          title: { id: 'Other Details', name: 'Policy Details', ext: ' / ពត៌មានលំអិតពីបណ្ណសន្យារ៉ាប់រង' },
          items: [
            { id: 'properties.Occupation Class', name: 'Occupation Class', ext: ' / ចំណាត់ថ្នាក់ ឬមុខរបរ', config: {} },
            { id: 'properties.Plan', name: 'Plan', ext: ' / គម្រោង', config: {} },
          ],
          config: {},
        },
      ],
      config: {},
    },
    config: {},
  };
  
  export default model;
  