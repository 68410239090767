import BaseAction from "./base";
import {ScopeManagementService} from '@xc-core/services';
import actionTypes from '@redux/actionTypes'
import Notifications from 'react-notification-system-redux';
import { getNotificationMessage } from '../../_services';
import { handleError } from '@xc-core/lib';

class ScopeManagementAction extends BaseAction{
    protected createScope: string = '';
    protected editScope: string = '';

    protected actions: IBaseActionTypes = actionTypes.scopeManagement;
    protected service: ScopeManagementService;

    constructor() {
        super();
        this.service = new ScopeManagementService();
    }

    public getList = (except?:number) => async (dispatch: Function, getState: Function) => {
        if(except){
            const { list } = getState();
            return dispatch(this.createAction(this.actions.GET_LIST, list.filter((item:IObject)=> item.id !== except )));    
        } else {
            try{
                const result = await this.service.getList();
                return dispatch(this.createAction(this.actions.GET_LIST, { ...result }));
            } catch(e){
                handleError(e)
            }
        }
        
    }

    public search = (key:string) => async (dispatch: Function) => {
        try{
            const result = await this.service.search(key);
            return dispatch(this.createAction(this.actions.SEARCH_LIST, result ));
        } catch(e){
            handleError(e)
        }
    }

    public edit = (item: IObject) => async (dispatch: Function) =>{
        const data: IObject = {
            ...item,
            type: item.type.toLowerCase(),
            parentResource: item.parentResource.value,
            dependencies: item.dependencies.map((value:IObject)=> value.value),
            extendedProperties:{
                ...item.extendedProperties,
                icon: item.extendedProperties.icon.value,
                isPermissionVisibleType: item.extendedProperties.isPermissionVisibleType.map((value:IObject)=> value.value)
            }
        }
        try{
            await this.service.edit(data,decodeURI(item.id));
            // @ts-ignore
            dispatch(Notifications.success(getNotificationMessage('Successfully updated')))
        } catch(e){
            handleError(e)
        }
        // dispatch(this.createAction(this.actions.TOGGLE_EDIT_LIMIT, {index,limitEditIsNew}));   
    }

    public add = (item: IObject) => async (dispatch: Function) =>{
        const data: IObject = {
            ...item,
            type: item.type.toLowerCase(),
            parentResource: item.parentResource.value,
            dependencies: item.dependencies.map((value:IObject)=> value.name),
            extendedProperties:{
                ...item.extendedProperties,
                icon: item.extendedProperties.icon.value,
                isPermissionVisibleType: item.extendedProperties.isPermissionVisibleType.map((value:IObject)=> value.value)
            }
        }
        if(data.extendedProperties.isNavVisible){
            if(!data.extendedProperties.displayName || !data.extendedProperties.link){
                // @ts-ignore
                dispatch(Notifications.error(getNotificationMessage('Please fill all the required fields.')))
                return;
            }
        }
        try{
            await this.service.add(data);
            // @ts-ignore
            dispatch(Notifications.success(getNotificationMessage('Successfully added')))
        } catch(e){
            handleError(e)
        }
    }

}

export default new ScopeManagementAction();

