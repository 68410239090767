import Notification from 'react-notification-system-redux';
import filterUtil from "_components/filter/utils";
import { getNotificationMessage } from "../../../_services";
import { orgConstants } from "../constants";
import { orgServices } from "../services";
import { HANDLE_FILTER_CLICKED } from "../../../_constants";

const hasPermissionTo = (scope) => {
  return async (dispatch) => {
    const data = await orgServices.hasPermission(scope);
    return data;
  };
};

function getOrganisationsChart() {
  return async (dispatch) => {
    const orgChart = await orgServices.getOrganisationsChart();
    if (!orgChart.error) {
      return dispatch({
        type: orgConstants.GET_ORG_CHART,
        payload: orgChart,
      });
    } else dispatch(Notification.error(getNotificationMessage("Sorry, something went wrong.")));
  };
}

function updateNotification(){
  return{
    type: orgConstants.ORG_REMOVE_NOTIFICATION,
    notification: "",
  }
}

function getList(page, perPage) {
  return async (dispatch) => {
    const list = await orgServices.getOrganisationList(page, perPage);
    if (list.message === "Forbidden") return dispatch({ type: orgConstants.SET_FORBIDDEN_ORG });
    if (list.error) return list;
    list.filters = filterUtil.addExtraPropertiesToFilters(list.filters);
    return dispatch({ type: orgConstants.GET_ORG_LIST, list });
  };
}

function createOrg(data){
  return async dispatch => {
    try {
      const response = await orgServices.createOrganisation(data);
      if(!response.error){
        dispatch(Notification.success(getNotificationMessage("Organisation created successfully.")));
        dispatch({
          type: orgConstants.UPDATE_ORG_LIST,
          list: data,
        })
      }else {
        dispatch(Notification.error(getNotificationMessage(response.message)));
      }
      return response;
    } catch (e) {
      return e;
    }
  }
}

function applyFilters(filterObjectParams){
  return dispatch => orgServices.applyFilter(filterObjectParams)
    .then(response => {
        const updatedResponse= {...response,...{filter: filterObjectParams.filterObject}};
        // return dispatch(updateList(updatedResponse,orgConstants.ORGS_APPLY_FILTER,""));
        if (!response.error) return dispatch({
          type: orgConstants.ORGS_APPLY_FILTER,
          list: updatedResponse,
        });

      }
    )
}

function updateOrg(name,data) {
  return dispatch => {
    return orgServices.updateOrganisation(name,data)
    .then(
      response => {
        return response;
      }
    )
  }
}

function getOrgDetails(name) {
  return async dispatch => {
    const data = await orgServices.getOrganisationDetails(name);
    if (data.error) dispatch(Notification.error(getNotificationMessage('An error occurred getting details')));
    else dispatch(showDetails(data));
    return data;
  };

  function showDetails(data) {
    return {
      type: orgConstants.GET_ORG_DETAILS,
      data
    }
  }
}


function getProducts(name) {
  return async dispatch => {
    const data = await orgServices.getProducts(name);
    if (!data.error) return dispatch(showDetails(data));
    else {
      dispatch(Notification.error(getNotificationMessage('Error getting products')));
      return data;
    }
  };

function showDetails(data) {
    return {
      type: orgConstants.UPDATE_PRODUCTS,
      data
    }
  }
}

function handleOptionChanged(filterOptions) {
  return {
    type: HANDLE_FILTER_CLICKED,
    payload: {filterOptions}
  }
}

const resetKeepFilters = () => {
  return {
    type: orgConstants.RESET_KEEP_FILTERS,
  };
};

export const orgActions = {
  getList,
  getOrgDetails,
  createOrg,
  updateOrg,
  applyFilters,
  updateNotification,
  getProducts,
  getOrganisationsChart,
  handleOptionChanged,
  resetKeepFilters,
  hasPermissionTo,
};
