import { userGroupsConstants } from "../constants";

const initialState = {
  userGroupList: [],
  collections: [],
  userGroupDetails: [],
  canViewList: true,
};
function userGroupManagement(state = initialState, action) {
  switch (action.type) {
    case userGroupsConstants.GET_GROUP_LIST:
      return {
        ...state,
        canViewList: true,
        userGroupList: action.list,
        collections: []
      };
    case userGroupsConstants.SET_FORBIDDEN_USER_GROUPS:
      return {
        ...state,
        canViewList: false,
      };
    case userGroupsConstants.UPDATE_GROUPS_DETAILS:
      return {
        ...state,
        userGroupDetails: {
          item: [],
          collections: state.userGroupDetails.collections,
          resourceTree: state.userGroupDetails.resourceTree,
        },
      };
    case userGroupsConstants.CREATE_GROUP:
      return {
        ...state,
        creationErrorMessage: "",
        userGroupDetails: {
          item: [],
          collections: state.userGroupDetails.collections,
          resourceTree: state.userGroupDetails.resourceTree,
        },
      };
    case userGroupsConstants.GET_GROUP_DETAILS:
      return {
        ...state,
        userGroupDetails: action.data,
      };
    case userGroupsConstants.GRP_REMOVE_NOTIFICATION:
      return {
        ...state,
        userGroupDetails: {
          item: [],
          collections: state.userGroupDetails.collections,
          resourceTree: state.userGroupDetails.resourceTree,
        },
        notification: "",
      };
    case userGroupsConstants.GET_CREATE_GROUP_DETAILS:
      return {
        ...state,
        userGroupDetails: {
          item: [],
          collections: action.data.collections,
          resourceTree: action.data.resourceTree,
        }
      };
    default:
      return state;
  }
}

export default userGroupManagement;
