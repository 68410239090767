import React from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Button } from 'reactstrap';
import { resActions } from './../actions';
import { ModalUi } from "../../../_components";

class DeleteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sendingRequest: false,
    }
  }
  
  render() {
    const { isOpen, toggleModal, handleSubmit } = this.props;
    const { sendingRequest: disabled } = this.state;
    return (
      <ModalUi isOpen={isOpen} toggle={() => toggleModal()}>
        <Form>
          <FormGroup>
            <Label for="remarks">Are you sure you want to delete this file?</Label>
          </FormGroup>
          <FormGroup className="text-right mb-0">
            <Button color="muted" className="mr-3 customLeastbtn" onClick={() => toggleModal()} disabled={disabled}>No</Button>
            <Button onClick={() => handleSubmit()} className="customPrimarybtn" color="primary" disabled={disabled}>Delete</Button>
          </FormGroup>
        </Form>
      </ModalUi>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteRes: (id) => dispatch(resActions.deleteRes(id))
  };
}

export default connect(
  null,
  mapDispatchToProps
)(DeleteForm);
