import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const CustomModal = (props: IModalProps) => {
  const {
    className, id, title, children,
    isOpen, toggle,
  } = props;
  return (
    <Modal
      id={id}
      className={`customModalHeader ${className}`}
      modalClassName='d-flex'
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className='text-light' toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default CustomModal;
