import env from 'env';
import Request from './request';
import Response from './response';
import BaseListingService from './baseListing';

class AuditLogService extends BaseListingService {
  protected env = env.audit;

  protected api = 'v1/audit';

  protected withApi = '';


  // public download = async (id: string): Promise<any> => {
  //   const url = `${this.env}/${this.api}/download?idOrCode=${id}`;
  //   const request = new Request();
  //   const response = new Response(url, request, 'file');
  //   const result = await response.handle();
  //   return result.data;
  // }
  // const url = `${this.env}/${this.api}/audit_logs?${params}`;
  // const url = `https://dev-audit.gbsn.xpresscover.io/v1/audit/audit_logs`;

  public getFilters = async () => {
    const url = `${this.env}/${this.api}/filters`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public getDetail = async (id: number | string): Promise<any> => {
    const url = `${this.env}/${this.api}/audit_logs/${id}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
}

export default AuditLogService;
