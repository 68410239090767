import { Col, Row } from 'reactstrap';
import React from 'react';
import { TablesUi } from '../../_components';


const heading = [
  {
    id: 1,
    headerName: 'Reference',
    key: 'refCode',
  },
  {
    id: 2,
    headerName: 'Product',
    key: 'productSlug',
  },
  {
    id: 3,
    headerName: 'Limit Type',
    key: 'limitType',
  },
  {
    id: 4,
    headerName: 'Start Date',
    key: 'startDate',
  },
  {
    id: 5,
    headerName: 'End Date',
    key: 'endDate',
  },
  {
    id: 6,
    headerName: 'Limit Used',
    key: 'limitUsed',
    classNames: 'text-danger'
  },
  {
    id: 7,
    headerName: 'Limit Cap',
    key: 'limitCap',
    classNames: 'text-success'
  },
  {
    id: 8,
    headerName: 'Status',
    key: 'status',
  },
  {
    id: 9,
    headerName: 'Updated At',
    key: 'updatedOn',
  },
  {
    id: 10,
    headerName: 'Created At',
    key: 'createdOn',
  },
  {
    id: 11,
    headerName: 'Action',
    key: 'action',
  },
  
];
export default (props: IObject) => {
  const { limits, addLimit, saveSettings,saving, status } = props;
  return (
    <div>
      <div className='container-fluid px-0 normal-gray-text'>
        {status && <Row className='d-flex justify-content-center my_30'>
          <Col sm={12} className='col-sm-12' style={{ padding: '0 4%' }}>
            <TablesUi
              horizontalScroll={true}
              tableClasses='center'
              heading={heading}
              tableData={limits}
            />
          </Col>
        </Row>}
        <Row className='d-flex justify-content-center my_30'>
          <Col sm={12} className='col-sm-12 d-flex justify-content-end' style={{ padding: '0 4%' }}>
            {status && <button
              type='button'
              className='btn btn-outline-primary mt-4'
              onClick={addLimit}
            >
              Add New Limit
            </button>}
            <button
              type='button'
              className='btn btn-primary mt-4 ml-4'
              onClick={saveSettings}
            >
              Save
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
