import {getRequestOptions} from "../../../utils";
import {handleRequest} from "../../../_services";
import {env} from "_config/env";

const {apiURL} = env;

const recoverPassword = async email => {
  const url = `${apiURL}/identity/users/forgot-password`;
  const requestParams = {body: {email}, method: "PUT"};
  const serverResponse = await handleRequest(url, getRequestOptions(requestParams));
  return await serverResponse.json();
};

export {
  recoverPassword
};