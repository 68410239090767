import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Accordion} from "../../../_components/Accordion";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import {TablesUi} from "../../../_components";
import {Button, Col, Row} from "reactstrap";
import {PropTypes} from "prop-types";
import documentationActions from "../actions";
import ReactJson from "react-json-view";

const PreText = ({objToFormat}) => {
  return (
    <ReactJson
      src={{...objToFormat}}
      name={false}
      displayDataTypes={false}
      enableClipboard={false}
      displayObjectSize={false}
      theme="monokai"
      style={{
        fontSize: "0.9em",
        backgroundColor: "#003a4d",
        maxHeight: "250px",
        borderRadius: "7px",
        padding: "15px",
        overflow: "auto"
      }}
    />
  );
};
const requestHeading = [
  {id: 1, headerName: "Type", key: "Body"},
  {id: 2, headerName: "Description", key: "Description"},
];
const responseHeading = [
  {id: 1, headerName: "Status", key: "status"},
  {id: 2, headerName: "Body", key: "body"},
];
const requestTableData = (tableData) => [
  {"Body": "Body", "Description": <PreText objToFormat={tableData}/>},
];
const responseTableData = (responses) => {
  return responses.map(response => ({ ...response, body: <PreText objToFormat={response.body} />}));
};
// const formatData = (dataArray = []) => {
//   let tempArray = [...dataArray];
//   tempArray.forEach((data, index) => {
//     let tempData = {...data};
//     if (tempData.Range && tempData.Range.length) tempData["PossibleValues"] = tempData.Range;
//     else if (tempData["PossibleValues"]) {
//       tempData["PossibleValues"] = tempData["PossibleValues"].join(', ');
//     }
//     if (tempData.type === "object" || tempData.DataType === "object"){
//       tempData.objectDescription = <pre
//         style={{maxHeight: "250px"}}
//         className="bg-light border borderRadius p-3"
//       >{JSON.stringify(tempData.objectDescription, null, 2)}</pre>;
//     } else if (tempData.description) tempData.objectDescription = tempData.description;

//     tempArray[index] = tempData;
//   });
//   return tempArray;
// };
const getColorByHttpVerb = (method) => {
  let color;
  let colorName;
  switch (method) {
    case "POST":
      color = "green";
      colorName = "green";
      break;
    case "PUT":
      color = "#a68200";
      colorName = "yellow";
      break;
    default:
      color = "#0089cc";
      colorName = "blue";
  }
  return {color, colorName};
};

const Title = ({endpoint, method}) => {
  const {color} = getColorByHttpVerb(method);
  const Action = Styled.span`
  color: ${color};
  padding: 5px 15px;
  margin: 3px;
  margin-right: 10px;
  border: 1px ${color} solid;
  border-radius: 5px;
  `;
  const Paragraph = Styled.p`
  margin: 10px 0; 
  color: ${color}
  `;
  return (
    <Paragraph>
      <Action className="request-method">{method}</Action> {endpoint}
    </Paragraph>
  );
};
Title.propTypes = {
  endpoint: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired
};

const DocumentationTable = (props) => {
  const {
    documentationData,
    setRequestObject, method, path, host, properties,
    requestObject, responses
  } = props;
  const tryOut = () => {
    setRequestObject({path, host, method, tempRequestObject: requestObject});
  };
  let tableLinks = [];
  const tableData = properties || documentationData;
  const handlePropertyClick = ({id}) => {
    const elements = document.querySelectorAll(`[data-scroll="${id}"]`);
    elements.length && elements[elements.length-1].scrollIntoView(true);
  };
  return (
    <Fragment>
      {/*=================SECTION 1=================*/}
      <Typography variant="subtitle1" className="p-2 text-dark ">Implement the following Notes</Typography>
      <Typography variant="subtitle2" className="p-2 text-info">
        The Following table elaborates input model properties possible values
      </Typography>
        <div className="row">
          <div className="col">
            <table className="table  table-hover" id={"mainTable"}>
              <thead>
              <tr>
                <th>name</th>
                <th>type</th>
                <th>description</th>
                <th>required</th>
              </tr>
              </thead>
              <tbody>
              {tableData && tableData.map((property, index) => {
                const type = property.dataType;
                let isComplex = false;
                let isALink = false;
                if (tableLinks.includes(property.name)) isALink = true;
                if (type === "object" || type === "array") {
                  try {
                    isComplex = true;
                    tableLinks.push(property.name);
                    let parsedDescription;
                    const {description, object} = JSON.parse(property.description);
                    if (description !== "") {
                      parsedDescription = `${JSON.stringify(object, null, 3)} \nNote: '${description}`;
                    }
                    else parsedDescription = JSON.stringify(object, null, 3);
                    property.description = <pre>{parsedDescription}</pre>;
                  } catch (e) {}
                } else {
                  if (typeof property.description === "string") {
                    property.description = <div dangerouslySetInnerHTML={{__html: property.description}}/>;
                  }
                }
                return <tr key={index} className={property.rowType === "title" ? "font-weight-bold" : ""}>
                  <td data-scroll={isALink ? property.name : ""}>{property.name}</td>
                  <td>{isComplex? <span className="text-primary font-weight-bold" style={{cursor: "pointer"}}
                    onClick={() => handlePropertyClick({id: property.name})}
                  >{property.dataType}</span> : property.dataType }
                  </td>
                  <td>{property.description}</td>
                  <td>{property.required}</td>
                </tr>;
              })}
              </tbody>
            </table>
          </div>
        </div>
      {/*<TablesUi*/}
      {/*  heading={propertiesHeading || tableHeading}*/}
      {/*  tableData={properties ? formatData(properties) : formatData(documentationData)}*/}
      {/*  noCard={true}*/}
      {/*  tableClasses="border-radius"*/}
      {/*  borderRadius={true}*/}
      {/*/>*/}
      <Row>
        <Col className="d-flex justify-content-end">
          <Button
            className="btn-primary"
            onClick={tryOut}
          >Try Out</Button>
        </Col>
      </Row>
      {/*=================REQUEST OBJECT=================*/}
      <h5>Request Object</h5>
      <TablesUi
        heading={requestHeading}
        tableData={requestTableData(requestObject || documentationData)}
        noCard={true}
        tableClasses="border-radius"
        borderRadius={true}
      />
      {/*=================RESPONSE OBJECT=================*/}
      <h5>Response Object</h5>
      <TablesUi
        heading={responseHeading}
        tableData={responseTableData(responses || [])}
        noCard={true}
        tableClasses="border-radius"
        borderRadius={true}
      />
    </Fragment>
  );
};
const Endpoint = (props) => {
  const {
    open, method, documentationData, endpointId, openState,
    tableHeading, setRequestObject, path, host,
    properties, propertiesHeading, requestObject, responses
  } = props;
  /**
   * @function setOpenValue - it helps keep track of the state(open/close) of the accordion
   * with this in place, even when the component is unmounted,
   * its parent will always know the its state whe it is mounted back
   * @param {boolean} value - the state of the accordion
   */
  const setOpenValue = (value) => {
    openState && openState.changeTabsState({...openState.tabsState, [endpointId] : value});
  };
  const {colorName} = getColorByHttpVerb(method);
  return (
    <Fragment>
      <Accordion
        open={open}
        classes={`border border-${colorName}`}
        headerClasses={"bg-transparent"}
        headerComponent={<Title endpoint={path} method={method}/>}
        setOpenValue={setOpenValue}
        bodyComponent={
          <div className="p-3">
            <DocumentationTable
              documentationData={documentationData}
              tableHeading={tableHeading}
              setRequestObject={setRequestObject}
              path={path}
              host={host}
              method={method}
              properties={properties}
              propertiesHeading={propertiesHeading}
              requestObject={requestObject}
              responses={responses}
            />
          </div>
        }
      />
    </Fragment>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setRequestObject: (requestObj) => dispatch(documentationActions.setRequestObject(requestObj))
});
export default connect(mapStateToProps, mapDispatchToProps)(Endpoint);