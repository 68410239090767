import React from "react";
import { connect } from "react-redux";
import { Row, Col, Input } from 'reactstrap';
import { CommonInputTypes } from "_components";
import PropTypes from "prop-types";
import moment from "moment";
import {datesOfBirth} from "Products/shared/utils/utils";
import { utils, CONFIG_DATE_MOMENT_FORMAT, calculateGenderFromIC, calculateDobMomentFromIC } from "utils";

const ValidationMessages = ({validator, disabled, value="", inputName, validationRules}) => {
  return (<div>
      {
        validator && !disabled &&
        validator.message(inputName, value,
          validationRules ? `${validationRules}|custom_required` : "custom_required"
        )
      }
    </div>);
};
ValidationMessages.propTypes = {
  inputName: PropTypes.string.isRequired,
  validator: PropTypes.object.isRequired,
  validationRules: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

class InsuredDetail extends React.Component {
  
  handleICInput = async (e, beneficiaryIndex) => {
    const {target} = e;
    await this.props.inputChange(e, beneficiaryIndex);
    if (String(target.value).length >= 4) {
      this.handleICGenderDOBAutofill({val: target.value, beneficiaryIndex});
    }
  };
  async handleICGenderDOBAutofill({val, beneficiaryIndex}) {

    const dobM = calculateDobMomentFromIC(val);
    const birthGender = calculateGenderFromIC(val);
    if (dobM.isValid()) {
        await this.props.inputChange({ 
            target: { 
                id: 'DOB', 
                value: dobM.format(CONFIG_DATE_MOMENT_FORMAT), 
            } 
        }, beneficiaryIndex);
    }
    this.props.inputChange({ target: { id: 'Gender', value: birthGender } }, beneficiaryIndex);
  }

  handleDOB = (date, beneficiaryIndex) => {
    const dobM = moment(date, CONFIG_DATE_MOMENT_FORMAT);
    if (dobM.isValid()) {
      this.props.inputChange({ target: { id: "DOB", value: dobM.format(CONFIG_DATE_MOMENT_FORMAT) } }, beneficiaryIndex)
    }
  }

  getValidationDates = (insuredType) =>{
    let validationDates;
    switch (insuredType) {
      case "Senior Citizen":
        validationDates = {
          maxDateOfBirth: datesOfBirth.seniorCitizen.max,
          minDateOfBirth: datesOfBirth.seniorCitizen.min,
        };
        break;
      case "Child":
        validationDates = {
          maxDateOfBirth: datesOfBirth.children.max,
          minDateOfBirth: datesOfBirth.children.min,
        };
        break;
      default:
        validationDates = {
          maxDateOfBirth: datesOfBirth.adult.max,
          minDateOfBirth: datesOfBirth.adult.min,
        };
        break;
    }
    return validationDates;
  };

  render() {
    const {
      data, beneficiaries, main, editMode, beneficiaryIndex, index,
      detailCollections, validator, uniqueId, mainInsurerIsAdult,
    } = this.props;
    const beneficiary = beneficiaries || {};
    if (data === undefined) return null;
    let insuredType;
    if (main && mainInsurerIsAdult) insuredType = "Adult";
    else if (main) insuredType = "Senior Citizen";
    else insuredType = data.Type;
    const validationDates = this.getValidationDates(insuredType);
    return (
      <Row>
        <Col sm={12}>
          <h4 className="beneficiary-header my-3 p-3">
          { `${main ? `${insuredType} 1 (Main Applicant)` : `${data.Type} ${index}`}`}
          </h4>
          <Row className="wordBreak">
            <Col sm={6}>
              <div className="custom-q-text-primary bold my-2">Insured Person Details</div>

              <Row>
                <Col className="pt-2 summary-label" sm={6}>Full Name</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id="Full Name"
                        name="Full Name"
                        value={data['Full Name']}
                        onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                      />
                      <ValidationMessages 
                      validator={validator} 
                      validationRules="full_name_valid_characters"
                      value={data["Full Name"]} inputName={`${uniqueId}: Full Name`}/>
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['Full Name'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>Nationality</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                     <Input
                      type="select"
                      id="Nationality"
                      name="Nationality"
                      value={data['Nationality']}
                      onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                    >
                      {
                        detailCollections.nationalities.map(country => {
                          return ( <option key={country.id}>{country.name}</option>);
                        })
                      }
                    </Input>
                      <ValidationMessages validator={validator} value={data["Nationality"]} inputName="Nationality"/>
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['Nationality'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>Place Of Birth</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        type="select"
                        id="Place of Birth"
                        name="Place of Birth"
                        value={data['Place of Birth'] || ""}
                        onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                      >
                        {
                          detailCollections.placeOfBirth.map(countryOfBirth => {
                            return ( <option key={countryOfBirth.id}>{countryOfBirth.name}</option>);
                          })
                        }
                      </Input>
                      <ValidationMessages validator={validator} value={data["Place of Birth"]} inputName="Place of Birth"/>
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['Place of Birth']  : '') }
                </Col>


                <Col className="pt-2 summary-label" sm={6}>ID Type</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                      type="select"
                      id="ID Type"
                      name="ID Type"
                      value={data['ID Type']}
                      onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                    >
                      {
                        detailCollections.idTypes.map(idType => {
                          return ( <option key={idType.id}>{idType.name}</option>);
                        })
                      }
                    </Input>
                      <ValidationMessages validator={validator} value={data["ID Type"]} inputName="ID Type" />
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['ID Type'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>ID Number</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                      id="ID Number"
                      name="ID Number"
                      value={data['ID Number']}
                      onChange={
                        data['ID Type'] === "IC" ?
                          (e) => this.handleICInput(e, beneficiaryIndex):
                          (e) => this.props.inputChange(e, beneficiaryIndex)
                      }
                    />
                      <ValidationMessages
                        validationRules={data["ID Type"] === "IC" ? `ic|min:${utils.lengths.IC}|max:${utils.lengths.IC}`
                          : "alpha_num_passport|custom_required"}
                        validator={validator}
                        value={data["ID Number"]}
                        inputName={`${uniqueId}: ID Number`}
                      />
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['ID Number'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>DOB</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <CommonInputTypes
                        dateStyle={{
                          marginBottom: 0
                        }}
                        validator={""}
                        // idName="DOB"
                        group={false}
                        type="date"
                        value={new Date(data['DOB'])}
                        // onChange={this.handleDOBChange.bind(this)} // datepicker onchange
                        onChange = {(date) => this.handleDOB(date, beneficiaryIndex)}
                        // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                        // openToDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                        maxDate={this.getValidationDates(insuredType).maxDateOfBirth}
                        minDate={this.getValidationDates(insuredType).minDateOfBirth}
                        openToDate={new Date(data['DOB'])}
                        id="DOB"
                        className={"datePickerBorderLeft"}
                        disabled={data['ID Type'] === "IC"}
                      />
                      <ValidationMessages
                        validationRules={"custom_required|validate_age_boundary"}
                        validator={validator}
                        value={{
                          value: data["DOB"],
                          validationParams: validationDates
                        }}
                        inputName={`${uniqueId}: DOB`}
                      />
                    </>
                  ) : null }
                  { editMode ? null : (data && utils.lib.applyConfigFormat(data['DOB']))}
                </Col>

                <Col className="pt-2 summary-label" sm={6}>Gender</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        type="select"
                        id="Gender"
                        name="Gender"
                        value={data['Gender']}
                        onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        disabled={data['ID Type'] === "IC"}
                      >
                        {
                          detailCollections.genders.map(gender=> {
                            return ( <option key={gender.id}>{gender.name}</option>);
                          })
                        }
                      </Input>
                      <ValidationMessages validationRules="custom_required" validator={validator} value={data["Gender"]} inputName="Gender"/>
                    </>
                  ) : null }
                  { editMode ? null : (data ? data['Gender'] : '-') }
                </Col>
              </Row>

              { main ? (
                <Row>
                  <Col className="pt-2 summary-label" sm={6}>Address</Col>
                  <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id="Address"
                          name="Address"
                          value={data['Address']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        />
                        <ValidationMessages validator={validator} value={data["Address"]} inputName="Address"/>
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Address'] : '-') }
                  </Col>

                  <Col className="pt-2 summary-label" sm={6}>City / Town</Col>
                  <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id="City"
                          name="City"
                          value={data['City']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        />
                        <ValidationMessages validator={validator} value={data["City"]} inputName="City"/>
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['City'] : '-') }
                  </Col>

                  <Col className="pt-2 summary-label" sm={6}>State / Province / Region</Col>
                  <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          type="select"
                          id="State"
                          name="State"
                          value={data['State']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        >
                          {
                            detailCollections.states.map(state=> {
                              return ( <option key={state.id}>{state.name}</option>);
                            })
                          }
                        </Input>
                        <ValidationMessages validator={validator} value={data["State"]} inputName="State"/>
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['State'] : '-') }
                  </Col>

                  <Col className="pt-2 summary-label" sm={6}>Postcode</Col>
                  <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id="Postcode"
                          name="Postcode"
                          value={data['Postcode']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        />
                        <ValidationMessages validationRules="custom_required|numeric" validator={validator} value={data["Postcode"]} inputName="Postcode"/>
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Postcode'] : '-') }
                  </Col>

                  <Col className="pt-2 summary-label" sm={6}>Country</Col>
                  <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          disabled={true}
                          type="select"
                          id="Country"
                          name="Country"
                          value={data['Country'] || 'Indonesia'}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        >
                          {
                            detailCollections.countries.map(country => {
                              return ( <option key={country.id}>{country.name}</option>);
                            })
                          }
                        </Input>
                        <ValidationMessages validator={validator} value={data["Country"]} inputName="Country"/>
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Country'] || 'Indonesia' : '-') }
                  </Col>

                  <Col className="pt-2 summary-label" sm={6}>Email Address</Col>
                  <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id="Email Address"
                          name="Email Address"
                          value={data['Email Address']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        />
                        <ValidationMessages validationRules="email" validator={validator} value={data["Email Address"]} inputName="Email Address"/>
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Email Address'] : '-') }
                  </Col>

                  <Col className="pt-2 summary-label" sm={6}>Phone Number</Col>
                  <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                    { editMode ? (
                      <>
                        <Input
                          id="Contact Number"
                          name="Contact Number"
                          value={data['Contact Number']}
                          onChange={(e) => this.props.inputChange(e, beneficiaryIndex)}
                        />
                        <ValidationMessages validationRules="numeric" validator={validator} value={data["Contact Number"]} inputName="Contact Number"/>
                      </>
                    ) : null }
                    { editMode ? null : (data ? data['Contact Number'] : '-') }
                  </Col>
                </Row>
              ) : null }

            </Col>

            <Col sm={6}>
              <div className="custom-q-text-primary bold my-2">Beneficiary Details</div>

              <Row className="wordBreak">
                <Col className="pt-2 summary-label" sm={6}>Full Name</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id="Full Name"
                        name="Full Name"
                        value={beneficiary['Full Name']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                      />
                      <ValidationMessages 
                        validator={validator} 
                        validationRules="full_name_valid_characters"
                        value={beneficiary["Full Name"]} 
                        inputName={`${uniqueId} - benf: Full Name`}/>
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Full Name'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>Relationship</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        type="select"
                        id="Relationship"
                        name="Relationship"
                        value={beneficiary['Relationship']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                      >
                        {
                          detailCollections.relations.map(relation => {
                            return ( <option key={relation.id}>{relation.name}</option>);
                          })
                        }
                      </Input>
                      <ValidationMessages validator={validator} value={beneficiary["Relationship"]} inputName={`${uniqueId} - benf: Relationship`}/>
                    </>
                    ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Relationship'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>ID Type</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        type="select"
                        id="ID Type"
                        name="ID Type"
                        value={beneficiary['ID Type']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                      >
                        {
                          detailCollections.idTypes.map(idType => {
                            return ( <option key={idType.id}>{idType.name}</option>);
                          })
                        }
                      </Input>
                      <ValidationMessages validator={validator} value={beneficiary["ID Type"]} inputName={`${uniqueId} - benf: ID Type`}/>
                    </>
                    ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['ID Type'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>ID Number</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id="ID Number"
                        name="ID Number"
                        value={beneficiary['ID Number']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                      />
                      <ValidationMessages
                        validator={validator}
                        validationRules={beneficiary["ID Type"] === "IC" ? `ic|idNumber_dob_inFuture|min:${utils.lengths.IC}|max:${utils.lengths.IC}` : null}
                        value={beneficiary["ID Number"]}
                        inputName={`${uniqueId} - benf: ID Number`}
                      />
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['ID Number'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>Email Address</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id="Email"
                        name="Email"
                        value={beneficiary['Email']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                      />
                      <ValidationMessages validationRules="email" validator={validator} value={beneficiary["Email"]} inputName={`${uniqueId} - benf: Email`}/>
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Email'] : '-') }
                </Col>

                <Col className="pt-2 summary-label" sm={6}>Phone Number</Col>
                <Col className="pt-2 summary-label-2-other font-size-small" sm={6}>
                  { editMode ? (
                    <>
                      <Input
                        id="Contact Number"
                        name="Contact Number"
                        value={beneficiary['Contact Number']}
                        onChange={(e) => this.props.beneficiaryChange(e, beneficiaryIndex)}
                      />
                        <ValidationMessages validationRules="numeric" validator={validator} value={beneficiary["Contact Number"]} inputName={`${uniqueId} - benf: Contact Number`}/>
                    </>
                  ) : null }
                  { editMode ? null : (beneficiary ? beneficiary['Contact Number'] : '-') }
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

InsuredDetail.defaultProps = {
  editMode: false,
}

function mapStateToProps(state) {
  const { detailCollections } = state.policyManagement;

  return { detailCollections };
}

export default connect(mapStateToProps)(InsuredDetail);
