import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const MultiSelectCheckbox = ({label, options, onChange, selected}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if(selected && selected.length === options.length)
      setSelectedOptions([{ label: "All", value: "*" }, ...options]);
    else if(selected && selected.length){
      let selectedItems = []
      options.map(item => {
        if(selected.findIndex(s => s === item.value) >= 0){
          selectedItems.push(item)
        }
      })
      setSelectedOptions([...selectedItems]);
    }
  }, []);

  useEffect(() => {
    onChange(selectedOptions)
  }, [selectedOptions]);
  
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChangeValue(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  return (
    <ReactMultiSelectCheckboxes
      options={[{ label: "All", value: "*" }, ...options]}
      placeholderButtonLabel={label}
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions}
      onChange={onChangeValue}
      setState={setSelectedOptions}
    />
  );
};

export default MultiSelectCheckbox;
