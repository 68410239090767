/* eslint-disable object-curly-newline */


const fieldsModel: IFieldModel = {
  plan: { id: 'properties.Plan Type', label: 'Plan Type', type: 'PlanButton', collectionId: 'priceList' },
  fullName: { id: 'properties.Full Name', label: 'Full Name', type: 'Input', placeholder: 'Enter full name' },
  nationality: { id: 'properties.Nationality', label: 'Nationality', type: 'Select', collectionId: 'nationalities' },
  idType: { id: 'properties.ID Type', label: 'ID Type', type: 'Select', collectionId: 'idTypes' },
  idNumber: { id: 'properties.ID Number', label: 'ID Number', type: 'Input', placeholder: 'Enter ID number' },
  dob: { id: 'properties.DOB', label: 'Date of Birth', type: 'DatePicker', placeholder: 'Select Date of Birth' },
  gender: { id: 'properties.Gender', label: 'Gender', type: 'Select', collectionId: 'genders' },
  debitCreditNo: { id: 'properties.Debit Card or Account No', label: 'Debit Card / Account No', type: 'Input', placeholder: 'Enter debit card / account number' },

  address: { id: 'properties.Address', label: 'Address', type: 'Input', placeholder: 'Enter address' },
  country: { id: 'properties.Country', label: 'Country', type: 'Select', collectionId: 'countries' },
  state: { id: 'properties.State', label: 'State/Province/Region', type: 'Select', collectionId: 'states' },
  postCode: { id: 'properties.Postcode', label: 'Postcode', type: 'Input', placeholder: 'Enter postcode' },
  city: { id: 'properties.City', label: 'City/Town', type: 'Input', placeholder: 'Enter city/town' },
  email: { id: 'properties.Email Address', label: 'Email Address', type: 'Input', placeholder: 'Enter email address' },
  contactNo: { id: 'properties.Contact Number', label: 'Contact Number', type: 'Input', placeholder: 'Enter contact number' },

  beneficiary: { id: 'properties.Beneficiaries', label: '', type: 'array' },
  beneficiaryName: { id: 'properties.Beneficiaries[_#_].Full Name', label: 'Full Name', type: 'Input', placeholder: 'Enter full name' },
  beneficiaryRelation: { id: 'properties.Beneficiaries[_#_].Relationship', label: 'Relationship', type: 'Select', collectionId: 'relations' },
  beneficiaryIdType: { id: 'properties.Beneficiaries[_#_].ID Type', label: 'ID Type', type: 'Select', collectionId: 'idTypes' },
  beneficiaryIdNumber: { id: 'properties.Beneficiaries[_#_].ID Number', label: 'ID Number', type: 'Input', placeholder: 'Enter ID number' },
  beneficiaryEmail: { id: 'properties.Beneficiaries[_#_].Email Address', label: 'Email Address', type: 'Input', placeholder: 'Enter email address' },
  beneficiaryPhone: { id: 'properties.Beneficiaries[_#_].Contact Number', label: 'Contact Number', type: 'Input', placeholder: 'Enter contact number' },

  paymentMode: { id: 'properties.Payment Mode', label: 'Mode of Payment', type: 'Select', collectionId: 'paymentMethod' },
  receiptNo: { id: 'properties.Receipt No', label: 'Receipt No', type: 'Input', placeholder: 'Enter receipt no' },
  paymentReceivedBy: { id: 'properties.Payment Received By', label: 'Payment Received By', type: 'Select', collectionId: 'currentOuUsers' },
  cc: { id: 'properties.CC Email', label: '<p>Other Emails to Receive Policy</p><span>CC</span>', type: 'MultiValueInput' },
  bcc: { id: 'properties.BCC Email', label: 'BCC', type: 'MultiValueInput' },
};

const mainDetailModel = [
  fieldsModel.fullName,
  fieldsModel.nationality,
  fieldsModel.idType,
  fieldsModel.idNumber,
  fieldsModel.dob,
  fieldsModel.gender,
  fieldsModel.debitCreditNo,
  fieldsModel.address,
  fieldsModel.city,
  fieldsModel.state,
  fieldsModel.country,
  fieldsModel.postCode,
  fieldsModel.email,
  fieldsModel.contactNo,
];

const beneficiaryDetailModel = [
  fieldsModel.beneficiaryName,
  fieldsModel.beneficiaryRelation,
  fieldsModel.beneficiaryIdType,
  fieldsModel.beneficiaryIdNumber,
  fieldsModel.beneficiaryEmail,
  fieldsModel.beneficiaryPhone,
];

const model = { fieldsModel, mainDetailModel, beneficiaryDetailModel };

export default model;
