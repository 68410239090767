import React from 'react';
import { Row, Col } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
// import Detail from './Detail';
import Details from "../../../@containers/Details/details";
import lib from 'utils/lib';
import {History} from 'history'

const Summary = (props: ILoadableComponentProps) => {
    const {
        model: {
            item,
            model,
            isKhmer,
            sidebar,
            productIcon
        }
    } = props;
    const isBelongToCompany = item.properties?.['Main Insured']?.['Belongs to Company'] === 'Yes';
    const basedPath = `properties.Main Insured.${isBelongToCompany ? 'Company' : 'Individual'}`;
    const mainInfo= [`${basedPath} Name`,
        `${basedPath} Business Occupation`,
        `${basedPath} VATIN`,
        `${basedPath} ID Type`,
        `${basedPath} ID Number`
    ]
    const mainInsuredModel = model[isBelongToCompany ? 'companyInsuredFields' : 'individualInsuredFields'].fields;
    const mainInfoFields= mainInsuredModel.filter((el:IField)=>{
        return mainInfo.indexOf(el.id) !== -1
    })

    const contactFields= mainInsuredModel.filter((el:IField)=>{
        return mainInfo.indexOf(el.id) === -1
    })

    const generateDetailModel=(fields:IField[])=>{
        return{
            items: fields.map((el:IField)=> {
                return {
                    id:el.id,
                    name:el.label,
                    config:{}
                }
            })
        }
    }

    return (
       <Row>
           <Details
               editQuotation={()=>{}}
               {...props}
               type={"summary"}
               data={item}
               sidebarModel={sidebar}
               components={[
                   {
                       title:`${isBelongToCompany ? 'Company' : 'Individual'} Details`,
                       model:generateDetailModel(mainInfoFields),
                       type:'primary'
                   },
                   {
                       title:`Contact Details`,
                       model:generateDetailModel(contactFields),
                       type:'primary'
                   },
                   {
                       title:`Property Details`,
                       model:generateDetailModel(model.insuredDetailFields.fields),
                       type:'primary'
                   },
                   {
                       title:'Beneficiaries',
                       model:generateDetailModel(model[isBelongToCompany ? 'companyBeneficiaryFields' : 'individualBeneficiaryFields'].fields),
                       type:'secondary'
                   }
               ]}
               productIcon={productIcon}
               isKhmer={isKhmer}
           />
       </Row>
    );
};

export default Summary;
