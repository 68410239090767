import moment from 'moment';
import { ageBoundaryRule, icIdNumberDobInFutureRule } from "utils";
const validationObject = {
  className: 'text-danger-step',
  messages: {
    email: "That is not a valid email.",
    max: "This field may not be greater than :max:type",
    min: "This field must be at least :min:type",
    alpha: "This field should contain only letters"
  },
  validators: {
    idtype: {
      message: 'The ID type field is required.',
      rule: (val, params, validator) => {
        return !validator.helpers.isBlank(val);
      },
      required: true
    },
    idnumrequired: {
      message: 'The ID number field is required.',
      rule: (val, params, validator) => {
        return !validator.helpers.isBlank(val);
      },
      required: true
    },
    alpha_num_passport: {
      message: 'The passport may only contain letters and numbers.',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-z0-9]+$/i);
      },
      required: true
    },
    validate_ktp_id_number: {
      message: 'Invalid KTP number.',
      rule: (val) => {
        if (val === "") return false;
        let dayOfBirth = Number(String(val).substr(6, 2));
        const monthOfBirth = String(val).substr(8, 2);
        let yearOfBirth = Number(String(val).substr(10, 2));
        if (!dayOfBirth) return false;
        if (!monthOfBirth) return false;
        if (!yearOfBirth && yearOfBirth !== 0) return false;
        if (monthOfBirth > 12) return false; // the resulting month should not go beyond 12 :)
        if (dayOfBirth > 40) dayOfBirth -= 40; // for female, we have to subtract 40 to use proper date
        if (dayOfBirth > 29 && Number(monthOfBirth) === 2) return false; // february should never have beyond 29 dates

        const maxInsuredAge = 80;
        let maxInsuredYear = Number(String(moment().year() - maxInsuredAge).substr(2, 2));

        if (yearOfBirth < 10) yearOfBirth = `0${yearOfBirth}`;
        if (yearOfBirth < maxInsuredYear) yearOfBirth = Number("20" + String(yearOfBirth));
        else if (yearOfBirth >= maxInsuredYear)yearOfBirth = Number("19" + String(yearOfBirth));

        const date = moment(`${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`);
        if (!date.isValid()) return false; // the whole date should be a valid date
        // if(isDateInFuture(date)) return false;
        // if (moment().year() < yearOfBirth) return false; // year of birth not be in the future
        return true;
      },
      required: true
    },
    idNumber_dob_inFuture: icIdNumberDobInFutureRule,
    ic: {  // name the rule
      message: 'The IC number must be valid and contain numbers only.',
      rule: (val, params, validator) => {
        return val && validator.helpers.numeric(val) && 
        validator.helpers.testRegex(val.substr(0,6),/([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))/);
      }
    },
    numbers_only: {  // name the rule
      message: 'This field must contain numbers only.',
      rule: (val) => (Number.isInteger(Number(val)) && val !== "")
    },
    full_name_valid_characters: {// name the rule
      message: 'This field must contain letters and certain symbols only.',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val,/^[a-z\-\'\@\.\ \/\\\u4E00-\u9FCC]+$/gi)
        && validator.helpers.testRegex(val, /[a-zA-Z]/gi);/** at least one alphabetical character */
      },
    },
    icsize: {
      message: 'The KTP number must consist of :size numeric characters.',
      rule: (val, params, validator) => {
        return validator.helpers.size(val, params[1]) === parseFloat(params[0]);
      },
      messageReplace: (message, params) => message.replace(':size', params[0])
    },
    custom_required: {
      message: 'This field is required.',
      rule: (val, params, validator) => {
        return !validator.helpers.isBlank(val) && !!val // making sure val is truthy value
      },
      required: true
    },
    validate_age_boundary: {
      message: `Incorrect date of birth for this age group. 
      Please enter a matching date of birth or change the IC number accordingly.`,
      rule: ageBoundaryRule,
      required: true,
    },
  }
};
export {
  validationObject
};