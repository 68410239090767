import React, { useEffect, useState } from 'react';
import { quotationActions } from 'Products/shared/quotationListing/actions';
import { connect } from 'react-redux';
import FlashMessage from '_components/FlashMessage';
import CBTravelQuotationDetail from 'Products/CBTravelInsurance/pymentFailed';
import CBFlexiPaQuotationDetail from 'Products/CBFlexiPa/pymentFailed';
import ABCTravelQuotationDetail from 'Products/ABCTravelInsurance/pymentFailed';
import AutoInsurancePaymentFailed from 'Products/AutoInsurance/paymentFailed';
import config from '_config';
import { quotationModel } from 'models';


const QuotationFailed = (props) => {
  const [productCode, changeProductCode] = useState(null);
  useEffect(() => {
    const { match: { params } } = props;
    props.clearQuotationDetail();
    props.getDetail(params.id);
  }, []);

  useEffect(() => {
    if (props.quotation) {
      changeProductCode(Number(props.quotation.productCode));
    }
  }, [props.quotation]);
  if (productCode) {
    switch (productCode) {
      case config.products.cbTravelInsurance.id:
        return <CBTravelQuotationDetail {...props} model={quotationModel.travelCB} />;
      case config.products.abcTravelInsurance.id:
        return <ABCTravelQuotationDetail {...props} model={quotationModel.travelCB} />;
      case config.products.flexiPa.id:
        return <CBFlexiPaQuotationDetail {...props} model={quotationModel.flexiPaCB} />;
      case config.products.autoInsurance.id:
        return <AutoInsurancePaymentFailed {...props} model={quotationModel.autoInsurance}/>
      default:
        return <FlashMessage flashMessage='unknown product code' />;
    }
  }
  return (
    <div className='loader' />
  );
};


function mapStateToProps(state) {
  const { quotation } = state.quotationManagement;
  return { quotation };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetail: (quotationId) => dispatch(quotationActions.getDetail(quotationId)),
    clearQuotationDetail: () => dispatch(quotationActions.clearQuotationDetail()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuotationFailed);
