import {store} from "../../../_helpers";
import {documentationConstants} from "../constants";
import docServices from "../services";

const setRequestObject = requestObj => dispatch => {
   return dispatch({
    type: documentationConstants.SET_CURRENT_REQUEST_OBJECT,
    payload: {
      tempRequestObject: requestObj.tempRequestObject,
      method: requestObj.method,
      path: requestObj.path,
      host: requestObj.host,
    },
  });
};
const handleHeaderChange = ({key, value}) => dispatch => {
   return dispatch({
    type: documentationConstants.SET_HEADER,
    payload: {key, value},
  });
};
const reset = () => dispatch => dispatch({type: documentationConstants.RESET_OBJECTS});

const generateResponse = (request) => async dispatch => {
  if (request.method === "" || request.requestObjectString === "") return dispatch({
    type: documentationConstants.SET_RESPONSE,
    payload: {message: "please select an endpoint"}
  });
  try {
    const {documentation} = store.getState();
    // const obj = JSON.parse(`{${request.requestObjectString}}`);
    const docResponse = await docServices.generateDocResponse({
      ...request,
      body: request.tempRequestObject,
      headers: documentation.headers
    });
    return dispatch({
      type: documentationConstants.SET_RESPONSE,
      payload: {...docResponse}
    });
  } catch (e) {
    return dispatch({
      type: documentationConstants.SET_RESPONSE,
      payload: {error: true, message: e.message}
    });
  }
};

const getDocEndpoints = () => async dispatch => {
  const response = await docServices.getDocEndpoints();
  if (Array.isArray(response)) return dispatch({
    type: documentationConstants.GET_DOCUMENTATION_ENDPOINTS,
    payload: response
  });
  if (response.message === "Forbidden") return dispatch({type: documentationConstants.SET_FORBIDDEN_DOC});
};

export default {
  getDocEndpoints,
  setRequestObject,
  generateResponse,
  handleHeaderChange,
  reset
}