import { objLib } from '@xc-core/lib';

const tryAddFemaleDisorderAndNumberOfCigarette = (obj: IObject,
  models: IObject, fields: IObject): IObject => {
  let updatedModels = models;
  const smoking = objLib.getValueWithLodash(obj, fields.quotation.smokingCigarette.id);
  const isSmoking = smoking && String(smoking).toLocaleLowerCase() === 'yes';
  const gender = objLib.getValueWithLodash(obj, fields.quotation.gender.id);
  const isFemale = gender && String(gender).toLocaleLowerCase() === 'female';

  if (isFemale) {
    updatedModels = {
      ...updatedModels,
      detailsHealthQuestionnaireModel: [ /** adding femaleDisorder to model */
        ...updatedModels.detailsHealthQuestionnaireModel,
        fields.quotation.femaleDisorder],
    };
  }

  updatedModels = {
    ...updatedModels,
    detailsHealthQuestionnaireModel: [ /** adding smokingCigarette to the model */
      ...updatedModels.detailsHealthQuestionnaireModel,
      fields.quotation.smokingCigarette],
  };

  if (isSmoking) {
    updatedModels = {
      ...updatedModels,
      detailsHealthQuestionnaireModel: [ /** adding numberOfCigarette to the model */
        ...updatedModels.detailsHealthQuestionnaireModel,
        fields.quotation.numberOfCigarette],
    };
  }
  return updatedModels;
};

const applyQuestionNumber = (model: IObject): IObject => {
  let updatedModel = model;
  const questionsWithNumber = updatedModel.detailsHealthQuestionnaireModel.map((q: IObject,
    index: number) => ({
    ...q,
    label: `${index + 1}. ${q.label}`,
  }));

  updatedModel = { ...updatedModel, detailsHealthQuestionnaireModel: questionsWithNumber };
  return updatedModel;
};

const util = { tryAddFemaleDisorderAndNumberOfCigarette, applyQuestionNumber };

export default util;
