import mapper from './product-config-map.json';

import alfalahWallet from './alfalahWallet.json';
import flexiPa from './flexiPa.json';

const productsConfigFiles = { alfalahWallet , flexiPa };

export {
  mapper,
  productsConfigFiles,
};
