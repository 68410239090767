import lib from "../../../utils/lib";

const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    total: { id: 'properties.Price.Total', name: 'Total Price', config: {} },
    config: {},
  },
  body: {
    items: [
      {
        id: 0,
        title: { id: 'Price Breakdown', name: 'Price Breakdown' },
        items: [
          { id: 'properties.NCD', name: 'NCD', config: { currency: false,type:'percent'  } },
          { id: 'properties.Price.Main Policy', name: 'Net Basic Premium', config: { currency: true } },
          { id: 'properties.Price.SST', name: 'SST 6%', config: { currency: true } },
          { id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: { currency: true } },
        ],
        config: {},
      },
      // {
      //   id: 1,
      //   name: 'Commission Breakdown',
      //   // items: [
      //   //   // {
      //   //   //   current: { id: 'properties.Commission Amount', name: 'Commission Amount', config: { currency: true } },
      //   //   //   prev: { id: 'properties.Previous Commission', name: 'Commission Amount', config: { currency: true, className: 'cross-line light-gray' } },
      //   //   //   config: { currency: true },
      //   //   // },
      //   //   { id: '', name: 'Commission Amount', config: {} },
      //   // ],
      //   config: {},
      // },
      {
        id: 2,
        title: { id: 'Other Details', name: 'Other Details' },
        items: [
          { id: 'properties.Sum Assured', name: 'Sum Assured', config: {currency: true} },
        ],
        config: {},
      }
    ],
  },
};

export default model;
