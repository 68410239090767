import {SystemConfigService} from '@xc-core/services';
import BaseAction from './base';
import actionTypes from '@redux/actionTypes'
import Notifications from 'react-notification-system-redux';
import { getNotificationMessage } from '../../_services';
import {objLib} from '@xc-core/lib';

class SystemConfigAction extends BaseAction{
    protected createScope: string = '';
    protected editScope: string = '';
    
    protected service: SystemConfigService;
    protected actions = actionTypes.systemConfig;

    constructor() {
        super();
        this.service = new SystemConfigService();
      }

    public getSystemConfigs = () => async (dispatch: Function) => {
        const result = await this.service.getSystemConfigs();
        return dispatch(this.createAction(this.actions.GET_SYSTEM_CONFIGS, { ...result }));
    }

    public updateSystemConfigs = (newConfigs: IObject) => async (dispatch: Function) =>{
        dispatch(this.createAction(this.actions.UPDATE_SYSTEM_CONFIGS, { ...newConfigs }));
    }

    public saveSystemConfigs = (newConfigs: IObject,toMerge:IObject) => async (dispatch: Function)=>{
      try{
        const result = await this.service.updateSystemConfigs(newConfigs);
        // @ts-ignore
        dispatch(Notifications.success(getNotificationMessage('Saved successfully.')));
        dispatch(this.createAction(this.actions.SAVE_SYSTEM_CONFIGS, { ...objLib.deepMergeWithLodash(result,toMerge) }));  
      } catch (e){
        // @ts-ignore
        dispatch(Notifications.error(getNotificationMessage('Error saving settings')));
        throw new Error(e);
      }
       
    }


    // reset system config to main, when user navigate away from the module
    // public resetSystemConfigs = () => (dispatch: Function) =>{
    //   dispatch(this.createAction(this.actions.RESET_SYSTEM_CONFIGS));
    // }
} 



export default new SystemConfigAction();