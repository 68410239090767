import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import { config, formatCurrency } from 'utils';

const PlanSelection = props => {
  
    const {classes, fields, collections, priceList} = props;  
    const getPriceObj = type => {
      return priceList.find(price => {
        return price['Plan Type'] === type
      });
    }
    return(
        <Grid container spacing={24}>
        {collections.plan.map((plan, key ) => {
          const priceObj = getPriceObj(plan.name);
          return (
            <Grid item xs={12} md={4} key={key}>
              <Card
                className={`
                  ${classes.card}
                  ${fields.plan === plan.name ? classes.cardActive : ""}
                `}
              >
                <CardActionArea
                  onClick={() => props.handlePlanChange("planSelection", {id:"plan", name: plan.name, priceObj: priceObj})}
                  data-set={1}
                  type="button"
                  autoFocus = {fields.plan === plan.name ? true : false}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                        color: "#064e8c"
                      }}
                    >
                      <i
                      className={
                        fields.plan === plan.name
                            ? "icon-checkedOutline font-size-normal"
                            : ""
                        }
                      />
                    </div>
                    <CardContent
                      className={"d-flex flex-column p-4"}
                    >
                      <h5>
                        {plan.name}
                      </h5>
                      <h3 className="text-primary font-weight-bold">
												{`${config.currencySymbol} ${formatCurrency(priceObj['Total'])} `}
                      </h3>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      );
}

export default PlanSelection;