import React from 'react';
import Search from '@xc-core/components/core/table/search';

const SearchCollections = (props:IObject) => {
    const{ children, searchKey, setSearchKey} = props;
    return (
        <Search
            classes='border-gray-top border-gray-left border-gray-right'
            keyword={searchKey}
            onKeywordChange={(pair:IPair)=> setSearchKey(pair.value)}
            >
            {children}
        </Search>
    )
}

export default SearchCollections;