import React from 'react';
import { BaseComponent } from '@xc-core/components';
import { filterLib } from '@xc-core/lib';
import SelectedFiltersChip from './selectedFiltersChip';
import FilterButton from './filterButton';
import FilterFooter from './filterFooter';
import FilterCard from './filterCard';
import FilterBody from './filterBody';
import SelectedFiltersCol from './selectedFiltersCol';
import FilterValuesCol from './filterValuesCol';
import FilterTypesCol from './filterTypesCol';

interface IProps {
  filterOptions: any,
  applyFilter: Function,
  onCheckChange: Function,
  cancelFilter: Function,
  isLoading: boolean,
}
interface IState {
  isCardVisible: boolean,
  activeIndex: number,
  searchKeyword: string,
}

class Filter extends BaseComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isCardVisible: false,
      activeIndex: 0,
      searchKeyword: '',
    };
  }

  setActiveIndex = (index: number) => {
    this.setState({ activeIndex: index });
  };

  onSearchKeywordChange = (e: any) => {
    const value = e && e.target && e.target.value;
    this.setState({ searchKeyword: value });
  };

  onAddFilterClicked = () => {
    this.setState({
      isCardVisible: true,
    });
  }

  onCancelClick = () => {
    const { cancelFilter } = this.props;
    this.setState({
      isCardVisible: false,
      searchKeyword: '',
      activeIndex: 0,
    });
    cancelFilter();
  }

  onApplyClick = () => {
    const { applyFilter, filterOptions } = this.props;
    this.setState({ isCardVisible: false, searchKeyword: '', activeIndex: 0 });
    applyFilter(filterOptions);
  }

  getFilterTypeForIndex = (index: number) => {
    const { filterOptions } = this.props;
    if (!filterOptions || !filterOptions.length) return [];
    if (index >= filterOptions.length) {
      this.setActiveIndex(0);
      return filterOptions[0] || [];
    }
    return filterOptions[index] || [];
  }

  getFilterIndexById = (id: any) => {
    const { filterOptions } = this.props;
    return filterOptions.findIndex((filter: any) => filter.id === id);
  }

  removeChipByFilterId = (filterId: any) => {
    const { filterOptions, applyFilter } = this.props;
    const index = this.getFilterIndexById(filterId);
    const selectedFilter = filterOptions[index];
    const clearSelectedFilterList = selectedFilter.list.map((option: any) => {
      const newOption = { ...option, isChecked: false };
      if (selectedFilter.type === filterLib.dateRangeType) {
        newOption.value = '';
      }
      return newOption;
    });
    const clearSelectedFilter = {
      ...selectedFilter,
      list: clearSelectedFilterList,
      checkedCount: 0,
    };
    const newFilterOptions = [
      ...filterOptions.slice(0, index),
      { ...clearSelectedFilter },
      ...filterOptions.slice(index + 1),
    ];
    applyFilter(newFilterOptions);
  };

  onDateRangeChange = (startDate: any, endDate: any) => {
    const { activeIndex } = this.state;
    const { filterOptions, onCheckChange } = this.props;

    const filterToChange = filterOptions[activeIndex];
    let checkedCount = 0;
    const newValues = filterToChange.list.map((value: any) => {
      const newValue = value;
      if (newValue.code === 'startDate') {
        newValue.value = startDate;
        newValue.isChecked = true;
      }
      if (newValue.code === 'endDate') {
        newValue.value = endDate;
        newValue.isChecked = true;
      }
      return newValue;
    });
    newValues.forEach((value: any) => {
      if (value.isChecked) checkedCount += 1;
    });
    const changedOption = { ...filterToChange, checkedCount, list: newValues };
    const newFilterOptions = this.getUpdatedOptionsArray(activeIndex, changedOption);
    onCheckChange(newFilterOptions);
  };

  onValueCheckChange = (changedValue: any) => {
    const { filterOptions, onCheckChange } = this.props;
    const { activeIndex } = this.state;
    const changedIndex = activeIndex;

    const filterToChange = filterOptions[changedIndex];
    let checkedCount = 0;
    const newValues = filterToChange.list.map((value: any) => {
      if (value.code === changedValue.code) return changedValue;
      return value;
    });
    newValues.forEach((value: any) => {
      if (value.isChecked) checkedCount += 1;
    });
    const changedOption = { ...filterToChange, checkedCount, list: newValues };
    const newFilterOptions = this.getUpdatedOptionsArray(changedIndex, changedOption);
    onCheckChange(newFilterOptions);
  }

  getUpdatedOptionsArray = (changedIndex: number, changedOption: any) => {
    const { filterOptions } = this.props;
    return [
      ...filterOptions.slice(0, changedIndex),
      { ...changedOption },
      ...filterOptions.slice(changedIndex + 1),
    ];
  };

  render() {
    const {
      isCardVisible,
      activeIndex,
      searchKeyword,
    } = this.state;
    const { filterOptions, isLoading } = this.props;
    const activeFilter = this.getFilterTypeForIndex(activeIndex);
    return (
      <>
        <div data-testid="filter-containter" id='filterModal' className='d-flex align-items-center flex-wrap'>
          <FilterButton onClick={this.onAddFilterClicked} />
          <SelectedFiltersChip
            filters={filterOptions}
            removeChip={(filterId: any) => this.removeChipByFilterId(filterId)}
          />
        </div>
        <FilterCard
          isVisible={isCardVisible}
          isLoading={isLoading}
        >
          <FilterBody>
            <FilterTypesCol
              filterOptions={filterOptions}
              activeIndex={activeIndex}
              setActiveIndex={this.setActiveIndex}
              getFilterIndexById={(id: any) => this.getFilterIndexById(id)}
            />
            <FilterValuesCol
              values={activeFilter?.list || []}
              valuesType={activeFilter?.type || ''}
              searchKeyword={searchKeyword}
              onSearchKeywordChange={(e: any) => this.onSearchKeywordChange(e)}
              onValueCheckChange={(value: any) => this.onValueCheckChange(value)}
              onDateRangeChange={(startDate: string, endDate: string) => (
                this.onDateRangeChange(startDate, endDate)
              )}
            />
            <SelectedFiltersCol
              filters={filterOptions}
            />
          </FilterBody>
          <FilterFooter
            onApplyClick={() => this.onApplyClick()}
            onCancelClick={this.onCancelClick}
          />
        </FilterCard>
      </>
    );
  }
}

export default Filter;
