import field from "../../../@xc-core/lib/field";
import { dobRanges} from 'utils';
import {dateLib} from "../../../@xc-core/lib";

const model = {
    header: {
        productName: {id: 'productName', name: '', config: {}},
        plan: {id: 'properties.Plan Type', name: '', config: {}},
        items: [
            {
                current: {id: 'properties.Price.Total', name: '', config: {currency: true}},
                prev: {
                    id: 'properties.Previous Price.Total',
                    name: '',
                    config: {currency: true, className: 'cross-line gray bold'}
                },
                config: {},
            },
        ],
        config: {},
    },
    body: {
        items: [
            {
                id: 'Price Breakdown',
                name: 'Price Breakdown',
                items: [
                    {
                        current: {id: 'properties.Price.Main Policy', name: 'Gross Premium', config: {currency: true}},
                        prev: {
                            id: 'properties.Previous Price.Main Policy',
                            name: 'Gross Premium',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },

                    {
                        current: {
                            id: 'properties.Price.Admin Surcharge',
                            name: 'Admin Surcharge',
                            config: {currency: true}
                        },
                        prev: {
                            id: 'properties.Previous Price.Admin Surcharge',
                            name: 'Admin Surcharge',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {
                            id: 'properties.Price.Federal Excise Duty',
                            name: 'Federal Excise Duty',
                            config: {currency: true}
                        },
                        prev: {
                            id: 'properties.Previous Price.Federal Excise Duty',
                            name: 'Federal Excise Duty',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {
                            id: 'properties.Price.Fixed Insurance Fee',
                            name: 'Fixed Insurance Fee',
                            config: {currency: true}
                        },
                        prev: {
                            id: 'properties.Previous Price.Fixed Insurance Fee',
                            name: 'Fixed Insurance Fee',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: {currency: true}},
                        prev: {
                            id: 'properties.Previous Price.Stamp Duty',
                            name: 'Stamp Duty',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                    {
                        current: {
                            id: 'properties.Price.Withholding Tax',
                            name: 'Withholding Tax',
                            config: {currency: true}
                        },
                        prev: {
                            id: 'properties.Previous Price.Withholding Tax',
                            name: 'Withholding Tax',
                            config: {currency: true, className: 'cross-line light-gray'}
                        },
                        config: {currency: true},
                    },
                ],
                config: {},
            },
            {
                id: 'Other Details',
                name: 'Other Details',
                items: [
                    {
                        current: {
                            id: 'properties.Plan Type',
                            name: 'Plan Type',
                            type: 'Select',
                            collectionId: 'plan',
                            disabled: field.defaultDisabled,
                            handler: field.defaultHandler,
                            config: {isEditable: true}
                        },
                        prev: {config: {}},
                        config: {}
                    },
                  {
                    current: {
                      id: 'properties.DOB',
                      name: 'Date of Birth',
                      type: 'DatePicker',
                      getMax:()=>dateLib.getDOBRange(dobRanges.minAge),
                      getMin:()=>dateLib.getDOBRange(dobRanges.maxAge),
                      disabled: field.defaultDisabled,
                      handler: field.defaultHandler,
                      config: {isEditable: true}
                    },
                    prev: {config: {}},
                    config: {}
                  },
                  {current: {id: 'properties.Age', name: 'Age', config: {}}, prev: {config: {}}, config: {}},
                  {
                    current: {
                      id: 'properties.Payment Type',
                      name: 'Payment Type',
                      type: 'Select',
                      collectionId: 'paymentType',
                      disabled: field.defaultDisabled,
                      handler: field.defaultHandler,
                      config: {isEditable: true}
                    },
                    prev: {config: {}},
                    config: {}
                  }
                ],
                config: {},
            },
        ],
    },
};

export default model;
