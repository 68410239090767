import React,{Fragment} from "react";
import { connect } from "react-redux";
import CircularButton from "./CircularButton";
// import DateFormat from "./DateFormat";
import PropTypes from "prop-types";
import { formatDate } from '../utils/index';
import { utils } from "utils";

const RenderRow = (props) => {
  const { 
    index, goToDetails, data, className, isChild,
    heading, printContent, onPressButton, arrowIcon, toggleArrow
  } = props;

  return(
    <Fragment>
    <tr key={index} className={className}
     data-testid="table-body-tr"
    onClick={() => goToDetails && goToDetails(data)}>
      <td onClick={(e)=> toggleArrow && toggleArrow(e)}>
        <i className= {arrowIcon}></i>
      </td>
    {heading.map((heading, headingIndex) => {
      if (heading.key) {
        const subs = heading.subKey
        if (subs === undefined || (subs && data[heading.key]) ) {
          return (
            <td key={headingIndex} className={`${ isChild && headingIndex === 0 ? 'pl-4' : ''} ${heading.classNames ? heading.classNames : ''}`}>
              { printContent(data, heading) }
            </td>
          );
        } else {
          return (
            <td key={headingIndex}>-</td>
          );
        }
      } else {
        return (
          <td
            key={headingIndex}
            className="actionIcon text-right"
          >
            {heading.icons.map((icon, index) => {
              return (
                <CircularButton
                  press={()=> onPressButton(icon['actionName'])}
                  key={index}
                  iconName={icon.name}
                  id={(data[icon.id] || '').toString()}
                />
              );
            })}
          </td>
        );
      }
    })}
  </tr>
  { data.validationErrors && 
    <tr>
      <td colSpan={heading.length + 1} className="text-danger">
        <ul>{data.validationErrors.map((item) => <li>{item}</li>)}</ul>
      </td>
    </tr> 
  }
  </Fragment>
  );
}
class TablesUiComponent extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      arrowIcons: [],
      selectedPage: this.props.selectedPage,
    }

  }

  static getDerivedStateFromProps(props, state) {
    if(props.selectedPage !== state.selectedPage) {
      /**initiate the arrowIcons for other page */
      return {arrowIcons: [], selectedPage: props.selectedPage};
    } else {
      return null;
    }
  }
  unimplementedActionIcons = () => alert('unimplemented');

  toggleArrow = (e, index) => {

    let arrowIcons = this.state.arrowIcons;
    if(!arrowIcons[index])
      arrowIcons[index] = 'icon-rightArrow'; /** default class */

    arrowIcons[index] = arrowIcons[index] === 'icon-rightArrow' ? 'icon-downArrow' : 'icon-rightArrow';
    this.setState({arrowIcons});
    e.stopPropagation();
  }

  printContent = (data, heading) => {
    let subs = heading.subKey ? heading.subKey.split(',') : heading.subKey;
    let content = '-';
    if (subs && subs.length === 1) {
      content = data[heading.key][subs]
    } else if (subs || utils.lib.isArray(content)) {
      let tempContent = data[heading.key];
      subs.forEach((sub) => { if(tempContent) tempContent = tempContent[sub.trim()] });
      content = tempContent;
    } else if (![null, undefined].includes(data[heading.key]) && typeof(data[heading.key].valueOf()) === "boolean") {
      content = data[heading.key] ? "Active" : "Inactive"
    } else if (heading.enum) {
      if (data.state === "Completed") content = heading.enum.Completed;
      else content = heading.enum.Incomplete;
    } else if (data[heading.key]) {
      content = data[heading.key]
    }
    
    /** print the content */
    // if( content !== null && (typeof content === 'object' || typeof content === 'function')){
    //   // return 'bad type';
    //   return '';
    // }
    // else 
    
    return this.printByFormat(content, heading.format);
  }

  printByFormat = (data, format, options={}) => {
    switch(format) {
      case 'date':
        // return ( <DateFormat date={data} options={options} /> );
        return formatDate({date: data, options: options});
      case 'currency':
        return data;
      default:
        return data;
    }
  }

  hasChildren = (item) => {
    return (item.properties && item.children 
      && item.children.length > 0) === true;
  }

  render() {
    const { heading, children, tableData, goToDetails,
      loading, hasRecords,
      image,
      tableClasses,
      // noCard,
      // borderRadius
      horizontalScroll,
      openTop

    } = this.props;
    return (
      <div
        className={`card shadow-sm overflow-visible
        ${children ? "additionalHeader" : ""} ${horizontalScroll ? " overflow-x-auto" : ""}
        ${openTop ? ' top-right-radius-0 top-left-radius-0' : ''}`}
        //         ${noCard ? "" : "card customShadow"}
        style={{
          minHeight: "auto"
        }}
      >
        {tableData.length <= 0 && loading ? (
          <div className={"loader d-block"} />
        ) : (
          <Fragment>
              <div className="card-header">
                {children &&
                  <Fragment>
                  {children}
                  </Fragment>
                }
                <div className="card-body p-0" data-testid="table-data">
                  <table id="mainTable" className={`
                  table overflow-visible ${tableClasses}
                  `}>

                    {/* ${tableClasses ? tableClasses : ""} */}
                  {/* ${borderRadius ? "borderRadius" : ""} */}
                  {/* `}> */}
                    <thead className="tableHeader">
                      <tr data-testid="table-header-tr">
                        <th></th>{/* empty header cell for arrow icon */}
                        {heading.map((data, index) => (
                          <th key={index} scope="col" style={data.style}>
                            {data.headerName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData && tableData.length > 0 ?
                        (
                          tableData.map((data, index) => {
                            return (
                            <Fragment key={data.id ? data.id : index}>
                              <RenderRow
                                index={index}
                                data={data}
                                goToDetails={goToDetails}
                                printContent={this.printContent}
                                toggleArrow={(e) => this.toggleArrow(e, index)}
                                heading={heading}
                                onPressButton={(actionName)=> this.props[actionName] || this.unimplementedActionIcons}
                                /**check of has child */
                                arrowIcon={`${this.hasChildren(data) ? this.state.arrowIcons[index] || 'icon-rightArrow' : ''}`}
                              />
                               {/** its children */}
                               { this.hasChildren(data) ? data.children.map((child, childIndex) => {
                                  return <RenderRow
                                  className={`child-bg ${this.state.arrowIcons[index] === 'icon-downArrow' ? '' : 'd-none'}`}
                                  index={childIndex}
                                  data={child}
                                  isChild={true}
                                  goToDetails={goToDetails}
                                  printContent={this.printContent}
                                  heading={heading}
                                  key={child.id ? child.id : childIndex}
                                  onPressButton={(actionName)=> this.props[actionName] || this.unimplementedActionIcons}
                                />
                               }) : <></>}
                              </Fragment>
                            )
                          })
                        ) : <tr key={Math.random().toString()}>
                          <td colSpan={heading.length} className="">
                            <div className="empty-state">
                                {image && <img src={image} alt="no data" className="center"/>}
                                <small className="text-lg-center">
                                  {
                                    hasRecords && tableData && tableData.length === 0 ?
                                      <p>No results match. Please try again.</p> :
                                      <div>
                                        <p className="m-0 text-left empty-state-p">No records yet.</p>
                                        <p className="m-0 text-left empty-state-p">Start creating one and it will show up here.</p>
                                      </div>
                                  }
                                </small>
                            </div>
                          </td>
                        </tr>
                      }
                      {tableData === undefined && <tr />}
                    </tbody>
                  </table>
                </div>
              </div>
          </Fragment>
        )}
      </div>
    );
  }
}

TablesUiComponent.propTypes = {
  openTop: PropTypes.bool,
  horizontalScroll: PropTypes.bool,
  heading: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  noCard: PropTypes.bool,
  tableClasses: PropTypes.string,
  borderRadius: PropTypes.bool,
  // not all props are described here. these are just a small portion of all the props
};

export const TablesUi = connect((state) => ({
  checkedFilterFieldCount: state.checkedFilterFieldCount
}))(TablesUiComponent);
