
import dashboardService from '../services';
import { DashboardConsts } from '../constants';

const getDashboard = () => dispatch => {
    dashboardService.fetchDashboard().then(data => {
        return dispatch(
            {
                type: DashboardConsts.GET_DASHBOARD,
                payload: data
            });
    });       
}

export default { getDashboard };