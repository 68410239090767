import React from 'react';
import {Checkbox} from "../../../@xc-core/components/core";

interface IProps{
    objModels:tObjModel ,
    model:IObject,
    item:IObject,
    itemPropertiesKey:string
}

const DetailsComponent =(props:IProps)=>{
    const {objModels,model,item,itemPropertiesKey} = props
    return <>
        {
            objModels.map((val, index:number)=> {
                const field=model.fields[typeof val === 'string' ? val :val.keyName];
                const label = field.label;
                const type = field.type
                return  type === 'Checkbox' ?
                    <div className={'summary-label'} style={{display:'flex', marginTop:5}} key={index}>
                        <Checkbox
                            id={`${index}`}
                            checked={item.properties[itemPropertiesKey]['Vehicle details verified'] === 'Yes'}
                            name={label}
                            handler={()=>{}}
                            />
                        {label}
                    </div>
                    :
                    <ul key={index} className='list-group'>
                        <li className='list-group-item d-flex justify-content-between border-0 px-0 py-1 grey-bg summary-label'>
                            {label}
                            <span className='col-6 p-0 wordBreak summary-label-2'>{item.properties[itemPropertiesKey][label]}</span>
                        </li>
                    </ul>
            })
        }
    </>
}

export default DetailsComponent;