import env from 'env';
import Request from './request';
import Response from './response';
import BaseListingService from './baseListing';

class UserService extends BaseListingService {
  protected env = env.tsd;

  protected api = 'identity/users';

  protected withApi = 'with-associations';

  public login = async (username: string, password: string): Promise<void> => {
    const url = `${this.env}/${this.api}/login`;
    const body = { username, password };
    const request = new Request(body, 'POST');
    const response = new Response(url, request);
    await response.handle();
  }

  public logout = async (): Promise<void> => {
    const url = `${this.env}/${this.api}/logout`;
    const request = new Request(null, 'POST');
    const response = new Response(url, request);
    await response.handle();
  }

  public getList = async (params: string): Promise<any> => {
    const url = `${this.env}/${this.api}/search/${this.withApi}?${params}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public create = async (body: object): Promise<void> => {
    const url = `${this.env}/${this.api}/${this.withApi}`;
    const request = new Request(body, 'POST');
    const response = new Response(url, request);
    await response.handle();
  }
}

export default UserService;
