import config from '@xc-core/config';
import dateLib from './date';

const dateRangeType = 'dateRange';

const prepareDateParams = (list: IFilterListItem[]) => {
  const start = dateLib.applyFormatFromTo(list && list[0].value ? list[0].value : '',
    config.dateMomentFormat, config.dateRequestParamFormat);
  const end = dateLib.applyFormatFromTo(list && list[1].value ? list[1].value : '',
    config.dateMomentFormat, config.dateRequestParamFormat);
  return `${start},${end}`;
};

const prepareFilterList = (type: string, list: IFilterListItem[],
  params: string[]): IFilterListItem[] => (
  list.map((item: IFilterListItem) => {
    const listItem = {
      ...item,
      id: item.code,
      isChecked: params ? params.includes(item.code) : false,
    };
    if (type === dateRangeType) listItem.value = '';
    return listItem;
  })
);

const getCheckedCount = (item: any, params: string[]): number => {
  if (!params) return 0;
  if (item.type === dateRangeType) return 1;
  return params.length;
};

/** code is needed because the filter object is not unified among all */
const parse = (source: IListingFilter[], params: IRequestParam): IListingFilter[] => (
  source.map((filter: any) => {
    const parsedFilterList = prepareFilterList(filter.type, filter.list, params[filter.id]);
    const parsedFilter = {
      ...filter,
      list: parsedFilterList,
      checkedCount: getCheckedCount(filter, params[filter.id]),
      name: filter.displayName,
      code: filter.filterProperty,
    };
    return parsedFilter as IListingFilter;
  })
);

const getChecked = (filters: IListingFilter[]): IPair[] => {
  const pairs:IPair[] = [];
  filters.forEach((filter: IListingFilter) => {
    if (filter.checkedCount > 0) {
      if (filter.type === dateRangeType) {
        pairs.push({ id: filter.id, value: prepareDateParams(filter.list) });
      } else {
        filter.list.forEach((f: IFilterListItem) => {
          if (f.isChecked) {
            pairs.push({
              id: filter.code,
              value: filter.type === dateRangeType ? f.value : f.code,
            } as IPair);
          }
        });
      }
    }
  });
  return pairs;
};

const getRequestParamsChecked = (filters: IListingFilter[]): IRequestParam => {
  const params: IRequestParam = {};
  const checkedFilters = getChecked(filters);
  checkedFilters.forEach((checked:IPair) => {
    if (params[checked.id]) {
      params[checked.id].push(checked.value);
    } else {
      params[checked.id] = [checked.value];
    }
  });
  return params;
};

const applyTodayCreatedOn = (filters: IListingFilter[]): IListingFilter[] => {
  const today = dateLib.getCurrentDate(config.dateMomentFormat);
  const checkedFilters = filters.map((filter: IListingFilter) => {
    if (filter.id === 'createdOn') {
      return {
        ...filter,
        list: [
          { ...filter.list[0], value: today, isChecked: true },
          { ...filter.list[1], value: today, isChecked: true },
        ],
        checkedCount: 2,
      };
    }
    return filter;
  });
  return checkedFilters;
};

const filter = {
  parse,
  getRequestParamsChecked,
  dateRangeType,
  applyTodayCreatedOn,
};

export default filter;
