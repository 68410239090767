import React, { useEffect, useRef, useState} from 'react';
import * as reactstrap from 'reactstrap';
import { eventLib } from '@xc-core/lib';

const CustomInput = (props: IInputFormControlModel) => {
  const {
    className, id, validator, value, name, placeholder,
    disabled, autoFocus, rules, customMessage, handler,
    validationMessage, validationData, validationName,validationRules
  } = props;
  // const {showMessage} = validator;
  const inputRef = useRef(null);
  const currentStartPosition = useRef(null);
  const currentEndPosition = useRef(null);
  const [,forceUpdate] = useState(0);

  
  const onChange = (e: any) => {
    const pair = eventLib.getPair(e);
    inputRef.current = e.target;
    currentStartPosition.current = e.target.selectionStart;
    currentEndPosition.current = e.target.selectionEnd;
    handler(pair);

  };

  useEffect(()=>{
    if(inputRef.current){
    // @ts-ignore
    inputRef.current.setSelectionRange(currentStartPosition.current,currentEndPosition.current)

    }
  },[value])

  useEffect(()=>{
    if(validator?.showMessage){
      forceUpdate(1)
    }
  },[validator?.showMessage])


  return (
    <>
      <reactstrap.Input
        className={`xc-input ${className}`}
        id={id}
        value={value}
        onChange={(e: any) => onChange(e)}
        type='text'
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {/* {
        validator
          ? validator.validateFieldAndGetMessage({
            name,
            value,
            rules: rules || [],
            customMessage,
          }) : ''
      } */}
      
      {validator 
        ? <div>{validator.message(validationName ? validationName : name,
          validationData ? validationData : value,
          validationRules ? validationRules : "") }</div>
        : ''}
      {
        validationMessage ? <div className='text-danger-step'>{validationMessage}</div> : <></>
      }
    </>
  );
};

export default CustomInput;
