
import { AuditLogService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import {
  requestLib,
  filterLib,
  listLib,
} from '@xc-core/lib';
import BaseListAction from './baseListing';

class AuditLogAction extends BaseListAction {
  constructor() {
    super();
    this.service = new AuditLogService();
  }

  protected actions = actionTypes.auditLog;

  protected service: AuditLogService;

  protected csvExportScope = 'Export Certificates to CSV';

  protected createScope = '';

  protected editScope = '';

  private defaultFields = [
    { id: 'logTime', displayName: 'Date & Time', type: 'datetime' },
    { id: 'icon', displayName: 'Type', type: 'icon' },
    { id: 'userFullName', displayName: 'User', type: 'string' },
    { id: 'serviceNameFull', displayName: 'Service', type: 'string' },
    { id: 'userAction', displayName: 'Action', type: 'string' },
    { id: 'sourceIP', displayName: 'Source IP', type: 'string' },
  ];

  private addIconToItem = (item: IObject) => {
    let icon: string;
    if (item.actionResult === 'SUCCESS') {
      icon = '<i class="icon-checkedOutline text-success d-block font-size-normal"/>';
    } else icon = '<i class="icon-warning text-danger  d-block font-size-normal"/>';
    return { ...item, icon };
  }

  private addIconsToList = (auditList: IObject[]) => auditList.map(this.addIconToItem)

  public setList = (params: IRequestParam) => async (dispatch: any, getState: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      const { filters } = getState().auditLog;
      const stringParams = requestLib.convertToRequestParams(params);
      let result = await this.service.getList(stringParams);
      const serverFilters = await this.service.getFilters();
      const fields = listLib.convertToIdValueTypeList(this.defaultFields, ['id', 'displayName', 'type']);
      const initialFilters = filterLib.parse(serverFilters, params);
      const list = this.addIconsToList(result.content);
      result = {
        ...result,
        list: this.applyListFormat(list, fields),
        fields,
        initialFilters,
        filters: filters.length > 0 ? filters : initialFilters,
        prevFilters: filters.length > 0 ? filters : initialFilters,
        pageInfo: this.createPageInfo(result),
        searchKeyword: params.name ? params.name[0] : '',
      };
      dispatch(this.createAction<IObject>(this.actions.LIST_GET, result));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      if (error.status === 404) {
        dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }

  public getSearchParams = () => (dispatch: any, getState: any): IRequestParam => {
    const { auditLog } = getState();
    return this.getSearchParamsByItem(auditLog);
  }

  public setCurrentAuditInfo = (item: object) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.SET_CURRENT_AUDIT_INFO, item));
  }

  public setHasRecordsInitially= () => async (dispatch: any, getState: any) => {
    // const { list } = getState().policyList;
    // const hasRecords = list && list.length > 0;
    // dispatch(this.createAction<boolean>(this.actions.LIST_SET_HAS_RECORDS_INITIALLY, hasRecords));
  }

  public get = (id: string) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.ENTITY_LOADING));
    try {
      const result = await this.service.getDetail(id);
      const item = this.addIconToItem(result);
      dispatch(this.createAction<any>(this.actions.ENTITY_GET, item));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.ENTITY_SET_FORBIDDEN));
      }
      dispatch(this.createAction<IError>(this.actions.ENTITY_FAILED, error));
    }
  }
}


export default new AuditLogAction();

interface IAuditObject extends IObject{
  userAction: string,
  userAccessScope: string,
  trackId: string;
  accessedUrl: string;
  activityDuration: string;
  activityStartTime: string;
  userName: string;
  serviceName: string;
  inputJsonObject: string;
  message: string;
  errorSummary: null;
  logTime: string;
  actionResult: string;
  outputJsonObject: string;
  logReference: string;
  activityEndTime: string;
  logLevel: string;
  sourceIP: string;
  operationType: string;
  id: number;
  jsonObject: { empty: boolean },
  icon?: any,
}
