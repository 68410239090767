const main = [
  { id: 'Full Name', name: 'Full Name' },
  { id: 'Nationality', name: 'Nationality' },
  { id: 'ID Type', name: 'ID Type' },
  { id: 'ID Number', name: 'ID Number' },
  { id: 'DOB', name: 'Date of Birth' },
  { id: 'Gender', name: 'Gender' },
  { id: 'Occupation', name: 'Occupation' },
  { id: 'Address', name: 'Address' },
  { id: 'City/Town', name: 'City / Town' },
  { id: 'State/Province/Region', name: 'State / Province / Region' },
  { id: 'Country', name: 'Country' },
  { id: 'Postcode', name: 'Postcode' },
  { id: 'Email Address', name: 'Email Address' },
  { id: 'Phone Number', name: 'Phone Number' }
  
];

const beneficiary = [
  { id: 'Full Name', name: 'Full Name' },
  { id: 'Relationship', name: 'Relationship' },
  { id: 'ID Type', name: 'ID Type' },
  { id: 'ID Number', name: 'ID Number' },
  { id: 'Email Address', name: 'Email Address' },
  { id: 'Phone Number', name: 'Phone Number' },
];

const other = [
  { id: 'Full Name', name: 'Full Name' },
  { id: 'Nationality', name: 'Nationality' },
  { id: 'ID Type', name: 'ID Type' },
  { id: 'ID Number', name: 'ID Number' },
  { id: 'DOB', name: 'Date of Birth' },
  { id: 'Gender', name: 'Gender' },
  { id: 'Occupation', name: 'Occupation' },
  { id: 'Email Address', name: 'Email Address' },
  { id: 'Phone Number', name: 'Phone Number' }
];

export { main, beneficiary, other };
