import React from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PropTypes from "prop-types";
import { useToggle } from "utils";

const ExportButton = (props) => {
  const { exportToCsv } = props;
  const [isOpen, toggleOpen] = useToggle(false);
  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggleOpen}>
      <DropdownToggle caret color={'primary'}>
        Export To CSV
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => exportToCsv(true)}>All Fields</DropdownItem>
        <DropdownItem onClick={() => exportToCsv(false)}>Non-PII</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

ExportButton.propTypes = {
  exportToCsv: PropTypes.func,
};
ExportButton.defaultProps = {
  exportToCsv: () => {},
};

export default ExportButton;
