import { env } from '_config/env';
import { handleRequest, handleResponse } from '../../../_services';
import { getRequestOptions } from '../../../utils';

const { quotationUrl } = env;
// const { modelingUrl } = env;

const generateDocResponse = async ({
  body, method, host, path, headers: headersArray = [],
}) => {
  const headers = {};
  headersArray.forEach((header) => (headers[header.key] = header.value));
  const url = `${host}${path}`;
  const requestOptions = {
    method,
    headers: new Headers({ ...headers }),
    body: JSON.stringify(body),
  };
  const serverResponse = await handleRequest(url, requestOptions);
  try {
    const res = await serverResponse.json();
    return res;
  } catch (e) {
    if (e.message === 'serverResponse.json is not a function') return serverResponse;
  }
};

const getDocEndpoints = async () => {
  const url = `${quotationUrl}/products/api-documentation`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  const res = await handleResponse(serverResponse);
  return res;
};

export default {
  generateDocResponse,
  getDocEndpoints,
};
