import React from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { usersActions } from "./actions";
import { ModalUi, Filterable, Filter, Table, AddButton } from "_components";
import CreateUserForm from "./component/CreateUserForm";
import {
  decrementCheckedFieldsCount,
  incrementCheckedFieldsCount,
  loadingActions,
  resetCheckedFieldsCount,
  searchAction,
  hasPermissionTo,
} from "../../_actions";
import userImage from '../../images/emptyStateImages/users.png';
import Notifications from "react-notification-system-redux";
import { TitleComponent, FlashMessage } from "_components";
import userGraphic from "../../images/bannerGraphicImages/users.png";

const heading = [
  {
    id: 1,
    headerName: "Name",
    key: "name"
  },
  {
    id: 2,
    headerName: "Email",
    key: "email",
  },
  {
    id: 3,
    headerName: "Organisation",
    key: "orgUnit",
  },
  {
    id: 4,
    headerName: "User Group",
    key: "groups"
  },
  {
    id: 5,
    headerName: "Status",
    key: "isActive",
  },
  {
    id: "editButton",
    headerName: "",
    editButton: true,
    icons: [
      {
        name: "pencil",
        id: 'code',
        actionName: 'editPress'
      }
    ]
  }
];

class Users extends Filterable {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      hint: "",
      isGettingList: false,
      pagination: { page: 0, perPage: 10 },
      filterOptions: this.props.filterOptions,
      status: [],
    };
  }

  async componentWillMount() {
    const { filterOptions, keepFilters, resetKeepFilters } = this.props;
    if (keepFilters) {
      this.handleFilter(filterOptions);
    } else {
      this.setState({ isGettingList: true });
      await this.props.getList(0, 10);
      this.setState({ isGettingList: false });
    }
    resetKeepFilters();
  }

  componentWillReceiveProps(props) {
    this.setState({
      modal: props.modalClose,
    });

    const newProps = props;
    if (JSON.stringify(newProps.filterOptions) !== JSON.stringify(this.state.filterOptions)) {
      this.setState({ filterOptions: newProps.filterOptions });
    }

    if (props.notification === "success") {
      let notificationMessage = {
        // uid: 'once-please', // you can specify your own uid if required
        title: "User added successfully.",
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Close",
          callback: () => console.log("close")
        }
      };

      this.props.notifications(notificationMessage, "success");
      setTimeout(() => {
        this.props.removeNotification();
      }, 900);
    }
  }

  toggle = async () => {
    const canCreateUser = await this.props.hasPermissionTo("Create User");
    canCreateUser && this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  handleSubmit = async (data) => await this.props.createUser(data);

  goToDetails = async (user) => {
    const cantEditUser = await this.props.hasPermissionTo("Edit User");
    cantEditUser && this.props.history.push(`/userManagement/${user.username}`);
  };

  onFilterCheckChange = (newFilterOptions) => {
    /** change inner state */
    this.setState({
      filterOptions: newFilterOptions,
    });
  }

  cancelFilter = () => {
    /** change inner state */
    const { filterOptions } = this.props;
    this.setState({
      filterOptions,
    });
  }

  applyFilter = (newFilterOptions) => {
    /** change both inner state and props state */
    this.setState({ filterOptions: newFilterOptions });
    const resetPage = true;
    this.handleFilter(newFilterOptions, resetPage);
  }

  render() {
    const {usersList, collections,
      pagination,
      canViewList, hasRecords,
    } = this.props;

    const { filterOptions } = this.state;
    const {modal, isGettingList} = this.state;
    if (isGettingList) return <div className="loader" />;
    if (!canViewList) return (<FlashMessage flashMessage="you are not allowed to view Users." />);
    const isFilterLoading = !filterOptions || filterOptions.length === 0;
    return (
      <div className="container-fluid px-0">
        <TitleComponent
          title="Users"
          bannerGraphic={userGraphic}
        />
        <div className="row d-flex justify-content-center my_30">
          <Col sm="11" className="my-2">
            <Filter
              filterOptions={filterOptions || []}
              isLoading={isFilterLoading}
              applyFilter={(newFilterOptions) => this.applyFilter(newFilterOptions)}
              cancelFilter={() => this.cancelFilter()}
              onCheckChange={(newFilterOptions) => this.onFilterCheckChange(newFilterOptions)}
            />
          </Col>
          <div className="col-sm-11">
            <Table
              titles={heading}
              records={usersList !== undefined ? usersList : []}
              hasRecords={hasRecords}
              image={userImage}
              goToDetails={this.goToDetails}
              handleSearchKeyword={(obj) => this.handleChange('hint', obj.value)}
              searchKeyword={this.state.hint}
              handlePageClick={this.handlePagination}
              pagination={{ ...pagination, perPage: this.state.pagination.perPage }}
            >
              <AddButton
                text='Add User'
                onClick={this.toggle}
              />
            </Table>
            <ModalUi title={'Create User'} isOpen={modal} toggle={this.toggle}>
              <CreateUserForm
                groups={collections ? collections.groups : []}
                organisation={collections ? collections.orgUnits : []}
                dataScope={collections ? collections.dataScope : []}
                handleSubmit={this.handleSubmit}
                toggle={this.toggle}
                getList={this.props.getList}
              />
            </ModalUi>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    usersList, canViewList,
    hasRecords,
    collections,
    modalClose,
    filterOptions,
    notification,
    pagination,
    keepFilters,
  } = state.userManagement;
  const {
    loading, searchValue,
  } = state;
  return {
    usersList,
    canViewList,
    hasRecords,
    collections,
    filterOptions,
    modalClose,
    notification,
    loading,
    pagination,
    searchValue,
    keepFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applyFilters: (filterObject) => dispatch(usersActions.applyFilters(filterObject)),
    handleOptionChanged: (filterPayload) => dispatch(usersActions.handleOptionChanged(filterPayload)),
    getList: (page, perPage) => dispatch(usersActions.getList(page, perPage)),
    createUser: data => dispatch(usersActions.createUsers(data)),
    notifications: (message, type) => dispatch(Notifications.show(message, type)),
    removeNotification: () => dispatch(usersActions.updateNotification()),
    startLoading: () => dispatch(loadingActions.startLoading()),
    endLoading: () => dispatch(loadingActions.endLoading()),
    updateSearchValue: (value) => dispatch(searchAction(value)),
    incrementCheckedFieldsCount: () => dispatch(incrementCheckedFieldsCount()),
    decrementCheckedFieldsCount: () => dispatch(decrementCheckedFieldsCount()),
    hasPermissionTo: (scope) => dispatch(hasPermissionTo(scope)),
    resetCheckedFieldsCount: () => dispatch(resetCheckedFieldsCount()),
    resetKeepFilters: () => dispatch(usersActions.resetKeepFilters()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
