import {
	UPDATE_SEARCH_VALUE,
	END_LOADING,
	START_LOADING,
	INCREMENT_CHECKED_FIELDS_COUNT,
	DECREMENT_CHECKED_FIELDS_COUNT,
	RESET_CHECKED_FIELDS_COUNT,
	SHOW_FORBIDDEN_MESSAGE,
} from "../_constants";
import { checkPermission } from "../_services";


const startLoading = () => ({ type: START_LOADING });
const endLoading = () => ({ type: END_LOADING });
const searchAction = (value) => ({ type: UPDATE_SEARCH_VALUE, payload: value });

// Action Creators
const incrementCheckedFieldsCount = () => ({ type: INCREMENT_CHECKED_FIELDS_COUNT });
const decrementCheckedFieldsCount = () => ({ type: DECREMENT_CHECKED_FIELDS_COUNT });
const resetCheckedFieldsCount = () => ({ type: RESET_CHECKED_FIELDS_COUNT });
const showForbiddenMessage = (message) => ({ type: SHOW_FORBIDDEN_MESSAGE, payload: message });
/**
 * @function hasPermissionTo - this action creator displays the error modal:
 * when the scope given is not permitted
 * It dispatches an action to display the modal
 * @param {string} scope - the permission we want to test
 * @returns {boolean} - this object has a key of status and the value is either "premitted/denied"
 */
const hasPermissionTo = (scope, showMessage = true) => async (dispatch) => {
	const { status } = await checkPermission(scope);
	if (status === "permitted") return true;

	if(showMessage) {
		dispatch({ type: SHOW_FORBIDDEN_MESSAGE });
	}
	return false;
};

export const loadingActions = { startLoading, endLoading };
export {
	searchAction,
	incrementCheckedFieldsCount,
	decrementCheckedFieldsCount,
	resetCheckedFieldsCount,
	showForbiddenMessage,
	hasPermissionTo,
};
