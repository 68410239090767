import React from "react";
import PropTypes from "prop-types";
import { CommonInputTypes } from "./CommonInputTypes";
import { utils } from "../utils";

const ListViewType = (props) => {
  const { constants: { viewTypes } } = utils;
  const { isAllTabSelected, isFlatView, onViewTypeChange } = props;
  return (
    <div className="record-level-date-range">
      {
        isAllTabSelected
          ? (
            <CommonInputTypes
              idName="viewTypes"
              type="select"
              placeholder="View Type"
              option={viewTypes}
              value={isFlatView ? viewTypes[1].name : viewTypes[0].name}
              onChange={(e) => onViewTypeChange(e)}
            />
          ) : <></>
    }
    </div>
  );
};
ListViewType.propTypes = {
  isAllTabSelected: PropTypes.bool,
  isFlatView: PropTypes.bool,
  onViewTypeChange: PropTypes.func.isRequired,
};
ListViewType.defaultProps = {
  isAllTabSelected: true,
  isFlatView: false,
};

export default ListViewType;
