import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom'
import LoginPage from "../../LoginModule/component/LoginPage";
import ForgotPasswordPage from "../components/ForgotPasswordPage";
import ResetPasswordPage from "../components/CreatePasswordPage";

const Routes = (props) => {
  return (
    <main>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/forgot_password" component={ForgotPasswordPage} />
        <Route exact path="/password-reset" component={ResetPasswordPage} />
        <Redirect to="/" />
      </Switch>
    </main>
  )
};

export default Routes;