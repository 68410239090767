import {
	SHOW_FORBIDDEN_MESSAGE,
	HIDE_FORBIDDEN_MESSAGE,
} from "../_constants";

const initialState = {
  showForbiddenMessage: false,
  message: '',
};

export function global(state = initialState, { type, payload }) {
  switch (type) {
    case SHOW_FORBIDDEN_MESSAGE:
      return {
        ...state,
        showForbiddenMessage: true,
        message: payload ? payload : "You do not have the permission to perform this action. Please contact your System Administrator.",
      };
    case HIDE_FORBIDDEN_MESSAGE:
      return {
        ...state,
        showForbiddenMessage: false,
        message: '',
      };
    default:
      return state;
  }
}
