import React from "react";
import { objLib } from "../../lib";

const RadioButtonGroup = (props: IRadioButtonGroupFormControl) => {
  const { id, items, name, handler, value: val } = props;

  return (
    <div
      style={{
        fontWeight: 300,
        fontSize: 16,
        flexDirection: "row",
        display: "flex",
        marginTop: 5,
      }}
      className={`xc-radio-button`}
    >
      {name && <span className={"mr-4"}>{name}</span>}
      {items.map((item, index) => {
        const { value } = item;
        return (
          <div key={index} style={{ marginRight: 10 }}>
            <input
              onChange={() => {
                const pair = objLib.getPair(id, value);
                handler(pair);
              }}
              style={{ marginRight: 5 }}
              checked={val === value}
              name={value}
              id={`${value}Radio`}
              type={"radio"}
              value={value}
            />
            <label htmlFor={`${value}Radio`}>{item.label}</label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioButtonGroup;