import actionTypes from '@redux/actionTypes';
import BaseReducer from './base';

class PolicyExportReducer extends BaseReducer<IExportState> {
  public actions: IDictionary<(state: IExportState, action: IAction) => IExportState>;

  public initialState: IExportState;

  protected actionTypes: IPolicyActionTypes;

  constructor() {
    super();

    this.actionTypes = actionTypes.policy;

    this.initialState = {
      isLoading: true,
      error: null,
      file: null,
      fileName: '',
      accessIsForbidden: false,
    };

    this.actions = this.createActions();
  }

  createActions = (): IDictionary<(state: IExportState,
    action: IAction) => IExportState> => ({
    [this.actionTypes.CSV_EXPORT_LOADING]: this.setLoading,
    [this.actionTypes.CSV_EXPORT_FAILED]: this.setError,
    [this.actionTypes.CSV_EXPORT_SET_FORBIDDEN]: this.setForbidden,
    [this.actionTypes.CSV_EXPORT_GET]: this.getCsv,
  })

  protected getCsv = (state:IExportState, action: IAction):IExportState => ({
    ...state,
    isLoading: false,
    error: null,
    file: action.payload.file,
    fileName: action.payload.fileName,
  })
}
export default PolicyExportReducer;
