import {handleRequest, handleResponse} from "../../../_services";
import {store} from "../../../_helpers";
import {loadingActions} from "../../../_actions";
import {getRequestOptions} from "../../../utils";

const Config = require('_config/env');
const {apiVersion: apiUrl} =  Config.env;

async function getUsersList(page, perPage) {
  const url = `${apiUrl}/identity/users/with-associations?page=${page}&pageSize=${perPage}`;
  store.dispatch(loadingActions.startLoading());
  const usersResponse = await handleRequest(url, getRequestOptions());
  store.dispatch(loadingActions.endLoading());
  let response;
  if (usersResponse.error) {
    return usersResponse;
  } else response = await handleResponse(usersResponse) ;
  return response;
}


async function createUser(body){
  const url = `${apiUrl}/identity/users/with-associations`;
  const serverResponse = await handleRequest(url, getRequestOptions({method: "Post", body}));
  const response = await handleResponse(serverResponse);
  return response  || [];
}

async function getUsersDetails(name){
  const url = `${apiUrl}/identity/users/${name}/with-associations`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  const response = await handleResponse(serverResponse);
  return response || [];
}

async function applyFilter({stringParams}){
  const url = `${apiUrl}/identity/users/search/with-associations?${stringParams}`;
  const serverResponse = await handleRequest(url, getRequestOptions());
  const response = await handleResponse(serverResponse);
  return response || [];
}

async function updateUserDetails(name,body){
  const url = `${apiUrl}/identity/users/${name}/with-associations`;
  const serverResponse = await handleRequest(url, getRequestOptions({method: "PUT", body}));
  const response = await handleResponse(serverResponse);
  return response;
}
async function resendActivation(username){
  const url = `${apiUrl}/identity/users/${username}/reinvite`;
  const serverResponse = await handleRequest(url, getRequestOptions({method: "PUT"}));
  const response = await serverResponse.json();
  return response;
}


export const usersServices = {
  getUsersList,
  createUser,
  getUsersDetails,
  applyFilter,
  updateUserDetails,
  resendActivation,
};