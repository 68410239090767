import React, {useState,useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Button, Col} from "reactstrap";
import {actions} from "../../@redux";
import {Header,Loader,Message} from "../../@xc-core/components/core";
import type from 'history'
import bannerImg from "../../images/bannerGraphicImages/get-quote.png";
import TagInput from "../../@xc-core/components/core/tagInput";
import {uniq} from 'lodash';
import { store } from '_helpers';
import {checkPermission} from '_services'

interface IProps{
    history:type.History
}

const policyActions = new actions.PolicyActions();

const BulkCancellation=(props:IProps)=>{
    const {history} = props;
    const [policyIds , setPolicyIds] = useState<string[]>([])
    const [loading , setIsLoading] = useState<boolean>(false);
    const [isDuplicate , setIsDuplicate] = useState<boolean>(false)
    const [isRequired , setIsRequired] = useState<boolean>(false)
    const [permissionStatus, setPermissionStatus] = useState<string>()
    const dispatch= useDispatch()
    const toBeDeletePolicy:any = useSelector<IStore>(state => state.policyList.toBeCancelledPolicyList)
    const isExceedMaxPolicyCount = policyIds.length > 100;

    useEffect(()=>{
        if(localStorage.getItem('policyIds')){
            const item: string[]= JSON.parse(localStorage.getItem('policyIds') || '');
            setPolicyIds(item)
        }

        const unListen = history.listen((location, action) => {
            localStorage.removeItem('policyIds');
            localStorage.removeItem('toBedDeletedPolicies');
            dispatch(policyActions.resetToBeDeletedPolicyIds())
        });
        return unListen;
    },[])

    useEffect(()=>{
        if(!permissionStatus){
            const checkPermissionStatus = async () => {
                const {status} = await checkPermission('Bulk Cancellation') as {status:string};
                setPermissionStatus(status)
            }
            checkPermissionStatus()
        }
    },[permissionStatus])

    const errorMessages=(status:number)=>{
        switch (status){
            case 1:
                return 'does not exist'
            case 2:
                return 'You are not allowed to cancel in Effect certificates.'
            case 3:
                return 'You are not allowed to cancel Not in Effect certificates.'
            case 4:
                return 'is already cancelled'
            case 5:
                return 'is already expired'
            case 6:
                return 'You are not allowed to cancel in Effect and Not in Effect certificates.'
            default:
                return ''
        }
    }

    const bulkCancellationErrorMessageHandler=(objs:{ref_code:string; status:number}[])=>{
        return objs.filter((el)=>el.status !== 0 && el.status !== 2 && el.status !== 3).map((val)=>{
            return `${val.ref_code} ${errorMessages(val.status)}`
        })
    }

    const requestHandler= async ()=>{
        if(policyIds.length === 0){
            setIsRequired(true)
            return;
        }

        if(isExceedMaxPolicyCount){
            return ;
        }

        setIsLoading(true)
        const res:any = await dispatch(policyActions.requestBulkCancellation(policyIds))
        setIsLoading(false)
        if(res.requestId === null){
            // to open modal when having error status 2, 3, or both (In Effect , Not in Effect, and both)
            const errStatus = uniq([...res.policies]
                .filter((el)=>el.status === 2 || el.status === 3).map((el)=>el.status));
            if(errStatus.length > 1){
                return store.dispatch({
                    type: 'SHOW_FORBIDDEN_MESSAGE',
                    payload: errorMessages(6),
                });
            }

            if(errStatus.length === 1){
                return store.dispatch({
                    type: 'SHOW_FORBIDDEN_MESSAGE',
                    payload: errorMessages(errStatus[0]),
                });
            }
            return;
        }
        localStorage.setItem('toBedDeletedPolicies', JSON.stringify(res))
        localStorage.setItem('policyIds' ,JSON.stringify(policyIds))
        history.push('/bulkCancellation/previewPage')
    }

    const errorHandler=()=>{
        let errorObjs:string[] = []
        if(isRequired){
            errorObjs = [...errorObjs,'Policy Number(s) is required.']
        }

        if(isExceedMaxPolicyCount){
            errorObjs= [...errorObjs , 'Only 100 policies can be cancelled at a time']
        }

        if(!isRequired && toBeDeletePolicy?.requestId === null){
            errorObjs = [...errorObjs,...bulkCancellationErrorMessageHandler(toBeDeletePolicy.policies)]
        }

        return errorObjs
    }

    if(permissionStatus === 'denied') return <Message message={'You dont have the permission'}/>

    return <>
        <Header
            title='Bulk Cancellation'
            bannerGraphic={bannerImg}
            withBorderBottom
        />
        {loading && <Loader/>}
        {
            permissionStatus && (
                <div
                    style={{
                        margin: '1rem 0 0 5rem',width:'60%'
                    }}
                >
                    <Col className='text-regular pt-2 pb-2 pl-1 text-nowrap' sm={6}>Please specify the policy(ies) to be cancelled.</Col>
                    <TagInput
                        onChange={(value:string[],duplicateValues:string[])=>{
                            setPolicyIds(value)
                            setIsRequired(false)
                            setIsDuplicate(duplicateValues.length !== 0)
                        }
                        }
                        placeholder={'Type policy number and press enter'}
                        errorMessages={errorHandler()}
                        duplicateErrorMessage={'Please remove any duplicate policy numbers'}
                        values={policyIds}
                    />
                    <div style={{ display:'flex' , flexDirection:'row',marginTop:15, justifyContent:'flex-end'}}>
                        <div style={{width:'14em' , paddingTop:5}}>
                            <p className="customBackbutton"
                               onClick={() => history.push('/policies')}
                            >
                                <i className="icon-leftArrow font-size-normal pr-2"/>
                                <span className="customBackbuttonname">Back to Certificates</span>
                            </p>
                        </div>
                       <div style={{width:'6em'}}>
                           <Button
                               className={'btn-primary'}
                               onClick={()=>requestHandler()}
                               style={{
                                   borderRadius:5,
                                   fontSize:'16px',
                                   width:'100%'
                               }}
                               size={'sm'}
                               disabled={(isRequired && policyIds.length === 0) || isExceedMaxPolicyCount || isDuplicate || loading}
                           >
                               Next
                           </Button>
                       </div>
                    </div>
                </div>
            )
        }
    </>
}

export default BulkCancellation;