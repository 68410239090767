import React from 'react';
import {
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col
} from "reactstrap";
import {dateLib} from "../../../../@xc-core/lib";
import {CommonInputTypes} from "_components";

const BasicDetails = props => {
    const {fields, collections, maxDate, minDate, handleDob} = props;

    return(
      <div className="row my_50 justify-content-center">
        <div className="col-12 col-md-10">
          <h5 className="sub-title">Fill in basic details</h5>
          <Form>
						<FormGroup>
            	<Row>
                <Col md={6} xs="auto">
                  <Label for="dob">Date of Birth</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="terminal-icon">
                        <i className="icon-depart" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <div className="terminal-input">
                      <CommonInputTypes
                        havePrepend
                        id="1"
                        idName="dob"
												dateStyle={{ marginBottom: 0}}
                        placeholder="Select Date of Birth"
                        group={false}
												type="date"
                        maxDate={dateLib.getDOBRange(minDate)}
                        minDate={dateLib.getDOBRange(maxDate)}
												onChange={(date) => handleDob("basicDetails",date)} /** datepicker onchange */
                        value={fields.dob}
                        validator={props.validator}
                        validationRules="customRequired"
                        autoFocus={true} 
                      />
                    </div>
                  </InputGroup>
                </Col>
								<Col>
									<CommonInputTypes
										id="2"
										idName="paymentType"
										iconName="aeroplane"
										group={true}
										type="select"
										onChange={(e) => props.handleChange("basicDetails",e)}
										title="Payment Type"
										autoFocus = {true}
										option={collections.paymentType}
										value = {fields.paymentType}
                    validator={props.validator}
                    validationRules="customRequired"
									/>
								</Col>
							</Row>
						</FormGroup>
					</Form>
				</div>
			</div>
    )
}

export default BasicDetails;