import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import objLib from './obj';


const defaultConfig = {
    element:(message:string) => React.createElement('small',{className:'text-danger'},message)
}


const validator = (conf:IObject = {}):ISimpleValidator=>{
    const config = conf;
    const instance = new SimpleReactValidator(objLib.deepMergeWithLodash(defaultConfig,config))
    const message = (name:string,value:string,rules:string) => instance.message(name,value,rules)
    let showMessage = false
    const showMessages = ()=> instance.showMessages()
    const allValid = ()=> instance.allValid()
    const showMessageFor =(name:string)=> instance.showMessageFor(name)
    const getErrorMessages = ()=> instance.getErrorMessages()
    const purgeFields = ()=> instance.purgeFields()
    const hideMessages = ()=> instance.hideMessages()


    return {
        // validateFieldAndGetMessage,
        message,
        showMessages,
        allValid,
        showMessageFor,
        getErrorMessages,
        purgeFields,
        hideMessages,
        showMessage
    }
    
}


export default validator;