import product from './product';
import getQuote from './getQuote';
import quotation from './quotation';
import policy from './policy';
import user from './user';
import organization from './organization';
import userGroup from './userGroup';
import apiDoc from './apiDocumentation';
import modalMessage from './modalMessage';
import leadManagement from './leadManagement';
import auditLog from './auditLog';
import passwordManagement from './passwordManagement';
import systemConfig from './systemConfig';
import fiscalYear from './fiscalYear';
import distributionControl from './distributionControl';
import scopeManagement from './scopeManagement';

const actionTypes = {
  product,
  getQuote,
  quotation,
  policy,
  user,
  organization,
  userGroup,
  apiDoc,
  modalMessage,
  leadManagement,
  auditLog,
  passwordManagement,
  systemConfig,
  fiscalYear,
  distributionControl,
  scopeManagement
};

export default actionTypes;
