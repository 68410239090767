import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Col, Row, Input, Alert,
} from 'reactstrap';
import { actions } from '@redux';
import { Header } from '../../@xc-core/components/core/header';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import model from './model';
import { Loader } from '../../@xc-core/components/core';


const passwordManagementActions = actions.PasswordManagementActions;
const ToolTip = (props: IObject) => {
  const { content } = props;
  const [toolTipIsShown, toggleToolTip] = useState(false);
  return (
    <div className='d-inline-flex text-primary tooltip-container'>
      <i
        className='icon-info pl-2'
        onMouseEnter={() => toggleToolTip(true)}
        onMouseLeave={() => toggleToolTip(false)}
      />
      <p className={toolTipIsShown ? 'd-block' : 'd-none'} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};
const RenderPasswordFields = (props: IObject) => {
  const { model = [], handleInputChange, itemObject } = props;
  const isCheckbox = (type: string) => type === 'checkbox';
  return model.map((item: IObject) => (
    <div key={item.title}>
      <p className='font-weight-bold mt-4'>{item.title}</p>
      {item.values.map((value: IObject) => (
        <Row key={value.label}>
          <Col sm={3}>
            <p className='d-flex'>
              <span className=''>{value.label}</span>
              {value.toolTip ? <ToolTip content={value.toolTip} /> : ''}
            </p>
          </Col>
          <Col
            sm={1}
            className={`'bg-info'`}
            style={{ marginLeft: value.type === 'checkbox' ? '20px' : '' }}
          >
            <Input
              type={value.type}
              value={itemObject[value.code]}
              name={value.code}
              onChange={(e) => handleInputChange({ event: e, checkValue: value.value })}
              checked={isCheckbox(value.type) && itemObject[value.code].includes(value.value)}
            />
          </Col>
          <Col sm={2}>
            <p>{value.meta}</p>
          </Col>
        </Row>
      ))}
    </div>

  ));
};
const PassManageDetails = (props: IObject) => {
  const {
    item, handleInputChange, save, setList,
    isLoading,
  } = props;
  useEffect(() => {
    setList();
  }, []);
  if (isLoading) return <Loader />;
  return (
    <div>
      <Header
        title='Password Management'
        bannerGraphic={bannerImg}
        withBorderBottom
      />
      <div className='container-fluid px-0 normal-gray-text'>
        <Row className='d-flex justify-content-center my_30'>
          <Col sm={12} className='col-sm-12' style={{ padding: '0 4%' }}>
            {
              !item.id && <Alert color='info'>No Password config exist for now, Press save to initiate config.</Alert>
            }
            <RenderPasswordFields
              model={model}
              itemObject={item}
              handleInputChange={handleInputChange}
            />
            <button className='btn btn-primary' onClick={save}>Save</button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ passwordManagement }: IStore) => ({
  item: passwordManagement.item,
  isLoading: passwordManagement.isLoading,
});
const mapDispatchToProps = (dispatch: any) => ({
  handleInputChange: (updateItems: IObject) => dispatch(
    passwordManagementActions.setItem(updateItems),
  ),
  setList: () => dispatch(passwordManagementActions.setList()),
  save: () => dispatch(passwordManagementActions.save()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PassManageDetails);
