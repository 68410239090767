const model: IObject[] = [
    {
      title: 'General Settings',
      values: [{
        label: 'Currency Symbol to be used',
        type: 'string',
        id: 'currencySymbol',
      }],
    }
  ];
  
  export default model;
  