import env from 'env';
import BaseListingService from './baseListing';
import Request from './request';
import Response from './response';

class AuthorizationService extends BaseListingService {
  protected env = env.tsd;

  protected api = 'authorization/resources/trust';

  protected withApi = '';

  public getResourcePermission = async (params: string) : Promise<IPermissionResponse> => {
    const url = `${this.env}/${this.api}?${params}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return { status: result.data.status };
  }
}

export default AuthorizationService;
