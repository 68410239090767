const insuredTypes = [
  { id: 'Adults', type: 'Adult' },
  { id: 'Senior Citizen (66-75)', type: 'Senior Citizen 66-75' },
  { id: 'Senior Citizen (76-80)', type: 'Senior Citizen 76-80' },
  { id: 'Senior Citizen (81-85)', type: 'Senior Citizen 81-85' },
  { id: 'Children', type: 'Child' },
];


export { insuredTypes };
