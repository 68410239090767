import lib from './lib';
import moment from 'moment';
import { dateLib } from '@xc-core/lib';
import { CONFIG_DATE_MOMENT_FORMAT, utils } from 'utils';

const alfalahValidationObject = {
  className: 'text-danger-step',
  messages: {
    email: 'That is not a valid email.',
    max: 'This field may not be greater than :max:type',
    min: 'This field must be at least :min:type',
    alpha: 'This field should contain only letters.',
    numeric: 'This field should contain numbers only.',
  },
  validators: {
    customRequired: {
      message: 'Required.',
      rule: (val, params, validator) => {
        let value = val;
        if (val.validationParams) {
          value = val.value;
        }
        return !validator.helpers.isBlank(value) && !!value;
      },
      required: true,
    },
    numbersOnly: {
      message: 'Contains numeric characters only.',
      rule: (val) => (val !== '' && lib.isOnlyNumber(val)),
    },
    full_name_valid_characters: {
      message: 'This field must contain letters and certain symbols only.',
      rule: (val, params, validator) => (validator.helpers.testRegex(val,/^[a-z\-\'\@\.\ \/\\\u4E00-\u9FCC]+$/gi)
            && validator.helpers.testRegex(val, /[a-zA-Z]/gi)/** at least one alphabetical character */
      ),
    },
    alfalah_full_name_valid_characters: {
      message: 'This field must contain letters and certain symbols only.',
      rule: (val, params, validator) => (validator.helpers.testRegex(val,/^[a-z\-\'\@\.\ \/\\\u4E00-\u9FCC]+$/gi)
            && validator.helpers.testRegex(val, /[a-zA-Z]/gi)/** at least one alphabetical character */
      ),
    },
    alpha_num_passport: {
      message: 'The passport may only contain letters and numbers.',
      rule: (val, params, validator) => {
        let value = val;
        if (val && val.value) {
          value = val.value;
        }
        return validator.helpers.testRegex(value, /^[a-z0-9]+$/i);
      },
      required: true,
    },
    custom_required: {
      message: 'This field is required.',
      rule: (val, params, validator) => {
        return !validator.helpers.isBlank(val) && !!val // making sure val is truthy value
      },
      required: true
    },
    issuance_date_boudary: {
      message: `Incorrect CNIC issuance date`,
      rule: (val) => {
        const value = val.value ? val.value : val;
        const selectedDate = value ? moment(value, CONFIG_DATE_MOMENT_FORMAT).format('YYYYMMDD') : '';
        const maxDate = value ? moment(new Date()).format('YYYYMMDD') : '';
        return Number(selectedDate) <= Number(maxDate);
      },
      required: true,
    },
    numbers_only: {
      message: 'This field must contain numbers only.',
      rule: (val) => (Number.isInteger(Number(val)) && val !== ''),
    },
    validate_age_boundary: {
      message: `:attribute`,
      rule: (val) => {
        if (!val) return false;

        const selectedDate = moment(val.value,
          CONFIG_DATE_MOMENT_FORMAT).format(CONFIG_DATE_MOMENT_FORMAT);
        const { maxAge, minAge } = val.validationParams;

        const today = dateLib.getCurrentDate(CONFIG_DATE_MOMENT_FORMAT);
        let yearsDiff = dateLib.getDiff(today, selectedDate, 'years');
        if (yearsDiff === 0) { // when selected date is bigger than today
          yearsDiff = dateLib.compare(today, selectedDate, CONFIG_DATE_MOMENT_FORMAT);
        }
        // console.log('yearsDiff', yearsDiff);
        return (yearsDiff >= 0) ? yearsDiff <= maxAge && yearsDiff >= minAge : false;
      },
      required: true,
    },
    validate_maximum_return_days: {
      message: 'Number of days should not go beyond 180 for Single Trip type.',
      // rule: maximumReturnDaysValidationRule, // throws  an error for this one!
      rule: ({ startDate, endDate, maxNoOfDay }, params, validator) => {
        const sM = moment(startDate, CONFIG_DATE_MOMENT_FORMAT);
        const eM = moment(endDate, CONFIG_DATE_MOMENT_FORMAT);
        const noOfDays = moment(eM.format('L')).diff(moment(sM.format('L')), 'days') + 1;
        return noOfDays <= maxNoOfDay;
      },
    },
    insured_no_same_Id: {
      message: 'This value has already been entered for another insured person',
      rule: (val) => {
        const { value, validationParams: { idCollections } } = val;
        const duplicates = idCollections.filter((id) => id === value);
        return duplicates ? duplicates.length > 1 === false : true;
      },
    },
    beneficiary_no_same_Id: {
      message: 'This value has already been entered for insured person',
      rule: (val) => {
        const { value, validationParams: { idCollections } } = val;
        return idCollections.includes(value) === false;
      },
    },
    cnic_length: {
      message: 'This value should be 13 characters length',
      rule: (val) => {
        const { value } = val;
        return value && value.length === utils.lengths.CNIC;
      },
    },
  },
};

const cbValidationObject = {
  className: 'text-danger-step',
  messages: {
    email: 'Invalid email.',
    max: 'This field may not be greater than :max:type',
    min: 'This field must be at least :min:type',
    alpha: 'This field should contain only letters',
  },
  validators: {
    customRequired: {
      message: 'Required.',
      // making sure val is truthy value,
      rule: (val, params, validator) => !validator.helpers.isBlank(val) && !!val,
      required: true,
    },
    numbersOnly: {
      message: 'Contains numeric characters only.',
      rule: (val) => (val !== '' && lib.isOnlyNumber(val)),
    },
    full_name_valid_characters: {
      message: 'This field must contain letters and certain symbols only.',
      // rule: (val, params, validator) => (validator.helpers.testRegex(val, /^[a-z\-\'\@\.\ \/\\\u4E00-\u9FCC]+$/gi)
      //       && validator.helpers.testRegex(val, /[a-zA-Z]/gi)/** at least one alphabetical character */
      // ),
      rule: (val, params, validator) => !validator.helpers.isBlank(val) && !!val,
      required: true,
    },
    alphanumeric: {
      message: 'This field must contain letters and numbers only.',
      rule: (val, params, validator) => (validator.helpers.testRegex(val, /[^a-z0-9]/gi)
      ),
    },
    date_of_birth_boundary: {
      message: 'Insured person age must be between 29 days - 80 years old.',
      rule: (val) => {
        // console.log('val', val);
        const { validationParams: { maxAgeYrs, minAgeDays }, value } = val;
        const today = dateLib.getCurrentDate(CONFIG_DATE_MOMENT_FORMAT);
        const yearsDiff = dateLib.getDiff(today, value, 'years');
        const daysDiff = dateLib.getDiff(today, value, 'days');

        // console.log('maxAgeYrs', maxAgeYrs);
        // console.log('minAgeDays', minAgeDays);
        // console.log('yearsDiff', yearsDiff);
        // console.log('daysDiff', daysDiff);
        if (daysDiff < minAgeDays) return false;
        if (yearsDiff > maxAgeYrs) return false;
        return true;
      },
    },
  },
};

const cbFlexiValidationObject = {
  className: 'text-danger-step',
  messages: {
    email: 'Invalid email.',
    max: 'This field may not be greater than :max:type',
    min: 'This field must be at least :min:type',
    alpha: 'This field should contain only letters',
  },
  validators: {
    customRequired: {
      message: 'Required.',
      // making sure val is truthy value,
      rule: (val, params, validator) => !validator.helpers.isBlank(val) && !!val,
      required: true,
    },
    numbersOnly: {
      message: 'Contains numeric characters only.',
      rule: (val) => (val !== '' && lib.isOnlyNumber(val)),
    },
    full_name_valid_characters: {
      message: 'This field must contain letters and certain symbols only.',
      rule: (val, params, validator) => !validator.helpers.isBlank(val) && !!val,
      required: true,
    },
    alphanumeric: {
      message: 'This field must contain letters and numbers only.',
      rule: (val, params, validator) => (validator.helpers.testRegex(val, /[^a-z0-9]/gi)
      ),
    },
    date_of_birth_boundary: {
      message: 'Insured person age must be between 18 years old to 65 years old.',
      rule: (val) => {
        const { validationParams: { maxAgeYrs, minAgeYrs }, value } = val;
        const today = dateLib.getCurrentDate(CONFIG_DATE_MOMENT_FORMAT);
        const yearsDiff = dateLib.getDiff(today, value, 'years');
        if (yearsDiff > maxAgeYrs || yearsDiff < minAgeYrs) return false;
        return true;
      },
    },
  },
};

const hgiValidationObject = {
  className: 'text-danger-step',
  messages: {
    email: 'Invalid email.',
    max: 'This field may not be greater than :max:type',
    min: 'This field must be at least :min:type',
    alpha: 'This field should contain only letters',
  },
  validators: {
    customRequired: {
      message: 'Required.',
      // making sure val is truthy value,
      rule: (val, params, validator) => !validator.helpers.isBlank(val) && !!val,
      required: true,
    },
    numbersOnly: {
      message: 'Contains numeric characters only.',
      rule: (val) => (val !== '' && lib.isOnlyNumber(val)),
    },
    full_name_valid_characters: {
      message: 'This field must contain letters and certain symbols only.',
      rule: (val, params, validator) => (validator.helpers.testRegex(val, /^[a-z\-\'\@\.\ \/\\\u4E00-\u9FCC]+$/gi)
            && validator.helpers.testRegex(val, /[a-zA-Z]/gi)/** at least one alphabetical character */
      ),
    },
    alphanumeric: {
      message: 'This field must contain letters and numbers only.',
      rule: (val, params, validator) => (validator.helpers.testRegex(val, /[^a-z0-9]/gi)
      ),
    },
    date_of_birth_boundary: {
      message: 'Insured person age must be between 29 days - 80 years old.',
      rule: (val) => {
        // console.log('val', val);
        const { validationParams: { range }, value } = val;
        const today = dateLib.getCurrentDate(CONFIG_DATE_MOMENT_FORMAT);
        // console.log(value,today)
        // first one is bigger than the second one : 1
        // console.log(dateLib.compare(today,value,CONFIG_DATE_MOMENT_FORMAT))
        const yearsDiff = dateLib.getDiff(today, value, 'years');
        const daysDiff = dateLib.getDiff(today, value, 'days');
        // if (daysDiff < minAgeDays) return false;
        // if (yearsDiff > maxAgeYrs) return false;
        return false;
      },
    },
  },
};



const validations = {
  alfalahValidationObject,
  cbValidationObject,
  hgiValidationObject,
  cbFlexiValidationObject,
};
export default validations;
