import { Col, Row } from 'reactstrap';
import React from 'react';
import { Header } from '../../@xc-core/components/core/header';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import { TablesUi } from '../../_components';


const heading = [
  {
    id: 1,
    headerName: 'Fiscal Year',
    key: 'fiscalYear',
  },
  {
    id: 2,
    headerName: 'Start Date',
    key: 'startDate',
  },
  {
    id: 3,
    headerName: 'End Date',
    key: 'endDate',
  },
  {
    id: 4,
    headerName: 'Action',
    key: 'action',
  },
];
export default (props: IObject) => {
  const { fiscalYearList, addFiscalYear, saveRecord } = props;
  return (
    <div data-testid="containter">
      <Header
        title='Fiscal Year Config'
        bannerGraphic={bannerImg}
        withBorderBottom
      />
      <div className='container-fluid px-0 normal-gray-text'>
        <Row className='d-flex justify-content-center my_30'>
          <Col sm={11} className='col-sm-11' style={{ padding: '0 4%' }}>
            <p className='font-weight-bold mt-4'>Fiscal Year Configuration</p>
          </Col>
        </Row>
      </div>
      <div className='container-fluid px-0 normal-gray-text'>
        <Row className='d-flex justify-content-center my_30'>
          <Col sm={11} className='col-sm-11' style={{ padding: '0 4%' }}>
            <TablesUi
              tableClasses='center'
              heading={heading}
              tableData={fiscalYearList}
            />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center my_30'>
          <Col sm={11} className='col-sm-11 d-flex justify-content-end' style={{ padding: '0 4%' }}>
            <button
              type='button'
              className='btn btn-outline-primary mt-4'
              onClick={addFiscalYear}
            >
              Add Fiscal Year
            </button>
            <button
              type='button'
              className='btn btn-primary mt-4 ml-4'
              onClick={saveRecord}
            >
              Save
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
