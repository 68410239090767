import React from "react";
import { Row, Col } from "reactstrap";
import { utils } from "utils";
import Field from "../getQuote/wizard/field";

interface IComponents {
  title: string;
  model: any;
  type: "primary" | "secondary";
}

interface IProps {
  components: IComponents[];
  item: any;
  endorseMode: boolean;
  onChange?: (pair: IPair) => void;
  collections?: any;
  errorMessage?: any;
  greyBoxTitle?: string;
}

const RenderDetails = (props: any) => {
  const {
    model,
    item,
    type,
    endorseMode,
    onChange,
    collections,
    errorMessage,
  } = props;
  return (
    <>
      {model ? (
        model.items.map((modelItem: any, index: number) => {
          return (
            <RenderDetail
              errorMessage={errorMessage}
              key={index}
              model={modelItem}
              item={item}
              type={type}
              endorseMode={endorseMode}
              onChange={onChange}
              collections={collections}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

const RenderDetail = (props: any) => {
  const { item, model, endorseMode, onChange, collections, errorMessage } =
    props;
  const {
    lib: { resolveObjKey },
  } = utils;
  return (
    <li
      key={model.id}
      className="list-group-item d-flex justify-content-between border-0 px-0 py-1 grey-bg summary-label"
    >
      {model.name}
      {!endorseMode ? (
        <span className="col-5 p-0 wordBreak summary-label-2">
          {item ? resolveObjKey(item, model.id) : ""}
        </span>
      ) : (
        <Col md={6}>
          <Field
            item={item}
            collections={collections}
            model={model}
            onChange={onChange}
            validationError={errorMessage.find(
              (el: { referral: string; refCode: string; message: string }) =>
                el.referral === model.id
            )}
          />
          {model.infoMessage ?<div className={'info-message'}>{model.infoMessage || ''}</div> : null}
        </Col>
      )}
    </li>
  );
};

const getComponent = (
  components: IComponents[],
  type: "primary" | "secondary",
  item: any,
  endorseMode?: boolean,
  onChange?: (pair: IPair) => void,
  collections?: any,
  errorMessage?: any
) => {
  return components
    .filter((el) => el.type === type)
    .map((el, index) => {
      const { title, model } = el;
      return (
        <Col key={index} className={"pt-2"}>
          <div className="custom-q-text-primary bold my-2">{title}</div>
          <ul className="list-group">
            <RenderDetails
              model={model}
              item={item}
              endorseMode={endorseMode && model.config?.editable}
              onChange={onChange}
              collections={collections}
              errorMessage={errorMessage}
            />
          </ul>
        </Col>
      );
    });
};

const InsuredDetails = (props: IProps) => {
  const {
    item,
    components,
    endorseMode,
    onChange,
    collections,
    errorMessage,
    greyBoxTitle,
  } = props;

  return (
    <>
      {greyBoxTitle && (
        <h4 className="beneficiary-header my-3 p-3">{greyBoxTitle}</h4>
      )}
      <Row>
        <Col md={6} className="wordBreak pl-0">
          {getComponent(
            components,
            "primary",
            item,
            endorseMode,
            onChange,
            collections,
            errorMessage
          )}
        </Col>
        <Col md={6} className="wordBreak pl-0">
          {getComponent(
            components,
            "secondary",
            item,
            endorseMode,
            onChange,
            collections,
            errorMessage
          )}
        </Col>
      </Row>
    </>
  );
};
export default InsuredDetails;
