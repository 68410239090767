
import React from "react";
import { Form, FormGroup, Button } from 'reactstrap';
import { ModalUi } from "../../_components";

const DeleteModal = (props:IObject) =>{
    const {isOpen, toggleModal, handleDelete, record} = props;

    return (
        <ModalUi className="my-auto" isOpen={isOpen} toggle={() => toggleModal()}>
          <Form>
            <FormGroup>
              <div className="d-flex">Are you sure you want to delete this scope management?</div>
              {/* <br />
              <div>Name: {record.name}</div>
              <div>Type: {record.type}</div>
              <div>Resource: {record.resource}</div> */}
            </FormGroup>
            <FormGroup className="text-right mb-0">
              <Button color="muted" className="mr-3 customLeastbtn" onClick={() => toggleModal()} >No</Button>
              <Button onClick={() => handleDelete(record)} className="customPrimarybtn" color="primary">Delete</Button>
            </FormGroup>
          </Form>
        </ModalUi>
      )
}

export default DeleteModal;