
import React from 'react';
import { Col, Row } from 'reactstrap';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import PaymentForm from '../PaymentGateWayForm';
import lib from 'utils/lib';

const PaymentDetails = (props: ILoadableComponentProps) => {
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            db
        },
    } = props;
    const usePaymentGateway = item.properties['usePaymentGateway'];

    if (usePaymentGateway) {
        return (
            <>
                <PaymentForm item={item} />
            </>
        );
    }
    return (
        <Row>
            <Col md='12'>
                <h5 className='sub-title'>{model.title}</h5>
                <RenderFields
                    onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
                    collections={collections}
                    item={item}
                    validationErrors={validationErrors}
                    fields={model.paymentDetails.fields}
                />
            </Col>
        </Row>
    );
};

export default PaymentDetails;
