import React from 'react';
import { userMessages } from '@xc-core/constants';
import { connect } from 'react-redux';
import { MultiProductHeader } from '@xc-core/components/core/header';
import { Col } from 'reactstrap';
import {
  Message, Tab, Loader,
  Table, Filter, ExportButton,Header
} from '@xc-core/components/core';
import { mediaLib } from '@xc-core/lib';
import * as routes from '@xc-core/routes';
import bannerImage from '../../images/bannerGraphicImages/get-quote.png';
import { actions } from '@redux';
import BaseListingContainer from '../baseListingContainer';
import lib from '../../utils/lib';

const quotationAction = new actions.QuotationActions();

interface IQuotationListProps extends IBaseListingComponentProps {
  products: IProductListState,
  item: IQuotationListState,
  initProduct: () => void,
  changeProduct: (pair: IProduct) => void,
  exportCsv: (param: IRequestParam, fileName: string) => void,
  changeTab: (pair: IPair) => void,
}

class QuotationList extends BaseListingContainer<IQuotationListProps, {}> {
  getTabCollection = (item: IQuotationListState) => (item
    && item.collections && item.collections.status);

  onQuotationExport = (pii: boolean) => {
    const { item } = this.props;
    let fileName = '';
    if (item && item.product) {
      fileName = mediaLib.generateCsvFileName(true, pii, item.product.orgUnit);
    }
    this.onExport(pii, fileName);
  }

  render() {
    const { products, item } = this.props;
    if (products.error || item.error) return (<Message message={userMessages.ERROR} />);
    if (products.accessIsForbidden || item.accessIsForbidden) {
      return (<Message message={userMessages.ACCESS_DENIED} />);
    }
    if (products.isLoading || item.product === null) return (
      <>
        <Header
          title='Quotations'
          bannerGraphic={bannerImage}
          withBorderBottom
        />
      </>
    );
    return (
      <div data-testid="containter">
        <MultiProductHeader
          title='Quotations'
          bannerGraphic={bannerImage}
          collections={products.list || []}
          selectedProduct={item.product}
          handler={(product: IProduct) => this.onProductChange(product)}
        />
        <div data-testid="containter-inner" className='container-fluid px-0'>
          <Tab
            collections={this.getTabCollection(item)}
            active={item.tab}
            handler={(pair: IPair) => this.onChangeTab(pair)}
          />
          <div className='row d-flex justify-content-center my_30'>
            <Col sm='12' className='my-2 filter-viewType-container'>
              <Filter
                isLoading={item.isLoading}
                filterOptions={item.filters}
                onCheckChange={
                  (newFilters: IListingFilter[]) => this.onFilterCheckChange(newFilters)
                }
                applyFilter={(newFilters: IListingFilter[]) => this.onApplyFilter(newFilters)}
                cancelFilter={() => this.onCancelFilter(item.prevFilters)}
              />
            </Col>
            <div className='col-sm-12'>
              <Table
                titles={item.fields}
                records={lib.constructTableListValue(item)}
                hasRecords={item.hasRecordsInitially}
                image={bannerImage}
                onRowButtonClicked={(row: IObject) => (
                  this.onRowClicked(routes.quotation.base, row.id))}
                onRowClicked={(row: IObject) => this.onRowClicked(routes.quotation.base, row.id)}
                handleSearchKeyword={(pair: IPair) => this.onSearchChange(pair)}
                searchKeyword={item.searchKeyword}
                handlePageClick={(pair: IPair) => this.onPageClick(pair)}
                handlePageSizeChange={(pair: IPair) => this.onPageSizeChange(pair)}
                pagination={item.pageInfo}
                buttonIcon='file'
              >
                <ExportButton
                  exportToCsv={(pii: boolean) => this.onQuotationExport(pii)}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  products: state.productList,
  item: state.quotationList,
});
const mapDispatchToProps = (dispatch: any) => ({
  initProduct: () => dispatch(quotationAction.initProduct()),
  changeProduct: (product: IProduct) => dispatch(quotationAction.setProduct(product)),
  setList: (params: IRequestParam) => dispatch(quotationAction.setList(params)),
  getSearchParams: () => dispatch(quotationAction.getSearchParams()),
  changeTab: (pair: IPair) => dispatch(quotationAction.changeTab(pair)),
  changeSearchKey: (keyword: string) => dispatch(quotationAction.changeSearchKey(keyword)),
  changePage: (value: number) => dispatch(quotationAction.changePage(value)),
  changePageSize: (value: number) => dispatch(quotationAction.changePageSize(value)),
  exportCsv: (params: IRequestParam, fileName: string) => (
    dispatch(quotationAction.getCsvFile(params, fileName))),
  applyFilters: (filters: IListingFilter[]) => (
    dispatch(quotationAction.applyFilters(filters))),
  changeFilters: (filters: IListingFilter[]) => (
    dispatch(quotationAction.changeFilters(filters))),
  cancelFilters: (prevFilters: IListingFilter[]) => (
    dispatch(quotationAction.cancelFilters(prevFilters))),
  resetFilters: () => dispatch(quotationAction.resetFilters()),
  setHasRecordsInitially: () => dispatch(quotationAction.setHasRecordsInitially()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationList);
