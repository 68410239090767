import env from 'env';
import Request from './request';
import Response from './response';
import BaseListingService from './baseListing';

class LeadManagementService extends BaseListingService {
  protected env = env.quotation;

  protected api = 'leads';

  protected withApi = 'with-collections';


  public download = async (id: string): Promise<any> => {
    const url = `${this.env}/${this.api}/download?idOrCode=${id}`;
    const request = new Request();
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }
  public getList = async (params: string): Promise<any> => {
    const url = `${this.env}/${this.api}/search?${params}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
  public getFilters = async () => {
     const url = `${this.env}/${this.api}/filters`;
     const request = new Request();
     const response = new Response(url, request);
     const result = await response.handle();
     return result.data;
  }
}

export default LeadManagementService;
