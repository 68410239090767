
import { PasswordManagementService } from '@xc-core/services';
import Notifications from 'react-notification-system-redux';
import actionTypes from '@redux/actionTypes';
import BaseListingAction from './baseListing';
import { getNotificationMessage } from '../../_services';

class PasswordManagementAction extends BaseListingAction {
  protected createScope: string = '';

  protected csvExportScope: string = '';

  protected editScope: string = '';

  protected actions = actionTypes.passwordManagement;

  protected service: PasswordManagementService;

  constructor() {
    super();
    this.service = new PasswordManagementService();
  }

  public setItem = ({ event: { target }, checkValue } : IObject) => async (
    dispatch: any,
    getState: Function,
  ) => {
    const { item } = getState().passwordManagement;
    let newItem: IObject = { ...item };
    const {
      checked, value, type, name,
    } = target;
    if (type === 'checkbox' && Array.isArray(item[name])) {
      const currentValue: string[] = item[name];
      const currentValueId: number = currentValue.indexOf(checkValue);
      if (checked) {
        newItem = {
          ...newItem,
          [name]: [...currentValue, checkValue],
        };
      } else {
        newItem = {
          ...newItem,
          [name]: [
            ...currentValue.slice(0, currentValueId), ...currentValue.slice(currentValueId + 1),
          ],
        };
      }
    } else {
      newItem = {
        ...newItem,
        [name]: value,
      };
    }

    dispatch(this.createAction(this.actions.SET_ITEM, { item: newItem }));
  }

  public save = () => async (dispatch: Function, getState: Function) => {
    const { item } = getState().passwordManagement;
    const result = await this.service.save(item);
    if (!result.error) {
    // @ts-ignore
      dispatch(Notifications.success(getNotificationMessage('Saved successfully.')));
      return dispatch(this.createAction(this.actions.SET_ITEM, { item: result }));
    }
    return result;
  }


  public setHasRecordsInitially= () => async () => {
  }

  public setList = () => async (dispatch: Function) => {
    dispatch(this.createAction(this.actions.LIST_LOADING, true));
    const result = await this.service.getList();
    const { content } = result;
    if (!result.error && content.length) {
      dispatch(this.createAction(
        this.actions.SET_ITEM, { item: content[0] },
      ));
    }
    dispatch(this.createAction(this.actions.LIST_LOADING, false));
  }

  public getSearchParams = () => (): IRequestParam => ({});
}


export default new PasswordManagementAction();
