// function defaultDisabled() {
//   return false;
// }

// function defaultHandler(pair, item) {
//   return { pair, item };
// }
const model = {
  header: {
    id: '',
    name: '',
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    items: [
      {
        current: { id: 'properties.Price.Total', name: '', config: { currency: true } },
        prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: 'Price Breakdown',
        name: 'Price Breakdown',
        items: [
          {
            current: { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Main Policy', name: 'Gross Premium', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Admin Fee', name: 'Admin Fee', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Admin Fee', name: 'Admin Fee', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      {
        id: 'Commission Breakdown',
        name: 'Commission Breakdown',
        items: [
          {
            current: { id: 'properties.Commission Amount', name: 'Commission Amount', config: { currency: true } },
            prev: { id: 'properties.Previous Commission', name: 'Commission Amount', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      // {
      //   id: 'Other Details',
      //   name: 'Other Details',
      //   items: [
      //     {
      //       current: {
      //         id: 'properties.Destination',
      //         name: 'Destination',
      //         type: 'Select',
      //         collectionId: 'destinations',
      //         config: {},
      //         disabled: defaultDisabled,
      //         handler: defaultHandler,
      //       },
      //       prev: { config: {} },
      //       config: {},
      //     },
      //     {
      //       current: {
      //         id: 'properties.Country of Origin',
      //         name: 'Country of Origin',
      //         type: 'Select',
      //         collectionId: 'countries',
      //         config: {},
      //         disabled: defaultDisabled,
      //         handler: defaultHandler,
      //       },
      //       prev: { config: {} },
      //       config: {},
      //     },
      //     {
      //       current: {
      //         id: 'properties.Trip Type',
      //         name: 'Trip Type',
      //         type: 'Select',
      //         collectionId: 'tripTypes',
      //         config: {},
      //         disabled: defaultDisabled,
      //         handler: defaultHandler,
      //       },
      //       prev: { config: {} },
      //       config: {},
      //     },
      //     {
      //       current: {
      //         id: 'properties.Start Date',
      //         name: 'Start Date',
      //         type: 'DatePicker',
      //         placeholder: 'Set Start date',
      //         start: 'properties.Start Date',
      //         end: 'properties.End Date',
      //         config: {},
      //         disabled: defaultDisabled,
      //         handler: defaultHandler,
      //       },
      //       prev: { config: {} },
      //       config: {},
      //     },
      //     {
      //       current: {
      //         id: 'properties.End Date',
      //         name: 'End Date',
      //         type: 'DatePicker',
      //         placeholder: 'Set End date',
      //         start: 'properties.Start Date',
      //         end: 'properties.End Date',
      //         config: {},
      //         disabled: defaultDisabled,
      //         handler: defaultHandler,
      //       },
      //       prev: { config: {} },
      //       config: {},
      //     },
      //     {
      //       current: {
      //         id: 'properties.Insured Type',
      //         name: 'Insured Type',
      //         type: 'Select',
      //         collectionId: 'insuredType',
      //         config: {},
      //         label: 'Insured Type',
      //         style: { col: '12' },
      //         disabled: defaultDisabled,
      //         handler: defaultHandler,
      //       },
      //       prev: { config: {} },
      //       config: {},
      //     },
      //   ],
      //   config: { isEditable: true },
      // },

    ],
  },
};

export default model;
