import React from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { hooks } from '@xc-core/lib';

interface IProps { exportToCsv: Function, hasDropDown?: boolean }

const ExportButton = (props: IProps) => {
  const { exportToCsv, hasDropDown = true } = props;
  const [isOpen, toggleOpen] = hooks.useToggle(false);
  const toggle = () => {
    if (hasDropDown) toggleOpen();
    else exportToCsv(true);
  };
  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret color={'primary'}>
        Export To CSV
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => exportToCsv(true)}>All Fields</DropdownItem>
        <DropdownItem onClick={() => exportToCsv(false)}>Non-PII</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default ExportButton;
