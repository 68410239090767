import env from 'env';
import BaseListingService from './baseListing';

class OrganizationService extends BaseListingService {
  protected env = env.tsd;

  protected api = 'identity/orgunits';

  protected withApi = 'withcollections';
}

export default OrganizationService;
