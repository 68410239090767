import React from "react";
import PropTypes from "prop-types";
import Chip from "react-toolbox/lib/chip";

const FilterButton = (props) => {
  const { onClick } = props;
  return (
    <Chip
      className="filterBtn px-3 mx-2"
      onClick={() => onClick()}
    >
      <i className="icon-add font-size-small mx-1" />
      <span>Add Filter</span>
    </Chip>
  );
};
FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FilterButton;
