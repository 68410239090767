import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';

const BasicDetail = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
    },
  } = props;

  const OccupationDetail = () => {
    return (
      <Row className='font-size-small'>
        <Col md='12' className='mt-3'>
          <p className="font-weight-bold underline">Classification of Occupation / ចំណាត់ថ្នាក់មុខរបរ</p>
          <p>Class 1 : Persons engaged in professional, administration, managerial, clerical and non-manual occupation</p>
          <p>ចំណាត់ថ្នាក់ទី ១ : បុគ្គលមានមុខរបរជាវិជ្ជាជីវៈ រដ្ឋបាល គ្រប់គ្រង ការិយាល័យ និងមិនមែនហត្ថកម្ម។</p>
          <p>Class 2 :  Persons engaged in work of a supervisory nature but not involves manual labor</p>
          <p>ចំណាត់ថ្នាក់ទី ២ : បុគ្គលពាក់ព័ន្ធនឹងការងារត្រួតពិនិត្យ ប៉ុន្តែមិនពាក់ព័ន្ធនឹងការងារហត្ថកម្ម។</p>
          <p>Class 3 :  Persons engaged either occasionally or generally in manual work which involves the use of tools and machinery</p>
          <p>ចំណាត់ថ្នាក់ទី ៣ : បុគ្គលពាក់ព័ន្ធម្តងម្កាល ឬជាប្រចាំនឹងការងារហត្ថកម្មដែលមានការប្រើប្រាស់ឧបករណ៍ ឬម៉ាស៊ីន។</p>
        </Col>
        <Col md='12' className='mt-3 mb-3'>
          <p className="font-weight-bold underline">Exclusions For Personal Accident / ករណីមិនធានាសំរាប់ធានារ៉ាប់រងគ្រោះថ្នាក់បុគ្គល</p>
          <p>1. Airline personnel and aircrew / នាវិក និងបុគ្គលិកអាកាសចរ</p>
          <p>2. Injuries or death participating in professional sports / របួស ឬស្លាប់ដោយសារកីឡាអាជីព</p>
          <p>3. Miners / អ្នករុករករ៉ែក្រោមដី</p>
          <p>4. Professional divers or ship crews / នាវិកនាវា</p>
          <p>5. Naval, military or air force personnel / កងនាវាចររបស់រាជរដ្ឋាភិបាល ទាហាន កងទ័ពអាកាស</p>
          <p>6. People dealing in manufacturing explosive, munitions, and fireworks / អ្នកធ្វើការពាក់ព័ន្ធនឹងគ្រឿងផ្ទុះ គ្រាប់បែក និងកាំជ្រួច</p>
          <p>7. Proposer who suffers permanent loss of limbs / អ្នកដែលរងគ្រោះបាត់បង់អវយវៈជាអចិន្ត្រៃយ៍</p>
          <p>8. Jockeys and racing drivers / អ្នកជិះសេះប្រណាំង ឬបើកបរប្រណាំង</p>
          <p>9. Timber loggers / អ្នកកាប់ឈើ</p>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col md='12'>
        <h5 className='sub-title'>Please enter the following details / សូមផ្ដល់ព័ត៌មានលម្អិតដូចខាងក្រោម</h5>
        <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={collections}
          item={item}
          ext={true}
          validationErrors={validationErrors}
          fields={model.basicDetail.fields}
        />
        <OccupationDetail/>
      </Col>
    </Row>
  );
}

export default BasicDetail;
