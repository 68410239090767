import React from "react";
import { Col } from "reactstrap";
import Searchbar from "./Searchbar";
import Pagination from "./Pagination";
import Header from "./Header";
import Body from "./Body";
import PropTypes from "prop-types";

const Table = (props) => {
  const {
    children,
    pagination,
    handlePageClick,
    titles,
    handleSearchKeyword,
    searchKeyword,
    records,
    hasRecords,
    image,
    goToDetails,
    listType,
  } = props;
  return (
    <>
      <div className="col-sm-12">
        <div
          className={`card shadow-sm" ${children ? "additionalHeader" : ""}`}
          style={{ minHeight: "auto" }}
        >
          {
            // isLoading ? <Loader /> : 
            // (
            <div className="card-header">
              <Searchbar
                searchKeyword={searchKeyword}
                handleSearchKeyword={handleSearchKeyword}
              >
                {children}
              </Searchbar>
              <div className="card-body p-0">
                <table id="mainTable" className="table">
                  <Header fields={titles} />
                  <Body
                    image={image}
                    titles={titles}
                    records={records}
                    hasRecords={hasRecords}
                    listType={listType}
                    goToDetails={goToDetails}
                  />
                </table>
              </div>
            </div>
            // )
          }
        </div>
      </div>
      <Col sm="11" className="mt-3">
        <Pagination
          pagination={pagination}
          onClick={handlePageClick}
        />
      </Col>
    </>
  );
};
Table.propTypes = {
  children: PropTypes.any,
  pagination: PropTypes.object,
  handlePageClick: PropTypes.func,
  titles: PropTypes.array.isRequired,
  handleSearchKeyword: PropTypes.func.isRequired,
  searchKeyword: PropTypes.string,
  records: PropTypes.array,
  hasRecords: PropTypes.bool,
  image: PropTypes.string.isRequired,
  goToDetails: PropTypes.func.isRequired,
  listType: PropTypes.string,
};
Table.defaultProps = {
  children: [],
  pagination: {},
  handlePageClick: () => {},
  searchKeyword: "",
  records: [],
  hasRecords: false,
  listType: "",
};

export default Table;
