import { getQuoteConstants} from '../constants';
import { getQuoteServices } from '../services';
import Notifications from "react-notification-system-redux";
import {getNotificationMessage, checkPermission} from "_services";
import {SET_FLASH_MESSAGES} from "_constants";
import {utils} from 'utils';
const updateList = (list,type) => ({
  type: type,
  list,
});

const createQuotation = (data) => async dispatch => {
  const response = await getQuoteServices.createQuotation(data);
  if (response.item) {
    return dispatch(updateList(response,getQuoteConstants.CREATE_QUOTATIONS));
  } else {
    dispatch(Notifications.error(getNotificationMessage('error creating quotation')));
    return response;
  }
};

const  updateQuotation = (data,id,direction) => async dispatch => {
  const response = await getQuoteServices.updateQuotation(data,id,direction);
  if (response.productCode) return dispatch(updateList(response,getQuoteConstants.UPDATE_QUOTATIONS));
  return response;
};

const getStates = (country, param = '?collection=state') => async dispatch =>{
  const states = await getQuoteServices.getStates(country, param);
  if (states) { //&& states.length
    return dispatch({
      states,
      type: getQuoteConstants.GET_STATES
    });
  }
};

const getQuotation = (quotationId) => async dispatch => {
  const quotation = await getQuoteServices.getQuotation(quotationId);
  if (quotation.item) return dispatch({
    type: getQuoteConstants.GET_QUOTATION,
    payload: quotation
  });
};

/**
 * @function getProducts fetches the products and the collections
 */
const getProducts = () => async dispatch =>{
  const response = await getQuoteServices.getProducts();

  // let states;
  // if (!response.error && response.collections.countries.length === 1) {
  //   states = await getQuoteServices.getStates(response.collections.countries[0].name);
  // }
  if (!response.error ) {
    const products = response.list;
    if ( products && products.length === 1) {
      const product = products[0];
      const productCollections = await getQuoteServices.getCollections(product.id);
      let states;
      if (productCollections && productCollections.collections.countries.length === 1) {
        states = await getQuoteServices.getStates(productCollections.collections.countries[0].name);
      }
      await dispatch({
        type: getQuoteConstants.GET_QUOTE_GET_COLLECTIONS,
        payload: { collections: { ...productCollections.collections, states: states || null } },
      });
      await dispatch({
        type: getQuoteConstants.GET_QUOTE_SELECT_PRODUCT,
        payload: {product},
      });
      await dispatch({
        type: getQuoteConstants.GET_QUOTE_GET_PRODUCTS,
        payload: {products: response.list} //, collections: {...response.collections, states: states || null}}
      });
      // this.props.history.push(`getQuote/${product.id}`);
      window.location.href = `#/getQuote/${product.id}`;
    } else  return dispatch({
      type: getQuoteConstants.GET_QUOTE_GET_PRODUCTS,
      payload: {products: response.list} //, collections: {...response.collections, states: states || null}}
    });
    // if (products.length === 1) {
    //   await dispatch({
    //     type: getQuoteConstants.GET_QUOTE_SELECT_PRODUCT,
    //     payload: {productCode: products[0].id},
    //   });
    // }
    // return dispatched;
  }
};
const getCollections = (productCode, stateParam) => async (dispatch) => {
  const response = await getQuoteServices.getCollections(productCode);
  if (!response.error) {
    let states;
    if (response.collections?.countries?.length === 1) {
      states = await getQuoteServices.getStates(response.collections.countries[0].name, stateParam);
      dispatch({
        type: getQuoteConstants.GET_QUOTE_GET_COLLECTIONS,
        payload: { collections: { ...response.collections, states: states || null } },
        // payload: {collections: response.collections}
      });
      return true;
    }
    dispatch({
      type: getQuoteConstants.GET_QUOTE_GET_COLLECTIONS,
      payload: { collections: { ...response.collections, states: states || null } },
      // payload: {collections: response.collections}
    });
    return true;
  }
  return false;
};
const updateStatePerStep = (stepNumber, stateOnStep) => dispatch => dispatch({
  type: getQuoteConstants.UPDATE_STATE_PER_STEP,
  payload: { stepNumber, stateOnStep }
});
const resetQuotation = () => dispatch => {
  return dispatch({
    type: getQuoteConstants.RESET_QUOTATION
  });
};

function checkEditPermission(){
  return async dispatch => {
    const permission = await checkPermission("Edit Quotation");
    if (!permission.error && permission.status !== "permitted") {
      dispatch({
        type: SET_FLASH_MESSAGES,
        payload: {message: utils.userMessages.NOT_ALLOWED}
      });
    }
    return permission;
  };
}
function checkGetQuotePermission(){
  return async dispatch => {
    const permission = await checkPermission("Get Quote");
    if (!permission.error && permission.status !== "permitted") {
      dispatch({
        type: SET_FLASH_MESSAGES,
        payload: {message: utils.userMessages.NOT_ALLOWED}
      });
    }
    return permission;
  };
}

const resetStatePerStep = () => dispatch => dispatch({type: getQuoteConstants.RESET_STATE_PRE_STEP});

const selectProduct = (product) => dispatch => dispatch({
  type: getQuoteConstants.GET_QUOTE_SELECT_PRODUCT,
  payload: {product}
});

export const getQuoteActions = {
  createQuotation,
  updateQuotation,
  getStates,
  getQuotation,
  resetQuotation,
  getProducts,
  updateStatePerStep,
  resetStatePerStep,
  checkEditPermission,
  checkGetQuotePermission,
  selectProduct,
  getCollections,
};