import React from "react";
import { Form, FormGroup } from "reactstrap";
import { CommonInputTypes } from "_components";


const PaymentDetails = (props) => {
	const {fields,collections} = props;
  return(
		<div className="row my_50 justify-content-center">
		<div className="col-12 col-md-10">
			<label className="sub-title">Fill in payment details (optional)</label>
			<Form>
				<FormGroup>
					<CommonInputTypes
						id="1"
						idName="paymentMode"
						group={false}
						type="select"
						title="Mode of Payment"
						option={collections.paymentMethod}
						value={fields.paymentMode}
						onChange={(e) => props.handlePaymentModeChange("paymentDetails",e)}
						autoFocus={true}
					/>
					<CommonInputTypes
						id="2"
						idName="receiptNo"
						title="Receipt No"
						group={false}
						type="input"
						value={fields.receiptNo}
						onChange={(e) => props.handleChange("paymentDetails",e)}
						autoFocus={true}
					/>
					<CommonInputTypes
						id="3"
						idName="receivedBy"
						title="Payment Received By"
						group={false}
						type="select"
						option={collections.currentOuUsers}
						value={fields.receivedBy}
						onChange={(e) => props.handleChange("paymentDetails",e)}
						autoFocus={true}
					/>
				</FormGroup>
			</Form>
		</div>
	</div>
  );
}

export default PaymentDetails;
