import React, {useState} from 'react';
import Styled from "styled-components";
import {CardBody, CardHeader, Collapse, Button} from 'reactstrap';
import PropTypes from 'prop-types';

const CustomCard = Styled.div`
  min-height: 0
`;

export function Accordion(props) {
  const {classes="", headerClasses="",containerClasses ="",  headerComponent, bodyComponent, setOpenValue} = props;
  const [open, changeOpen] = useState(props.open || false);
  const toggleSection = () => {
    const newValue = !open;
    changeOpen(newValue);
    setOpenValue && setOpenValue(newValue);
  };
  return (
    <div id="accordion" className={`shadow-sm ${containerClasses}`}>
      <CustomCard className={`card my-4 rounded-xl accordion ${classes}`}>
        <CardHeader
          data-test="accordion-header"
          className={`d-flex align-items-center justify-content-between ${headerClasses}`}
          onClick={toggleSection}
        >
          <h5 className="mb-0">
            <Button color="link">
              {headerComponent}
            </Button>
          </h5>
          <i className={open ? "icon-up-arrow p-3" : "icon-downArrow p-3"}/>
        </CardHeader>
        <Collapse isOpen={open}>
          <CardBody data-test="accordion-body">
            {bodyComponent}
          </CardBody>
        </Collapse>
      </CustomCard>
    </div>
  )
}

Accordion.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.string, // Classes to be applied to the whole accordion
  headerClasses: PropTypes.string, // Classes to be applied to the header part
  setOpenValue: PropTypes.func,
  headerComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  bodyComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
};