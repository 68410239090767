import actionTypes from '@redux/actionTypes';
import { GetQuoteService } from '@xc-core/services';
import { listLib, productLib } from '@xc-core/lib';
import BaseAction from './base';
import QuotationAction from './quotation';
import ProductAction from './product';
import Notifications from 'react-notification-system-redux';
import { getNotificationMessage } from '../../_services';

// getQuote
// 1. https://xc-tds-test.finology.com.my/authorization/resources/trust?scope=Get%20Quote
// 5. https://xc-tds-test.finology.com.my/authorization/resources/trust?scope=Edit%20Quotation
// 3. https://xc-quote-test.finology.com.my/quotations/0/next, get the quotation id here

const quotationAction = new QuotationAction();
const productAction = new ProductAction();

class GetQuoteAction extends BaseAction {
  protected actions = actionTypes.getQuote;

  protected service = new GetQuoteService();

  protected createScope = 'Get Quote';

  protected editScope = 'Edit Quotation';

  public initProduct = () => (dispatch: any, getState: any): void => {
    const { list } = getState().productList;
    if (list && list.length === 1) {
      const action = this.createAction<IProduct>(this.actions.SET_PRODUCT, list[0]);
      dispatch(action);
    } else if (list && list.length > 1) {
      /* because the first element for more than one product is All */
      const action = this.createAction<IProduct | null>(this.actions.SET_PRODUCT, null);
      dispatch(action);
    }
  }

  public setProduct = (product: IProduct) => (dispatch: any): void => {
    // product.model = loadModel(product.id);
    const action = this.createAction<IProduct>(this.actions.SET_PRODUCT, product);
    dispatch(action);
  }

  public init = (productCode: number, country: string,
    stateListParam: string) => async (dispatch: any): Promise<void> => {
    dispatch(this.createAction(this.actions.GET_QUOTE_LOADING));
    try {
      const result = await this.service.init(productCode);
      const collections = this.prepareCollections(result.collections);
      const states = await dispatch(quotationAction.getStates(country, stateListParam));
      const collectionsWithStates = this.addStatesToCollections(collections, states);
      dispatch(this.createAction<IObject>(this.actions.GET_QUOTE_SET_COLLECTIONS,
        collectionsWithStates));
      dispatch(productAction.setQuotationValidationModuleInStorage(productCode));
    } catch (error) {
      // if (error.status === 401) {
      //   dispatch(this.createAction(this.actions.CREATE_SET_FORBIDDEN));
      // }
      // if (error.status === 404) {
      //   dispatch(this.createAction(this.actions.SET_NOT_FOUND));
      // }
      dispatch(this.createAction(this.actions.GET_QUOTE_FAILED, error));
    }
  }

  public setStateInCollections = (country: string,
    param: string) => async (dispatch: any, getState: any): Promise<void> => {
    try {
      let { collections } = getState().newGetQuote;
      // const quotationService = new QuotationService();
      // const stateList = await quotationService.getStates(country, param);
      const states = await dispatch(quotationAction.getStates(country, param));
      collections = this.addStatesToCollections(collections, states);
      dispatch(this.createAction<IObject>(this.actions.GET_QUOTE_SET_COLLECTIONS,
        collections));
    } catch (error) {
      dispatch(this.createAction(this.actions.GET_QUOTE_FAILED, error));
    }
  }

  public setSteps = (steps: IStep[]) => (dispatch: any): void => {
    dispatch(this.createAction(this.actions.GET_QUOTE_LOADING));
    // try {
    dispatch(this.createAction<IObject>(this.actions.GET_QUOTE_SET_STEPS, steps));
    // } catch (error) {
    //   dispatch(this.createAction(this.actions.GET_QUOTE_FAILED, error));
    // }
  }

  public setGetQuote = (id: number) => async (dispatch: any): Promise<void> => {
    dispatch(this.createAction(this.actions.GET_QUOTE_LOADING));
    try {
      const result = await this.service.get(id);
      const collections = this.prepareCollections(result.collections);
      const product = productLib.prepareObj(result.product);
      const payload = {
        product,
        collections,
        item: result.item,
      };
      dispatch(this.createAction<IObject>(this.actions.GET_QUOTE_SET, payload));
    } catch (error) {
      // if (error.status === 401) {
      //   dispatch(this.createAction(this.actions.CREATE_SET_FORBIDDEN));
      // }
      // if (error.status === 404) {
      //   dispatch(this.createAction(this.actions.SET_NOT_FOUND));
      // }
      dispatch(this.createAction(this.actions.GET_QUOTE_FAILED, error));
    }
  }

  public getItemOnNext = (productCode: number, id: number,
    properties: IObject) => async (dispatch: any, getState: any): Promise<IObject> => {
    dispatch(this.createAction(this.actions.GET_QUOTE_LOADING));
    const { item } = getState().newGetQuote;
    try {
      const obj = { productCode, properties };
      const result = await this.service.next(id, obj);
      dispatch(this.createAction<IObject>(this.actions.GET_QUOTE_NEXT, result));
      return result;
    } catch (error) {
      if (error.status === 400) {
        // @ts-ignore
        dispatch(Notifications.error(getNotificationMessage(error.message)))
      }
      // if (error.status === 404) {
      //   dispatch(this.createAction(this.actions.SET_NOT_FOUND));
      // }
      dispatch(this.createAction(this.actions.GET_QUOTE_FAILED, error));
      return item;
    }
  }

  public getItemOnSkip = (productCode: number, id: number,
    properties: IObject) => async (dispatch: any, getState: any): Promise<IObject> => {
    dispatch(this.createAction(this.actions.GET_QUOTE_LOADING));
    const { item } = getState().newGetQuote;
    try {
      const obj = { productCode, properties };
      //remove the beneficiary from properties. its added for Beneficiary step. Its not dynamic now
      obj.properties.beneficiaries = undefined;
      const result = await this.service.next(id, obj);
      dispatch(this.createAction<IObject>(this.actions.GET_QUOTE_NEXT, result));
      return result;
    } catch (error) {
      if (error.status === 400) {
        // @ts-ignore
        dispatch(Notifications.error(getNotificationMessage(error.message)))
      }
      dispatch(this.createAction(this.actions.GET_QUOTE_FAILED, error));
      return item;
    }
  }

  public getItemOnPrevious = (productCode: number,
    id: number) => async (dispatch: any, getState: any): Promise<IObject> => {
    dispatch(this.createAction(this.actions.GET_QUOTE_LOADING));
    const { item } = getState().newGetQuote;
    try {
      const obj = { productCode };
      const result = await this.service.previous(id === 0 ? productCode : id, obj);
      dispatch(this.createAction<IObject>(this.actions.GET_QUOTE_PREV, result));
      return result;
    } catch (error) {
      // if (error.status === 401) {
      //   dispatch(this.createAction(this.actions.CREATE_SET_FORBIDDEN));
      // }
      // if (error.status === 404) {
      //   dispatch(this.createAction(this.actions.SET_NOT_FOUND));
      // }
      dispatch(this.createAction(this.actions.GET_QUOTE_FAILED, error));
      return item;
    }
  }

  public reset = () => (dispatch: any) => {
    dispatch(this.createAction(this.actions.GET_QUOTE_RESET));
  }

  private prepareCollections = (obj: IObject): IObject => {
    const collections: IObject = {};
    Object.keys(obj).forEach((key) => {
      if (key === 'currentOuUsers') {
        collections[key] = listLib.extendWithIDValueForKeys(obj[key], ['username', 'name']);
      } else {
        collections[key] = listLib.extendWithIDValueForKeys(obj[key], ['code', 'name']);
      }
    });
    collections.priceList = obj.priceList; // I have to over write the price obj
    // because I cannot change it since its values are being send to in the request
    return collections;
  }

  private addStatesToCollections = (collections: IObject, states: IObject[]) => ({
    ...collections,
    states: [
      ...states,
    ],
  })
}

export default GetQuoteAction;
