import env from 'env';
import Request from './request';
import Response from './response';
import BaseListingService from './baseListing';

class PasswordManagementService extends BaseListingService {
  protected env = env.tsd;

  protected api = 'configs';

  protected withApi = '';

  public getList = async (): Promise<any> => {
    const url = `${this.env}/${this.api}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public save = async (item: IObject): Promise<any> => {
    const url = `${this.env}/${this.api}`;
    const method: string = item.id ? 'PUT' : 'POST';
    const request = new Request(item, method);
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
}

export default PasswordManagementService;
