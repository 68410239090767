import React from 'react';
import { connect } from 'react-redux';
import { sidebarModel } from 'models';
import stateToProps from '@containers/storeState';
import dispatchToProps from '@containers/dispatches';
import products from '_config/products';
import { objLib } from '@xc-core/lib';
import { Loader } from '@xc-core/components/core';
import NomineeWitnessDetails from './nominees-witness-details';
import BaseQuotationDetails from '../base-quotation-detail';
import fields from './fields';
import models from './model';

interface IProps {
  setQuotationOccupationList: () => {}
  getQuoteWitnessSignature: (refId: string) => Promise<string>
  history: any
  quotationManagement: IObject
}
const ICareQuotationDetails = (props: IProps) => {
  const {
    history,
    quotationManagement: { quotation, collections },
    setQuotationOccupationList,
    getQuoteWitnessSignature,
  } = props;

  if (objLib.isEmpty(quotation)) return <Loader />;
  return (
    <BaseQuotationDetails
      fields={fields}
      models={{ ...models, sidebar: sidebarModel.quotation.iCareGbsn }}
      history={history}
      quotation={quotation}
      collections={collections}
      defaultCountry={products.gbsnICare.defaultValues.country}
      setOccupationList={setQuotationOccupationList}
    >
      <NomineeWitnessDetails
        fields={fields.quotation}
        item={quotation}
        collections={collections}
        models={models}
        getWitnessSignature={getQuoteWitnessSignature}
        signatureId={quotation.refId}
      />
    </BaseQuotationDetails>
  );
}; 

export default connect(stateToProps, dispatchToProps)(ICareQuotationDetails);
