import React from 'react';
import { Select } from '@xc-core/components/core';

interface IListViewProps {
  isFlat: boolean,
  onChange: Function,
  collections: IPair[],
}

const ListViewType = (props: IListViewProps) => {
  const {
    isFlat,
    onChange,
    collections,
  } = props;

  let value = '';
  if (collections && collections.length > 0) {
    value = isFlat ? collections[1].value : collections[0].value;
  }

  return (
    <div className='record-level-date-range'>
      <Select
        className='view-type'
        id='viewTypes'
        name='viewTypes'
        placeholder='View Type'
        collections={collections}
        value={value}
        handler={(pair:IPair) => onChange(pair)}
      />
    </div>
  );
};

export default ListViewType;
