import React from 'react';
import PropTypes from 'prop-types';
import {Input} from "reactstrap";

const TitleComponent = (props) => {
  const {noBorderBottom, products, currentProduct, changeProduct, classes} = props;
  // if (products.length) debugger;
  return (
    <div
      className={
        `mx-3 text-dark 
        ${noBorderBottom ? "" : "border-bottom bg-white"}
        ${props.children ? "align-items-end" : "align-items-center"}
        ${classes}
        `
      }
      id="menu-title">
      <div className="">
        {props.backTitle && <p className="customBackbutton"
                               onClick={() => props.history.push(props.backPath)}
        >
          <i className="icon-leftArrow font-size-normal pr-2"/>
          <span className="customBackbuttonname">{props.backTitle}</span>
        </p>
        }
        <div className="title-container" >
            <h2 className={`
              menu-title-banner
              ${props.children ? "mb-4 mr-2" : ""}
              `}
            >
              {props.title}
            </h2>
          {
            products && products.length > 1  && currentProduct &&
            <Input
              className="px-2 pr-4"
              value={currentProduct.refCode} name={"currentProduct"} type="select" onChange={changeProduct}>
              {products.map((product, index) => {
                return (<option key={index} value={product.refCode}>{product.name}</option>);
              })}
            </Input>
          }
        </div>
        {props.children}
      </div>
      <img src={props.bannerGraphic} alt=""/>
    </div>
  )
};

TitleComponent.propTypes = {
  noBorderBottom: PropTypes.bool,
  title: PropTypes.string.isRequired,
  products: PropTypes.array,
  currentProduct: PropTypes.object,
  changeProduct: PropTypes.func,
  backPath: PropTypes.string,
  history: PropTypes.object,
  bannerGraphic: PropTypes.string,
  backTitle: PropTypes.string,
};

export default TitleComponent;