import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-toolbox/lib/card";
import Loader from "../Loader";

const FilterCard = (props) => {
  const { isVisible, isLoading, children } = props;
  return (
    <Card
      id="xc-filter"
      className={
        isVisible ? "d-block rounded-xl shadow card mt-2 xc-filter" : "d-none"
      }
    >
      {isLoading ? <Loader /> : <>{children}</>}
    </Card>
  );
};
FilterCard.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default FilterCard;
