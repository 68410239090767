import React from 'react';
import StepConnector from "@material-ui/core/StepConnector";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";


const CustomStepper = (props) => {

	const {steps, activeStep, classes } = props;
  
  const getStepTitle = () => {
    return `${steps[activeStep]}
     - Step ${ activeStep + 1}
      of ${ steps.length}`;
  }
  const connector = (
    <StepConnector
      classes={{
        active: `${classes.connectorActive} step-connector-active`,
        completed: `${classes.connectorCompleted} step-connector-completed`,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  );

  return (	
	
    steps[activeStep] !== steps[steps.length - 1] && ( /**completed */
    <>
      <h5 className="text-medium">{getStepTitle()}</h5>
      <Stepper orientation="horizontal" 
        classes={{horizontal: classes.removePadding,}} 
        activeStep={activeStep+1} /**activeStep starts from 0 */
        connector={connector}>
        {steps.map(label => (
          <Step key={label}/>
        ))}
      </Stepper>
    </>
  ));
}

export default CustomStepper;