import React from "react";
import {
  Col, Nav, NavItem, NavLink,
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";

const Tab = (props) => {
  const { activeCode, onClick, collections } = props;
  const allCollection = { name: "All", code: "" };

  const onTabClick = (code) => {
    onClick({ key: code, value: code });
  };

  return (
    <Col sm={12} className="px-0 bg-white border-bottom">
      <Nav tabs className="px-3">
        <NavItem>
          <NavLink
            onClick={() => onTabClick(allCollection.code)}
            className={classnames({ active: activeCode === allCollection.code })}
          >
            {allCollection.name}
          </NavLink>
        </NavItem>
        {
          collections.map((collection) => (
            <NavItem key={collection.id}>
              <NavLink
                onClick={() => onTabClick(collection.code)}
                className={classnames({ active: activeCode === collection.code })}
              >
                {collection.name}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
    </Col>
  );
};

Tab.propTypes = {
  collections: PropTypes.array,
  activeCode: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
Tab.defaultProps = {
  collections: [],
  activeCode: "",
};

export default Tab;
