import React from "react";
import PropTypes from "prop-types";
import Chip from "react-toolbox/lib/chip";
import moment from "moment";

const RenderChipValue = (props) => {
  const { filter, filter: { checkedCount } } = props;

  if (filter.type === "dateRange") {
    const startDate = filter.list.find((value) => value.code === "startDate").value;
    const endDate = filter.list.find((value) => value.code === "endDate").value;
    return <span>{`${moment(startDate).format("DD/MMM/YYYY")}-${moment(endDate).format("DD/MMM/YYYY")}`}</span>;
  }

  const firstCheckedName = filter.list.find((listItem) => (listItem.isChecked)).name;
  return checkedCount <= 1
    ? <span>{firstCheckedName}</span> : <span>{ `${firstCheckedName} .... (${checkedCount - 1} more)`}</span>;
};
RenderChipValue.propTypes = {
  filter: PropTypes.object.isRequired,
};

const SelectedFiltersChip = (props) => {
  const { filters, removeChip } = props;
  return (
    filters && filters.map((filter) => (
      <div key={filter.id} style={{ marginBottom: "-5px" }}>
        {filter.checkedCount >= 1 && (
          <Chip
            key={filter.id + 1}
            theme={{
              deleteIcon: "deleteBg",
              deleteX: "deleteIcon",
              chip: "chipBg",
            }}
            onDeleteClick={() => removeChip(filter.id)}
            deletable
            className="m-1"
          >
            <span className="text-primary font-weight-normal">
              {`${filter.displayName} =`}
              <RenderChipValue filter={filter} />
            </span>
          </Chip>
        )}
      </div>
    ))
  );
};
SelectedFiltersChip.propTypes = {
  filters: PropTypes.array,
  removeChip: PropTypes.func.isRequired,
};
SelectedFiltersChip.defaultProps = {
  filters: [],
};

export default SelectedFiltersChip;
