function defaultDisabled() {
  return false;
}
function defaultHandler(pair) {
  return [pair];
}
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    sumAssured: { id: 'properties.Sum Assured', name: '', config: {} },
    items: [
      {
        current: { id: 'properties.Price.Total', name: 'Total Price', config: { currency: true } },
        prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: 'Price Breakdown',
        name: 'Price Breakdown',
        items: [
          {
            current: { id: 'properties.NCD', name: 'NCD', config: { currency: false ,type:'percent' } },
            prev: { config: {} },
            config: {}
          },
          {
            current: { id: 'properties.Price.Main Policy', name: 'Net Basic Premium', config: { currency: true } },
            prev: { config: {} },
            config: {}
          },
          {
            current: { id: 'properties.Price.SST', name: 'SST 6%', config: { currency: true } },
            prev: { config: {} },
            config: {} 
          },
          {
            current: { id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: { currency: true } },
            prev: { config: {} },
            config: {}
          },
        ],
        config: {},
      },
      {
        id: 'Other Details',
        name:'Other Details',
        items: [
          {
            current:  { id: 'properties.Sum Assured', name: 'Sum Assured', config: {currency:true} },
            prev: { config: {} },
            config: {}
          }
        ],
        config: {},
      },
    ],
  },
};

export default model;
