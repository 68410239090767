
import { dobRanges, convertToInt } from 'utils';
import lib from "../../../utils/lib";

const alfalahDatesOfBirth = {
  children: dobRanges.childrenRange,
  adult: dobRanges.adultRange,
  seniorCitizen6675: dobRanges.senior6675Range,
  seniorCitizen7680: dobRanges.senior7680Range,
  seniorCitizen8185: dobRanges.senior8185Range,
};
const getValidationDates = (insuredType) => {
  let validationDates;
  switch (insuredType) {
    case 'Senior Citizen 66-75':
      validationDates = {
        maxDateOfBirth: alfalahDatesOfBirth.seniorCitizen6675.maxDate,
        minDateOfBirth: alfalahDatesOfBirth.seniorCitizen6675.minDate,
        maxAge: alfalahDatesOfBirth.seniorCitizen6675.maxAge,
        minAge: alfalahDatesOfBirth.seniorCitizen6675.minAge,
      };
      break;
    case 'Senior Citizen 76-80':
      validationDates = {
        maxDateOfBirth: alfalahDatesOfBirth.seniorCitizen7680.maxDate,
        minDateOfBirth: alfalahDatesOfBirth.seniorCitizen7680.minDate,
        minAge: alfalahDatesOfBirth.seniorCitizen7680.minAge,
        maxAge: alfalahDatesOfBirth.seniorCitizen7680.maxAge,
      };
      break;
    case 'Senior Citizen 81-85':
      validationDates = {
        maxDateOfBirth: alfalahDatesOfBirth.seniorCitizen8185.maxDate,
        minDateOfBirth: alfalahDatesOfBirth.seniorCitizen8185.minDate,
        minAge: alfalahDatesOfBirth.seniorCitizen8185.minAge,
        maxAge: alfalahDatesOfBirth.seniorCitizen8185.maxAge,
      };
      break;
    case 'Child':
      validationDates = {
        maxDateOfBirth: alfalahDatesOfBirth.children.maxDate,
        minDateOfBirth: alfalahDatesOfBirth.children.minDate,
        minAge: alfalahDatesOfBirth.children.minAge,
        maxAge: alfalahDatesOfBirth.children.maxAge,
      };
      break;
    default:
      validationDates = {
        maxDateOfBirth: alfalahDatesOfBirth.adult.maxDate,
        minDateOfBirth: alfalahDatesOfBirth.adult.minDate,
        minAge: alfalahDatesOfBirth.adult.minAge,
        maxAge: alfalahDatesOfBirth.adult.maxAge,
      };
      break;
  }
  return validationDates;
};

const getIndicesForTitles = (properties) => {
  // set it to true if adult if not 0
  const mainInsurerIsAdult = !!Number.parseInt(properties.Adults, 10);

  const senior66Count = Number.parseInt(properties['Senior Citizen (66-75)'], 10);
  const senior76Count = Number.parseInt(properties['Senior Citizen (76-80)'], 10);
  const senior81Count = Number.parseInt(properties['Senior Citizen (81-85)'], 10);

  const adultIndex = mainInsurerIsAdult ? 1 : 0;
  const childIndex = 0;
  let senior66Index = 0;
  let senior76Index = 0;
  let senior81Index = 0;
  if (mainInsurerIsAdult === false && senior66Count > 0) senior66Index = 1;
  if (mainInsurerIsAdult === false && senior66Index === 0 && senior76Count > 0) senior76Index = 1;
  if (mainInsurerIsAdult === false && senior66Index === 0 && senior76Index === 0
    && senior81Count > 0) senior81Index = 1;

  const indices = {
    adult: adultIndex,
    senior66: senior66Index,
    senior76: senior76Index,
    senior81: senior81Index,
    child: childIndex,
  };

  return indices;
};

const getMainInsuredTypeTitle = (indices) => {
  if (indices.adult > 0) return 'Adult';
  if (indices.senior66 > 0) return 'Senior Citizen (66-75)';
  if (indices.senior76 > 0) return 'Senior Citizen (76-80)';
  if (indices.senior81 > 0) return 'Senior Citizen (81-85)';
  return '';
};

const mainInsuredType = (indices) => {
  if (indices.adult > 0) return 'Adult';
  if (indices.senior66 > 0) return 'Senior Citizen 66-75';
  if (indices.senior76 > 0) return 'Senior Citizen 76-80';
  if (indices.senior81 > 0) return 'Senior Citizen 81-85';
  return '';
};

const updateIndicesByType = (indices, type) => {
  switch (type) {
    case 'Adult':
      indices.adult += 1;
      return indices.adult;
    case 'Senior Citizen 66-75':
      indices.senior66 += 1;
      return indices.senior66;
    case 'Senior Citizen 76-80':
      indices.senior76 += 1;
      return indices.senior76;
    case 'Senior Citizen 81-85':
      indices.senior81 += 1;
      return indices.senior81;
    case 'Child':
      indices.child += 1;
      return indices.child;
    default:
      return indices;
  }
};

/** order is important, it shows priority for main insured */
const ALFALAH_INSURED_TYPES = [
  { id: 'Adults', name: 'Adult', validationMessage: 'age should be 18-65 years old.' },
  { id: 'Senior Citizen (66-75)', name: 'Senior Citizen 66-75', validationMessage: 'age should be 66-75 years old.' },
  { id: 'Senior Citizen (76-80)', name: 'Senior Citizen 76-80', validationMessage: 'age should be 76-80 years old.' },
  { id: 'Senior Citizen (81-85)', name: 'Senior Citizen 81-85', validationMessage: 'age should be 81-85 years old.' },
  { id: 'Children', name: 'Child', validationMessage: 'age should be 0-17 years old.' },
];

const getMainInsuredType = (properties) => {
  const mainInsured = ALFALAH_INSURED_TYPES.find((type) => convertToInt(properties[type.id]) > 0);
  return mainInsured ? mainInsured.name : '';
};

const generateDobErrorMessageByType = (type) => {
  const selectedType = ALFALAH_INSURED_TYPES.find((t) => t.name === type);
  return selectedType ? `Incorrect date of birth for ${selectedType.name} age group. 
    ${selectedType.name} ${selectedType.validationMessage}`
    : `Incorrect date of birth for ${type}`;
};
const noAddOnCountries = ['Andorra', 'Argentina', 'Aruba', 'Bahrain', 'Brazil', 'Costa Rica', 'Czechia', 'Czech Republic', 'France', 'French Polynesia', 'Guam', 'Israel', 'Kuwait', 'Maldives', 'Montenegro', 'Occupied Palestinian territory', 'Panama', 'Peru', 'Puerto Rico', 'Saint Martin', 'Spain', 'Serbia and Montenegro'];

const adjustAlfalahSideBar =(sidebarmodel , properties)=>{
  return lib.removeSidebarBodyItem(sidebarmodel,[
    {
      ...( properties['Trip Type'] === 'Annual' ?
          {
            parentKey:'Other Details',
            removeItem: ['properties.destinationCountry']
          }
          : {})
    },
    {
      ...(properties['Covid-19 Add On'] === 'No Add On' ?
          {
            parentKey:'COVID-19 Add On Details',
            removeParent: true
          }
          : {})
    }
  ]);
}

export {
  alfalahDatesOfBirth,
  getValidationDates,
  getIndicesForTitles,
  getMainInsuredTypeTitle,
  mainInsuredType,
  updateIndicesByType,
  getMainInsuredType,
  ALFALAH_INSURED_TYPES,
  generateDobErrorMessageByType,
  noAddOnCountries,
  adjustAlfalahSideBar
};
