import React from 'react';
import { Row, Col } from 'reactstrap';
import { objLib, fieldLib } from '@xc-core/lib';

const Individual = (props:{ model: IObject[], item: IObject, index: number }) => {
  const { model, item } = props;
  return (
    <ul className='list-group'>
      {
        model.map((m: any) => {
          return (
            <li key={m.id} className='list-group-item d-flex justify-content-between border-0 px-0 py-1 grey-bg summary-label'>
              {m.label}
              <span className='col-5 p-0 wordBreak summary-label-2'>{objLib.getValueWithLodash(item, m.id)}</span>
            </li>
          );
        })
      }
    </ul>
  );
};

const Pair = (props: { model: { insured: IObject[],
  beneficiary: IObject[], otherInsured: IObject[], otherBeneficiary: IObject[] }, item: IObject, index: number }) => {
  const { model, item, index } = props;
  return (
    <>
      <Row>
        <Col md='6'>
          <p className='custom-text-primary'>Insured Person Details</p>
          <Individual model={model.insured} item={item} index={index} />
        </Col>
        <Col md='6'>
          <p className='custom-text-primary'>Beneficiary Details</p>
          <Individual model={model.beneficiary} item={item} index={index} />
        </Col>
      </Row>
      {item.properties['Other Insured'].map((value:IObject,index:number)=>{
        return <div key={index}>
          <br/>
          <hr/>
          <Row>
            <Col md='6'>
              <p className='custom-text-primary'>Insured Person Details</p>
              <Individual model={fieldLib.addIndexToFields(model.otherInsured,'Other Insured',index)} item={item} index={index} />
            </Col>
            <Col md='6'>
              <p className='custom-text-primary'>Beneficiary Details</p>
              <Individual model={fieldLib.addIndexToFields(model.otherBeneficiary,'Other Insured',index)} item={item} index={index} />
            </Col>
          </Row>
        </div>
      })}
    </>
  );
};

const Detail = (props: { item: IObject,
  insured: IObject[], beneficiary: IObject[],otherInsured: IObject[],otherBeneficiary: IObject[] }) => {
  const {
    item,
    insured,
    beneficiary,
    otherInsured,
    otherBeneficiary
  } = props;

  return (
    <Pair
      index={0}
      item={item}
      model={{
        insured: insured,
        beneficiary: beneficiary,
        otherInsured: otherInsured,
        otherBeneficiary: otherBeneficiary
      }}
    />
  );
};

export default Detail;
