const model = {
    header: {
        productName: { id: 'productName', name: '', config: {} },
        plan: { id: 'properties.Plan', name: '', config: {} },
        total: { id: 'properties.Price.Total', name: 'Total Price', ext: '', config: {} },
        config: {},
    },
    body: {
        items: [
            {
                id: 0,
                title: { id: 'Price Breakdown', name: 'Price Breakdown', ext: '' },
                items: [
                    { id: 'properties.Price.Main Policy', name: 'Gross Premium', ext: '', config: { currency: true } },
                    { id: 'properties.Price.Admin Fee', name: 'Admin Fee', ext: '', config: { currency: true } },
                    { id: 'properties.Price.Tax', name: 'Tax', ext: '', config: { currency: true } }
                ],
                config: {},
            },
            {
                id: 1,
                title: { id: 'Other Details', name: 'Policy Details', ext: '' },
                items: [
                    { id: 'properties.Plan', name: 'Plan', ext: '', config: {} },
                    { id: 'properties.Trip Type', name: 'Trip Type', ext: '', config: {} },
                    { id: 'properties.Country of Origin', name: 'Country of Origin', ext: '', config: {} },
                    { id: 'properties.Destination', name: 'Destination', ext: '', config: {} },
                    { id: 'properties.Start Date', name: 'Start Date', ext: '', config: {} },
                    { id: 'properties.End Date', name: 'End Date', ext: '', config: {} },
                    { id: 'properties.Insured Type', name: 'Insured Type', ext: '', config: {} },
                ],
                config: {},
            },
        ],
        config: {},
    },
    config: {},
};

export default model;
