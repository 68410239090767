import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { objLib } from '@xc-core/lib';

interface IState { editMode: boolean, submitting: boolean }
interface IProps {
  item: IObject
  model: IObject[]
}

const PaymentCard = (props: IProps) => {

  const { item, model } = props;
  return (
    <div className='sidebarCard'>
      <div>
        <Card className='mt-4 shadow-md border-0' style={{ borderRadius: '10px' }}>
          <CardBody className='px-3'>
            <div className='pt-2 text-primary'>
              <Row>
                <Col sm={9} className='payment-title-qc font-size-normal text-secondary mt-2 mb-2'>
                  Payment Details
                </Col>
              </Row>
            </div>
            <hr className='my-2' />
            <div className='pt-2'>
              {
                model.map((m:IObject) => (
                  <Row className='pt-1 pb-3' key={m.id}>
                    <Col sm={6}>{m.label}</Col>
                    <Col className='text-right summary-label-3' sm={6}>
                      { objLib.getValueFromPath(item, m.id) }
                    </Col>
                  </Row>
                ))
              }
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default PaymentCard;
