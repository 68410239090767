import React from 'react';
import { eventLib } from '@xc-core/lib';
import {
  Col, Nav, NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';

const Tab = (props: { collections: [IPair], active: IPair, handler: Function }) => {
  const { active, handler, collections } = props;

  const onClick = (collection: IPair) => {
    const pair = eventLib.getPair({ target: { id: collection.id, value: collection.value } });
    handler(pair);
  };
  return (
    <Col sm={12} className='px-0 bg-white border-bottom'>
      <Nav tabs className='px-3'>
        {
          collections && collections.map((collection: IPair) => (
            <NavItem key={collection.id}>
              <NavLink
                onClick={() => onClick(collection)}
                className={classnames({ active: active.id === collection.id })}
              >
                {collection.value}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
    </Col>
  );
};

export default Tab;
