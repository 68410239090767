/**
 * convert obj to requestParams
 * @param {IRequestParam} obj
 * @returns {string}
 */
const convertToRequestParams = (obj: IRequestParam): string => {
  let requestParams = '';
  Object.entries(obj).forEach(([key, value]): void => {
    requestParams = value.reduce((accumulate: string, current: string) => {
      const paramValue = encodeURIComponent(current);
      return accumulate ? `${accumulate}&${key}=${paramValue}` : `${key}=${paramValue}`;
    }, requestParams);
  });
  return requestParams;
};
const request = { convertToRequestParams };
export default request;
