import React, { useState, useEffect} from 'react';
import { Col, Row } from 'reactstrap';
import Img from 'images/bannerGraphicImages/get-quote.png';
import { TitleComponent } from '_components';


interface IProps {
  quotation: IObject
  title: JSX.Element
  children: JSX.Element
  sidebar: JSX.Element
  history: IObject | null
  actionButton?: JSX.Element
  marketingInfoCard?: JSX.Element
}

const BaseQuotationDetail = (props: IProps) => {
  const {
    quotation,
    title,
    children,
    sidebar,
    history,
    actionButton,
    marketingInfoCard,
  } = props;

  const [backPath, setBackPath] = useState('');
  useEffect(() => {
    let path = '';
    if (history) {
      path = [...history.location.pathname.split('/').slice(0, 2)].join('/');
    }
    setBackPath(path);
  }, []);

  return (
    <div className='container-fluid px-0'>
      <TitleComponent
        title='Quotation Details'
        bannerGraphic={Img}
        backTitle='Back to listing'
        backPath={backPath}
        history={history}
      />
      <div className='row d-flex justify-content-center my_50' id='quotation-detail'>
        <Col sm={11} className='quotation-detail-wrapper'>
          { quotation === null ? <div className='loader d-block' />
            : (
              <Row>
                <Col sm={8}>
                  {title}
                  {children}
                </Col>
                <Col sm={4}>
                  {actionButton}
                  {sidebar}
                  {marketingInfoCard}
                </Col>
              </Row>
            )}
        </Col>
      </div>
    </div>
  );
};

export default BaseQuotationDetail;
