
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { fieldLib, objLib } from '@xc-core/lib';

// const addIndexToPairs = (pairs: IPair[], arrayProp:string ,index:number): IPair[] =>{
//   return pairs.map((pair)=>{
//     return {
//       ...pair,
//       id: pair.id.replace(arrayProp,`${arrayProp}[${index}]`)
//     }
//   })
// }

const InsuredDetails = (props: ILoadableComponentProps) => {
  const [typesOrderedList, setTypesOrderedList] = useState<IObject[]>([{type:"",index:""}]);
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
      initValues,
      defaults,
    },
  } = props;

  useEffect(() => {
    const country = objLib.getValueWithLodash(item, model.fields.country.id)
      || defaults.country;
    const pairs: IPair[] = [{ id: model.fields.country.id, value: country }];
    initValues(pairs);
  }, []);

  useEffect(()=>{
    if(item && item.properties){
      createTypesOrderedList(item)
    }
  },[item])

  const filterCountries= (): IObject[] =>{
    const countries: IObject[] = objLib.getValueWithLodash(collections, 'countries');
    return countries.filter((val)=> val.name === 'Nepal');
  }

  const sortNationalities = () =>{
    const nationalities: IObject[] = objLib.getValueWithLodash(collections, 'nationalities');
    let nepal = {}
    let sortedNationalities =  nationalities.filter((nationality)=>{
      if(nationality.name === 'Nepalese'){
        nepal = nationality
        return false;
      } else {
        return true;
      }
    })
    sortedNationalities.unshift(nepal)
    return sortedNationalities;
  }

  

  const createTypesOrderedList = (item: IObject) =>{
    let typesOrderedList: IObject[] = [];
    const mainType: string = objLib.getValueWithLodash(item, model.fields.type.id)
    const otherInsured = objLib.getValueWithLodash(item, model.fields.otherInsured.id);
    const typesCount : IObject = {
      'Adult': mainType === 'Adult' ? 1 : 0,
      'Senior Citizen': mainType === 'Senior Citizen' ? 1 : 0,
      'Children': 0
    }
    typesOrderedList.push({type :mainType, index:typesCount[mainType]})

    otherInsured.forEach((val:IObject)=>{
      typesCount[val['Type']] = typesCount[val['Type']]+1;
      typesOrderedList.push({type:val['Type'],index:typesCount[val['Type']]})
    })

    setTypesOrderedList(typesOrderedList)

  }

  return (
    <Row>
      <Col md='8'>
        <h5 className='sub-title'>Please enter the insured person(s) details</h5>

        <p className='custom-text-primary'>{`${typesOrderedList[0].type}[${typesOrderedList[0].index}]`} - Main Insured Details</p>
        {item && item.properties &&
          <RenderFields
          onChange={(pairs: IPair[]) => {return onChange('onChange', { pairs })}}
          collections={{
            ...collections,
            nationalities: sortNationalities()
          }}
          item={item}
          validationErrors={validationErrors}
          fields={model.mainDetail.fields}
          />
        }
        {item && item.properties &&
          <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={{
            ...collections,
            countries: filterCountries(),
          }}
          item={item}
          validationErrors={validationErrors}
          fields={model.contactDetail.fields}
        />
        }
        
        {item && item.properties && item.properties['Other Insured'] &&
         item.properties['Other Insured'].map((value:Object,index:number)=>{
          return <div key={index}>
            <br/>
            <hr/>
            <p className='custom-text-primary'>{typesOrderedList[index+1] ? `${typesOrderedList[index+1].type} [${typesOrderedList[index+1].index}]` : ''}</p>
            <RenderFields
                  onChange={(pairs: IPair[]) => onChange('onChange', { pairs : pairs})}
                  collections={{
                    ...collections,
                    nationalities: sortNationalities()
                  }}
                  item={item}
                  validationErrors={validationErrors}
                  fields={fieldLib.addIndexToFields(model.otherDetail.fields,'Other Insured',index)}
                />
          </div>
        })}



      </Col>
      <Col md='4'>
        <SidebarCard.Quotation
          item={item}
          model={sidebarModel.quotation.hgiTravel}
        />
      </Col>
    </Row>
  );
};

export default InsuredDetails;
