import React from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { TitleComponent, FlashMessage } from "_components";
import { userGroupActions } from "./actions";
import { hasPermissionTo } from "../../_actions";
import { Accordion } from "../../_components/Accordion";
import { TablesUi } from "../../_components";
import userGroupImage from "../../images/emptyStateImages/user-groups.png";
import usergroupGraphic from "../../images/bannerGraphicImages/usergroups.png";

const heading = [
  {
    id: 1,
    headerName: "User Group Name",
    key: "name",
  },
  {
    id: 2,
    headerName: "No. of Users",
    key: "users",
  },
  {
    id: 3,
    headerName: "",
    icons: [
      {
        name: "pencil",
        id: "code",
        actionName: "editPress",
      }
    ]
  }
];

class UserGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGettingList: false,
    };
  }

  componentDidMount() {
    return this.getList();
  }

  getList = async () => {
    const { getList } = this.props;
    this.setState({ isGettingList: true });
    await getList();
    this.setState({ isGettingList: false });
  };

  handleEdit = (event) => {
    const { history } = this.props;
    history.push(`/userGroup/${event.currentTarget.dataset.name}`);
  };

  createGroup = async () => {
    const { hasPermissionTo, history } = this.props;
    const canCreateUserGroup = await hasPermissionTo("Create User Group");
    if (canCreateUserGroup) {
      history.push(`/userGroup/CreateGroup`);
    }
  };

  goToDetails = async (userGroup) => {
    const { hasPermissionTo, history } = this.props;
    const canEditUserGroup = await hasPermissionTo("Edit User Group");
    if (canEditUserGroup) {
      history.push(`/userGroup/${userGroup.name}`);
    }
  };

  render() {
    const { userGroupList, canViewList } = this.props;
    const { isGettingList } = this.state;
    if (isGettingList) return <div className="loader" />;
    if (!canViewList) return <FlashMessage flashMessage="you are not allowed to view UserGroups." />;
    return (
      <div className="container-fluid px-0">
        <TitleComponent
          title="User Groups"
          bannerGraphic={usergroupGraphic}
        />
        <div id="userGroups" className="row d-flex justify-content-center my_30">
          <Col sm="8" className="text-right">
            <button
              type="button"
              className="btn btn-primary pl-1 btn-sm rounded-xl"
              onClick={() => this.createGroup()}
            >
              <i className="icon-add mx-2 font-size-small" />
              Add User Group
            </button>
          </Col>
          <Col sm="8" className="my-2 accordianfontSize">
            {userGroupList !== undefined
              && userGroupList.map((data, key) => (
                <Accordion
                  key={key}
                  open={key === 0}
                  headerComponent={`${data.orgUnitType}`}
                  bodyComponent={
                    (
                      <TablesUi
                        heading={heading}
                        editPress={this.handleEdit}
                        tableData={data.groups}
                        image={userGroupImage}
                        goToDetails={this.goToDetails}
                      />
                    )
                  }
                />
              ))}
          </Col>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userGroupList, collections, canViewList } = state.userGroupManagement;
  return {
    userGroupList,
    canViewList,
    collections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getList: () => dispatch(userGroupActions.getList()),
    hasPermissionTo: (scope) => dispatch(hasPermissionTo(scope)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserGroups);
