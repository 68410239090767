import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export class OrganisationInput extends React.PureComponent {
  render() {
    const {
      title, type, value, idName, option, validator,
      children, disabled, validationRules, optional
    } = this.props;
    return (
      <FormGroup>
        <Label for={idName}>{title}</Label>
        {type !== "select" && (
          <span>
            <Input
              value={value || ""}
              onChange={this.props.onChange}
              type={type || "text"}
              name={idName}
              id={idName}
              placeholder={`Enter ${title}`}
            />
            {validator !== "" &&  validator.message(
              // idName.toLowerCase(),
              title.toLowerCase(),
              value,
              `required|${validationRules ? validationRules : 'required'}`,
            )}
          </span>
        )}
        {type === "select" && (
          <span>
            <Input
              type="select"
              name={idName}
              id={idName}
              value={value}
              disabled={disabled ? disabled : false}
              onChange={this.props.onChange}
            >
              <option disabled={!optional} value="">Select Option</option>
              {option.map((data, index) => {
                const code = data.code;
                return <option key={index} value={code ? code : data.name}>{data.name}</option>;
              })}
            </Input>
            { validator !== "" && validator.message(title.toLowerCase(), value, `${ disabled ? "rootNode" : "required"}`)}
          </span>
        )}
        {children ? children : ""}
      </FormGroup>
    );
  }
}
