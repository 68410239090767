import fields from './fields';
import baseModel from '../base-models';

const { quotation } = fields;

const detailsMainModel = [
  quotation.name,
  quotation.gender,
  quotation.nric,
  quotation.religionName,
  quotation.dob,
  quotation.address,
  quotation.city,
  quotation.stateName,
  quotation.postcode,
  quotation.country,
  quotation.occupationName,
  quotation.businessName,
  quotation.height,
  quotation.weight,
  quotation.email,
  quotation.mobile,
];

const detailsHealthQuestionnaireModel = [
  quotation.treatmentFor,
  quotation.declinedInsuranceCoverage,
  quotation.consultSpecialist,
];

const models = {
  detailsMainModel,
  detailsHealthQuestionnaireModel,
  ...baseModel,
};

export default models;