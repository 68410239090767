import React from 'react';
import PropTypes from 'prop-types';

const FlashMessage = ({flashMessage}) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center empty-products-container flash-message-component">
      <h2>
        {flashMessage}
      </h2>
    </div>
  );
};
FlashMessage.propTypes = {
  flashMessage: PropTypes.string,
};
export default FlashMessage;
