import React, {Fragment} from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import moment from "moment";
import { CONFIG_DATE_MOMENT_FORMAT, DATE_PICKER_FORMATS } from "../utils";

class CommonInputTypes extends React.Component {

  constructor(props) {
    super(props);

    this.datepickerRef = React.createRef();
    this.closeCalendar.bind(this);
  }

  closeCalendar = () => {
    const datePicker = this.datepickerRef.current;
    datePicker.setOpen(false);
  }

  openCalendar = () => {
    const datePicker = this.datepickerRef.current;
    if (datePicker) {
      datePicker.setOpen(true);
    }
  }

  getDateObj = (value) => {
    const m = moment(value, CONFIG_DATE_MOMENT_FORMAT);
    return m.isValid() ? m._d : null;
  }

  render() {
    const {
      title,
      type,
      value,
      idName,
      idNameIndex,
      option,
      id,
      disabled,
      placeholder,
      group,
      iconName,
      children,
      validator,
      validationRules, validationParams,
      min, optional,
      maxDate, minDate, openToDate,
      havePrepend,
      formGroupClasses = '',
    } = this.props;
    return (
      <FormGroup style={{...this.props.dateStyle}} className={`${type === "date" ? "d-flex" : ""} ${formGroupClasses}`}>
        {title !== "" && type !== 'radio' && (
          <Label for={idName}>
            {title}
          </Label>
        )}

        {!group &&
        type !== "select" && type !== "date" && type !=='radio' && (
          <Fragment>
            <Input
              className={`${this.props.error ? "text-danger border-danger border" : ""}`}
              key={id}
              value={value || ''}
              onChange={this.props.onChange}
              type={type}
              name={idName}
              id={idName}
              placeholder={placeholder}
              disabled={disabled}
              autoFocus = {this.props.autoFocus}
            />
            {validator && validator !== "" && !disabled && validator.message && 
            validator.message(`${idNameIndex ? idNameIndex : idName}`.toLowerCase(), 
            // value,
            !value ? null : ( validationParams ? { value, validationParams} : value ),
            `${validationRules ? validationRules.concat('|required') : 'required'}`
            )
            }
          </Fragment>
        )}

        {!group && type === "select" && (
          <Fragment>
            <Input
              key={id}
              type="select"
              name={idName}
              id={idName}
              value={value || ""}
              onChange={this.props.onChange}
              disabled={disabled}
              autoFocus = {this.props.autoFocus}
            >
              <option disabled={!optional} value="">{placeholder || "Select Option"}</option>
              {option && option.map && option.map((data, index) => {
                return (
                  <option key={index} value={data && data.code ? data.code : data.name}>
                    {data.name}
                  </option>
                );
              })}
            </Input>

            {/*{this.props.validator !== "" && !disabled &&*/}
            {/*  this.props.validator.message(title,value === undefined ? null : value, "required")}*/}
            { validator && validator !== "" && !disabled && validator.message(
              `${idNameIndex ? idNameIndex : title}`,
              !value ? null : value,
              `${validationRules ? validationRules.concat('|required') : 'required'}`
            )}
          </Fragment>
        )}

        {type === "date" && (
          <div style={{width: "100%"}}>
            <FormGroup className="mb-0 xc-date-picker">
              <InputGroup>
                <DatePicker
                ref={this.datepickerRef}
                disabled={this.props.disabled || false}
                validator={this.props.validator}
                className={this.props.className}
                placeholderText={this.props.placeholder}
                selected= {this.getDateObj(value)}
                selectsStart
                startDate={this.getDateObj(this.props.startDate)}
                openToDate={this.getDateObj(openToDate)}
                endDate={this.getDateObj(this.props.endDate)}
                minDate={this.getDateObj(minDate)}
                maxDate={this.getDateObj(maxDate)}
                onChange={(date) => this.props.onChange(date)}
                dateFormat={DATE_PICKER_FORMATS}
                showDisabledMonthNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode = "select"
              />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText
                    className="dateIconStyle"
                    onClick={this.openCalendar}
                  >
                    <i className="icon-calendar" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <div className={`${havePrepend ? "push-left" : ""}`}>
              {validator && validator !== ""
                && validator.message(
                    `${idNameIndex || "date"}`,
                    !value ? null : ( validationParams ? { value, validationParams} : value ),
                    `${validationRules ? validationRules.concat("|required") : "required"}`
                  )}
            </div>
          </div>
        )}

        {group && (
          <Fragment>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className={"icon-" + iconName}/>{" "}
                </InputGroupText>
              </InputGroupAddon>

              {type !== "select" && (
                <Fragment>
                  <Input
                    key={id}
                    value={value}
                    onChange={this.props.onChange}
                    type={type}
                    name={idName}
                    id={idName}
                    placeholder={placeholder}
                    disabled={disabled}
                    min={min}
                  />
                </Fragment>
              )}

              {type === "select" && (
                <Fragment>
                  <Input
                    key={id}
                    type="select"
                    name={idName}
                    id={idName}
                    value={value || ""}
                    onChange={this.props.onChange}
                    disabled={disabled}
                    autoFocus = {this.props.autoFocus}
                  >
                    <option disabled value="">Select Option</option>
                    {
                      option && option.map && option.map((data, key) => {
                      return (
                        <option
                          disabled={data.disabled}
                          value={data.code !== null ? data.code : data.name}
                          key={key}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </Input>
                </Fragment>
              )}
            </InputGroup>

            {this.props.validator && this.props.validator !== "" && !disabled &&
            this.props.validator.message(`${idNameIndex ? idNameIndex : title}`, value === undefined ? null : value, "required")}

          </Fragment>
        )}

        {!group && type === 'radio' && (
          <Fragment>
            <InputGroup
                id={this.props.idName} 
                name={this.props.title} 
                onClick={() => this.props.onChange(this.props.idName)} >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className={"icon-" + iconName}/>{" "}
                </InputGroupText>
              </InputGroupAddon>
              <Label className='form-control'>
                {this.props.title}
              </Label>
            </InputGroup>
          </Fragment>
        )}
        {children}
      </FormGroup>
    );
  }
}

CommonInputTypes.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  idName: PropTypes.string,
  idNameIndex: PropTypes.any,
  option: PropTypes.array,
  id: PropTypes.any,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  group: PropTypes.any,
  iconName: PropTypes.string,
  validator: PropTypes.any,
  validationRules: PropTypes.string,
  maxDate: PropTypes.any, minDate: PropTypes.any, openToDate: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};
export {CommonInputTypes};