import BaseAction from "./base";
import {DistributionControlService} from '@xc-core/services';
import actionTypes from '@redux/actionTypes'
import Notifications from 'react-notification-system-redux';
import { getNotificationMessage } from '../../_services';
import {dateLib, handleError} from '@xc-core/lib';


class DistributionControlAction extends BaseAction{
    protected createScope: string = '';
    protected editScope: string = '';

    protected actions: IBaseActionTypes = actionTypes.distributionControl;
    protected service: DistributionControlService;

    constructor() {
        super();
        this.service = new DistributionControlService();
    }

    public getList = () => async (dispatch: Function) => {
        dispatch(this.createAction(this.actions.SET_DISTRIBUTION_LOADING))
        const result = await this.service.getList();
        return dispatch(this.createAction(this.actions.GET_LIST, { ...result }));
    }

    public getListWithParams = (data:IObject) => async (dispatch:Function) => {
        let result;
        const {searchKeyword, filters,page,pagesize} = data;
        let payload:IObject = {
            page,
            pageSize: pagesize
        }
        if(searchKeyword.length > 0){
            payload.name = searchKeyword;
        }
        if(filters.length > 0){
            filters.forEach((item:IObject)=>{
                if(item.id === 'type'){
                    payload.type = []
                    item.list.forEach((value:IObject)=>{
                        if(value.isChecked){
                            payload.type.push(value.code)
                        }
                    })
                } else if(item.id === 'status'){
                    if(item.list[0].isChecked && !item.list[1].isChecked){
                        payload.status = true
                    } else if(!item.list[0].isChecked && item.list[1].isChecked){
                        payload.status = false
                    }
                }

            })
        }

        result = await this.service.getListWithParams(payload);
        return dispatch(this.createAction(this.actions.GET_LIST, { ...result }));
    }
    
    public getDetails = (id:any) => async (dispatch: Function) => {

        dispatch(this.createAction(this.actions.SET_DISTRIBUTION_LOADING))
        const result = await this.service.getDetails(id);
        return dispatch(this.createAction(this.actions.GET_DETAILS, { ...result }));
    }

    public toggleEdit = (index:number,limitEditIsNew:boolean) => (dispatch: Function) =>{
        dispatch(this.createAction(this.actions.TOGGLE_EDIT_LIMIT, {index,limitEditIsNew}));   
    }

    public deleteLimit = (index:number,limitEditIsNew:boolean,limit:IObject) => async (dispatch: Function) =>{
        try{
            await this.service.deleteLimit({id: limit.id});
            dispatch(this.createAction(this.actions.DELETE_LIMIT, {index,limitEditIsNew}));   
        } catch(e){
            handleError(e)
        }
    }

    // dispatch(Notifications.success(getNotificationMessage('Saved successfully.')));
    // dispatch(Notifications.error(getNotificationMessage('Error saving settings')));

    public saveLimitsSettings = (orgDetails: IObject,editItem:IObject) => async (dispatch: Function, getState: Function) =>{
        const { distributionControl:{limitEditIndex, isEditMode, collections} } = getState();
        
        let newLimits = orgDetails.limits.map((item:IObject,i:number)=>{
            if(i === limitEditIndex){
              return {
                ...item,
                ...editItem
              }
            } else {
              return item;
            }
          })
        if(!isNaN(parseInt(limitEditIndex))){
            collections.products.forEach((product:IObject) => {
                if(product.productName === newLimits[limitEditIndex].productSlug){
                    newLimits[limitEditIndex].productSlug = product.productSlug
                }
            });  
        }  


        const newStartDateObj = isEditMode && dateLib.toDate(newLimits[limitEditIndex].startDate, dateLib.config.dateMomentFormat);
        const newEndDateObj = isEditMode && dateLib.toDate(newLimits[limitEditIndex].endDate, dateLib.config.dateMomentFormat);
          

          if(limitEditIndex !== null && limitEditIndex >= 0){
            if(!newLimits[limitEditIndex].refCode ||
            !newLimits[limitEditIndex].startDate ||
            !newLimits[limitEditIndex].endDate ||
            !newLimits[limitEditIndex].limitCap){
                // @ts-ignore
                dispatch(Notifications.error(getNotificationMessage('All fields are required')))
                return;
            }
            if(newLimits[limitEditIndex].status !== 'Active' && newStartDateObj && newStartDateObj < new Date(new Date().setHours(0, 0, 0, 0))){
                // @ts-ignore
                dispatch(Notifications.error(getNotificationMessage('Start Date cannot be a past date')))
                return;
            }
            if(newStartDateObj && newEndDateObj && (newStartDateObj > newEndDateObj)){
               // @ts-ignore
               dispatch(Notifications.error(getNotificationMessage('Start date should be before end date.')))
               return; 
            }
            if(newLimits[limitEditIndex].limitCap < newLimits[limitEditIndex].limitUsed){
                // @ts-ignore
               dispatch(Notifications.error(getNotificationMessage('Limit cap must be greater than limit used')))
               return; 
            }
          }
          if(isEditMode){
            let error = false;
            newLimits.forEach((limit:any,i:number)=>{
                if(i === limitEditIndex){
                    return
                }
                const { endDate, startDate } = limit;
                const startDateObj = dateLib.toDate(startDate, dateLib.config.dateMomentFormat);
                const endDateObj = dateLib.toDate(endDate, dateLib.config.dateMomentFormat);
                if (
                    (startDateObj && newStartDateObj && endDateObj && newEndDateObj)
                    && ((startDateObj < newStartDateObj && newStartDateObj <= endDateObj)
                    || (startDateObj <= newEndDateObj && newEndDateObj < endDateObj)
                    || (newStartDateObj <= startDateObj && newEndDateObj >= endDateObj))
                  ) {
                      // @ts-ignore
                    dispatch(Notifications.error(getNotificationMessage('New Start dates and End dates should not intersect with existing ones.')))
                    error = true;
                  }

            })
            if(error){
                return;
            }
          }

          if(orgDetails.emailAlertStatus){
              if(!orgDetails.alertAddressesList || orgDetails.alertAddressesList.length === 0 || orgDetails.alertThreshold <= 0){
                 // @ts-ignore
                 dispatch(Notifications.error(getNotificationMessage('Alert Threshold and Email List are required.')))
                 return;
              }
          }


          if(orgDetails.alertAddressesList){
            let invalidEmail = orgDetails.alertAddressesList.some((item:string) =>{
                if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(item)){
                    return true
                } else{
                    return false
                }
            })

            if(invalidEmail){
                // @ts-ignore
                dispatch(Notifications.error(getNotificationMessage('One or more emails are invalid!')))
                return;
            }
          }

        
          
        let data:IObject = {
            id :orgDetails.id,
            status :  orgDetails.status,
            sharedLimitWithBranches : orgDetails.sharedLimitWithBranches,
            alertThreshold : orgDetails.alertThreshold,
            alertAddressesList: orgDetails.alertAddressesList,
            emailAlertStatus: orgDetails.emailAlertStatus,
        }  
        if(newLimits[limitEditIndex]){
            data.limits = [newLimits[limitEditIndex]] ;
        }
        try{
            const result = await this.service.saveSettings(data);
            dispatch(this.createAction(this.actions.SAVE_LIMIT_SETTINGS,{ ...result } )); 
            // @ts-ignore  
            dispatch(Notifications.success(getNotificationMessage('Saved successfully.')));
        }catch(e){
            handleError(e)
        }
    }

    public addLimit = () => (dispatch: Function) =>{
        dispatch(this.createAction(this.actions.ADD_LIMIT ));   
    }

    public handleChange = (pair: IPair) => (dispatch: Function) =>{
        dispatch(this.createAction(this.actions.HANDLE_CHANGE_LIMIT, { pair }));
    }

    public resetOrgDetails = () => (dispatch: Function) => {
        dispatch(this.createAction(this.actions.LIMIT_RESET_ORG_DETAILS));
    } 

    public applyFilters = (filterObjectParams:any) => (dispatch: Function) =>  {
        // dispatch(this.createAction(this.actions.SET_DISTRIBUTION_LOADING), {isLoading})
        
        // const { stringParams } = filterObjectParams;

      }



}

export default new DistributionControlAction();