import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { objLib } from '@xc-core/lib';
import * as routes from '@xc-core/routes';
import * as materialUI from '@material-ui/core';
import { Modal } from '@xc-core/components/core';
import getQuoteGraphic from '../../images/bannerGraphicImages/get-quote.png';
import { TitleComponent } from '../../_components';

const LeadItemFields : string[] = [
  'Created At Date',
  'Created At Time',
  'Lead Name',
  'Age',
  'Mobile No',
  'Email Address',
  'State',
  'Preferred Call Time',
  'Enquiry Reason',
];

const UtmParams = ({ item }: IObject) => {
  const [showUrl, changeShowUrl] = useState(false);
  return (
    <>
      <materialUI.Card className='leadMarketingInfo'>
        <materialUI.CardContent>
          <div className='text-info'>
            <p>Marketing info</p>
            <div className='row'>
              <div className='col mb-3'>
                <div className='border-top' />
              </div>
            </div>
            <div className='row'>
              <div className='col-6'><p>URL:</p></div>
              <div className='col-6'>
                <button
                  type='button'
                  className='p-0 wordBreak summary-label-2 button-as-link'
                  onClick={() => changeShowUrl(true)}
                >
                  View Full URL
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'><p>Source</p></div>
              <div className='col-6'><p>{item.source || '-'}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'><p>Medium</p></div>
              <div className='col-6'><p>{item.medium || '-'}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'><p>Campaign</p></div>
              <div className='col-6'><p>{item.campaign || '-'}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'><p>Content</p></div>
              <div className='col-6'><p>{item.content || '-'}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'><p>Term</p></div>
              <div className='col-6'><p>{item.term || '-'}</p></div>
            </div>
          </div>
        </materialUI.CardContent>
      </materialUI.Card>
      <Modal
        id='marketing-url'
        title='Full URL'
        isOpen={showUrl}
        toggle={() => changeShowUrl(!showUrl)}
      >
        <div className='text-center wordBreak' style={{ maxWidth: '600px' }}>
          {item.fullURL}
        </div>
      </Modal>
    </>
  );
};


const LeadInfo = (props: IObject) => {
  const { currentLeadItem, history } = props;
  let fieldsAndValues : IObject = {};
  useEffect(() => {
    if (!currentLeadItem) history.push(`/${routes.lead.base}`);
  }, []);
  currentLeadItem && Object.keys(currentLeadItem).forEach((key) => {
    switch (key) {
      case 'common':
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[0]]: objLib.getValueWithLodash(currentLeadItem, ['common', 'createdOn']) };
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[1]]: objLib.getValueWithLodash(currentLeadItem, ['common', 'updatedOn']) };
        break;
      case 'customerName':
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[2]]: currentLeadItem[key] };
        break;
      case 'age':
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[3]]: currentLeadItem[key] };
        break;
      case 'mobileNo':
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[4]]: currentLeadItem[key] };
        break;
      case 'emailAddress':
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[5]]: currentLeadItem[key] };
        break;
      case 'state':
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[6]]: currentLeadItem[key] };
        break;
      case 'preferredCallTime':
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[7]]: currentLeadItem[key] };
        break;
      case 'enquiryReason':
        fieldsAndValues = { ...fieldsAndValues, [LeadItemFields[8]]: currentLeadItem[key] };
        break;
      default:
    }
  });

  return (
    <div>
      <TitleComponent
        title={currentLeadItem ? currentLeadItem.customerName : ''}
        bannerGraphic={getQuoteGraphic}
        backTitle='Back to listing'
        backPath={`/${routes.lead.base}`}
        history={history}
        noBorderBottom={false}
      />
      <div className='container-fluid px-0 normal-gray-text'>
        <div className='row d-flex justify-content-sm-between my_30 align-items-start'>
          <div className='col-sm-6' style={{ padding: '0 4%' }}>
            {currentLeadItem && LeadItemFields.map((key, index) => (
              <div className='row' key={index}>
                <div className='col-3'>
                  <p>
                    {key}
                  </p>
                </div>
                <div className='col-3'>
                  <p>{fieldsAndValues[key]}</p>
                </div>
              </div>
            ))}
          </div>
          {currentLeadItem && currentLeadItem.fullURL ? <UtmParams item={currentLeadItem || {}} /> : <></>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ leadList }: IStore) => ({
  currentLeadItem: leadList.currentLeadItem,
});
const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LeadInfo);
