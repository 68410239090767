const getQuote: IRoutes = {
  base: 'getQuote',
  detailParam: 'name',
};

const quotation: IRoutes = {
  base: 'quotations',
  detailParam: 'id',
};

const policy: IRoutes = {
  base: 'policies',
  detailParam: 'id',
};
const lead: IRoutes = {
  base: 'leads',
  detailParam: 'name',
};
const audit: IRoutes = {
  base: 'auditlogs',
  detailParam: 'id',
};

const organization: IRoutes = {
  base: 'organizationsManagement',
  detailParam: 'name',
};

const user: IRoutes = {
  base: 'userManagement',
  detailParam: 'name',
};

const userGroup: IRoutes = {
  base: 'userGroup',
  detailParam: 'name',
};
const passwordManagement: IRoutes = {
  base: 'passwordManagement',
  detailParam: 'name',
};

export {
  getQuote,
  quotation,
  policy,
  organization,
  user,
  userGroup,
  lead,
  audit,
  passwordManagement
};
