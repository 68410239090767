import React from 'react';
import { connect } from 'react-redux';
import { TitleComponent, TablesUi } from '_components';
import MultiSelectCheckbox from '_components/MultiSelectCheckbox';
import DeleteForm from './DeleteForm';
import {
  Input, Button
} from 'reactstrap';
import { Loader } from '@xc-core/components/core';
import ButtonCircular from '@xc-core/components/core/buttonCircular';
import { orgActions } from './../../OrganizationModule/actions';
import { resActions } from './../actions';
import { resServices } from './../services';
import Notification from 'react-notification-system-redux';
import getResourceGraphic from "../../../images/bannerGraphicImages/get-quote.png";
import { mediaLib } from '@xc-core/lib';

const heading = [
  {
    id: 0,
    headerName: 'Order',
    key: 'order',
    style: {width: '80px'}
  },{
    id: 1,
    headerName: 'Document Title',
    key: 'documentTitle'
  },{
    id: 2,
    headerName: 'File',
    key: 'file',
    style: {width: '27%'}
  },{
    id: 3,
    headerName: 'Share With (Organization)',
    key: 'allowedOrganizations',
    style: {width: '220px'}
  },{
    id: 4,
    headerName: 'Actions',
    key: 'actions',
    style: {width: '100px'}
  }
];

class OrganisationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isGettingOrgList: false,
      organisations: [],
      resources: [],
      backPath: '',
      requestDeleteIndex: false
    };
  }

  async componentDidMount() {
    const { history } = this.props;
    let componentType;

    this.setState({isLoading: true});
    this.setState({resources: await this.props.getResManageList()});
    this.setState({isLoading: false});

    this.handleOrganizationList();
    
    const backPath = history.location.pathname.split("/");
    this.setState({
      componentType,
      backPath: [...backPath.slice(0, 2)].join("/"),
    });
  }

  createNewResource = () => {
    const { resources, organisations } = this.state;
    const allowedOrganizations = organisations.map(item => item.value);
    const maxId = resources.length === 0 ? 0 : resources.reduce(
      (max, character) => (character.sortOrder > max ? character.sortOrder : max),
      resources[0].sortOrder
    );
    this.setState({resources: [
      ...resources,
      {
        key: Math.floor(Math.random().toString() * 100000),
        id: null,
        sortOrder: maxId + 1,
        title: '',
        file: null,
        allowedOrganizations,
        errors: false
      }
    ]})
  }

  handleDownloadResource = async (resourceId, index) => {
    const { resources } = this.state;
    const blob = await resServices.downloadResource(resourceId);

    const fileName = resources[index].fileName.split('/');
    mediaLib.downloadFile(fileName[fileName.length - 1], blob, false);
  };

  deleteResource = async () => {
    const { resources, requestDeleteIndex } = this.state;

    if(resources[requestDeleteIndex].id > 0){
      this.setState({isLoading: true});
      const result = await this.props.deleteRes(resources[requestDeleteIndex].id);
      if(result){
        this.setState({resources: await this.props.getResManageList()});
      }
      this.setState({isLoading: false, requestDeleteIndex: false});
    } else {
      this.setState({requestDeleteIndex: false, resources: resources.filter((item) => item.key !== resources[requestDeleteIndex].key)});
    }
  };

  handleOrganizationList = async () => {
    this.setState({isGettingOrgList: true});
    const organizationList = await this.props.getOrgList();
    this.setState({isGettingOrgList: false});

    let organisations = [];
    organizationList.map(org => {
      return organisations.push({
        "id": org.id,
        "value": org.name,
        "label": org.name
      })
    });
    this.setState({organisations});
  }

  handleAllowedOrganizationsChange(index, data) {
    let itemList = [];
    data.map(item => (item.value !== '*') && itemList.push(item.value));
    this.changeValue(index, 'allowedOrganizations', itemList);
  }

  handleFileChange(index, attachedFile) {
    if(attachedFile.length){
      const allowTypes = ['.pdf', '.txt', '.doc', '.docx', '.odt', '.png', '.jpeg', '.jpg'];
      const fileType = attachedFile[0].name.split('.');
      if(allowTypes.findIndex(item => item === `.${fileType[fileType.length - 1]}`) < 0) {
        this.changeValue(index, 'errors', [`You should upload a file with these types: ${allowTypes.join(', ')}`]);
      } else if(attachedFile[0].size > 5242880){
        this.changeValue(index, 'errors', ['Uploaded file cannot be bigger than 5MB']);
      } else {
        this.changeValue(index, 'file', attachedFile);
      }
    }
  }

  changeValue(index, key, value){
    let { resources } = this.state;
    if(resources[index]){
      resources[index]['errors'] = false;

      if(key === 'sortOrder'){
        value = parseInt(value.toString().replace(/\D/g,''));

        if(isNaN(value))
          value = "";
      }

      resources[index][key] = value;

      this.setState({resources});
    }
  }

  validateFormItem(item, index){
    let { resources } = this.state;
    let errors = [];

    if(resources[index]['errors'] && resources[index]['errors'].length > 0) return false;
    if(item.sortOrder <= 0 || item.sortOrder === '') errors.push('Order is required');
    if(item.title === '') errors.push('Document title is required');
    if(!item.allowedOrganizations.length) errors.push('Organisation is required');
    if(!item.id && !item.file) errors.push('File is required');

    resources[index]['errors'] = (errors.length) ? errors : false;

    this.setState({resources});
    return (!errors.length);
  }

  async handleSubmit(){
    const { resources } = this.state;
    if(!resources.length) {
      this.props.showNotification('Please add at least 1 record.', 'error');
      return;
    }

    let formData = new FormData();
    let resourceData = [];
    let isValid = true;
    
    resources.map((item, index) => {
      const validationResult = this.validateFormItem(item, index);
      if(!validationResult) isValid = false;
      let name = '';

      if(item.file && item.file[0]){
        name = item.file[0].name;
        formData.append("files", item.file[0], name);
      }

      return resourceData.push({
        fileName: name,
        resourceId: item.id ? item.id : null,
        order: item.sortOrder,
        title: item.title,
        allowedOrganizations: item.allowedOrganizations
      })
    })

    formData.append("resource-data", JSON.stringify({detailDtos: resourceData}));

    if(isValid){
      this.setState({isLoading: true});
      const result = await this.props.updateRes(formData);
      if(!result.error) {
        this.setState({resources: await this.props.getResManageList()});
      }
      this.setState({isLoading: false});
    }
  }


  render() {
    const { isLoading, isGettingOrgList, resources, organisations, requestDeleteIndex } = this.state;

    return (
      <div>
        <TitleComponent 
          title='Resource Center - Manage Documents'
          bannerGraphic={getResourceGraphic}
          backTitle='Back to listing'
          backPath={this.state.backPath}
          history={this.props.history}
        />
        
        <div className='container-fluid px-0'>
          <div className='row d-flex justify-content-center my_30'>
            <div className='col-sm-12'>
              {isLoading && <Loader /> }
              <TablesUi
                heading={heading}
                tableData={resources.map((item, index) => ({
                  key: item.key,
                  validationErrors: item.errors,
                  order: <Input
                    className="resource-management-order"
                    min={1}
                    value={item.sortOrder}
                    onChange={e => this.changeValue(index, 'sortOrder', e.target.value)}
                  />,
                  documentTitle: <Input
                    type="text"
                    maxLength={100}
                    value={item.title}
                    placeholder="Type your document title"
                    onChange={e => this.changeValue(index, 'title', e.target.value)}
                  />,
                  file: (item.file && item.file[0].name) ? <span>{item.file[0].name}</span> : 
                    (item.fileName) && <span className="text-primary" style={{cursor:'pointer'}} onClick={() => this.handleDownloadResource(item.id, index)}>
                    {(item.fileName.split('/'))[item.fileName.split('/').length - 1]}
                  </span>,
                  allowedOrganizations: organisations.length && <MultiSelectCheckbox 
                    label={'Orgs'}
                    options={organisations}
                    selected={item.allowedOrganizations}
                    onChange={(value) => this.handleAllowedOrganizationsChange(index, value)}
                  />,
                  actions: <div className="d-flex direction-row justify-content-center">
                    <div className="upload-area">
                      <ButtonCircular
                        className='table-row-button mr-1 ml-1'
                        // handler={() => toggleEditAction(index,limitEditIsNew)}
                        key='row-action-edit'
                        icon='file'
                        id='record.edit'
                        name='details'
                      />
                      <input
                        type="file"
                        onChange={ (e) => this.handleFileChange(index, e.target.files) }
                        className="select-file"
                        
                      />
                    </div>
                    <ButtonCircular
                      className='table-row-button mr-1 ml-1 border-danger text-danger'
                      handler={() => this.setState({requestDeleteIndex: index})}
                      key='row-action-delete'
                      icon='garbage'
                      id='record.delete'
                      name='details'
                    />
                  </div>
                }))}
                >
                </TablesUi>
            </div>
          </div>
          <div className="d-flex justify-content-end m-5">
            <Button
              className={`btn btn-outline-primary mr-3`}
              disabled={isLoading || isGettingOrgList}
              onClick={() => this.createNewResource()}
            >
              Add New Document
            </Button>
            <Button
              className={`btn btn-primary`}
              disabled={isLoading}
              onClick={() => this.handleSubmit()}
            >
              Save
            </Button>
          </div>
          <DeleteForm
            isOpen={(requestDeleteIndex !== false)}
            handleSubmit={() => this.deleteResource()}
            toggleModal={() => this.setState({requestDeleteIndex: false})}
          />
        </div>
      </div>

    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // hasPermissionTo: (scope) => dispatch(orgActions.hasPermissionTo(scope)),
    getResManageList: () => dispatch(resActions.getManageList()),
    deleteRes: (id) => dispatch(resActions.deleteRes(id)),
    updateRes: (data) => dispatch(resActions.updateRes(data)),
    getOrgList: () => dispatch(resActions.getOrganizationList()),
    showNotification: (message, type) => dispatch(Notification.show({title: message}, type)),
    removeNotification: () => dispatch(orgActions.updateNotification()),
  };
};

export default connect(null, mapDispatchToProps)(OrganisationDetails);
