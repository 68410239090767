import { env } from "_config/env";
import { getRequestOptions } from "../utils/index";

const apiUrl = env.apiURL;

async function logout() {
	await fetch(`${apiUrl}/identity/users/logout`, {
		method: "POST",
		headers: new Headers({
			"Content-Type": "application/json",
			"X-Auth-Token": env.token,
		}),
	});
	localStorage.clear();
	sessionStorage.clear();
	window.location.href = "#/";
	return true;
}

async function handleResponse(response, isFile = false) {
	if (response.error && response.error.message === "server error") {
		return response;
	}

	try {
		if (response.status === 401) { // Unauthorized
			await logout();
			window.location.reload();
			return [];
		}
		if (isFile) {
			return response.blob();
		}
		return await response.json();
	} catch (e) {
		return e;
	}
}

async function handleRequest(url, requestOptions) {
	// this should have all the the requests to the server

	try {
		const res = await fetch(url, requestOptions);
		return res;
	} catch (e) {
		if (e.message === "Failed to fetch") {
			setTimeout(() => { // logout 2 seconds after displaying the error notification
				sessionStorage.clear();
				window.location.reload();
			}, 2000);
			return {
				error: { message: "server error" },
			};
		}
		return e;
		// Error :(
	}
}

/**
 * @function checkPermission - this function doesn't  get to the store
 * @param {string} scope - the permission we want to test
 * @returns {object} - this object has a key of status and the value is either "permitted/denied"
 */
async function checkPermission(scope = "") {
	const url = `${apiUrl}/authorization/resources/trust?scope=${scope}`;
	const option = getRequestOptions();
	const response = await handleRequest(url, option);
	const handledResponse = await handleResponse(response);
	return handledResponse;
}

const getNotificationMessage = (message) => ({
	title: message,
	position: "tr",
	autoDismiss: 2,
});

export {
	handleResponse,
	logout,
	handleRequest,
	getNotificationMessage,
	checkPermission,
};
