import React, {useEffect} from 'react';
import {connect} from 'react-redux';


interface IPlanButtonProps {
    type: string
    isSelected: boolean
    value: string
    onClick: () => void
}

interface ILoadableComponentPropsCBPlanSelection extends ILoadableComponentProps {
    storeItem: IObject,
}

const LoadablePlanSelectionComponent = (props: ILoadableComponentPropsCBPlanSelection) => {
    const {
        storeItem,
        model: {
            model: {forms},
            currentStep,
            selectedPlan,
            onChange,
            skipAutoNextOnPlanSelection,
            previous
        },
    } = props;
    const {fields: [planModel]} = forms;
    useEffect(() => {
        if (!skipAutoNextOnPlanSelection) {
            let priceList: IObject[] = [];
            if (storeItem && storeItem.properties && storeItem.properties.priceList) {
                priceList = storeItem.properties.priceList;
            }
            const selectedPlan = priceList.filter((item: any) => {
                if (item.Plan === storeItem.properties.Plan) {
                    return true
                }
                return false;
            })
            if (selectedPlan.length > 0) {
                planModel.handler(currentStep, {
                    'Admin Fee': selectedPlan[0]['Admin Fee'],
                    'Main Policy': selectedPlan[0]['Main Policy'],
                    'Tax': selectedPlan[0]['Tax'],
                    'Total': selectedPlan[0]['Total'],
                    "Additional Driver Addon": selectedPlan[0]['Additional Driver Addon'],
                    "Plan": storeItem.properties.Plan
                })
            }
        } else {
            previous('skipAutoNextOnPlanSelection');
        }
    }, [])

    return null;
};
const mapStateToProps = ({newGetQuote}: IObject) => ({
    storeItem: newGetQuote.item,
});

export default connect(mapStateToProps, () => ({}))(LoadablePlanSelectionComponent);
