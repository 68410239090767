import {handleRequest, handleResponse} from "../../../_services";
import {getRequestOptions} from "../../../utils";

const Config = require("_config/env");

const apiUrl = Config.env.apiVersion;

export const userGroupServices = {
  getUserGroupsList,
  createUserGroups,
  getGroupDetails,
  updateUserGroupDetails,
  getCreateGroupDetails,
  deletedUserGroup
};

async function getUserGroupsList() {
  const serverResponse = await handleRequest(`${apiUrl}/identity/groups/with-associations`, getRequestOptions());
  if (serverResponse.error) return serverResponse;
  return await handleResponse(serverResponse);
}

async function createUserGroups(body) {
  const url = `${apiUrl}/identity/groups/with-associations`;
  const serverResponse = await fetch(url, getRequestOptions({method: "Post" , body: body}));
  return await handleResponse(serverResponse);
}

async function getCreateGroupDetails() {
  const serverResponse = await fetch(
    `${apiUrl}/identity/groups/init/with-associations`,
    getRequestOptions()
  );
  return  await handleResponse(serverResponse);
}

async function getGroupDetails(name) {
  const serverResponse = await  fetch(
    `${apiUrl}/identity/groups/${name}/with-associations`,
    getRequestOptions()
  );
  return await handleResponse(serverResponse);
}

async function updateUserGroupDetails(name, body) {
  const url = `${apiUrl}/identity/groups/${name}/with-associations`;
  const response = await fetch(url,getRequestOptions({method:"PUT", body: body}));
  return await handleResponse(response);
}
async function deletedUserGroup(name) {
  const url = `${apiUrl}/identity/groups/${name}`;
  const serverResponse = await handleRequest(url, getRequestOptions({method: "DELETE"}));
  return await handleResponse(serverResponse);
}
