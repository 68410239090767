import { DashboardConsts } from '../constants';

export function dashboard(state = { data: {} } , action) {
    switch(action.type) {
        case DashboardConsts.GET_DASHBOARD:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}