import React from 'react';
import { dateLib } from '@xc-core/lib';
import config from '@xc-core/config';

interface IProps {item: any, children: any}

const Title = (props: IProps) => {
  const { item, children } = props;
  const createdOn = dateLib.applyFormat(item && item.common && item.common.createdOn, config.dateTimeMomentFormat);
  const updatedOn = dateLib.applyFormat(item && item.common && item.common.updatedOn, config.dateTimeMomentFormat);
  const createdBy = (item && item.common && item.common.createdByName) || '';
  const updatedBy = (item && item.common && item.common.updatedByName) || '';
  return (
    <>
      <h5 className='text-medium-qc font-size-large' style={{
          color:"#586FA3"
      }}>{`${item.displayName}: ${item.displayId}`}</h5>
      {children}
      <p className='m-0'>{`Created by ${createdBy}, at ${createdOn}`}</p>
      <p className='m-0'>{`Updated by ${updatedBy}, at ${updatedOn}`}</p>
    </>
  );
};

export default Title;
