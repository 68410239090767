import React from 'react';
import Chip from 'react-toolbox/lib/chip';

const FilterButton = (props: { onClick: Function }) => {
  const { onClick } = props;
  return (
    <Chip
      className='filterBtn px-3 mx-2'
      onClick={() => onClick()}
    >
      <i className='icon-add font-size-small mx-1' />
      <span>Add Filter</span>
    </Chip>
  );
};

export default FilterButton;
