
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { fieldLib, objLib } from '@xc-core/lib';
import lib from 'utils/lib';

const BeneficiaryDetail = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
      initValues,
    sidebar
    },
  } = props;

  useEffect(() => {
    const beneficiary = objLib.getValueWithLodash(item, model.fields.beneficiary.id) || {};
    const pairs: IPair[] = [{ id: model.fields.beneficiary.id, value: beneficiary }];
    initValues(pairs);

    setTimeout(()=>{
      onChange('onChange', { pairs:    [
          {
            "id": "properties.beneficiaries.Full Name",
            "value": "  "
          }
        ] })
      setTimeout(()=>{
        onChange('onChange', { pairs:    [
            {
              "id": "properties.beneficiaries.Full Name",
              "value": ""
            }
          ] })
      },100)
    },400)


  }, []);

  return (
    <Row>
      <Col md='8'>
        <h5 className='sub-title'>Please enter beneficiary details {lib.checkIsDemoSite() && '/ សូមបំពេញព័ត៌មានលម្អិតរបស់អ្នកទទួលផល'}</h5>

        <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={collections}
          item={item}
          ext={lib.checkIsDemoSite()}
          validationErrors={validationErrors}
          fields={fieldLib.applyIndexToFieldsRawId(0, model.beneficiaryDetail.fields)}
        />

      </Col>
      <Col md='4'>
        <SidebarCard.Quotation
          customIconProduct={"icon-hospital"}
          item={item}
          model={sidebar}
          ext={lib.checkIsDemoSite()}
        />
      </Col>
    </Row>
  );
};

export default BeneficiaryDetail;
