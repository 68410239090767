const model = {
  header: {
    id: '',
    name: '',
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    items: [
      {
        current: { id: 'properties.Price.Total', name: '', config: { currency: true } },
        prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: 'Price Breakdown',
        name: 'Price Breakdown',
        items: [
          {
            current: { id: 'properties.Price.Main Policy', name: 'Net Basic Premium', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Main Policy', name: 'Net Basic Premium', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.SST', name: 'SST 6%', config: { currency: true } },
            prev: { config: {} },
            config: {}
          },
          {
            current: { id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: { currency: true } },
            prev: { config: {} },
            config: {}
          },
          {
            current: { id: 'properties.Price.Admin Fee', name: 'Admin Fee', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Admin Fee', name: 'Admin Fee', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      {
        id: 'Commission Breakdown',
        name: 'Commission Breakdown',
        items: [
          {
            current: { id: 'properties.Commission Amount', name: 'Commission Amount', config: { currency: true } },
            prev: { id: 'properties.Previous Commission', name: 'Commission Amount', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
    ],
  },
};

export default model;
