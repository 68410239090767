
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    total: { id: 'properties.Price.Total', name: 'Total Price', config: {} },
    config: {},
  },
  body: {
    items: [
      {
        id: 0,
        title: { id: 'Price Breakdown', name: 'Price Breakdown' },
        items: [
          { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
          { id: 'properties.Price.Admin Fee', name: 'Admin Fee', config: { currency: true } },
          { id: 'properties.Price.Tax', name: 'Tax', config: { currency: true } },
        ],
        config: {},
      },
      {
        id: 1,
        title: { id: 'Other Details', name: 'Other Details' },
        items: [
          { id: 'properties.Plan', name: 'Plan', config: {} },
        ],
        config: {},
      },
    ],
  },
};

export default model;
