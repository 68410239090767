import baseFields from './base-fields';

const { quotation, policy } = baseFields;

const paymentModel = [
  policy.paymentModeName,
  policy.paymentId,
];

const marketingInfoModel = [
  quotation.marketingSource,
  quotation.marketingMedium,
  quotation.marketingCampaign,
  quotation.marketingContent,
  quotation.marketingTerm,
  quotation.marketingReferralCode,
];

const baseModel = { paymentModel, marketingInfoModel };

export default baseModel;
