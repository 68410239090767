import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

// -----------------For Old State------------------------------
import { getQuoteActions } from 'Products/shared/GetQuote/actions';
// -----------------------------------------------------------

import * as routes from '@xc-core/routes';
import { BaseComponent } from '@xc-core/components';
import { Message, Header, Loader } from '@xc-core/components/core';
import { userMessages } from '@xc-core/constants';
import { actions } from '@redux';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import ProductButton from './productButton';


const productAction = new actions.ProductActions();
const getQuoteAction = new actions.GetQuoteActions();


interface IRenderProductsProps {
  onSelect: (product: IProduct) => void,
  products: IProduct[],
  selectedProduct: IProduct | null,
}

const RenderProducts = (props: IRenderProductsProps) => {
  const { products, selectedProduct, onSelect } = props;
  return (
    <div className='row d-flex justify-content-center my_50'>
      <div className='col-10'>
        <div>
          <Grid container spacing={24}>
            {
              products.map((product:IProduct) => (
                <ProductButton
                  key={product.id}
                  product={product}
                  isSelected={selectedProduct ? product.id === selectedProduct.id : false}
                  handler={() => onSelect(product)}
                />
              ))
            }
          </Grid>
        </div>
      </div>
    </div>
  );
};

interface IProps {
  history: any,
  getQuote: IGetQuoteState,
  products: IProductListState,
  setGetQuotePermission: () => void,
  setProductList: () => void,
  initProduct: () => void,
  setProduct: (product: IProduct) => void,
  resetGetQuote: () => void,
  // ---------------------Old------------------------
  oldStoreSelectProduct: (product: IProduct) => void,
  oldGetCollections: (productCode: number) => void,
  oldResetQuotation: () => void,
  oldItem: any,
}
class ProductSelection extends BaseComponent<IProps, {}> {
  async componentDidMount() {
    const {
      setGetQuotePermission,
      setProductList,
      initProduct,
      oldResetQuotation,
      resetGetQuote,
    } = this.props;

    oldResetQuotation();

    await resetGetQuote();
    await setGetQuotePermission();
    await setProductList();
    initProduct();
  }

  onProductSelect = async (product: IProduct) => {
    const {
      history,
      setProduct,
      oldStoreSelectProduct,
      oldGetCollections,
    } = this.props;

    await oldGetCollections(product.id);
    await oldStoreSelectProduct(product);

    setProduct(product);
    history.push(`${routes.getQuote.base}/${product.id}`);
  }

  getProductsWithoutAll = (products: IProductListState) => {
    // for more than one product the first one is "All" which is added manually
    if (products.list && products.list.length > 1) return [...products.list.slice(1)];
    if (products.list && products.list.length === 1) return products.list;
    return [];
  }

  render() {
    const { getQuote, products } = this.props;
    if (getQuote.accessIsForbidden || (products.list && products.list.length < 0)) {
      return <Message message={userMessages.ACCESS_DENIED} />;
    }
    if (products.list && products.list.length === 1 && getQuote.product) {
      return <Redirect to={`${routes.getQuote.base}/${products.list[0].id}`} />;
    }
    return (
      <div className='container-fluid px-0' data-testid="containter">
        <Header
          title='Get Quote'
          bannerGraphic={bannerImg}
          withBorderBottom
        />
        {
          products.isLoading ? <Loader />
            : (
              <RenderProducts
                onSelect={(product: IProduct) => this.onProductSelect(product)}
                products={this.getProductsWithoutAll(products)}
                selectedProduct={getQuote.product}
              />
            )
        }
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  getQuote: state.newGetQuote,
  products: state.productList,
  oldItem: state.getQuote,
  // oldSelectedProductCode: state.getQuote,
});

const mapDispatchToProps = (dispatch: any) => ({
  setGetQuotePermission: () => dispatch(getQuoteAction.setCreatePermission()),
  setProductList: () => dispatch(productAction.setList()),
  initProduct: () => dispatch(getQuoteAction.initProduct()),
  setProduct: (product: IProduct) => dispatch(getQuoteAction.setProduct(product)),
  resetGetQuote: () => dispatch(getQuoteAction.reset()),
  // -------------------------------Old-----------------------------------------------------
  oldStoreSelectProduct: (product: any) => dispatch(getQuoteActions.selectProduct(product)),
  oldGetCollections: (productCode: number) => dispatch(getQuoteActions.getCollections(productCode)),
  oldResetQuotation: () => dispatch(getQuoteActions.resetQuotation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelection);
