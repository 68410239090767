import React, {useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {utils} from 'utils';

const RenderDetails = (props) => {
    const {model, item} = props;
    return (
        <>
            {
                model ? model.items.map((modelItem) => {
                    return <RenderDetail key={modelItem.id} model={modelItem} item={item}/>;
                }) : <></>
            }
        </>
    );
};
const RenderDetail = (props) => {
    const {item, model} = props;
    const {lib: {resolveObjKey}} = utils;
    return (
        <>
            <Col className='pt-2 summary-label' sm={6}>{model.name}</Col>
            <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                {item ? resolveObjKey(item, model.id.replace("properties.additional drivers[_#_].","")) : ''}
            </Col>
        </>
    );
};
const RenderInsuredDetails = (props) => {
    const {model, item} = props;
    const additionalDrivers = item?.properties["additional drivers"];
    return (
        <Row>
            <Col sm={12}>
                <h4 className='beneficiary-header my-3 p-3'>
                    Adult 1 (Main Applicant)
                </h4>
                <Row className='wordBreak'>
                    <Col sm={6}>
                        <div className='custom-q-text-primary bold my-2'>Insured Person Details</div>
                        <Row>
                            <RenderDetails model={model.insured} item={item}/>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        {additionalDrivers && additionalDrivers.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className='custom-q-text-primary bold my-2'>Additional Driver [{index+1}]</div>
                                    <Row>
                                        <RenderDetails key={`additionalDrivers${index}`} model={model.additionalDrivers} item={item}/>
                                    </Row>
                                </div>
                            )
                        })}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
export default RenderInsuredDetails;
