import React, { Fragment } from "react";
import { Col } from "reactstrap";
import {CommonInputTypes} from "../../../../_components/CommonInputTypes"
import config from '_config';
import { defaultValues } from '@products/AlfalahWallet/config';

class PersonalInfo extends React.Component {
  componentDidMount() {
    /**
     * set the country automatically if there is only one country
     */
    // if (countries && countries.length === 1) {
    //   curentThis.forceUpdate(() => {
    //     curentThis.setState({
    //       properties: {...curentThis.state.properties, Country: countries[0].name}
    //     })
    //   })
    // }
  }

  handleNumberChange = (e, length) => {
    let { value } = e.target;
    value = value && value.length > length
      ? value.slice(0, length) : value;
    if (Number.isInteger(Number(value))) {
      this.props.handleInputChange({ target: { id: e.target.id, name: e.target.name, value } });
    }
  };

  getDefaultCountry = () => config.products.cashForHospitalization.defaultValues.country;

  render() {
    const { updatedVal,collections } = this.props;
    return (
      <Fragment>
        <Col sm="12">
          <CommonInputTypes
            idName={"Address"}
            type={"input"}
            value={updatedVal["Address"] || ""}
            onChange={this.props.handleInputChange}
            title={"Address"}
            id={1}
            group={false}
            placeholder={"Enter address"}
            validator={this.props.validator}
            validationRules="custom_required"
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"Country"}
            type="select"
            value={updatedVal["Country"] || this.getDefaultCountry()}
            onChange={this.props.handleInputChange}
            title={"Country"}
            id={1}
            group={false}
            option={collections.countries}
            validator={this.props.validator}
            disabled //={collections.countries.length <= 1}
            
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"State"}
            type="select"
            value={updatedVal["State"]}
            onChange={this.props.handleInputChange}
            title={"State/Province/Region"}
            id={1}
            group={false}
            option={collections.states ? collections.states : []}
            // validator={this.props.validator}
            validationRules="custom_required"
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"Postcode"}
            type="input"
            value={updatedVal["Postcode"] || ""}
            onChange={this.props.handleInputChange}
            title={"Postcode"}
            id={1}
            group={false}
            // validator={updatedVal["Postcode"] ? this.props.validator : ''}
            validationRules={updatedVal["Postcode"] ? `min:${defaultValues.postcodeLength}|max:${defaultValues.postcodeLength}` : ''}
            placeholder={"Enter postcode"}
            // validationRules="custom_required"
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"City"}
            type="input"
            value={updatedVal["City"] || ""}
            onChange={this.props.handleInputChange}
            title={"City/Town"}
            id={1}
            group={false}
            validator={this.props.validator}
            placeholder={"Enter City/Town"}
            validationRules="custom_required"
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName={"Email Address"}
            type="email"
            value={updatedVal["Email Address"] || ""}
            onChange={this.props.handleInputChange}
            title={"Email Address"}
            id={1}
            group={false}
            validator={this.props.validator}
            placeholder={"Enter email address"}
            validationRules="custom_required|email"
          />
        </Col>  
        <Col sm="6">
          <CommonInputTypes
            idName={"Contact Number"}
            type="input"
            value={updatedVal["Contact Number"] || ""}
            onChange={(e) => this.handleNumberChange(e, defaultValues.contactNumberLength)}
            title={"Contact Number"}
            id={1}
            group={false}
            validator={this.props.validator}
            validationRules={`custom_required|min:${defaultValues.contactNumberLength}|max:${defaultValues.contactNumberLength}`}
            placeholder={"Enter contact number"}
          />
        </Col>  
      </Fragment>
    );
  }
}

export default PersonalInfo;
