
import React from 'react';
import { Col, Row } from 'reactstrap';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import PaymentForm from './PaymentForm';
import lib from 'utils/lib';

const PaymentDetails = (props: ILoadableComponentProps) => {
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            db
        },
    } = props;
    // const { usePaymentGateway } = JSON.parse(sessionStorage.getItem('user') || '');
    const usePaymentGateway = item.properties['usePaymentGateway'];
    if (usePaymentGateway) {
        /**
         * string status is added to the query params on the redirected url form the payment gateway.
         * it is not supposed to load this component if it has been to the payment gateway
         * unless the payment failed
         */
        // if (search && search.includes('status')) {
        //   return (
        //     <div
        //       className='d-flex m-5 text-primary justify-content-center align-items-center'
        //     >
        //       <h2>Sorry, Something went wrong.</h2>
        //     </div>
        //   );
        // }
        return (
            <>
                <PaymentForm item={item} />
            </>
        );
    }
    return (
        <Row>
            <Col md='12'>
                <h5 className='sub-title'>{lib.withKhmerText(model.title,db)}</h5>
                <RenderFields
                    onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
                    collections={collections}
                    item={item}
                    validationErrors={validationErrors}
                    fields={model.paymentDetails.fields}
                />
            </Col>
        </Row>
    );
};

export default PaymentDetails;
