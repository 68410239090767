
/**
 * get item from session storage
 * @param key
 */
const getItem = (key: string) => {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

/**
 * set item in session storage
 * @param key
 * @param item
 */
const setItem = (key: string, item: object) => {
  const objStr = JSON.stringify(item);
  sessionStorage.setItem(key, objStr);
};

/**
 * remove item from session storage
 * @param key
 */
const removeItem = (key: string) => {
  sessionStorage.removeItem(key);
};

/**
 * clear session storage
 */
const clear = () => {
  sessionStorage.clear();
};

const storage = {
  getItem, setItem, removeItem, clear,
};

export default storage;
