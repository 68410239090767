import React from 'react';
import { Row, Col } from "reactstrap";
import { SidebarCard, InsuredDetail } from '@xc-core/components';
import { sidebarModel, DetailModel } from 'models';

const Summary = props => {

	const { fields, properties, quotation } = props;
  return(
		<Row>
			<Col lg='8'>
        <InsuredDetail
          item={{
            insureds: [{ ...quotation.properties }],
            beneficiaries: quotation.properties.Beneficiaries,
          }}
          model={DetailModel.walletAlfalah}
        />
			</Col>
			<Col lg='4'>
				<SidebarCard.Quotation
                    item={quotation}
                    model={sidebarModel.quotation.walletAlfalah}
        		/>
			</Col>
		</Row>
	);
}

export default Summary;