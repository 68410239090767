import React from 'react';
// import * as reactstrap from 'reactstrap';
import { eventLib } from '@xc-core/lib';


const CustomButton = (props: IButtonFromControlModal) => {
  const {
    handler, id, className, disabled, name, type, children,
  } = props;
  const onClick = (e: any): void => {
    const pair = eventLib.getPair(e);
    handler(pair);
  };

  return (
    // <reactstrap.Button
    // eslint-disable-next-line react/button-has-type
    <button
      className={`xc-button btn col-12 my-2 rounded-xl ${className}`}
      id={id}
      onClick={(e) => onClick(e)}
      disabled={disabled}
      type={type}
    >
      {children}
      {name}
    </button>
    // </reactstrap.Button>
  );
};

export default CustomButton;
