
import { QuotationService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import { quotationConstants } from 'Products/shared/quotationListing/constants';
import {
  requestLib,
  filterLib,
  listLib,
} from '@xc-core/lib';
import BaseListAction from './baseListing';

class QuotationAction extends BaseListAction {
  constructor() {
    super();
    this.service = new QuotationService();
  }

  protected actions = actionTypes.quotation;

  protected service: QuotationService;

  protected csvExportScope = 'Export Quotations to CSV';

  protected createScope = '';

  protected editScope = '';

  public setList = (params: IRequestParam) => async (dispatch: any, getState: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      const { filters, filterToday } = getState().quotationList;
      const stringParams = requestLib.convertToRequestParams(params);
      let result = await this.service.getList(stringParams);
      const fields = listLib.convertToIdValueTypeList(result.fields, ['id', 'displayName', 'type']);
      let initialFilters = filterLib.parse(result.filters, params);
      if (filterToday) initialFilters = filterLib.applyTodayCreatedOn(initialFilters);
      result = {
        ...result,
        collections: this.prepareCollections(result.collections),
        list: this.prepareList(result.list.content, fields),
        fields,
        initialFilters,
        filters: filters.length > 0 ? filters : initialFilters,
        prevFilters: filters.length > 0 ? filters : initialFilters,
        pageInfo: this.createPageInfo(result.list),
        tab: this.createTabPair(params),
        searchKeyword: params.name ? params.name[0] : '',
      };
      dispatch(this.createAction<IObject>(this.actions.LIST_GET, result));
      dispatch(this.createAction(this.actions.LIST_RESET_TODAY_FILTER));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      if (error.status === 404) {
        dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }

  public getSearchParams = () => (dispatch: any, getState: any): IRequestParam => {
    const { quotationList } = getState();
    return this.getSearchParamsByItem(quotationList);
  }

  public setHasRecordsInitially= () => async (dispatch: any, getState: any) => {
    const { list } = getState().quotationList;
    const hasRecords = list && list.length > 0;
    dispatch(this.createAction<boolean>(this.actions.LIST_SET_HAS_RECORDS_INITIALLY, hasRecords));
  }

  public setTodayFilter = () => (dispatch: any) => {
    const action = this.createAction(this.actions.LIST_SET_TODAY_FILTER);
    dispatch(action);
  }

  public getStates = (country: string,
    param: string) => async (): Promise<IObject[]> => {
    try {
      const list = await this.service.getStates(country, param);
      const preparedList = listLib.extendWithIDValueForKeys(list, ['code', 'name']);
      return preparedList;
    // eslint-disable-next-line no-empty
    } catch (error) {
      return [];
    }
  }

  public setOccupationList = () => async (dispatch: any): Promise<void> => {
    const collectionId = 'occupation_gbsn';
    try {
      // const { collections } = getState().quotationManagement;
      const result = await this.service.getCollectionList([collectionId]);

      // const prepared = this.prepareCollections(result);
      // console.log('setOccupationList,result', result);
      // console.log('setOccupationList,result', prepared);
      const occupationList = { occupation: result[collectionId] };
      // console.log('updatedCollections', occupationList);
      dispatch(this.createAction<IObject>(quotationConstants.QUOTATION_UPDATE_COLLECTIONS,
        occupationList));
    } catch (error) {
      console.error('setOccupationList ', error);
    }
  }

  public getWitnessSignatureURL = (refId: string) => async (): Promise<any> => {
    try {
      // const { collections } = getState().quotationManagement;
      const result = await this.service.getWitnessSignature(refId);
      const url = URL.createObjectURL(result);
      return url;
    } catch (error) {
      console.error('getQuoteWitnessSignature ', error);
      return '';
    }
  }


  private prepareCollections = (obj: IObject): IObject => ({
    ...obj,
    status: this.addAllToList(listLib.convertToPairList(obj.status, ['code', 'name'])),
  });

  private changeState = (list: IObject[]): IObject[] => {
    return list.map((item) => {
      const state = item['status'] === 'Certificate Issued' ? 'Certificate Issued' : 'Incomplete Quotation';
      return {
        ...item,
        state
      };
    });
  };

  private prepareList = (list: IObject[], fields: IObject[]): IObject[] => {
    let prepared = this.applyListFormat(list, fields);
    prepared = this.changeState(prepared);
    return prepared;
  };
}
export default QuotationAction;
