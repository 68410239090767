import React from 'react';
import ReactTagInput from '@pathofdev/react-tag-input';
// eslint-disable-next-line import/extensions
import { objLib } from '../../lib';

export default (props: IMultiValueInputProps) => {
  const { id, handler, values , validationMessage } = props;
  const onChange = (tags: String[]) => {
    const pair = objLib.getPair(id, tags.join(';'));
    handler({ ...pair, value: pair.value || null });
  };
  return (
    <>
      <ReactTagInput
        tags={values}
        placeholder='Type email and press enter'
        onChange={onChange}
        validator={(value) => {
          const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,5})$/g;
          const isEmail = !!value.match(emailRegEx);
          // Return boolean to indicate validity
          return isEmail;
        }}
      />
      {
        validationMessage ? <div className='text-danger-step'>{validationMessage}</div> : <></>
      }
    </>
  );
};
