import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import BaseComponent from '@xc-core/components/baseComponent';

interface IProps { properties: any, status: string }
interface IRemarkItem {key:string,name:string}

export default class RemarksCard extends BaseComponent<IProps> {
  generateRemarkItems=()=>{
    const { properties,status } = this.props;
    const propertyKeys: IRemarkItem[] = [
      {
        key:'Cancel Reason',
        name:'Cancellation Reason'
      },
      {
        key:'Cancel Remarks',
        name:'Remarks'
      },{
        key:'Endorse Remarks',
        name:'Reason'
      }];
    return propertyKeys.map((item:IRemarkItem , index)=>{
      const {key,name} = item
      const value = properties[key];
      return value && ((status === 'Cancelled' && key !== "Endorse Remarks")
          || (status !== 'Cancelled' && key === "Endorse Remarks")) ? (
          <Row key={index}>
            <Col sm={6}>{name}</Col>
            <Col className='text-right py-2 summary-label-3' sm={6}>
              { value }
            </Col>
          </Row>
      ) :null
    })
  }

  render() {
    const { properties,status } = this.props;
    if(!properties['Cancel Reason'] && !properties['Cancel Remarks'] && !properties['Endorse Remarks']) return null;
    return (
        <div className='sidebarCard'>
          <div>
            <Card className='mt-4 shadow-md border-0' style={{ borderRadius: '10px' }}>
              <CardBody className='px-3'>
                <div className='pt-2 text-primary'>
                  <Row>
                    <Col sm={9} className='payment-title-qc font-size-normal text-secondary mt-2 mb-2'>
                      Remarks
                    </Col>
                  </Row>
                </div>
                <hr className='my-2' />
                <div className='pt-2'>
                  {this.generateRemarkItems()}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
    );
  }
}
