import {
  quotationConstants
} from '../constants';
import {HANDLE_FILTER_CLICKED, CLEAR_FILTER_SELECTION} from "../../../../_constants";
import { setHasRecords } from "../../../../utils";

const defaultQuotation = {
  "id": null,
  "refCode": null,
  "common": {
    "createdOn": (new Date()).toString(),
    "createdBy": "",
    "updatedOn": (new Date()).toString(),
    "updatedBy": "",
    "domain": "",
    "detatedOn": null
  },
  "state": "",
  "status": null,
  "productCode": "",
  "stateHistory": null,
  "description": null,
  "properties": {
    "Plan": {},
    "Trip Type": "",
    "priceList": [],
    "Destination": "",
    "No. of Days": 0,
    "Insured Type": "",
    "Beneficiaries": [],
  },
  "quotationTmpltId": null
};

const setupQuotationObject = (item) => {
  return {
    ...defaultQuotation,
    ...item,
    properties: {
      ...(item.properties || defaultQuotation.properties)
    },
  };
};

const initialState = {
  canViewList: true,
  hasRecords: true,
  products: [],
  fields: [],
  currentProduct: null,
  /** default should be false otherwise it will show an empty list rather than the proper message */
  hasTemplate: false,
  quotation: null,
  keepFilters: false, /** when backing to list from detail page, list should not get refreshed */
  filterTodayQuotations: false,
};

function quotationManagement(state = initialState, action) {
  switch (action.type) {
    case quotationConstants.SET_CSV_BLOB:
      return {...state, csvBlob: action.blob }
    case quotationConstants.RESET_CSV_BLOB:
      return {...state, csvBlob: undefined }
    case quotationConstants.GET_QUOTATION_DETAIL:
      return {
        ...state,
        keepFilters: true,
        quotation: setupQuotationObject(action.payload.item),
        collections: action.payload.collections,
        notification: action.notification,
        notificationType: action.notificationType,
      };
    case quotationConstants.SET_FORBIDDEN_QUOTATIONS:
      return ({
        ...initialState,
        canViewList: false,
      });
    case quotationConstants.GET_QUOTATIONS_LIST:
      let pagination = {};
      if (!action.payload.list.error) {
        pagination = {
          first: action.payload.list.list.first,
          last: action.payload.list.list.last,
          currentPage: action.payload.list.list.number,
          pageable: action.payload.list.list.pageable,
          totalElement: action.payload.list.list.totalElements,
          totalPages: action.payload.list.list.totalPages,
        };
      }
      const hasRecords = setHasRecords(action.payload.list.list.content);
      return {
        ...state,
        hasRecords,
        canViewList: true,
        filterTodayQuotations: false, /** reset it */
        hasTemplate: action.payload.hasTemplate,
        filterOptions: action.payload.list.filters,
        initialFilterOptions: action.payload.list.filters,
        quotationsList: action.payload.list.list ? action.payload.list.list.content : [],
        fields: action.payload.list.fields || [],
        collections: action.payload.list.collections,
        pagination: pagination,
        notification: action.payload.notification,
        notificationType: action.payload.notificationType,
      };
    case quotationConstants.QUOTATIONS_APPLY_FILTER:
      // const dynamicOptions = !action.payload.filter ?
      //   filterUtil.addExtraPropertiesToFilters(action.payload.filters) : action.payload.filter;
      const filtersWithExtraProperties = action.payload.filter;
      return{
        ...state,
        quotationsList: action.payload.list.content === '' ? state.orgList : action.payload.list.content,
        collections: action.payload.collections,
        filterOptions: filtersWithExtraProperties, // dynamicOptions, // action.payload.list.filter === "" ? state.filterOptions : dynamicOptions,
        fields: action.payload.fields || [],
        pagination: {
          first: action.payload.list.first,
          last: action.payload.list.last,
          currentPage: action.payload.list.number,
          pageable: action.payload.list.pageable,
          totalElement: action.payload.list.totalElements,
          totalPages: action.payload.list.totalPages
        },
        notification: action.payload.notification,
        notificationType: action.payload.notificationType,
      };
    case HANDLE_FILTER_CLICKED:
      return {
        ...state,
        filterOptions: action.payload.filterOptions,
      };
    case CLEAR_FILTER_SELECTION:
      return {
        ...state,
        filterOptions: action.payload.filterOptions,
      };
    case quotationConstants.QUOTATION_RESET_FILTER:
      return {
        ...state,
        filterOptions: state.initialFilterOptions,
      };
    case quotationConstants.CLEAR_QUOTATION_DETAIL:
      return {
        ...state,
        quotation: null,
      };
    case quotationConstants.GET_QUOTATION_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        currentProduct: action.payload[0],
      };
    case quotationConstants.CHANGE_CURRENT_QUOTATION_PRODUCT:
      return {
        ...state,
        hasTemplate: action.payload.hasTemplate,
        currentProduct: action.payload.selectedProduct,
      };
    case quotationConstants.SET_CURRENT_QUOTATION_PRODUCT_TO_ALL:
      return {
        ...state,
        currentProduct: action.payload.currentProduct,
      };
    case quotationConstants.CLEAR_QUOTATION_LIST:
      return {
        ...state,
        quotationsList: [],
      };
    case quotationConstants.RESET_KEEP_FILTERS:
      return {
        ...state,
        keepFilters: false,
      };
    case quotationConstants.SET_APPLY_TODAY_QUOTATION_FILTER:
      return {
        ...state,
        filterTodayQuotations: true,
      };
    case quotationConstants.QUOTATION_UPDATE_COLLECTIONS:
      return {
        ...state,
        collections: {
          ...state.collections,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default quotationManagement;
