import React, {useEffect} from 'react';
import {
    Col
  } from 'reactstrap';
import { connect } from "react-redux";
import { Header } from '../../@xc-core/components/core/header';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import { actions } from '@redux';
import { Table, Filter, Loader } from '../../@xc-core/components/core';
import { numberLib, handleError} from '@xc-core/lib'
import orgImage from "images/emptyStateImages/organisations.png";

const distributionControlActions = actions.DistributionControlActions;

const heading: any = [
  {
    "id": "orgUnitName",
    "type": "string",
    "displayName": "Organisation",
    "value": "Organisation"
  },
  {
    "id": "orgUnitType",
    "type": "string",
    "displayName": "Organisation Type",
    "value": "Organisation Type"
  },
  {
    "id": "status",
    "type": "string",
    "displayName": "Distribution Limit Activated?",
    "value": "Distribution Limit Activated?"
  },
  {
    "id": "updatedOn",
    "type": "string",
    "value": "Last Updated",
    "displayName": "Last Updated"
  },
  
];

const DistributionControlComponent = (props: IObject)=>{
  const {
    orgList,
    pagination,
    isLoading,
    handleChange,
    searchKeyword,
    filters,
    getList,
    getListWithParams,
    prevFilters
  } = props
  
  useEffect(()=>{
    if(!props.isTestCase)
      callGetList();
  },[])

  const callGetList = async ()=> {
    try{
      await getList();
    } catch(e){
      handleError(e)
    }
  }

  const goToDetails = (item:any) =>{
    props.history.push(`/PolicyLimitConfig/${item.orgUnitCode}`);
  }

  const onCancelFilter = (prevFilters: IListingFilter[]) => {
    handleChange({id:'filters',value:prevFilters})
  }

  const onFilterCheckChange = (filters: IListingFilter[]) => {
    handleChange({id:'filters',value:filters})
  }

  const onApplyFilter = async (filters: IListingFilter[]) => {
    handleChange({id:'prevFilters',value:filters})
    handleChange({id:'filters',value:filters})
    callGetListWithParams(filters,searchKeyword,pagination.pageNumber,pagination.pageSize)
  }

  const onPageSizeChange = (pair:IPair) =>{
    handleChange({id:'pagination.pageNumber',value:0})
    handleChange({id:'pagination.pageSize',value:numberLib.toInt(pair.value)})
    callGetListWithParams(filters,searchKeyword,0,numberLib.toInt(pair.value))
  }

  const onPageClick = (pair:IPair)=>{
    handleChange({id:'pagination.pageNumber',value:numberLib.toInt(pair.value)})
    callGetListWithParams(filters,searchKeyword,numberLib.toInt(pair.value),pagination.pageSize)
  }

  const onSearchChange = (pair:IPair) =>{
    handleChange({id:'pagination.pageNumber',value:0})
    handleChange({id:'searchKeyword',value: pair.value})
    callGetListWithParams(filters,pair.value,0,pagination.pageSize)
  }

  const callGetListWithParams = async (filters:IObject[],value:string,page:number,pageSize: number)=>{
    try{
      getListWithParams({
        filters: filters,
        searchKeyword : value,
        page: page,
        pagesize: pageSize
      })
    } catch(e){
      handleError(e)
    }
    
  }

  if (isLoading || filters.length === 0) return <Loader />;
  return (
          <div data-testid="containter">
              <Header
                title='Distribution Control'
                bannerGraphic={bannerImg}
                withBorderBottom
              />
              <div data-testid="containter-inner" className='container-fluid px-0'>
              <div className="row d-flex justify-content-center my_30">
                <Col sm="11" className="my-2">
                  { <Filter
                    filterOptions={filters || []}
                    // isLoading={isFilterLoading}
                    isLoading={false}
                    applyFilter={(newFilters: IListingFilter[]) => onApplyFilter(newFilters)}
                    cancelFilter={() => onCancelFilter(prevFilters)}
                    onCheckChange={
                      (newFilters: IListingFilter[]) => onFilterCheckChange(newFilters)
                    }
                  />}
                </Col>
                <div className="col-sm-11">
                  <Table
                    titles={heading}
                    records={orgList !== undefined ? orgList : []}
                    hasRecords={orgList.length > 0}
                    image={orgImage}
                    onRowClicked={goToDetails}
                    handlePageClick={(pair: IPair)=> onPageClick(pair)}
                    handlePageSizeChange={(pair: IPair) => onPageSizeChange(pair)}
                    pagination={pagination}
                    onRowButtonClicked={goToDetails}
                    handleSearchKeyword={(pair: IPair) => onSearchChange(pair)}
                    searchKeyword={searchKeyword}
                    buttonIcon={"pencil"}
                  >
                  </Table>
                  
                </div>
              </div>
            </div>
          </div>
        );

}    
const mapStateToProps = (state: IStore) => ({
   filterOptions: state.distributionControl.filterOptions,
   orgList: state.distributionControl.orgList,
   pagination : state.distributionControl.pagination,
   isLoading : state.distributionControl.isLoading,
   searchKeyword: state.distributionControl.searchKeyword,
   filters: state.distributionControl.filters,
   prevFilters: state.distributionControl.prevFilters,
   isTestCase: state.isTestCase
});
const mapDispatchToProps = (dispatch: any) => ({
  handleChange: (pair: IPair) => dispatch(distributionControlActions.handleChange(pair)),
  getList: () => dispatch(distributionControlActions.getList()),
  getListWithParams: (data:IObject) => dispatch(distributionControlActions.getListWithParams(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(DistributionControlComponent);