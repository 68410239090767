import React from 'react';
import {Col} from "reactstrap";
import {CommonInputTypes} from '_components';
import {utils} from 'utils';

const MainInsuredDetails = (props) => {

    const {fields, collections, handleChange} = props;
        return(
        <>
        <Col sm="6">
          <CommonInputTypes
            idName="fullName"
            id={1}
            type="input"
						title="Full Name"
            group={false}
            placeholder={"Enter full name"}
            onChange={(e) => props.handleChange(e)}
            validator={props.validator}
            validationRules="customRequired|alfalah_full_name_valid_characters"
            value = {fields.fullName}
            autoFocus={true}
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName="nationality"
            idNameIndex="nationality"
            type="select"
            value={fields.nationality}
            onChange={(e) => props.handleChange(e)}
            title="Nationality"
            id={6}
            group={false}
            option={collections.nationalities}
            validator={props.validator}
            validationRules="customRequired"
          />
        </Col>
        <Col sm="6">
          <CommonInputTypes
            idName="idType"
            type="select"
            title="Id Type"
            id={5}
            group={false}
            option={collections.idTypes}
            onChange={(e) => props.handleIdTypeChange(e)}
            value = {fields.idType}
            autoFocus={true}
            validator={props.validator}
            validationRules="customRequired"
          />
        </Col>
        <Col sm="6">
					<CommonInputTypes
            idName="idNumber"
            id={2}
            type="text"
						title="Id Number"
            group={false}
            placeholder={"Enter Id number"}
            onChange={(e) => props.handleIdNumberChange(e)}
            validator={props.validator}
            validationRules={fields.idType === "CNIC" 
              ? `customRequired|numbersOnly|min:${utils.lengths.CNIC}|max:${utils.lengths.CNIC}` : "customRequired"}
            value = {fields.idNumber}
            autoFocus={true}
          />        
					</Col>
					<Col sm="6">
              <CommonInputTypes
                idName={"gender"}
                type="select"
                title={"Gender"}
                id={4}
                group={false}
                option={collections.genders}
                // disabled = {fields.idType === "CNIC"}
                onChange={(e) => props.handleChange(e)}
                value = {fields.gender}
                autoFocus={true}
                validator={props.validator}
                validationRules="customRequired"
              />
          </Col>
          <Col sm="6">
            <CommonInputTypes
              idName="debitAccountNo"
              id={5}
              type="text"
              title="Debit Card / Account No"
              group={false}
              placeholder="Enter debit card / account no"
              onChange={(e) => props.handleDebitCreditNoChange(e)}
              value = {fields.debitAccountNo}
            />
        </Col>
      </>);
}

export default MainInsuredDetails;
