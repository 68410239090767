import {handleRequest, handleResponse} from "../../../../_services";
import {getRequestOptions} from "../../../../utils";
import endpoints from 'Products/shared/GetQuote/endpoints';

const Config = require('_config/env');

const apiUrl = Config.env.quotationUrl;
const policyUrl = Config.env.policyUrl;

async function createQuotation(body){
  const method = "POST";
  const url = `${apiUrl}/quotations/with-collections`;
  const response = await handleRequest(url, getRequestOptions({body, method}));
  const res = await handleResponse(response);
  return res;
}

export async function downloadCertificate (id) {
  /**
   * get a pdf file for the certificate's id
   * @return url to open the pdf from
   */
  const url = `${policyUrl}/policies/download?idOrCode=${id}`;
  const response = await handleRequest(url, getRequestOptions());
  try {
    return await response.blob();
  } catch (e) {
    return {
      error: e,
      message: e.message
    }
  }
}

async function updateQuotation(body,id,direction){
  const url = `${apiUrl}/quotations/${id}/${direction}`;
  const method = "PUT";
  const response = await handleRequest(url, getRequestOptions({body, method}));
  const res = await handleResponse(response);
  return res;
}

const getStates = async (country, param = '') => {
  if (param) {
    const url = `${apiUrl}/quotations/${country}/states${param}`;
    const response = await handleRequest(url, getRequestOptions());
    const res = await handleResponse(response);
    return res;
  }
};

const getQuotation = async (quotationId) => {
  const url = `${apiUrl}/quotations/${quotationId}/with-collections`;
  const response = await handleRequest(url, getRequestOptions());
  const res = await handleResponse(response);
  return res;
};

const getProducts = async () => {
  const url = `${apiUrl}${endpoints.getQuote.getProducts}`;
  const response = await handleRequest(url, getRequestOptions());
  const products = await handleResponse(response);
  return products;
};
const getCollections = async (productCode) => {
  const url = `${apiUrl}${endpoints.getQuote.getCollections}?productCode=${productCode}`;
  const response = await handleRequest(url, getRequestOptions());
  const collections = await handleResponse(response);
  return collections;
}
export const getQuoteServices = {
  createQuotation,
  updateQuotation,
  getStates,
  getQuotation,
  getProducts,
  getCollections,
};