import env from 'env';
import Request from './request';
import Response from './response';
import {handleRequest, handleResponse} from "_services";
import {getRequestOptions} from "utils";

class CollectionManagementService {
    protected env = env.policy;


    protected api = 'collections';

    public getCollections = async (page:number,pageSize:number): Promise<any> => {
      const url = `${this.env}/${this.api}?page=${page}&pageSize=${pageSize}`;
      const request = new Request();
      const response = new Response(url, request);
      const result = await response.handle();
      return result.data;
    }

    public downloadCollection = async (code:string)=>{
      const url = `${this.env}/${this.api}/download/${code}`
      try {
        const response = await handleRequest(url, getRequestOptions());
        return await response.blob();
      } catch (e) {
        return {
          error: e,
          message: e.message
        }
      }
    }

    public save = async (formData:FormData) =>{ 
      const url = `${this.env}/${this.api}`;
      try{
        const serverResponse = await handleRequest(url, getRequestOptions({ body:formData, method:"POST", contentType: "multipart/form-data" }));
        const res = await handleResponse(serverResponse);
        if(res.error){
          return Promise.reject(res);
        }
        return res;
      } catch(e){
        return Promise.reject(e);
      }


      
    }

    public delete = async(data:IObject)=>{
      const url = `${this.env}/${this.api}`;
      const request = new Request(data,'DELETE');
      const response = new Response(url, request);
      const result = await response.handle();
      return result.data;
    }

    public search = async(name:string,page:number,pageSize:number)=>{
      const url = `${this.env}/${this.api}/search?name=${name}&page=${page}&pageSize=${pageSize}`;
      const request = new Request();
      const response = new Response(url, request);
      const result = await response.handle();
      return result.data;
    }
}

export default CollectionManagementService;
