import { ProductService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import BaseAction from './base';

class APIDocAction extends BaseAction {
  constructor() {
    super();
    this.service = new ProductService();
  }

  protected actions = actionTypes.apiDoc;

  protected service: ProductService;

  protected csvExportScope = '';

  protected editScope = '';

  protected createScope = '';

  protected dispatchList = (params: IRequestParam,
    serviceMethod: Function) => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.LOADING));
    try {
      const documentation = await serviceMethod();
      dispatch(this.createAction<any>(this.actions.GET, documentation));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.SET_FORBIDDEN));
      }
      // if (error.status === 404) {
      //   dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      // }
      dispatch(this.createAction(this.actions.FAILED, error));
    }
  }
}

export default APIDocAction;
