import React, {Fragment} from "react";
import PropTypes from "prop-types";
import { utils } from "utils";
import CircularButton from "../CircularButton";

const RenderRecord = (props) => {
  const {
    goToDetails, data, className,
    isChild, titles, printContent, onPressButton,
    arrowIcon, toggleChildren, listType,
  } = props;
  return (
    <tr
      className={className}
      onClick={() => goToDetails && goToDetails(data)}
    >
      {titles.map((title) => {
        if (title.viewChildren) {
          return (
            <td key={title.id} onClick={(e)=> (toggleChildren && toggleChildren(e))} >
              <i className={arrowIcon} />
            </td>
          );
        }
        if (title.key) {
          const { subKey } = title;
          if (subKey === undefined || (subKey && data[title.key])) {
            return (
              <td key={title.id} className={`${isChild && title.id === 0 ? "pl-4" : ""}`}>
                { printContent(data, title, listType) }
              </td>
            );
          }
          return (
            <td key={title.id} />
          );
        }
        if (title.editButton) {
          return (
            <td
              key={title.id}
              className="actionIcon text-right"
            >
              {title.icons.map((icon) => (
                <CircularButton
                  press={() => onPressButton(icon.actionName)}
                  key={icon.id}
                  iconName={icon.name}
                  id={(data[icon.id] || "").toString()}
                />
              ))}
            </td>
          );
        }
        return <td key={title.id} />;
      })}
    </tr>
  );
};

const RenderChildrenRecords = (props) => {
  const {
    records,
    goToDetails,
    printContent,
    titles,
    arrowIcons,
    parentId
  } = props;
  return (
    records.map((record) => (
      <RenderRecord
        key={record.id}
        className={`child-bg ${arrowIcons[parentId] === "icon-downArrow" ? "" : "d-none"}`}
        index={record.id}
        data={record}
        isChild
        goToDetails={goToDetails}
        printContent={printContent}
        titles={titles}
        onPressButton={(actionName) => this.props[actionName]} 
      />
    )));
};

const NoData = (props) => {
  const {
    headerLength,
    recordsLength,
    hasRecords,
    noDataImagePath,
  } = props;
  return (
    <tr>
      <td colSpan={headerLength}>
        <div className="empty-state">
          <img src={noDataImagePath} alt="no data" className="center" />
          <small className="text-lg-center ml-2">
            {
              hasRecords && recordsLength === 0
                ? <p>No results match. Please try again.</p>
                : (
                  <div>
                    <p className="m-0 text-left empty-state-p">No records yet.</p>
                    <p className="m-0 text-left empty-state-p">Start creating one and it will show up here.</p>
                  </div>
                )
            }
          </small>
        </div>
      </td>
    </tr>
  );
};
NoData.propTypes = {
  headerLength: PropTypes.number,
  recordsLength: PropTypes.number,
  hasRecords: PropTypes.bool,
  noDataImagePath: PropTypes.string,
};
NoData.defaultProps = {
  headerLength: 0,
  recordsLength: 0,
  hasRecords: false,
  noDataImagePath: "",
};

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      arrowIcons: [],
    };
  }

  hasChildren = (record) => (record.children && record.children.length > 0) === true;

  printContent = (data, title) => {
    const { listType } = this.props;
    const subs = title.subKey ? title.subKey.split(",") : title.subKey;
    let content = "";
    if (subs && subs.length === 1) {
      content = data[title.key][subs];
    } else if (subs || utils.lib.isArray(content)) {
      let tempContent = data[title.key];
      subs.forEach((sub) => { if (tempContent) tempContent = tempContent[sub.trim()]; });
      content = tempContent;
    } else if (![null, undefined].includes(data[title.key]) && typeof (data[title.key].valueOf()) === "boolean") {
      content = data[title.key] ? "Active" : "Inactive";
    } else if (title.key === "state" && listType === "quotation") {
      content = data.state === "Completed" ? "Certificate Issued" : "Incomplete Quotation";
    } else if (data[title.key]) {
      content = data[title.key];
    }
    return content;
  };

  toggleChildren = (e, index) => {
    const { arrowIcons } = this.state;

    const newArrowIcons = [...arrowIcons];
    if (!newArrowIcons[index]) {
      newArrowIcons[index] = "icon-rightArrow"; /** default class */
    }
    newArrowIcons[index] = newArrowIcons[index] === "icon-rightArrow" ? "icon-downArrow" : "icon-rightArrow";
    this.setState({arrowIcons: newArrowIcons});
    e.stopPropagation();
  };
  
  render() {
    const {
      records, goToDetails, titles,
      image, hasRecords, listType,
    } = this.props;
    const { arrowIcons } = this.state;
    return (
      <tbody>
        { records.length <= 0 ? (
          <NoData
            headerLength={titles.length}
            recordsLength={records && records.length}
            hasRecords={hasRecords}
            noDataImagePath={image}
          />
        ) : (
          records.map((record) => (
            <Fragment key={record.id}>
              <RenderRecord
                key={record.id}
                index={record.id}
                data={record}
                goToDetails={goToDetails}
                printContent={this.printContent}
                listType={listType}
                titles={titles}
                onPressButton={(actionName) => this.props[actionName]}
                toggleChildren={(e) => this.toggleChildren(e, record.id)}
                arrowIcon={`${this.hasChildren(record) ? arrowIcons[record.id] || "icon-rightArrow" : ""}`}
              />
              {this.hasChildren(record) === false ? <></> : (
                <RenderChildrenRecords
                  className={`child-bg ${arrowIcons[record.id] === "icon-downArrow" ? "" : "d-none"}`}
                  arrowIcons={arrowIcons}
                  records={record.children}
                  goToDetails={goToDetails}
                  printContent={this.printContent}
                  titles={titles}
                  parentId={record.id}
                />
              )}
            </Fragment>
          ))
        )}
      </tbody>
    );
  }
};

Body.propTypes = {
  records: PropTypes.array,
  hasRecords: PropTypes.bool,
  image: PropTypes.string.isRequired,
  listType: PropTypes.string,
  titles: PropTypes.any,
  goToDetails: PropTypes.func,
};
Body.defaultProps = {
  records: [],
  hasRecords: false,
  titles: [],
  listType: "",
  goToDetails: () => {},
};

export default Body;
